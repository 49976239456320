import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'

export default function SidebarClub({ isCollapsed }) {
	const { t } = useTranslation()
	const {club} = useSelector(state=>state.clubs)

  	return (
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} id="sidebar">
			<Link to="/"><img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='mb-5 ms-3' /></Link>
			<Nav className="flex-column p-0">
				<Nav.Item>
					<Link to={`/${t("url.club.club")}/${t("url.club.dashboard")}`}><img src="/images/menus/dashboard.svg" alt={t('menus.general.dashboard')}/> {!isCollapsed && t('menus.general.dashboard')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/members.svg" alt={t('menus.general.clients')}/> {!isCollapsed && t('menus.general.clients')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.club.club")}/${t("url.club.payments")}`}><img src="/images/menus/payments.svg" alt={t('menus.general.payments')}/> {!isCollapsed && t('menus.general.payments')}</Link>
				</Nav.Item>
				{
					club?.activitybooking
					?
						<>
							<Nav.Item>
								<Link to={`/${t("url.club.club")}/${t("url.club.activities")}`}><img src="/images/menus/activities.svg" alt={t('menus.general.activities')}/> {!isCollapsed && t('menus.general.activities')}</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to={`/${t("url.club.club")}/${t("url.club.promotions")}`}><img src="/images/menus/promotions.svg" alt={t('menus.general.promotions')}/> {!isCollapsed && t('menus.general.promotions')}</Link>
							</Nav.Item>
						</>
					:
						<></>
				}
				<Nav.Item>
					<Link to={`/${t("url.club.club")}/${t("url.club.sites")}`}><img src="/images/menus/sites.svg" alt={t('menus.general.sites')}/> {!isCollapsed && t('menus.general.sites')}</Link>
				</Nav.Item>
				{
					club?.renting
					?
					<>
						<Nav.Item>
							<Link to={`/${t("url.club.club")}/${t("url.club.courts")}`}><img src="/images/menus/sites.svg" alt={t('menus.general.courts')}/> {!isCollapsed && t('menus.general.courts')}</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/${t("url.club.club")}/${t("url.club.courtsrents")}`}><img src="/images/menus/calendar.svg" alt={t('menus.general.courtsrents')}/> {!isCollapsed && t('menus.general.courtsrents')}</Link>
						</Nav.Item>
					</>
					:
					<></>
				}
				{
					club?.ticketing
					?
						<Nav.Item>
							<Link to={`/${t("url.club.club")}/${t("url.club.seasons")}`}><img src="/images/menus/ticketing.svg" alt={t('menus.general.ticketing')}/> {!isCollapsed && t('menus.general.ticketing')}</Link>
						</Nav.Item>
					:
						<></>
				}
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/reports.svg" alt={t('menus.general.reports')}/> {!isCollapsed && t('menus.general.reports')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/billing.svg" alt={t('menus.general.billing')}/> {!isCollapsed && t('menus.general.billing')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.club.club")}/${t("url.club.logs")}/${t("url.club.sepalogs")}`}><img src="/images/menus/config.svg" alt={t('menus.general.sepalogs')}/> {!isCollapsed && t('menus.general.sepalogs')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.club.club")}/${t("url.club.basicdata")}`}><img src="/images/menus/config.svg" alt={t('menus.general.config')}/> {!isCollapsed && t('menus.general.config')}</Link>
				</Nav.Item>
			</Nav>
		</div>
	)
}