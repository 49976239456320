import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function CheckOutPassType({item}) {
    const { t } = useTranslation()

    const calculatePassTypePrice = (item) => {
        const priceToPay = item.passType.seasons?.find(season => season.season?._id === item.season)?.price || 0

        if (priceToPay > 0) {
            const priceComission = item.commission?.find(passType => priceToPay >= passType.lowPrice && priceToPay <= passType.highPrice);
            return Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
        } else {
            return 0.00
        }
    }

    return (
        <Row className='border py-4 px-3 rounded my-5'>
            <Col xs={12} md={4}>
                <img src={item.passType.image || `/images/bannerclub.jpg`} alt={item.passType.name} className="w-100"/>
            </Col>
            <Col xs={12} md={8}>
                <Row className='mb-5'>
                    <Col xs={8}>
                        <h3>{item.passType.name}</h3>
                    </Col>
                    <Col xs={4}>
                        <div className='border rounded group-price text-center py-3 fw-bold'>{calculatePassTypePrice(item)}€</div>
                    </Col>
                </Row>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("passTypes.sector")}</span>
                        <span>{item.passType.sector.name}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("passTypes.season")}</span>
                        <span>{item.passType.seasons?.find(season => season.season._id === item.season)?.season?.name}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                {
                    item.passes.map((pass, index) => (
                        <div className='mt-3' key={index}>
                            <div className='d-flex justify-content-between align-items-end'>
                                <span className='fw-bold'>{t("passTypes.marketplacePassPerson", { index: index + 1 })}</span>
                                <span>{pass.lastname}, {pass.firstname}</span>
                            </div>
                            <hr className='border my-1'/>
                        </div>
                    ))
                }
            </Col>
        </Row>
    )
}