import { Button, Col, Form, Modal, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Loader from "../../design/Loader/Loader"
import { useState } from "react"
import Select from "react-select"

export default function PromotionForm({state, statetype, usertype, isAddingOrEditing, submit, inputChange, addCondition, editCondition, checkboxChange, onDeleteCondition, onImpactInputChange}) {
    const { t } = useTranslation()

    const [show, setShow] = useState(false)
    const [conditionForm, setConditionForm] = useState({
        type: 'member',
        parameters: ['']
    })

    const handleConditionFormType = (value) => {
        let parametersCopy = [...conditionForm.parameters]
        
        if ((value === 'age' || value === 'membersYounger') && parametersCopy.length < 2) {
            parametersCopy.push('')
        }

        if ((value !== 'age' && value !== 'membersYounger') && parametersCopy.length > 1) {
            parametersCopy.pop()
        }
        setConditionForm({
            ...conditionForm,
            type: value,
            parameters: parametersCopy
        })
    }

    const handleConditionFormParameterInput = (value, index) => {
        setConditionForm({
            ...conditionForm,
            parameters: conditionForm.parameters.map((parameter, i) => {
                if (i === index) {
                    return value
                } else {
                    return parameter
                }
            })
        })
    }

    const handleSaveCondition = () => {
        if (conditionForm._id) {
            editCondition(conditionForm)
        } else {
            addCondition(conditionForm)
        }
        setConditionForm({
            type: 'member',
            parameters: ['']
        })
        setShow(false)
    }

    const selectConditionToEdit = (condition) => {
        setConditionForm(condition)
        setShow(true)
    }

    const hideModal = () => {
        setConditionForm({
            type: 'member',
            parameters: ['']
        })
        setShow(false)
    }

    return (
        <>
            <Form onSubmit={submit}>
                <Row>
                    <Col>
                        <h3 className="font-single">{t('promotions.data')}</h3>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} lg={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.name ? 'has-content' : ''}
                                type='text'
                                name='name'
                                value={state?.name ||  ''}
                                onChange={inputChange}
                                required
                            />
                            <Form.Label htmlFor='name'>{t('promotions.name')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} lg={6}>
                        <Form.Group className="d-flex">
                            <Form.Label htmlFor='active'>{t('promotions.active')}</Form.Label>
                            <Form.Check
                                className="ms-3"
                                type="switch"
                                name="active"
                                onChange={checkboxChange}
                                checked={state?.active || false}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Form.Group className="d-flex">
                            <Form.Label htmlFor='exclusive'>{t('promotions.exclusive')}</Form.Label>
                            <Form.Check
                                className="ms-3"
                                type="switch"
                                name="exclusive"
                                onChange={checkboxChange}
                                checked={state?.exclusive || false}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} className="my-3">
                        <div className="d-flex justify-content-between align-items-end">
                            <h3 className="font-single">{t('promotions.conditions.title')}</h3>
                            <Button variant="default" onClick={() => {setShow(true)}}>
                                <i className="fas fa-plus" /> {t('promotions.addCondition')}
                            </Button>
                        </div>
                        <hr className="fina"/>
                    </Col>
                    <Col xs={12} lg={11}>
                        <Row className="border-bottom border-1 border-dark pb-2">
                            <Col xs={4}>{t('promotions.conditions.type')}</Col>
                            <Col xs={3}>{t('promotions.conditions.parameters')}</Col>
                            <Col xs={1}></Col>
                        </Row>
                        {
                            state?.conditions?.map((condition, index) => (
                                <Row className="py-3 border-1 border-bottom" key={index}>
                                    <Col xs={6}>{t(`promotions.conditions.${condition.type}`)}</Col>
                                    <Col xs={5}>
                                        { condition.parameters.join('-') }
                                    </Col>
                                    <Col xs={1}>
                                        <Link onClick={() => {selectConditionToEdit(condition)}} className="me-3">
                                            <img src="/images/pencil.svg" className="cursor" alt="Pencil" />
                                        </Link>
                                        <Link onClick={() => {onDeleteCondition(index)}}>
                                            <img src="/images/trash.svg" className="cursor" alt="Trash" />
                                        </Link>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <h3 className="font-single">{t('promotions.discount')}</h3>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} lg={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.impact?.registrationQuantity !== null && state?.impact?.registrationQuantity !== undefined ? 'has-content' : ''}
                                type='number'
                                name='registrationQuantity'
                                value={state?.impact?.registrationQuantity || 0}
                                onChange={onImpactInputChange}
                                required
                            />
                            <Form.Label htmlFor='registrationQuantity'>{t('promotions.registrationQuantity')}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.impact?.registrationPercentage !== null && state?.impact?.registrationPercentage !== undefined ? 'has-content' : ''}
                                type='number'
                                name='registrationPercentage'
                                value={state?.impact?.registrationPercentage || 0}
                                onChange={onImpactInputChange}
                                required
                            />
                            <Form.Label htmlFor='registrationPercentage'>{t('promotions.registrationPercentage')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} lg={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.impact?.seasonPaymentQuantity !== null && state?.impact?.seasonPaymentQuantity !== undefined ? 'has-content' : ''}
                                type='number'
                                name='seasonPaymentQuantity'
                                value={state?.impact?.seasonPaymentQuantity || 0}
                                onChange={onImpactInputChange}
                                required
                            />
                            <Form.Label htmlFor='seasonPaymentQuantity'>{t('promotions.seasonPaymentQuantity')}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.impact?.seasonPaymentPercentage !== null && state?.impact?.seasonPaymentPercentage !== undefined ? 'has-content' : ''}
                                type='number'
                                name='seasonPaymentPercentage'
                                value={state?.impact?.seasonPaymentPercentage || 0}
                                onChange={onImpactInputChange}
                                required
                            />
                            <Form.Label htmlFor='seasonPaymentPercentage'>{t('promotions.seasonPaymentPercentage')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                {isAddingOrEditing
                    ?
                        <Loader/>
                    :
                        <Row className="mt-5">
                            <Col xs={12} lg={6}>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.promotions")}` : `/${t("url.club.club")}/${t("url.club.promotions")}`} className="btn btn-default my-2 w-100">
                                    {t('global.cancel')}
                                </Link>
                            </Col>
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button type="submit" variant="primary" className='my-2 w-100'>
                                    {
                                        statetype === 'add' ? t('global.save') :
                                        statetype === 'edit' ? t('global.edit') : ''
                                    }
                                </Button>
                            </Col>
                        </Row>
                }
            </Form>
            <Modal show={show} onHide={() => hideModal()} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>
                            {
                                conditionForm._id
                                ?
                                    t('promotions.conditions.edit')
                                :
                                    t('promotions.conditions.new')
                            }
                        </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo p-5'>
                    <Row className="my-3">
                        <Form.Label htmlFor='type'>{t('promotions.conditions.type')}</Form.Label>
                        <Select
                            className="form-control has-content"
                            name='type'
                            options={["member", "membersYounger", "inscriptions", "age"].map(type => {
                                return {
                                    label: t(`promotions.conditions.${type}`),
                                    value: type
                                }
                            })}
                            value={{
                                label: t(`promotions.conditions.${conditionForm.type}`),
                                value: conditionForm.type
                            }}
                            onChange={(value) => handleConditionFormType(value.value)}
                            required
                        />
                    </Row>
                    <Row className="my-3">
                        {
                            conditionForm.parameters.length < 2
                            ?
                                conditionForm.type === 'member'
                                ?
                                    <Form.Label >{t('promotions.conditions.members')}</Form.Label>
                                :
                                    <Form.Label >{t('promotions.conditions.inscriptionsLabel')}</Form.Label>
                            :
                                <></>
                        }
                        {
                            conditionForm.parameters.map((parameter, index) => (
                                <Row key={index}>
                                    <Col xs={12}>

                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={`text-right ${parameter !== '' ? 'has-content' : ''}`}
                                                type="number"
                                                name='parameter'
                                                value={parameter}
                                                onChange={({target}) => {handleConditionFormParameterInput(target.value, index)}}
                                                required
                                            />
                                            {
                                                conditionForm.parameters.length > 1
                                                ?
                                                    index % 2 === 0
                                                    ?
                                                        <Form.Label>
                                                            {
                                                                conditionForm.type === "age"
                                                                ?
                                                                    t('promotions.conditions.minAge')
                                                                :
                                                                    t('promotions.conditions.members')
                                                            }
                                                        </Form.Label>
                                                    :
                                                        <Form.Label>{t('promotions.conditions.maxAge')}</Form.Label>
                                                :
                                                    <></>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6} className="ms-auto">
                            <Button variant="primary" className="w-100 mt-2" onClick={() => {handleSaveCondition()}}>{t('promotions.conditions.saveCondition')}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )    
}
