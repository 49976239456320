import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, CardBody} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import useAuth from '../../../../context/auth/useAuth'
import HeadConfigClub from '../../../../components/menus/SubMenuConfigClub'
import { getCommissionByClub, editCommissionClub } from '../../../../actions/settings/commissionsActions'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'

export default function Commissiondata({usertype}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {user} = useSelector(state=>state.users)
    const {commission, resultcommission} = useSelector(state=>state.commissions)
    const { club } = useSelector(state => state.clubs)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})

    useEffect(() => {
        if(clientauth && club){
            dispatch(getCommissionByClub(club?._id))
        }
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(commission){
            setFormState({
                activities: commission?.activities,
                sites: commission?.sites,
                ticketing: commission?.ticketing
            })
        }
    // eslint-disable-next-line
    }, [user, commission])

    const handleInputChange = ({target}, commissionType, index)=>{
        let newCommissionArray = formState[commissionType]
        const value = target.type === 'number' ? parseFloat(target.value) : target.value
        newCommissionArray[index][target.name] = value

        setFormState({
            ...formState,
            [commissionType]: newCommissionArray
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        dispatch(editCommissionClub(formState, commission._id))
        setIslogging(false)
    }

    useEffect(() => {
        if(resultcommission){
            let newresult = resultcommission
            dispatch({
                type: 'COMMISSION_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('club.commissionedited')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultcommission])

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="commissiondata" usertype={usertype}/>
                </Col>
            </Row>
            <Form className="formulario" onSubmit={handleEdit}>
                <Row className="my-3">
                    {['activities', 'sites', 'ticketing'].map((commissionType) => (
                        <Col xs={6} className='my-3' key={commissionType}>
                            <Card>
                                <CardBody>
                                    <h3 className='font-single'>{t('club.commission.' + commissionType)}</h3>
                                    {formState[commissionType]?.map((commissionSection, index) => (
                                        <Row key={index} className="my-3">
                                            <Col xs={12} className='py-2 mb-4 border-1 border-bottom fw-bold'>
                                                Rango {index +1}
                                            </Col>
                                            <Col xs={12} lg={3}>
                                                {index !== 0 ?
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className='has-content'
                                                            type='number'
                                                            name='lowPrice'
                                                            value={formState[commissionType][index]?.lowPrice || 0}
                                                            onChange={(e) =>handleInputChange(e, commissionType, index)}
                                                        />
                                                        <Form.Label htmlFor='lowPrice'>{t('club.commission.lowprice')}</Form.Label>
                                                        <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                    </Form.Group>
                                                :
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className='has-content'
                                                            type='text'
                                                            value='0'
                                                        />
                                                        <Form.Label htmlFor='lowPrice'>{t('club.commission.lowprice')}</Form.Label>
                                                    </Form.Group>

                                                }
                                            </Col>
                                            <Col xs={12} lg={3} className=''>
                                                {index !== 2 ?
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className='has-content'
                                                            type='number'
                                                            name='highPrice'
                                                            value={formState[commissionType][index]?.highPrice || 0}
                                                            onChange={(e) =>handleInputChange(e, commissionType, index)}
                                                        />
                                                        <Form.Label htmlFor='highPrice'>{t('club.commission.highprice')}</Form.Label>
                                                        <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                    </Form.Group>
                                                :
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className='has-content'
                                                            type='text'
                                                            value='∞'
                                                        />
                                                        <Form.Label htmlFor='lowPrice'>{t('club.commission.highprice')}</Form.Label>
                                                    </Form.Group>
                                                }
                                            </Col>
                                            <Col xs={12} lg={3}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className='has-content'
                                                        type='number'
                                                        name='percentage'
                                                        value={formState[commissionType][index]?.percentage || 0}
                                                        onChange={(e) =>handleInputChange(e, commissionType, index)}
                                                    />
                                                    <Form.Label htmlFor='percentage'>{t('club.commission.percentage')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} lg={3}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className='has-content'
                                                        type='number'
                                                        name='double'
                                                        value={formState[commissionType][index]?.double || 0}
                                                        onChange={(e) =>handleInputChange(e, commissionType, index)}
                                                    />
                                                    <Form.Label htmlFor='double'>{t('club.commission.double')}</Form.Label>
                                                    <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row className="mt-5">
                    <Col xs={12} lg={6} className='ms-auto'>
                        <Button variant='primary' type="submit" className="w-100 my-2">
                        {isUpdating
                            ?
                                <>
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </>
                            :
                                <>{t('global.update')}</>
                        }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}
