export const usersReducer = (state = {loading: false, resultuser: null, users: null, user: null, useractivate: null } , action) => {
    switch (action.type) {
        case 'USER_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                user: action.payload
            }
        case 'USER_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                resultuser: action.payload.message,
                useractivate: action.payload.idUser
            }
        case 'USER_EDIT_SUCCESS':
            if(action.payload.user){
                return {
                    ...state,
                    loading:false,
                    resultuser: action.payload.message,
                    user: action.payload.user
                }
            }else{
                return {
                    ...state,
                    loading:false,
                    resultuser: action.payload
                }
            }
        case 'USER_ADDMEMBER_RESULT':
            return {
                ...state,
                resultuser: action.payload.message
            }
        case 'USER_NOTHINGHERE':
            return {
                ...state,
                loading:false,
                resultuser: 'nothinghere'
            }
        case 'USER_RESET_RESULT':
            return {
                ...state,
                resultuser: null
            }
        case 'USER_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'USER_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

