import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer"
import QRCode from "qrcode"

Font.register({
	family: 'Northlane Two',
    src: '/fonts/Northlane/Northlane-Two.ttf',
    fontWeight: 'normal',
});

export default function Ticket({ticket}){

	const formatearFecha = (fechaISO) => {
		const fecha = new Date(fechaISO)
		const dia = fecha.getDate().toString().padStart(2, '0')
		const mes = (fecha.getMonth() + 1).toString().padStart(2, '0')
		const anio = fecha.getFullYear()
		return `${dia}/${mes}/${anio}`
	};

	const qrValue = ticket?.identifier
    const [qrImage, setQrImage] = React.useState(null)

    React.useEffect(() => {
        QRCode.toDataURL(qrValue)
            .then((url) => {
                setQrImage(url)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [qrValue])

	let styles = StyleSheet.create({
		pagina: {
			flexDirection: 'row',
			padding: 10
		},
		fondoVertical: {
			width: 400,
			height: 355,
			position: 'absolute',
			zIndex: 0
		},
		fondoHorizontal: {
			width: 355,
			height: 400,
			position: 'absolute',
			zIndex: 0
		},
		contenedorFondo:{
			display: 'flex',
			width: '100%',
			height: '100%',
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden'
		},
        imagenFondo: {
			width: '100%',
			height: '100%',
			objectFit: 'cover'
        },
		templateVertical:{
			width: 400,
			height: 650,
			position: 'absolute',
		},
		templateHorizontal:{
			width: 650,
			height: 400,
			position: 'absolute',
		},
		escudoVertical: {
			width: 100,
            height: 100,
            overflow: 'hidden',
            position: 'absolute',
            left: 40,
            top: 320
        },
		escudo2Vertical: {
			width: 100,
            height: 100,
            overflow: 'hidden',
            position: 'absolute',
            left: 265,
            top: 320
        },
		escudoHorizontal: {

        },
		escudo2Horizontal: {

        },
		contenedorEscudo:{
			display: 'flex',
			width: '100%',
			height: '100%',
			justifyContent: 'center',
			alignItems: 'center'
		},
        imagenEscudo: {
			height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
        },
		hostVertical:{
			textAlign: 'center',
			width: 120,
            height: 80,
            position: 'absolute',
            left: 30,
            top: 425
		},
		hostHorizontal:{

		},
		visitorVertical:{
			textAlign: 'center',
			width: 120,
            height: 80,
            position: 'absolute',
            left: 255,
            top: 425
		},
		visitorHorizontal:{

		},
		jornadaVertical:{
			textAlign: 'center',
			width: 90,
            height: 100,
            position: 'absolute',
            left: 160,
            top: 380
		},
		jornadaHorizontal:{

		},
		contenedorDetalle:{
			borderTop: '1px solid #000',
			borderBottom: '1px solid #000',
			width: 400,
			padding: 5,
            position: 'absolute',
            left: 0,
            top: 459
		},
		contenedorSector:{
			borderBottom: '1px solid #000',
			width: 133,
			height: 32,
			padding: 5,
            position: 'absolute',
            left: 0,
            top: 492
		},
		contenedorTipo: {
			borderBottom: '1px solid #000',
			borderLeft: '1px solid #000',
			borderRight: '1px solid #000',
			width: 134,
			height: 32,
			padding: 5,
            position: 'absolute',
            left: 133,
            top: 492
        },
		contenedorAsiento:{
			borderBottom: '1px solid #000',
			width: 133,
			height: 32,
			padding: 5,
            position: 'absolute',
            left: 267,
            top: 492
		},
		contenedorFecha:{
			borderBottom: '1px solid #000',
			width: 133,
			height: 32,
			padding: 5,
            position: 'absolute',
            left: 0,
            top: 524
		},
		contenedorHora:{
			borderBottom: '1px solid #000',
			borderLeft: '1px solid #000',
			borderRight: '1px solid #000',
			width: 134,
			height: 32,
			padding: 5,
            position: 'absolute',
            left: 133,
            top: 524
		},
		contenedorPrecio:{
			borderBottom: '1px solid #000',
			width: 133,
			height: 32,
			padding: 5,
            position: 'absolute',
            left: 267,
            top: 524
		},
		contenedorTextoQR:{
			width: 80,
            position: 'absolute',
            left: 310,
            top: 560
		},
		contenedorQR:{
			width: 80,
            height: 80,
            overflow: 'hidden',
            position: 'absolute',
            left: 310,
            top: 565
		},
		contenedorLogo:{
			width: 80,
            height: 80,
            overflow: 'hidden',
            position: 'absolute',
            left: 20,
            top: 565
		},
        textoEquipos: {
            fontSize: 12,
			fontWeight: 'bold',
            color: '#000',
			textAlign: 'center'
        },
		textoJornada: {
            fontSize: 8,
            color: '#000',
			textAlign: 'center',
			textTransform: 'uppercase'
        },
		textoVS: {
            fontSize: 18,
			fontFamily:'Northlane Two',
            color: '#000',
			textAlign: 'center'
        },
		textoDetalle: {
            fontSize: 14,
			fontFamily:'Northlane Two',
            color: '#000',
			textAlign: 'center'
        },
		textoNormal: {
            fontSize: 12,
			marginBottom:2,
            color: '#000'
        },
		textoQr: {
            fontSize: 8,
			textAlign: 'center',
            color: '#000'
        },
		numero: {
            fontSize: 5,
            color: '#000'
        },
		tipo:{
			fontSize: 5,
            color: '#000'
		},
		logo:{
			height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
		},
		qr:{
			height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
		}
	})

	const orientation = ticket?.match?.ticketOrientation
	const widthTicket = ticket?.match?.ticketOrientation ? 670 : 420
	const heightTicket = ticket?.match?.ticketOrientation ? 420 : 670
	const template = ticket?.match?.ticketOrientation ? '/images/templateTicketHorizontal.png' : '/images/templateTicketVertical.png'

	const matchTemplate = ticket?.match?.template ? `${ticket?.match?.template}?v=1` : undefined
	const hostBadge = ticket?.match?.hostBadge  ? `${ticket?.match?.hostBadge}?v=1` : undefined
	const visitorBadge = ticket?.match?.visitorBadge ? `${ticket?.match?.visitorBadge}?v=1` : undefined
	const thumbnail = ticket?.match?.thumbnail ? `${ticket?.match?.thumbnail}?v=1` : undefined

	return (
		<Document>
            <Page size={{width: widthTicket, height: heightTicket}} style={styles.pagina}>
				<View>
					<View style={orientation ? styles.fondoHorizontal : styles.fondoVertical}>
						<View style={styles.contenedorFondo}>
							<Image style={styles.imagenFondo} src={matchTemplate} />
						</View>
					</View>
					<Image style={orientation ? styles.templateHorizontal : styles.templateVertical} src={template} />
					<View style={orientation ? styles.escudoHorizontal : styles.escudoVertical}>
						<View style={styles.contenedorEscudo}>
							<Image style={styles.imagenEscudo} src={hostBadge} />
						</View>
					</View>
					{
						ticket?.match?.visitorBadge
						?
							<View style={orientation ? styles.escudo2Horizontal : styles.escudo2Vertical}>
								<View style={styles.contenedorEscudo}>
									<Image style={styles.imagenEscudo} src={visitorBadge} />
								</View>
							</View>
						:
							<></>
					}
					<View style={orientation ? styles.hostHorizontal : styles.hostVertical}>
						<Text style={styles.textoEquipos}>{ticket?.match?.host}</Text>
					</View>
					<View style={orientation ? styles.jornadaHorizontal : styles.jornadaVertical}>
						<Text style={styles.textoJornada}>{ticket?.match?.day}</Text>
						<Text style={styles.textoVS}>vs</Text>
					</View>
					<View style={orientation ? styles.visitorHorizontal : styles.visitorVertical}>
						<Text style={styles.textoEquipos}>{ticket?.match?.visitor}</Text>
					</View>
					<View style={styles.contenedorDetalle}>
						<Text style={styles.textoDetalle}>DETALLES DE LA ENTRADA</Text>
					</View>
					<View style={styles.contenedorSector}>
						<Text style={styles.textoNormal}>SECTOR:</Text>
						<Text style={styles.textoNormal}>{ticket?.sector?.name}</Text>
					</View>
					<View style={styles.contenedorTipo}>
						<Text style={styles.textoNormal}>TIPO:</Text>
						<Text style={styles.textoNormal}>{ticket?.fee?.name}</Text>
					</View>
					<View style={styles.contenedorAsiento}>
						<Text style={styles.textoNormal}>ASIENTO:</Text>
						<Text style={styles.textoNormal}></Text>
					</View>
					<View style={styles.contenedorFecha}>
						<Text style={styles.textoNormal}>FECHA:</Text>
						<Text style={styles.textoNormal}>{formatearFecha(ticket?.match?.date)}</Text>
					</View>
					<View style={styles.contenedorHora}>
						<Text style={styles.textoNormal}>HORA:</Text>
						<Text style={styles.textoNormal}>{ticket?.match?.hour}</Text>
					</View>
					<View style={styles.contenedorPrecio}>
						<Text style={styles.textoNormal}>PRECIO:</Text>
						<Text style={styles.textoNormal}>{ticket?.price} €</Text>
					</View>
					<View style={styles.contenedorLogo}>
						{
							ticket?.match?.thumbnail
							?
							<View>
								<Image style={styles.logo} src={thumbnail} />
							</View>
							:
							<></>
						}
					</View>
					<View style={styles.contenedorQR}>
						{qrImage && (
							<View>
								<Image style={styles.qr} src={qrImage} />
							</View>
						)}
					</View>
					{/* <View style={styles.contenedorTextoQR}>
						<Text style={styles.textoQr}>{ticket?.identifier}</Text>
					</View> */}
				</View>
            </Page>
		</Document>
	)
}