import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getSuperadmin = (user) => async (dispatch) => {
    dispatch({
        type: 'SUPERADMIN_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/superadmin/get/'+ user, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SUPERADMIN_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SUPERADMIN_NOT_LOADING'
        })
    }
}