import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { addSector } from '../../../../actions/ticketing/sectorsActions'
import SectorForm from '../../../../components/forms/sectors/SectorForm'

export default function AddSector({usertype}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading} = useSelector(state=>state.sectors)
	const {club} = useSelector(state=>state.clubs)

    const [newSector, setNewSector] = useState({
        name: null,
        maxCapacity: '0',
        numerated: false,
        active: true,
        distribution: {
            rows: '0',
            columns: '0',
            numeration: '0',
            deleted: []
        }
    })

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newSector.name !== ''){
            if (newSector.maxCapacity !== '') {
                dispatch(addSector({...newSector, club: club._id}))
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.ok'),
                    icon: 'warning',
                    text: t('sectors.noMaxCapacity')
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('sectors.noName')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'SECTOR_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('sectors.addSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.sectors")}`)
                        }
                    })
                    break;
                case 'duplicateName':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('sectors.duplicateName')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sectors.createNewSector')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <SectorForm state={newSector} setState={setNewSector} statetype={'add'} usertype={usertype} isAdding={loading} submit={handleAdd}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}