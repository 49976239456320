export const reservationsReducer = (state = {loadingreservation: false, resultreservation: null, reservations: [], reservation: null, reservationsReserved: []}, action) => {
    switch (action.type) {
        case 'RESERVATION_LOADING':
            return {
                ...state,
                loadingreservation: true
            }
        case 'RESERVATION_NOT_LOADING':
            return {
                ...state,
                loadingreservation: false
            }
        case 'RESERVATION_GETALL_SUCCESS':
            return {
                ...state,
                loadingreservation: false,
                reservations: action.payload.reservations
            }
        case 'RESERVATION_ADD_SUCCESS':
            return {
                ...state,
                loadingreservation:false,
                resultreservation: action.payload.message,
                reservations: [...state.reservations, action.payload.reservation].filter(r => r !== null)
            }
        case 'RESERVATION_ADD_NOT_SUCCESS':
            return {
                ...state,
                loadingreservation:false,
                resultreservation: action.payload.message,
                reservationsReserved: action.payload.reservations
            }
        case 'RESERVATION_CANCEL_SUCCESS':
            return {
                ...state,
                reservations: state.reservations.map(reservation => {
                    if (action.payload.reservation.some(r => r._id === reservation._id)) {
                        return null
                    } else {
                        return reservation
                    }
                }).filter(r => r !== null)
            }
        case 'RESERVATION_RESET_RESULT':
            return {
                ...state,
                resultreservation: null
            }
        default:
            return state
    }
}