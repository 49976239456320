import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function CheckOutTicket({item}) {
    const { t } = useTranslation()

    const calculatePassTypePrice = (item) => {
        let priceToPay = 0

        if (item.ticket?.type === "pass") {
            priceToPay = item.match.passTypes?.find(p => p.passType === item.ticket.client?.passType)?.price || 0
        } else {
            priceToPay = item.match.sectors?.find(s => s.sector === item.ticket.sector._id)?.fees?.find(f => f.fee === item.ticket.fee._id)?.price || 0
        }

        if (priceToPay > 0) {
            const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
            return Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
        } else {
            return 0.00
        }
    }

    return (
        <Row className='border py-4 px-3 rounded my-5'>
            <Col xs={12} md={4}>
                {
                    item.match?.thumbnail
                    ?
                        <img src={item.match.thumbnail?.image || `/images/bannerclub.jpg`} alt={`${item.match.host} - ${item.match.visitor}`} className="w-100"/>
                    :
                        item.match?.hostBadge && item.match?.hostBadge !== "" && item.match?.visitorBadge && item.match?.visitorBadge !== ""
                        ?
                            <Row>
                                <Col>
                                    <img src={item.match.hostBadge || `/images/bannerclub.jpg`} alt={item.match.host} className="w-100"/>
                                </Col>
                                <Col>
                                    <img src={item.match.visitorBadge || `/images/bannerclub.jpg`} alt={item.match.visitor} className="w-100"/>
                                </Col>
                            </Row>
                        :
                            item.match.hostBadge && item.match.hostBadge !== ""
                            ?
                                <img src={item.match.hostBadge || `/images/bannerclub.jpg`} alt={item.match.host} className="w-100"/>
                            :
                                <img src="/images/bannerclub.jpg" alt={item.match.host} className="w-100"/>
                }
            </Col>
            <Col xs={12} md={8}>
                <Row className='mb-5'>
                    <Col xs={8}>
                        <h3>{item.match.host} - {item.match.visitor}</h3>
                    </Col>
                    <Col xs={4}>
                        <div className='border rounded group-price text-center py-3 fw-bold'>{calculatePassTypePrice(item)}€</div>
                    </Col>
                </Row>
                {
                    item.ticket.type === "normal"
                        ?
                            <>
                                <div className='mt-3'>
                                    <div className='d-flex justify-content-between align-items-end'>
                                        <span className='fw-bold'>{t("matches.sector")}</span>
                                        <span>{item.ticket.sector?.name}</span>
                                    </div>
                                    <hr className='border my-1'/>
                                </div>
                                <div className='mt-3'>
                                    <div className='d-flex justify-content-between align-items-end'>
                                        <span className='fw-bold'>{t("matches.client")}</span>
                                        <span>{`${item.ticket.client?.firstname || item.ticket.firstname} ${item.ticket.client?.lastname || item.ticket.lastname}`}</span>
                                    </div>
                                    <hr className='border my-1'/>
                                </div>
                            </>
                        :
                            <div className='mt-3'>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <span className='fw-bold'>{t("matches.passPerson")}</span>
                                    <span>{`${item.ticket.client?.name || item.ticket.client?.firstname} ${item.ticket.client?.lastname || ''}`}</span>
                                </div>
                                <hr className='border my-1'/>
                            </div>
                }
            </Col>
        </Row>
    )
}