export const categoriesReducer = (state = {loadingcategory: true, resultcategory: null, categories: null, categoryToImport: null, category: null } , action) => {
    switch (action.type) {
        case 'CATEGORY_GET_SUCCESS':
            return {
                ...state,
                loadingcategory: false,
                category: action.payload.category
            }
        case 'CATEGORY_GETALL_SUCCESS':
            return {
                ...state,
                loadingcategory: false,
                categories: action.payload.categories
            }
        case 'CATEGORY_GETALL_IMPORTS_SUCCESS':
            return {
                ...state,
                loadingcategory: false,
                categoryToImport: action.payload.categories,
            }
        case 'CATEGORY_ADD_SUCCESS':
            let arraycategories = []
            state?.categories?.map(async (category) =>{
                arraycategories.push(category)
            })
            arraycategories.push(action.payload.category)

            return {
                ...state,
                loadingcategory:false,
                resultcategory: action.payload.message,
                category: action.payload.category,
                categories: arraycategories
            }
        case 'CATEGORY_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resultcategory: action.payload
            }
        case 'CATEGORY_IMPORT_SUCCESS':
            return {
                ...state,
                resultcategory: action.payload.message,
                categories: action.payload.categories
            }
        case 'CATEGORY_REMOVE_RESULT':
            return {...state,
                loading:false,
                resultcategory: action.payload.message,
                categories: state.categories.filter(categories => categories._id !== action.payload.category)
            }
        case 'CATEGORY_RESET_RESULT':
            return {
                ...state,
                resultcategory: null
            }
        case 'CATEGORY_LOADING':
            return {
                ...state,
                loadingcategory:true
            }
        case 'CATEGORY_NOT_LOADING':
            return {
                ...state,
                loadingcategory:false
            }
        default:
            return state
    }
}