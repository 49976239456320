import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import './translations/i18n'
import App from './App'
import store from './store'
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/Madrid')

window.jwt = ''

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<Provider store={store}>
		<App/>
	</Provider>
)