import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editInscription, getInscriptions,getInscriptionsByClub } from '../../../../actions/activities/inscriptionsActions'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import ActivitiesSubMenu from '../../../../components/menus/ActivitiesSubMenu'
import InscriptionSummary from '../../../../components/Activities/InscriptionSummary'
import Swal from 'sweetalert2'
import useAuth from '../../../../context/auth/useAuth'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'
import Loader from '../../../../components/design/Loader/Loader'

export default function Inscriptions({usertype, isclub, inscriptionselected, handleInscriptionSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {clientauth} = useAuth()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const {inscriptions, loadinginscriptions} = useSelector(state=>state.inscriptions)
    const {club} = useSelector(state=>state.clubs)

    const [typeform, setTypeform] = useState(null)

    const translatedInscriptions = inscriptions?.map(inscription => ({
        ...inscription,
        translatedStatus: t('inscriptions.status' + inscription.status),
    }))
    useEffect(() => {
        if(clientauth){
            if(isclub){
                dispatch(getInscriptionsByClub(club?._id))
            }else{
                dispatch(getInscriptions())
            }
        }
        // eslint-disable-next-line
    },[dispatch,clientauth,club,isclub])

    const handleSelectChange = (inscription, value) => {
        if(value==='3'){
            Swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: t('global.yes'),
                cancelButtonText: t('global.no'),
                icon: 'warning',
                text: t('inscriptions.cancelSecure')
            }).then((newresult) => {
                if (newresult.isConfirmed) {
                    dispatch(editInscription(inscription, value, 'status', 'superadmin'))
                }
            })
        }else{
            dispatch(editInscription(inscription, value, 'status', 'superadmin'))
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('es-ES')
    }
	const columnsInscriptions = [
        {
            name: t('inscriptions.client'),
            cell: inscription =>
                <div
                    onClick={() => {
                        handleInscriptionSelect(inscription._id)
                        setShow(true)
                    }}
                    className='table-link'>
                    {inscription?.client?.firstname+' '+inscription?.client?.lastname}
                </div>,
            selector: (inscription) => inscription?.client?.firstname+' '+inscription?.client?.lastname,
            sortable: false,
        },{
            name: t('inscriptions.club'),
            cell: inscription =>
                <div
                    onClick={() => {
                        handleInscriptionSelect(inscription._id)
                        setShow(true)
                    }}
                    className='table-link'>
                    {inscription?.club?.name}
                </div>,
            selector: (inscription) => inscription?.club?.name,
            sortable: false,
        },{
            name: <>{t('groups.activity')} / {t('groups.category')}</>,
            cell: inscription =>
                <div
                    onClick={() => {
                        handleInscriptionSelect(inscription._id)
                        setShow(true)
                    }}
                    className='table-link'>
                    {inscription?.activity?.title} / {inscription?.category?.title}
                </div>,
            selector: (inscription) => inscription?.activity?.title+' / '+inscription?.category?.title,
            sortable: false,
        },{
            name: <>{t('inscriptions.group')} / {t('inscriptions.horary')}</>,
            cell: inscription =>
                <>{inscription?.group?.title} / {inscription?.horary?.name}</>,
            selector: (inscription) => inscription?.group?.title+' / '+inscription?.horary?.name,
            sortable: false,
        },{
            name: t('inscriptions.status'),
            center: 'true',
            cell: inscription => inscription.translatedStatus,
            selector: (inscription) => inscription.translatedStatus,
            sortable: true,
        },{
            name: t('inscriptions.paymentStatus'),
            center: 'true',
            cell: inscription =>
                !inscription.paymentStatus && inscription.paymentStatus !== 0
                    ?
                        <></>
                    :
                        <span className={`p-2 text-white ${inscription.paymentStatus === 0 ? 'bg-success' : inscription.paymentStatus === 1 ? 'bg-warning' : 'bg-danger'}`}>
                            {
                                inscription.paymentStatus === 0
                                    ?
                                        <i className='fas fa-check'/>
                                    :
                                        inscription.paymentStatus === 1
                                            ?
                                                <i className='fas fa-clock'/>
                                            :
                                                <i className='fas fa-x'/>
                            }
                        </span>
            ,
            selector: (inscription) => inscription.paymentStatus === 0 ? 'success' : inscription.paymentStatus === 1 ? 'warning' : 'danger',
            sortable: false,
        },{
            name: t('inscriptions.actions'),
            center: 'true',
            width:'120px',
            cell: inscription =>
                <div className='d-flex justify-content-around w-100'>
                    <div
                        onClick={() => {
                            handleInscriptionSelect(inscription._id)
                            setTypeform('view')
                            setShow(true)
                        }}
                        className='table-link me-2'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('inscriptions.seeinscription')}</Tooltip>}
                        >
                            <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16}/>
                        </OverlayTrigger>
                    </div>
                    <div
                        onClick={() => {
                            handleInscriptionSelect(inscription._id)
                            setTypeform('edit')
                            setShow(true)
                        }}
                        className='table-link'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('inscriptions.editinscription')}</Tooltip>}
                        >
                            <img className="cursor" src="/images/pencil.svg" alt="pencil" height={16}/>
                        </OverlayTrigger>
                    </div>
                    {inscription?.status === "0" || inscription?.status === "4" ?
                        <>
                            <OverlayTrigger placement="top"
                                overlay={<Tooltip id="tooltip">{t('inscriptions.approveinscription')}</Tooltip>}
                            >
                                <i className="fas fa-check px-2 cursor" onClick={() => handleSelectChange(inscription?._id, "1")}></i>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top"
                                overlay={<Tooltip id="tooltip">{t('inscriptions.cancelinscription')}</Tooltip>}
                            >
                                <i className="fas fa-xmark px-2 cursor" onClick={() => handleSelectChange(inscription?._id, "3")}></i>
                            </OverlayTrigger>
                        </>
                    :
                        inscription?.status !== "3" ?
                            <OverlayTrigger placement="top"
                                overlay={<Tooltip id="tooltip">{t('inscriptions.cancelinscription')}</Tooltip>}
                            >
                                <i className="fas fa-xmark px-2 cursor" onClick={() => handleSelectChange(inscription?._id, "3")}></i>
                            </OverlayTrigger>
                        :
                            ''
                    }
                </div>,
            selector: (inscription) => inscription?.group?.title,
            sortable: false,
        }
	]

    const ExpandedRow = ({ data }) => {
        return (
            <Container className='p-3'>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('users.birthdate')}</small> {formatDate(data?.client?.birthdate)}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('users.phone')}</small> {data?.client?.phone}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('users.emergencyphone')}</small> {data?.client?.emergencyPhone}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('users.email')}</small> {data?.clientEmail}
                        </div>
                        {data?.client?.identityDocuments?.length > 0
                        ?
                            <div className='d-flex flex-column border-1 border-bottom p-2'>
                                {data?.client?.identityDocuments?.map((doc, index) => (
                                    <div key={index} className='d-flex justify-content-between'>
                                        <small className='text-uppercase'>{doc.documentType}</small>{doc.documentNumber}
                                    </div>
                                ))}
                            </div>
                         :
                            <></>
                        }
                         {data?.client?.addresses?.length > 0
                        ?
                            <div className='d-flex flex-column border-1 border-bottom p-2'>
                                {data?.client?.addresses?.map((address, index) => (
                                    <div key={index} className='d-flex justify-content-between'>
                                        <small className='text-uppercase'>{t('menus.config.address')}</small>
                                        <div className='text-end'>
                                            {address.address}<br/>
                                            {address.town} ({address.province})<br/>
                                            {address.postalCode}
                                        </div>
                                    </div>
                                ))}
                            </div>
                         :
                            <></>
                        }
                    </Col>
                    <Col xs={12} lg={6}>
                        {data?.tutor1Name
                            ?
                                <>
                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                        <small>{t('users.tutor1Name')}</small> {data?.tutor1Name}
                                    </div>
                                    <div className='d-flex justify-content-between border-bottom p-2'>
                                        <small>{t('users.tutor1Phone')}:</small> {data?.tutor1Phone}
                                    </div>
                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                        <small>{t('users.tutor1Email')}</small> {data?.tutor1Email}
                                    </div>
                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                        <small>{t('users.tutor2Name')}</small> {data?.tutor2Name}
                                    </div>
                                    <div className='d-flex justify-content-between border-bottom p-2'>
                                        <small>{t('users.tutor2Phone')}:</small> {data?.tutor2Phone}
                                    </div>
                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                        <small>{t('users.tutor2Email')}</small> {data?.tutor2Email}
                                    </div>
                                </>
                            :
                                <></>
                        }
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <>
            <Container className='cuerpo'>
                {
                    isclub
                    ?
                        <Row>
                            <Col>
                                <ClubBreadCrumbs usertype={usertype} club={club} />
                            </Col>
                        </Row>

                    :
                        <></>
                }
                <Row>
                    <Col className='d-flex align-items-center'>
                        <h1 className='ms-2 my-0'>{t('menus.activities.inscriptions')}</h1>
                    </Col>
                </Row>
                {
                    isclub
                    ?
                        <Row className="my-3">
                            <Col>
                                <ActivitiesSubMenu usertype={usertype} active="inscriptions"/>
                            </Col>
                        </Row>
                    :
                        <></>
                }

                <Row className="my-3">
                    <Col>
                        <Card>
                            {loadinginscriptions
                            ?
                                <Loader/>
                            :
                                <Card.Body>
                                    <div className="my-3">
                                        <Row>
                                            <Col>
                                                {inscriptions?.length > 0 ?
                                                    <CustomDataTable 
                                                        columns={columnsInscriptions}
                                                        data={translatedInscriptions}
                                                        exportable={true}
                                                        printable={true}
                                                        expandedData={ExpandedRow}
                                                    />
                                                :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('inscriptions.thereisnotinscriptions')}</h4>
                                                        {t('inscriptions.thereisnotinscriptionsphrase')}
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('inscriptions.datainscription')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo px-5 py-4'>
                    <InscriptionSummary
                        usertype={usertype}
                        typeform={typeform}
                        inscriptionselected={inscriptionselected}
                        closeModal={handleClose}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}