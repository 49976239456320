import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotFoundPage from '../pages/error/404'
import { Container } from 'react-bootstrap'
import Dashboard from '../pages/client/Dashboard'
import Personaldata from '../pages/client/config/Personaldata'
import Socialdata from '../pages/client/config/Socialdata'
import Addressdata from '../pages/client/config/Addressdata'
import Medicaldata from '../pages/client/config/Medicaldata'
import Carddata from '../pages/client/config/Carddata'
import Accountdata from '../pages/client/config/Accountdata'
import Identitydata from '../pages/client/config/Identitydata'
import Members from '../pages/client/member/Members'
import RegisterMember from '../pages/client/member/RegisterMember'
import MenuSuperior from '../components/menus/MenuSuperior'
import SidebarClient from '../components/menus/SidebarClient'
import useAuth from '../context/auth/useAuth'
import { getClubsByUser } from '../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18next'
import Inscriptions from '../pages/client/activities/inscriptions/Inscriptions'
import Reservations from '../pages/client/sites/Reservations'
import Payments from '../pages/client/payments/payments'
import Passes from '../pages/client/ticketing/passes'
import Tickets from '../pages/client/ticketing/tickets'

const t = i18n.t.bind(i18n)

export default function ClientRouter () {
	const navigate = useNavigate()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {clubs} = useSelector(state=>state.clubs)

	const [usertype, setUsertype] = useState(null)
	const [selectedClientId, setSelectedClientId] = useState(null)
	const [selectedInscriptionId, setSelectedInscriptionId] = useState(null)
	const [isCollapsed, setIsCollapsed] = useState(false)

	const toggleSidebar = () => {
		setIsCollapsed(!isCollapsed)
	}

	const updateSessionStorage = (newValues) => {
		const currentData = JSON.parse(sessionStorage.getItem('dataIncrono')) || {}
		const updatedData = { ...currentData, ...newValues }
		sessionStorage.setItem('dataIncrono', JSON.stringify(updatedData))
	}

	const handleClientSelect = (clientId) => {
		setSelectedClientId(clientId)
		updateSessionStorage({ clientId })
	}

	const handleClubSelect = (clubId) => {
		updateSessionStorage({ clubId })
	}

	const handleInscriptionSelect = (inscriptionId) => {
        setSelectedInscriptionId(inscriptionId)
		updateSessionStorage({inscriptionId})
	}

    useEffect(() => {
		const storedData = sessionStorage.getItem('dataIncrono')
		if (storedData) {
			const { clientId, inscriptionId } = JSON.parse(storedData)
			if (clientId) setSelectedClientId(clientId)
			if (inscriptionId) setSelectedInscriptionId(inscriptionId)
		}
    }, [])

	useEffect(() => {
		let token = sessionStorage.getItem('jwt')

		if (!token) {
			navigate(`/${t("url.auth.login")}`)
		}
	}, [navigate])

	useEffect(() => {
		if(clientauth){
			setUsertype(clientauth?.usertype)
			dispatch(getClubsByUser(clientauth?.id))
		}
	}, [dispatch, clientauth])

	const clientRoutesAlias = {
		"": "dashboard",
		[t("url.client.dashboard")]: "dashboard",
		[t("url.client.personaldata")]: "personaldata",
		[t("url.client.socialdata")]: "socialdata",
		[t("url.client.addressdata")]: "addressdata",
		[t("url.client.medicaldata")]: "medicaldata",
		[t("url.client.carddata")]: "carddata",
		[t("url.client.accountdata")]: "accountdata",
		[t("url.client.identitydata")]: "identitydata",
		[t("url.client.members")]: "members",
		[t("url.client.registermember")]: "registermember",
		[t("url.client.inscriptions")]: "inscriptions",
		[t("url.client.inscription")]: "inscription",
		[t("url.client.reservations")]: "reservations",
		[t("url.client.payments")]: "payments",
		[t("url.client.passes")]: "passes",
		[t("url.client.tickets")]: "tickets",
	}

	const getComponentClient = (path) => {
		switch (path) {
			case "dashboard": return <Dashboard handleClientSelect={handleClientSelect} handleClubSelect={handleClubSelect}/>;

			case "personaldata": return <Personaldata usertype={usertype} clientselected={selectedClientId}/>;
			case "socialdata": return <Socialdata usertype={usertype} clientselected={selectedClientId}/>;
			case "addressdata": return <Addressdata usertype={usertype} clientselected={selectedClientId}/>;
			case "medicaldata": return <Medicaldata usertype={usertype} clientselected={selectedClientId}/>;
			case "carddata": return <Carddata usertype={usertype} clientselected={selectedClientId}/>;
			case "accountdata": return <Accountdata usertype={usertype} clientselected={selectedClientId}/>;
			case "identitydata": return <Identitydata usertype={usertype} clientselected={selectedClientId}/>;

			case "members": return <Members handleClientSelect={handleClientSelect}/>;
			case "registermember": return <RegisterMember/>;

			case "inscriptions": return <Inscriptions usertype={usertype} clientselected={selectedClientId} inscriptionselected={selectedInscriptionId} handleInscriptionSelect={handleInscriptionSelect}/>;

			case "reservations": return <Reservations usertype={usertype} clientselected={selectedClientId}/>;

			case "payments": return <Payments usertype={usertype} clientselected={selectedClientId}/>;

			case "passes": return <Passes usertype={usertype} clientSelected={selectedClientId}/>;

			case "tickets": return <Tickets usertype={usertype} clientSelected={selectedClientId}/>;

			default: return <NotFoundPage />;
		}
	}

	return (
		<Container fluid className='d-flex p-0'>
			<SidebarClient isCollapsed={isCollapsed} client={clientauth?.client} handleClientSelect={handleClientSelect}/>
			<Container fluid className={`contenedor ${isCollapsed ? 'expanded' : ''}`}>
				<MenuSuperior toggleSidebar={toggleSidebar} usertype={'client'} isCollapsed={isCollapsed} avatar={clientauth?.avatar ? clientauth?.avatar : "/images/avatar-client.png"} client={clientauth?.client} clubs={clubs}  handleClientSelect={handleClientSelect} handleClubSelect={handleClubSelect}/>
				<Routes>
					{Object.entries(clientRoutesAlias).map(([alias, path]) => (
						<Route key={alias} path={alias} element={getComponentClient(path)} />
					))}
					<Route path="*" element={<NotFoundPage />}/>
				</Routes>
			</Container>
		</Container>
	)
}