export const inscriptionsReducer = (state = {loadinginscription: true, loadinginscriptions: true, resultinscription: null, inscriptions: null, inscription: null } , action) => {
    switch (action.type) {
        case 'INSCRIPTION_GET_SUCCESS':
            return {
                ...state,
                loadinginscription: false,
                inscription: action.payload.inscription
            }
        case 'INSCRIPTION_GETALL_SUCCESS':
            return {
                ...state,
                loadinginscriptions: false,
                inscriptions: action.payload.inscriptions
            }
        case 'INSCRIPTION_EDIT_SUCCESS':
            if(action.payload.inscription){
                return {
                    ...state,
                    inscription: action.payload.inscription,
                    inscriptions: state.inscriptions?.map(inscription => {
                        if (inscription._id === action.payload.inscription._id) {
                            return action.payload.inscription
                        } else {
                            return inscription
                        }
                    })
                }
            } else {
                return state
            }
        case 'INSCRIPTION_RESET_RESULT':
            return {
                ...state,
                resultinscription: null
            }
        case 'INSCRIPTION_LOADING':
            return {
                ...state,
                loadinginscription:true
            }
        case 'INSCRIPTION_NOT_LOADING':
            return {
                ...state,
                loadinginscription:false
            }
        case 'INSCRIPTIONS_LOADING':
            return {
                ...state,
                loadinginscriptions:true
            }
        case 'INSCRIPTIONS_NOT_LOADING':
            return {
                ...state,
                loadinginscriptions:false
            }
        default:
            return state
    }
}