import Home from '../pages/marketplace/Home'
import Clubs from '../pages/marketplace/clubs/Clubs'
import Club from '../pages/marketplace/clubs/Club'
import Activities from '../pages/marketplace/activities/Activities'
import Activity from '../pages/marketplace/activities/Activity'
import ClubActivities from '../pages/marketplace/clubs/ClubActivities'
import ClubCourts from '../pages/marketplace/clubs/ClubCourts'
import Court from '../pages/marketplace/renting/Court'
import CheckOut from '../pages/marketplace/CheckOut'
import FinishedCart from '../pages/marketplace/finishedCart'
import CookiesPolicy from '../pages/marketplace/legalinformation/CookiesPolicy'
import LegalWarning from '../pages/marketplace/legalinformation/LegalWarning'
import PrivacyPolicy from '../pages/marketplace/legalinformation/PrivacyPolicy'
import Returns from '../pages/marketplace/legalinformation/Returns'
import Incrono from '../pages/marketplace/corporation/Incrono'
import i18n from 'i18next'
import ClubPassTypes from '../pages/marketplace/clubs/ClubPassTypes'
import PassType from '../pages/marketplace/passTypes/PassType'
import OperationOK from '../pages/marketplace/OperationOk'
import OperationKO from '../pages/marketplace/OperationKO'
import ClubMatches from '../pages/marketplace/clubs/ClubMatches'
import Match from '../pages/marketplace/matches/Match'
import Check from '../pages/club/ticketing/matches/Check'
import Download from '../pages/marketplace/Download'

const t = i18n.t.bind(i18n)
// Mapeo de alias a paths reales
export const aliasMapMarketplace = {
    "": "home",
    "/": "home",
    [t("url.marketplace.home")]: "home",
    [t("url.marketplace.clubs")]: "clubs",
    [`${t("url.marketplace.clubs")}/:clubUrl`]: "club",
    [t("url.marketplace.operationok")]: "operationOK",
    [t("url.marketplace.operationko")]: "operationKO",
    [t("url.marketplace.activities")]: "activities",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.activities")}`]: "clubActivities",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.activities")}/:activityUrl`]: "activity",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.courts")}`]: "clubCourts",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.courts")}/:courtUrl`]: "court",
    [t("url.marketplace.checkOut")]: "checkOut",
    [t("url.marketplace.finishedCart")]: "finishedCart",
    [`${t("url.marketplace.downloadcart")}/:cartId/:cartReference`]: "download",
    [`${t("url.marketplace.downloadpass")}/:passId/:passIdentifier`]: "download",
    [`${t("url.marketplace.downloadticket")}/:ticketId/:ticketIdentifier`]: "download",
    [`${t("url.marketplace.downloadInvitation")}/:invitationId/:invitationIdentifier`]: "download",
    [t("url.marketplace.incrono")]: "incrono",
    [t("url.marketplace.cookiespolicy")]: "cookiespolicy",
    [t("url.marketplace.legalwarning")]: "legalwarning",
    [t("url.marketplace.privacypolicy")]: "privacypolicy",
    [t("url.marketplace.returns")]: "returns",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.passTypes")}`]: "clubPassTypes",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.passTypes")}/:passTypeUrl`]: "passType",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.matches")}`]: "clubMatches",
    [`${t("url.marketplace.clubs")}/:clubUrl/${t("url.marketplace.matches")}/:matchUrl`]: "match",
    [`${t("url.marketplace.match")}/:selectedMatch/:identifier`]: "checkMatch"
}

// Función auxiliar para obtener el componente basado en el path real
export const getComponentMarketplace = (path) => {
    switch (path) {
        case "": return <Home />
        case "home": return <Home />
        case "clubs": return <Clubs />
        case "club": return <Club />
        case "activities": return <Activities />
        case "clubActivities": return <ClubActivities />
        case "activity": return <Activity />
        case "clubCourts": return <ClubCourts />
        case "court": return <Court />
        case "checkOut": return <CheckOut/>
        case "finishedCart": return <FinishedCart/>
        case "download": return <Download/>
        case "incrono": return <Incrono/>
        case "cookiespolicy": return <CookiesPolicy/>
        case "legalwarning": return <LegalWarning/>
        case "privacypolicy": return <PrivacyPolicy/>
        case "returns": return <Returns/>
        case "clubPassTypes": return <ClubPassTypes />
        case "passType": return <PassType />
        case "clubMatches": return <ClubMatches />
        case "match": return <Match />
        case "checkMatch": return <Check />
        case "operationOK": return <OperationOK />
        case "operationKO": return <OperationKO />
        default: return <Home />;
    }
}