import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable.js'
import useAuth from '../../../../context/auth/useAuth.js'
import { deletePassType, getPassTypesByClub } from '../../../../actions/ticketing/passTypesActions.js'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs.js'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu.js'
import Swal from 'sweetalert2'

export default function PassTypes({usertype, handlePassTypeSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {passTypes, result} = useSelector(state=>state.passTypes)
    const {club} = useSelector(state=>state.clubs)

    const handleDeletePassType = (passTypeId) => {
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.yes'),
            denyButtonText: t('global.no'),
            icon: 'warning',
            text: t('passTypes.deletePassTypeText')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePassType(passTypeId))
            }
        })
    }

    useEffect(() => {
        handlePassTypeSelect(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club?._id){
            dispatch(getPassTypesByClub(club?._id))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'passTypeDeleteSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text:  t('passTypes.deleteSuccessText')
                    })
                    dispatch(getPassTypesByClub(club?._id))
                    dispatch({
                        type: 'PASSTYPE_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result])

	const columnsPassTypes = [
        // {
        //     name: "ID",
        //     width: "180px",
        //     cell: passType =>
        //            <small>{passType._id}</small>,
        //     selector: (passType) => passType._id,
        //     sortable: true,
        // },
        {
            name: t('passTypes.name'),
            cell: passType =>
                <Link onClick={() => handlePassTypeSelect(passType._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}/${t("url.club.edit")}`} className={`table-link ${passType.valid ? '' : 'text-danger'}`}>
                    {passType.name}
                </Link>,
            selector: (passType) => passType.name,
            sortable: true,
        },{
			name: t('passTypes.sector'),
			cell: passType =>
				<Link onClick={() => handlePassTypeSelect(passType._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}/${t("url.club.edit")}`} className={`table-link ${passType.valid ? '' : 'text-danger'}`}>
					{passType.sector?.name}
				</Link>,
			selector: (passType) => passType.sector?.name,
			sortable: true,
		},{
			name: t('passTypes.season'),
			cell: passType => {
                const latestSeason = passType.seasons?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))[0]
                const seasonName = latestSeason ? latestSeason.season.name : '-'
                return (
                    <Link
                        onClick={() => handlePassTypeSelect(passType._id)}
                        to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}/${t("url.club.edit")}`}
                        className={`table-link ${passType.valid ? '' : 'text-danger'}`}
                    >
                        {seasonName}
                    </Link>
                )
            },
            selector: (passType) => {
                const latestSeason = passType.seasons?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))[0]; // Ordenar y obtener la más reciente
                return latestSeason ? latestSeason.season.name : '-'
            },
			sortable: true,
		},{
            name: t('passTypes.price'),
            center: 'true',
            cell: passType => {
                const latestSeason = passType.seasons?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))[0]; // Ordenar y obtener la más reciente
                return latestSeason ? `${latestSeason.price} €` : '-';
            },
            selector: (passType) => {
                const latestSeason = passType.seasons?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))[0];
                return latestSeason ? latestSeason.price : '-';
            },
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',  
            width:'120px',
			cell: passType =>
                <div className={`d-flex justify-content-around w-100 ${passType.valid ? '' : 'text-danger'}`}>
                    <Link onClick={() => handlePassTypeSelect(passType._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}/${t("url.club.edit")}`}>
                        <img src="/images/edit.svg" alt={t('global.edit')} width={15}></img>
                    </Link>
                    <Link onClick={() => handleDeletePassType(passType._id)}>
                        <img src="/images/trash.svg" alt="trash" width={15}/>
                    </Link>
                </div>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <TicketingSubMenu usertype={usertype} active="passTypes"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('passTypes.passTypesList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('passTypes.createPassType')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {passTypes?.length > 0 ?
                                            <CustomDataTable columns={columnsPassTypes} data={passTypes} exportable={false} printable={false}/>
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('passTypes.noPassTypes')}</h4>
                                                {t('passTypes.thereIsNotPassTypesPhrase')}
                                                <div className='mt-3'>
                                                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                                        {t('passTypes.createPassType')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}