import React from 'react'
import {Container, Button, Navbar, Dropdown, NavDropdown} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { Link } from 'react-router-dom'

export default function MenuAdmin( { toggleSidebar , isCollapsed} ) {
	const { t } = useTranslation()
	const {logout} = useAuth()
  	return (
		<Navbar expand="md" id="menu-superior" className='py-2 bg-white border-bottom border-2 border-dark'>
			<Container fluid>
				<Navbar.Brand className='d-block d-sm-none'>
					<Link to="/"><img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='ms-3' /></Link>
				</Navbar.Brand>
				<Navbar.Brand href="#" className='d-none d-sm-block'>
					<Button variant='default' className='btn-icono' onClick={toggleSidebar}>
						{isCollapsed ?
							<>
								<img src='/images/flechas.svg'  className='rotate-horizontal' alt='Flecha'/>
							</>
						:
							<>
								<img src='/images/flechas.svg' alt='Flecha'/>
							</>
						}
					</Button>
				</Navbar.Brand>
				<div className='d-flex'>
					<Dropdown className='ms-4'>
						<Dropdown.Toggle as="div" className='avatar-menu d-flex align-items-center justify-content-center'>
							<i className="fas fa-bars"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.dashboard")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.dashboard')}</Link>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.clubs")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.clubs')}</Link>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.clients")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.clients')}</Link>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.inscriptions")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.inscriptions')}</Link>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.passes")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.passes')}</Link>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.tickets")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.tickets')}</Link>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.payments")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.payments')}</Link>
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.logs")}/${t("url.admin.sepalogs")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.sepalogs')}</Link>
							<NavDropdown.Divider />
							<Link to={`/${t("url.admin.admin")}/${t("url.admin.config")}`} className='dropdown-item'>{t('menus.general.profile')}</Link>
							<Link to="/" className='dropdown-item' onClick={logout}>{t('menus.logout')}</Link>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</Container>
		</Navbar>
	)
}