import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getInscriptionsByClient } from '../../../../actions/activities/inscriptionsActions'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import InscriptionSummary from '../../../../components/Activities/InscriptionSummary'
import useAuth from '../../../../context/auth/useAuth'
import { Link } from 'react-router-dom'
import Loader from '../../../../components/design/Loader/Loader'

export default function Inscriptions({usertype, clientselected, inscriptionselected, handleInscriptionSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const {inscriptions, loadinginscriptions} = useSelector(state=>state.inscriptions)

    useEffect(() => {
        if(clientauth){
            dispatch(getInscriptionsByClient(clientselected))
        }
    },[dispatch, t, clientauth, clientselected])

	const columnsInscriptions = [
        {
            name: t('inscriptions.client'),
            cell: inscription =>
                <Link
                    onClick={() => {
                        handleInscriptionSelect(inscription._id)
                        setShow(true)
                    }}
                    className='table-link'>
                    {inscription?.client?.firstname} {inscription?.client?.lastname}
                </Link>,
            selector: (inscription) => inscription?.client?.firstname,
            sortable: false,
        },{
            name: t('inscriptions.club'),
            cell: inscription =>
                <Link
                    onClick={() => {
                        handleInscriptionSelect(inscription._id)
                        setShow(true)
                    }}
                    className='table-link'>
                    {inscription?.club?.name}
                </Link>,
            selector: (inscription) => inscription.name,
            sortable: false,
            hide: 'sm',

        },
        // {
        //     name: t('users.membertype'),
        //     cell: inscription =>
        //         <div>
        //             {inscription?.memberType ?
        //                 t('users.membertypes.'+inscription?.memberType)
        //             :
        //                 ''
        //             }
        //         </div>,
        //     selector: (inscription) => {
        //         const translatedMemberType = inscription?.memberType ? t('users.membertypes.' + inscription.memberType) : '';
        //         return translatedMemberType.toLowerCase();
        //     },
        //     sortable: true,
        // },
        {
            name: <>{t('groups.activity')} / {t('groups.category')}</>,
            cell: inscription =>
                <Link
                    onClick={() => {
                        handleInscriptionSelect(inscription._id)
                        setShow(true)
                    }}
                    className='table-link'>
                    {inscription?.activity?.title} / {inscription?.category?.title}
                </Link>,
            selector: (inscription) => inscription?.activity?.title,
            sortable: false,
        },{
            name: t('inscriptions.group'),
            cell: inscription =>
                inscription?.group?.title,
            selector: (inscription) => inscription?.group?.title,
            sortable: false,
            hide: 'sm',
        },{
            name: t('inscriptions.status'),
            center: 'true',
            cell: inscription =>
                t('inscriptions.status'+inscription?.status),
            selector: (inscription) => inscription?.status,
            sortable: false,
            hide: 'sm',
        },{
            name: t('inscriptions.paymentStatus'),
            center: 'true',
            cell: inscription =>
                !inscription.paymentStatus && inscription.paymentStatus !== 0
                    ?
                        <></>
                    :
                        <span className={`p-2 text-white ${inscription.paymentStatus === 0 ? 'bg-success' : inscription.paymentStatus === 1 ? 'bg-warning' : 'bg-danger'}`}>
                            {
                                inscription.paymentStatus === 0
                                    ?
                                        <i className='fas fa-check'/>
                                    :
                                        inscription.paymentStatus === 1
                                            ?
                                                <i className='fas fa-clock'/>
                                            :
                                                <i className='fas fa-x'/>
                            }
                        </span>
            ,
            selector: (inscription) => inscription.paymentStatus === 0 ? 'success' : inscription.paymentStatus === 1 ? 'warning' : 'danger',
            sortable: false,
            hide: 'sm',
        },{
            name: t('inscriptions.actions'),
            center: 'true',
            width:'80px',
            cell: inscription =>
                <Link
                    onClick={() => {
                        handleInscriptionSelect(inscription._id)
                        setShow(true)
                    }}
                    className='table-link'>
                    <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16}/>
                </Link>,
            selector: (inscription) => inscription?.group?.title,
            sortable: false,
        }
	]

    return (
        <>
            <Container className='cuerpo'>
                <Row>
                    <Col className='d-flex align-items-center'>
                        <h1 className='ms-2 my-0'>{t('menus.activities.inscriptions')}</h1>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Card>
                            {loadinginscriptions
                            ?
                                <Loader/>
                            :
                                <Card.Body>
                                    <div className="my-3">
                                        <Row>
                                            <Col>
                                                {inscriptions?.length > 0 ?
                                                    <CustomDataTable columns={columnsInscriptions} data={inscriptions} exportable={false} printable={false} />
                                                :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('inscriptions.thereisnotinscriptions')}</h4>
                                                        {t('inscriptions.thereisnotinscriptionsphrase')}
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('inscriptions.datainscription')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo px-5 py-4'>
                    <InscriptionSummary usertype={usertype} typeform={'view'} inscriptionselected={inscriptionselected}/>
                </Modal.Body>
            </Modal>
        </>
    )
}