import React, { useEffect } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import { changeInvitationStatus, getTicketsByInvitation } from '../../actions/ticketing/ticketsActions'
import Loader from '../design/Loader/Loader'
import MultipleTickets from '../downloads/MultipleTickets'

export default function InvitationSummary({usertype, invitationSelected, closeModal, formType}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {invitationTickets, invitationTicketsLoading} = useSelector(state=>state.ticket)

    const handleStatusChange = (status) => {
        dispatch(changeInvitationStatus(
            {
                match: invitationSelected.match._id,
                sector: invitationSelected.sector._id,
                email: invitationSelected.email,
                firstname: invitationSelected.firstname,
                lastname: invitationSelected.lastname,
                phone: invitationSelected.phone,
                status: invitationSelected.status
            },
            clientauth.id,
            status
        ))
    }

    useEffect(() => {
        if (invitationSelected) {
            dispatch(getTicketsByInvitation(invitationSelected.firstTicket))
        }
    }, [invitationSelected, dispatch])

    return (
        <Modal show={invitationSelected ? true : false} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('tickets.ticketData')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cuerpo px-5 py-4'>
                <Row className="mb-5">
                    <Col xs={12} md={8}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.club')}:</small>
                            <div className='text-end'>
                                {invitationSelected?.club?.name}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.match')}:</small>
                            <div className='text-end'>
                                {`${invitationSelected?.match?.host} - ${invitationSelected?.match?.visitor}`}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.date')}:</small>
                            <div className='text-end'>
                                {`${new Date(invitationSelected?.match?.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${invitationSelected?.match?.hour}`}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.client')}:</small>
                            <div className='text-end'>
                                {`${invitationSelected?.firstname} ${invitationSelected?.lastname || ''}`}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.contactData')}:</small>
                            <div className='text-end'>
                                {`${invitationSelected?.email} ${invitationSelected?.phone || ''}`}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('tickets.maxUses')}:</small>
                            <div className='text-end'>
                                {parseInt(invitationSelected?.totalMaxUses || 0) + parseInt(invitationSelected?.ticketCount || 0)}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className='p-2 text-center'>
                            <small>{t('tickets.status')}</small>
                            <div className='fw-bold'>
                                {
                                    invitationSelected?.status === 0 ? t('tickets.pending') :
                                    invitationSelected?.status === 1 ? t('tickets.active') :
                                    invitationSelected?.status === 2 ? t('tickets.canceled') :
                                    t('tickets.unknown')
                                }
                            </div>
                        </div>
                        {
                            formType === "edit" && (usertype === "club" || usertype === "superadmin")
                            ?
                                <div className='mt-4 text-center'>
                                    <small>{t('tickets.changeStatus')}</small>
                                    {
                                        (invitationSelected?.status === 0 || invitationSelected?.status === 2) &&
                                        <div className='btn btn-secondary p-1 my-3' onClick={() => handleStatusChange(1)}>{t('tickets.toActive')}</div>
                                    }
                                    {
                                        (invitationSelected?.status === 0 ||invitationSelected?.status === 1) &&
                                        <div className='btn btn-default p-1' onClick={() => handleStatusChange(2)}>{t('tickets.toCancelled')}</div>
                                    }
                                </div>
                            :
                                <></>
                        }
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col xs={12} md={6} className='ms-auto'>
                        {
                            invitationTicketsLoading
                            ?
                                <Button type='button' className='w-100 my-2' variant='default' disabled>
                                    <Loader/>
                                </Button>
                            :
                                <PDFDownloadLink document={<MultipleTickets tickets={invitationTickets}/>} fileName={`${invitationTickets?.reduce((sum, ticket) => { return sum + '-' + ticket.identifier }, '')}.pdf`}>
                                    <Button type='button' className='w-100 my-2' variant='default'>
                                        <img src='/images/download.svg' alt='download'/>
                                    </Button>
                                </PDFDownloadLink>
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
	)
}