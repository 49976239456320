import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getCourtsByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/court/getCourtsByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'COURT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const getCourtsPublicByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/court/public/getcourtspublicbyclub/'+ club)
        if (status === 200) {
            dispatch({
                type: 'COURT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const getCourtPublicByUrl = ({url, club}) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/court/public/getcourtbyurl/'+ url +'/'+ club)
        if (status === 200) {
            dispatch({
                type: 'COURT_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const getCourt = (courtId) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/court/getCourt/'+ courtId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'COURT_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const addCourt = (courtdata) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        if(courtdata.image !== null && courtdata.image !== undefined && courtdata.image !== '' && typeof courtdata.image !== 'string'){
            let formData = new FormData()
            formData.append('image', courtdata.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            courtdata.image = data
        }

        const { data, status} = await Axios.post('/api/court/register', courtdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'COURT_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const addCourtSchedulesAndBlockedDates = (courtdata, id) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/court/addScheduleAndBlockedDates/' + id, courtdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'COURT_EDITSCHEDULE_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const editCourtManagerData = (managerData, id) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/court/editCourtManagerData/' + id, managerData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'COURT_ADD_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const changeCourtStatus = (id, activeStatus) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/court/changeStatus/' + id, {active: activeStatus}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'COURT_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const editCourt = (courtData, courtId) => async (dispatch) => {
    dispatch({
        type: 'COURT_LOADING'
    })
    try {
        if(courtData.image !== null && courtData.image !== undefined && courtData.image !== '' && typeof courtData.image !== 'string'){
            let formData = new FormData()
            formData.append('image', courtData.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            courtData.image = data
        }

        const { data, status} = await Axios.post('/api/court/edit/' + courtId, courtData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'COURT_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}

export const removeCourt = (courtId) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/court/remove/' + courtId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'COURT_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COURT_NOT_LOADING'
        })
    }
}