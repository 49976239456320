import { useTranslation } from "react-i18next"

const FamiliarComponent = () => {
    const { t } = useTranslation()

    let familiar = 
    [
        { value: "fathermother", label: t('users.membertypes.fathermother') },
        { value: "sondaughter", label: t('users.membertypes.sondaughter') },
        { value: "brothersister", label: t('users.membertypes.brothersister') },
        { value: "grandfathergrandmother", label: t('users.membertypes.grandfathergrandmother') },
        { value: "grandsongranddaughter", label: t('users.membertypes.grandsongranddaughter') },
        { value: "great_grandfathergreat_grandmother", label: t('users.membertypes.great_grandfathergreat_grandmother') },
        { value: "great_grandsongreat_granddaughter", label: t('users.membertypes.great_grandsongreat_granddaughter') },
        { value: "uncleaunt", label: t('users.membertypes.uncleaunt') },
        { value: "nephewniece", label: t('users.membertypes.nephewniece') },
        { value: "cousin_malecousin_female", label: t('users.membertypes.cousin_malecousin_female') },
        { value: "father_in_lawmother_in_law", label: t('users.membertypes.father_in_lawmother_in_law') },
        { value: "son_in_lawdaughter_in_law", label: t('users.membertypes.son_in_lawdaughter_in_law') },
        { value: "brother_in_lawsister_in_law", label: t('users.membertypes.brother_in_lawsister_in_law') },
        { value: "stepfatherstepmother", label: t('users.membertypes.stepfatherstepmother') },
        { value: "stepsonstepdaughter", label: t('users.membertypes.stepsonstepdaughter') },
        { value: "adoptive_fatheradoptive_mother", label: t('users.membertypes.adoptive_fatheradoptive_mother') },
        { value: "adoptive_sonadoptive_daughter", label: t('users.membertypes.adoptive_sonadoptive_daughter') },
        { value: "adoptive_brotheradoptive_sister", label: t('users.membertypes.adoptive_brotheradoptive_sister') },
        { value: "friend_malefriend_female", label: t('users.membertypes.friend_malefriend_female') },
        { value: "partner", label: t('users.membertypes.partner') },
        { value: "coworker_malecoworker_female", label: t('users.membertypes.coworker_malecoworker_female') },
    ]

    familiar = familiar?.sort((a, b) => (a.label > b.label ? 1 : -1))

    return { 'familiar': familiar}
}

export default FamiliarComponent