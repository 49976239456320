export const cartReducer = (state = {items: JSON.parse(sessionStorage.getItem('cart') || '[]'), status: null, loading: false, cart: null, error: null}, action)=>{
    switch (action.type) {
        case 'CART_SET':
            return {
                ...state,
                items: action.payload.items,
                status: action.payload.action + 'Success'
            }
        case 'CART_STATUS_RESET':
            return {
                ...state,
                status: null
            }
        case 'CART_RESET': {
            return {
                ...state, items: []
            }
        }
        case 'CART_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'CART_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case 'CART_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                cart: action.payload,
                error: null
            }
        default:
            return state
    }
}