import React from "react";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer"
import QRCode from "qrcode"

export default function Pass({pass}){

	const qrValue = pass?.identifier
    const [qrImage, setQrImage] = React.useState(null)

    React.useEffect(() => {
        QRCode.toDataURL(qrValue)
            .then((url) => {
                setQrImage(url)
            })
            .catch((err) => {
                console.log(err)
            });
    }, [qrValue])

	let styles = StyleSheet.create({
		pagina: {
			flexDirection: 'row'
		},
		fondoImagen: {
			width: 241.3,
			height: 155.9,
			position: 'absolute',
			zIndex:0
		},
		cuadro:{
			backgroundColor: '#ffffffcc',
			border: '0.5px solid #444444',
			left: 11,
			top:99,
			height:46,
			position:'absolute',
			width:220
		},
		escudo: {
			width: 43,
            height: 43,
            overflow: 'hidden',
            position: 'absolute',
            left: 12,
            top: 101.7
        },
		contenedorEscudo:{
			display: 'flex',
			width: '100%',
			height: '100%',
			justifyContent: 'center',
			alignItems: 'center'
		},
        imagenEscudo: {
			height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
        },
		contenedorNombre: {
            position: 'absolute',
            left: 56,
            top: 102,
            width: 128,
            textAlign: 'center'
        },
		contenedorNumero: {
            position: 'absolute',
            left: 56,
            top: 114,
            width: 128,
            textAlign: 'center'
        },
		contenedorTipo: {
            position: 'absolute',
            left: 56,
            top: 128,
            width: 128,
            textAlign: 'center'
        },
		contenedorQR:{
			width: 43,
            height: 43,
            overflow: 'hidden',
            position: 'absolute',
            left: 187,
            top: 100
		},
        nombre: {
            fontSize: 6.5,
            color: '#000'
        },
		numero: {
            fontSize: 5,
            color: '#000'
        },
		tipo:{
			fontSize: 4,
            color: '#000'
		},
		qr:{
			height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
		}
	})

	const obverseTemplate = pass?.passType?.obverseTemplate ? `${pass.passType.obverseTemplate}?v=1` : undefined
	const avatar = pass?.club?.avatar  ? `${pass.club.avatar}?v=1` : undefined

	return (
		<Document>
			<Page size={{ width: 241.3, height: 155.9 }} style={styles.pagina}>
				<View>
					{/* Renderiza la imagen de fondo solo si existe */}
					{obverseTemplate && <Image style={styles.fondoImagen} src={obverseTemplate} />}
					
					<View style={styles.cuadro}></View>
					
					{/* Renderiza el escudo solo si existe */}
					{avatar && (
						<View style={styles.escudo}>
							<View style={styles.contenedorEscudo}>
								<Image style={styles.imagenEscudo} src={avatar} />
							</View>
						</View>
					)}
					
					<View style={styles.contenedorNombre}>
						<Text style={styles.nombre}>
							{pass?.client ? (
								<>
									{pass?.client?.firstname} {pass?.client?.lastname}
								</>
							) : (
								<>
									{pass?.personData?.firstname} {pass?.personData?.lastname}
								</>
							)}
						</Text>
					</View>
					
					<View style={styles.contenedorNumero}>
						<Text style={[styles.numero, { fontWeight: 'bold' }]}>NÚMERO SOCIO</Text>
						<Text style={styles.numero}>{pass?.passNumber}</Text>
					</View>
					
					<View style={styles.contenedorTipo}>
						<Text style={[styles.numero, { fontWeight: 'bold' }]}>TIPO DE ABONO</Text>
						<Text style={styles.tipo}>{pass?.passType?.name}</Text>
					</View>
					
					{/* Renderiza el código QR solo si existe */}
					{qrImage && (
						<View style={styles.contenedorQR}>
							<View>
								<Image style={styles.qr} src={qrImage} />
							</View>
						</View>
					)}
				</View>
			</Page>
		</Document>
	)	
}