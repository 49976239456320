import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { addSiteSchedulesAndBlockedDates, getSite } from '../../../../actions/sites/sitesActions'
import SitesEditSubMenu from '../../../../components/menus/SitesEditSubMenu'
import SiteFormStep2 from '../../../../components/forms/sites/SiteFormStep2'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'
import Swal from 'sweetalert2'

export default function EditSiteStep2({usertype, selectedSite}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {result, loading, site} = useSelector(state => state.sites)
    const {club} = useSelector(state=>state.clubs)

    const [siteData, setSiteData] = useState({
        days: [
            {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null},
        ],
        datesBlocked: [],
        deletedDays: [],
        deletedDatesBlocked: []
    })

    const {siteId} = JSON.parse(sessionStorage.getItem("dataIncrono"))


    const handleDayCheck = (target, index) => {
        setSiteData({
            ...siteData,
            days: siteData?.days?.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setSiteData({
            ...siteData,
            days: [...siteData.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, index) => {
        let deletedScheduleId = siteData.days.find((d, i) => i === index)._id
        setSiteData({
            ...siteData,
            days: siteData.days.filter((d, i) => i !== index),
            deletedDays: deletedScheduleId ? [...siteData.deletedDays, deletedScheduleId] : siteData.deletedDays
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setSiteData({
            ...siteData,
            days: siteData.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleAddBlockedRange = (range) => {
        setSiteData({
            ...siteData,
            datesBlocked: [...siteData.datesBlocked, {startDate: range[0], endDate: range[1]}]
        })
    }

    const handleRemoveBlockedRange = (index) => {
        let deletedBlockedRangeId = siteData.datesBlocked.find((d, i) => i === index)._id
        setSiteData({
            ...siteData,
            datesBlocked: siteData.datesBlocked.filter((d, i) => i !== index),
            deletedDatesBlocked: deletedBlockedRangeId ? [...siteData.deletedDatesBlocked, deletedBlockedRangeId] : siteData.deletedDatesBlocked
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addSiteSchedulesAndBlockedDates(siteData, selectedSite))
    }
    
    useEffect(()=>{
        if (site) {
            setSiteData({
                datesBlocked: site.datesBlocked.map(dB => {
                    return {...dB, startDate: new Date(dB.startDate), endDate: new Date(dB.endDate)}
                }),
                deletedDays: [],
                deletedDatesBlocked: []
            })
        }
    //eslint-disable-next-line
    }, [site])

    useEffect(() => {
        if(result){
            switch (result) {
                case 'success':
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('sites.editsuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.sites")}`)
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (siteId) {
            dispatch(getSite(siteId))
        }
    }, [siteId, dispatch])

	return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <SitesEditSubMenu usertype={usertype} active="editsitestep2"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('sites.editSite')}</h2>
                            <hr className='mb-4'/>
                            <SiteFormStep2 state={siteData} statetype={'edit'} usertype={usertype} isAdding={loading} submit={handleAdd} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange} handleAddBlockedRange={handleAddBlockedRange} handleRemoveBlockedRange={handleRemoveBlockedRange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}