import React from "react"
import { Card, Carousel } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import ActivityMarket from "../Activities/ActivityMarket"

export default function ActivitiesFeatured({activities}) {
    const { t } = useTranslation()

    return (
        <>
            <Card className='p-1 border-0 mb-3'>
                <Card.Body>
                    <h3 className="m-0">{t('club.activitiesfeatured') }</h3>
                </Card.Body>
            </Card>
            <Carousel className="activitiesFeatured">
                {activities?.map((activity, i) => (
                    <Carousel.Item key={i}>
                        <ActivityMarket activity={activity}/>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>


    )
}