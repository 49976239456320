import { Container, Row, Col } from "react-bootstrap"
import MenuMarketplace from "../../components/menus/MenuMarketplace"
import { useTranslation } from 'react-i18next'

export default function OperationKO() {
    const { t } = useTranslation()

    return(
        <>
            <MenuMarketplace section='operation'/>
            <Container className="vh-100 d-flex justify-content-center align-items-center">
                <Row>
                    <Col className="d-flex flex-column justify-content-center align-items-center">
                        <img src="/images/operationKO.svg" alt="Operation KO" />
                        <h2 className="my-3">{t("global.operationKO")}</h2>
                        <p dangerouslySetInnerHTML={{ __html: t("global.operationKOphrase") }} className="text-center"/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}