import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function SidebarAdmin({ isCollapsed }) {
	const { t } = useTranslation()

  	return (
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} id="sidebar">
			<Link to="/"><img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='mb-5 ms-3' /></Link>
			<Nav className="flex-column p-0">
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.dashboard")}`}><img src="/images/menus/dashboard.svg" alt={t('menus.general.dashboard')}/> {!isCollapsed && t('menus.general.dashboard')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.clubs")}`}><img src="/images/menus/clubs.svg" alt={t('menus.general.clubs')}/> {!isCollapsed && t('menus.general.clubs')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.clients")}`}><img src="/images/menus/members.svg" alt={t('menus.general.clients')}/> {!isCollapsed && t('menus.general.clients')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.inscriptions")}`}><img src="/images/menus/calendar.svg" alt={t('menus.general.inscriptions')}/> {!isCollapsed && t('menus.general.inscriptions')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.passes")}`}><img src="/images/menus/pass.svg" alt={t('menus.general.passes')}/> {!isCollapsed && t('menus.general.passes')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.tickets")}`}><img src="/images/menus/ticketing.svg" alt={t('menus.general.tickets')}/> {!isCollapsed && t('menus.general.tickets')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.payments")}`}><img src="/images/menus/payments.svg" alt={t('menus.general.payments')}/> {!isCollapsed && t('menus.general.payments')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.admin.admin")}/${t("url.admin.logs")}/${t("url.admin.sepalogs")}`}><img src="/images/menus/config.svg" alt={t('menus.general.sepalogs')}/> {!isCollapsed && t('menus.general.sepalogs')}</Link>
				</Nav.Item>
			</Nav>
		</div>
	)
}