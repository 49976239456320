import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function SitesSubMenu({usertype, active}) {
    const { t } = useTranslation()

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.sites')}</h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/site" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "sites"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.sites")}`}>{t('menus.sites.sitedata')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "editsitestep2"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.edit")}/${t("url.admin.step2")}` : `/${t("url.club.club")}/${t("url.club.sites")}/${t("url.club.edit")}/${t("url.club.step2")}`}>{t('menus.sites.horaries')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "editsitestep3"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.edit")}/${t("url.admin.step3")}` : `/${t("url.club.club")}/${t("url.club.sites")}/${t("url.club.edit")}/${t("url.club.step3")}`}>{t('menus.sites.manager')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}