import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { useDispatch, useSelector } from 'react-redux'
import { getActivitiesPublicByFilter, getSitesPublicForFilter, getSportsPublicForFilter } from '../../../actions/filtersActions'

export default function SearchActivities({ancho, filters = null}){
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {sitesfilter, sportsfilter} = useSelector(state=>state.filters)

    const [filterOptions, setFilterOptions] = useState({activity: '', province: null, sport: null})
    const [provincesOptions, setProvincesOptions] = useState([])
    const [sportsOptions, setSportsOptions] = useState([])

    useEffect(() =>{
        dispatch(getSitesPublicForFilter())
        dispatch(getSportsPublicForFilter())
    }, [dispatch])

    useEffect(() => {
        if (filters) {
            setFilterOptions(filters)
            dispatch(getActivitiesPublicByFilter(filters))
        }
    }, [filters])

    useEffect(() => {
        if (sitesfilter) {
            const uniqueProvinces = [...new Set(sitesfilter.map(site => site?.province))]
            let arrayOptionsProvinces = uniqueProvinces.map(province => ({label: province, value: province}))
            arrayOptionsProvinces.unshift({label: t('marketplace.allprovinces'), value: null});
            setProvincesOptions(arrayOptionsProvinces)
        }
    }, [sitesfilter, t])

    useEffect(() => {
        if (sportsfilter) {
            let arrayOptionsSports = sportsfilter.map(sport => ({label: t('sports.'+sport), value: sport}))
            arrayOptionsSports.unshift({label: t('marketplace.allsports'), value: null});
            setSportsOptions(arrayOptionsSports)
        }
    }, [sportsfilter, t])

    const handleInputChange = ({target})=>{
        setFilterOptions({
            ...filterOptions,
            'activity': target.value
        })
    }

    const handleSelectChange = (target, selecttype) => {
        setFilterOptions({
            ...filterOptions,
            [selecttype]: target.value
        })
    }

    const searchActivities = () => {
        dispatch(getActivitiesPublicByFilter(filterOptions))
    }

    const clearSearch = () => {
        setFilterOptions({activity: '', province: null, sport: null})
        dispatch(getActivitiesPublicByFilter({activity: '', province: null, sport: null}))
    }

    return (
        <Card.Body className='m-auto' style={{ width: ancho }}>
            <Row>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Control
                            type="text"
                            name="activity"
                            onChange={handleInputChange}
                            value={filterOptions?.activity}
                            placeholder={t('marketplace.activitysearch')}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control select has-content"
                            name="province"
                            options={provincesOptions}
                            value={provincesOptions?.find(option => option?.value === filterOptions?.province)}
                            placeholder={t('marketplace.province')}
                            onChange={(e) => handleSelectChange(e, 'province')}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control select has-content"
                            name= "sport"
                            options={sportsOptions}
                            value={sportsOptions?.find(option => option?.value === filterOptions?.sport)}
                            placeholder={t('marketplace.sport')}
                            onChange={(e) => handleSelectChange(e, 'sport')}
                        />
                    </Form.Group>
                </Col>
                <Col className='text-center d-flex align-items-center justify-content-around'>
                    <Button variant='secondary' type="submit" className="my-2 mx-1" onClick={() => searchActivities()}>
                        {t('marketplace.search')}
                    </Button>
                    {filterOptions.activity || filterOptions.province || filterOptions.sport ?
                        <div className="my-2 mx-1 cursor" onClick={() => clearSearch()}>
                            <img src="/images/trash.svg" alt="Limpiar" /> {t('marketplace.clearsearch')}
                        </div>
                    :
                        <></>
                    }
                </Col>
            </Row>
        </Card.Body>
    )
}