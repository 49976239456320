export const ticketReducer = (state = { loading: false, tickets: null, result: null, newTickets: null, invitationTickets: null, invitationTicketsLoading: false }, action)=>{
    switch (action.type) {
        case 'TICKET_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'TICKET_INVITATION_LOADING':
            return {
                ...state,
                invitationTicketsLoading: true
            }
        case 'TICKET_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'TICKET_INVITATION_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'TICKET_GET_ALL_SUCCESS':
            return {
                ...state,
                loading: false,
                tickets: action.payload.tickets
            }
        case 'TICKET_GET_INVITATION_SUCCESS':
            return {
                ...state,
                invitationTicketsLoading: false,
                invitationTickets: action.payload.tickets
            }
        case 'TICKET_CHANGE_STATUS_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'TICKET_CREATE_INVITATION_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'TICKET_CREATE_WINDOW_TICKET_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload.message,
                newTickets: action.payload.tickets
            }
        case 'TICKET_NEW_TICKETS_RESET':
            return {
                ...state,
                newTickets: null
            }
        case 'TICKET_CHANGE_STATUS_RESET':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}