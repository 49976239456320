import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'

export default function SidebarClubEmpty({client, handleClientSelect, handleClubSelect, isCollapsed }) {
	const { t } = useTranslation()

	const {logout} = useAuth()

  	return (
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} id="sidebar">
			<Link to="/"><img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='mb-5 ms-3' /></Link>
			<Nav className="flex-column p-0">
				<Nav.Item>
					<Link to={`/${t("url.club.club")}/${t("url.club.lobby")}`}><img src="/images/menus/dashboard.svg" alt={t('menus.general.dashboard')}/> {!isCollapsed && t('menus.general.mydashboard')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.club.club")}/${t("url.club.accountdata")}`} className='dropdown-item'><img src="/images/menus/config.svg" alt={t('menus.general.config')}/> {!isCollapsed && t('menus.general.accountdata')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={'/'} className='dropdown-item' onClick={() => handleClubSelect(null)}><img src="/images/cart.svg" alt={t('menus.general.marketplace')}/> {!isCollapsed && t('menus.general.marketplace')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/" className='dropdown-item' onClick={logout}><img src='/images/logout.svg' alt={t('menus.logout')} />{t('menus.logout')}</Link>
				</Nav.Item>
			</Nav>
		</div>
	)
}