import React,{useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'

export default function LegalWarning () {
    const { t } = useTranslation()

	return (
		<>
			<MenuMarketplace/>
			<Container className='market'>
				<Row>
					<Col>
						<h1>{t('legalinformation.legalwarning.title1')}</h1>
						<div className='my-3'>{t('legalinformation.legalwarning.description1a')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description1b')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description1c')} <a href="mailto:lopd@incrono.com" target="_blank" rel="noreferrer">lopd@incrono.com</a></div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle2')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description2a')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description2b')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle3')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description3a')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle4')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description4a')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle5')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description5a')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description5b')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description5c')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle6')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description6a')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description6b')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description6c')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description6d')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description6e')}</div>
						<ol type="I">
							<li className='my-2'>{t('legalinformation.legalwarning.description6f')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6g')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6h')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6i')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6j')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6k')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6l')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6m')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description6n')}
								<ol type="i">
									<li className='my-2'>{t('legalinformation.legalwarning.description6o')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6p')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6q')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6r')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6s')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6t')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6u')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6v')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6w')}</li>
									<li className='my-2'>{t('legalinformation.legalwarning.description6x')}</li>
								</ol>
							</li>
						</ol>
						<div className='my-3'>{t('legalinformation.legalwarning.description6y')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description6z')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle7')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description7a')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description7b')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description7c')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description7d')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description7e')}</div>
						<ol type="i">
							<li className='my-2'>{t('legalinformation.legalwarning.description7f')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description7g')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description7h')}</li>
							<li className='my-2'>{t('legalinformation.legalwarning.description7i')}</li>
						</ol>
						<div className='my-3'>{t('legalinformation.legalwarning.description7j')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description7k')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description7l')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle8')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description8a')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description8b')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description8c')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description8d')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle9')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description9a')} <Link to={`/${t("url.marketplace.cookiespolicy")}`} className="link">LINK</Link></div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle10')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description10a')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle11')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description11a')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle12')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description12a')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col>
						<h3>{t('legalinformation.legalwarning.subtitle13')}</h3>
						<div className='my-3'>{t('legalinformation.legalwarning.description13a')}</div>
						<div className='my-3'>{t('legalinformation.legalwarning.description13a')}</div>
					</Col>
				</Row>
			</Container>
			<FooterMarketplace className='m-0'/>
		</>
	)
}