import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function ActivitiesSubMenu({usertype, active}) {
    const { t } = useTranslation()

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/site" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "activities"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.activities")}` : `/${t("url.club.club")}/${t("url.club.activities")}`}>{t('menus.activities.activities')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "categories"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.categories")}` : `/${t("url.club.club")}/${t("url.club.categories")}`}>{t('menus.activities.categories')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "groups"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.groups")}` : `/${t("url.club.club")}/${t("url.club.groups")}`}>{t('menus.activities.groups')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "inscriptions"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.inscriptions")}` : `/${t("url.club.club")}/${t("url.club.inscriptions")}`}>{t('menus.activities.inscriptions')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "inscriptionscanceled"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.inscriptionscanceled")}` : `/${t("url.club.club")}/${t("url.club.inscriptionscanceled")}`}>{t('menus.activities.inscriptionscanceled')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}