export const filtersReducer = (state = {loading: false, result: null, clubsfiltered: null, clubsfilteredMarket: null, activitiesfilteredMarket: null, courtsfilteredMarket: null, sitesfilter: null, sportsfilter: null, passTypesFilteredMarket: null, matchesFilteredMarket: null}, action) => {
    switch (action.type) {
        case 'FILTER_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'FILTER_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'FILTER_GET_CLUBSFILTERED_SUCCESS':
            return {
                ...state,
                loading: false,
                clubsfilteredMarket: action.payload.clubs
            }
        case 'FILTER_GET_ACTIVITIESFILTERED_SUCCESS':
            return {
                ...state,
                loading: false,
                activitiesfilteredMarket: action.payload.activities
            }
        case 'FILTER_GET_COURTSFILTERED_SUCCESS':
            return {
                ...state,
                loading: false,
                courtsfilteredMarket: action.payload.courts
            }
        case 'FILTER_GET_SITES_SUCCESS':
            return {
                ...state,
                loading: false,
                sitesfilter: action.payload.sites,
                sportsfilter: action.payload.sports
            }
        case 'FILTER_GET_SPORTS_SUCCESS':
            return {
                ...state,
                loading: false,
                sportsfilter: action.payload.sports
            }
        case 'FILTER_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        case 'FILTER_GET_PASSTYPES_FILTERED_SUCCESS':
            return {
                ...state,
                loading: false,
                passTypesFilteredMarket: action.payload.passTypes
            }
        case 'FILTER_GET_MATCHES_FILTERED_SUCCESS':
            return {
                ...state,
                loading: false,
                matchesFilteredMarket: action.payload.matches
            }
        default:
            return state
    }
}