import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'

export default function MenuClubMarketplace({nuevaSeccion, clubUrl, activitybooking = false, renting = false, ticketing = false}) {
    const { t } = useTranslation()

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/home" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "info"} to={`/${t("url.marketplace.clubs")}/${clubUrl}`}>{t('menus.club.info')}</Nav.Link>
                        </Nav.Item>
                        {activitybooking &&
                            <Nav.Item as="li">
                                <Nav.Link as={Link} active={nuevaSeccion === "activities"} to={`/${t("url.marketplace.clubs")}/${clubUrl}/${t("url.marketplace.activities")}`}>{t('menus.club.activities')}</Nav.Link>
                            </Nav.Item>
}
                        {renting &&
                            <Nav.Item as="li">
                                <Nav.Link as={Link} active={nuevaSeccion === "renting"} to={`/${t("url.marketplace.clubs")}/${clubUrl}/${t("url.marketplace.courts")}`}>{t('menus.club.courts')}</Nav.Link>
                            </Nav.Item>
                        }
                        {ticketing &&
                            <>
                                <Nav.Item as="li">
                                    <Nav.Link as={Link} active={nuevaSeccion === "passTypes"} to={`/${t("url.marketplace.clubs")}/${clubUrl}/${t("url.marketplace.passTypes")}`}>{t('menus.club.passTypes')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link as={Link} active={nuevaSeccion === "matches"} to={`/${t("url.marketplace.clubs")}/${clubUrl}/${t("url.marketplace.matches")}`}>{t('menus.club.matches')}</Nav.Link>
                                </Nav.Item>
                            </>
                        }
                        {/* <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "ticketing"} to={`/${t("url.marketplace.clubs")}/${clubUrl}/${t("url.marketplace.ticketing")}`}>{t('menus.club.ticketing')}</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}