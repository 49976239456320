import { createContext, useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { useDispatch } from "react-redux"
import i18n from 'i18next'

const t = i18n.t.bind(i18n)
export const AuthContext = createContext()

export default function AuthProvider({children}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let token = sessionStorage.getItem('jwt')

    const [clientauth, setClientauth] = useState()

    /**
     * Loguea un usuario y genera el token
     * @param {*} userCredentials
     */
    const loginClient = async (userCredentials) => {
        const options = {
            headers: { "Access-Control-Allow-Origin": "*" },
        }
        const result = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/login/in', { username: userCredentials.username, password: userCredentials.password }, options)
        if(result?.data?.message !== 'notverified'){
            sessionStorage.setItem('jwt', result.data['token'])
            token = sessionStorage.getItem('jwt')
            if (token!=='undefined') {
                let roles = jwtDecode(token).roles
                let hasSuperadminRole = roles.some(role => role.profileType === 'Superadmin')
                let hasClubRole = roles.some(role => role.profileType === 'Club')

                if(hasSuperadminRole){
                    setClientauth({...jwtDecode(token), usertype: 'superadmin'})
                    navigate(`/${t("url.admin.admin")}/${t("url.admin.dashboard")}`)
                }else{
                    if(hasClubRole){
                        setClientauth({...jwtDecode(token), usertype: 'club'})
                        navigate(`/${t("url.club.club")}/${t("url.club.lobby")}`)
                    }else{
                        setClientauth({...jwtDecode(token), usertype: 'client'})
                        navigate('/')
                    }
                }
            } else {
                // Mostramos el popup informativo
                // TODO: Traducir esto
                Swal.fire({
                    showConfirmButton: true,
                    confirmButtonColor: '#3085d6',
                    buttonsStyling: false,                    
                    confirmButtonText: 'Cerrar',
                    icon: 'error',
                    text: 'Credenciales incorrectas'
                })          
            }
        }else{
            navigate(`/${t("url.auth.activation")}/`+result?.data?.idUser)
        }
    }

    // Desloguea un usuario eliminando token y seteando el usuario a null
    const logout = () => {
        setClientauth(null);
        dispatch({ type: 'USER_LOGOUT'})
        sessionStorage.removeItem('jwt')
        sessionStorage.removeItem('dataIncrono')
        navigate('/')
    }

    // Comprueba el estado del usuario y devuelve true o false
    const isLogged = () => !!clientauth;

    // Variables accesibles desde cualquier componente
    const contextValue = {clientauth, isLogged, loginClient, logout}

    /**
     * Usamos el useEffect para comprobar que el token no ha cambiado
     */
    // token = sessionStorage.getItem('jwt')
    useEffect(() =>{
        try {
            let roles = jwtDecode(token).roles
            let hasSuperadminRole = roles.some(role => role.profileType === 'Superadmin')
            let hasClubRole = roles.some(role => role.profileType === 'Club')

            if(hasSuperadminRole){
                setClientauth({...jwtDecode(token), usertype: 'superadmin'})
            }else{
                if(hasClubRole){
                    setClientauth({...jwtDecode(token), usertype: 'club'})
                }else{
                    setClientauth({...jwtDecode(token), usertype: 'client'})
                }
            }
        } catch (error) {
            setClientauth(null);
            sessionStorage.removeItem('jwt')
        }
    }, [token])

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
