import { Button, Col, Form, Modal, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Loader from "../../design/Loader/Loader"
import { createRef, useState } from "react"
// import SportsComponent from "../../sports"
import Select from "react-select"
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

export default function PassTypeForm({state, statetype, usertype, isAddingOrEditing, submit, setState, sectors, seasons, editorState, setEditorState, commission}) {
    const { t } = useTranslation()
    const imageRef = createRef("<HTMLInputElement>")
    const obverseTemplateRef = createRef("<HTMLInputElement>")
    const reverseTemplateRef = createRef("<HTMLInputElement>")

    // const {sports} = SportsComponent()

    const [showSeasonModal, setShowSeasonModal] = useState(false)
    const [seasonForm, setSeasonForm] = useState({
        season: null,
        price: null,
        startDate: null,
        endDate: null
    })

    const handleInputChange = ({target})=>{
        setState({
            ...state,
            [target.name]: target.value
        })
    }

    const handleImage = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.files[0]
        })
    }

    const handleSeasonInputChange = ({target}) => {
        setSeasonForm({
            ...seasonForm,
            [target.name]: target.value
        })
    }

    const handleSaveSeason = () => {
        if (state.seasons.some(season => season.season === seasonForm.season)) {
            setState({
                ...state,
                seasons: state.seasons.map(season => {
                    if (season.season === seasonForm.season) {
                        return seasonForm
                    } else {
                        return season
                    }
                })
            })
        } else {
            setState({
                ...state,
                seasons: [...state.seasons, seasonForm]
            })
        }
        setShowSeasonModal(false)
        setSeasonForm({
            season: null,
            price: null,
            passPrice: null
        })
    }

    const deleteSeason = (seasonId) => {
        setState({
            ...state,
            seasons: state.seasons?.filter(season => season.season !== seasonId)
        })
    }

    const editSeason = (seasonId) => {
        setSeasonForm(state.seasons?.find(season => season.season === seasonId))
        setShowSeasonModal(true)
    }

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState)

        setState({
            ...state,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const calculatePayment = (price) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }

        if (!isNaN(price)) {
            const ticketing = commission?.ticketing?.find(ticketing => price >= ticketing.lowPrice && price <= ticketing.highPrice);
            if (ticketing) {
                return {
                    price: price,
                    priceClient: price > 0 ? (Math.ceil(((price * (ticketing.percentage || 0) / 100) + (ticketing.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
                }
            } else {
                return {
                    price: price,
                    priceClient: '0.00'
                }
            }
        } else {
            return {
                price: '0.00',
                priceClient: '0.00'
            }
        }
    }

    return (
        <>
            <Form onSubmit={submit}>
                <Row>
                    <Col>
                        <h3 className="font-single">{t('passTypes.data')}</h3>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.name ? 'has-content' : ''}
                                type='text'
                                name='name'
                                value={state?.name ||  ''}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Label htmlFor='name'>{t('passTypes.name')}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.people ? 'has-content' : ''}
                                type='number'
                                name='people'
                                value={state?.people ||  ''}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Label htmlFor='people'>{t('passTypes.people')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    {/* <Col xs={12} md={6}>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name="sport"
                                options={sports}
                                placeholder={t('passTypes.sport')}
                                onChange={(e) => handleInputChange({target: {name: 'sport', value: e.value}})}
                                value={sports?.find(s => s.value === state.sport)}
                            />
                            <Form.Label htmlFor='sport'>{t('passTypes.sport')}</Form.Label>
                        </Form.Group>
                    </Col> */}
                    <Col xs={12}>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name="sector"
                                options={
                                    sectors?.map(sector => {
                                        return { value: sector._id, label: sector.name }
                                    })
                                }
                                placeholder={t('passTypes.sector')}
                                onChange={(e) => handleInputChange({target: {name: 'sector', value: e.value}})}
                                value={sectors?.find(sector => sector._id === state.sector) ? { value: sectors?.find(sector => sector._id === state.sector)._id, label: sectors?.find(sector => sector._id === state.sector).name } : null}
                            />
                            <Form.Label htmlFor='sectors'>{t('passTypes.sector')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12}>
                        <Form.Label htmlFor='description'>{t('passTypes.description')}</Form.Label>
                        <Editor
                            toolbar={{
                                options: ['inline', 'list', 'textAlign', 'link'],
                                inline: { options: ['bold', 'italic', 'underline'] },
                                list: { options: ['unordered', 'ordered'] },
                                textAlign: { options: ['left', 'center', 'right'] },
                                link: { options: ['link', 'unlink'] }
                            }}
                            editorState={editorState}
                            onEditorStateChange={handleEditorStateChange}
                        />
                    </Col>
                </Row>
                <Row className="my-3 align-items-center">
                    <Col xs={12} className="d-flex align-items-center">
                        <div className='avatar-cuadrado me-2'>
                            {state?.image
                                ?
                                    <img className="img-profile" alt="Preview" src={typeof state?.image === 'string' ? state?.image : URL.createObjectURL(state?.image)} />
                                :
                                    <div className="img-profile"></div>
                            }
                            <input 
                                className='custom-file-input'
                                style={{ display: 'none' }}
                                ref={imageRef}
                                type="file" 
                                name="image" 
                                onChange={handleImage} 
                            />
                        </div>
                        <div className="editaravatar" onClick={() => imageRef.current?.click()}>{t('passTypes.image')}</div>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6} className="d-flex align-items-center">
                        <div className='pass-template me-2'>
                            {state?.obverseTemplate
                                ?
                                    <img className="img-profile" alt="Preview" src={typeof state?.obverseTemplate === 'string' ? state?.obverseTemplate : URL.createObjectURL(state?.obverseTemplate)} />
                                :
                                    <div className="img-profile"></div>
                            }
                            <input 
                                className='custom-file-input' 
                                style={{ display: 'none' }}
                                ref={obverseTemplateRef}
                                type="file" 
                                name="obverseTemplate" 
                                onChange={handleImage} 
                            />
                        </div>
                        <div className="editaravatar" onClick={() => obverseTemplateRef.current?.click()}>{t('passTypes.obverseTemplate')}</div>
                    </Col>
                    <Col xs={12} md={6} className="d-flex align-items-center">
                        <div className='pass-template me-2'>
                            {state?.reverseTemplate
                                ?
                                    <img className="img-profile" alt="Preview" src={typeof state?.reverseTemplate === 'string' ? state?.reverseTemplate : URL.createObjectURL(state?.reverseTemplate)} />
                                :
                                    <div className="img-profile"></div>
                            }
                            <input 
                                className='custom-file-input' 
                                style={{ display: 'none' }}
                                ref={reverseTemplateRef}
                                type="file" 
                                name="reverseTemplate" 
                                onChange={handleImage}
                            />
                        </div>
                        <div className="editaravatar" onClick={() => reverseTemplateRef.current?.click()}>{t('passTypes.reverseTemplate')}</div>
                    </Col>
                </Row>
                <Row className="mt-5 mb-3">
                    <Col xs={12} className="my-3">
                        <div className="d-flex justify-content-between align-items-end">
                            <h3 className="font-single">{t('passTypes.seasons')}</h3>
                            <Button type="button" variant="default" onClick={() => {setShowSeasonModal(true)}}>
                                <img className="fas fa-plus" /> {t('passTypes.addSeason')}
                            </Button>
                        </div>
                        <hr className="fina"/>
                    </Col>
                    <Col xs={12} lg={11} className="mx-auto">
                        {
                            state.seasons?.length > 0
                            ?
                                <>
                                    <Row className='border-bottom border-1 border-dark pb-2'>
                                        <Col xs={3}>{t('passTypes.season')}</Col>
                                        <Col xs={2}>{t('passTypes.price')}</Col>
                                        <Col xs={3}>{t('passTypes.startDate')}</Col>
                                        <Col xs={3}>{t('passTypes.endDate')}</Col>
                                    </Row>
                                    {state.seasons.map((season, seasonIndex) => (
                                        <Row className="py-3 border-1 border-bottom" key={seasonIndex}>
                                            <Col xs={3} className="d-flex align-items-center">
                                                {seasons?.find(s => s._id === season.season || s._id.toString() === season.season)?.name}
                                            </Col>
                                            <Col xs={2} className="d-flex align-items-center">
                                                {season.price}€
                                            </Col>
                                            <Col xs={3} className="d-flex align-items-center">
                                                {new Date(season.startDate).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})}
                                            </Col>
                                            <Col xs={3} className="d-flex align-items-center">
                                                {new Date(season.endDate).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})}
                                            </Col>
                                            <Col xs={1}>
                                                <Link onClick={() => {deleteSeason(season.season)}} className="me-2">
                                                    <img src="/images/trash.svg" className="cursor" alt="Trash" />
                                                </Link>
                                                <Link onClick={() => {editSeason(season.season)}} className="ms-2">
                                                    <img src="/images/pencil.svg" className="cursor" alt="Pencil" />
                                                </Link>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            :
                                <></>
                        }
                    </Col>
                </Row>
                {isAddingOrEditing
                    ?
                        <Loader/>
                    :
                        <Row className="mt-5">
                            <Col xs={12} lg={6}>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}`} className="btn btn-default my-2 w-100">
                                    {t('global.cancel')}
                                </Link>
                            </Col>
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button type="submit" variant="primary" className='my-2 w-100'>
                                    {
                                        statetype === 'add' ? t('global.save') :
                                        statetype === 'edit' ? t('global.edit') : ''
                                    }
                                </Button>
                            </Col>
                        </Row>
                }
            </Form>
            <Modal
                show={showSeasonModal}
                onHide={() => {
                    setSeasonForm({
                        season: null,
                        price: null,
                        passPrice: null
                    })
                    setShowSeasonModal(false)
                }}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('passTypes.manageSeasons')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo p-5'>
                    <Row className="my-3">
                        <Col xs={12} md={6}>
                            <Form.Group className='form-group'>
                                <Select
                                    className="form-control select has-content"
                                    name="season"
                                    options={
                                        seasons?.filter(season => !state.seasons.some(s => s.season === season._id || s.season === season._id.toString())).map(season => {
                                            return { value: season._id, label: season.name }
                                        })
                                    }
                                    placeholder={t('passTypes.season')}
                                    onChange={(e) => handleSeasonInputChange({target: {name: 'season', value: e.value}})}
                                    value={seasons?.find(season => season._id === seasonForm.season) ? { value: seasons?.find(season => season._id === seasonForm.season)._id, label: seasons?.find(season => season._id === seasonForm.season).name } : null}
                                />
                                <Form.Label htmlFor='season'>{t('passTypes.season')}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <div>
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={seasonForm?.price ? 'has-content' : ''}
                                        type='number'
                                        name='price'
                                        value={seasonForm?.price ||  ''}
                                        onChange={handleSeasonInputChange}
                                        min={0}
                                        required
                                    />
                                    <Form.Label htmlFor='price'>{t('passTypes.price')}</Form.Label>
                                </Form.Group>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <small>{t('inscriptions.priceclient')}</small>
                                <small>{calculatePayment(seasonForm?.price || 0).priceClient}€</small>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <small>{t('inscriptions.finalprice')}</small>
                                <small>
                                    {(
                                        Number(seasonForm?.price) +
                                        Number(calculatePayment(seasonForm?.price || 0).priceClient)
                                    ).toFixed(2)}€
                                </small>
                            </div>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col xs={12} md={6}>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className='has-content'
                                    type='date'
                                    name='startDate'
                                    value={seasonForm?.startDate ||  ''}
                                    onChange={handleSeasonInputChange}
                                    required
                                />
                                <Form.Label htmlFor='startDate'>{t('passTypes.startDate')}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className='has-content'
                                    type='date'
                                    name='endDate'
                                    value={seasonForm?.endDate ||  ''}
                                    onChange={handleSeasonInputChange}
                                    required
                                />
                                <Form.Label htmlFor='endDate'>{t('passTypes.endDate')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6} className="ms-auto">
                            <Button variant="primary" className="w-100 mt-2" onClick={handleSaveSeason}>{t('passTypes.saveSeason')}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )    
}
