import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from "react-select"

export default function MemberSelect ({members, onChange, value, isClearable = false}){
    const { t } = useTranslation()

    const [clientOptions, setClientOptions] = useState([])
    useEffect(() => {
        if (members) {
            setClientOptions(members.map((member) => {
                return {...member, birthdate: member.birthdate ? new Date(member.birthdate) : null, label: `${member.firstname || member.name} ${member.lastname || member.surnames}`, value: member._id, imageUrl: member.avatar}
            }))
        }
    }, [members])

    return (
        <Form.Group className='form-group'>
            <Select
                options={clientOptions}
                value={value}
                onChange={onChange}
                components={{Option: CustomOption, SingleValue: SelectedOption}}
                isClearable={isClearable}
                className='form-control has-content'
            />
            <Form.Label>{t('payments.member')}</Form.Label>
        </Form.Group>
    )
}

const CustomOption = (props) => {
    const { innerRef, innerProps, data } = props
    return (
        <div className='member-select' ref={innerRef} {...innerProps}>
            {
                data.imageUrl &&
                    <img src={data.imageUrl} alt='' style={{width: 30, height: 30, marginRight: 10, borderRadius: 50}}/>
            }
            {data.label}
        </div>
    )
}

const SelectedOption = (props) => {
    const { innerRef, innerProps, data } = props
    return (
        <div ref={innerRef} {...innerProps} style={{gridArea: '1/1/2/3', alignItems: 'center'}}>
            {
                data.imageUrl &&
                    <img src={data.imageUrl} alt='' style={{width: 30, height: 30, marginRight: 10, borderRadius: 50}}/>
            }
            {data.label}
        </div>
    )
}