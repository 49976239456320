import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CourtMarket({court}) {
    const { t } = useTranslation()
    const backCardImage = {
        backgroundImage: court?.image ? `url(${court?.image})` : court?.sports?.length > 0 ? `url("/images/sports/${court?.sports[0]}.jpg")` : `url("/images/sports/other_sports.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    return (
        <Card className="miniatura">
            <Link to={`/${t("url.marketplace.clubs")}/${court?.site?.club?.url}/${t("url.marketplace.courts")}/${court?.url}`}>
                <div className="image" variant="top" style={backCardImage}></div>
            </Link>
            <Card.Body>
                <Card.Title>{court.name}</Card.Title>
                <Row className="mt-3">
                    <Col className="d-flex align-items-center">
                        <img src="/images/club.svg" alt="club" className="me-2"/>
                        {court?.site?.name}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="d-flex align-items-center justify-content-between flex-wrap">
                        <span className="price">
                            {`${t("global.from")} ${court?.minPrice.toFixed(2)}€`}
                        </span>
                        <Link
                            to={`/${t("url.marketplace.clubs")}/${court?.site?.club?.url}/${t("url.marketplace.courts")}/${court?.url}`}
                            className="btn btn-primary"
                        >
                            {t("courts.reserve")}
                            <img src="/images/rignt-arrow.svg" alt={t("courts.reserve")} className="ms-3"/>
                        </Link>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}