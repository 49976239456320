export const clientsReducer = (state = {loadingclient: false, resultclient: null, clients: null, client: null, cardResult: null } , action) => {
    switch (action.type) {
        case 'CLIENT_GETALL_SUCCESS':
            return {
                ...state,
                loadingclient:false,
                clients: action.payload.clients
            }
        case 'CLIENT_GET_SUCCESS':
            return {
                ...state,
                loadingclient:false,
                client: action.payload.client
            }
        case 'CLIENT_EDIT_SUCCESS':
            return {
                ...state,
                loadingclient:false,
                clients: state.clients?.map(client => {
                    if (client._id === action.payload.client) {
                        client.activeuser = action.payload.status
                        return client
                    } else {
                        return client
                    }
                })
            }
        case 'CLIENT_ADDMEMBER_SUCCESS':
            return {
                ...state,
                loading:false,
                resultclient: action.payload.message
            }
        case 'CLIENT_ACTIVATEDEACTIVATE_SUCCESS':
            return {
                ...state,
                loading:false,
                resultclient: action.payload.message
            }
        case 'CLIENT_RESET_RESULT':
            return {
                ...state,
                resultclient: null
            }
        case 'CLIENT_LOADING':
            return {
                ...state,
                loadingclient:true
            }
        case 'CLIENT_NOT_LOADING':
            return {
                ...state,
                loadingclient:false
            }
        case 'CLIENT_CARD_SUCCESS':
            return {
                ...state,
                cardResult: action.payload,
                loadingclient: false
            }
        case 'CLIENT_CARD_RESET':
            return {
                ...state,
                cardResult: null
            }
        default:
            return state
    }
}