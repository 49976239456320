import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CourtFormStep2 from '../../../../components/forms/courts/CourtFormStep2'
import { addCourtSchedulesAndBlockedDates, getCourt } from '../../../../actions/sites/courtsActions'
import CourtsEditSubMenu from '../../../../components/menus/CourtsEditSubMenu'
import Swal from 'sweetalert2'

export default function EditCourtStep2({usertype, selectedCourt}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {result, loadingcourt, court, scheduleWrong} = useSelector(state => state.courts)

    const [conflicts, setConflicts] = useState([])
    const [courtData, setCourtData] = useState({
        days: [
            {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null},
        ],
        datesBlocked: [],
        deletedDatesBlocked: [],
        deletedDays: []
    })

    const {courtId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    const handleDayCheck = (target, index) => {
        setCourtData({
            ...courtData,
            days: courtData?.days?.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setCourtData({
            ...courtData,
            days: [...courtData.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null},]
        })
    }

    const removeSchedule = (day, index) => {
        let deletedScheduleId = courtData.days.find((d, i) => i === index)._id
        setCourtData({
            ...courtData,
            days: courtData.days.filter((d, i) => i !== index),
            deletedDays: deletedScheduleId ? [...courtData.deletedDays, deletedScheduleId] : courtData.deletedDays
        })
    }

    const handleScheduleInputChange = (target, index) => {
        setCourtData({
            ...courtData,
            days: courtData.days.map((d, i) => {
                if (i === index) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleAddBlockedRange = (range) => {
        setCourtData({
            ...courtData,
            datesBlocked: [...courtData.datesBlocked, {startDate: range[0], endDate: range[1]}]
        })
    }

    const handleRemoveBlockedRange = (index) => {
        let deletedBlockedRangeId = courtData.datesBlocked.find((d, i) => i === index)._id
        setCourtData({
            ...courtData,
            datesBlocked: courtData.datesBlocked.filter((d, i) => i !== index),
            deletedDatesBlocked: deletedBlockedRangeId ? [...courtData.deletedDatesBlocked, deletedBlockedRangeId] : courtData.deletedDatesBlocked
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addCourtSchedulesAndBlockedDates(courtData, courtId))
    }

    const handleInputChange = ({target})=>{
        setCourtData({
            ...courtData,
            [target.name]: target.value
        })
    }

    const handleSchedulePeriodChange = (value, index) => {
        setCourtData({
            ...courtData,
            days: courtData.days.map((d, i) => {
                if (i === index) {
                    return {
                        ...d,
                        startDate: value ? value[0] : null,
                        endDate: value ? value[1] : null
                    }
                } else {
                    return d
                }
            })
        })
    }

    useEffect(() => {
        if(result){
            switch (result) {
                case 'success':
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    if(scheduleWrong?.length === 0){
                        Swal.fire({
                            showConfirmButton: true,
                            showDenyButton: false,
                            confirmButtonText: t('global.great'),
                            icon: 'success',
                            text: t('courts.editsuccess')
                        }).then(() => {
                            if(usertype === 'superadmin'){
                                navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}`)
                            }else{
                                navigate(`/${t("url.club.club")}/${t("url.club.courts")}`)
                            }
                        })
                    }else{
                        Swal.fire({
                            showConfirmButton: true,
                            showDenyButton: false,
                            confirmButtonText: t('global.great'),
                            icon: 'warning',
                            text: t('courts.editwrong')
                        })
                    }
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (courtId) {
            dispatch(getCourt(courtId))
        }
    }, [courtId, dispatch])

    useEffect(() => {
        setConflicts(scheduleWrong)
    }, [scheduleWrong])

    useEffect(()=>{
        async function setCourtInfo(court) {
            setCourtData({
                days: court.schedule?.map(schedule => {
                    return {...schedule, startDate: new Date(schedule.startDate), endDate: new Date(schedule.endDate)}
                }) || [{weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null}],
                datesBlocked: court.datesBlocked?.map(dateBlocked => {
                    return {...dateBlocked, startDate: new Date(dateBlocked.startDate), endDate: new Date(dateBlocked.endDate)}
                }) || [],
                deletedDatesBlocked: [],
                deletedDays: []
            })
        }

        if (court) {
            setCourtInfo(court)
        }
    //eslint-disable-next-line
    }, [court])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <CourtsEditSubMenu usertype={usertype} active="editcourtstep2"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('courts.editCourt')}</h2>
                            <hr className='mb-4'/>
                            <CourtFormStep2 state={courtData} statetype={'edit'} usertype={usertype} isAdding={loadingcourt} conflicts={conflicts} addSchedule={addSchedule} removeSchedule={removeSchedule} submit={handleAdd} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange} handleAddBlockedRange={handleAddBlockedRange} handleRemoveBlockedRange={handleRemoveBlockedRange} inputChange={handleInputChange} onPeriodChange={handleSchedulePeriodChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}