import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getActivity = (activity) => async (dispatch) => {
    dispatch({
        type: 'ACTIVITY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/activity/get/'+ activity, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'ACTIVITY_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}

export const getActivitiesPublic = () => async (dispatch) => {
    dispatch({
        type: 'ACTIVITY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/activity/public/getactivities/')
        if (status === 200) {
            dispatch({
                type: 'ACTIVITY_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}

export const getActivityPublicByUrl = ({url, club}) => async (dispatch) => {
    dispatch({
        type: 'ACTIVITY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/activity/public/getactivitybyurl/'+ url +'/'+ club)
        if (status === 200) {
            dispatch({
                type: 'ACTIVITY_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}

export const getActivitiesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'ACTIVITY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/activity/getactivitiesbyclub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'ACTIVITY_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}

export const getActivitiesPublicByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'ACTIVITY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/activity/public/getactivitiesbyclub/'+ club)
        if (status === 200) {
            dispatch({
                type: 'ACTIVITY_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}

export const addActivity = (activitydata) => async (dispatch) => {
    dispatch({
        type: 'ACTIVITY_LOADING'
    })
    try {
        if(activitydata.image !== null && activitydata.image !== undefined&& activitydata.image !== '' && typeof activitydata.image !== 'string'){
            let formData = new FormData()
            formData.append('image', activitydata.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            activitydata.image = data
        }

        const { data, status} = await Axios.post('/api/activity/register', activitydata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'ACTIVITY_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}

export const updateActivity = (activitydata, activityId) => async (dispatch) => {
    dispatch({
        type: 'ACTIVITY_LOADING'
    })
    try {
        if(activitydata.image !== null && activitydata.image !== undefined&& activitydata.image !== '' && typeof activitydata.image !== 'string'){
            let formData = new FormData()
            formData.append('image', activitydata.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            activitydata.image = data
        }

        const { data, status} = await Axios.put('/api/activity/edit/' + activityId, activitydata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'ACTIVITY_EDIT_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}

export const removeActivity = (activity) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/activity/remove/' + activity, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'ACTIVITY_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'ACTIVITY_NOT_LOADING'
        })
    }
}