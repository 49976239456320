import React, { useEffect, useState, createRef } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, Carousel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClub } from '../../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import ClubBreadCrumbs from '../../../components/breadcrumbs/ClubBreadCrumbs'

export default function Basicdata({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const fileRef = createRef("<HTMLInputElement>")
    const fileRefTwo = createRef("<HTMLInputElement>")

    const {user, resultuser} = useSelector(state=>state.users)
    const {club} = useSelector(state=>state.clubs)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})
    const [imageAux, setImage] = useState(null)
    const [bannerAux, setBanner] = useState(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [carouselImages, setCarouselImages] = useState([])
    const [newCarouselImages, setNewCarouselImages] = useState([])
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0)

    const [sponsorsImages, setSponsorsImages] = useState([])
    const [newSponsorsImages, setNewSponsorsImages] = useState([])
    const [activeSponsorIndex, setActiveSponsorIndex] = useState(0)
    const [sponsorsLinks, setSponsorsLinks] = useState([])
    const [newSponsorsLinks, setNewSponsorsLinks] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(club){
            if (club?.description) {
                const blocksFromHtml = htmlToDraft(club.description)
                const { contentBlocks, entityMap } = blocksFromHtml
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
            }

            setFormState({
                name: club?.name,
                avatar: club?.avatar,
                phone: club?.phone,
                description: club?.description,
                verified: club?.verified,
                bannerMarketplace: club?.bannerMarketplace,
                web: club?.web,
                active: club?.active,
                activitybooking: club?.activitybooking,
                ticketing: club?.ticketing,
                renting: club?.renting
            })

			setImage(club?.avatar)
            setCarouselImages(club?.carouselImages || [])
            setSponsorsImages(club?.sponsors || [])
            setSponsorsLinks(club?.sponsorsLinks || [])
        }
    // eslint-disable-next-line
    }, [user, club])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setFormState({
            ...formState,
            [target.name]: target.checked
        })
    }

	const changeImage = (e) => {
		setImage(e.target.files[0])
	}

	const changeBanner = (e) => {
		setBanner(e.target.files[0])
	}

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState)

        setFormState({
            ...formState,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)

        const allSponsorsLinks = sponsorsLinks.concat(newSponsorsLinks);

        dispatch(editUserClub({
            ...formState,
            sponsorsLinks: allSponsorsLinks
        }, club._id, imageAux, bannerAux, carouselImages.concat(newCarouselImages), sponsorsImages.concat(newSponsorsImages), null, null))
        setIslogging(false)
    }

    const handleCarouselImageChange = (e) => {
        const files = Array.from(e.target.files)
        setNewCarouselImages([...newCarouselImages, ...files])
    }

    const handleRemoveCarouselImage = (index, img) => {
        if (typeof img === 'string') {
            setCarouselImages(carouselImages.filter((_, i) => i !== index))
        } else {
            setNewCarouselImages(newCarouselImages.filter((_, i) => i !== index))
        }

        if (index >= carouselImages.concat(newCarouselImages).length - 1) {
            setActiveCarouselIndex(prevIndex => Math.max(0, prevIndex - 1))
        }
    }

    const handleSponsorsImageChange = (e) => {
        const files = Array.from(e.target.files);
        const newLinks = Array(files.length).fill(""); // Crea un array con enlaces vacíos
    
        setNewSponsorsImages(prevImages => [...prevImages, ...files]);
        setNewSponsorsLinks(prevLinks => [...prevLinks, ...newLinks]); // Añade los enlaces vacíos
    }

    const handleSponsorsLinkChange = (index, link) => {
        const updatedLinks = [...sponsorsLinks];
        updatedLinks[index] = link;
        setSponsorsLinks(updatedLinks);
    }

    const handleRemoveSponsorImage = (index, img) => {
        if (typeof img === 'string') {
            setSponsorsImages(prevImages => prevImages.filter((_, i) => i !== index));
            setSponsorsLinks(prevLinks => prevLinks.filter((_, i) => i !== index));
        } else {
            setNewSponsorsImages(prevImages => prevImages.filter((_, i) => i !== index));
            setNewSponsorsLinks(prevLinks => prevLinks.filter((_, i) => i !== index));
        }
    
        if (index >= sponsorsImages.concat(newSponsorsImages).length - 1) {
            setActiveSponsorIndex(prevIndex => Math.max(0, prevIndex - 1));
        }
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('club.clubedited')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="basicdata" usertype={usertype}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('club.editaccount')}</h2>
                            <hr className='mb-4'/>
                            <Row>
                                <Col xs={12} className='mx-auto'>
                                    <Form className="formulario" onSubmit={handleEdit}>
                                        <Row className="mt-3 mb-4">
                                            <Col xs={12} lg={6} className='d-flex align-items-center'>
                                                <div className='avatar me-2'>
                                                    {imageAux
                                                        ?
                                                            <img className="img-profile" alt="Preview" width={100} height={100} src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} />
                                                        :
                                                            formState.avatar
                                                                ?
                                                                    <img className="img-profile" alt="Preview" src={typeof formState.avatar === 'string' ? formState.avatar : URL.createObjectURL(formState.avatar)} />
                                                                :
                                                                    <div className="img-profile"></div>
                                                    }
                                                
                                                    <input 
                                                        className='custom-file-input' 
                                                        style={{ display: 'none' }}
                                                        ref={fileRef}
                                                        type="file" 
                                                        name="myImage" 
                                                        onChange={changeImage} 
                                                    />
                                                </div>
                                                <div className="editaravatar" onClick={() => fileRef.current?.click()}>{t('club.avatar')}</div>
                                            </Col>
                                            <Col xs={12} lg={6} className='d-flex align-items-center'>
                                                <div className='bannerclub me-2'>
                                                    {bannerAux
                                                        ?
                                                            <img className="img-profile" alt="Preview" src={typeof bannerAux === 'string' ? bannerAux : URL.createObjectURL(bannerAux)} />
                                                        :
                                                            formState.bannerMarketplace
                                                                ?
                                                                    <img className="img-profile" alt="Preview" src={typeof formState.bannerMarketplace === 'string' ? formState.bannerMarketplace : URL.createObjectURL(formState.bannerMarketplace)} />
                                                                :
                                                                    <div className="img-profile"></div>
                                                    }
                                                    <input 
                                                        className='custom-file-input' 
                                                        style={{ display: 'none' }}
                                                        ref={fileRefTwo}
                                                        type="file" 
                                                        name="myBanner" 
                                                        onChange={changeBanner} 
                                                    />
                                                </div>
                                                <div className="editaravatar" onClick={() => fileRefTwo.current?.click()}>{t('club.banner')}</div>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className={formState.name ? 'has-content' : ''}
                                                        type='text'
                                                        name='name' 
                                                        value={formState?.name ||  ''}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <Form.Label htmlFor='name'>{t('club.name')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className={formState.web ? 'has-content' : ''}
                                                        type='text'
                                                        name='web' 
                                                        value={formState?.web ||  ''}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Form.Label htmlFor='web'>{t('club.web')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className={formState.phone ? 'has-content' : ''}
                                                        type='text'
                                                        name='phone' 
                                                        value={formState?.phone ||  ''}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Form.Label htmlFor='phone'>{t('club.phone')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <Form.Group className="d-flex">
                                                    <Form.Label htmlFor='show_marketplace'>{t('club.active')}</Form.Label>
                                                    <Form.Check
                                                        className="ms-3"
                                                        type="switch"
                                                        name="active"
                                                        onChange={handleCheckboxChange}
                                                        checked={formState?.active || false}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {usertype === 'superadmin' ?
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={12} className='mb-3'>
                                                            <Form.Group className="d-flex">
                                                                <Form.Label htmlFor='show_marketplace'>{t('club.verified')}</Form.Label>
                                                                <Form.Check
                                                                    className="ms-3"
                                                                    type="switch"
                                                                    name="verified"
                                                                    onChange={handleCheckboxChange}
                                                                    checked={formState?.verified || false}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} className='mb-3'>
                                                            <Form.Group className="d-flex">
                                                                <Form.Label htmlFor='activitybooking'>{t('club.activityBookingAccess')}</Form.Label>
                                                                <Form.Check
                                                                    className="ms-3"
                                                                    type="switch"
                                                                    name="activitybooking"
                                                                    onChange={handleCheckboxChange}
                                                                    checked={formState?.activitybooking || false}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} className='mb-3'>
                                                            <Form.Group className="d-flex">
                                                                <Form.Label htmlFor='ticketing'>{t('club.ticketingAccess')}</Form.Label>
                                                                <Form.Check
                                                                    className="ms-3"
                                                                    type="switch"
                                                                    name="ticketing"
                                                                    onChange={handleCheckboxChange}
                                                                    checked={formState?.ticketing || false}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} className='mb-3'>
                                                            <Form.Group className="d-flex">
                                                                <Form.Label htmlFor='renting'>{t('club.rentingAccess')}</Form.Label>
                                                                <Form.Check
                                                                    className="ms-3"
                                                                    type="switch"
                                                                    name="renting"
                                                                    onChange={handleCheckboxChange}
                                                                    checked={formState?.renting || false}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            :
                                                ''
                                            }

                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12}>
                                                <Form.Label htmlFor='description'>{t('club.description')}</Form.Label>
                                                <Form.Group className='form-group'>
                                                    <Editor
                                                        toolbar={{
                                                            options: ['inline', 'list', 'textAlign', 'link'],
                                                            inline: { options: ['bold', 'italic', 'underline'] },
                                                            list: { options: ['unordered', 'ordered'] },
                                                            textAlign: { options: ['left', 'center', 'right'] },
                                                            link: { options: ['link', 'unlink'] }
                                                        }}
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorStateChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={6}>
                                                <Form.Label htmlFor='description'>{t('club.carouselimages')}</Form.Label>  <Form.Label htmlFor='description'>({t('club.idealsize')}: 900x490)</Form.Label><br/>
                                                <input
                                                    type="file"
                                                    multiple
                                                    onChange={handleCarouselImageChange}
                                                /><br/><br/>
                                                {carouselImages.concat(newCarouselImages).length > 0 && (
                                                    <Carousel
                                                        className='config-carousel'
                                                        controls={true}
                                                        indicators={true}
                                                        interval={null}
                                                        activeIndex={activeCarouselIndex}
                                                        onSelect={(selectedIndex) => setActiveCarouselIndex(selectedIndex)}
                                                    >
                                                        {carouselImages.concat(newCarouselImages).map((img, index) => (
                                                            <Carousel.Item key={index}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={typeof img === 'string' ? img : URL.createObjectURL(img)}
                                                                    alt={`Slide ${index}`}
                                                                />
                                                                <Button
                                                                    variant="danger"
                                                                    className="position-absolute top-0 end-0 m-2"
                                                                    onClick={() => handleRemoveCarouselImage(index, img)}
                                                                    style={{ zIndex: 10 }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </Button>
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                )}
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Label htmlFor='description'>{t('club.sponsorsimages')}</Form.Label>  <Form.Label htmlFor='description'>({t('club.idealsize')}: 302x213)</Form.Label><br/>
                                                <input
                                                    type="file"
                                                    multiple
                                                    onChange={handleSponsorsImageChange}
                                                /><br/><br/>
                                                {sponsorsImages.concat(newSponsorsImages).length > 0 && (
                                                    <Carousel
                                                        className="config-carousel"
                                                        controls={sponsorsImages.concat(newSponsorsImages).length > 1}
                                                        indicators={sponsorsImages.concat(newSponsorsImages).length > 1}
                                                        interval={null}
                                                        activeIndex={activeSponsorIndex}
                                                        onSelect={(selectedIndex) => setActiveSponsorIndex(selectedIndex)}
                                                    >
                                                        {sponsorsImages.concat(newSponsorsImages).map((img, index) => (
                                                            <Carousel.Item key={index} className="position-relative">
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={typeof img === 'string' ? img : URL.createObjectURL(img)}
                                                                    alt={`Sponsor ${index}`}
                                                                />
                                                                <Button
                                                                    variant="danger"
                                                                    className="position-absolute top-0 end-0 m-2"
                                                                    onClick={() => handleRemoveSponsorImage(index, img)}
                                                                    style={{ zIndex: 10 }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </Button>
                                                                <Form.Group className="mt-2">
                                                                    <Form.Label>{t('club.sponsorlink')}</Form.Label>
                                                                    <Form.Control
                                                                        type="url"
                                                                        value={sponsorsLinks[index] || ''}
                                                                        onChange={(e) => handleSponsorsLinkChange(index, e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-5">
                                            <Col xs={12} lg={6} className='ms-auto'>
                                                <Button variant='primary' type="submit" className="w-100 my-2">
                                                {isUpdating
                                                    ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </>
                                                    :
                                                        <>{t('global.update')}</>
                                                }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
