import React, { useEffect, useState } from 'react'
import { Row, Col, Modal, Button, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { returnPayment } from '../../actions/payments/paymentActions'
import Swal from 'sweetalert2'

export default function ReturnPayment({userType = "club", payment, onHide, inscriptionBank = false}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()

    const [quantity, setQuantity] = useState(0)

    const handleRetryButton = () => {
        if (quantity > 0) {
            if (quantity > parseFloat(payment.quantity || 0) - parseFloat(payment.refunded || 0)) {
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('payments.wrongMaxReturnQuantity')
                })
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: t('global.yes'),
                    cancelButtonText: t('global.no'),
                    icon: 'warning',
                    text: t('payments.returnConfirmationText', {
                        "returnQuantity": quantity,
                        "totalQuantity": parseFloat(payment.quantity || 0) - parseFloat(payment.refunded || 0)
                    })
                }).then((newresult) => {
                    if (newresult.isConfirmed) {
                        dispatch(returnPayment(payment._id, quantity))
                        onHide()
                    }
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('payments.wrongMinReturnQuantity')
            })
        }
    }

    const handleQuantityChange = (e) => {
        let inputValue = e.target.value;

        if (/^\d*\.?\d*$/.test(inputValue)) {
            if (quantity === 0 && inputValue !== '0') {
                inputValue = inputValue.replace(/^0+/, '')
            }

            setQuantity(inputValue)
        }
    }

    useEffect(() => {
        if (payment) {
            setQuantity((parseFloat(payment.quantity || 0) - parseFloat(payment.refunded || 0)).toFixed(2))
        }
    }, [payment])

    return (
        <Modal show={payment ? true : false} onHide={() => onHide()} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('payments.returnPayment')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cuerpo px-5 py-4'>
                <div>
                    {
                        payment
                            ?
                                <>
                                    <Row className="my-2 p-2">
                                        <Col xs={12}>
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('inscriptions.paymentreference')}</small> {payment?.paymentReference}
                                            </div>
                                            <div className='d-flex justify-content-between border-bottom p-2'>
                                                <small>{t('payments.refType')}:</small> {payment.refType && payment.ref ? t('payments.' + payment.refType) : t('payments.cardRegistration')}
                                            </div>
                                            {
                                                (payment.refType === "Inscription" && inscriptionBank === false)
                                                ?
                                                <>
                                                    <div className='border-bottom p-2'>
                                                        <small>{t('payments.' + payment.refType)}:</small><br/>{payment.ref?.activity}
                                                    </div>
                                                    <div className='d-flex justify-content-between border-bottom p-2'>
                                                        <small>{t('payments.category')}:</small> {payment.ref?.category}
                                                    </div>
                                                    <div className='d-flex justify-content-between border-bottom p-2'>
                                                        <small>{t('payments.group')}:</small> {payment.ref?.group}
                                                    </div>
                                                </>
                                                :
                                                    <></>
                                            }
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('payments.member')}:</small> {payment.client ? `${payment.client?.lastname}, ${payment.client?.firstname}` : '-'}
                                            </div>
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('payments.paymentClient')}:</small> {payment.paymentClient ? `${payment.paymentClient?.lastname}, ${payment.paymentClient?.firstname}` : '-'}
                                            </div>
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('payments.quantity')}</small> {payment?.quantity} €
                                            </div>
                                            {
                                                payment?.refunded &&
                                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                        <small>{t('payments.refunded')}</small> {payment?.refunded} €
                                                    </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Row className='align-items-center'>
                                                <Col xs={7}>
                                                    <span className="fw-bold">{t('payments.refundQuantity')}</span>
                                                </Col>
                                                <Col xs={5}>
                                                    <Form.Group className='form-group'>
                                                        <Form.Control type="text" step={0.01} name="refundQuantity" min={0} required value={quantity || ''} onChange={handleQuantityChange} />
                                                        <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            :
                                <></>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" type="button" onClick={() => {onHide()}}>{t('global.cancel')}</Button>
                <Button variant="primary" type="button" onClick={() => {handleRetryButton()}}>{t('payments.return')}</Button>
            </Modal.Footer>
        </Modal>
	)
}