import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addUser } from '../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import { Link,useNavigate } from 'react-router-dom'

export default function RegisterClient() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {user, resultuser, useractivate} = useSelector(state=>state.users)

    const [formState, setFormState] = useState({})
    const [policy, setPolicy] = useState(false)

    //eslint-disable-next-line
    const [passwordverify, setPasswordverify] = useState("")
    const [isCorrect, setIsCorrect] = useState(false)
    const [showPasswordOne, setShowPasswordOne] = useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        setFormState({
            profile: 'Client',
            firstname: '',
            lastname: '',
            birthdate: '',
            username: '',
            password: '',
            email: '',
            tutor1Name: null,
            tutor1Email: null,
            tutor1Phone: null,
            tutor2Name: null,
            tutor2Email: null,
            tutor2Phone: null
        })
    }, [user, dispatch])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleDateChange = ({target}) => {
        let currentDate = new Date()
        let date18YearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())

        if (new Date(target.value) > date18YearsAgo) {
            setFormState({
                ...formState,
                tutor1Name: formState.tutor1Name ? formState.tutor1Name : '',
                tutor1Email: formState.tutor1Email ? formState.tutor1Email : '',
                tutor1Phone: formState.tutor1Phone ? formState.tutor1Phone : '',
                tutor2Name: formState.tutor2Name ? formState.tutor2Name : '',
                tutor2Email: formState.tutor2Email ? formState.tutor2Email : '',
                tutor2Phone: formState.tutor2Phone ? formState.tutor2Phone : '',
                [target.name]: target.value
            })
        } else {
            setFormState({
                ...formState,
                tutor1Name: null,
                tutor1Email: null,
                tutor1Phone: null,
                tutor2Name: null,
                tutor2Email: null,
                tutor2Phone: null,
                [target.name]: target.value
            })
        }
    }

    const handlePolicy  = (e) => {
        setPolicy(e.target.checked)
    }

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handlePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo)
    }

    const checkValidation = (isPassVer, e) => {
        const confirmPass = e.target.value

        if(isPassVer){
            setPasswordverify(confirmPass)
        }else{
            setFormState({
                ...formState,
                'password': confirmPass
            })
        }

        if (formState.password !== confirmPass) {
            setIsCorrect(false)
        }else {
            setIsCorrect(true)
        }
    }

    const handleAdd = async(e) => {
        e.preventDefault()
        if(isCorrect){
            if(formState.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,20}$/g)){
                dispatch(addUser(formState))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('users.passwordsnotvalid')
                })
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.passwordsnotmatch')
            })
        }
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('users.gotoverify'),
                        icon: 'success',
                        text: t('users.useradd')
                    }).then((newresult) => {
                        if (newresult.isConfirmed) {
                            navigate(`/${t("url.auth.activation")}/`+useractivate)
                        }
                    })
                    break;
                case 'email':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.emailduplicate')
                    })
                    break;
                case 'username':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.usernameduplicate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>
                    <Col md={7} className='d-none d-md-block aside-auth'>
                    </Col>
                    <Col xs={12} md={5} className='d-flex align-items-center p-5'>
                        <Container>
                            <Row>
                                <Col>
                                    <h1 className='mb-4'>{t('users.createaccount')}</h1>
                                    <Form className="formulario" onSubmit={handleAdd}>
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='firstname'>{t('users.firstname')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='firstname'
                                                    value={formState.firstname ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='lastname'>{t('users.lastname')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='lastname'
                                                    value={formState.lastname ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='phone'>{t('users.phone')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='phone'
                                                    value={formState.phone ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='tutor1Name'>{t('users.birthdate')}</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='date'
                                                    name='birthdate'
                                                    value={formState.birthdate ||  ''}
                                                    onChange={handleDateChange}
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            formState.tutor1Name !== null &&
                                                <>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <Form.Label htmlFor='tutor1Name'>{t('users.tutor1Name')} *</Form.Label>
                                                            <Form.Control
                                                                className="login"
                                                                type='text'
                                                                name='tutor1Name'
                                                                value={formState.tutor1Name ||  ''}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <Form.Label htmlFor='tutor1Phone'>{t('users.tutor1Phone')} * </Form.Label>
                                                            <Form.Control
                                                                className="login"
                                                                type='text'
                                                                name='tutor1Phone' 
                                                                value={formState.tutor1Phone ||  ''}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <Form.Label htmlFor='tutor1Email'>{t('users.tutor1Email')} *</Form.Label>
                                                            <Form.Control
                                                                className="login"
                                                                type='text'
                                                                name='tutor1Email' 
                                                                value={formState.tutor1Email ||  ''}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <Form.Label htmlFor='tutor2Name'>{t('users.tutor2Name')}</Form.Label>
                                                            <Form.Control
                                                                className="login"
                                                                type='text'
                                                                name='tutor2Name' 
                                                                value={formState.tutor2Name ||  ''}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <Form.Label htmlFor='tutor2Phone'>{t('users.tutor2Phone')}</Form.Label>
                                                            <Form.Control
                                                                className="login"
                                                                type='text'
                                                                name='tutor2Phone' 
                                                                value={formState.tutor2Phone ||  ''}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <Form.Label htmlFor='tutor2Email'>{t('users.tutor2Email')}</Form.Label>
                                                            <Form.Control
                                                                className="login"
                                                                type='text'
                                                                name='tutor2Email' 
                                                                value={formState.tutor2Email ||  ''}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                        }
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='username'>{t('users.username')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='username' 
                                                    value={formState.username ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='email'>{t('users.email')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='email'
                                                    name='email' 
                                                    value={formState.email ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col className='sd-passwordview'>
                                                <Form.Label htmlFor='firstname'>{t('users.password')} *</Form.Label>
                                                <Form.Control
                                                    className="sd-input"
                                                    type={showPasswordOne ? 'text' : 'password'}
                                                    name='password' 
                                                    value={formState.password ||  ''}
                                                    placeholder={t('users.setpassword')}
                                                    onChange={(e) => checkValidation(false, e)}
                                                    required
                                                />
                                                <img className="sd-imageneye" src={showPasswordOne ? "/images/eyeclose.svg" : "/images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <p>
                                                    <span className='ps-1'>{t('users.passwordneed1')}</span><br/>
                                                    <span className='password-list'>
                                                        <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed2')}</span>
                                                        <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed3')}</span>
                                                        <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed4')}</span>
                                                    </span>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col className='sd-passwordview'>
                                                <Form.Label htmlFor='firstname'>{t('users.passwordconfirm')} *</Form.Label>
                                                <Form.Control
                                                    className="sd-input"
                                                    type={showPasswordTwo ? 'text' : 'password'}
                                                    name='passwordcheck'
                                                    placeholder={t('users.setpasswordconfirm')}
                                                    onChange={(e) => checkValidation(true, e)}
                                                    required
                                                />
                                                <img className="sd-imageneye" src={showPasswordTwo ? "/images/eyeclose.svg" : "/images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityTwo}></img>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='d-flex align-items-start'>
                                                <Form.Check className='float-left pe-3' type='checkbox' name='policy' onChange={handlePolicy} checked={policy || false} />                                
                                                <p>
                                                    {t('users.policy1')}
                                                    <a href="https://incrono.com/es/politica-cookies" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy2')}</u></a>
                                                    {t('users.policy3')}
                                                    <a href="https://incrono.com/es/politica-privacidad" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy4')}</u></a>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Button variant='primary' type="submit" className="w-100 my-2" disabled={!policy}>{t('users.createaccountbutton')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>                                
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col className='text-center'>
                                    {t('registerlanding.alreadyaccount')}
                                    <Link to={`/${t("url.auth.login")}`} className='link ms-1'>
                                        {t('signin.login')}
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
