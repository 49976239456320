import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import { getUser, editUser } from '../../../actions/profiles/usersActions'

export default function Config() {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {user, resultuser} = useSelector(state=>state.users)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})
    const [passwordverify, setPasswordverify] = useState("")

    const [showPasswordOne, setShowPasswordOne] = useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth){
            dispatch(getUser(clientauth?.id))
        }
    },[dispatch, t, clientauth])

    useEffect(() => {
        if(user){
            setFormState({
                username: user?.username,
                email: user?.email,
                password: '',
            })
        }
    // eslint-disable-next-line
    }, [user, user])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handlePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo)
    }

    const checkValidation = (isPassVer, e) => {
        const confirmPass = e.target.value

        if(isPassVer){
            setPasswordverify(confirmPass)
        }else{
            setFormState({
                ...formState,
                'password': confirmPass
            })
        }
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        if(formState.password === passwordverify || formState.password === ''){
            if((formState.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,8}$/g) && formState.password === passwordverify) || formState.password === ''){
                dispatch(editUser(formState, clientauth?.id))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('users.passwordsnotvalid')
                })
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.passwordsnotmatch')
            })
        }
        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    break;
                case 'email':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.emailduplicate')
                    })
                    break;
                case 'username':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.usernameduplicate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3 justify-content-center">
                <Col xs={12} lg={6}>
                    <Card>
                        <Card.Body>
                            <h2>{t('users.editaccount')}</h2>
                            <hr className='mb-4'/>
                            <Form className="p-4 formulario" onSubmit={handleEdit}>
                                <Row className="text-center my-3">
                                    <Col>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.username ? 'has-content' : ''}
                                                type='username'
                                                name='username'
                                                value={formState.username ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <Form.Label htmlFor='email'>{t('users.username')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.email ? 'has-content' : ''}
                                                type='email'
                                                name='email'
                                                value={formState.email ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='email'>{t('users.email')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col className='sd-passwordview'>
                                        <Form.Label htmlFor='password'>{t('users.newpassword')}</Form.Label>
                                        <Form.Control
                                            className="sd-input"
                                            type={showPasswordOne ? 'text' : 'password'}
                                            name='password'
                                            value={formState.password ||  ''}
                                            placeholder={t('users.setpassword')}
                                            onChange={(e) => checkValidation(false, e)}
                                        />
                                        <img className="sd-imageneye" src={showPasswordOne ? "/images/eyeclose.svg" : "/images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <p>
                                        <span className='fs-12 fw-400 ps-1'>{t('users.passwordneed1')}</span><br/>
                                        <span className='password-list'>
                                            <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed2')}</span>
                                            <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed3')}</span>
                                            <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed4')}</span>
                                        </span>
                                    </p>
                                </Row>
                                <Row className="my-3">
                                    <Col className='sd-passwordview'>
                                        <Form.Control
                                            className="sd-input"
                                            type={showPasswordTwo ? 'text' : 'password'}
                                            name='passwordcheck'
                                            placeholder={t('users.setpasswordconfirm')}
                                            onChange={(e) => checkValidation(true, e)}
                                        />
                                        <img className="sd-imageneye" src={showPasswordTwo ? "/images/eyeclose.svg" : "/images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityTwo}></img>
                                    </Col>
                                </Row>
                                <Row className="text-center my-3">
                                    <Col>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                        {isUpdating
                                            ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                            :
                                                <>{t('global.update')}</>
                                        }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
