import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function BannerHead({image,title,section}) {
    const { t } = useTranslation()
    const currentUrl = window.location.pathname
    const urlParts = currentUrl.split('/').filter(Boolean)
    const breadcrumbs = urlParts.map((part, index) => {
      const path = `/${urlParts.slice(0, index + 1).join('/')}`
      if (index !== urlParts.length - 1) {
        return (
          <span key={index}>
            <Link to={path} className="text-white link">{part.charAt(0).toUpperCase() + part.slice(1)}</Link> {'>'}
          </span>
        )
      }else{
        return null
      }
    })
    const backImage = {
        backgroundImage: 'url('+image+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%'
    }

    return (
        <div className='p-5 text-center border-0 card-gradient position-relative' style={backImage} >
            <h2 className='display-6 text-white'>{title}</h2>
            <div className="text-white d-flex gap-2 justify-content-center">
                <Link to="/" className="link">{t('menus.general.home')}</Link> {'>'} {breadcrumbs} <span>{section}</span>
            </div>
        </div>
    )
}