import React from "react"
import { Row, Col, Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import InfoSite from "./InfoSite"

export default function SidebarSite({club}) {
    const { t } = useTranslation()

    return (
        <>
            <Row>
                <Col>
                    <Card className='p-3 border-0'>
                        <Card.Body>
                            <h3>{t('club.editaccount') }</h3>
                            <div className="d-flex align-items-center mb-5">
                                {club?.avatar
                                ?
                                    <img src={club?.avatar} alt="Escudo club" style={{width: '50px'}} className="me-2"/>
                                :
                                    ''
                                }
                                {club?.name}
                            </div>
                            {club?.sites?.length > 0 ? (
                                club?.sites?.map((site, index) => (
                                    <InfoSite site={site} key={index} />
                                ))
                            ) : (
                                ''
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}