import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../actions/profiles/usersActions'
import { dashboardAdmin } from '../../actions/payments/paymentActions'
import { Link } from 'react-router-dom'
import Version from '../../version'

export default function Dashboard() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()


    const [totalQuantity,setTotalQuantity]= useState(0)
    const [totalRawQuantity,setTotalRawQuantity]= useState(0)
    const [totalRefunded,setTotalRefunded]= useState(0)
    const [totalBenefit,setTotalBenefit]= useState(0)
    const {user} = useSelector(state=>state.users)
    const {resume} = useSelector(state=>state.payment)

    const formatNumber = (num) => {
        const number = Number(num)
        return new Intl.NumberFormat('es-ES', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(num)
    }

    useEffect(() => {
        if(clientauth){
            dispatch(getUser(clientauth?.id))
            dispatch(dashboardAdmin())
        }
    },[dispatch, t, clientauth])
    useEffect(()=>{
        if(resume){
            setTotalQuantity(parseFloat(resume.totalQuantity) || 0)
            setTotalRawQuantity(parseFloat(resume.totalRawQuantity) || 0)
            setTotalRefunded(parseFloat(resume.totalRefunded) || 0)
            setTotalBenefit(parseFloat(resume.totalBenefit) || 0)
        }
    },[resume])


    return (
        <Container className='p-5'>
            <Row>
                <Col>
                    <div className='sd-label-dashboard'>{t('admin.dashboard.welcome')}</div>
                    <div className="sd-label-userdashboard">{user?.username}</div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('admin.dashboard.subwelcome')}</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={6} lg={3} className='my-3'>
                    <Card className='text-center'>
                        <h2>Ingresos</h2>
                        <h3>{formatNumber(parseFloat(totalQuantity.toString().replace(',', '.')) || 0)} €</h3>
                    </Card>
                </Col>
                <Col xs={6} lg={3} className='my-3'>
                    <Card className='text-center'>
                        <h2>Clubes</h2>
                        <h3>{formatNumber(parseFloat(totalRawQuantity.toString().replace(',', '.')) || 0)} €</h3>
                    </Card>
                </Col>
                <Col xs={6} lg={3} className='my-3'>
                    <Card className='text-center'>
                        <h2>Margen</h2>
                        <h3>{formatNumber(parseFloat(totalBenefit.toString().replace(',', '.')) || 0)} €</h3>
                    </Card>
                </Col>
                <Col xs={6} lg={3} className='my-3'>
                    <Card className='text-center'>
                        <h2>Devoluciones</h2>
                        <h3>{formatNumber(parseFloat(totalRefunded.toString().replace(',', '.')) || 0)} €</h3>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className='my-3'>
                    <Card>
                        <Link to={`/${t("url.admin.admin")}/${t("url.admin.clubs")}`} className='btn btn-dashboard'>
                            <h2 className='noBold'>{t('superadmin.dashboard.clubs')}</h2>
                        </Link>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className='my-3'>
                    <Card>
                        <Link to={`/${t("url.admin.admin")}/${t("url.admin.clients")}`} className='btn btn-dashboard'>
                            <h2 className='noBold'>{t('superadmin.dashboard.clients')}</h2>
                        </Link>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className='my-3'>
                    <Card>
                        <Link to={`/${t("url.admin.admin")}/${t("url.admin.inscriptions")}`} className='btn btn-dashboard'>
                            <h2 className='noBold'>{t('superadmin.dashboard.inscriptions')}</h2>
                        </Link>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className='my-3'>
                    <Card>
                        <Link to={`/${t("url.admin.admin")}/${t("url.admin.payments")}`} className='btn btn-dashboard'>
                            <h2 className='noBold'>{t('superadmin.dashboard.payments')}</h2>
                        </Link>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className='text-center mt-2'>
                    <Version />
                </Col>
            </Row>
        </Container>
    )
}