import React from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function SectorMap({rows, columns, numeration, deleted, selected, unavailable, type = "default", action = () => {}}) {
    const { t } = useTranslation()
    return (
        rows && rows !== ""
        ?
            <>
                <Row className="my-3 sector-container">
                    {
                        Array.from({ length: rows }, (_, rowIndex) => (
                            <div key={rowIndex} className={`sector-row action-${type}`}>
                                <div className="sector-row-index">{rowIndex + 1}</div>
                                <div className="sector-row-content">
                                    {
                                        columns && columns !== ""
                                        ?
                                            Array.from({ length: columns }, (_, columnIndex) => (
                                                deleted?.find(d => d[0] === rowIndex + 1 && d[1] === columnIndex + 1)
                                                ?
                                                    type === "default"
                                                    ?
                                                        <div key={`${rowIndex}${columnIndex}`} className="empty unclicable"/>
                                                    :
                                                        <div key={`${rowIndex}${columnIndex}`} className={'deleted'} onClick={() => {action(rowIndex, columnIndex)}}>
                                                            {
                                                                numeration?.toString() === '0'
                                                                ?
                                                                    <>
                                                                        {parseInt(columnIndex) + 1}
                                                                    </>
                                                                :
                                                                    numeration?.toString() === "1"
                                                                    ?
                                                                        <>
                                                                            {(parseInt(columnIndex) + 1) * 2 - 1}
                                                                        </>
                                                                    :
                                                                        <>
                                                                            {(parseInt(columnIndex) + 1) * 2}
                                                                        </>
                                                            }
                                                        </div>
                                                :
                                                    <div key={`${rowIndex}${columnIndex}`} className={`${unavailable?.find(d => d[0] === rowIndex + 1 && d[1] === columnIndex + 1) ? "unavailable unclicable" : selected && selected[0] === rowIndex + 1 && selected[1] === columnIndex + 1 ? 'selected' : 'available'}`} onClick={() => {
                                                        if (!unavailable?.find(d => d[0] === rowIndex + 1 && d[1] === columnIndex + 1)){
                                                            action(rowIndex, columnIndex)
                                                        }
                                                    }}>
                                                        {
                                                            numeration?.toString() === '0'
                                                            ?
                                                                <>
                                                                    {parseInt(columnIndex) + 1}
                                                                </>
                                                            :
                                                                numeration?.toString() === "1"
                                                                ?
                                                                    <>
                                                                        {(parseInt(columnIndex) + 1) * 2 - 1}
                                                                    </>
                                                                :
                                                                    <>
                                                                        {(parseInt(columnIndex) + 1) * 2}
                                                                    </>
                                                        }
                                                    </div>
                                            ))
                                        :
                                            <></>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </Row>
                {
                    type === "default" &&
                    <div className="my-3">
                        <div className="sector-row">
                            <div className="d-flex gap-2 align-items-center">
                                <div className="sector-row-content">
                                    <div className="selected unclicable">N</div>
                                </div>
                                <span>{t('sectors.legend.selected')}</span>
                            </div>
                            <div className="d-flex gap-2 align-items-center">
                                <div className="sector-row-content">
                                    <div className="unavailable unclicable">N</div>
                                </div>
                                <span>{t('sectors.legend.unavailable')}</span>
                            </div>
                            <div className="d-flex gap-2 align-items-center">
                                <div className="sector-row-content">
                                    <div className="available unclicable">N</div>
                                </div>
                                <span>{t('sectors.legend.available')}</span>
                            </div>
                        </div>
                    </div>
                }
            </>
        :
            <></>
    )
}