import { useTranslation } from "react-i18next"

const CountriesComponent = () => {
    const { t } = useTranslation()

    let countries = 
    [
        { "value": "afghanistan", "label": t('countries.afghanistan') },
        { "value": "albania", "label": t('countries.albania') },
        { "value": "algeria", "label": t('countries.algeria') },
        { "value": "andorra", "label": t('countries.andorra') },
        { "value": "angola", "label": t('countries.angola') },
        { "value": "antigua_and_barbuda", "label": t('countries.antigua_and_barbuda') },
        { "value": "argentina", "label": t('countries.argentina') },
        { "value": "armenia", "label": t('countries.armenia') },
        { "value": "australia", "label": t('countries.australia') },
        { "value": "austria", "label": t('countries.austria') },
        { "value": "azerbaijan", "label": t('countries.azerbaijan') },
        { "value": "bahamas", "label": t('countries.bahamas') },
        { "value": "bahrain", "label": t('countries.bahrain') },
        { "value": "bangladesh", "label": t('countries.bangladesh') },
        { "value": "barbados", "label": t('countries.barbados') },
        { "value": "belarus", "label": t('countries.belarus') },
        { "value": "belgium", "label": t('countries.belgium') },
        { "value": "belize", "label": t('countries.belize') },
        { "value": "benin", "label": t('countries.benin') },
        { "value": "bhutan", "label": t('countries.bhutan') },
        { "value": "bolivia", "label": t('countries.bolivia') },
        { "value": "bosnia_and_herzegovina", "label": t('countries.bosnia_and_herzegovina') },
        { "value": "botswana", "label": t('countries.botswana') },
        { "value": "brazil", "label": t('countries.brazil') },
        { "value": "brunei", "label": t('countries.brunei') },
        { "value": "bulgaria", "label": t('countries.bulgaria') },
        { "value": "burkina_faso", "label": t('countries.burkina_faso') },
        { "value": "burundi", "label": t('countries.burundi') },
        { "value": "cabo_verde", "label": t('countries.cabo_verde') },
        { "value": "cambodia", "label": t('countries.cambodia') },
        { "value": "cameroon", "label": t('countries.cameroon') },
        { "value": "canada", "label": t('countries.canada') },
        { "value": "central_african_republic", "label": t('countries.central_african_republic') },
        { "value": "chad", "label": t('countries.chad') },
        { "value": "chile", "label": t('countries.chile') },
        { "value": "china", "label": t('countries.china') },
        { "value": "colombia", "label": t('countries.colombia') },
        { "value": "comoros", "label": t('countries.comoros') },
        { "value": "congo_brazzaville", "label": t('countries.congo_brazzaville') },
        { "value": "congo_kinshasa", "label": t('countries.congo_kinshasa') },
        { "value": "costa_rica", "label": t('countries.costa_rica') },
        { "value": "croatia", "label": t('countries.croatia') },
        { "value": "cuba", "label": t('countries.cuba') },
        { "value": "cyprus", "label": t('countries.cyprus') },
        { "value": "czech_republic", "label": t('countries.czech_republic') },
        { "value": "denmark", "label": t('countries.denmark') },
        { "value": "djibouti", "label": t('countries.djibouti') },
        { "value": "dominica", "label": t('countries.dominica') },
        { "value": "dominican_republic", "label": t('countries.dominican_republic') },
        { "value": "ecuador", "label": t('countries.ecuador') },
        { "value": "egypt", "label": t('countries.egypt') },
        { "value": "elsalvador", "label": t('countries.elsalvador') },
        { "value": "equatorial_guinea", "label": t('countries.equatorial_guinea') },
        { "value": "eritrea", "label": t('countries.eritrea') },
        { "value": "estonia", "label": t('countries.estonia') },
        { "value": "eswatini", "label": t('countries.eswatini') },
        { "value": "ethiopia", "label": t('countries.ethiopia') },
        { "value": "fiji", "label": t('countries.fiji') },
        { "value": "finland", "label": t('countries.finland') },
        { "value": "france", "label": t('countries.france') },
        { "value": "gabon", "label": t('countries.gabon') },
        { "value": "gambia", "label": t('countries.gambia') },
        { "value": "georgia", "label": t('countries.georgia') },
        { "value": "germany", "label": t('countries.germany') },
        { "value": "ghana", "label": t('countries.ghana') },
        { "value": "greece", "label": t('countries.greece') },
        { "value": "grenada", "label": t('countries.grenada') },
        { "value": "guatemala", "label": t('countries.guatemala') },
        { "value": "guinea", "label": t('countries.guinea') },
        { "value": "guinea_bissau", "label": t('countries.guinea_bissau') },
        { "value": "guyana", "label": t('countries.guyana') },
        { "value": "haiti", "label": t('countries.haiti') },
        { "value": "holysee", "label": t('countries.holysee') },
        { "value": "honduras", "label": t('countries.honduras') },
        { "value": "hungary", "label": t('countries.hungary') },
        { "value": "iceland", "label": t('countries.iceland') },
        { "value": "india", "label": t('countries.india') },
        { "value": "indonesia", "label": t('countries.indonesia') },
        { "value": "iran", "label": t('countries.iran') },
        { "value": "iraq", "label": t('countries.iraq') },
        { "value": "ireland", "label": t('countries.ireland') },
        { "value": "israel", "label": t('countries.israel') },
        { "value": "italy", "label": t('countries.italy') },
        { "value": "jamaica", "label": t('countries.jamaica') },
        { "value": "japan", "label": t('countries.japan') },
        { "value": "jordan", "label": t('countries.jordan') },
        { "value": "kazakhstan", "label": t('countries.kazakhstan') },
        { "value": "kenya", "label": t('countries.kenya') },
        { "value": "kiribati", "label": t('countries.kiribati') },
        { "value": "korea_south", "label": t('countries.korea_south') },
        { "value": "kosovo", "label": t('countries.kosovo') },
        { "value": "kuwait", "label": t('countries.kuwait') },
        { "value": "kyrgyzstan", "label": t('countries.kyrgyzstan') },
        { "value": "laos", "label": t('countries.laos') },
        { "value": "latvia", "label": t('countries.latvia') },
        { "value": "lebanon", "label": t('countries.lebanon') },
        { "value": "lesotho", "label": t('countries.lesotho') },
        { "value": "liberia", "label": t('countries.liberia') },
        { "value": "libya", "label": t('countries.libya') },
        { "value": "liechtenstein", "label": t('countries.liechtenstein') },
        { "value": "lithuania", "label": t('countries.lithuania') },
        { "value": "luxembourg", "label": t('countries.luxembourg') },
        { "value": "madagascar", "label": t('countries.madagascar') },
        { "value": "malawi", "label": t('countries.malawi') },
        { "value": "malaysia", "label": t('countries.malaysia') },
        { "value": "maldives", "label": t('countries.maldives') },
        { "value": "mali", "label": t('countries.mali') },
        { "value": "malta", "label": t('countries.malta') },
        { "value": "marshall_islands", "label": t('countries.marshall_islands') },
        { "value": "mauritania", "label": t('countries.mauritania') },
        { "value": "mauritius", "label": t('countries.mauritius') },
        { "value": "mexico", "label": t('countries.mexico') },
        { "value": "micronesia", "label": t('countries.micronesia') },
        { "value": "moldova", "label": t('countries.moldova') },
        { "value": "monaco", "label": t('countries.monaco') },
        { "value": "mongolia", "label": t('countries.mongolia') },
        { "value": "montenegro", "label": t('countries.montenegro') },
        { "value": "morocco", "label": t('countries.morocco') },
        { "value": "mozambique", "label": t('countries.mozambique') },
        { "value": "namibia", "label": t('countries.namibia') },
        { "value": "nauru", "label": t('countries.nauru') },
        { "value": "nepal", "label": t('countries.nepal') },
        { "value": "netherlands", "label": t('countries.netherlands') },
        { "value": "newzealand", "label": t('countries.newzealand') },
        { "value": "nicaragua", "label": t('countries.nicaragua') },
        { "value": "niger", "label": t('countries.niger') },
        { "value": "nigeria", "label": t('countries.nigeria') },
        { "value": "northmacedonia", "label": t('countries.northmacedonia') },
        { "value": "norway", "label": t('countries.norway') },
        { "value": "oman", "label": t('countries.oman') },
        { "value": "pakistan", "label": t('countries.pakistan') },
        { "value": "palau", "label": t('countries.palau') },
        { "value": "palestinian_territories", "label": t('countries.palestinian_territories') },
        { "value": "panama", "label": t('countries.panama') },
        { "value": "papuanewguinea", "label": t('countries.papuanewguinea') },
        { "value": "paraguay", "label": t('countries.paraguay') },
        { "value": "peru", "label": t('countries.peru') },
        { "value": "philippines", "label": t('countries.philippines') },
        { "value": "poland", "label": t('countries.poland') },
        { "value": "portugal", "label": t('countries.portugal') },
        { "value": "qatar", "label": t('countries.qatar') },
        { "value": "romania", "label": t('countries.romania') },
        { "value": "russia", "label": t('countries.russia') },
        { "value": "rwanda", "label": t('countries.rwanda') },
        { "value": "saintkittsandnevis", "label": t('countries.saintkittsandnevis') },
        { "value": "saintlucia", "label": t('countries.saintlucia') },
        { "value": "saintvincentandthegrenadines", "label": t('countries.saintvincentandthegrenadines') },
        { "value": "samoa", "label": t('countries.samoa') },
        { "value": "sanmarino", "label": t('countries.sanmarino') },
        { "value": "saotomeandprincipe", "label": t('countries.saotomeandprincipe') },
        { "value": "saudiarabia", "label": t('countries.saudiarabia') },
        { "value": "senegal", "label": t('countries.senegal') },
        { "value": "serbia", "label": t('countries.serbia') },
        { "value": "seychelles", "label": t('countries.seychelles') },
        { "value": "sierraleone", "label": t('countries.sierraleone') },
        { "value": "singapore", "label": t('countries.singapore') },
        { "value": "slovakia", "label": t('countries.slovakia') },
        { "value": "slovenia", "label": t('countries.slovenia') },
        { "value": "solomonislands", "label": t('countries.solomonislands') },
        { "value": "somalia", "label": t('countries.somalia') },
        { "value": "southafrica", "label": t('countries.southafrica') },
        { "value": "southsudan", "label": t('countries.southsudan') },
        { "value": "spain", "label": t('countries.spain') },
        { "value": "srilanka", "label": t('countries.srilanka') },
        { "value": "sudan", "label": t('countries.sudan') },
        { "value": "suriname", "label": t('countries.suriname') },
        { "value": "sweden", "label": t('countries.sweden') },
        { "value": "switzerland", "label": t('countries.switzerland') },
        { "value": "syria", "label": t('countries.syria') },
        { "value": "taiwan", "label": t('countries.taiwan') },
        { "value": "tajikistan", "label": t('countries.tajikistan') },
        { "value": "tanzania", "label": t('countries.tanzania') },
        { "value": "thailand", "label": t('countries.thailand') },
        { "value": "timorleste", "label": t('countries.timorleste') },
        { "value": "togo", "label": t('countries.togo') },
        { "value": "tonga", "label": t('countries.tonga') },
        { "value": "trinidadandtobago", "label": t('countries.trinidadandtobago') },
        { "value": "tunisia", "label": t('countries.tunisia') },
        { "value": "turkey", "label": t('countries.turkey') },
        { "value": "turkmenistan", "label": t('countries.turkmenistan') },
        { "value": "tuvalu", "label": t('countries.tuvalu') },
        { "value": "uganda", "label": t('countries.uganda') },
        { "value": "ukraine", "label": t('countries.ukraine') },
        { "value": "unitedarabemirates", "label": t('countries.unitedarabemirates') },
        { "value": "unitedkingdom", "label": t('countries.unitedkingdom') },
        { "value": "unitedstates", "label": t('countries.unitedstates') },
        { "value": "uruguay", "label": t('countries.uruguay') },
        { "value": "uzbekistan", "label": t('countries.uzbekistan') },
        { "value": "vanuatu", "label": t('countries.vanuatu') },
        { "value": "venezuela", "label": t('countries.venezuela') },
        { "value": "vietnam", "label": t('countries.vietnam') },
        { "value": "yemen", "label": t('countries.yemen') },
        { "value": "zambia", "label": t('countries.zambia') },
        { "value": "zimbabwe", "label": t('countries.zimbabwe') }
    ]

    countries = countries?.sort((a, b) => (a.label > b.label ? 1 : -1))

    return { 'countries': countries}
}

export default CountriesComponent