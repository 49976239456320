export const seasonsReducer = (state = {loading: false, result: null, seasons: [], season: null}, action) => {
    switch (action.type) {
        case 'SEASON_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'SEASON_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'SEASON_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                seasons: action.payload.seasons
            }
        case 'SEASON_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                season: action.payload
            }
        case 'SEASON_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'SEASON_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'SEASON_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}