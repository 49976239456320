import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import { getClubsByUser } from '../../actions/profiles/clubsActions'
import { jwtDecode } from 'jwt-decode'
import Loader from '../../components/design/Loader/Loader'


export default function Dashboard({handleClientSelect,handleClubSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()
    const tokensave = sessionStorage.getItem('jwt')

    const {user} = useSelector(state=>state.users)
    const {resultclient} = useSelector(state=>state.clients)

    const {clubs,loadingclub} = useSelector(state=>state.clubs)
    const [token, setToken] = useState()

    useEffect(() =>{
        if(tokensave){
            setToken(jwtDecode(tokensave))
        }
    }, [tokensave])

    useEffect(() =>{
        if(token && clientauth){
            dispatch(getClubsByUser(clientauth?.id))
        }
    }, [token, dispatch, clientauth])

    useEffect(() => {
        if(clientauth){
            dispatch(getUser(clientauth?.id))
            handleClientSelect(clientauth?.client)
        }
    },[dispatch, t, clientauth, handleClientSelect])

    useEffect(() => {
        if(resultclient){
            let newresult = resultclient
            dispatch({
                type: 'CLIENT_RESET_RESULT'
            })
            switch (newresult) {
                case 'successactivate':
                    dispatch(getUser(clientauth?.id))
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.memberactivate')
                    })
                    break;
                case 'successdeactivate':
                    dispatch(getUser(clientauth?.id))
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.memberdeactivate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultclient])        

    return (
        <Container className='p-5'> 
            <Row>
                <Col>
                    <div className='sd-label-dashboard'>{t('client.dashboard.welcome')}</div>
                    <div className="sd-label-userdashboard">{user?.username}</div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('client.dashboard.subwelcome')}</h3>
                </Col>
            </Row>
            <Row>
                { loadingclub
                ?
                    <Loader/>
                :
                
                    clubs?.map((club, index) => (
                    <Col key={index}>
                        <Card onClick={() => handleClubSelect(club._id)}>
                            <Link to={`/${t("url.club.club")}/${t("url.club.dashboard")}`} className='btn btn-dashboard'>
                                <img
                                    src={club.avatar ? club.avatar : "/images/shield.png"}
                                    alt="Icon shield"
                                    width={100}
                                />
                                <h2 className='noBold'>{club.name}</h2>
                            </Link>
                        </Card>
                    </Col>
                    ))
                }
            </Row>            
        </Container>
    )
}