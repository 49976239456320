import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getSectorsByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/sector/getSectorsByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SECTOR_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const getAvailableSectorsByMatch = (club) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/sector/getAvailableSectorsByMatch/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SECTOR_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const getSector = (sectorId) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/sector/getSector/'+ sectorId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SECTOR_GET_SUCCESS',
                payload: data?.sector
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const addSector = (sectordata) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/sector/register', sectordata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SECTOR_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const editSector = (sectorId, sectorData) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/sector/edit/' + sectorId, sectorData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SECTOR_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const changeSectorActiveStatus = (sectorId, active) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/sector/changeActiveStatus/' + sectorId, {active}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SECTOR_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const deleteSector = (sectorId) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status } = await Axios.delete('/api/sector/delete/'+ sectorId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SECTOR_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const getSectorUnavailableSeats = (sectorId) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/sector/getUnavailableSeats/'+ sectorId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'SECTOR_GET_UNAVAILABLE_SEATS_SUCCESS',
                payload: data?.unavailableSeats
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}

export const getMultipleUnavailableSeats = (sectors) => async (dispatch) => {
    dispatch({
        type: 'SECTOR_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/sector/getMultipleUnavailableSeats', {sectors}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'SECTOR_GET_UNAVAILABLE_SEATS_SUCCESS',
                payload: data?.unavailableSeats
            })
        }
    } catch (error) {
        dispatch({
            type: 'SECTOR_NOT_LOADING'
        })
    }
}