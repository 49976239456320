import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

/**
 * Obtiene las promociones de un club
 * @param {String} club
 */
export const getPromotionsByClub = club => async dispatch => {
    dispatch({
        type: 'PROMOTION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/promotion/getPromotionsByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PROMOTION_GETALL_SUCCESS',
                payload: data?.promotions || []
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROMOTION_NOT_LOADING'
        })
    }
}

/**
 * Obtiene las promociones de un club
 * @param {String} promotion
 */
export const getPromotion = promotion => async dispatch => {
    dispatch({
        type: 'PROMOTION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/promotion/getPromotion/'+ promotion, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PROMOTION_GET_SUCCESS',
                payload: data?.promotion
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROMOTION_NOT_LOADING'
        })
    }
}

/**
 * Cambia el estado de una promoción
 * @param {Object} data {id: String, status: Boolean}
 */
export const changeActiveStatus = (data) => async (dispatch) => {
    dispatch({
        type: 'PROMOTION_LOADING'
    })
    try {
        const response = await Axios.put('api/promotion/changeActiveStatus', data, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PROMOTION_NOT_ERROR'
            })
        } else {
            dispatch({
                type: 'PROMOTION_EDIT_SUCCESS',
                payload: response?.data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROMOTION_NOT_ERROR'
        })
    }
}

/**
 * Elimina una promoción
 * @param {String} promotionId
 */
export const deletePromotion = promotionId => async dispatch => {
    dispatch({
        type: 'PROMOTION_LOADING'
    })
    try {
        const { data, status } = await Axios.delete('/api/promotion/deletePromotion/'+ promotionId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PROMOTION_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROMOTION_NOT_LOADING'
        })
    }
}

/**
 * Crea una promoción
 * @param {Object} data
 */
export const addPromotion = (data) => async (dispatch) => {
    dispatch({
        type: 'PROMOTION_LOADING'
    })
    try {
        const response = await Axios.post('api/promotion/register', data, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PROMOTION_NOT_LOADING'
            })
        } else {
            dispatch({
                type: 'PROMOTION_ADD_SUCCESS',
                payload: response?.data?.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROMOTION_NOT_LOADING'
        })
    }
}

/**
 * Edita una promoción
 * @param {Object} data
 */
export const editPromotion = (promotionId, data) => async (dispatch) => {
    dispatch({
        type: 'PROMOTION_LOADING'
    })
    try {
        const response = await Axios.put('api/promotion/edit/' + promotionId, data, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'PROMOTION_NOT_LOADING'
            })
        } else {
            dispatch({
                type: 'PROMOTION_EDIT_SUCCESS',
                payload: response?.data?.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROMOTION_NOT_LOADING'
        })
    }
}