import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getTicketsByClient = (client) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/ticket/getTicketsByClient/'+ client, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const getTicketsByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/ticket/getTicketsByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const getInvitationsByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/ticket/getInvitationsByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const getTickets = () => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/ticket/getAll', {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const changeTicketStatus = (ticketId, userId, ticketStatus) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.put('/api/ticket/changeStatus', {user: userId, ticket: ticketId, status: ticketStatus}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_CHANGE_STATUS_SUCCESS',
                payload: data?.message
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const changeInvitationStatus = (invitationData, userId, invitationStatus) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.put('/api/ticket/changeInvitationStatus', {user: userId, invitation: invitationData, status: invitationStatus}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_CHANGE_STATUS_SUCCESS',
                payload: data?.message
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const getTicketsByInvitation = (invitationTemplate) => async (dispatch) => {
    dispatch({
        type: 'TICKET_INVITATION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/ticket/getTicketsByInvitation/' + invitationTemplate, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_GET_INVITATION_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'TICKET_INVITATION_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_INVITATION_NOT_LOADING'
        })
    }
}

export const getTicketsByPaymentClient = (client) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/ticket/getTicketsByPaymentClient/'+ client, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const createInvitation = (invitationData) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.post('/api/ticket/createInvitation', invitationData, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_CREATE_INVITATION_SUCCESS',
                payload: data?.message
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}

export const createWindowTicket = (windowTicketData) => async (dispatch) => {
    dispatch({
        type: 'TICKET_LOADING'
    })
    try {
        const { data, status } = await Axios.post('/api/ticket/createWindowTicket', windowTicketData, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'TICKET_CREATE_WINDOW_TICKET_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'TICKET_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TICKET_NOT_LOADING'
        })
    }
}