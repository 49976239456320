import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { editFee, getFee } from '../../../../actions/ticketing/feesActions.js'
import FeeForm from '../../../../components/forms/fees/FeeForm.js'
import { getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions.js'

export default function EditFee({usertype, selectedFee}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading: feesLoading, fee} = useSelector(state=>state.fees)
    const {sectors, loading: sectorsLoading} = useSelector(state=>state.sectors)
	const {club} = useSelector(state=>state.clubs)
	const {commission} = useSelector(state=>state.commissions)

    const [feeForm, setFeeForm] = useState({
        name: '',
        prices: []
    })

    const handleEdit = async(e) => {
        e.preventDefault()

        if(feeForm.name !== ''){
            if (!feeForm.prices?.some(p => !p.price || p.price === "")) {
                dispatch(editFee(fee?._id, feeForm))
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.ok'),
                    icon: 'warning',
                    text: t('fees.noPrices')
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('fees.noName')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'FEE_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('fees.editSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.fees")}`)
                        }
                    })
                    break;
                case 'duplicateFee':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('fees.duplicateFee')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (selectedFee) {
            dispatch(getFee(selectedFee))
        }
    }, [selectedFee, dispatch])

    useEffect(() => {
        if (fee) {
            setFeeForm({
                name: fee.name || '',
                prices: fee.prices || []
            })
        }
    }, [fee])

    useEffect(() => {
        if (club?._id) {
            dispatch(getSectorsByClub(club?._id))
            dispatch(getCommissionByClub(club?._id))
        }
    }, [club, dispatch])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('fees.editFeeForm')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <FeeForm state={feeForm} setState={setFeeForm} statetype={'edit'} usertype={usertype} isAdding={feesLoading || sectorsLoading} submit={handleEdit} sectors={sectors} commission={commission}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}