import React, { useEffect,useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { getActivitiesPublicByClub } from '../../../actions/activities/activitiesActions'
import { useLocation, useParams } from 'react-router'
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import Grid from '../../../components/design/Grid/Grid'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import MenuClubMarketplace from '../../../components/menus/MenuClubMarketplace'
import Loader from '../../../components/design/Loader/Loader'
import SidebarClubMarketplace from '../../../components/Club/SidebarClubMarketplace'
import NotFoundPage from '../../error/404'
import { getCourtsPublicByClub } from '../../../actions/sites/courtsActions'
import SearchCourts from '../../../components/design/Search/SearchCourts'


export default function ClubCourts() {
    const { t } = useTranslation()
	const dispatch = useDispatch()
    const {clubUrl} = useParams()
    const location = useLocation()

    const backgroundGeneric = '/images/fondo-auth.jpg'
    const {activitiesMarket: activities, loadingactivity} = useSelector(state=>state.activities)
    const {clubMarket: club, loadingclub, resultclub} = useSelector(state => state.clubs)
    const {courtsMarket: courts} = useSelector(state=>state.courts)
    const {courtsfilteredMarket: courtsfiltered} = useSelector(state=>state.filters)

    const [selectedActivities, setSelectedActivities] = useState()

    const [filters, setFilters] = useState({club: null, site: null, court: null, sport: null})

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch,clubUrl])

    useEffect(() =>{
        if(club){
            dispatch(getActivitiesPublicByClub(club?._id))
            dispatch(getCourtsPublicByClub(club?._id))
            setFilters({
                ...filters,
                club: club?._id
            })
        }
    }, [dispatch, club])

    useEffect(() => {
        if(activities){
            const activitesRandom = [...activities].sort(() => 0.5 - Math.random())
            setSelectedActivities(activitesRandom.slice(0, 3))
        }
    },[activities])

    useEffect(() => {
        if(location.search){
            const searchParams = new URLSearchParams(location.search)
            const site = searchParams.get('site') || null
            const court = searchParams.get('court') || null
            const sport = searchParams.get('sport') || null
            setFilters({ site, court, sport })
        }
    }, [location.search])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image={club?.bannerMarketplace || backgroundGeneric}
                title={club?.name}
                section={t('menus.general.activities')}
            />

            <Container className='market'>
                {loadingclub || loadingactivity ?
                    <Loader/>
                :
                    resultclub === 'thereisnotclub'
                    ?
                        <NotFoundPage />
                    :
                        club &&
                        <Row>
                            <Col xs={12} lg={9}>
                                <Row>
                                    <Col>
                                        <MenuClubMarketplace nuevaSeccion="renting" clubUrl={clubUrl} activitybooking={club?.activitybooking} renting={club?.renting} ticketing={club?.ticketing}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className='p-0 form-special w-100 border-0'>
                                            <SearchCourts ancho="100%" filters={filters} clubId={club?._id} courts={courts}/>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Grid type="court" data={courtsfiltered || courts} columns="4"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={3} className="my-3">
                                <SidebarClubMarketplace activitiesfeatured={selectedActivities} sites={club?.sites} />
                            </Col>
                        </Row>
                }
            </Container>
            <FooterMarketplace className='m-0'/>
        </>

    )
}