import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function HeadConfig({ nuevaSeccion, type, usertype }) {
    const { t } = useTranslation()

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>
                        {type === 'client' ? usertype === 'superadmin' ? t('admin.profile') : t('users.profile') : t('users.memberProfile')}
                    </h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/home" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "personal"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.personaldata")}` : `/${t("url.client.client")}/${t("url.client.personaldata")}`}>{t('menus.config.profile')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "address"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.addressdata")}` : `/${t("url.client.client")}/${t("url.client.addressdata")}`}>{t('menus.config.address')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "social"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.socialdata")}` : `/${t("url.client.client")}/${t("url.client.socialdata")}`}>{t('menus.config.social')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "medical"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.medicaldata")}` : `/${t("url.client.client")}/${t("url.client.medicaldata")}`}>{t('menus.config.medical')}</Nav.Link>
                        </Nav.Item>
                        {type === 'client'
                            ?
                            <Nav.Item as="li">
                                <Nav.Link as={Link} active={nuevaSeccion === "card"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.carddata")}` : `/${t("url.client.client")}/${t("url.client.carddata")}`}>{t('menus.config.card')}</Nav.Link>
                            </Nav.Item>
                            :
                                <></>
                        }
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "account"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.accountdata")}` : `/${t("url.client.client")}/${t("url.client.accountdata")}`}>{t('menus.config.account')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "identity"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.identitydata")}` : `/${t("url.client.client")}/${t("url.client.identitydata")}`}>{t('menus.config.identity')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}