import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const FooterMarketplace = () => {
    const { t } = useTranslation()

    return (
        <footer className='bg-white m-0 p-0 border-top border-2 border-dark'>
            <Container className='my-5'>
                <Row className='p-2'>
                    <Col xs={12} lg={3}>
                        <img src='/images/logotipopie.svg' alt="Logotipo Incrono" className='mb-3'/><br/>
                        {t('marketplace.footericonphrase')}
                    </Col>
                    <Col xs={12} lg={3}>
                        <h4>{t('marketplace.footer.interest')}</h4>
                        <ul>
                            <li><Link to={`/${t("url.marketplace.incrono")}`} className="link">{t('marketplace.footer.incrono')}</Link></li>
                            <li><Link to={`/${t("url.marketplace.activities")}`} className="link">{t('marketplace.footer.play')}</Link></li>
                            <li><Link to={`/${t("url.auth.registerclub")}`} className="link">{t('marketplace.home.discoverincrono')}</Link></li>
                        </ul>
                    </Col>
                    <Col xs={12} lg={3}>
                        <h4>{t('marketplace.footer.legal')}</h4>
                        <ul>
                            <li><Link to={`/${t("url.marketplace.cookiespolicy")}`} className="link">{t('marketplace.footer.cookiespolicy')}</Link></li>
                            <li><Link to={`/${t("url.marketplace.legalwarning")}`} className="link">{t('marketplace.footer.legalwarning')}</Link></li>
                            <li><Link to={`/${t("url.marketplace.privacypolicy")}`} className="link">{t('marketplace.footer.privacypolicy')}</Link></li>
                            <li><Link to={`/${t("url.marketplace.returns")}`} className="link">{t('marketplace.footer.returns')}</Link></li>
                        </ul>
                    </Col>
                    <Col xs={12} lg={3}>
                        <h4>{t('marketplace.footer.links')}</h4>
                        <ul>
                            <li><Link to={`/${t("url.marketplace.activities")}`} className="link">{t('marketplace.footer.activities')}</Link></li>
                            <li><Link to={`/${t("url.marketplace.clubs")}`} className="link">{t('marketplace.footer.clubs')}</Link></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='m-0 p-5 bg-dark'>
                <Row>
                    <Col></Col>
                </Row>
            </Container>
        </footer>
    )
}
