import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Col, Card, CardBody } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'
import ClubBreadCrumbs from '../../../components/breadcrumbs/ClubBreadCrumbs'
import Swal from 'sweetalert2'
import { paySepa } from '../../../actions/payments/paymentActions'
import { getClub } from '../../../actions/profiles/clubsActions'
import useAuth from '../../../context/auth/useAuth'
import Loader from '../../../components/design/Loader/Loader'

export default function Accounting({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()
    const { club } = useSelector(state => state.clubs)
    const {loading, sepaResult} = useSelector(state => state.payment)

    const handleSepaBtn = (club) => {
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.yes'),
            denyButtonText: t('global.no'),
            icon: 'warning',
            text: t('admin.clubs.paySepaText')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(paySepa(clientauth.id, club))
            }
        })
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (sepaResult) {
            switch (sepaResult) {
                case 'paySepaSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('admin.clubs.paySepaSuccess')
                    })
                    dispatch(getClub(club._id, true))
                    break;
                default:
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('admin.clubs.' + sepaResult)
                    })
                    break;
            }
            dispatch({type: 'PAYMENT_SEPA_RESULT_RESET'})
        }
    }, [sepaResult, dispatch])

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="accounting" usertype={usertype}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('club.accounting.title')}</h2>
                            <hr className='mb-4' />
                            <Row className='my-3'>
                                <Col xs={12} lg={6}>
                                    <strong>{t('club.accounting.charged')}: </strong>
                                    <span>{(club?.accounting?.charged !== null && club?.accounting?.charged !== undefined) ? club?.accounting?.charged : 0}€</span>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <strong>{t('club.accounting.paid')}: </strong>
                                    <span>{(club?.accounting?.paid !== null && club?.accounting?.paid !== undefined) ? club?.accounting?.paid : 0}€</span>
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col xs={12} lg={6}>
                                    <strong>{t('club.accounting.sepaPending')}: </strong>
                                    <span>{(club?.accounting?.sepaPending !== null && club?.accounting?.sepaPending !== undefined) ? club?.accounting?.sepaPending : 0}€</span>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <strong>{t('club.accounting.freeSepaPending')}: </strong>
                                    <span>{(club?.accounting?.freeSepaPending !== null && club?.accounting?.freeSepaPending !== undefined) ? club?.accounting?.freeSepaPending : 0}€</span>
                                </Col>
                            </Row>
                            {
                                club?.accounting?.freeSepaPending && club?.accounting?.freeSepaPending > 0 && club?.paycomet?.allVerified === true && club?.paycomet?.iban
                                ?
                                    <Row className='mt-5'>
                                        <Col xs={12} className='d-grid'>
                                            <Button type='button' onClick={() => {handleSepaBtn(club._id)}} disabled={loading}>
                                                {
                                                    loading
                                                    ?
                                                        <Loader className=''/>
                                                    :
                                                        t('club.accounting.paySepa')
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                :
                                    <></>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}