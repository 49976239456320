import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubsPublic } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { getActivitiesPublic } from '../../../actions/activities/activitiesActions'
import Grid from '../../../components/design/Grid/Grid'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import SearchActivities from '../../../components/design/Search/SearchActivities'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import Loader from '../../../components/design/Loader/Loader'
import { useLocation } from 'react-router-dom'

export default function Activities() {
    const { t } = useTranslation()
	const dispatch = useDispatch()
    const location = useLocation()

    // const {clubs} = useSelector(state=>state.clubs)
    const {activitiesMarket: activities, loadingactivity} = useSelector(state=>state.activities)
    const {activitiesfilteredMarket: activitiesfiltered} = useSelector(state=>state.filters)

    const [filters, setFilters] = useState({ activity: '', province: null, sport: null})

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubsPublic())
        dispatch(getActivitiesPublic())
    }, [dispatch])

    useEffect(() => {
        if(location.search){
            const searchParams = new URLSearchParams(location.search)
            const activity = searchParams.get('activity') || ''
            const province = searchParams.get('province') || null
            const sport = searchParams.get('sport') || null
            setFilters({ activity, province, sport })
        }
    }, [location.search])

    return (
        <>
            <MenuMarketplace section='activities'/>
            <BannerHead
                image='/images/fondo-auth.jpg'
                title={t('marketplace.home.activities')}
                section={t('marketplace.home.activities')}
            />
            <div className='p-0 form-special w-100 border-bottom border-2 border-dark'>
                <SearchActivities ancho="75%" filters={filters}/>
            </div>
            <Container className='market'>
                <Row className="my-3">
                    <Col>
                    {
                        loadingactivity
                        ?
                        <Loader />
                        :
                        <Grid type="activity" data={activitiesfiltered || activities} columns={3}/>
                    }
                    </Col>
                </Row>
            </Container>
            <FooterMarketplace className='m-0'/>
        </>

    )
}