import React,{useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'

export default function Incrono () {
    const { t } = useTranslation()
	useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
    }, [])

    return (
		<>
            <MenuMarketplace/>
            <Container className='market'>
                <Row>
                    <Col className='mx-auto'>
                        <h1>{t('corporation.incrono.title')}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className='my-4'>{t('corporation.incrono.paragraph1')}</h4>
                        <h4 className='my-4'>{t('corporation.incrono.paragraph2')}</h4>
                        <h4 className='my-4'>{t('corporation.incrono.paragraph3')}</h4>
                        <h4 className='my-4'>{t('corporation.incrono.paragraph4')}</h4>
                    </Col>
                </Row>
            </Container>
            <FooterMarketplace className='m-0'/>
        </>
    )
}