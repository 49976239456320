import React, { Fragment, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable.js'
import useAuth from '../../../../context/auth/useAuth.js'
import { deleteFee, getFeesByClub } from '../../../../actions/ticketing/feesActions.js'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs.js'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu.js'
import Swal from 'sweetalert2'
import { getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions.js'

export default function Fees({usertype, handleFeeSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {fees, result} = useSelector(state=>state.fees)
    const {club} = useSelector(state=>state.clubs)
    const {sectors} = useSelector(state=>state.sectors)
	const {commission} = useSelector(state=>state.commissions)

    const handleDeleteFee = (feeId) => {
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.yes'),
            denyButtonText: t('global.no'),
            icon: 'warning',
            text: t('fees.deleteFeeText')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteFee(feeId))
            }
        })
    }

    const calculatePayment = (price) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }

        if (!isNaN(price)) {
            const ticketing = commission?.ticketing?.find(ticketing => price >= ticketing.lowPrice && price <= ticketing.highPrice);
            if (ticketing) {
                return {
                    price: price,
                    priceClient: price > 0 ? (Math.ceil(((price * (ticketing.percentage || 0) / 100) + (ticketing.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
                }
            } else {
                return {
                    price: price,
                    priceClient: '0.00'
                }
            }
        } else {
            return {
                price: '0.00',
                priceClient: '0.00'
            }
        }
    }

    useEffect(() => {
        handleFeeSelect(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(clientauth && club?._id){
            dispatch(getFeesByClub(club?._id))
            dispatch(getSectorsByClub(club?._id))
            dispatch(getCommissionByClub(club?._id))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'feeDeleteSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text:  t('fees.deleteSuccessText')
                    })
                    dispatch(getFeesByClub(club?._id))
                    dispatch({
                        type: 'FEE_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result])

	const columnsFees = [
        {
            name: t('fees.name'),
            cell: fee =>
                <Link onClick={() => handleFeeSelect(fee._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.fees")}/${t("url.club.edit")}`} className='table-link'>
                    {fee.name}
                </Link>,
            selector: (fee) => fee.name,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',  
            width:'120px',
			cell: fee =>
                <div className={`d-flex justify-content-around w-100 ${fee.valid ? '' : 'text-danger'}`}>
                    <Link onClick={() => handleFeeSelect(fee._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.fees")}/${t("url.club.edit")}`}>
                        <img src="/images/edit.svg" alt={t('global.edit')} width={15}></img>
                    </Link>
                    <Link onClick={() => handleDeleteFee(fee._id)}>
                        <img src="/images/trash.svg" alt="trash" width={15}/>
                    </Link>
                </div>,
			sortable: false,
		}
	]

    const ExpandedRow = ({ data }) => {
        return (
            <Container className='p-3'>
                {data?.prices.map((price, index) => (
                    price.deleted
                    ?
                        <Fragment key={index} />
                    :
                        <Row className="my-2 border-bottom" key={index}>
                            <Col xs={6}>
                                <div className='d-flex justify-content-between border-1 border-end p-2 pe-4'>
                                    <small>{t('fees.sector')}</small>
                                    <span>{ sectors?.find(sector => sector._id === price.sector)?.name }</span>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className='d-flex justify-content-between border-1 border-end p-2 pe-4'>
                                    <small>{t('fees.price')}</small>
                                    <span>{Number(price.price).toFixed(2)}€</span>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className='d-flex justify-content-between border-1 p-2'>
                                    <small>{t('inscriptions.finalprice')}</small>
                                    <span>
                                        {(
                                            Number(price.price) +
                                            Number(calculatePayment(price.price || 0).priceClient)
                                        ).toFixed(2)}€
                                    </span>
                                </div>
                            </Col>
                        </Row>
                ))}
            </Container>
        )
    }

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <TicketingSubMenu usertype={usertype} active="fees"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('fees.feesList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.fees")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('fees.createFee')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {fees?.length > 0 ?
                                            <CustomDataTable columns={columnsFees} data={fees} exportable={false} printable={false} expandedData={ExpandedRow}/>
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('fees.noFees')}</h4>
                                                {t('fees.thereIsNotFeesPhrase')}
                                                <div className='mt-3'>
                                                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.fees")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                                        {t('fees.createFee')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}