import React, { useEffect } from 'react'
import { Container, Row, Col, Card, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getClients, changeClientActive } from '../../../actions/profiles/clientsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import { Link } from 'react-router-dom'
import Loader from '../../../components/design/Loader/Loader'

export default function Clients({handleClientSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {clients, resultclient, loadingclient} = useSelector(state=>state.clients)

    useEffect(() => {
        if(clientauth){
            dispatch(getClients())
        }
    },[dispatch, t, clientauth])

    const handleActiveChange = (clientId, active) => {
        dispatch(changeClientActive(clientId, active))
    }

    useEffect(() => {
        if(resultclient?.message){
            switch (resultclient.message) {
                case 'editedsuccess':
                    dispatch({
                        type: 'CLIENT_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [resultclient])

	const columnsClients = [
        {
            name: t('client.name'),
            cell: client =>
                <Link onClick={() => handleClientSelect(client._id)} to={`/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.personaldata")}`} className='table-link'>
                    <div className='d-flex align-items-center'>
                        <div className='avatar me-2' style={{width: "60px", height: "60px"}}>
                        {client.avatar ?
                            <img src={client.avatar} alt={client.title} height="60px" width="60px"/>
                        :
                            <div className="img-profile" style={{width: "60px", height: "60px"}}></div>
                        }
                        </div>
                        {client.firstname} {client.lastname}
                    </div>
                </Link>,
            selector: (client) => client.name,
            sortable: true,
        },{
            name: t('client.email'),
            cell: client =>
                <div>
                    {client.email}
                </div>,
            selector: (client) => client.email,
            sortable: true,
        },{
            name: t('client.phone'),
            cell: client =>
                <div>
                    {client.phone}
                </div>,
            selector: (client) => client.phone,
            sortable: true,
        },{
            name: t('client.active'),
            center: 'true',
            width: '100px',
            cell: client =>
                <div>
                    {client.activeuser === true || client.activeuser === false ?
                        <Form.Check
                            type='switch'
                            checked={client.activeuser}
                            onChange={({target}) => (handleActiveChange(client._id, target.checked))}
                        />
                    :
                        ''
                    }
                </div>,
            selector: (client) => client.activeuser,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width: '120px',
			cell: client =>
				<div className='d-flex align-items-center justify-content-between w-50'>
					<Link onClick={() => handleClientSelect(client._id)} to={`/${t("url.admin.admin")}/${t("url.admin.client")}/${t("url.admin.personaldata")}`}>
                        <img src="/images/edit.svg" alt="pencil" height={16}></img>
					</Link>
				</div>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col className='d-flex justify-content-between'>
                    <h1 className='ms-2 my-0'>{t('client.clients')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        {loadingclient
                        ?
                            <Loader/>
                        :
                            <Card.Body>
                                <div className="my-3">
                                    <Row>
                                        <Col>
                                        {clients?.length > 0 ?
                                            <CustomDataTable columns={columnsClients} data={clients} exportable={false} printable={false} />
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('client.thereisnotclients')}</h4>
                                            </div>
                                        }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}