import Login from '../pages/auth/Login'
import Forgot from '../pages/auth/Forgot'
import EmailSend from '../pages/auth/EmailSend'
import ResetPassword from '../pages/auth/ResetPassword'
import ResetPasswordSuccess from '../pages/auth/ResetPasswordSuccess'
import RegisterLanding from '../pages/auth/RegisterLanding'
import RegisterClient from '../pages/auth/RegisterClient'
import RegisterMember from '../pages/auth/RegisterMember'
import RegisterClub from '../pages/auth/RegisterClub'
import Activation from '../pages/auth/Activation'
import Lobby from '../pages/auth/Lobby'
import i18n from 'i18next'

const t = i18n.t.bind(i18n)

// Mapeo de alias a paths reales
export const aliasMapAuth = {
    [t("url.auth.login")]: "login",
    [t("url.auth.lobby")]: "lobby",
    [t("url.auth.forgotpassword")]: "forgotpassword",
    [`${t("url.auth.emailsend")}/:email`]: "emailsend/:email",
    [`${t("url.auth.resetpassword")}/:id/:tokenresetpassword`]: "resetpassword/:id/:tokenresetpassword",
    [t("url.auth.resetpasswordsuccess")]: "resetpasswordsuccess",
    [t("url.auth.registerlanding")]: "registerlanding",
    [t("url.auth.registerclient")]: "registerclient",
    [`${t("url.auth.registermember")}/:clientid/:emailuser`]: "registermember/:clientid/:emailuser",
    [t("url.auth.registerclub")]: "registerclub",
    [`${t("url.auth.activation")}/:idUser`]: "activation/:idUser",
}

// Función auxiliar para obtener el componente basado en el path real
export const getComponentAuth = (path) => {
    switch (path) {
        case "login": return <Login />;
        case "lobby": return <Lobby />;
        case "forgotpassword": return <Forgot />;
        case "emailsend/:email": return <EmailSend />;
        case "resetpassword/:id/:tokenresetpassword": return <ResetPassword />;
        case "resetpasswordsuccess": return <ResetPasswordSuccess />;
        case "registerlanding": return <RegisterClient />;
        case "registerclient": return <RegisterClient />;
        case "registermember/:clientid/:emailuser": return <RegisterMember />;
        case "registerclub": return <RegisterClub />;
        case "activation/:idUser": return <Activation />;
        default: return <Login />;
    }
}