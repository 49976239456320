import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubsPublic } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import Grid from '../../../components/design/Grid/Grid'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import SearchClubs from '../../../components/design/Search/SearchClubs'
import BannerHead from '../../../components/Banners/BannerHead'
import Loader from '../../../components/design/Loader/Loader'

export default function Clubs() {
    const { t } = useTranslation()
	const dispatch = useDispatch()

    const {clubsMarket: clubs, loadingclub} = useSelector(state=>state.clubs)
    const {clubsfilteredMarket: clubsfiltered} = useSelector(state=>state.filters)

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubsPublic())
    }, [dispatch])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image='/images/fondo-auth.jpg'
                title={t('marketplace.home.clubs')}
                section={t('marketplace.home.clubs')}
            />
            <div className='p-0 form-special w-100 border-bottom border-2 border-dark'>
                <SearchClubs ancho="75%"/>
            </div>
            <Container className='market'>
                <Row className="my-3">
                    {
                    loadingclub
                    ?
                        <Loader/>
                    :
                        <Grid type="club" data={clubsfiltered || clubs} columns={3}/>
                    }
                </Row>
            </Container>
            <FooterMarketplace className='m-0'/>
        </>
        
    )
}