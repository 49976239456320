import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getClubsByUser } from '../../actions/profiles/clubsActions'
import { jwtDecode } from 'jwt-decode'
import Loader from '../../components/design/Loader/Loader'

export default function Lobby({handleClubSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()
    const tokensave = sessionStorage.getItem('jwt')

    const {clubs,loadingclub} = useSelector(state=>state.clubs)

    const [token, setToken] = useState()

    useEffect(() =>{
        if(tokensave){
            setToken(jwtDecode(tokensave))
        }
    }, [tokensave])

    useEffect(() =>{
        if(token && clientauth){
            dispatch(getClubsByUser(clientauth?.id))
        }
    }, [token, dispatch, clientauth])

    return (
        <Container className='p-5'> 
            <Row>
                <Col>
                    <div className='sd-label-dashboard'>{t('lobby.dashboard.welcome')}</div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('lobby.dashboard.subwelcome')}</h3>
                </Col>
            </Row>
            <Row>
                { loadingclub
                ?
                    <Loader/>
                :
                
                    clubs?.map((club, index) => (
                    <Col key={index}>
                        <Card onClick={() => handleClubSelect(club._id)} className='my-3'>
                            <Link to={`/${t("url.club.club")}/${t("url.club.dashboard")}`} className='btn btn-dashboard'>
                                <img
                                    src={club.avatar ? club.avatar : "/images/shield.png"}
                                    alt="Icon shield"
                                    width={100}
                                />
                                <h2 className='noBold'>{club.name}</h2>
                            </Link>
                        </Card>
                    </Col>
                    ))
                }
            </Row>
        </Container>
    )
}