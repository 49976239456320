import React,{useEffect} from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupsPublicByActivity } from '../../../actions/activities/groupsActions'
import Loader from '../../../components/design/Loader/Loader'
import { getCommissionByClub } from '../../../actions/settings/commissionsActions'

export default function Description({activity}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { loading } = useSelector(state => state.categories)
    const { groupsMarket: groups } = useSelector(state => state.groups)
	const {commission} = useSelector(state=>state.commissions)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (activity) {
            dispatch(getGroupsPublicByActivity(activity?._id))
            dispatch(getCommissionByClub(activity?.club?._id))
        }
    }, [dispatch, activity])

    const getPrice = (price) => {
        if (price && !isNaN(price)) {
            const activity = commission?.activities?.find(activity => price >= activity.lowPrice && price <= activity.highPrice);
            if (activity) {
                return price > 0 ? (price + (Math.ceil(((price * (activity.percentage || 0) / 100) + (activity.double || 0)) * 100) / 100)).toFixed(2) : parseFloat(price).toFixed(2)
            } else {
                return parseFloat(price).toFixed(2)
            }
        } else {
            return '0.00'
        }
    }

    const calculateTotal = (registrationPayment, seasonPayments) => {
        const seasonTotal = seasonPayments?.reduce((total, payment) => total += payment.price, 0) || 0
        return getPrice((seasonTotal + (registrationPayment?.price || 0)))
    }

    return (
        <>
        {
        loading
        ?
            <Loader/>
        :
            <>
               <Card className='border-0 my-4'>
                    <Card.Body>
                        <div className='d-flex align-items-end flex-grow-1'>
                            <div className='flex-grow-1'>
                                <h2 className='border-2 border-bottom border-dark'>{t('global.description') }</h2>
                            </div>
                            <div className='ps-3 pb-2'>
                                <img src="/images/share.svg" alt="Compartir Club" />
                            </div>
                        </div>
                        <Row>
                            <Col className='my-3'>
                                <div dangerouslySetInnerHTML={{ __html: activity?.description }} />
                            </Col>
                        </Row>
                        <div className='d-flex align-items-end flex-grow-1'>
                            <div className='flex-grow-1'>
                                <h2 className='border-2 border-bottom border-dark'>{t('marketplace.activity.details') }</h2>
                            </div>
                        </div>
                        <div className='mt-3'>
                                {groups?.map((group, index) => (
                                    <Row key={index}>
                                        <Col  xs={12} className='my-3'>
                                            <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                                <strong>{t('marketplace.activity.category')}</strong>
                                                {group?.category?.title} ({group?.category?.ageFrom} - {group?.category?.ageTo} {t('marketplace.activity.yearsOld')})
                                            </div>
                                            <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                                <strong>{t('marketplace.activity.federated')}</strong>
                                                {group?.federated ? t('global.yes') : t('global.no')}
                                            </div>
                                            {group?.registrationPayment &&
                                                <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                                    <strong>{group?.registrationPayment.title}</strong>
                                                    {getPrice(group?.registrationPayment?.price)}€
                                                </div>
                                            }
                                            {group?.seasonPayments?.length > 0 &&
                                                <>
                                                    <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                                        <strong>{t('marketplace.activity.seasonPayments')}</strong>
                                                        <div>
                                                            {getPrice(group?.seasonPayments?.reduce((total, payment) => total += payment.price, 0))}€
                                                            <small className='ms-1'>({group?.seasonPayments?.length} {t('marketplace.activity.seasons')})</small>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between py-2'>
                                                        <strong>{t('marketplace.activity.total')}</strong>
                                                        {calculateTotal(group?.registrationPayment, group?.seasonPayments)}€
                                                    </div>
                                                </>
                                            }
                                        </Col>
                                        <hr className='border my-1 border-dark'/>
                                    </Row>

                                ))}
                        </div>
                    </Card.Body>
               </Card>
            </>
        }
        </>
    )
}