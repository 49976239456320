export const passReducer = (state = { loading: false, passes: null, result: null }, action)=>{
    switch (action.type) {
        case 'PASS_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'PASS_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'PASS_GET_ALL_SUCCESS':
            return {
                ...state,
                loading: false,
                passes: action.payload.passes
            }
        case 'PASS_CHANGE_STATUS_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'PASS_RESULT_RESET':
            return {
                ...state,
                result: null
            }
        case 'PASS_CREATE_PASS_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload.message
            }          
        default:
            return state
    }
}