export const superadminsReducer = (state = {loading: true, resultsuperadmin: null, superadmins: null, superadmin: null } , action) => {
    switch (action.type) {
        case 'SUPERADMIN_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                superadmin: action.payload.superadmin
            }
        case 'SUPERADMIN_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'SUPERADMIN_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

