import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getCategory = (category) => async (dispatch) => {
    dispatch({
        type: 'CATEGORY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/category/get/'+ category, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CATEGORY_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CATEGORY_NOT_LOADING'
        })
    }
}

export const addCategory = (categorydata) => async (dispatch) => {
    dispatch({
        type: 'CATEGORY_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/category/register', categorydata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'CATEGORY_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CATEGORY_NOT_LOADING'
        })
    }
}

export const updateCategory = (categorydata, categoryId) => async (dispatch) => {
    dispatch({
        type: 'CATEGORY_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/category/edit/' + categoryId, categorydata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'CATEGORY_EDIT_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'CATEGORY_NOT_LOADING'
        })
    }
}

export const getCategoriesByClub = (club, isImport = false) => async (dispatch) => {
    dispatch({
        type: 'CATEGORY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/category/getcategoriesbyclub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: isImport ? 'CATEGORY_GETALL_IMPORTS_SUCCESS' : 'CATEGORY_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CATEGORY_NOT_LOADING'
        })
    }
}

export const importCategoriesByClub = (categoriestoimport, club) => async (dispatch) => {
    dispatch({
        type: 'CATEGORY_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/category/importcategoriesbyclub/' + club, {categoriestoimport: categoriestoimport}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CATEGORY_IMPORT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CATEGORY_NOT_LOADING'
        })
    }
}

export const removeCategory = (category) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/category/remove/' + category, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'CATEGORY_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CATEGORY_NOT_LOADING'
        })
    }
}