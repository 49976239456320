export const paymentReducer = (state = { loading: false, error: null, paymentData: null, publicPurchaseData: null, paymentStatus: null, itemsPayment: null, payments: null, sepaResult: null, retryStatus: null, returnStatus: null, createManualStatus:null,cards: null, editStatus: null }, action)=>{
    switch (action.type) {
        case 'PAYMENT_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'PAYMENT_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'PAYMENT_ERROR':
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case 'PAYMENT_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                payments: action.payload.payments,
                cards: action?.payload?.cards ? action?.payload?.cards : null
            }
        case 'PAYMENT_STARTPURCHASE_SUCCESS':
            return {
                ...state,
                loading: false,
                paymentData: action.payload
            }
        case 'PAYMENT_START_PUBLIC_PURCHASE_SUCCESS':
            return {
                ...state,
                loading: false,
                publicPurchaseData: action.payload
            }
        case "PAYMENT_START_PUBLIC_PURCHASE_RESET": {
            return {
                ...state,
                publicPurchaseData: null
            }
        }
        case 'PAYMENT_PAYMENT_STATUS_SUCCESS':
            return {
                ...state,
                loading: false,
                paymentStatus: action.payload
            }
        case 'PAYMENT_PAYMENT_STATUS_RESET':
            return {
                ...state,
                paymentStatus: null
            }
        case 'PAYMENT_PAYMENT_DATA_RESET':
            return {
                ...state,
                paymentData: null
            }
        case 'PAYMENT_PAYMENT_REQUEST_SUCCESS':
            return {
                ...state,
                loading: false,
                itemsPayment: action.payload
            }
        case 'PAYMENT_PAYMENT_REQUEST_RESET':
            return {
                ...state,
                itemsPayment: null
            }
        case 'PAYMENT_EDIT_SUCCESS':
            return {
                ...state,
                editStatus: action.payload.message,
                payments: state.payments?.map(payment => {
                    if (payment._id === action.payload.payment._id) {
                        return action.payload.payment
                    } else {
                        return payment
                    }
                })
            }
        case 'PAYMENT_EDIT_RESET':
            return {
                ...state,
                editStatus: null
            }
        case 'PAYMENT_SEPA_RESULT':
            return {
                ...state,
                sepaResult: action.payload,
                loading: false
            }
        case 'PAYMENT_SEPA_RESULT_RESET':
            return {
                ...state,
                sepaResult: null
            }
        case 'PAYMENT_RETRY_SUCCESS':
            return {
                ...state,
                retryStatus: action.payload,
                loading: false
            }
        case 'PAYMENT_RETRY_RESET':
            return {
                ...state,
                retryStatus: null,
            }
        case 'PAYMENT_RETURN_SUCCESS':
            return {
                ...state,
                returnStatus: action.payload,
                loading: false
            }
        case 'PAYMENT_RETURN_RESET':
            return {
                ...state,
                returnStatus: null,
            }
        case 'PAYMENT_CREATEMANUALPAYMENT_SUCCESS':
            return {
                ...state,
                createManualStatus: action.payload,
                loading: false
            }
        case 'PAYMENT_CREATEMANUALPAYMENT_RESET':
            return {
                ...state,
                createManualStatus: null,
            }
        case 'PAYMENT_GETRESUME_SUCCESS':
            return {
                ...state,
                resume: action.payload,
                loading: false
            }
        default:
            return state
    }
}