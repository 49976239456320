import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function DataSite({site,clubWeb}) {
    const { t } = useTranslation()

    return (
        <Card className='border-0 p-2'>
            <Card.Body>
                <div className='d-flex align-items-end flex-grow-1'>
                    <div className='flex-grow-1'>
                        <h2 className='border-2 border-bottom border-dark'>{site?.name} - {t('global.contact') }</h2>
                    </div>
                    <div className='ps-3 pb-2'>
                        <img src="/images/share.svg" alt="Compartir Club" />
                    </div>
                </div>
                <Row>
                    <Col xs={12} lg={5}>
                        {site?.manager?.phoneNumber
                        ?
                            <a href={`tel:${site?.manager?.phoneNumber}`}>
                                <img src="/images/phone.svg" alt="Phone" className='me-2 my-2' />
                                {site?.manager?.phoneNumber}<br/>
                            </a>
                        : ''
                        }
                        {site?.manager?.email
                        ?
                            <a href={`mailto:${site?.manager?.email}`}>
                                <img src="/images/envelope.svg" alt="Email" className='me-2 my-2' />
                                {site?.manager?.email}<br/>
                            </a>
                        : ''
                        }
                        {clubWeb
                        ?
                            <a href={clubWeb} target='_blank' rel='noreferrer'>
                                <img src="/images/web.svg" alt="Web" className='me-2 my-2' />
                                {clubWeb}<br/>
                            </a>
                        : ''
                        }
                         <Col className='d-flex flex-wrap serviceInverse feature-card mt-3'>
                            {site?.services?.map ((service, i) => {
                                return (
                                    <div className='item' key={i}>
                                        <img src={`/images/services/${service}.svg`} alt={service} />
                                        {t('services.'+service) }
                                    </div>
                                )
                            })}
                        </Col>
                    </Col>
                    <Col xs={12} lg={7} className='text-center p-1'>
                        <div className='mapa'>
                            <div dangerouslySetInnerHTML={{ __html: site?.location }} />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}