import React, { useEffect } from 'react'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import Loader from '../../components/design/Loader/Loader'
import { getCart } from '../../actions/payments/cartActions'
export default function FinishedCart() {
	const {clientauth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {loading, error, cart} = useSelector(state => state.cart)

    const {cartId, cartReference} = JSON.parse(sessionStorage.getItem('dataIncrono'))

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (cartId) {
            dispatch(getCart(cartId, cartReference))
        }
    }, [cartId, cartReference, dispatch])

    return (
        <>
            <MenuMarketplace/>
            <Container className='market'>
                <div>
                    <Card className='border-0'>
                        <Card.Body>
                            <h2>{t("cart.purchaseSummary")}</h2>
                            <hr className="mb-4"/>
                            {
                                loading &&
                                    <Loader />
                            }
                            {
                                error &&
                                    <Alert variant='danger'>{error}</Alert>
                            }
                            {
                                cart &&
                                    <>
                                        {cart.inscriptions?.map((item, index) => (
                                            <Row key={index} className='border py-4 px-3 rounded my-5'>
                                                <Col xs={12} md={4}>
                                                    <img src={item.activity?.image || `/images/sports/${item.inscription?.activity?.sport}.jpg`} alt={item.activity?.title} className="w-100"/>
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <Row className='mb-5'>
                                                        <Col xs={8}>
                                                            <h3>{item.inscription?.group.title}</h3>
                                                        </Col>
                                                        {
                                                            cart.payment && item.inscription?.status === "2" &&
                                                                <Col xs={4}>
                                                                    <div className='border rounded group-price text-center py-3 fw-bold'>{Number(item.inscription?.initialPayment).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</div>
                                                                </Col>
                                                        }
                                                    </Row>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("marketplace.activity.member")}</span>
                                                            <span>{item.inscription?.client?.lastname}, {item.inscription?.client?.firstname}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("marketplace.activity.category")}</span>
                                                            <span>{item.inscription?.group.category.title}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("marketplace.activity.federated")}</span>
                                                            <span>{item.inscription?.group.federated ? t('global.yes') : t('global.no')}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("marketplace.activity.age")}</span>
                                                            <span>{item.inscription?.group.category.ageFrom} - {item.inscription?.group.category.ageTo} {t('marketplace.activity.yearsOld')}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    {item?.group?.assignableHorary ?
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("marketplace.activity.horary")}</span>
                                                                <span>{item.inscription?.group.horaries.find(g => g._id === item.inscription?.horary)?.name || ''}</span>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                    :
                                                        ''
                                                    }
                                                    {
                                                        item.inscription?.questions?.map((question, questionIndex) => (
                                                            <div className='mt-3' key={questionIndex}>
                                                                <div className='d-flex justify-content-between align-items-end'>
                                                                    <span className='fw-bold'>{question.question}</span>
                                                                    <span>{question.answer}</span>
                                                                </div>
                                                                <hr className='border my-1'/>
                                                            </div>
                                                        ))
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                        {cart.passes?.map((item, index) => (
                                            <Row key={index} className='border py-4 px-3 rounded my-5'>
                                                <Col xs={12} md={4}>
                                                    <img src={item.passType?.image || `/images/sports/${item.passType?.sport}.jpg`} alt={item.passType?.name} className="w-100"/>
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <Row className='mb-5'>
                                                        <Col xs={8}>
                                                            <h3>{item.passType?.name}</h3>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <div className='border rounded group-price text-center py-3 fw-bold'>{Number(item.passType?.price).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</div>
                                                        </Col>
                                                    </Row>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("passTypes.sector")}</span>
                                                            <span>{item.passType?.sector.name}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("passTypes.season")}</span>
                                                            <span>{item.passType?.season?.season?.name}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    {
                                                        item.passes.map((pass, index) => (
                                                            <div className='mt-3' key={index}>
                                                                <div className='d-flex justify-content-between align-items-end'>
                                                                    <span className='fw-bold'>{t("passTypes.marketplacePassPerson", { index: index + 1 })}</span>
                                                                    {
                                                                        pass.client
                                                                        ?
                                                                            <span>{pass.client?.lastname}, {pass.client?.firstname}</span>
                                                                        :
                                                                            <span>{pass.personData?.lastname}, {pass.personData?.firstname}</span>
                                                                    }
                                                                </div>
                                                                <hr className='border my-1'/>
                                                            </div>
                                                        ))
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                        {cart.tickets?.map((item, index) => (
                                            <Row key={index} className='border py-4 px-3 rounded my-5'>
                                                <Col xs={12} md={4}>
                                                    {
                                                        item.ticket?.match?.thumbnail
                                                        ?
                                                            <img src={item.ticket?.match.thumbnail?.image || `/images/bannerclub.jpg`} alt={`${item.ticket?.match.host} - ${item.ticket?.match.visitor}`} className="w-100"/>
                                                        :
                                                            item.ticket?.match?.hostBadge && item.ticket?.match?.hostBadge !== "" && item.ticket?.match?.visitorBadge && item.ticket?.match?.visitorBadge !== ""
                                                            ?
                                                                <Row>
                                                                    <Col>
                                                                        <img src={item.ticket?.match.hostBadge || `/images/bannerclub.jpg`} alt={item.ticket?.match.host} className="w-100"/>
                                                                    </Col>
                                                                    <Col>
                                                                        <img src={item.ticket?.match.visitorBadge || `/images/bannerclub.jpg`} alt={item.ticket?.match.visitor} className="w-100"/>
                                                                    </Col>
                                                                </Row>
                                                            :
                                                                item.ticket?.match?.hostBadge && item.ticket?.match?.hostBadge !== ""
                                                                ?
                                                                    <img src={item.ticket?.match.hostBadge || `/images/bannerclub.jpg`} alt={item.ticket?.match.host} className="w-100"/>
                                                                :
                                                                    <img src="/images/bannerclub.jpg" alt={item.ticket?.match?.host} className="w-100"/>
                                                    }
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <Row className='mb-5'>
                                                        <Col xs={8}>
                                                            <h3>{item.ticket?.match?.host} - {item.ticket?.match?.visitor}</h3>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <div className='border rounded group-price text-center py-3 fw-bold'>{item.ticket?.price}€</div>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        !item.ticket?.pass
                                                            ?
                                                                <>
                                                                    <div className='mt-3'>
                                                                        <div className='d-flex justify-content-between align-items-end'>
                                                                            <span className='fw-bold'>{t("matches.sector")}</span>
                                                                            <span>{item.ticket?.sector?.name}</span>
                                                                        </div>
                                                                        <hr className='border my-1'/>
                                                                    </div>
                                                                    <div className='mt-3'>
                                                                        <div className='d-flex justify-content-between align-items-end'>
                                                                            <span className='fw-bold'>{t("matches.client")}</span>
                                                                            <span>{`${item.ticket?.personData?.firstname} ${item.ticket?.personData?.lastname}`}</span>
                                                                        </div>
                                                                        <hr className='border my-1'/>
                                                                    </div>
                                                                </>
                                                            :
                                                                <div className='mt-3'>
                                                                    <div className='d-flex justify-content-between align-items-end'>
                                                                        <span className='fw-bold'>{t("matches.passPerson")}</span>
                                                                        <span>{`${item.ticket?.personData?.firstname} ${item.ticket?.personData?.lastname}`}</span>
                                                                    </div>
                                                                    <hr className='border my-1'/>
                                                                </div>
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                        {
                                            cart.reservations?.map((item, index) => (
                                                <Row key={index} className='border py-4 px-3 rounded my-5'>
                                                    <Col xs={12} md={4}>
                                                    {
                                                        item.reservation.court?.image
                                                        ?
                                                            <img src={item.reservation.court?.image || `/images/bannerclub.jpg`} alt={item.reservation.court?.name} className="w-100"/>
                                                        :
                                                            <img src="/images/bannerclub.jpg" alt={item.reservation.court?.name} className="w-100"/>
                                                    }
                                                    </Col>
                                                    <Col xs={12} md={8}>
                                                        <Row className='mb-5'>
                                                            <Col xs={8}>
                                                                <h3>{item.reservation.court?.name}</h3>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <div className='border rounded group-price text-center py-3 fw-bold'>{item.reservation.price}€</div>
                                                            </Col>
                                                        </Row>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("marketplace.reservation.club")}</span>
                                                                <span>{item.reservation.club?.name}</span>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("marketplace.reservation.client")}</span>
                                                                <span>{`${item.reservation.personData?.firstname} ${item.reservation.personData?.lastname}`}</span>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("marketplace.reservation.date")}</span>
                                                                <span>{`${String(new Date(item.reservation.date).getDate()).padStart(2, '0')}/${String(new Date(item.reservation.date).getMonth() + 1).padStart(2, '0')}/${new Date(item.reservation.date).getFullYear()}`}</span>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("marketplace.reservation.slots")}</span>
                                                                <span>
                                                                    {`${item.reservation.startHour}-${item.reservation.endHour}`}
                                                                </span>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                        {
                                            cart.payment &&
                                                <Row className='mt-3'>
                                                    <Col>
                                                        <div className='d-flex my-3'>
                                                            <h2 className='me-3 mb-0'>{t('cart.total')}:</h2>
                                                            <span>{cart.payment?.quantity?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</span>
                                                        </div>
                                                    </Col>
                                                    <hr className='my-1'/>
                                                </Row>
                                        }
                                    </>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    )
}