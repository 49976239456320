import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { useDispatch, useSelector } from 'react-redux'
import { getCourtsPublicByFilter, getSitesPublicByClubForFilter, getSportsPublicByClubForFilter } from '../../../actions/filtersActions'

export default function SearchCourts({ancho, filters = null, clubId, courts}){
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {sitesfilter, sportsfilter} = useSelector(state=>state.filters)

    const [filterOptions, setFilterOptions] = useState({club: clubId, site: null, court: null, sport: null})
    const [sitesOptions, setSitesOptions] = useState([])
    const [courtsOptions, setCourtsOptions] = useState([])
    const [sportsOptions, setSportsOptions] = useState([])

    useEffect(() =>{
        dispatch(getSitesPublicByClubForFilter(clubId))
        dispatch(getSportsPublicByClubForFilter(clubId))
    }, [dispatch])

    useEffect(() => {
        if (filters) {
            setFilterOptions(filters)
            dispatch(getCourtsPublicByFilter(filters))
        }
    }, [filters])

    useEffect(() => {
        if (courts) {
            let arrayOptionsCourts = courts.map(court => ({label: court?.name, value: court?._id}))
            arrayOptionsCourts.unshift({label: t('marketplace.allcourts'), value: null});
            setCourtsOptions(arrayOptionsCourts)
        }
    }, [courts, t])

    useEffect(() => {
        if (sitesfilter) {
            let arrayOptionsSites = sitesfilter.map(site => ({label: site?.name, value: site?._id}))
            arrayOptionsSites.unshift({label: t('marketplace.allsites'), value: null});
            setSitesOptions(arrayOptionsSites)
        }
    }, [sitesfilter, t])

    useEffect(() => {
        if (sportsfilter) {
            let arrayOptionsSports = sportsfilter.map(sport => ({label: t('sports.'+sport), value: sport}))
            arrayOptionsSports.unshift({label: t('marketplace.allsports'), value: null});
            setSportsOptions(arrayOptionsSports)
        }
    }, [sportsfilter, t])

    const handleSelectChange = (target, selecttype) => {
        setFilterOptions({
            ...filterOptions,
            [selecttype]: target.value
        })
    }

    const searchCourts = () => {
        dispatch(getCourtsPublicByFilter(filterOptions))
    }

    return (
        <Card className='p-0 form-special w-100'>
            <Card.Body className='m-auto' style={{ width: ancho }}>
                <Row>
                    <Col>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name= "site"
                                options={sitesOptions}
                                value={sitesOptions?.find(option => option?.value === filterOptions?.site)}
                                placeholder={t('marketplace.allsites')}
                                onChange={(e) => handleSelectChange(e, 'site')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name= "court"
                                options={courtsOptions}
                                value={courtsOptions?.find(option => option?.value === filterOptions?.court)}
                                placeholder={t('marketplace.allcourts')}
                                onChange={(e) => handleSelectChange(e, 'court')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name= "sport"
                                options={sportsOptions}
                                value={sportsOptions?.find(option => option?.value === filterOptions?.sport)}
                                placeholder={t('marketplace.sport')}
                                onChange={(e) => handleSelectChange(e, 'sport')}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col>
                        <Form.Control type="date" />
                    </Col> */}
                    <Col className='text-center d-flex align-items-center justify-content-around'>
                        <Button variant='secondary' type="submit" className="my-2 mx-1" onClick={() => searchCourts()}>
                            {t('marketplace.search')}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}