export const sitesReducer = (state = {loading: false, result: null, sites: [], site: null}, action) => {
    switch (action.type) {
        case 'SITE_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'SITE_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'SITE_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                sites: action.payload.sites
            }
        case 'SITE_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                site: action.payload
            }
        case 'SITE_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'SITE_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'SITE_REMOVE_RESULT':
            return {
                ...state,
                loading: false,
                result: action.payload,
                sites: action.payload.message === "deleteSiteSuccess" ? state.sites.filter(s => s._id !== action.payload.site) : state.sites
            }
        case 'SITE_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}