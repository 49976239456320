import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getInscriptions = () => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTIONS_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/inscription/getall/', {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'INSCRIPTION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTIONS_NOT_LOADING'
        })
    }
}

export const getInscription = (inscription) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/inscription/getinscription/'+ inscription, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'INSCRIPTION_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTION_NOT_LOADING'
        })
    }
}

export const getInscriptionsByClub = (club,canceled = false) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTIONS_LOADING'
    })
    try {
        const { data, status } = await Axios.get(`/api/inscription/getinscriptionsbyclub/${club}${canceled ? '?canceled=true' : ''}`, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'INSCRIPTION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTIONS_NOT_LOADING'
        })
    }
}

export const getInscriptionsByGroup = (group) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTIONS_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/inscription/getinscriptionsbygroup/'+ group, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'INSCRIPTION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTIONS_NOT_LOADING'
        })
    }
}

export const getInscriptionsByClient = (client) => async (dispatch) => {
    dispatch({
        type: 'INSCRIPTIONS_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/inscription/getinscriptionsbyclient/'+ client, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'INSCRIPTION_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'INSCRIPTIONS_NOT_LOADING'
        })
    }
}

export const editInscription = (inscription, newId, typechange, usertype) => async (dispatch) => {
    // dispatch({
    //     type: 'INSCRIPTION_LOADING'
    // })
    try {
        const { data, status} = await Axios.put('/api/inscription/edit/'+ inscription, {changeToId: newId, typechange, usertype}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'INSCRIPTION_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        // dispatch({
        //     type: 'INSCRIPTION_NOT_LOADING'
        // })
    }
}