import { Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next"

export default function Loading({id='loading', show = false}){
    const { t } = useTranslation()

    return (
        <>
        <Modal 
            show={show} 
            id={id} 
            tabIndex={-1} 
            aria-labelledby="contained-modal-title-vcenter"
            size="sm"
            centered
            role="dialog">                        
                <div className="modal-content modal-rounded modal-xs">
                    <Modal.Body>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('global.loading')}...</span>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
        </Modal>
        </>
      )
}