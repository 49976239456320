import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getPassTypePublicByUrl } from '../../../actions/ticketing/passTypesActions'
import Loader from '../../../components/design/Loader/Loader'
import { Link } from 'react-router-dom'
import Description from './Description'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import UsersRegistered from '../../../components/Banners/UsersRegistered'
import BannerPhrase2 from '../../../components/Banners/BannerPhrase2'
import Suscribete from '../../../components/Banners/Subscribe'
import NotFoundPage from '../../error/404'
import SidebarSite from '../../../components/Activities/SidebarSite'
import PassPurchase from './PassPurchase'
import { getCommissionByClub } from '../../../actions/settings/commissionsActions'

export default function PassType() {
    const { t } = useTranslation()
    const backgroundGeneric = '/images/fondo-auth.jpg'

	const dispatch = useDispatch()
    const {clubUrl, passTypeUrl} = useParams()

    const {clubMarket: club, loadingclub, resultclub} = useSelector(state=>state.clubs)
    const {passType, loading, result} = useSelector(state=>state.passTypes)

    const [activeTab, setActiveTab] = useState("description")

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch, clubUrl])

    useEffect(() =>{
        if(club){
            dispatch(getPassTypePublicByUrl({url: passTypeUrl, club: club?._id}))
            dispatch(getCommissionByClub(club?._id))
        }
    // eslint-disable-next-line
    }, [club, passTypeUrl])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image={club?.bannerMarketplace || backgroundGeneric}
                title={passType?.name}
                section={passType?.name}
            />
            <Container className='market'>
                {
                loading || loadingclub
                ?
                    <Loader/>
                :
                    resultclub === 'thereisnotclub' || result === 'thereisnotactivity'
                    ?
                        <NotFoundPage />
                    :
                    club &&
                        <Row>
                            <Col xs={12} lg={9}>
                                <div className='submenu my-3'>
                                    <Nav as="ul">
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("description")} active={activeTab === "description"}>{t('passTypes.description')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("passPurchase")} active={activeTab === "passPurchase"}>{t('passTypes.passPurchase')}</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                {
                                    activeTab === "description" &&
                                        <Description passType={passType} stadium={club?.stadium}/>
                                }
                                {
                                    activeTab === "passPurchase" &&
                                        <PassPurchase passType={passType} club={club}/>
                                }
                            </Col>
                            <Col xs={12} lg={3} className="my-3">
                                <SidebarSite club={club} />
                            </Col>
                            <Col xs={12} lg={9}>
                                <div>
                                    <Row className='my-3'>
                                        <Col xs={12} sm={6} className='my-3 my-sm-0'>
                                            <UsersRegistered />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <BannerPhrase2
                                                phrase={t('marketplace.banners.yoursport')}
                                                image="/images/banner-incrono01.jpg"
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row className='h-100'>
                                        <Col xs={12} className='columna1 d-flex flex-column'>
                                            <Suscribete />
                                        </Col>
                                    </Row> */}
                                </div>
                            </Col>
                        </Row>
                }

            </Container>
            <FooterMarketplace className='m-0'/>
        </>


    )
}