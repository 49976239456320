import CartDownloads from '../pages/marketplace/CartDownloads'
import i18n from 'i18next'

const t = i18n.t.bind(i18n)
// Mapeo de alias a paths reales
export const aliasPublicAccessMap = {
    [`${t("url.publicAccess.cartDownloads")}/:cartId/:cartReference`]: "cartDownloads",
}

// Función auxiliar para obtener el componente basado en el path real
export const getPublicAccessComponent = (path) => {
    switch (path) {
        case "cartDownloads": return <CartDownloads />
        default: return <CartDownloads />;
    }
}