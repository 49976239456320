import React from "react";
import { Card, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ClubSchedule from '../../components/design/Schedule/ClubSchedule'

export default function ScheduleSites({sites}) {
    const { t } = useTranslation()
    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

    return (
        sites.length === 1 ? (
            <Card className='border-0 my-3 p-3'>
                <h3>{t('sites.scheduleClub') }</h3>
                <div>
                {daysOfWeek.map(day => (
                    <ClubSchedule 
                        key={day} 
                        day={day}
                        schedules={sites[0].weeklySchedule[day]}
                    />
                ))}
                </div>
            </Card>
        ) : (
            <Card className='border-0 my-3 p-3'>
                <Accordion defaultActiveKey="0">
                    {sites.map((site, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index} className='border-0'>
                            <Accordion.Header className='p-0'>{site?.name}</Accordion.Header>
                            <Accordion.Body className='p-0'>
                                {daysOfWeek.map(day => (
                                    <ClubSchedule
                                        key={day} 
                                        day={day} 
                                        schedules={site.weeklySchedule[day]} 
                                    />
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Card>
        )
    )
}