import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getFeesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'FEE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/fee/getFeesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'FEE_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'FEE_NOT_LOADING'
        })
    }
}

export const getFee = (feeId) => async (dispatch) => {
    dispatch({
        type: 'FEE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/fee/getFee/'+ feeId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'FEE_GET_SUCCESS',
                payload: data?.fee
            })
        }
    } catch (error) {
        dispatch({
            type: 'FEE_NOT_LOADING'
        })
    }
}

export const addFee = (feeData) => async (dispatch) => {
    dispatch({
        type: 'FEE_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/fee/register', feeData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'FEE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'FEE_NOT_LOADING'
        })
    }
}

export const editFee = (feeId, feeData) => async (dispatch) => {
    dispatch({
        type: 'FEE_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/fee/edit/' + feeId, feeData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'FEE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'FEE_NOT_LOADING'
        })
    }
}

export const deleteFee = (feeId) => async (dispatch) => {
    dispatch({
        type: 'FEE_LOADING'
    })
    try {
        const { data, status } = await Axios.delete('/api/fee/delete/'+ feeId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'FEE_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'FEE_NOT_LOADING'
        })
    }
}