import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { editPromotion, getPromotion } from '../../../../actions/payments/promotionsActions'
import PromotionForm from '../../../../components/forms/payments/PromotionForm'

export default function EditPromotion({usertype, selectedPromotion}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading, promotion} = useSelector(state=>state.promotions)

    const [promotionForm, setPromotionForm] = useState({
        name: '',
        active: true,
        exclusive: false,
        conditions: [{
            type: 'member',
            parameters: []
        }],
        impact: {
            registrationQuantity: 0,
            registrationPercentage: 0,
            seasonPaymentQuantity: 0,
            seasonPaymentPercentage: 0
        },
        deletedConditions: []
    })

    const handleInputChange = ({target})=>{
        setPromotionForm({
            ...promotionForm,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setPromotionForm({
            ...promotionForm,
            [target.name]: target.checked
        })
    }

    const onDeleteCondition = async (index) => {
        let finalConditions = []
        let finalDeletedConditions = []

        await Promise.all(promotionForm.conditions.map(async (condition, i) => {
            if (i === index) {
                if (condition._id) {
                    finalDeletedConditions.push(condition._id)
                }
            } else {
                finalConditions.push(condition)
            }
        }))
        setPromotionForm({
            ...promotionForm,
            conditions: finalConditions,
            deletedConditions: finalDeletedConditions
        })
    }

    const handleImpactInputChange = ({target}) => {
        setPromotionForm({
            ...promotionForm,
            impact: {
                ...promotionForm.impact,
                [target.name]: target.value
            }
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()

        if(promotionForm.name !== ''){
            dispatch(editPromotion(promotion?._id, promotionForm))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('promotions.noName')
            })
        }
    }

    const addCondition = (condition) => {
        setPromotionForm({
            ...promotionForm,
            conditions: [...promotionForm.conditions, {...condition, _id: promotionForm.conditions.length}]
        })
    }

    const editCondition = (condition) => {
        setPromotionForm({
            ...promotionForm,
            conditions: promotionForm.conditions.map(formCondition => {
                if (formCondition._id === condition._id) {
                    return condition
                } else {
                    return formCondition
                }
            })
        })
    }

    useEffect(() => {
        if(result){
            let newresult = result
            dispatch({
                type: 'PROMOTION_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('promotions.editSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.promotions")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.promotions")}`)
                        }
                    })
                    break;
                case 'duplicateName':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('promotions.duplicateName')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (selectedPromotion) {
            dispatch(getPromotion(selectedPromotion))
        }
    }, [selectedPromotion, dispatch])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (promotion) {
            setPromotionForm({
                name: promotion?.name,
                active: promotion?.active,
                exclusive: promotion?.exclusive,
                conditions: promotion?.conditions || [],
                impact: {
                    registrationQuantity: promotion?.impact?.registrationQuantity,
                    registrationPercentage: promotion?.impact?.registrationPercentage,
                    seasonPaymentQuantity: promotion?.impact?.seasonPaymentQuantity,
                    seasonPaymentPercentage: promotion?.impact?.seasonPaymentPercentage
                },
                deletedConditions: []
            })
        }
    }, [promotion])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('promotions.editPromotionForm')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <PromotionForm state={promotionForm} editCondition={editCondition} addCondition={addCondition} statetype={'edit'} usertype={usertype} isAdding={loading} submit={handleEdit} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} onDeleteCondition={onDeleteCondition} onImpactInputChange={handleImpactInputChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}