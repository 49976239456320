import React,{useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'

export default function Privacypolicy () {
    const { t } = useTranslation()
	useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
    }, [])

	return (
		<>
			<MenuMarketplace/>
			<Container className='market'>
				<Row>
					<Col className='mx-auto'>
						<h1>{t('legalinformation.privacypolicy.title1')}</h1>
						<label>{t('legalinformation.privacypolicy.description1')}</label>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col className='mx-auto'>
						<h2 className='display-7'>{t('legalinformation.privacypolicy.subtitle1')}</h2>
						<div className='my-3'>{t('legalinformation.privacypolicy.subdescription1')}</div>
						<div className='my-3'>{t('legalinformation.privacypolicy.subdescription2')}</div>
						<div className='my-3'>{t('legalinformation.privacypolicy.subdescription3')}</div>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col className='mx-auto'>
						<h2 className='display-7'>{t('legalinformation.privacypolicy.subtitle2')}</h2>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle1')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription1')}</div>
							</Col>
						</Row>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle2')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription2')}</div>
							</Col>
						</Row>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle3')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription3')}</div>
							</Col>
						</Row>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle4')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription4')}</div>
							</Col>
						</Row>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle5')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription5')}</div>
							</Col>
						</Row>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle6')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription6')}</div>
							</Col>
						</Row>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle7')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription7')}</div>
							</Col>
						</Row>
						<Row className='px-2 my-3'>
							<Col>
								<h3>{t('legalinformation.privacypolicy.subsubtitle8')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription8')}</div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='my-3'>
					<Col className='mx-auto'>
						<h2 className='display-7'>{t('legalinformation.privacypolicy.subtitle3')}</h2>
						<ol>
							<li className='my-3'><h3>{t('legalinformation.privacypolicy.subsubtitle9')}</h3>
								<div dangerouslySetInnerHTML={{ __html: t('legalinformation.privacypolicy.subsubdescription9')}}></div>
							</li>
							<li className='my-3'><h3>{t('legalinformation.privacypolicy.subsubtitle10')}</h3>
								<ul>
									<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription10a')}
										<div><strong>{t('legalinformation.privacypolicy.subsubdescription10b')}</strong></div>
										<div>{t('legalinformation.privacypolicy.subsubdescription10c')}</div>
										<div><strong>{t('legalinformation.privacypolicy.subsubdescription10d')}</strong></div>
										<div>{t('legalinformation.privacypolicy.subsubdescription10e')}</div>
										<div><strong>{t('legalinformation.privacypolicy.subsubdescription10f')}</strong></div>
										<div>{t('legalinformation.privacypolicy.subsubdescription10g')}</div>
									</li>
									<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription10h')}
										<div>{t('legalinformation.privacypolicy.subsubdescription10i')}</div>
										<ol type="a">
											<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription10j')}
												<div>{t('legalinformation.privacypolicy.subsubdescription10k')}</div>
											</li>
											<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription10l')}
												<div>{t('legalinformation.privacypolicy.subsubdescription10m')}</div>
												<div>{t('legalinformation.privacypolicy.subsubdescription10n')}</div>
											</li>
										</ol>
									</li>
								</ul>
							</li>
							<li className='my-3'><h3>{t('legalinformation.privacypolicy.subsubtitle11')}</h3>
								<div className='my-3'>{t('legalinformation.privacypolicy.subsubdescription11a')}</div>
								<div className='my-3'>
									<strong>{t('legalinformation.privacypolicy.subsubdescription11b')}</strong><br/>
									{t('legalinformation.privacypolicy.subsubdescription11c')}
								</div>
								<div className='my-3'>
									<strong>{t('legalinformation.privacypolicy.subsubdescription11d')}</strong><br/>
									{t('legalinformation.privacypolicy.subsubdescription11e')}
								</div>
								<div className='my-3'>
									<strong>{t('legalinformation.privacypolicy.subsubdescription11f')}</strong><br/>
									{t('legalinformation.privacypolicy.subsubdescription11g')}
								</div>
								<div>{t('legalinformation.privacypolicy.subsubdescription11h')}</div>
							</li>
							<li className='my-3'><h3>{t('legalinformation.privacypolicy.subsubtitle12')}</h3>
								<div className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12a')}</div>
								<div className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12b')}</div>
								<ul>
									<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12c')}</li>
									<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12d')}</li>
									<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12e')}</li>
									<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12f')}</li>
									<li className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12g')}</li>
								</ul>
								<div className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12h')}</div>
								<div className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12i')}</div>
								<div className='my-3'>{t('legalinformation.privacypolicy.subsubdescription12j')} <a href="https://www.aepd.es/" target="_blank" rel="noreferrer">https://www.aepd.es/</a></div>
							</li>
							<li className='my-3'><h3>{t('legalinformation.privacypolicy.subsubtitle13')}</h3>
								<div>{t('legalinformation.privacypolicy.subsubdescription13a')} <a href="mailto:lopd@incrono.com" target="_blank" rel="noreferrer">lopd@incrono.com</a></div>
							</li>
						</ol>
					</Col>
				</Row>
			</Container>
			<FooterMarketplace className='m-0'/>
		</>
	)
}