import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { addMatch } from '../../../../actions/ticketing/matchesActions'
import { getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import MatchForm from '../../../../components/forms/matches/MatchForm'
import { getPassTypesByClub } from '../../../../actions/ticketing/passTypesActions.js'
import { getFeesByClub } from '../../../../actions/ticketing/feesActions.js'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions.js'

export default function AddMatch({usertype}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading: matchesLoading} = useSelector(state=>state.matches)
    const {sectors, loading: sectorsLoading} = useSelector(state=>state.sectors)
    const {passTypes} = useSelector(state=>state.passTypes)
    const {fees} = useSelector(state=>state.fees)
	const {club} = useSelector(state=>state.clubs)
    const {commission} = useSelector(state=>state.commissions)

    const [newMatch, setNewMatch] = useState({
        host: '',
        hostBadge: null,
        visitor: '',
        visitorBadge: null,
        thumbnail: null,
        date: '',
        hour: '',
        day: '',
        sport: null,
        template: null,
        sectors: [],
        passTypes: [],
        usePassTypes: false,
        ticketOrientation: false,
        active: true
    })

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newMatch.host !== '' && newMatch.visitor !== ''){
            if (newMatch.date !== "" && newMatch.hour !== "") {
                if (newMatch.sectors.length > 0) {
                    dispatch(addMatch({...newMatch, club: club._id}))
                } else {
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('matches.noSectors')
                    })
                }
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.ok'),
                    icon: 'warning',
                    text: t('matches.noTime')
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('matches.noTeams')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'MATCH_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('matches.addSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.matches")}`)
                        }
                    })
                    break;
                case 'duplicateDate':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('matches.duplicateDate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (club?._id) {
            dispatch(getSectorsByClub(club?._id))
            dispatch(getPassTypesByClub(club?._id))
            dispatch(getFeesByClub(club?._id))
            dispatch(getCommissionByClub(club?._id))
        }
    }, [club, dispatch])

    useEffect(() => {
        if (club) {
            setNewMatch({
                host: club?.name,
                hostBadge: club?.avatar,
                visitor: null,
                visitorBadge: null,
                thumbnail: null,
                date: null,
                hour: null,
                day: null,
                sport: null,
                template: null,
                ticketOrientation: false,
                active: true,
                sectors: sectors?.map(sector => {
                    return {
                        sector: sector._id,
                        marketplaceVisible: true,
                        active: true,
                        fees: fees?.filter(f => f.prices.some(p => p.sector === sector._id))?.map(fee => {
                            return {
                                fee: fee._id,
                                active: true,
                                price: fee.prices.find(p => p.sector === sector._id)?.price || '0'
                            }
                        })
                    }
                }),
                passTypes: passTypes?.map(passType => {
                    return {
                        passType: passType._id,
                        price: '0',
                        deleted: false,
                        active: true
                    }
                }),
                usePassTypes: false
            })
            
        }
    }, [club, fees, passTypes, sectors])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('matches.createNewMatch')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <MatchForm
                                state={newMatch}
                                setState={setNewMatch}
                                statetype={'add'}
                                usertype={usertype}
                                isAdding={matchesLoading || sectorsLoading}
                                submit={handleAdd}
                                sectors={sectors}
                                passTypes={passTypes}
                                fees={fees}
                                commission={commission}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}