import { useTranslation } from "react-i18next"

const ServicesComponent = () => {
    const { t } = useTranslation()

    let services = [
        {"label": t("services.lockerRooms"), "value": "lockerRooms"},
        {"label": t("services.cafeteria"), "value": "cafeteria"},
        {"label": t("services.conferenceRoom"), "value": "conferenceRoom"},
        {"label": t("services.receptionArea"), "value": "receptionArea"},
        {"label": t("services.sauna"), "value": "sauna"},
        {"label": t("services.spa"), "value": "spa"},
        {"label": t("services.swimmingPool"), "value": "swimmingPool"},
        {"label": t("services.jacuzzi"), "value": "jacuzzi"},
        {"label": t("services.gym"), "value": "gym"},
        {"label": t("services.athleticsTrack"), "value": "athleticsTrack"},
        {"label": t("services.paddleCourts"), "value": "paddleCourts"},
        {"label": t("services.tennisCourts"), "value": "tennisCourts"},
        {"label": t("services.childrensPlayArea"), "value": "childrensPlayArea"},
        {"label": t("services.eventHall"), "value": "eventHall"},
        {"label": t("services.sportsEquipmentStore"), "value": "sportsEquipmentStore"},
        {"label": t("services.parkingArea"), "value": "parkingArea"},
        {"label": t("services.changingRooms"), "value": "changingRooms"},
        {"label": t("services.accesible"), "value": "accesible"}
    ]

    services = services?.sort((a, b) => (a.label > b.label ? 1 : -1))

    return { 'services': services}
}

export default ServicesComponent