export const commissionsReducer = (state = {loadingcommission: true, resultcommission: null, commissions: null, commission: null } , action) => {
    switch (action.type) {
        case 'COMMISSION_GET_SUCCESS':
            return {
                ...state,
                loadingcommission:false,
                commission: action.payload.commission
            }
        case 'COMMISSION_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resultcommission: action.payload.message
            }
        case 'COMMISSION_RESET_RESULT':
            return {
                ...state,
                resultcommission: null
            }
        default:
            return state
    }
}