import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import GroupForm from '../../../../components/forms/activities/GroupForm'
import { getActivitiesByClub } from '../../../../actions/activities/activitiesActions'
import { getCategoriesByClub } from '../../../../actions/activities/categoriesActions'
import { addGroup } from '../../../../actions/activities/groupsActions'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions'

export default function GroupAdd({usertype, handleGroupSelect}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {resultgroup, loadinggroup} = useSelector(state=>state.groups)
	const {club} = useSelector(state=>state.clubs)
	const {commission} = useSelector(state=>state.commissions)
	const {activities} = useSelector(state=>state.activities)
	const {categories} = useSelector(state=>state.categories)

    const [newGroup, setNewGroup] = useState({
        club: club?._id,
        activity: null,
        category: null,
        title: '',
        seasonStart: null,
        seasonEnd: null,
        seasonPayments: [],
        registrationStart: null,
        registrationEnd: null,
        registrationPayment: {
            title: '',
            maxdate: null,
            price: 0
        },
        assignableHorary: false,
        verify: false,
        postpayverified: false,
        federated: false,
        questions: [],
        horaries: [{
            name: 'General',
            gaps: 100,
            trainer: '',
            schedule: [],
            active: true
        }],
        active: false
    })

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(club){
            dispatch(getCommissionByClub(club?._id))
            dispatch(getActivitiesByClub(club?._id))
            dispatch(getCategoriesByClub(club?._id))

            setNewGroup({...newGroup, club: club._id})
        }
    //eslint-disable-next-line
    }, [dispatch, club])

    const handleInputChange = ({target})=>{
        setNewGroup({
            ...newGroup,
            [target.name]: target.value
        })
    }

    const handleInputRegistrationChange = ({target})=>{
        setNewGroup({
            ...newGroup,
            registrationPayment: {
                ...newGroup.registrationPayment,
                [target.name]: target.value
            }
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewGroup({
            ...newGroup,
            [target.name]: target.checked
        })
    }

    const handleDaterangeChange = (value, index) => {
        setNewGroup({
            ...newGroup,
            [`${index}Start`]: value[0],
            [`${index}End`]: value[1]
        })
    }

    const handleSelectChange = (target, selectType) => {
        setNewGroup({
            ...newGroup,
            [selectType]: target.value
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newGroup?.registrationStart !== null && newGroup?.registrationEnd !== null && newGroup?.seasonStart !== null && newGroup?.seasonEnd !== null){
            dispatch(addGroup(newGroup))
        }else{
            if(newGroup?.registrationStart === null || newGroup?.registrationEnd === null){
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.great'),
                    icon: 'warning',
                    text: t('groups.registrationneeded')
                })
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.great'),
                    icon: 'warning',
                    text: t('groups.seasonneeded')
                })
            }
        }
    }

    useEffect(() => {
        if(resultgroup){
            let newresult = resultgroup
            dispatch({
                type: 'GROUP_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('groups.addsuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.groups")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.groups")}`)
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultgroup])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('groups.createnewgroup')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <GroupForm state={newGroup} club={club} activities={activities} categories={categories} commission={commission} statetype={'add'} usertype={usertype} isAdding={loadinggroup} submit={handleAdd} inputChange={handleInputChange} selectChange={handleSelectChange} inputRegistrationChange={handleInputRegistrationChange} checkboxChange={handleCheckboxChange} daterangeChange={handleDaterangeChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}