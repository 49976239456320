import React, { useEffect, useState } from 'react'
import useAuth from '../../../context/auth/useAuth'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../actions/profiles/usersActions'
import Loader from '../../../components/design/Loader/Loader'
import { getClient } from '../../../actions/profiles/clientsActions'
import { addCartItem } from '../../../actions/payments/cartActions'
import Swal from 'sweetalert2'
import Select from "react-select"
import { getSector, getSectorUnavailableSeats } from '../../../actions/ticketing/sectorsActions'
import MemberSelect from '../../../components/design/Select/MemberSelect'
import SectorMap from '../../../components/ticketing/SectorMap'

export default function PassPurchase({passType, club}) {
	const {clientauth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {user, loading} = useSelector(state=>state.users)
    const {client, loadingclient} = useSelector(state => state.clients)
    const {loading: sectorLoading, sector, unavailableSeats} = useSelector(state=>state.sectors)
	const {commission} = useSelector(state=>state.commissions)
    const {status, items} = useSelector(state => state.cart)

    const [seasonSelected, setSeasonSelected] = useState(passType?.seasons ? {label: passType.seasons[0].season.name, value: passType.seasons[0].season._id} : null)
    const [clientOptions, setClientOptions] = useState([])

    const [cartUsedSeats, setCartUsedSeats] = useState([])

    // Estado para la información de las personas de los abonos que se crearán
    const [passes, setPasses] = useState([])

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (seasonSelected) {
            let allFieldsFilled = true
            let passIndex = 0
            let passesToSend = []
            for await (const pass of passes) {
                if (allFieldsFilled) {
                    if (
                        (passIndex === 0 && (!pass ||
                        !pass?.firstname || pass?.firstname === "" ||
                        !pass?.lastname || pass?.lastname === "" ||
                        !pass?.email || pass?.email === "" ||
                        !pass?.phone || pass?.phone === "" ||
                        !pass?.birthDate || pass?.birthDate === "")) ||
                        ((pass?.firstname && pass?.firstname !== "") && (!pass?.email || pass?.email === "" || !pass?.lastname || pass?.lastname === "" || !pass?.phone || pass?.phone === "" || !pass?.birthDate || pass?.birthDate === "")) ||
                        ((pass?.lastname && pass?.lastname !== "") && (!pass?.email || pass?.email === "" || !pass?.firstname || pass?.firstname === "" || !pass?.phone || pass?.phone === "" || !pass?.birthDate || pass?.birthDate === "")) ||
                        ((pass?.email && pass?.email !== "") && (!pass?.lastname || pass?.lastname === "" || !pass?.firstname || pass?.firstname === "" || !pass?.phone || pass?.phone === "" || !pass?.birthDate || pass?.birthDate === "")) ||
                        ((pass?.phone && pass?.phone !== "") && (!pass?.email || pass?.email === "" || !pass?.lastname || pass?.lastname === "" || !pass?.firstname || pass?.firstname === "" || !pass?.birthDate || pass?.birthDate === "")) ||
                        ((pass?.birthDate && pass?.birthDate !== "") && (!pass?.email || pass?.email === "" || !pass?.lastname || pass?.lastname === "" || !pass?.phone || pass?.phone === "" || !pass?.firstname || pass?.firstname === ""))
                    ) {
                        allFieldsFilled = false
                    }
                }

                if (!(!pass ||
                    !pass?.firstname || pass?.firstname === "" ||
                    !pass?.lastname || pass?.lastname === "" ||
                    !pass?.email || pass?.email === "" ||
                    !pass?.phone || pass?.phone === "" ||
                    !pass?.birthDate || pass?.birthDate === "")) {
                        passesToSend.push(pass)
                }
                passIndex++
            }

            if (allFieldsFilled) {
                let seatsOk = true
                if (sector.numerated) {
                    for await (const pass of passes) {
                        if (pass.email && (!pass || !pass?.seat)) {
                            seatsOk = false
                        }
                    }
                }

                if (seatsOk) {
                    // Se añade al carrito
                    dispatch(addCartItem({
                        passType: passType,
                        paymentClient: clientauth?.client,
                        commission: commission.ticketing,
                        club: club,
                        passes: passesToSend,
                        season: seasonSelected.value
                    }))
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: t('cart.noSeat'),
                        showConfirmButton: true,
                        confirmButtonText: t('global.accept'),
                        timer: 2000
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    text: t('cart.noPassFields'),
                    showConfirmButton: true,
                    confirmButtonText: t('global.accept'),
                    timer: 2000
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                text: t('cart.noPassSeason'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        }
    }

    const handlePassInputChange = (target, index) => {
        setPasses(passes.map((pass, passIndex) => {
            if (passIndex === index) {
                if (pass) {
                    return {
                        ...pass,
                        [target.name]: target.value
                    }
                } else {
                    return {
                        [target.name]: target.value
                    }
                }
            } else {
                return pass
            }
        }))
    }

    const handleClientChange = (selectedClient, index) => {
        setPasses(passes.map((pass, passIndex) => {
            if (passIndex === index) {
                if (selectedClient) {
                    const client = clientOptions.find(client => client._id === selectedClient.value)
                    return {
                        client: selectedClient,
                        firstname: client.firstname,
                        lastname: client.lastname,
                        email: client.email,
                        phone: client.phone,
                        birthDate: client?.birthdate?.substring(0, 10) || '',
                        dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                    }
                } else {
                    return null
                }
            } else {
                return pass
            }
        }))
    }

    const handleSeatClick = (index, row, column) => {
        setPasses(passes.map((pass, passIndex) => {
            if (passIndex === index) {
                if (pass) {
                    return {
                        ...pass,
                        seat: {
                            row: row,
                            column: column
                        }
                    }
                } else {
                    return {
                        seat: {
                            row: row,
                            column: column
                        }
                    }
                }
            } else {
                return pass
            }
        }))
    }

    const calculatePrice = (price) => {
        if (price <= 0) {
            return 0.00
        } else {
            const ticketingCommission = commission?.ticketing?.find(activity => price >= activity.lowPrice && price <= activity.highPrice);
            return (Math.ceil((parseFloat(price) + parseFloat(price * (ticketingCommission?.percentage || 0) / 100) + parseFloat(ticketingCommission?.double || 0)) * 100) / 100).toFixed(2)
        }
    }

    useEffect(() => {
        if (clientauth?.id) {
            dispatch(getUser(clientauth?.id))
            dispatch(getClient(clientauth?.client))
        }
    }, [clientauth, dispatch])

    useEffect(() => {
        if (passType && clientauth) {
            dispatch(getSector(passType.sector._id))
        }
    }, [passType, clientauth, dispatch])

    useEffect(() => {
        if (client && user) {
            // Opciones de clientes
            let clients = user.members || []
            clients.unshift(client)
            setClientOptions(clients)

            // Datos de abonos
            let passes = []
            for (let i = 0; i < passType.people; i++) {
                if (i === 0) {
                    passes.push({
                        client: {...client, birthdate: client.birthdate ? new Date(client.birthdate) : null, label: `${client.firstname} ${client.lastname}`, value: client._id, imageUrl: client.avatar},
                        firstname: client.firstname,
                        lastname: client.lastname,
                        email: client.email,
                        phone: client.phone,
                        birthDate: client?.birthdate?.substring(0, 10) || '',
                        dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                    })
                } else {
                    passes.push(null)
                }
            }
            setPasses(passes)
        }
    }, [client, passType, user])

    useEffect(() => {
        if (sector?.numerated) {
            dispatch(getSectorUnavailableSeats(sector?._id))
        }
    }, [sector, dispatch])

    useEffect(() => {
        if (status === 'addSuccess') {
            Swal.fire({
                icon: 'success',
                text: t('cart.' + status),
                showConfirmButton: false,
                timer: 1200
            })
            // Reseteamos formulario
            let passes = []
            for (let i = 0; i < passType.people; i++) {
                if (i === 0) {
                    passes.push({
                        client: {...client, birthdate: client.birthdate ? new Date(client.birthdate) : null, label: `${client.firstname} ${client.lastname}`, value: client._id, imageUrl: client.avatar},
                        name: client.firstname,
                        lastname: client.lastname,
                        email: client.email,
                        phone: client.phone,
                        birthDate: client?.birthdate?.substring(0, 10) || '',
                        dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                    })
                } else {
                    passes.push(null)
                }
            }
            setPasses(passes)
            setSeasonSelected(passType?.seasons ? {label: passType.seasons[0].season.name, value: passType.seasons[0].season._id} : null)
        }
    }, [status, dispatch, t, passType, client])

    useEffect(() => {
        if (items) {
            let usedSeats = []

            for (const item of items) {
                // Es una entrada
                if (item.match) {
                    if (item.ticket.sector && item?.ticket?.sector?._id === passType?.sector?._id) {
                        const matchDate = new Date(item.match.date)
                        const seasonEndDate = new Date(passType.seasons?.find(s => s.season._id === seasonSelected.value)?.endDate || new Date())
                        if (seasonEndDate > matchDate && item.ticket?.seat) {
                            usedSeats.push([item.ticket.seat.row + 1, item.ticket.seat.column + 1])
                        }
                    }
                }

                // Son abonos
                if (item.passType) {
                    if (item.passType.sector._id === passType?.sector?._id && item.season === seasonSelected.value) {
                        for (const pass of item.passes) {
                            if (pass.seat) {
                                usedSeats.push([pass.seat.row + 1, pass.seat.column + 1])
                            }
                        }
                    }
                }
            }

            setCartUsedSeats(usedSeats)
        }
    }, [items, passType, seasonSelected])

    return (
        <>
            {
                loading || loadingclient || sectorLoading
                    ?
                        <Loader/>
                    :
                        <div>
                            <Form onSubmit={handleSubmit}>
                                <Card className='border-0 my-4'>
                                    <Card.Body>
                                        {
                                            seasonSelected
                                            ?
                                                <Row className='mb-5'>
                                                    <Col xs={8}>
                                                        <h2>{t('passTypes.passPurchase')}</h2>
                                                        <hr className="mb-4"/>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <div className='border rounded group-price text-center py-3 fw-bold'>{calculatePrice(passType?.seasons?.find(s => s.season._id === seasonSelected?.value)?.price || 0)}€</div>
                                                    </Col>
                                                </Row>
                                            :
                                                <>
                                                    <h2>{t('passTypes.passPurchase')}</h2>
                                                    <hr className="mb-4"/>
                                                </>
                                        }
                                        <p>
                                            {t("passTypes.passTypeText1")}{club.name}{t("passTypes.passTypeText2")}
                                        </p>
                                        <p>
                                            {t("passTypes.passTypeText3")}<span className='fw-bold'>{club.phone}</span>.
                                        </p>
                                        {
                                            !clientauth || clientauth?.usertype === 'club'
                                                ?
                                                    clientauth?.usertype === 'club' ?
                                                        <div className='contenedor-vacio text-center py-4'>
                                                            <h4 className='fw-bold'>{t('marketplace.registerOrLogIn')}</h4>
                                                            {t('marketplace.activity.needAccount')}
                                                        </div>
                                                    :
                                                        <div className='contenedor-vacio text-center py-4'>
                                                            <h4 className='fw-bold'>{t('marketplace.registerOrLogIn')}</h4>
                                                            {t('marketplace.passType.needAccount')}
                                                            <div className='mt-3'>
                                                                <Link to={`/${t("url.auth.registerclient")}`} className='btn btn-primary m-3'>
                                                                    {t('signin.register')}
                                                                    <img alt={t("signin.register")} src='/images/register.svg' className='ms-2'/>
                                                                </Link>
                                                                <Link to={`/${t("url.auth.login")}`} className='btn btn-secondary m-3'>
                                                                    {t('signin.login')}
                                                                    <img alt={t("signin.login")} src='/images/login.svg' className='ms-2'/>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                :
                                                    <div className='cuerpo p-0'>
                                                        <div className='mt-4'>
                                                            <Form.Label htmlFor='season'>{t('passTypes.season')}</Form.Label>
                                                            <Select
                                                                className="select has-content border-none"
                                                                name="season"
                                                                options={passType?.seasons?.map(season => {
                                                                    return { label:  season.season.name, value: season.season._id}
                                                                })}
                                                                placeholder={t('passTypes.season')}
                                                                onChange={(value) => setSeasonSelected(value)}
                                                                value={seasonSelected}
                                                            />
                                                        </div>
                                                        <div className='mt-4'>
                                                            <h2 className='mb-5'>{t('passTypes.passesData')}</h2>
                                                            {
                                                                passes.map((pass, passIndex) => (
                                                                    <Card key={passIndex} className='my-3'>
                                                                        <h3>{t('passTypes.passPerson', { index: passIndex + 1 })}</h3>
                                                                        <Row className='my-3'>
                                                                            <MemberSelect
                                                                                members={clientOptions?.filter(member => !passes.filter((pass, index) => index !== passIndex)?.find(pass => pass?.client?.value === member._id))}
                                                                                value={pass?.client}
                                                                                onChange={selectedClient => handleClientChange(selectedClient, passIndex)}
                                                                                isClearable
                                                                            />
                                                                        </Row>
                                                                        <Row className='my-3'>
                                                                            <Col xs={12} md={6}>
                                                                                <Form.Group className='form-group'>
                                                                                    <Form.Control
                                                                                        type='text'
                                                                                        name="firstname"
                                                                                        value={pass?.firstname || ""}
                                                                                        className={pass?.firstname ? 'has-content' : ''}
                                                                                        disabled={pass?.client ? true : false}
                                                                                        onChange={({target}) => {
                                                                                            if(!pass?.client) {
                                                                                                handlePassInputChange(target, passIndex)
                                                                                            }
                                                                                        }}
                                                                                        required={passIndex === 0}
                                                                                    />
                                                                                    <Form.Label htmlFor='firstname'>{t('passTypes.pass.name')}</Form.Label>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col xs={12} md={6}>
                                                                                <Form.Group className='form-group'>
                                                                                    <Form.Control
                                                                                        type='text'
                                                                                        name="lastname"
                                                                                        value={pass?.lastname || ""}
                                                                                        className={pass?.lastname ? 'has-content' : ''}
                                                                                        disabled={pass?.client ? true : false}
                                                                                        onChange={({target}) => {
                                                                                            if(!pass?.client) {
                                                                                                handlePassInputChange(target, passIndex)
                                                                                            }
                                                                                        }}
                                                                                        required={passIndex === 0}
                                                                                    />
                                                                                    <Form.Label htmlFor='lastname'>{t('passTypes.pass.lastname')}</Form.Label>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='my-3'>
                                                                            <Col xs={12} md={6}>
                                                                                <Form.Group className='form-group'>
                                                                                    <Form.Control
                                                                                        type='text'
                                                                                        name="email"
                                                                                        value={pass?.email || ""}
                                                                                        className={pass?.email ? 'has-content' : ''}
                                                                                        disabled={pass?.client ? true : false}
                                                                                        onChange={({target}) => {
                                                                                            if(!pass?.client) {
                                                                                                handlePassInputChange(target, passIndex)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <Form.Label htmlFor='email'>{t('passTypes.pass.email')}</Form.Label>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col xs={12} md={6}>
                                                                                <Form.Group className='form-group'>
                                                                                    <Form.Control
                                                                                        type='text'
                                                                                        name="phone"
                                                                                        value={pass?.phone || ""}
                                                                                        className={pass?.phone ? 'has-content' : ''}
                                                                                        disabled={pass?.client ? true : false}
                                                                                        onChange={({target}) => {
                                                                                            if(!pass?.client) {
                                                                                                handlePassInputChange(target, passIndex)
                                                                                            }
                                                                                        }}
                                                                                        required={passIndex === 0}
                                                                                    />
                                                                                    <Form.Label htmlFor='phone'>{t('passTypes.pass.phone')}</Form.Label>
                                                                                </Form.Group>
                                                                                {
                                                                                    pass?.client && (!pass.phone || pass.phone === "")
                                                                                    ?
                                                                                        <span className='text-danger'>* {t('passTypes.pass.userWithoutPhone')}</span>
                                                                                    :
                                                                                        pass?.birthDate && (new Date().getFullYear() - new Date(pass.birthDate).getFullYear() > 13) && (!pass.phone || pass.phone === "")
                                                                                        ?
                                                                                            <span className='text-danger'></span>
                                                                                        :
                                                                                            <></>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='my-3'>
                                                                            <Col xs={12} md={6}>
                                                                                <Form.Group className='form-group'>
                                                                                    <Form.Control
                                                                                        type='date'
                                                                                        name="birthDate"
                                                                                        value={pass?.birthDate || ""}
                                                                                        className='has-content'
                                                                                        disabled={pass?.client ? true : false}
                                                                                        onChange={({target}) => {
                                                                                            if(!pass?.client) {
                                                                                                handlePassInputChange(target, passIndex)
                                                                                            }
                                                                                        }}
                                                                                        required={passIndex === 0}
                                                                                    />
                                                                                    <Form.Label htmlFor='birthDate'>{t('passTypes.pass.birthDate')}</Form.Label>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col xs={12} md={6}>
                                                                                <Form.Group className='form-group'>
                                                                                    <Form.Control
                                                                                        type='text'
                                                                                        name="dni"
                                                                                        value={pass?.dni || ""}
                                                                                        className={pass?.dni ? 'has-content' : ''}
                                                                                        disabled={pass?.client ? true : false}
                                                                                        onChange={({target}) => {
                                                                                            if(!pass?.client) {
                                                                                                handlePassInputChange(target, passIndex)
                                                                                            }
                                                                                        }}
                                                                                        required={(pass?.client && (!pass.dni || pass.dni === "")) || (pass?.birthDate && (new Date().getFullYear() - new Date(pass.birthDate).getFullYear() > 13) && (!pass.dni || pass.dni === ""))}
                                                                                    />
                                                                                    <Form.Label htmlFor='dni'>{t('passTypes.pass.dni')}</Form.Label>
                                                                                </Form.Group>
                                                                                {
                                                                                    pass?.client && (!pass.dni || pass.dni === "")
                                                                                    ?
                                                                                        <span className='text-danger'>* {t('passTypes.pass.userWithoutDni')}</span>
                                                                                    :
                                                                                        pass?.birthDate && (new Date().getFullYear() - new Date(pass.birthDate).getFullYear() > 13) && (!pass.dni || pass.dni === "")
                                                                                        ?
                                                                                            <span className='text-danger'>* {t('passTypes.pass.userWithoutDni')}</span>
                                                                                        :
                                                                                            <></>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        {
                                                                            sector.numerated &&
                                                                            <SectorMap
                                                                                rows={sector?.distribution?.rows}
                                                                                columns={sector?.distribution?.columns}
                                                                                numeration={sector?.distribution?.numeration}
                                                                                unavailable={unavailableSeats?.unavailable?.map(s => {
                                                                                    return [s[0] + 1, s[1] + 1]
                                                                                })?.concat(passes.filter((p, index) => index !== passIndex).map(p => {
                                                                                    if (p?.seat) {
                                                                                        return [p.seat.row + 1, p.seat.column + 1]
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                }).filter(p => p !== null)).concat(cartUsedSeats)}
                                                                                deleted={unavailableSeats?.deleted?.map(s => {
                                                                                    return [s[0] + 1, s[1] + 1]
                                                                                })}
                                                                                selected={pass?.seat ? [pass.seat.row + 1, pass.seat.column + 1] : null}
                                                                                action={(row, column) => {
                                                                                    handleSeatClick(passIndex, row, column)
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Card>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='mt-4 d-grid'>
                                                            <Button type='submit' variant='primary' className='d-flex justify-content-center align-items-center'>{t('marketplace.passType.addPass')} <img alt='' src='/images/rignt-arrow.svg' className='ms-3'/></Button>
                                                        </div>
                                                    </div>
                                        }
                                    </Card.Body>
                                </Card>
                            </Form>
                        </div>
            }
        </>
    )
}