import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getSitesByClub } from '../../../../actions/sites/sitesActions'
import CourtForm from '../../../../components/forms/courts/CourtForm'
// import { getZonesBySite } from '../../../../actions/sites/zonesActions'
import { editCourt, getCourt } from '../../../../actions/sites/courtsActions'
import CourtsEditSubMenu from '../../../../components/menus/CourtsEditSubMenu'
import SportsComponent from '../../../../components/sports'

export default function EditCourt({usertype}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {loading: loadingSite, sites} = useSelector(state => state.sites)
    // const {loading: loadingZone, zones} = useSelector(state => state.zones)
    const {loadingcourt, result, court} = useSelector(state => state.courts)
    const {club} = useSelector(state=>state.clubs)

	const {sports} = SportsComponent()
    const [sportsAux, setSportsAux] = useState([])

    const [courtData, setCourtData] = useState({
        name: '',
        image: null,
        // zone: null,
        site: null,
        sports: [],
        rentable: true,
        active: true,
        minSlots: 1,
        maxSlots: 2,
        onwardsDays: 1,
        freeCancelDays: 7
    })

    const [siteOptions, setSiteOptions] = useState([])
    // const [zoneOptions, setZoneOptions] = useState([])

    const {courtId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    const handleInputChange = ({target})=>{
        setCourtData({
            ...courtData,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setCourtData({
            ...courtData,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        // if (selectType === 'site') {
        //     dispatch(getZonesBySite(target.value))
        // }
        setCourtData({
            ...courtData,
            [selectType]: target
        })
    }

    const handleImage = (e)=>{
        setCourtData({
            ...courtData,
            image: e.target.files[0]
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if (courtData.name !== '') {
            let sportsToSend = courtData.sports?.map(sport => {
                return sport.value
            }) || []

            dispatch(editCourt({
                ...courtData,
                site: courtData.site?.value || courtData.site,
                sports: sportsToSend
            }, courtId))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('courts.formNotFinished')
            })
        }
    }

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('courts.editsuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.courts")}`)
                        }
                    })
                    break;
                case 'duplicatename':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('courts.duplicatename')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(()=>{
        if (court) {
            let sportsSaved = []

            if(court?.sports?.length > 0){
                for(const sport of court.sports) {
                    sportsSaved.push(sports.find(s => s.value === sport))
                }
            }

            setCourtData({
                name: court.name,
                image: court.image || null,
                // zone: {value: court.zone._id, label: court.zone.name},
                site: {value: court.site._id, label: court.site.name},
                sports: sportsSaved,
                rentable: court.rentable || false,
                active: court.active || false,
                minSlots: court.minSlots,
                maxSlots: court.maxSlots,
                onwardsDays: court.onwardsDays,
                freeCancelDays: court.freeCancelDays
            })
        }
        setSportsAux(sports)
    //eslint-disable-next-line
    }, [court])

    useEffect(() => {
        if (courtId) {
            dispatch(getCourt(courtId))
        }
    }, [courtId, dispatch])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (club) {
            dispatch(getSitesByClub(club?._id))
        }
    // eslint-disable-next-line
    }, [club])

    useEffect(() => {
        setSiteOptions(sites.map(site => {
            return {label: site.name, value: site._id}
        }))
    }, [sites])

    // useEffect(() => {
    //     setZoneOptions(zones.map(zone => {
    //         return {label: zone.name, value: zone._id}
    //     }))
    // }, [zones])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <CourtsEditSubMenu usertype={usertype} active="courts"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2 className='fs-18'>{t('courts.editCourt')}</h2>
                            <hr className='mb-4'/>
                            <CourtForm state={courtData} statetype={'edit'} usertype={usertype} isAdding={loadingSite || loadingcourt} submit={handleAdd} sports={sportsAux} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} handleImage={handleImage} sites={siteOptions}/>
                        </CardBody>
                    </Card>
                </Col>                
            </Row>
        </Container>
	)
}