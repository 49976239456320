import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import AuthProvider from './context/auth/AuthProvider'
import PrivateAdminRouter from './routers/PrivateAdminRouter'
import AdminRouter from './routers/AdminRouter'
import PrivateClientRouter from './routers/PrivateClientRouter'
import ClientRouter from './routers/ClientRouter'
import PrivateClubRouter from './routers/PrivateClubRouter'
import ClubRouter from './routers/ClubRouter'
import PublicRouter from './routers/PublicRouter'
import i18n from 'i18next'

const t = i18n.t.bind(i18n)

export default function App() {
	//Cancela el scroll en los input tipo número para evitar que se modifiquen al subir o bajar en la página
    useEffect(() => {
        const handleWheel = (event) => {
            if (document.activeElement.type === 'number' && document.activeElement === event.target) {
                event.preventDefault()
            }
        }
        window.addEventListener('wheel', handleWheel, { passive: false })
        return () => {
            window.removeEventListener('wheel', handleWheel, { passive: false })
        }
    }, [])

    return (
        <>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path={`/${t("url.admin.admin")}/*`} element={<PrivateAdminRouter><AdminRouter /></PrivateAdminRouter>} />
                        <Route path={`/${t("url.client.client")}/*`} element={<PrivateClientRouter><ClientRouter /></PrivateClientRouter>} />
                        <Route path={`/${t("url.club.club")}/*`} element={<PrivateClubRouter><ClubRouter /></PrivateClubRouter>} />
                        <Route path="/*" element={<PublicRouter />}/>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </>
    )
}