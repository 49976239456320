import React from "react"
import { Row, Col, Carousel } from "react-bootstrap"
import ScheduleSites from "./ScheduleSites"
import ActivitiesFeatured from "./ActivitiesFeatured"

export default function SidebarClubMarketplace({activitiesfeatured, sites, sponsors, sponsorsLinks}) {

    return (
        <>
            <Row>
                <Col>
                    {activitiesfeatured?.length > 0
                        ?
                            <ActivitiesFeatured activities={activitiesfeatured}/>
                        :
                            <></>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {sites?.length > 0
                        ?
                            <ScheduleSites sites={sites} />
                        :
                            <></>
                    }
                </Col>
            </Row>
            {sponsors?.length > 0 ?
                <Row className="my-3 carouselClub">
                    <Col xs={12}>
                        <Carousel interval={3000}>
                            {sponsors?.map((img, index) => (
                                <Carousel.Item key={index}>
                                    <a href={sponsorsLinks[index]} target="_blank" rel="noopener noreferrer">
                                        <img
                                            className="d-block w-100"
                                            src={typeof img === 'string' ? img : URL.createObjectURL(img)}
                                            alt={`Slide ${index}`}
                                        />
                                    </a>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            :
                <></>
            }
        </>
    )
}