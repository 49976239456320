import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import CompleteSchedule from "../../design/Schedule/CompleteSchedule"
import Loader from "../../design/Loader/Loader"
import { Link } from "react-router-dom"

export default function CourtFormStep2({state, usertype, isAddingOrEditing, submit, handleDayCheck, addSchedule, removeSchedule, handleScheduleInputChange, handleAddBlockedRange, handleRemoveBlockedRange, onPeriodChange}) {
    const { t } = useTranslation()

    return (
        <Form onSubmit={submit}>
            <CompleteSchedule value={state} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange} onPeriodChange={onPeriodChange} handleRemoveBlockedRange={handleRemoveBlockedRange} handleAddBlockedRange={handleAddBlockedRange}/>
            <Row className="my-3">
                <Col xs={6}>
                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}` : `/${t("url.club.club")}/${t("url.club.courts")}`}><Button variant="default" className="w-100 mt-3">{t('courts.backtolist')}</Button></Link>
                </Col>
                <Col xs={6}>
                    {isAddingOrEditing ?
                        <Loader/>
                    :
                        <Button type="submit" variant="primary" className='mt-3 w-100'>
                            {t('global.save')}
                        </Button>
                    }
                </Col>
            </Row>
        </Form>
    )    
}
