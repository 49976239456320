import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { thunk } from 'redux-thunk'
import { usersReducer } from './reducers/profiles/usersReducer'
import { redirectReducer } from './reducers/redirectReducer'
import { logsReducer } from './reducers/logsReducer'
import { commissionsReducer } from './reducers/settings/commissionsReducer'
import { superadminsReducer } from './reducers/profiles/superadminsReducer'
import { clubsReducer } from './reducers/profiles/clubsReducer'
import { clientsReducer } from './reducers/profiles/clientsReducer'
import { activitiesReducer } from './reducers/activities/activitiesReducer'
import { categoriesReducer } from './reducers/activities/categoriesReducer'
import { groupsReducer } from './reducers/activities/groupsReducer'
import { horariesReducer } from './reducers/activities/horariesReducer'
import { sitesReducer } from './reducers/sites/sitesReducer'
// import { zonesReducer } from './reducers/sites/zonesReducer'
import { courtsReducer } from './reducers/sites/courtsReducer'
// import { areasReducer } from './reducers/sites/areasReducer'
import { reservationsReducer } from './reducers/sites/reservationsReducer'
import { cartReducer } from './reducers/payments/cartReducer'
import { paymentReducer } from './reducers/payments/paymentReducer'
import { inscriptionsReducer } from './reducers/activities/inscriptionsReducer'
import { filtersReducer } from './reducers/filtersReducer'
import { promotionsReducer } from './reducers/payments/promotionReducer'
import { seasonsReducer } from './reducers/ticketing/seasonsReducer'
import { sectorsReducer } from './reducers/ticketing/sectorsReducer'
import { matchesReducer } from './reducers/ticketing/matchesReducer'
import { sepaLogsReducer } from './reducers/logs/sepaLogsReducer'
import { passTypesReducer } from './reducers/ticketing/passTypesReducer'
import { feesReducer } from './reducers/ticketing/feesReducer'
import { passReducer } from './reducers/ticketing/passReducer'
import { ticketReducer } from './reducers/ticketing/ticketReducer'
import { downloadsReducer } from './reducers/downloads/downloadsReducer'

const intialState = {}
const appReducer = combineReducers({
    users: usersReducer,
    logs: logsReducer,
    sepaLogs: sepaLogsReducer,
    commissions: commissionsReducer,
	redirectReducer: redirectReducer,
    superadmins: superadminsReducer,
    clubs: clubsReducer,
    clients: clientsReducer,
    activities: activitiesReducer,
    categories: categoriesReducer,
    groups: groupsReducer,
    horaries: horariesReducer,
    inscriptions: inscriptionsReducer,
    sites: sitesReducer,
    // zones: zonesReducer,
    courts: courtsReducer,
    // areas: areasReducer,
    reservations: reservationsReducer,
    cart: cartReducer,
    payment: paymentReducer,
    filters: filtersReducer,
    promotions: promotionsReducer,
    seasons: seasonsReducer,
    sectors: sectorsReducer,
    matches: matchesReducer,
    passTypes: passTypesReducer,
    fees: feesReducer,
    pass: passReducer,
    ticket: ticketReducer,
    downloads: downloadsReducer
})

const reducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const composeEnhancer = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, intialState, composeEnhancer(applyMiddleware(thunk)))

export default store