import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import Loader from '../../../../components/design/Loader/Loader'
import Swal from 'sweetalert2'
import { getReservationsCanceledByClub } from '../../../../actions/sites/reservationsActions'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'
import SitesSubMenu from '../../../../components/menus/SitesSubMenu'

export default function CourtsCanceled({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {reservations, loading} = useSelector(state=>state.reservations)
    const {club} = useSelector(state=>state.clubs)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth){
            dispatch(getReservationsCanceledByClub(club?._id))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth, club])



	const columnsReserves = [
        {
            name: t('reservations.reserveReference'),
            width: '130px',
            cell: reserve =>
                reserve.reserveReference ? reserve.reserveReference : '',
            selector: (reserve) => reserve.reserveReference,
            sortable: false,
        },{
            name: t('reservations.date'),
            width: '130px',
            cell: reserve => {
                const date = new Date(reserve.date)
                return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
            },
            selector: reserve => {
                const date = new Date(reserve.date)
                return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
            },
            sortable: false,
        },{
            name: t('reservations.hours'),
            width: '150px',
            cell: reserve => {
                const start = reserve.startHour || '';
                const end = reserve.endHour || '';
                return `${start} - ${end}`;
            },
            selector: reserve => {
                const start = reserve.startHour || '';
                const end = reserve.endHour || '';
                return `${start} - ${end}`;
            },
            sortable: false,
        },{
            name: t('reservations.court'),
            width: '130px',
            cell: reserve =>
                reserve.court.name ? reserve.court.name : '',
            selector: (reserve) => reserve.court.name,
            sortable: false,
        },{
            name: t('reservations.person'),
            cell: reserve => {
                const person = reserve.personData
                return (
                    <div className="d-flex flex-column">
                        <strong>{person?.firstname || ''} {person?.lastname || ''}</strong>
                        {person?.email && (
                            <div><small>{person.email}</small></div>
                        )}
                        {person?.phone && (
                            <div><small>{person.phone}</small></div>
                        )}
                    </div>
                )
            },
            selector: reserve => {
                const person = reserve.personData;
                return `${person?.firstname || ''} ${person?.lastname || ''} ${person?.email || ''} ${person?.phone || ''}`.trim()
            },
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width:'80px',
			cell: reserve =>
                <div className='d-flex justify-content-around w-100'>

                </div>,
			sortable: false,
		}
	]


    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <SitesSubMenu usertype={usertype} active="courtscanceled"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('menus.sites.courtscanceled')}</h2>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            loading
                                                ?
                                                    <Loader/>
                                                :
                                                    <>
                                                        {
                                                            reservations?.length > 0 ?
                                                                <CustomDataTable
                                                                    columns={columnsReserves}
                                                                    data={reservations}
                                                                />
                                                            :
                                                                <div className='contenedor-vacio text-center'>
                                                                    <h4>{t('payments.noPayments')}</h4>
                                                                    {t('payments.thereIsNoPaymentsPhrase')}
                                                                </div>
                                                        }
                                                    </>
                                        }

                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}