import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { editSiteManagerData, getSite } from '../../../../actions/sites/sitesActions'
import SitesEditSubMenu from '../../../../components/menus/SitesEditSubMenu'
import SiteFormStep3 from '../../../../components/forms/sites/SiteFormStep3'
import Swal from 'sweetalert2'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'

export default function EditSiteStep3({usertype, selectedSite}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {result, loading, site} = useSelector(state => state.sites)
    const {club} = useSelector(state=>state.clubs)

    const [newSite, setNewSite] = useState({
        days: [
            {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null},
        ],
        name: null,
        email: null,
        phoneNumber: null
    })

    const {siteId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    const handleDayCheck = (target, index) => {
        setNewSite({
            ...newSite,
            days: newSite.days.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setNewSite({
            ...newSite,
            days: [...newSite.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, index) => {
        setNewSite({
            ...newSite,
            days: newSite.days.filter((d, i) => i !== index)
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setNewSite({
            ...newSite,
            days: newSite.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }
    
    const handleInputChange = ({target}) => {
        setNewSite({
            ...newSite,
            [target.name]: target.value
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()

        dispatch(editSiteManagerData(newSite, selectedSite))
    }

    useEffect(() => {
        if(result){
            switch (result) {
                case 'success':
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('sites.editsuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.sites")}`)
                        }
                    })

                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (siteId) {
            dispatch(getSite(siteId))
        }
    }, [siteId, dispatch])

    useEffect(()=>{
        if(site){
            setNewSite({
                days: site.site.manager.schedule.length > 0 ? site.site.manager.schedule : [{weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}],
                name: site.site.manager.name,
                email: site.site.manager.email,
                phoneNumber: site.site.manager.phoneNumber
            })
        }
    //eslint-disable-next-line
    }, [site])

	return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <SitesEditSubMenu usertype={usertype} active="editsitestep3"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('sites.editSite')}</h2>
                            <hr className='mb-4'/>
                            <SiteFormStep3 state={newSite} statetype={'edit'} usertype={usertype} isAdding={loading} addSchedule={addSchedule} removeSchedule={removeSchedule} submit={handleEdit} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange} handleInputChange={handleInputChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}