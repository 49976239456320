import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function TicketingSubMenu({usertype, active}) {
    const { t } = useTranslation()

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.' + active)}</h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/seasons" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "seasons"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}` : `/${t("url.club.club")}/${t("url.club.seasons")}`}>{t('menus.ticketing.seasons')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "stadium"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.stadium")}` : `/${t("url.club.club")}/${t("url.club.stadium")}`}>{t('menus.ticketing.stadium')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "sectors"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}` : `/${t("url.club.club")}/${t("url.club.sectors")}`}>{t('menus.ticketing.sectors')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "fees"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}` : `/${t("url.club.club")}/${t("url.club.fees")}`}>{t('menus.ticketing.fees')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "passTypes"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}` : `/${t("url.club.club")}/${t("url.club.passTypes")}`}>{t('menus.ticketing.passTypes')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "matches"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}` : `/${t("url.club.club")}/${t("url.club.matches")}`}>{t('menus.ticketing.matches')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "passes"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passes")}` : `/${t("url.club.club")}/${t("url.club.passes")}`}>{t('menus.ticketing.passes')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "tickets"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.tickets")}` : `/${t("url.club.club")}/${t("url.club.tickets")}`}>{t('menus.ticketing.tickets')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "invitations"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.invitations")}` : `/${t("url.club.club")}/${t("url.club.invitations")}`}>{t('menus.ticketing.invitations')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}