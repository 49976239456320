import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable.js'
import useAuth from '../../../../context/auth/useAuth.js'
import { changeSectorActiveStatus, deleteSector, getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs.js'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu.js'
import Swal from 'sweetalert2'

export default function Sectors({usertype, handleSectorSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {sectors, result} = useSelector(state=>state.sectors)
    const {club} = useSelector(state=>state.clubs)

    const handleStatusChange = (sectorId, active) => {
        dispatch(changeSectorActiveStatus(sectorId, active))
    }

    const handleDeleteSector = (sectorId) => {
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.yes'),
            denyButtonText: t('global.no'),
            icon: 'warning',
            text: t('sectors.deleteSectorText')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSector(sectorId))
            }
        })
    }

    useEffect(() => {
        handleSectorSelect(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club?._id){
            dispatch(getSectorsByClub(club?._id))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth, club])


    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'editedSuccess':
                    dispatch(getSectorsByClub(club?._id))
                    dispatch({
                        type: 'SECTOR_RESET_RESULT'
                    })
                    break;
                case 'success':
                    dispatch(getSectorsByClub(club?._id))
                    dispatch({
                        type: 'SECTOR_RESET_RESULT'
                    })
                    break;
                case 'sectorDeleteSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text:  t('sectors.deleteSuccessText')
                    })
                    dispatch(getSectorsByClub(club?._id))
                    dispatch({
                        type: 'SECTOR_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result])

	const columnsSectors = [
        {
            name: t('sectors.name'),
            cell: sector =>
                <Link onClick={() => handleSectorSelect(sector._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.sectors")}/${t("url.club.edit")}`} className='table-link'>
                    {sector.name}
                </Link>,
            selector: (sector) => sector.name,
            sortable: true,
        },{
			name: t('sectors.maxCapacity'),
            center: 'true',
			cell: sector =>
				<Link onClick={() => handleSectorSelect(sector._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.sectors")}/${t("url.club.edit")}`} className='table-link'>
					{sector.maxCapacity}
				</Link>,
			selector: (sector) => sector.name,
			sortable: true,
		},{
			name: t('sectors.numerated'),
            center: 'true',
			cell: sector =>
				<Link onClick={() => handleSectorSelect(sector._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.sectors")}/${t("url.club.edit")}`} className='table-link'>
					{sector.numerated ? t('global.yes') : t('global.no')}
				</Link>,
			selector: (sector) => sector.numerated ? t('global.yes') : t('global.no'),
			sortable: true,
		},{
			name: t('sectors.active'),
            center: 'true',
			cell: sector =>
				<div>
                    <Form.Check
                        type='switch'
                        checked={sector.active}
                        onChange={({target}) => (handleStatusChange(sector._id, target.checked))}
                    />
                </div>,
			selector: (sector) => sector.active ? t('global.yes') : t('global.no'),
			sortable: true,
		},{
			name: t('global.actions'),
            center: 'true',  
            width:'120px',
			cell: sector =>
                <div className='d-flex justify-content-around w-100'>
                    <Link onClick={() => handleSectorSelect(sector._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.sectors")}/${t("url.club.edit")}`}>
                        <img src="/images/edit.svg" alt={t('global.edit')} width={15}></img>
                    </Link>
                    <Link onClick={() => handleDeleteSector(sector._id)}>
                        <img src="/images/trash.svg" alt="trash" width={15}/>
                    </Link>
                </div>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <TicketingSubMenu usertype={usertype} active="sectors"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('sectors.sectorsList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.sectors")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('sectors.createSector')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {sectors?.length > 0 ?
                                            <CustomDataTable columns={columnsSectors} data={sectors} exportable={false} printable={false}/>
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('sectors.noSectors')}</h4>
                                                {t('sectors.thereIsNotSectorsPhrase')}
                                                <div className='mt-3'>
                                                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.sectors")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                                        {t('sectors.createSector')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}