import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../../../components/design/Loader/Loader'

export default function Club() {
    const { t } = useTranslation()

    const {club, loadingclub} = useSelector(state=>state.clubs)

    return (
        <Container className='p-5'>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('admin.clubs.list')}</h3>
                </Col>
            </Row>
            <Row>

                { loadingclub
                ?
                    <Loader/>
                :
                    club ?
                        <>
                            <Row>
                                <Col>
                                    <h2 className='noBold'>{club.name}</h2>
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col>
                                    <Card>
                                        <Link to={`/${t("url.admin.admin")}/${t("url.admin.clubs")}/${t("url.admin.basicdata")}`} className='btn btn-dashboard'>
                                            <h2 className='noBold'>{t('club.dashboard.config')}</h2>
                                        </Link>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col>
                                    <Card>
                                        <Link to={`/${t("url.admin.admin")}/${t("url.admin.clubs")}/${t("url.admin.activities")}`} className='btn btn-dashboard'>
                                            <h2 className='noBold'>{t('club.dashboard.activities')}</h2>
                                        </Link>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col>
                                    <Card>
                                        <Link to={`/${t("url.admin.admin")}/${t("url.admin.clubs")}/${t("url.admin.sites")}`} className='btn btn-dashboard'>
                                            <h2 className='noBold'>{t('club.dashboard.sites')}</h2>
                                        </Link>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    :
                        ''
                }
            </Row>
        </Container>
    )
}