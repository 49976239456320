import React, { Fragment, useEffect, useState } from 'react'
import {Container,Row,Col,Button,Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from "../../context/auth/useAuth"
import { Link, useNavigate } from 'react-router-dom'

const clientCredentials = {}

export default function Login() {
    const { t } = useTranslation()
    const [islogging, setIslogging] = useState(false)
    const {loginClient, clientauth} = useAuth()
    const navigate = useNavigate()

    const handleSubmit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        loginClient(clientCredentials).then(() => {
            setIslogging(false)
        })
    }

    useEffect(() => {
        if (clientauth) {
            if(clientauth?.roles?.some(role => role.profileType === 'Superadmin')){
                navigate(`/${t("url.admin.admin")}/${t("url.admin.dashboard")}`)
            }else{
                navigate(`/${t("url.client.client")}/${t("url.client.dashboard")}`)
            }
        }
    }, [clientauth, navigate, t])

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>
                    <Col md={7} className='d-none d-md-block aside-auth'>
                    </Col>
                    <Col xs={12} md={5} className='d-flex align-items-center p-5'>
                        <Container>
                            <Row>
                                <Col>
                                    <h1 className='mb-4'>{t('signin.signin')}</h1>
                                    <Form className="formulario" onSubmit={ handleSubmit}>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='login_username'>{t('signin.username')}</Form.Label>
                                                <Form.Control name="login_username" className="login" type="text" autoComplete="off" onChange={ (e) => clientCredentials.username = e.target.value} tabIndex="1"/>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <div className='d-flex justify-content-between'>
                                                    <Form.Label htmlFor='login_password'>{t('signin.password')}</Form.Label>
                                                    <Link to={`/${t("url.auth.forgotpassword")}`} className='link' tabIndex="3">{t('signin.passwordforgoten')}</Link>
                                                </div>
                                                <Form.Control name="login_password" className="login" type="password" autoComplete="off" onChange={ (e) => clientCredentials.password = e.target.value} tabIndex="2"/>
                                            </Col>
                                        </Row>           
                                        <Row className="my-3">
                                            <Col>
                                                <Button variant='primary' type="submit" className="w-100 my-2" tabIndex="4">
                                                {islogging
                                                    ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </>
                                                    :
                                                    <>{t('signin.login')}</>
                                                }
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-center pt-4'>
                                                {t('signin.createaccount')}<br/>
                                                <Link to={`/${t("url.auth.registerclient")}`} className='btn btn-default w-100 my-2' tabIndex="5">
                                                    {t('signin.register')}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>                       
                        </Container>
                    </Col>
                </Row>            
            </Container>
        </Fragment>
    )
}
