import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { editSector, getSector } from '../../../../actions/ticketing/sectorsActions'
import SectorForm from '../../../../components/forms/sectors/SectorForm'

export default function EditSector({usertype, selectedSector}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading, sector} = useSelector(state=>state.sectors)

    const [sectorForm, setSectorForm] = useState({
        name: null,
        maxCapacity: '0',
        numerated: false,
        active: true,
        distribution: {
            rows: '0',
            columns: '0',
            numeration: '0',
            deleted: []
        },
        club: null
    })

    const handleEdit = async(e) => {
        e.preventDefault()

        if(sectorForm.name !== ''){
            dispatch(editSector(sector?._id, sectorForm))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('sectors.noName')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'SECTOR_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('sectors.editSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.sectors")}`)
                        }
                    })
                    break;
                case 'duplicateName':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('sectors.duplicateName')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (selectedSector) {
            dispatch(getSector(selectedSector))
        }
    }, [selectedSector, dispatch])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (sector) {
            setSectorForm({
                name: sector?.name,
                maxCapacity: sector?.maxCapacity,
                numerated: sector?.numerated || false,
                active: sector?.active || false,
                distribution: sector?.distribution || {
                    rows: '0',
                    columns: '0',
                    numeration: '0',
                    deleted: []
                },
                club: sector?.club
            })
        }
    }, [sector])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sectors.editSectorForm')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <SectorForm state={sectorForm} statetype={'edit'} usertype={usertype} isAdding={loading} submit={handleEdit} setState={setSectorForm}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}