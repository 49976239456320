import React, {Fragment, useState} from 'react'
import axios from 'axios'
import { useNavigate, useParams  } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'

export default function ResetPassword(props) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showPasswordOne, setShowPasswordOne] = useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = useState(false)
    const [isCorrect, setIsCorrect] = useState(false)

    const { id } = useParams()
    const { tokenresetpassword } = useParams()

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handlePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo)
    }

    /**
     * Captura el submit del formulario y cambia la clave de la cuenta
     * @param {*} e 
     */
    const handleSubmit = async(e) => {
        e.preventDefault()

        if (!isCorrect) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('passwordreset.passwordsdoesnotmatch')
            })
            return
        }

        if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,20}$/g)) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.passwordsnotvalid')
            })
            return
        }

        const clientPassword = {password}
           
        setIsLoading(true)

        await axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/forgot/resetpassword?id=' + id + '&token=' + tokenresetpassword, clientPassword, {
            where: {
                id: id,
                tokenresetpassword: tokenresetpassword
            }
        })
        .then((res) => {            
            setIsLoading(false)

            // Mostramos la notificación
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: t('passwordreset.passwordreset')
            })

            // Redirigimos a la raíz
            navigate('/')

        }).catch((err) => {
            setIsLoading(false)
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text:  t('passwordreset.error')
            })
        })
    }

    const checkValidation = (isPassVer, e) => {
        const confirmPass = e.target.value

        if (isPassVer) {
            setConfirmPassword(confirmPass)
        } else {
            setPassword(confirmPass)
        }

        if (password !== confirmPass) {
            setIsCorrect(false)
            setIsError(t('users.passwordsdoesnotmatch'))
        } else {
            setIsCorrect(true)
            setIsError("")
        }
    }

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Form className="formulario" onSubmit={handleSubmit}>
                    <Row className='p-0 m-0 centro-auth'>
                        <Col xs={12} md={5} className='d-flex align-items-center justify-content-center p-5 mx-auto'>
                            <Container>
                                <Row>
                                    <Col className='text-center'>
                                        <h1>{t('passwordreset.question')}</h1>
                                        {t('passwordreset.help')}
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col className='sd-passwordview'>
                                        <Form.Label htmlFor='password'>{t('passwordreset.new_password')}</Form.Label>
                                        <Form.Control
                                            className="sd-input"
                                            type={showPasswordOne ? 'text' : 'password'}
                                            name='password' 
                                            value={password || ''}
                                            onChange={(e) => checkValidation(false, e)}
                                            required
                                        />
                                        <img
                                            className="sd-imageneye"
                                            src={showPasswordOne ? "/images/eyeclose.svg" : "/images/eyeopen.svg"}
                                            alt="eyes"
                                            width={15}
                                            onClick={handlePasswordVisibilityOne}
                                        />
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <p>
                                            <span className='ps-1'>{t('users.passwordneed1')}</span><br/>
                                            <span className='password-list'>
                                                <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed2')}</span>
                                                <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed3')}</span>
                                                <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed4')}</span>
                                            </span>
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col className='sd-passwordview'>
                                        <Form.Label htmlFor='confirm_password'>{t('passwordreset.confirm_password')}</Form.Label>
                                        <Form.Control
                                            className="sd-input"
                                            type={showPasswordTwo ? 'text' : 'password'}
                                            name='confirm_password'
                                            value={confirmPassword || ''}
                                            onChange={(e) => checkValidation(true, e)}
                                            required
                                        />
                                        <img
                                            className="sd-imageneye"
                                            src={showPasswordTwo ? "/images/eyeclose.svg" : "/images/eyeopen.svg"}
                                            alt="eyes"
                                            width={15}
                                            onClick={handlePasswordVisibilityTwo}
                                        />
                                    </Col>
                                </Row>
                                {isError !== '' && (
                                    <Row className="my-3">
                                        <Col>
                                            <div className="text-danger">* {isError} *</div>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="my-3">
                                    <Col>
                                        <Button type="submit" variant="primary" className="w-100 my-2">
                                            {isLoading
                                                ? <span className="indicator-label">
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                                : <span className="indicator-label">{t('passwordreset.submit')}</span>
                                            }
                                        </Button>                                           
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Link to={`/${t("url.auth.login")}`} className='btn btn-default w-100 my-2'>
                                                {t('global.return')}
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Fragment>
    )
}