export const passTypesReducer = (state = {loading: false, result: null, passTypes: [], passType: null}, action) => {
    switch (action.type) {
        case 'PASSTYPE_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'PASSTYPE_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'PASSTYPE_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                passTypes: action.payload.passTypes
            }
        case 'PASSTYPE_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                passType: action.payload
            }
        case 'PASSTYPE_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'PASSTYPE_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'PASSTYPE_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}