export const matchesReducer = (state = {loading: false, result: null, matches: [], match: null, checks: null, check: null, entryExist: null, entryOtherMatch: false, entryCancel: null, entryValid: null, maxUsesDone: null, resultentry: null, matchExist: null, loadingentry: false}, action) => {
    switch (action.type) {
        case 'MATCH_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'MATCH_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'MATCH_ENTRY_LOADING':
            return {
                ...state,
                loadingentry: true
            }
        case 'MATCH_ENTRY_NOT_LOADING':
            return {
                ...state,
                loadingentry: false
            }
        case 'MATCH_EXIST_SUCCESS':
            return {
                ...state,
                matchExist: action.payload.matchExist
            }
        case 'MATCH_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                matches: action.payload.matches
            }
        case 'MATCH_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                match: action.payload
            }
        case 'MATCH_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'MATCH_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'MATCH_CHECKS_GET_SUCCESS':
            return {
                ...state,
                loadingentry: false,
                checks: action.payload.checks
            }
        case 'MATCH_GETENTRY_SUCCESS':
            return {
                ...state,
                loadingentry: false,
                entry: action.payload.entry,
                entryExist: action.payload.entryExist,
                entryOtherMatch: action.payload.entryOtherMatch,
                entryCancel: action.payload.entryCancel,
                entryValid: action.payload.entryValid,
                maxUsesDone: action.payload.maxUsesDone,
                matchExist: action.payload.matchExist,
                check: action.payload.check
            }
        case 'MATCH_CHECKINOUT_SUCCESS':
            let arrayChecks = state.checks
            if(arrayChecks){
                arrayChecks = []
                state.checks?.map(check => {
                    if (check._id === action?.payload?.check?._id) {
                        arrayChecks.push(action?.payload?.check)
                    } else {
                        arrayChecks.push(check)
                    }
                })
            }

            return {
                ...state,
                loadingentry: false,
                entry: action?.payload?.entry,
                entryExist: action?.payload?.entryExist,
                check: action?.payload?.check,
                checks: arrayChecks,
                resultentry: true
            }
        case 'MATCH_RESET_ENTRY':
            return {
                ...state,
                entry: null,
                entryExist: null,
                entryOtherMatch: null,
                entryCancel: null,
                entryValid: null,
                maxUsesDone: null,
                matchExist: null,
                check: null
            }
        case 'MATCH_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        case 'ENTRY_RESET_RESULT':
            return {
                ...state,
                resultentry: null
            }
        default:
            return state
    }
}