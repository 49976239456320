import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removeCartItem } from "../../actions/payments/cartActions";

export default function CartReservation({item, index}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const calculateReservationPrice = () => {
        if (item.slots && item.slots.length > 0) {
            let price = item.slots.reduce((sum, value) => {
                let price = value?.price
                if (price > 0) {
                    const sitesCommission = item.commission?.find(sites => price >= sites.lowPrice && price <= sites.highPrice);
                    return parseFloat(sum) + parseFloat((Math.ceil((parseFloat(price) + parseFloat(price * (sitesCommission?.percentage || 0) / 100) + parseFloat(sitesCommission?.double || 0)) * 100) / 100).toFixed(2))
                } else {
                    return sum
                }
            }, 0)

            return parseFloat(price).toFixed(2)
        } else {
            return 0.00
        }
    }

    return (
        <Row className="my-3 cart-item">
            <Col xs={12} md={4}>
                {
                    item.court?.image
                    ?
                        <img src={item.court?.image || `/images/bannerclub.jpg`} alt={item.court?.name} className="w-100"/>
                    :
                        <img src="/images/bannerclub.jpg" alt={item.court?.name} className="w-100"/>
                }
            </Col>
            <Col xs={12} md={8} className="d-flex justify-content-between align-items-center">
                <div className="data">
                    <h3 className="font-single mb-1">{item.court?.name}</h3>
                    <p className="club">{item.club?.name}</p>
                        <p>{ t('marketplace.reservation.client') }: {`${item.personData?.firstname} ${item.personData?.lastname}`}</p>
                    <p className="fw-bold">{t('marketplace.reservation.total')}: {calculateReservationPrice()}€
                    </p>
                </div>
                <div className="icons">
                    <img src="/images/trash.svg" alt="trash" className="cursor" width={15} onClick={() => {dispatch(removeCartItem(index))}}/>
                </div>
            </Col>
        </Row>
    )
}