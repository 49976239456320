import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getEntryDownload = (entryId, identifier) => async (dispatch) => {
    dispatch({
        type: 'DOWNLOAD_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/download/getentry/'+ entryId + '/'+ identifier)
        if (status === 200) {
            dispatch({
                type: 'DOWNLOAD_GETENTRY_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'DOWNLOAD_NOT_LOADING'
        })
    }
}

export const getEntryInvitationDownload = (invitationId, identifier) => async (dispatch) => {
    dispatch({
        type: 'DOWNLOAD_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/download/getInvitationEntry/'+ invitationId + '/'+ identifier)
        if (status === 200) {
            dispatch({
                type: 'DOWNLOAD_GET_INVITATION_ENTRY_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'DOWNLOAD_NOT_LOADING'
        })
    }
}

export const sendEmailDownloadEntry = (passId) => async (dispatch) => {
    dispatch({
        type: 'DOWNLOAD_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/download/emaildownloadentry/'+ passId)
        if (status === 200) {
            dispatch({
                type: 'DOWNLOAD_SENDEMAIL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'DOWNLOAD_NOT_LOADING'
        })
    }
}

export const sendEmailDownloadInvitation = (invitationId) => async (dispatch) => {
    dispatch({
        type: 'DOWNLOAD_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/download/emailDownloadInvitation/'+ invitationId)
        if (status === 200) {
            dispatch({
                type: 'DOWNLOAD_SENDEMAIL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'DOWNLOAD_NOT_LOADING'
        })
    }
}