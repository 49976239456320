import React, { useEffect } from 'react'
import { Container, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getSepaLogs,getSepaLogsByClub } from '../../../actions/logs/sepaLogsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Loader from '../../../components/design/Loader/Loader'
import paycometError from '../../../components/paycometError.json'

export default function SepaLogs({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const getErrorDescription = (errorCode) => {
        const error = paycometError.find(e => Number(e.code) === Number(errorCode))
        return error ? error.description : 'Error desconocido'
    }
    const {sepalogs,loadingsepalogs} = useSelector(state => state.sepaLogs)
    const { club } = useSelector(state => state.clubs)

    useEffect(() =>{
        if(clientauth){
            console.log('Club',club)
            if(usertype === "club"){
                dispatch(getSepaLogsByClub(club?._id))
            }else{
                dispatch(getSepaLogs(usertype))
            }

        }
    }, [dispatch, t, clientauth, club])

    const columnsLogs= [
        {
            name: t('admin.logs.date'),
            width: '150px',
            cell:  log => {
                        const formattedDate = new Date(log?.date).toLocaleDateString('es-ES', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                        })
                        return <>{formattedDate}</>
            },
            selector: (log) => log?.date,
            sortable: true,
        },
        {
            name: t('admin.logs.reference'),
            cell: log =>{
                    return <>{log?.ref}</>
            },
            selector: (log) => log?.ref,
            sortable: true,
        },
        {
            name: t('admin.logs.status'),
            center: 'true',
            width: '15%',
            cell: log =>{
                if (log?.status === 0) {
                    return (
                        <>
                            <i className='fas fa-check'></i>
                        </>
                    );
                } else {
                    const descriptionObj = JSON.parse(log?.description)
                    const errorCode = descriptionObj?.operations?.[0]?.operationErrorCode || descriptionObj?.errorCode
                    return (
                        <div className='error'>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                <Tooltip id="error-tooltip">
                                    {getErrorDescription(errorCode)}
                                </Tooltip>
                                }
                            >
                                <div><i className="fas fa-exclamation-triangle me-2"></i> {errorCode}</div>
                            </OverlayTrigger>
                        </div>
                    )
                }
            },
            selector: (log) => log?.status,
            sortable: true,
        },
        ...(usertype !== 'club' ? [{
            name: t('admin.logs.club'),
            width: '40%',
            cell: log => {
                return <>{log?.club?.name}</>;
            },
            selector: (log) => log?.club.name,
            sortable: true,
        }] : []),
        {
            name: t('admin.logs.quantity'),
            center: 'true',
            width: '180px',
            cell: log => {
                const formattedQuantity = (log?.quantity / 100).toFixed(2);
                return <>{formattedQuantity} €</>
            },
            selector: (log) => log?.quantity,
            sortable: true,
        }
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('admin.logs.sepaLog')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <div className='buttons d-flex justify-content-end'>
                            <a href='https://docs.paycomet.com/es/recursos/codigos-de-error'
                                className='btn btn-primary'
                                target='_blank'
                                rel='noreferrer'>
                                    Errores Paycomet <i className="fas fa-chevron-right ms-3"></i>
                            </a>
                        </div>
                        {
                        loadingsepalogs
                        ?
                            <Loader/>
                        :
                            sepalogs?.length >0
                             ?
                                <CustomDataTable
                                    columns={columnsLogs}
                                    data={sepalogs}
                                    exportable={false}
                                    printable={false}
                                />
                            :
                                <>{t('admin.logs.thereIsNotLogs')}</>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}