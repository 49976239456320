import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getClubsPublicByFilter = (datafilter) => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/public/getbyfilter/', { params: datafilter })

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_CLUBSFILTERED_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getCourtsPublicByFilter = (datafilter) => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/court/public/getbyfilter/', { params: datafilter })

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_COURTSFILTERED_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getActivitiesPublicByFilter = (datafilter) => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/activity/public/getbyfilter/', { params: datafilter })

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_ACTIVITIESFILTERED_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getSitesPublicForFilter = () => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/site/public/getpublicsitesforfilter/')

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_SITES_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getSitesPublicByClubForFilter = (club) => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/site/public/getpublicsitesbyclubforfilter/'+club)

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_SITES_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getSportsPublicForFilter = () => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/activity/public/getpublicsportsforfilter/')

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_SPORTS_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getSportsPublicByClubForFilter = (club) => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/court/public/getpublicsportsbyclubforfilter/'+club)

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_SPORTS_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getPassTypesPublicByFilter = (filters) => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/passType/public/getbyfilter/', { params: filters })

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_PASSTYPES_FILTERED_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}

export const getMatchesPublicByFilter = (filters) => async (dispatch) => {
    dispatch({
        type: 'FILTER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/public/getbyfilter/', { params: filters })

        if (status === 200) {
            dispatch({
                type: 'FILTER_GET_MATCHES_FILTERED_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'FILTER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'FILTER_NOT_LOADING'
        })
    }
}