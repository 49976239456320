import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../context/auth/useAuth'
import Loader from '../../../components/design/Loader/Loader'
import { getPaymentsByPaymentClient, changePaymentCard } from '../../../actions/payments/paymentActions'
import ManualPayment from '../../../components/Payments/ManualPayment'

export default function Payments({clientselected}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {payments, cards, loading} = useSelector(state=>state.payment)

    const [manualPaymentSelected, setManualPaymentSelected] = useState(null)

    useEffect(() => {
        if(clientauth){
            dispatch(getPaymentsByPaymentClient(clientselected))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth])

	const columnsPayments = [
        {
            name: t('payments.reference'),
            width: '130px',
            cell: payment =>
                payment.paymentReference,
            selector: (payment) => payment.paymentReference,
            sortable: false,
        },{
            name: t('payments.paymentDate'),
            width: '130px',
            cell: payment =>
                payment.paymentDate ? `${new Date(payment.paymentDate).getDate() > 9 ? new Date(payment.paymentDate).getDate() : "0" + new Date(payment.paymentDate).getDate()}/${new Date(payment.paymentDate).getMonth() > 8 ? parseInt(new Date(payment.paymentDate).getMonth()) + 1 : "0" + (parseInt(new Date(payment.paymentDate).getMonth()) + 1)}/${new Date(payment.paymentDate).getFullYear() > 9 ? new Date(payment.paymentDate).getFullYear() : "0" + new Date(payment.paymentDate).getFullYear()}`: '-',
            selector: (payment) => `${new Date(payment.paymentDate).getDate() > 9 ? new Date(payment.paymentDate).getDate() : "0" + new Date(payment.paymentDate).getDate()}/${new Date(payment.paymentDate).getMonth() > 8 ? parseInt(new Date(payment.paymentDate).getMonth()) + 1 : "0" + (parseInt(new Date(payment.paymentDate).getMonth()) + 1)}/${new Date(payment.paymentDate).getFullYear() > 9 ? new Date(payment.paymentDate).getFullYear() : "0" + new Date(payment.paymentDate).getFullYear()}`,
            sortable: true,
        },{
            name: t('payments.status'),
            width: '130px',
            center: 'true',
            cell: payment => {
                const statusText = (
                    payment.status === 0 ? t('payments.pending') :
                    payment.status === 1 ? `${t('payments.failed')} (${payment.totalRetries || 1})` :
                    payment.status === 2 ? t('payments.canceled') :
                    payment.status === 3 ? t('payments.paid') :
                    t('payments.unknown')
                )

                const externalPaidText = payment.externalPaid ? ` ${t('payments.externalPaid')}` : ''

                return `${statusText}${externalPaidText}`
            },
            selector: (payment) => payment.status,
            sortable: true,
        },{
            name: t('payments.card'),
            width: '200px',
            cell: payment => (payment.status === 0 || payment.status === 1) && payment.paymentReference && payment.quantity > 0 ? (
                <select value={payment?.card?.payCometId} onChange={(e) => {
                    if(payment?.card?.payCometId !== e.target.value){
                        dispatch(changePaymentCard(payment._id, e.target.value))
                    }
                }}>
                    <option value="">{t('payments.selectcard')}</option>
                    {cards?.map(card => (
                        <option key={card?._id} value={card?.payCometId} selected={card?.payCometId === payment?.card?.payCometId && card?.payCometToken === payment?.card?.payCometToken}>
                            **** {card.last4} ({card.name})
                        </option>
                    ))}
                </select>
            ) : '',
            selector: (payment) => payment.card,
            sortable: false,
        },{
            name: t('payments.quantity'),
            center: 'true',
            width: '110px',
            cell: payment =>
                payment.quantity.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + "€",
            selector: (payment) => payment.quantity,
            sortable: true,
        },{
            name: t('payments.refunded'),
            width: '130px',
            center: 'true',
            cell: payment =>
                payment.refunded ? payment.refunded.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + "€" : '',
            selector: (payment) => payment.refunded ? payment.refunded.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '',
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width:'80px',
			cell: payment =>
                <div className='d-flex justify-content-around w-100'>
                    {
                        (payment.status === 1 || payment.status === 0) &&
                            <div onClick={() => setManualPaymentSelected(payment)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip id="tooltip">
                                                {t('payments.manualPayment')}
                                            </Tooltip>}
                                >
                                    <img className="cursor" src="/images/menus/payments.svg" alt="eyes" height={16}/>
                                </OverlayTrigger>
                            </div>
                    }
                </div>,
			sortable: false,
		}
	]
    const ExpandedRow = ({ data }) => {
        return (
            <Container className='p-3'>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.paymentreference')}</small> {data?.paymentReference}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.quantity')}</small> {data?.quantity} €
                        </div>
                        <div className='d-flex justify-content-between border-bottom p-2'>
                            <small>{t('inscriptions.paymentdate')}:</small> {data.paymentDate ? `${new Date(data.paymentDate).getDate() > 9 ? new Date(data.paymentDate).getDate() : "0" + new Date(data.paymentDate).getDate()}/${new Date(data.paymentDate).getMonth() > 8 ? parseInt(new Date(data.paymentDate).getMonth()) + 1 : "0" + (parseInt(new Date(data.paymentDate).getMonth()) + 1)}/${new Date(data.paymentDate).getFullYear() > 9 ? new Date(data.paymentDate).getFullYear() : "0" + new Date(data.paymentDate).getFullYear()}`: '-'}
                        </div>
                        <div className='d-flex justify-content-between border-bottom p-2'>
                            <small>{t('payments.refType')}:</small> {data.refType && data.ref ? t('payments.' + data.refType) : t('payments.cardRegistration')}
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.status')}:</small> {t('inscriptions.statusseasonpay'+data?.status)} {data.externalPaid ? ` ${t('payments.externalPaid')}` : ''}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.creationdate')}:</small> {data.creationDate ? `${new Date(data.creationDate).getDate() > 9 ? new Date(data.creationDate).getDate() : "0" + new Date(data.creationDate).getDate()}/${new Date(data.creationDate).getMonth() > 8 ? parseInt(new Date(data.creationDate).getMonth()) + 1 : "0" + (parseInt(new Date(data.creationDate).getMonth()) + 1)}/${new Date(data.creationDate).getFullYear() > 9 ? new Date(data.creationDate).getFullYear() : "0" + new Date(data.creationDate).getFullYear()}`: '-'}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('payments.member')}:</small> {data.client ? `${data.client?.lastname}, ${data.client?.firstname}` : '-'}
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container className='cuerpo'>
            <Row className='mt-3 mb-5'>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.payments')}</h1>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('payments.paymentsList')}</h2>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            loading
                                                ?
                                                    <Loader/>
                                                :
                                                    <>
                                                        {
                                                            payments?.length > 0 ?
                                                                <CustomDataTable
                                                                    columns={columnsPayments}
                                                                    data={payments}
                                                                    exportable={false}
                                                                    printable={false}
                                                                    expandedData={ExpandedRow}
                                                                />
                                                            :
                                                                <div className='contenedor-vacio text-center'>
                                                                    <h4>{t('payments.noPayments')}</h4>
                                                                    {t('payments.thereIsNoPaymentsPhrase')}
                                                                </div>
                                                        }
                                                    </>
                                        }
                                        
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* <PaymentSummary userType="client" payment={paymentSelected} onHide={() => setPaymentSelected(null)}/> */}
            <ManualPayment userType="club" payment={manualPaymentSelected} onHide={() => setManualPaymentSelected(null)}/>
        </Container>
    )
}