import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function NotFoundPage () {
    const { t } = useTranslation()

	return (
		<div className='marketplace'>
			<Container className='market'>
				<Row>
					<Col xs='12' lg='6' className='mx-auto'>
						<h2 className='display-6'>{t('global.pagenotfound')}</h2>
						<img src='/images/clubsmoresearch.png' alt="Club más buscados" className='img-fluid'/>
					</Col>
				</Row>
				<Row>
					<Col className='text-center'>
						<Link to="/" className='btn btn-default'>{t('global.gotohome')}</Link>
					</Col>
				</Row>
			</Container>
		</div>
	)
}