import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removeCartItem } from "../../actions/payments/cartActions";

export default function CartTicket({item, index}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const calculatePassTypePrice = (item) => {
        let priceToPay = 0

        if (item.ticket?.type === "pass") {
            priceToPay = item.match.passTypes?.find(p => p.passType === item.ticket.client?.passType)?.price || 0
        } else {
            priceToPay = item.match.sectors?.find(s => s.sector === item.ticket.sector._id)?.fees?.find(f => f.fee === item.ticket.fee._id)?.price || 0
        }

        if (priceToPay > 0) {
            const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
            return Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
        } else {
            return 0.00
        }
    }

    return (
        <Row className="my-3 cart-item">
            <Col xs={12} md={4}>
                {
                    item.match?.thumbnail
                    ?
                        <img src={item.match.thumbnail.image || `/images/bannerclub.jpg`} alt={`${item.match.host} - ${item.match.visitor}`} className="w-100"/>
                    :
                        item.match?.hostBadge && item.match?.hostBadge !== "" && item.match?.visitorBadge && item.match?.visitorBadge !== ""
                        ?
                            <Row>
                                <Col>
                                    <img src={item.match.hostBadge || `/images/bannerclub.jpg`} alt={item.match.host} className="w-100"/>
                                </Col>
                                <Col>
                                    <img src={item.match.visitorBadge || `/images/bannerclub.jpg`} alt={item.match.visitor} className="w-100"/>
                                </Col>
                            </Row>
                        :
                            item.match.hostBadge && item.match.hostBadge !== ""
                            ?
                                <img src={item.match.hostBadge || `/images/bannerclub.jpg`} alt={item.match.host} className="w-100"/>
                            :
                                <img src="/images/bannerclub.jpg" alt={item.match.host} className="w-100"/>
                }
            </Col>
            <Col xs={12} md={8} className="d-flex justify-content-between align-items-center">
                <div className="data">
                    <h3 className="font-single mb-1">{item.match.host} - {item.match.visitor}</h3>
                    <p className="club">{item.club.name}</p>
                    {
                        item.ticket.type === "normal"
                        ?
                            <>
                                <p>{ t('matches.sector') }: {item.ticket.sector?.name}</p>
                                <p>{ t('matches.client') }: {`${item.ticket.client?.firstname || item.ticket.firstname} ${item.ticket.client?.lastname || item.ticket.lastname}`}</p>
                            </>
                        :
                            <p>{ t('matches.passPerson') }: {`${item.ticket.client?.name || item.ticket.client?.firstname} ${item.ticket.client?.lastname || ''}`}</p>
                    }
                    <p className="fw-bold">{t('marketplace.passType.total')}: {calculatePassTypePrice(item)}€</p>
                </div>
                <div className="icons">
                    <img src="/images/trash.svg" alt="trash" className="cursor" width={15} onClick={() => {dispatch(removeCartItem(index))}}/>
                </div>
            </Col>
        </Row>
    )
}