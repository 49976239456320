import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotFoundPage from '../pages/error/404'
import {Container} from 'react-bootstrap'
import MenuAdmin from '../components/menus/MenuAdmin'
import SidebarAdmin from '../components/menus/SidebarAdmin'
import Dashboard from '../pages/admin/Dashboard'
import Config from '../pages/admin/Config'
import Clubs from '../pages/admin/club/Clubs'
import Club from '../pages/admin/club/Club'
import Basicdata from '../pages/club/config/Basicdata'
import SocialdataClub from '../pages/club/config/Socialdata'
import Documents from '../pages/club/config/Documents'
import Billingdata from '../pages/club/config/Billingdata'
import Paycometdata from '../pages/club/config/Paycometdata'
import Clients from '../pages/admin/client/Clients'
import Personaldata from '../pages/client/config/Personaldata'
import SocialdataClient from '../pages/client/config/Socialdata'
import Addressdata from '../pages/client/config/Addressdata'
import Medicaldata from '../pages/client/config/Medicaldata'
import Carddata from '../pages/client/config/Carddata'
import Accountdata from '../pages/client/config/Accountdata'
import Identitydata from '../pages/client/config/Identitydata'
import Activities from '../pages/club/activities/activities/Activities'
import ActivityAdd from '../pages/club/activities/activities/ActivityAdd'
import ActivityEdit from '../pages/club/activities/activities/ActivityEdit'
import Categories from '../pages/club/activities/categories/Categories'
import CategoryEdit from '../pages/club/activities/categories/CategoryEdit'
import Groups from '../pages/club/activities/groups/Groups'
import GroupAdd from '../pages/club/activities/groups/GroupAdd'
import GroupEdit from '../pages/club/activities/groups/GroupEdit'
import Inscriptions from '../pages/admin/activities/inscriptions/Inscriptions'
import Sites from '../pages/club/sites/sites/Sites'
import AddSite from '../pages/club/sites/sites/AddSite'
// import AddSiteStep2 from '../pages/club/sites/sites/AddSiteStep2'
// import AddSiteStep3 from '../pages/club/sites/sites/AddSiteStep3'
import EditSite from '../pages/club/sites/sites/EditSite'
import EditSiteStep2 from '../pages/club/sites/sites/EditSiteStep2'
import EditSiteStep3 from '../pages/club/sites/sites/EditSiteStep3'
// import Zones from '../pages/club/sites/zones/Zones'
// import AddZone from '../pages/club/sites/zones/AddZone'
// import EditZone from '../pages/club/sites/zones/EditZone'
import Courts from '../pages/club/sites/courts/Courts'
import AddCourt from '../pages/club/sites/courts/AddCourt'
// import AddCourtStep2 from '../pages/club/sites/courts/AddCourtStep2'
import EditCourt from '../pages/club/sites/courts/EditCourt'
import EditCourtStep2 from '../pages/club/sites/courts/EditCourtStep2'
import CourtsRents from '../pages/club/sites/courts/CourtsRents'
// import Areas from '../pages/club/sites/areas/Areas'
// import AddArea from '../pages/club/sites/areas/AddArea'
// import AddAreaStep2 from '../pages/club/sites/areas/AddAreaStep2'
// import EditArea from '../pages/club/sites/areas/EditArea'
// import EditAreaStep2 from '../pages/club/sites/areas/EditAreaStep2'
import { useTranslation } from 'react-i18next'
import Commissiondata from '../pages/admin/club/config/Commissiondata'
import { useDispatch } from 'react-redux'
import { getClub } from '../actions/profiles/clubsActions'
import useAuth from '../context/auth/useAuth'
import Accounting from '../pages/club/config/Accounting'
import Seasons from '../pages/club/ticketing/seasons/Seasons'
import AddSeason from '../pages/club/ticketing/seasons/AddSeason'
import EditSeason from '../pages/club/ticketing/seasons/EditSeason'
import Sectors from '../pages/club/ticketing/sectors/Sectors'
import AddSector from '../pages/club/ticketing/sectors/AddSector'
import EditSector from '../pages/club/ticketing/sectors/EditSector'
import SepaLogs from '../pages/admin/logs/SepaLogs'
import Stadium from '../pages/club/ticketing/stadium/Stadium'
import Matches from '../pages/club/ticketing/matches/Matches'
import AddMatch from '../pages/club/ticketing/matches/AddMatch'
import EditMatch from '../pages/club/ticketing/matches/EditMatch'
import PassTypes from '../pages/club/ticketing/passTypes/PassTypes'
import AddPassType from '../pages/club/ticketing/passTypes/AddPassType'
import EditPassType from '../pages/club/ticketing/passTypes/EditPassType'
import InscriptionsCanceled from '../pages/club/activities/inscriptions/InscriptionsCanceled'
import AllPayments from '../pages/admin/payments/Payments'
import Fees from '../pages/club/ticketing/fees/Fees'
import AddFee from '../pages/club/ticketing/fees/AddFee'
import EditFee from '../pages/club/ticketing/fees/EditFee'
import Passes from '../pages/club/ticketing/passes/passes'
import Tickets from '../pages/club/ticketing/tickets/tickets'
import Check from '../pages/club/ticketing/matches/Check'
import Checks from '../pages/club/ticketing/matches/Checks'
import Invitations from '../pages/club/ticketing/tickets/invitations'

export default function AdminRouter () {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const {clientauth} = useAuth()

	const [usertype, setUsertype] = useState(null)
	const [selectedClientId, setSelectedClientId] = useState(null)
	const [selectedActivityId, setSelectedActivityId] = useState(null)
	const [selectedCategoryId, setSelectedCategoryId] = useState(null)
	const [selectedGroupId, setSelectedGroupId] = useState(null)
	const [selectedInscriptionId, setSelectedInscriptionId] = useState(null)
	const [selectedSiteId, setSelectedSite] = useState(null)
	// const [selectedZoneId, setSelectedZone] = useState(null)
	const [selectedCourtId, setSelectedCourt] = useState(null)
	// const [selectedAreaId, setSelectedArea] = useState(null)
	const [selectedSeasonId, setSelectedSeason] = useState(null)
	const [selectedSectorId, setSelectedSector] = useState(null)
	const [selectedFeeId, setSelectedFee] = useState(null)
	const [selectedMatchId, setSelectedMatch] = useState(null)
	const [selectedPassTypeId, setSelectedPassType] = useState(null)

	const [isCollapsed, setIsCollapsed] = useState(false)
	const toggleSidebar = () => {
		setIsCollapsed(!isCollapsed)
	}

	const updateSessionStorage = (newValues) => {
		const currentData = JSON.parse(sessionStorage.getItem('dataIncrono')) || {}
		const updatedData = { ...currentData, ...newValues }
		sessionStorage.setItem('dataIncrono', JSON.stringify(updatedData))
	}

    const handleClubSelect = (clubIdToChange) => {
		const storedData = JSON.parse(sessionStorage.getItem('dataIncrono'))
		let lastClubId = null
		if (storedData?.clubId) {
			lastClubId = storedData?.clubId
		}

		dispatch(getClub(clubIdToChange, true))
		if(lastClubId !== clubIdToChange){
			handleActivitySelect(null)
			handleCategorySelect(null)
			handleGroupSelect(null)
			setSelectedSite(null)
			// setSelectedZone(null)
			setSelectedCourt(null)
			// setSelectedArea(null)
			updateSessionStorage({clubId: clubIdToChange})
		}
    }

    const handleClientSelect = (clientId) => {
        setSelectedClientId(clientId)
        updateSessionStorage({clientId})
    }

    const handleActivitySelect = (activityId) => {
        setSelectedActivityId(activityId)
        updateSessionStorage({activityId})
    }

    const handleCategorySelect = (categoryId) => {
        setSelectedCategoryId(categoryId)
        updateSessionStorage({categoryId})
    }

    const handleGroupSelect = (groupId) => {
        setSelectedGroupId(groupId)
        updateSessionStorage({groupId})
    }

	const handleInscriptionSelect = (inscriptionId) => {
        setSelectedInscriptionId(inscriptionId)
		updateSessionStorage({inscriptionId})
	}

	const handleSiteSelect = (siteId) => {
		setSelectedSite(siteId)
		updateSessionStorage({siteId})
	}

	// const handleZoneSelect = (zoneId) => {
	// 	setSelectedZone(zoneId)
	// 	updateSessionStorage({zoneId})
	// }

	const handleCourtSelect = (courtId) => {
		setSelectedCourt(courtId)
		updateSessionStorage({courtId})
	}

	// const handleAreaSelect = (areaId) => {
	// 	setSelectedArea(areaId)
	// 	updateSessionStorage({areaId})
	// }

	const handleSeasonSelect = (seasonId) => {
		setSelectedSeason(seasonId)
		updateSessionStorage({seasonId})
	}

	const handleSectorSelect = (sectorId) => {
		setSelectedSector(sectorId)
		updateSessionStorage({sectorId})
	}
	
	const handleFeeSelect = (feeId) => {
		setSelectedFee(feeId)
		updateSessionStorage({feeId})
	}
	
	const handleMatchSelect = (matchId) => {
		setSelectedMatch(matchId)
		updateSessionStorage({matchId})
	}

	const handlePassTypeSelect = (passTypeId) => {
		setSelectedPassType(passTypeId)
		updateSessionStorage({passTypeId})
	}

	useEffect(() => {
		const storedData = sessionStorage.getItem('dataIncrono')
		if (storedData) {
			//const { clubId, clientId, activityId, categoryId, groupId, inscriptionId, siteId, zoneId, courtId, areaId } = JSON.parse(storedData)
			const { clubId, clientId, activityId, categoryId, groupId, inscriptionId, siteId, courtId, matchId } = JSON.parse(storedData)
			if (clubId) handleClubSelect(clubId)
			if (clientId) setSelectedClientId(clientId)
			if (activityId) setSelectedActivityId(activityId)
			if (categoryId) setSelectedCategoryId(categoryId)
			if (groupId) setSelectedGroupId(groupId)
			if (inscriptionId) setSelectedInscriptionId(inscriptionId)
			if (siteId) setSelectedSite(siteId)
			// if (zoneId) setSelectedZone(zoneId)
			if (courtId) setSelectedCourt(courtId)
			// if (areaId) setSelectedArea(areaId)
			if (matchId) setSelectedMatch(matchId)
		}
    // eslint-disable-next-line
	}, [])

    useEffect(() => {
		let token = sessionStorage.getItem('jwt')

		if (!token) {
			navigate(`/${t("url.auth.login")}`)
		}
    }, [navigate, t])

	useEffect(() => {
		if(clientauth){
			setUsertype(clientauth?.usertype)
		}
	}, [dispatch, clientauth])

	const adminRoutesAlias = {
		"": "dashboard",
		[t("url.admin.dashboard")]: "dashboard",
		[t("url.admin.config")]: "config",
		[t("url.admin.clubs")]: "clubs",
		[t("url.admin.club")]: "club",
		[t("url.admin.clients")]: "clients",
		[t("url.admin.inscriptions")]: "inscriptions",
		[t("url.admin.passes")]: "passes",
		[t("url.admin.tickets")]: "tickets",
		[t("url.admin.inscription")]: "inscription",
		[t("url.admin.payments")]: "payments",
		[`${t("url.admin.club")}/${t("url.admin.basicdata")}`]: "basicdata",
		[`${t("url.admin.club")}/${t("url.admin.socialdata")}`]: "socialdataclub",
		[`${t("url.admin.club")}/${t("url.admin.documents")}`]: "documents",
		[`${t("url.admin.club")}/${t("url.admin.billingdata")}`]: "billingdata",
		[`${t("url.admin.club")}/${t("url.admin.paycometdata")}`]: "paycometdata",
		[`${t("url.admin.club")}/${t("url.admin.commissiondata")}`]: "commissiondata",
		[`${t("url.admin.club")}/${t("url.admin.accounting")}`]: "accounting",

		[`${t("url.admin.club")}/${t("url.admin.activities")}`]: "activities",
		[`${t("url.admin.club")}/${t("url.admin.newactivity")}`]: "newactivity",
		[`${t("url.admin.club")}/${t("url.admin.editactivity")}`]: "editactivity",
		[`${t("url.admin.club")}/${t("url.admin.categories")}`]: "categories",
		[`${t("url.admin.club")}/${t("url.admin.editcategory")}`]: "editcategory",
		[`${t("url.admin.club")}/${t("url.admin.groups")}`]: "groups",
		[`${t("url.admin.club")}/${t("url.admin.newgroup")}`]: "newgroup",
		[`${t("url.admin.club")}/${t("url.admin.editgroup")}`]: "editgroup",
		[`${t("url.admin.club")}/${t("url.admin.inscriptions")}`]: "inscriptionsclub",
		[`${t("url.admin.club")}/${t("url.admin.inscriptionscanceled")}`]: "inscriptionscanceled",

		[`${t("url.admin.club")}/${t("url.admin.horaries")}`]: "horaries",
		[`${t("url.admin.club")}/${t("url.admin.edithorary")}`]: "edithorary",
		[`${t("url.admin.club")}/${t("url.admin.sites")}`]: "sites",
		[`${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.new")}`]: "sites/new",
		// [`${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.new")}/${t("url.admin.step2")}`]: "sites/new/step2",
		// [`${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.new")}/${t("url.admin.step3")}`]: "sites/new/step3",
		[`${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.edit")}`]: "sites/edit",
		[`${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.edit")}/${t("url.admin.step2")}`]: "sites/edit/step2",
		[`${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.edit")}/${t("url.admin.step3")}`]: "sites/edit/step3",
		// [`${t("url.admin.club")}/${t("url.admin.zones")}`]: "zones",
		// [`${t("url.admin.club")}/${t("url.admin.zones")}/${t("url.admin.new")}`]: "zones/new",
		// [`${t("url.admin.club")}/${t("url.admin.zones")}/${t("url.admin.edit")}`]: "zones/edit",
		[`${t("url.admin.club")}/${t("url.admin.courts")}`]: "courts",
		[`${t("url.admin.club")}/${t("url.admin.courts")}/${t("url.admin.new")}`]: "courts/new",
		[`${t("url.admin.club")}/${t("url.admin.courts")}/${t("url.admin.new")}/${t("url.admin.step2")}`]: "courts/new/step2",
		[`${t("url.admin.club")}/${t("url.admin.courts")}/${t("url.admin.edit")}`]: "courts/edit",
		[`${t("url.admin.club")}/${t("url.admin.courts")}/${t("url.admin.edit")}/${t("url.admin.step2")}`]: "courts/edit/step2",
		[`${t("url.admin.club")}/${t("url.club.courtsrents")}`]: "courtsrents",
		// [`${t("url.admin.club")}/${t("url.admin.areas")}`]: "areas",
		// [`${t("url.admin.club")}/${t("url.admin.areas")}/${t("url.admin.new")}`]: "areas/new",
		// [`${t("url.admin.club")}/${t("url.admin.areas")}/${t("url.admin.new")}/${t("url.admin.step2")}`]: "areas/new/step2",
		// [`${t("url.admin.club")}/${t("url.admin.areas")}/${t("url.admin.edit")}`]: "areas/edit",
		// [`${t("url.admin.club")}/${t("url.admin.areas")}/${t("url.admin.edit")}/${t("url.admin.step2")}`]: "areas/edit/step2",
		[`${t("url.admin.client")}/${t("url.admin.personaldata")}`]: "personaldata",
		[`${t("url.admin.client")}/${t("url.admin.socialdata")}`]: "socialdataclient",
		[`${t("url.admin.client")}/${t("url.admin.addressdata")}`]: "addressdata",
		[`${t("url.admin.client")}/${t("url.admin.medicaldata")}`]: "medicaldata",
		[`${t("url.admin.client")}/${t("url.admin.carddata")}`]: "carddata",
		[`${t("url.admin.client")}/${t("url.admin.accountdata")}`]: "accountdata",
		[`${t("url.admin.client")}/${t("url.admin.identitydata")}`]: "identitydata",
		[`${t("url.admin.club")}/${t("url.admin.seasons")}`]: "seasons",
		[`${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.new")}`]: "seasons/new",
		[`${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.edit")}`]: "seasons/edit",
		[`${t("url.admin.club")}/${t("url.admin.fees")}`]: "fees",
		[`${t("url.admin.club")}/${t("url.admin.fees")}/${t("url.admin.new")}`]: "fees/new",
		[`${t("url.admin.club")}/${t("url.admin.fees")}/${t("url.admin.edit")}`]: "fees/edit",
		[`${t("url.admin.club")}/${t("url.admin.sectors")}`]: "sectors",
		[`${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.new")}`]: "sectors/new",
		[`${t("url.admin.club")}/${t("url.admin.sectors")}/${t("url.admin.edit")}`]: "sectors/edit",
		[`${t("url.admin.club")}/${t("url.club.stadium")}`]: "stadium",
		[`${t("url.admin.club")}/${t("url.club.matches")}`]: "matches",
		[`${t("url.admin.club")}/${t("url.club.matches")}/${t("url.club.new")}`]: "matches/new",
		[`${t("url.admin.club")}/${t("url.club.matches")}/${t("url.club.edit")}`]: "matches/edit",
		[`${t("url.admin.club")}/${t("url.club.passTypes")}`]: "passTypes",
		[`${t("url.admin.club")}/${t("url.club.passTypes")}/${t("url.club.new")}`]: "passTypes/new",
		[`${t("url.admin.club")}/${t("url.club.passTypes")}/${t("url.club.edit")}`]: "passTypes/edit",
		[`${t("url.admin.club")}/${t("url.club.matches")}/${t("url.club.checks")}`]: "checks",

		[`${t("url.admin.club")}/${t("url.club.passes")}`]: "clubPasses",

		[`${t("url.admin.club")}/${t("url.club.tickets")}`]: "clubTickets",
		[`${t("url.admin.club")}/${t("url.club.invitations")}`]: "clubInvitations",

		[`${t("url.admin.logs")}/${t("url.admin.sepalogs")}`]: "logs/sepalogs",
	}

	const getComponentClub = (path) => {
		switch (path) {
			case "dashboard": return <Dashboard/>;
			case "config": return <Config/>;

			case "clubs": return <Clubs handleClubSelect={handleClubSelect}/>;
			case "club": return <Club handleClubSelect={handleClubSelect}/>;
			case "basicdata": return <Basicdata usertype={usertype} />;
			case "socialdataclub": return <SocialdataClub usertype={usertype} />;
			case "documents": return <Documents usertype={usertype} />;
			case "billingdata": return <Billingdata usertype={usertype} />;
			case "paycometdata": return <Paycometdata usertype={usertype} />;
			case "commissiondata": return <Commissiondata usertype={usertype} />;
			case "accounting": return <Accounting usertype={usertype} />;

			case "clients": return <Clients usertype={usertype} handleClientSelect={handleClientSelect} />;
			case "personaldata": return <Personaldata usertype={usertype} clientselected={selectedClientId}/>;
			case "socialdataclient": return <SocialdataClient usertype={usertype} clientselected={selectedClientId}/>;
			case "addressdata": return <Addressdata usertype={usertype} clientselected={selectedClientId}/>;
			case "medicaldata": return <Medicaldata usertype={usertype} clientselected={selectedClientId}/>;
			case "carddata": return <Carddata usertype={usertype} clientselected={selectedClientId}/>;
			case "accountdata": return <Accountdata usertype={usertype} clientselected={selectedClientId}/>;
			case "identitydata": return <Identitydata usertype={usertype} clientselected={selectedClientId}/>;

			case "activities": return <Activities usertype={usertype} handleActivitySelect={handleActivitySelect} />;
			case "newactivity": return <ActivityAdd usertype={usertype} handleActivitySelect={handleActivitySelect} />;
			case "editactivity": return <ActivityEdit usertype={usertype} activityselected={selectedActivityId} />;

			case "categories": return <Categories usertype={usertype} handleCategorySelect={handleCategorySelect} />;
			case "editcategory": return <CategoryEdit usertype={usertype} categoryselected={selectedCategoryId} />;

			case "groups": return <Groups usertype={usertype} groupselected={selectedGroupId} handleGroupSelect={handleGroupSelect} />;
			case "newgroup": return <GroupAdd usertype={usertype} handleGroupSelect={handleGroupSelect} />;
			case "editgroup": return <GroupEdit usertype={usertype} groupselected={selectedGroupId} />;

			case "inscriptions": return <Inscriptions usertype={usertype} isclub={false} inscriptionselected={selectedInscriptionId} handleInscriptionSelect={handleInscriptionSelect}/>;
			case "inscriptionsclub": return <Inscriptions usertype={usertype} isclub={true} inscriptionselected={selectedInscriptionId} handleInscriptionSelect={handleInscriptionSelect}/>;
			case "inscriptionscanceled": return <InscriptionsCanceled usertype={usertype} isclub={true} inscriptionselected={selectedInscriptionId} handleInscriptionSelect={handleInscriptionSelect}/>;

			case "payments": return <AllPayments usertype={usertype} isclub={false} />;

			case "sites": return <Sites usertype={usertype} handleSiteSelect={handleSiteSelect}/>
			case "sites/new": return <AddSite usertype={usertype} handleSiteSelect={handleSiteSelect}/>
			// case "sites/new/step2": return <AddSiteStep2 usertype={usertype} selectedSite={selectedSiteId}/>
			// case "sites/new/step3": return <AddSiteStep3 usertype={usertype} selectedSite={selectedSiteId}/>
			case "sites/edit": return <EditSite usertype={usertype} handleSiteSelect={handleSiteSelect}/>
			case "sites/edit/step2": return <EditSiteStep2 usertype={usertype} selectedSite={selectedSiteId}/>
			case "sites/edit/step3": return <EditSiteStep3 usertype={usertype} selectedSite={selectedSiteId}/>

			// case "zones": return <Zones usertype={usertype} handleZoneSelect={handleZoneSelect} />;
			// case "zones/new": return <AddZone usertype={usertype} />;
			// case "zones/edit": return <EditZone usertype={usertype} selectedZoneId={selectedZoneId} />;

			case "courts": return <Courts usertype={usertype} handleCourtSelect={handleCourtSelect}/>
			case "courts/new": return <AddCourt usertype={usertype} handleCourtSelect={handleCourtSelect} selectedCourt={selectedCourtId}/>
			// case "courts/new/step2": return <AddCourtStep2 usertype={usertype} handleCourtSelect={handleCourtSelect} selectedCourt={selectedCourtId}/>
			case "courts/edit": return <EditCourt usertype={usertype} selectedCourtId={selectedCourtId}/>
			case "courts/edit/step2": return <EditCourtStep2 usertype={usertype} selectedCourt={selectedCourtId}/>

			case "courtsrents": return <CourtsRents usertype={usertype}/>

			// case "areas": return <Areas usertype={usertype} handleAreaSelect={handleAreaSelect}/>
			// case "areas/new": return <AddArea usertype={usertype} handleAreaSelect={handleAreaSelect} selectedArea={selectedAreaId}/>
			// case "areas/new/step2": return <AddAreaStep2 usertype={usertype} handleAreaSelect={handleAreaSelect} selectedArea={selectedAreaId}/>
			// case "areas/edit": return <EditArea usertype={usertype} selectedAreaId={selectedAreaId}/>
			// case "areas/edit/step2": return <EditAreaStep2 usertype={usertype} selectedArea={selectedAreaId}/>

			case "seasons": return <Seasons usertype={usertype} handleSeasonSelect={handleSeasonSelect}/>
			case "seasons/new": return <AddSeason usertype={usertype}/>
			case "seasons/edit": return <EditSeason usertype={usertype} handleSeasonSelect={handleSeasonSelect} selectedSeason={selectedSeasonId}/>

			case "fees": return <Fees usertype={usertype} handleFeeSelect={handleFeeSelect}/>
			case "fees/new": return <AddFee usertype={usertype}/>
			case "fees/edit": return <EditFee usertype={usertype} handleFeeSelect={handleFeeSelect} selectedFee={selectedFeeId}/>

			case "sectors": return <Sectors usertype={usertype} handleSectorSelect={handleSectorSelect}/>
			case "sectors/new": return <AddSector usertype={usertype}/>
			case "sectors/edit": return <EditSector usertype={usertype} handleSectorSelect={handleSectorSelect} selectedSector={selectedSectorId}/>

			case "stadium": return <Stadium usertype={usertype}/>

			case "matches": return <Matches usertype={usertype} handleMatchSelect={handleMatchSelect}/>
			case "matches/new": return <AddMatch usertype={usertype}/>
			case "matches/edit": return <EditMatch usertype={usertype} handleMatchSelect={handleMatchSelect} selectedMatch={selectedMatchId}/>

			case "passTypes": return <PassTypes usertype={usertype} handlePassTypeSelect={handlePassTypeSelect}/>
			case "passTypes/new": return <AddPassType usertype={usertype}/>
			case "passTypes/edit": return <EditPassType usertype={usertype} handlePassTypeSelect={handlePassTypeSelect} selectedPassType={selectedPassTypeId}/>

			case "checks": return <Checks usertype={usertype} selectedMatch={selectedMatchId}/>

			case "clubPasses": return <Passes usertype={usertype}/>;

			case "clubTickets": return <Tickets usertype={usertype}/>;
			case "clubInvitations": return <Invitations usertype={usertype}/>;

			case "passes": return <Passes usertype={usertype} isAdmin={true}/>;

			case "tickets": return <Tickets usertype={usertype} isAdmin={true}/>;

			case "logs/sepalogs": return <SepaLogs usertype={usertype}/>

			default: return <NotFoundPage />;
		}
	}

	return (
		<Container fluid className='d-flex p-0'>
			<SidebarAdmin isCollapsed={isCollapsed} />
			<Container fluid className={`contenedor ${isCollapsed ? 'expanded' : ''}`}>
				<MenuAdmin toggleSidebar={toggleSidebar} isCollapsed={isCollapsed}/>
				<Routes>
					{Object.entries(adminRoutesAlias).map(([alias, path]) => (
						<Route key={alias} path={alias} element={getComponentClub(path)} />
					))}
					<Route path="*" element={<NotFoundPage />}/>
				</Routes>
			</Container>			
		</Container>
	)
}