export const logsReducer = (state = { loading:true, result:null, logs:[], cardsparent:[], cardaux:null, cardsdenied:false } , action) => {
    switch (action.type) {
        case 'LOG_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                logs: action.payload
            }
        case 'LOG_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'LOG_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

