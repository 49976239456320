import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getCommissionByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'COMMISSION_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/commission/getcommissionbyclub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'COMMISSION_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COMMISSION_NOT_LOADING'
        })
    }
}

export const editCommissionClub = (commissiondata, commissionId) => async (dispatch) => {
    dispatch({
        type: 'COMMISSION_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/commission/edit/' + commissionId, commissiondata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'COMMISSION_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'COMMISSION_NOT_LOADING'
        })
    }
}