import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, CardBody } from 'react-bootstrap'
import Select from "react-select"
import { useDispatch, useSelector } from 'react-redux'
import { editUserClub } from '../../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import { sendToVerify } from '../../../actions/profiles/clubsActions'
import { useForm } from 'react-hook-form'
import HeadConfigClub from '../../../components/menus/SubMenuConfigClub'
import ClubBreadCrumbs from '../../../components/breadcrumbs/ClubBreadCrumbs'

export default function Paycometdata({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { register, reset, watch } = useForm()

    const data = {
        dniFront: watch("dniFront"),
        dniBack: watch("dniBack"),
        ownershipCertificate: watch("ownershipCertificate"),
        statutesCompany: watch("statutesCompany"),
        paymentUptodate: watch("paymentUptodate"),
        invoiceService: watch("invoiceService"),
        corporationTax: watch("corporationTax"),
        cif: watch("cif")
    }
    const typeCompany = [
        {
            "value": '2',
            "label": t('club.paycomet.selfEmployed')
        },
        {
            "value": "3",
            "label": t('club.paycomet.business')
        },
    ]

    const { user, resultuser } = useSelector(state => state.users)
    const { club, resultclub } = useSelector(state => state.clubs)
    const [typeCompanyAux, setTypeCompanyAux] = useState('')
    const [typeCompanyNameAux, setTypeCompanyNameAux] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)

    const [paycomet, setPaycomet] = useState({})

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (club) {
            setPaycomet({
                clubName: club?.paycomet?.clubName || '',
                shortName: club?.paycomet?.shortName || '',
                typeCompany: club?.paycomet?.typeCompany || 3,
                sepa: club?.paycomet?.sepa || '',
                iban: club?.paycomet?.iban || '',
                dniFront: club?.paycomet?.dniFront || '',
                dniFrontPreVerified: club?.paycomet?.dniFrontPreVerified || false,
                dniFrontVerified: club?.paycomet?.dniFrontVerified || 0,
                dniBack: club?.paycomet?.dniBack || '',
                dniBackPreVerified: club?.paycomet?.dniBackPreVerified || false,
                dniBackVerified: club?.paycomet?.dniBackVerified || 0,
                ownershipCertificate: club?.paycomet?.ownershipCertificate || '',
                ownershipCertificatePreVerified: club?.paycomet?.ownershipCertificatePreVerified || false,
                ownershipCertificateVerified: club?.paycomet?.ownershipCertificateVerified || 0,
                statutesCompany: club?.paycomet?.statutesCompany || '',
                statutesCompanyPreVerified: club?.paycomet?.statutesCompanyPreVerified || false,
                statutesCompanyVerified: club?.paycomet?.statutesCompanyVerified || 0,
                cif: club?.paycomet?.cif || '',
                cifPreVerified: club?.paycomet?.cifPreVerified || false,
                cifVerified: club?.paycomet?.cifVerified || 0,
                paymentUptodate: club?.paycomet?.paymentUptodate || '',
                paymentUptodatePreVerified: club?.paycomet?.paymentUptodatePreVerified || false,
                paymentUptodateVerified: club?.paycomet?.paymentUptodateVerified || 0,
                invoiceService: club?.paycomet?.invoiceService || '',
                invoiceServicePreVerified: club?.paycomet?.invoiceServicePreVerified || false,
                invoiceServiceVerified: club?.paycomet?.invoiceServiceVerified || 0,
                corporationTax: club?.paycomet?.corporationTax || '',
                corporationTaxPreVerified: club?.paycomet?.corporationTaxPreVerified || false,
                corporationTaxVerified: club?.paycomet?.corporationTaxVerified || 0,
                blocked: club?.paycomet?.blocked || false,
                allVerified: club?.paycomet?.allVerified || false
            })
            if(club?.paycomet?.typeCompany && club?.paycomet?.typeCompany !== ''){
                    setTypeCompanyAux(club?.paycomet?.typeCompany)
                    if(club?.paycomet?.typeCompany === 2){
                        setTypeCompanyNameAux('selfEmployed')
                    }else{
                        setTypeCompanyNameAux('business')
                    }
            }
        }
    // eslint-disable-next-line
    }, [user, club])

    const verifyDocument = (documentType) => {
        setPaycomet({
            ...paycomet,
            [documentType]: !paycomet[documentType]
        })

        dispatch(editUserClub({paycomet: {
            ...paycomet,
            [documentType]: !paycomet[documentType]
        }}, club._id, null, null, null, null, null))
    }

    const sendDocumentToVerify = (documentType) => {
        dispatch(sendToVerify(club?._id, documentType))
    }

    const blockunblockDocuments = () => {
        setPaycomet({
            ...paycomet,
            blocked: !paycomet.blocked
        })

        dispatch(editUserClub({paycomet: {
            ...paycomet,
            blocked: !paycomet.blocked
        }}, club._id, null, null, null, null, null))
    }

    const handleEdit = async (e) => {
        e.preventDefault()

        setIsUpdating(true)

        // const data = getValues()

        // Despachamos la acción con el FormData
        dispatch(editUserClub({paycomet: paycomet}, club._id, null, null, null, null, null, data))

        setIsUpdating(false)
    }

    const handleInputChange = ({target}) => {
        setPaycomet({
            ...paycomet,
            [target.name]: target.value
        })
    }
    const handleTypeCompanyChange = (target) => {
        setTypeCompanyAux(target.value)
        if(target.value === '2'){
            setTypeCompanyNameAux('selfEmployed')
        }else{
            setTypeCompanyNameAux('business')
        }


        setPaycomet({
            ...paycomet,
            typeCompany: target.value
        })
    }


    useEffect(() => {
        if (resultuser) {
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    // TODO: VERIFICAR ESTO SI SE PUEDE QUITAR
                    // dispatch(getClub(clubselected))
                    reset()
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    useEffect(() => {
        if (resultclub) {
            switch (resultclub) {
                case 'sendToVerifySuccess':
                    // TODO: VERIFICAR ESTO SI SE PUEDE QUITAR
                    // dispatch(getClub(clubselected))
                    dispatch({type: 'CLUB_RESET_RESULT'})
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('club.documentVerified')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultclub])

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <HeadConfigClub nuevaSeccion="paycometdata" usertype={usertype}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('club.documents')}</h2>
                            <hr className='mb-4' />
                            <Form className="formulario" onSubmit={handleEdit}>
                                {usertype === 'superadmin' ?
                                    <Row className='my-3'>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className='form-group'>
                                                <Form.Control
                                                    className={paycomet.shortName ? 'has-content' : ''}
                                                    type='text'
                                                    name='shortName'
                                                    id='shortName'
                                                    value={paycomet.shortName || ''}
                                                    maxLength={25}
                                                    onChange={handleInputChange}
                                                />
                                                <Form.Label htmlFor='shortName'>{t('club.shortName')}</Form.Label>
                                                <Form.Text className='text-muted'>
                                                    {t('club.shortNameDescription')}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <Form.Group className='form-group'>
                                                <Form.Control
                                                    className={paycomet.sepa ? 'has-content' : ''}
                                                    type='text'
                                                    name='sepa'
                                                    id='sepa'
                                                    value={paycomet.sepa || ''}
                                                    onChange={handleInputChange}
                                                />
                                                <Form.Label htmlFor='sepa'>{t('club.sepa')}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <Form.Group className='form-group'>
                                                <Select
                                                    className="form-control select has-content"
                                                    options={typeCompany}
                                                    name='typeCompany'
                                                    value={{value: typeCompanyAux, label: t('club.paycomet.'+typeCompanyNameAux)}}
                                                    onChange={handleTypeCompanyChange}
                                                />
                                                <Form.Label htmlFor='typeCompany'>{t('club.paycomet.typeCompany')}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                                }
                                <Row className='my-3'>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={paycomet.clubName ? 'has-content' : ''}
                                                type='text'
                                                name='clubName'
                                                id='clubName'
                                                value={paycomet.clubName || ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='clubName'>{t('club.clubName')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={paycomet.iban ? 'has-content' : ''}
                                                type='text'
                                                name='iban'
                                                id='iban'
                                                value={paycomet.iban || ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='iban'>{t('club.iban')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    {/* DNI FRONT */}
                                    <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.dniFront')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.dniFrontVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.dniFrontPreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.dniFront ?
                                                    <a href={paycomet?.dniFront} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('dniFrontPreVerified')}>
                                                        {!paycomet?.dniFrontPreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.dniFrontPreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="dniFront"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('dniFront', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.dniFrontPreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.dniFrontVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('dniFront')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                    {/* DNI BACK */}
                                    <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.dniBack')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.dniBackVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.dniBackPreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.dniBack ?
                                                    <a href={paycomet?.dniBack} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('dniBackPreVerified')}>
                                                        {!paycomet?.dniBackPreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.dniBackPreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="dniBack"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('dniBack', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.dniBackPreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.dniBackVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('dniBack')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                    {/* OWNER SHIP CERTIFICATE */}
                                    <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.ownershipCertificate')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.ownershipCertificateVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.ownershipCertificatePreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.ownershipCertificate ?
                                                    <a href={paycomet?.ownershipCertificate} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('ownershipCertificatePreVerified')}>
                                                        {!paycomet?.ownershipCertificatePreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.ownershipCertificatePreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="ownershipCertificate"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('ownershipCertificate', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.ownershipCertificatePreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.ownershipCertificateVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('ownershipCertificate')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                    {/* STATUS COMPANY */}
                                    <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.statutesCompany')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.statutesCompanyVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.statutesCompanyPreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.statutesCompany ?
                                                    <a href={paycomet?.statutesCompany} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('statutesCompanyPreVerified')}>
                                                        {!paycomet?.statutesCompanyPreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.statutesCompanyPreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="statutesCompany"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('statutesCompany', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.statutesCompanyPreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.statutesCompanyVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('statutesCompany')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                    {/* PAYMENT UP TO DATE */}
                                    <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.paymentUptodate')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.paymentUptodateVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.paymentUptodatePreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.paymentUptodate ?
                                                    <a href={paycomet?.paymentUptodate} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('paymentUptodatePreVerified')}>
                                                        {!paycomet?.paymentUptodatePreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.paymentUptodatePreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="paymentUptodate"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('paymentUptodate', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.paymentUptodatePreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.paymentUptodateVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('paymentUptodate')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                     {/* INVOICE SERVICE */}
                                     <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.invoiceService')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.invoiceServiceVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.invoiceServicePreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.invoiceService ?
                                                    <a href={paycomet?.invoiceService} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('invoiceServicePreVerified')}>
                                                        {!paycomet?.invoiceServicePreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.invoiceServicePreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="invoiceService"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('invoiceService', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.invoiceServicePreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.invoiceServiceVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('invoiceService')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                     {/* CORPORATION TAX */}
                                     <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.corporationTax')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.corporationTaxVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.corporationTaxPreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.corporationTax ?
                                                    <a href={paycomet?.corporationTax} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('corporationTaxPreVerified')}>
                                                        {!paycomet?.corporationTaxPreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.corporationTaxPreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="corporationTax"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('corporationTax', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.corporationTaxPreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.corporationTaxVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('corporationTax')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                    {/* CIF */}
                                    <Col xs={12} className='py-4 border-1 border-bottom'>
                                        <h3 className="font-single">{t('club.cif')}</h3>
                                        <div className='d-flex justify-content-start mx-3'>
                                            <div className='verify text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.status')}</div>
                                                {paycomet?.cifVerified
                                                    ?
                                                    <i className="fa-regular fa-circle-check"/>
                                                    : paycomet?.cifPreVerified
                                                        ?
                                                        <i className="fa-regular fa-clock"></i>
                                                        :
                                                        <i className="fa-regular fa-circle-xmark"></i>
                                                }
                                            </div>
                                            <div className='document text-center me-5'>
                                                <div className='fw-bold'>{t('club.paycomet.document')}</div>
                                                {paycomet?.cif ?
                                                    <a href={paycomet?.cif} target="_blank" rel="noopener noreferrer">
                                                        <img src="/images/eyeopen.svg" alt="Ver" />
                                                    </a>
                                                :
                                                    t('club.paycomet.nodocument')
                                                }
                                            </div>
                                            {usertype === 'superadmin' ?
                                                <div className='text-center me-5'>
                                                    <div className='fw-bold'>{t('club.paycomet.verifyincrono')}</div>
                                                    <div className='cursor' onClick={() => verifyDocument('cifPreVerified')}>
                                                        {!paycomet?.cifPreVerified ?
                                                            t('club.paycomet.verify')
                                                        :
                                                            t('club.paycomet.unverify')
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                            }
                                            {(!paycomet?.cifPreVerified && !paycomet?.blocked) || usertype === 'superadmin'
                                                ?
                                                    <div className='text-center me-5'>
                                                        <div className='fw-bold'>{t('club.paycomet.upload')}</div>
                                                        <input
                                                            type="file"
                                                            name="cif"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Documento"
                                                            {...register('cif', { required: t('club.selectfile') })}
                                                        />
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            {
                                            usertype === 'superadmin' &&
                                                paycomet?.cifPreVerified && club?.paycomet?.iban && club?.paycomet?.clubName && paycomet?.cifVerified !== 3 &&
                                                    <div>
                                                        <Button className="btn btn-primary" type="button" onClick={() => sendDocumentToVerify('cif')}>
                                                            {t('club.paycomet.sendToVerify')}
                                                        </Button>
                                                    </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-3'>
                                    <Col xs={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2" disabled={usertype !== 'superadmin' && paycomet?.blocked}>
                                            {isUpdating
                                                ? <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                : <>{t('global.update')}</>
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className='my-3'>
                                        {usertype === 'superadmin' ?
                                            <>
                                                <Col xs={6} className='ms-auto'>
                                                    <Button variant='primary' type="button" className="w-100 my-2" onClick={blockunblockDocuments}>
                                                        {paycomet?.blocked ?
                                                            t('club.paycomet.notblockuploaddocuments')
                                                        :
                                                            t('club.paycomet.blockuploaddocuments')
                                                        }
                                                    </Button>
                                                </Col>
                                                {
                                                    paycomet?.dniFrontPreVerified && paycomet?.dniBackPreVerified && paycomet?.ownershipCertificatePreVerified && paycomet?.statutesCompanyPreVerified && paycomet?.paymentUptodatePreVerified && paycomet?.invoiceServicePreVerified && paycomet?.corporationTaxPreVerified && !paycomet?.allVerified &&
                                                        <Col xs={6} className='ms-auto'>
                                                            <Button variant='secondary' type='button' className='w-100 my-2' onClick={() => sendDocumentToVerify('all')}>
                                                                {t('club.paycomet.sendAllToVerify')}
                                                            </Button>
                                                        </Col>
                                                }
                                            </>
                                        :
                                            null
                                        }
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}