import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { parseISO, format } from 'date-fns'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Pass from '../downloads/Pass'
import { useDispatch } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import { changePassStatus } from '../../actions/ticketing/passesActions'

export default function PassSummary({usertype, passSelected, closeModal, formType}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const handleStatusChange = (status) => {
        dispatch(changePassStatus(passSelected?._id, clientauth.id, status))
    }

    if (!passSelected) {
        return null; 
    }

    return (
        <Modal show={passSelected ? true : false} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('passes.passData')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cuerpo px-5 py-4'>
                <Row className="mb-5">
                    <Col xs={12} md={8}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('passes.identifier')}:</small>
                            <div className='text-end'>
                                {passSelected?.identifier}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('passes.club')}:</small>
                            <div className='text-end'>
                                {passSelected?.club?.name}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('passes.client')}:</small>
                            <div className='text-end'>
                                {
                                    passSelected?.client
                                        ?
                                        <>
                                            {passSelected?.client?.firstname} {passSelected?.client?.lastname}
                                        </>
                                        :
                                        <>
                                            {passSelected?.personData?.firstname} {passSelected?.personData?.lastname ? passSelected?.personData?.lastname : ''}
                                        </>
                                }<br/>
                                {passSelected?.personData?.email ? passSelected?.personData?.email: ''}

                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('passes.passType')}:</small>
                            <div className='text-end'>
                                {passSelected?.passType?.name}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('passes.season')}:</small>
                            <div className='text-end'>
                                {passSelected?.season?.name}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('passes.availableUntil')}:</small>
                            <div className='text-end'>
                                {passSelected?.availableUntil ? format(parseISO(passSelected?.availableUntil), 'dd/MM/yyyy') : ''}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('passes.sector')}:</small>
                            <div className='text-end'>
                                {passSelected?.sector?.name}
                            </div>
                        </div>
                        {
                            passSelected?.seat?.row
                            ?
                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                    <small>{t('passes.seat')}:</small>
                                    <div className='text-end'>
                                        {`${t("passes.row")} ${parseInt(passSelected?.seat?.row) + 1}, ${t("passes.column")} ${parseInt(passSelected?.seat?.column) + 1}`}
                                    </div>
                                </div>
                            :
                                <></>
                        }
                         {passSelected?.questions?.length > 0 ?
                            <>
                                {/* <Row>
                                    <Col>
                                        <div className="d-flex justify-content-between align-items-end">
                                            <h3 className="font-single">{t('inscriptions.questions')}</h3>
                                        </div>
                                        <hr className="fina"/>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col>
                                        {/* <Row className="my-2 p-2 rounded">
                                            <Col xs={12} lg={4}>
                                                {t('inscriptions.question')}
                                            </Col>
                                            <Col xs={12} lg={4}>
                                                {t('inscriptions.answer')}
                                            </Col>
                                            <Col xs={12} lg={4}>
                                                {t('inscriptions.impact')}
                                            </Col>
                                        </Row> */}
                                        {passSelected?.questions.map((question, index) => (
                                            <div key={index}>
                                                <Col className='d-flex justify-content-between border-1 border-bottom  p-2'>
                                                    <small>{question.question}</small>
                                                    {question.answer}
                                                </Col>
                                                {/* <Col xs={12} lg={4}>
                                                    {t('inscriptions.impactregistration')}<br/>
                                                        {question?.impact?.registrationQuantity ? question?.impact?.registrationQuantity+' €' : '0 €'}
                                                        {question?.impact?.registrationQuantity && question?.impact?.registrationPercentage ? ' - ' : ''}
                                                        {question?.impact?.registrationPercentage ? question?.impact?.registrationPercentage+' %' : '0 %'}<br/><br/>
                                                    {t('inscriptions.impactseason')}<br/>
                                                        {question?.impact?.seasonPaymentQuantity ? question?.impact?.seasonPaymentQuantity+' €' : '0 €'}
                                                        {question?.impact?.seasonPaymentQuantity && question?.impact?.seasonPaymentPercentage ? ' - ' : ''}
                                                        {question?.impact?.seasonPaymentPercentage ? question?.impact?.seasonPaymentPercentage+' %' : '0 %'}<br/>
                                                </Col> */}
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
                            </>
                        :
                            <></>
                        }
                    </Col>
                    <Col xs={12} md={4}>
                        <div className='p-2 text-center'>
                            <small>{t('passes.status')}</small>
                            <div className='fw-bold'>
                                {
                                    passSelected?.status === 0 ? t('passes.pending') :
                                    passSelected?.status === 1 ? t('passes.active') :
                                    passSelected?.status === 2 ? t('passes.canceled') :
                                    t('passes.unknown')
                                }
                            </div>
                        </div>
                        {
                            formType === "edit" && (usertype === "club" || usertype === "superadmin")
                            ?
                                <div className='mt-4 text-center'>
                                    <small>{t('passes.changeStatus')}</small>
                                    {
                                        passSelected?.status === 0 || passSelected?.status === 2
                                        ?
                                            <div className='btn btn-secondary p-1 my-3' onClick={() => handleStatusChange(1)}>{t('passes.toActive')}</div>
                                        :
                                            passSelected?.status === 0 ||passSelected?.status === 1
                                            ?
                                                <div className='btn btn-default p-1' onClick={() => handleStatusChange(2)}>{t('passes.toCancelled')}</div>
                                            :
                                                <></>
                                    }
                                </div>
                            :
                                <></>
                        }
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col xs={12} md={6} className='ms-auto'>
                        <PDFDownloadLink document={<Pass pass={passSelected}/>} fileName={`${passSelected?.identifier}.pdf`}>
                            <Button type='button' className='w-100 my-2' variant='default'>
                                <img src='/images/download.svg' alt='download'/>
                            </Button>
                        </PDFDownloadLink>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
	)
}