import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../context/auth/useAuth'
import Loader from '../../../components/design/Loader/Loader'
import { getPassesByClient } from '../../../actions/ticketing/passesActions'
import { Link } from 'react-router-dom'
import PassSummary from '../../../components/ticketing/PassSummary'

export default function Passes({clientSelected}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {passes, loading} = useSelector(state=>state.pass)

    const [passSelected, setPassSelected] = useState(null)

	const columnsPasses = [
        {
            name: t('passes.club'),
            cell: pass =>
                pass.club.name,
            selector: (pass) => pass.club.name,
            sortable: false,
        },{
            name: t('passes.season'),
            cell: pass =>
                pass.season.name,
            selector: (pass) => pass.season.name,
            sortable: true,
        },{
            name: t('passes.sector'),
            cell: pass =>
                pass.sector.name,
            selector: (pass) => pass.sector.name,
            sortable: true,
        },{
            name: t('passes.status'),
            center: 'true',
            cell: pass =>
                pass.status === 0 ? t('passes.pending') :
                pass.status === 1 ? t('passes.active') :
                pass.status === 2 ? t('passes.canceled') :
                t('passes.unknown'),
            selector: (pass) =>
                pass.status === 0 ? t('passes.pending') :
                pass.status === 1 ? t('passes.active') :
                pass.status === 2 ? t('passes.canceled') :
                t('passes.unknown'),
            sortable: true,
        },{
            name: t('passes.client'),
            cell: pass => `${pass.client ? pass.client.firstname : pass.personData.firstname} ${pass.client ? pass.client.lastname : pass.personData.lastname}`,
            selector: (pass) => `${pass.client ? pass.client.firstname : pass.personData.firstname} ${pass.client ? pass.client.lastname : pass.personData.lastname}`,
            sortable: true,
        },{
            name: t('global.actions'),
            center: 'true',
            cell: pass =>
                <Link
                    onClick={() => {
                        setPassSelected(pass)
                    }}
                    className='table-link'>
                    <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16}/>
                </Link>,
            sortable: false,
        }
	]

    useEffect(() => {
        if(clientauth){
            dispatch(getPassesByClient(clientSelected))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth])

    return (
        <Container className='cuerpo'>
            <Row className='mt-3 mb-5'>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('client.passes')}</h1>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('passes.passesList')}</h2>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            loading
                                                ?
                                                    <Loader/>
                                                :
                                                    <>
                                                        {
                                                            passes?.length > 0 ?
                                                                <CustomDataTable
                                                                    columns={columnsPasses}
                                                                    data={passes}
                                                                    exportable={false}
                                                                    printable={false}
                                                                />
                                                            :
                                                                <div className='contenedor-vacio text-center'>
                                                                    <h4>{t('passes.noPasses')}</h4>
                                                                    {t('passes.thereIsNoPassesPhrase')}
                                                                </div>
                                                        }
                                                    </>
                                        }
                                        
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <PassSummary userType="client" passSelected={passSelected} closeModal={() => setPassSelected(null)}/>
        </Container>
    )
}