import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { editSeason, getSeason } from '../../../../actions/ticketing/seasonsActions'
import SeasonForm from '../../../../components/forms/seasons/SeasonForm'

export default function EditSeason({usertype, selectedSeason}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading, season} = useSelector(state=>state.seasons)

    const [seasonForm, setSeasonForm] = useState({
        name: '',
        startDate: '',
        endDate: '',
        club: null
    })

    const handleInputChange = ({target})=>{
        setSeasonForm({
            ...seasonForm,
            [target.name]: target.value
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()

        if(seasonForm.name !== ''){
            dispatch(editSeason(season?._id, seasonForm))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('seasons.noName')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result
            dispatch({
                type: 'SEASON_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('seasons.editSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.seasons")}`)
                        }
                    })
                    break;
                case 'duplicateName':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('seasons.duplicateName')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (selectedSeason) {
            dispatch(getSeason(selectedSeason))
        }
    }, [selectedSeason, dispatch])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (season) {
            setSeasonForm({
                name: season?.name,
                startDate: `${new Date(season?.startDate).getFullYear()}-${new Date(season?.startDate).getMonth() + 1 < 10 ? '0' + parseInt(new Date(season?.startDate).getMonth() + 1) : new Date(season?.startDate).getMonth() + 1}-${new Date(season?.startDate).getDate() < 10 ? '0' + new Date(season?.startDate).getDate() : new Date(season?.startDate).getDate()}`,
                endDate: `${new Date(season?.endDate).getFullYear()}-${new Date(season?.endDate).getMonth() + 1 < 10 ? '0' + parseInt(new Date(season?.endDate).getMonth() + 1) : new Date(season?.endDate).getMonth() + 1}-${new Date(season?.endDate).getDate() < 10 ? '0' + new Date(season?.endDate).getDate() : new Date(season?.endDate).getDate()}`,
                club: season?.club
            })
        }
    }, [season])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('seasons.editSeasonForm')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <SeasonForm state={seasonForm} statetype={'edit'} usertype={usertype} isAdding={loading} submit={handleEdit} inputChange={handleInputChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}