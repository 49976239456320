import React, { useEffect, useState } from 'react'
import useAuth from '../../../context/auth/useAuth'
import { Button, Card, Col, Form, FormSelect, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../actions/profiles/usersActions'
import Loader from '../../../components/design/Loader/Loader'
import MemberSelect from '../../../components/design/Select/MemberSelect'
import { getClient } from '../../../actions/profiles/clientsActions'
import { getGroupsPublicByActivityAndClient } from '../../../actions/activities/groupsActions'
import { addCartItem } from '../../../actions/payments/cartActions'
import Swal from 'sweetalert2'

export default function Group({activity, club}) {
	const {clientauth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {user, loading} = useSelector(state=>state.users)
    const {client, loading: clientLoading} = useSelector(state => state.clients)
    const {groupsMarket: groups, loadinggroup: groupsLoading, commission} = useSelector(state => state.groups)
    const {status} = useSelector(state => state.cart)

    const [clientSelected, setClientSelected] = useState(null)
    const [groupOptionsForm, setGroupOptionsForm] = useState([])

    const [groupScheduleModal, setGroupScheduleModal] = useState(null)

    const handleSubmit = (event) => {
        event.preventDefault()
    }

    const handleClientSelect = (value) => {
        setClientSelected(value)
        dispatch(getGroupsPublicByActivityAndClient(activity._id, value._id))
    }

    const handleHoraryChange = (target, index) => {
        setGroupOptionsForm(groupOptionsForm.map((group, i) => {
            if (index === i) {
                return {...group, horarySelected: target.value}
            } else {
                return group
            }
        }))
    }

    const handleQuestionChange = (target, groupIndex, questionIndex) => {
        setGroupOptionsForm(groupOptionsForm.map((group, i) => {
            if (groupIndex === i) {
                return {...group, questions: group.questions.map((question, ind) => {
                    if (questionIndex === ind) {
                        return target.value
                    } else {
                        return question
                    }
                })}
            } else {
                return group
            }
        }))
    }

    const handleGroupSelection = (groupIndex) => {
        if (groups[groupIndex].horaries?.length > 0) {
            let questions = []
            for (let i = 0; i < groups[groupIndex].questions.length; i++) {
                const formQuestion = groupOptionsForm[groupIndex].questions[i]
                if (formQuestion !== '') {
                    questions.push({
                        question: groups[groupIndex].questions[i].question,
                        answer: groups[groupIndex].questions[i].answers.find(a => a._id === formQuestion).option,
                        impact: groups[groupIndex].questions[i].answers.find(a => a._id === formQuestion).impact
                    })
                }
            }

            // Almacenamos las plazas disponibles
            let totalGapsAvailables = 0;
            let firstWithGapsAvailables = -1;

            // Iterar sobre los horarios para calcular las plazas disponibles
            for (let i = 0; i < groups[groupIndex].horaries.length; i++) {
                const gapsAvailables = groups[groupIndex].horaries[i].gaps - groups[groupIndex].horaries[i].occupiedGaps;
                totalGapsAvailables += gapsAvailables;

                // Si aún no se ha encontrado un horario con plazas disponibles, asignarlo
                if (firstWithGapsAvailables === -1 && gapsAvailables > 0) {
                    firstWithGapsAvailables = i;
                }
            }

            // ¿Horario seleccionable?
            if(groups[groupIndex].assignableHorary){
                // ¿Hay plazas en la seleccionada?
                const horaryIdSelected = groupOptionsForm[groupIndex].horarySelected
                const selectedHorary = groups[groupIndex].horaries.find(horary => horary._id === horaryIdSelected)
                if (selectedHorary && (selectedHorary.gaps > selectedHorary.occupiedGaps)) {
                    dispatch(addCartItem({
                        activity: activity,
                        client: clientSelected,
                        paymentClient: clientauth?.client,
                        group: groups[groupIndex],
                        horary: groupOptionsForm[groupIndex].horarySelected,
                        questions: questions,
                        commission: commission.activities,
                        club: club
                    }))
                }else{
                    // No hay disponible en este horario, ¿quieres entrar en lista de espera para este horario?
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: true,
                        confirmButtonText: t('global.accept'),
                        denyButtonText: t('global.cancel'),
                        icon: 'warning',
                        text: t('cart.nogapswantwaitinglist')
                    }).then(result => {
                        // ¿Quiere entrar en lista de espera?
                        if (result.isConfirmed){
                            dispatch(addCartItem({
                                activity: activity,
                                client: clientSelected,
                                paymentClient: clientauth?.client,
                                group: groups[groupIndex],
                                horary: groupOptionsForm[groupIndex].horarySelected,
                                questions: questions,
                                commission: commission.activities,
                                club: club,
                                waitinglist: true
                            }))
                        }else{
                            Swal.fire({
                                icon: 'error',
                                text: t('cart.nogapsavailables'),
                                showConfirmButton: true,
                                confirmButtonText: t('global.accept'),
                                timer: 2000
                            })
                        }
                    })
                }
            }else{
                // ¿Hay plazas?
                if(totalGapsAvailables > 0){
                    // Se añade al carrito
                    dispatch(addCartItem({
                        activity: activity,
                        client: clientSelected,
                        paymentClient: clientauth?.client,
                        group: groups[groupIndex],
                        horary: groups[groupIndex].horaries[firstWithGapsAvailables]._id,
                        questions: questions,
                        commission: commission.activities,
                        club: club
                    }))
                }else{
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: true,
                        confirmButtonText: t('global.accept'),
                        denyButtonText: t('global.cancel'),
                        icon: 'warning',
                        text: t('cart.wantwaitinglist')
                    }).then(result => {
                        // ¿Quiere entrar en lista de espera?
                        if (result.isConfirmed) {
                            dispatch(addCartItem({
                                activity: activity,
                                client: clientSelected,
                                paymentClient: clientauth?.client,
                                group: groups[groupIndex],
                                horary: groups[groupIndex].horaries[(groups[groupIndex].horaries?.length-1)]._id,
                                questions: questions,
                                commission: commission.activities,
                                club: club,
                                waitinglist: true
                            }))
                        }else{
                            Swal.fire({
                                icon: 'error',
                                text: t('cart.nogapsavailables'),
                                showConfirmButton: true,
                                confirmButtonText: t('global.accept'),
                                timer: 2000
                            })
                        }
                    })
                }
            }
        } else {
            Swal.fire({
                icon: 'error',
                text: t('cart.noHorariesAvailable'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        }
    }

    const calculatePrice = (group, formGroup) => {
        // Si no hay preguntas o no se han respondido las preguntas
        if (formGroup?.questions && (formGroup.questions.length === 0 || formGroup?.questions?.every(question => question.trim() === ""))) {
            let priceToPay = parseFloat(group?.registrationPayment?.price || 0) + parseFloat(group.seasonPayments.reduce((acumulator, seasonPayDate) => {
                const currentDate = new Date()
                const endDate = new Date(seasonPayDate.endDate)

                if (currentDate < endDate) {
                    return parseFloat(acumulator) + (parseFloat(seasonPayDate.price) || 0)
                } else {
                    return parseFloat(acumulator)
                }
            }, 0))
            let priceComission = commission?.activities?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);

            if (priceToPay <= 0) {
                return 0.00
            } else {
                return (Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100).toFixed(2)
            }

        } else {
            let registrationPayment = 0
            if (group.registrationPayment?.maxdate && new Date(group.registrationPayment?.maxdate) >= new Date()) {
                registrationPayment = parseFloat(group.registrationPayment.price)
            }
            const registrationImpact = (formGroup?.questions || [])
            .filter(question => question.trim() !== "")
            .reduce((sum, question) => {
                const selectedAnswer = group?.questions.find(q => !!q.answers?.find(a => a._id === question))?.answers?.find(a => a._id === question)
                const registrationPercentageTotal = (parseFloat(registrationPayment) * ((parseFloat(selectedAnswer?.impact?.registrationPercentage || 0)) / 100))

                return parseFloat(sum) + (parseFloat(registrationPercentageTotal)) + (selectedAnswer?.impact?.registrationQuantity || 0);
            }, 0)

            const seasonPaymentsTotal = group.seasonPayments.reduce((seasonPaymentTotal, seasonPayment) => {
                if (new Date(seasonPayment.endDate) > new Date()) {
                    const seasonPaymentImpact = (formGroup?.questions || [])
                    .filter(question => question.trim() !== "")
                    .reduce((questionTotal, question) => {
                        const selectedAnswer = group?.questions.find(q => !!q.answers?.find(a => a._id === question))?.answers?.find(a => a._id === question)
                        const seasonPaymentPercentageTotal = (parseFloat(seasonPayment.price) * ((parseFloat(selectedAnswer?.impact?.seasonPaymentPercentage || 0)) / 100))
        
                        return parseFloat(questionTotal) + (parseFloat(seasonPaymentPercentageTotal)) + (selectedAnswer?.impact?.seasonPaymentQuantity || 0);
                    }, 0)
                    return parseFloat(seasonPaymentTotal) + parseFloat(seasonPaymentImpact) + parseFloat(seasonPayment.price)
                } else {
                    return seasonPaymentTotal
                }
            }, 0)

            const priceToPay = parseFloat(registrationPayment) + parseFloat(registrationImpact) + parseFloat(seasonPaymentsTotal)

            const priceComission = commission?.activities?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);

            if (priceToPay <= 0) {
                return 0.00
            } else {
                return (Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100).toFixed(2)
            }
        }
    }

    const formatSchedule = (schedule) => {
        let scheduleFormatted = []
        if (schedule) {
            for (const section of schedule) {
                if (section.startHour && section.endHour) {
                    for (const day of section.weekDays) {
                        if (day !== 'all') {
                            if (scheduleFormatted[day]) {
                                scheduleFormatted.find(d => d.day === day).hours.push({startHour: section.startHour, endHour: section.endHour})
                            } else {
                                scheduleFormatted.push({day: day, hours: [{startHour: section.startHour, endHour: section.endHour}]})
                            }
                        }
                    }
                }
            }
        }
        return scheduleFormatted
    }

    useEffect(() => {
        dispatch(getUser(clientauth?.id))
        dispatch(getClient(clientauth?.client))
    }, [clientauth, dispatch])

    useEffect(() => {
        if (groups) {            
            setGroupOptionsForm(groups.map(group => {
                return {
                    horarySelected: group.horaries[0]?._id,
                    questions: group.questions.map(question => {
                        if (question.required) {
                            return question.answers[0]?._id
                        } else {
                            return ''
                        }
                    })
                }
            }))
        }
    }, [groups])

    useEffect(() => {
        if (status === 'addSuccess') {
            Swal.fire({
                icon: 'success',
                text: t('cart.' + status),
                showConfirmButton: false,
                timer: 1200
            })
        }
    }, [status, dispatch, t])

    return (
        <>
            {
                loading || clientLoading
                    ?
                        <Loader/>
                    :
                        <div>
                            <Form onSubmit={handleSubmit}>
                                <Card className='border-0 my-4'>
                                    <Card.Body>
                                        <h2>{t('activities.group')}</h2>
                                        <hr className="mb-4"/>
                                        <p>
                                            {t("activities.groupText1")}{club.name}{t("activities.groupText2")}
                                        </p>
                                        <p>
                                            {t("activities.groupText3")}<span className='fw-bold'>{club.phone}</span>.
                                        </p>
                                        {
                                            !clientauth || clientauth?.usertype === 'club'
                                                ?
                                                    clientauth?.usertype === 'club' ?
                                                        <div className='contenedor-vacio text-center py-4'>
                                                            <h4 className='fw-bold'>{t('marketplace.registerOrLogIn')}</h4>
                                                            {t('marketplace.activity.needAccount')}
                                                        </div>
                                                    :
                                                        <div className='contenedor-vacio text-center py-4'>
                                                            <h4 className='fw-bold'>{t('marketplace.registerOrLogIn')}</h4>
                                                            {t('marketplace.activity.needAccount')}
                                                            <div className='mt-3'>
                                                                <Link to={`/${t("url.auth.registerclient")}`} className='btn btn-primary m-3'>
                                                                    {t('signin.register')}
                                                                    <img alt={t("signin.register")} src='/images/register.svg' className='ms-2'/>
                                                                </Link>
                                                                <Link to={`/${t("url.auth.login")}`} className='btn btn-secondary m-3'>
                                                                    {t('signin.login')}
                                                                    <img alt={t("signin.login")} src='/images/login.svg' className='ms-2'/>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                :
                                                    <>
                                                        <div className='mt-4'>
                                                            <Row>
                                                                <Col>
                                                                    {/* <Form.Group> */}
                                                                        {/* <Form.Label>{t('marketplace.activity.member')}</Form.Label> */}
                                                                        <MemberSelect members={user?.members && client ? [...user?.members, client] : null} value={clientSelected} onChange={handleClientSelect}/>
                                                                    {/* </Form.Group> */}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {
                                                            clientSelected &&
                                                                <div className='mt-4'>
                                                                    <h6 className='fw-bold'>{t('marketplace.activity.enrolledClientData')}:</h6>
                                                                    <hr className="mb-1"/>
                                                                    <Row className='rounded mt-4 pt-3' style={{backgroundColor: "var(--grisclaro)"}}>
                                                                        <Col xs={12} lg={6} className="my-2">
                                                                            <div className='d-flex justify-content-between'>
                                                                                <span className='fw-bold'>{t("users.firstname")}</span>
                                                                                <span>{clientSelected.firstname}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </Col>
                                                                        <Col xs={12} lg={6} className="my-2">
                                                                            <div className='d-flex justify-content-between'>
                                                                                <span className='fw-bold'>{t("users.lastname")}</span>
                                                                                <span>{clientSelected.lastname}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </Col>
                                                                        <Col xs={12} lg={6} className="my-2">
                                                                            <div className='d-flex justify-content-between'>
                                                                                <span className='fw-bold'>{t("users.birthdate")}</span>
                                                                                <span>{clientSelected.birthdate ? `${clientSelected.birthdate.getDate() > 9 ? clientSelected.birthdate.getDate() : "0" + clientSelected.birthdate.getDate()}/${clientSelected.birthdate.getMonth() > 8 ? parseInt(clientSelected.birthdate.getMonth()) + 1 : "0" + (parseInt(clientSelected.birthdate.getMonth()) + 1)}/${clientSelected.birthdate.getFullYear() > 9 ? clientSelected.birthdate.getFullYear() : "0" + clientSelected.birthdate.getFullYear()}` : '-'}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </Col>
                                                                        <Col xs={12} lg={6} className="my-2">
                                                                            <div className='d-flex justify-content-between'>
                                                                                <span className='fw-bold'>{t("users.gender")}</span>
                                                                                <span>{clientSelected.gender ? t("users.gendertype." + clientSelected.gender) : '-'}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </Col>
                                                                        {
                                                                            clientSelected.tutor1Name &&
                                                                            <>
                                                                                <Col xs={12} lg={6} className="my-2">
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fw-bold'>{t("users.tutor1Name")}</span>
                                                                                        <span>{clientSelected.tutor1Name}</span>
                                                                                    </div>
                                                                                    <hr className='border my-1'/>
                                                                                </Col>
                                                                                <Col xs={12} lg={6} className="my-2">
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fw-bold'>{t("users.tutor1Phone")}</span>
                                                                                        <span>{clientSelected.tutor1Phone}</span>
                                                                                    </div>
                                                                                    <hr className='border my-1'/>
                                                                                </Col>
                                                                                <Col xs={12} lg={6} className="my-2">
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fw-bold'>{t("users.tutor1Email")}</span>
                                                                                        <span>{clientSelected.tutor1Email}</span>
                                                                                    </div>
                                                                                    <hr className='border my-1'/>
                                                                                </Col>
                                                                            </>
                                                                        }
                                                                        {
                                                                            clientSelected.tutor2Name &&
                                                                            <>
                                                                                <Col xs={12} lg={6} className="my-2">
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fw-bold'>{t("users.tutor2Name")}</span>
                                                                                        <span>{clientSelected.tutor2Name}</span>
                                                                                    </div>
                                                                                    <hr className='border my-1'/>
                                                                                </Col>
                                                                                <Col xs={12} lg={6} className="my-2">
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fw-bold'>{t("users.tutor2Phone")}</span>
                                                                                        <span>{clientSelected.tutor2Phone}</span>
                                                                                    </div>
                                                                                    <hr className='border my-1'/>
                                                                                </Col>
                                                                                <Col xs={12} lg={6} className="my-2">
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fw-bold'>{t("users.tutor2Email")}</span>
                                                                                        <span>{clientSelected.tutor2Email}</span>
                                                                                    </div>
                                                                                    <hr className='border my-1'/>
                                                                                </Col>
                                                                            </>
                                                                        }
                                                                    </Row>
                                                                </div>
                                                        }
                                                    </>
                                        }
                                    </Card.Body>
                                </Card>
                                {
                                    groupsLoading &&
                                        <Loader/>
                                }
                                {
                                    clientSelected && groups?.map((group, index) => (
                                        <Card key={index} className='border-0 my-3'>
                                            <Card.Body>
                                                <Row className='mb-5'>
                                                    <Col xs={8}>
                                                        <h2>{group.title}</h2>
                                                        <hr className="mb-4"/>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <div className='border rounded group-price text-center py-3 fw-bold'>{calculatePrice(group, groupOptionsForm[index])}€</div>
                                                    </Col>
                                                </Row>
                                                <div className='mt-3'>
                                                    <div className='d-flex justify-content-between align-items-end'>
                                                        <span className='fw-bold'>{t("marketplace.activity.category")}</span>
                                                        <span>{group.category.title}</span>
                                                    </div>
                                                    <hr className='border my-1'/>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='d-flex justify-content-between align-items-end'>
                                                        <span className='fw-bold'>{t("marketplace.activity.federated")}</span>
                                                        <span>{group.federated ? t('global.yes') : t('global.no')}</span>
                                                    </div>
                                                    <hr className='border my-1'/>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='d-flex justify-content-between align-items-end'>
                                                        <span className='fw-bold'>{t("marketplace.schedule")}</span>
                                                        <span>
                                                            <img className='show-schedule' src='/images/eyeopen.svg' alt={t("marketplace.schedule")} onClick={() => setGroupScheduleModal(`${index}`)}/>
                                                        </span>
                                                    </div>
                                                    <hr className='border my-1'/>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='d-flex justify-content-between align-items-end'>
                                                        <span className='fw-bold'>{t("marketplace.activity.age")}</span>
                                                        <span>{group.category.ageFrom} - {group.category.ageTo} {t('marketplace.activity.yearsOld')}</span>
                                                    </div>
                                                    <hr className='border my-1'/>
                                                </div>
                                                {
                                                    group.assignableHorary && group.horaries?.length > 0 &&
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("marketplace.activity.horary")}</span>
                                                                <FormSelect className='group-select' value={groupOptionsForm[index]?.horarySelected} onChange={({target}) => handleHoraryChange(target, index)}>
                                                                    {group.horaries?.map((horary, index) => (
                                                                        <option key={index} value={horary._id}>{horary.name}</option>
                                                                    ))}
                                                                </FormSelect>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                }
                                                {
                                                    group.questions?.map((question, questionIndex) => (
                                                        <div className='mt-3' key={questionIndex}>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{question.question}</span>
                                                                <FormSelect className='group-select' required={question.required} value={groupOptionsForm[index]?.questions[questionIndex] || ''} onChange={({target}) => handleQuestionChange(target, index, questionIndex)}>
                                                                    {
                                                                        !question.required &&
                                                                            <option value=''></option>
                                                                    }
                                                                    {question.answers?.map((answer, index) => (
                                                                        <option key={index} value={answer._id}>{answer.option}</option>
                                                                    ))}
                                                                </FormSelect>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                    ))
                                                }
                                                <div className='d-grid mt-5'>
                                                    <Button role='button' variant='primary' className='d-flex justify-content-center align-items-center' onClick={() => {handleGroupSelection(index)}}>{t('marketplace.activity.addGroup')} <img alt='' src='/images/rignt-arrow.svg' className='ms-3'/></Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                            </Form>
                        </div>
            }
            {
                groups &&
                    <Modal show={groupScheduleModal ? true : false} onHide={() => setGroupScheduleModal(null)} size='lg' centered>
                        <Modal.Header closeButton className='p-4'>
                            <Modal.Title>
                                { groups[groupScheduleModal]?.title }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='p-5'>
                            {
                                groups[groupScheduleModal]?.assignableHorary
                                    ?
                                        <div>
                                            {
                                                formatSchedule(groups[groupScheduleModal]?.horaries?.find(g => g._id === groupOptionsForm[groupScheduleModal]?.horarySelected)?.schedule)?.map((day, index) => (
                                                    <div key={index} className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span className='fw-bold'>{t('schedule.' + day.day)}</span>
                                                            <div>
                                                                {
                                                                    day.hours.map((hour, hourIndex) => (
                                                                        <div key={hourIndex}>
                                                                            { `${hour.startHour} - ${hour.endHour}` }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    :
                                        <div>{t('global.undefined')}</div>
                            }
                        </Modal.Body>
                    </Modal>
            }
        </>
    )
}