import { Button, Col, Form, Row, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import Select from "react-select"
import "rc-slider/assets/index.css"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import { Fragment, useEffect, useState } from "react"
import SimpleSchedule from "../../design/Schedule/SimpleSchedule"
import Loader from "../../design/Loader/Loader"

export default function GroupForm({state, club, activities, categories, commission, statetype, usertype, isAddingOrEditing, submit, inputChange, selectChange, inputRegistrationChange, checkboxChange, daterangeChange}) {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const openModal = () => setShow(true)

    const [activitiesOptions, setActivitiesOptions] = useState()
    const [activitySelected, setActivitySelected] = useState(null)
    const [categoriesOptions, setCategoriesOptions] = useState()
    const [categorySelected, setCategorySelected] = useState(null)

    const [registrationPaymentAux, setRegistrationPayAux] = useState(false)
    const [seasonPaymentsAux, setSeasonPaymentsAux] = useState(false)
    const [seasonPayDaysQuantity, setSeasonPayDaysQuantity] = useState(0)
    const [seasonPayDays, setSeasonPayDays] = useState([])
    const [initialized, setInitialized] = useState(false)

    const [minToPay, setMinToPay] = useState(0)
    const [maxToPay, setMaxToPay] = useState(0)

    const [questions, setQuestions] = useState([])
    const [newQuestion, setNewQuestion] = useState({
        question: '',
        answers: [{
            option: '',
            impact: {
                registrationQuantity: 0,
                registrationPercentage: 0,
                seasonPaymentQuantity: 0,
                seasonPaymentPercentage: 0
            }
        }],
        required: false
    })

    useEffect(() => {
        if (state && !initialized) {
            if(state?.seasonPayments?.length > 0){
                setSeasonPayDaysQuantity(state?.seasonPayments?.length)
                setSeasonPayDays(state?.seasonPayments)
                setSeasonPaymentsAux(true)
            }

            if(state?.registrationPayment?.price !== 0){
                setRegistrationPayAux(true)
            }

            setQuestions(state?.questions)

            setInitialized(true)
        }
    // eslint-disable-next-line
    }, [state, initialized])

    useEffect(() => {
        setActivitiesOptions(activities?.map(activity => {
            return {label: activity.title, value: activity._id}
        }))
        setActivitySelected(activities?.map(activity => {
            return activity._id === state?.activity ? {label: activity.title, value: activity._id} : null
        }))
    }, [activities, state?.activity])

    useEffect(() => {
        setCategoriesOptions(categories?.map(category => {
            return {label: category.title, value: category._id}
        }))
        setCategorySelected(categories?.map(category => {
            return category._id === state?.category ? {label: category.title, value: category._id} : null
        }))
    }, [categories, state?.category])

    useEffect(() => {
        updateMinAndMaxToPay()
    // eslint-disable-next-line
    }, [state?.registrationPayment?.price, state?.questions, seasonPayDays, commission])

    const handleRegistrationPayAux = ({target}) => {
        setRegistrationPayAux(target.checked)
        inputChange({target: {name: 'registrationPayment', value: target.checked}})
    }
    const handleSeasonPaymentsAux = ({target}) => {
        const isChecked = target.checked
        setSeasonPaymentsAux(isChecked)
        if(!isChecked){
            setSeasonPayDaysQuantity(0)
            setSeasonPayDays([])
            inputChange({target: {name: 'seasonPayments', value: []}})
        }
    }

    const handleSeasonPayDaysQuantity = ({target}) => {
        setSeasonPayDaysQuantity(target.value)

        if(target.value > seasonPayDays.length){
            const newDays = [...seasonPayDays]
            for(let i = seasonPayDays.length; i < target.value; i++){
                newDays.push({ startDate: '', endDate: '', price: 0 })
            }
            setSeasonPayDays(newDays)
        } else {
            setSeasonPayDays(seasonPayDays.slice(0, target.value))
        }
    }

    const handleSeasonDayChange = (e, index, toChange) => {
        const newSeasonPayDays = [...seasonPayDays]
        if (!newSeasonPayDays[index]) {
            newSeasonPayDays[index] = {}
        }

        if (toChange === 'dates') {
            newSeasonPayDays[index].startDate = e ? e[0] : null
            newSeasonPayDays[index].endDate = e ? e[1] : null
        }else{
            const value = e.target.value
            newSeasonPayDays[index][toChange] = value
        }

        setSeasonPayDays(newSeasonPayDays)
        inputChange({target: {name: 'seasonPayments', value: newSeasonPayDays}})
    }

    const handleInputNewQuestion = ({target}) => {
        setNewQuestion({ ...newQuestion, [target.name]: target.value })
    }

    const handleInputAnswer = (index, {target}) => {
        const updatedAnswers = newQuestion.answers.map((answer, i) =>
            i === index ? { ...answer, [target.name]: target.value } : answer
        )
        setNewQuestion({ ...newQuestion, answers: updatedAnswers })
    }

    const handleInputImpact = (index, {target}) => {
        const updatedAnswers = newQuestion.answers.map((answer, i) =>
            i === index ? { ...answer, impact: {...answer.impact, [target.name]: target.value} } : answer
        )
        setNewQuestion({ ...newQuestion, answers: updatedAnswers })
    }

    const handleCheckboxNewQuestion = (e) => {
        setNewQuestion({ ...newQuestion, required: e.target.checked })
    }

    const addAnswer = () => {
        setNewQuestion({
            ...newQuestion, 
            answers: [
                ...newQuestion.answers,
                {
                    option: '',
                    impact: {
                        registrationQuantity: 0,
                        registrationPercentage: 0,
                        seasonPaymentQuantity: 0,
                        seasonPaymentPercentage: 0
                    }
                }
            ]
        })
    }

    const removeAnswer = (index) => {
        const updatedAnswers = newQuestion.answers.filter((_, i) => i !== index)
        setNewQuestion({ ...newQuestion, answers: updatedAnswers })
    }

    const handleAddQuestion = () => {
        setQuestions([...questions, newQuestion])
        setNewQuestion({
            question: '',
            answers: [{
                option: '',
                impact: {
                    registrationQuantity: 0,
                    registrationPercentage: 0,
                    seasonPaymentQuantity: 0,
                    seasonPaymentPercentage: 0
                }
            }],
            required: false
        })
        inputChange({target: {name: 'questions', value: [...questions, newQuestion]}})
        handleClose()
    }

    const handleRemoveQuestion = (questionIndex) => {
        const updatedQuestions = questions.filter((_, index) => index !== questionIndex)
        setQuestions(updatedQuestions)
        inputChange({ target: { name: 'questions', value: updatedQuestions } })
    }

    const handlePriceChange = (e, index) => {
        let inputValue = e.target.value;

        if (/^\d*\.?\d*$/.test(inputValue)) {
            if (seasonPayDays[index]?.price === 0 && inputValue !== '0') {
                inputValue = inputValue.replace(/^0+/, '')
            }

            handleSeasonDayChange({ target: { value: inputValue } }, index, 'price');

            const parsedValue = parseFloat(inputValue);
            if (!isNaN(parsedValue)) {
                const { price, priceClient } = calculatePayment(parsedValue);
                inputChange({ target: { name: `seasonPayments[${index}].price`, value: price } });
                inputChange({ target: { name: `seasonPayments[${index}].priceClient`, value: priceClient } });
            }
        }
    }

    const calculatePayment = (price) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }

        if (!isNaN(price)) {
            const activity = commission?.activities?.find(activity => price >= activity.lowPrice && price <= activity.highPrice);
            if (activity) {
                return {
                    price: price,
                    priceClient: price > 0 ? (Math.ceil(((price * (activity.percentage || 0) / 100) + (activity.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
                }
            } else {
                return {
                    price: price,
                    priceClient: '0.00'
                }
            }
        } else {
            return {
                price: '0.00',
                priceClient: '0.00'
            }
        }
    }

    const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

    const updateMinAndMaxToPay = () => {
        // Obtiene la cantidad a pagar del registro junto a su comisión
        const initialRegistrationPriceMin = roundToTwoDecimals(parseFloat(state?.registrationPayment?.price || 0));
        const initialRegistrationPriceMax = roundToTwoDecimals(parseFloat(state?.registrationPayment?.price || 0));

        let registrationPriceMin = initialRegistrationPriceMin;
        let registrationPriceMax = initialRegistrationPriceMax;

        // Si hubiera preguntas que impactan en el precio de la matrícula
        if (state?.questions?.length > 0) {
            state.questions.forEach((question) => {
                // Encuentra la respuesta que minimice el precio para preguntas obligatorias
                if (question.required) {
                    let minImpactAnswer = question.answers.reduce((minImpact, answer) => {
                        const percentage = parseFloat(answer.impact.registrationPercentage);
                        const quantity = parseFloat(answer.impact.registrationQuantity);
                        const impactValue = initialRegistrationPriceMin * (percentage / 100) + quantity;

                        if (impactValue < minImpact.value) {
                        return { value: impactValue, answer };
                        }
                        return minImpact;
                    }, { value: Infinity, answer: null });

                    if (minImpactAnswer.answer) {
                        // Actualiza el registrationPriceMin sumando el menor impacto basado en el precio inicial
                        registrationPriceMin = initialRegistrationPriceMin + minImpactAnswer.value;
                    }
                }

                // Encuentra la respuesta que maximice el precio para todas las preguntas (registrationPriceMax)
                let maxImpactAnswer = question.answers.reduce((maxImpact, answer) => {
                    const percentage = parseFloat(answer.impact.registrationPercentage);
                    const quantity = parseFloat(answer.impact.registrationQuantity);
                    const impactValue = initialRegistrationPriceMax * (percentage / 100) + quantity;

                    if (impactValue > maxImpact.value) {
                    return { value: impactValue, answer };
                    }
                    return maxImpact;
                }, { value: -Infinity, answer: null });

                if (maxImpactAnswer.answer) {
                    // Suma el mayor impacto basado en el precio inicial para el registrationPriceMax
                    registrationPriceMax += maxImpactAnswer.value;
                }
            })
        }

        // Obtiene el precio mínimo de pago inicial junto a su comisión a pagar
        const registrationCommissionMin = commission?.activities?.find(activity => registrationPriceMin >= activity.lowPrice && registrationPriceMin <= activity.highPrice)
        const commissionAmountMin = (registrationPriceMin * (registrationCommissionMin?.percentage || 0) / 100) + (registrationCommissionMin?.double || 0)
        const roundedCommissionMin = Math.ceil(commissionAmountMin * 100) / 100
        const registrationTotalMin = parseFloat((registrationPriceMin + roundedCommissionMin).toFixed(2))

        // Obtiene el precio máximo de pago inicial junto a su comisión a pagar
        const registrationCommissionMax = commission?.activities?.find(activity => registrationPriceMax >= activity.lowPrice && registrationPriceMax <= activity.highPrice)
        const commissionAmountMax = (registrationPriceMax * (registrationCommissionMax?.percentage || 0) / 100) + (registrationCommissionMax?.double || 0)
        const roundedCommissionMax = Math.ceil(commissionAmountMax * 100) / 100
        const registrationTotalMax = parseFloat((registrationPriceMax + roundedCommissionMax).toFixed(2))

        // Obtiene la cantidad a pagar de cada cuota junto a su comisión
        let totalMinPayments = 0;
        let totalMaxPayments = 0;
        let totalMinPaymentsTax = 0;
        let totalMaxPaymentsTax = 0;

        seasonPayDays.forEach((seasonPayDate) => {
            const initialSeasonPrice = roundToTwoDecimals(parseFloat(seasonPayDate.price) || 0);
            let seasonPriceMin = initialSeasonPrice;
            let seasonPriceMax = initialSeasonPrice;

            if (state?.questions?.length > 0) {
                state.questions.forEach((question) => {
                    // Aplicar los impactos de seasonPaymentPercentage y seasonPaymentQuantity para el mínimo (solo preguntas obligatorias)
                    if (question.required) {
                        let minSeasonImpactAnswer = question.answers.reduce((minImpact, answer) => {
                            const seasonPercentage = parseFloat(answer.impact.seasonPaymentPercentage);
                            const seasonQuantity = parseFloat(answer.impact.seasonPaymentQuantity);
                            const seasonImpactValue = seasonPayDate.price * (seasonPercentage / 100) + seasonQuantity;

                            if (seasonImpactValue < minImpact.value) {
                                return { value: seasonImpactValue, answer };
                            }
                            return minImpact;
                        }, { value: Infinity, answer: null });

                        if (minSeasonImpactAnswer.answer) {
                            // Sumar el menor impacto al seasonPriceMin
                            seasonPriceMin += minSeasonImpactAnswer.value;
                        }
                    }

                    // Para el seasonPriceMax, considerar todas las preguntas (obligatorias y no obligatorias)
                    let maxSeasonImpactAnswer = question.answers.reduce((maxImpact, answer) => {
                        const seasonPercentage = parseFloat(answer.impact.seasonPaymentPercentage);
                        const seasonQuantity = parseFloat(answer.impact.seasonPaymentQuantity);
                        const seasonImpactValue = seasonPayDate.price * (seasonPercentage / 100) + seasonQuantity;

                        if (seasonImpactValue > maxImpact.value) {
                            return { value: seasonImpactValue, answer };
                        }
                        return maxImpact;
                    }, { value: -Infinity, answer: null });

                    if (maxSeasonImpactAnswer.answer) {
                        // Sumar el mayor impacto al seasonPriceMax
                        seasonPriceMax += maxSeasonImpactAnswer.value;
                    }
                })
            }

            totalMinPayments += seasonPriceMin
            totalMaxPayments += seasonPriceMax

            // Calcular la comisión sobre el seasonPriceMin
            const seasonCommissionMin = commission?.activities?.find(activity => seasonPriceMin >= activity.lowPrice && seasonPriceMin <= activity.highPrice)
            const seasoncommissionAmountMin = (seasonPriceMin * (seasonCommissionMin?.percentage || 0) / 100) + (seasonCommissionMin?.double || 0);
            const seasonRoundedCommissionMin = Math.ceil(seasoncommissionAmountMin * 100) / 100;

            // Calcular la comisión sobre el seasonPriceMax
            const seasonCommissionMax = commission?.activities?.find(activity => seasonPriceMax >= activity.lowPrice && seasonPriceMax <= activity.highPrice)
            const seasoncommissionAmountMax = (seasonPriceMax * (seasonCommissionMax?.percentage || 0) / 100) + (seasonCommissionMax?.double || 0);
            const seasonRoundedCommissionMax = Math.ceil(seasoncommissionAmountMax * 100) / 100;

            // Calcular el total mínimo (precio mínimo + comisión)
            const seasonTotalMin = parseFloat((seasonPriceMin + seasonRoundedCommissionMin).toFixed(2));
            totalMinPaymentsTax += seasonTotalMin;

            // Calcular el total máximo (precio máximo + comisión)
            const seasonTotalMax = parseFloat((seasonPriceMax + seasonRoundedCommissionMax).toFixed(2));
            totalMaxPaymentsTax += seasonTotalMax;
        })

        setMaxToPay(parseFloat((registrationTotalMax+totalMaxPaymentsTax).toFixed(2)))
        setMinToPay(parseFloat((registrationTotalMin+totalMinPaymentsTax).toFixed(2)))
    }

    /* ---------------------      HORARIOS      ---------------------- */
    const [showHoraries, setShowHoraries] = useState(false)
    const [horaryFormType, setHoraryFormType] = useState('add')
    const [editHoraryIndex, setEditHoraryIndex] = useState(null)
    const openModalHoraries = () => {
        setHoraryFormType('add')
        setEditHoraryIndex(null)
        setHoraryForm({
            name: '',
            gaps: 0,
            trainer: '',
            schedule: [],
            active: true
        })
        setShowHoraries(true)
    }

    const handleHorarieselect = (index) => {
        setHoraryFormType('edit')
        setHoraryForm(state?.horaries[index])
        setEditHoraryIndex(index)
        setShowHoraries(true)
    }

    const handleCloseHoraries = () => setShowHoraries(false)
    const [horaryForm, setHoraryForm] = useState(null)

    const addSchedule = () => {
        setHoraryForm({
            ...horaryForm,
            schedule: [...horaryForm?.schedule, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startDate: null, endDate: null, startHour: null, endHour: null, price: null}]
        })
    }

    const removeSchedule = (day, index) => {
        setHoraryForm({
            ...horaryForm,
            schedule: horaryForm.schedule.filter((scheduleDay, scheduleIndex) => scheduleIndex !== index),
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setHoraryForm({
            ...horaryForm,
            schedule: horaryForm.schedule.map(scheduleDay => {
                if (scheduleDay.weekDays === day) {
                    return {
                        ...scheduleDay,
                        [target.name]: target.value
                    }
                } else {
                    return scheduleDay
                }
            })
        })
    }

    const handleDayCheck = (target, position) => {
        setHoraryForm({
            ...horaryForm,
            schedule: horaryForm.schedule.map((day, index) => {
                if(index === position){
                    if(target.checked){
                        if(target.name !== 'all'){
                            return {...day, weekDays: [...day.weekDays, target.name]}
                        }else{
                            return {...day, weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
                        }
                    }else{
                        if(target.name !== 'all'){
                            return {...day, weekDays: day.weekDays.filter(dayFilter => (dayFilter !== target.name && dayFilter !== "all"))}
                        }else{
                            return {...day, weekDays: []}
                        }
                    }
                }else{
                    return day
                }
            })
        })
    }

    const addHorary = () => {
        inputChange({target: {name: 'horaries', value: [...state?.horaries, horaryForm]}})
        setShowHoraries(false)

        setHoraryForm({
            name: '',
            gaps: 0,
            trainer: '',
            schedule: [],
            active: true
        })
    }

    const editHorary = (index) => {
        const newHoraries = [...state.horaries]
        newHoraries[index] = horaryForm
        inputChange({target: {name: 'horaries', value: newHoraries}})
        setShowHoraries(false)
    }

    const handleRemoveHorary = (index) => {
        const newHoraries = state.horaries.filter((_, number) => number !== index)
        inputChange({target: {name: 'horaries', value: newHoraries}})
    }

    const inputChangeHorary = ({target}) => {
        setHoraryForm({
            ...horaryForm,
            [target.name]: target.value
        })
    }

    const checkboxChangeHorary = ({target}) => {
        setHoraryForm({
            ...horaryForm,
            [target.name]: target.checked
        })
    }

    // ORDENO LOS HORARIOS PARA MOSTRARLOS EN LA LISTA
    const weekDayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const prepareSchedules = (schedules) => {
        const sortedSchedules = [];

        schedules.forEach((schedule) => {
            schedule.schedule.forEach((bloque) => {
                bloque.weekDays.forEach((day) => {
                    sortedSchedules.push({
                        day,
                        startHour: bloque.startHour,
                        endHour: bloque.endHour,
                        name: schedule.name,
                        trainer: schedule.trainer,
                        scheduleId: schedule?._id
                    });
                });
            });
        });

        sortedSchedules.sort((a, b) => {
            const dayAIndex = weekDayOrder.indexOf(a.day);
            const dayBIndex = weekDayOrder.indexOf(b.day);

            if (dayAIndex !== dayBIndex) {
                return dayAIndex - dayBIndex;
            }

            return a.startHour.localeCompare(b.startHour);
        });

        return sortedSchedules;
    };
    const sortedSchedules = prepareSchedules(state?.horaries || []);

    /* ------------------------------------------------------------- */

    return (
        <>
            <Form onSubmit={submit}>
                <Row>
                    <Col>
                        <h3 className="font-single">{t('groups.data')}</h3>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={8} className="my-3">
                        <Form.Group className='form-group'>
                            <Form.Control 
                                className={state?.title ? 'has-content' : ''}
                                type='text'
                                name='title'
                                value={state?.title ||  ''}
                                onChange={inputChange}
                                required
                            />
                            <Form.Label htmlFor='title'>{t('groups.title')}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={4} className="my-3">
                        <Form.Group className="d-flex">
                            <Form.Label htmlFor='federated'>{t('groups.federated')}</Form.Label>
                            <Form.Check
                                className="ms-3"
                                type="switch"
                                name="federated"
                                onChange={checkboxChange}
                                checked={state?.federated || false}
                            />
                            <span className="ms-3">
                                {state?.federated
                                ?
                                    <>{t('global.yes')}</>
                                :
                                    <>{t('global.no')}</>
                                }
                            </span>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6} className="my-3">
                        <Form.Label htmlFor='activities'>{t('groups.activity')}</Form.Label>
                        <Select
                            className="form-control has-content"
                            name='activities'
                            options={activitiesOptions}
                            value={activitySelected}
                            onChange={(e) => selectChange(e, 'activity')}
                            required
                        />
                    </Col>
                    <Col xs={12} lg={6} className="my-3">
                        <Form.Label htmlFor='categories'>{t('groups.category')}</Form.Label>
                        <Select
                            className="form-control has-content"
                            name='categories'
                            options={categoriesOptions}
                            value={categorySelected}
                            onChange={(e) => selectChange(e, 'category')}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={3} className="my-3">
                        <Form.Group>
                            <Form.Label htmlFor='active'>
                                {t('groups.active')}
                                <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('groups.activeinfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                                    </OverlayTrigger>
                            </Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    className="ms-3"
                                    type="switch"
                                    name="active"
                                    onChange={checkboxChange}
                                    checked={state?.active || false}
                                />
                                <span className="ms-3">
                                    {state?.active
                                    ?
                                        <>{t('global.yes')}</>
                                    :
                                        <>{t('global.no')}</>
                                    }
                                </span>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={3} className="my-3">
                        <Form.Group>
                            <Form.Label htmlFor='verify'>
                                {t('groups.assignablehorary')}
                                <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('groups.assignablehoraryinfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                                    </OverlayTrigger>
                            </Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    className="ms-3"
                                    type="switch"
                                    name="assignableHorary"
                                    onChange={checkboxChange}
                                    checked={state?.assignableHorary || false}
                                />
                                <span className="ms-3">
                                    {state?.assignableHorary
                                    ?
                                        <>{t('groups.selectableroup')}</>
                                    :
                                        <>{t('groups.unassignablehorary')}</>
                                    }
                                </span>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={3} className="my-3">
                        <Form.Group>
                            <Form.Label htmlFor='verify'>
                                {t('groups.verify')}
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('groups.verifyinfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                                    </OverlayTrigger>
                            </Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    className="ms-3"
                                    type="switch"
                                    name="verify"
                                    onChange={checkboxChange}
                                    checked={state?.verify || false}
                                />
                                <span className="ms-3">
                                    {state?.verify
                                    ?
                                        <>{t('global.yes')}</>
                                    :
                                        <>{t('global.no')}</>
                                    }
                                </span>
                            </div>
                        </Form.Group>
                    </Col>
                    {/* {!state?.verify ?
                        <Col xs={12} lg={3} className="my-3">
                            <Form.Group>
                                <Form.Label htmlFor='postpayverified'>
                                    {t('groups.postpayverified')}
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('groups.postpayverifiedinfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="d-flex">
                                    <Form.Check
                                        className="ms-3"
                                        type="switch"
                                        name="postpayverified"
                                        onChange={checkboxChange}
                                        checked={state?.postpayverified || false}
                                    />
                                    <span className="ms-3">
                                        {state?.postpayverified
                                        ?
                                            <>{t('global.yes')}</>
                                        :
                                            <>{t('global.no')}</>
                                        }
                                    </span>
                                </div>
                            </Form.Group>
                        </Col>
                    :
                        ''
                    } */}
                </Row>
                <Row>
                    <Col xs={12} lg={6} className="my-3">
                        <Form.Label className="me-3">{t('groups.seasonlength')}</Form.Label><br/>
                        <DateRangePicker onChange={(value) => {daterangeChange(value, 'season')}} value={[state?.seasonStart, state?.seasonEnd]}/>
                    </Col>
                    <Col xs={12} lg={6} className="my-3">
                        <Form.Label className="me-3">
                            {t('groups.registrationlength')}
                            <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('groups.registrationlengthinfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                            </OverlayTrigger>
                        </Form.Label><br/>
                        <DateRangePicker onChange={(value) => {daterangeChange(value, 'registration')}} value={[state?.registrationStart, state?.registrationEnd]}/>
                    </Col>
                </Row>
                {/* ---------------------      PAGO INICIAL      ---------------------- */}
                <Row className="mt-4">
                    <Col className="d-flex justify-content-between">
                        <h3 className="font-single">
                            {t('groups.initialpayment')}
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">
                                                {t('groups.initialpaymentinfo')}
                                            </Tooltip>}
                            >
                                <img src="/images/info.svg" alt="Info" className="ms-2" />
                            </OverlayTrigger>
                        </h3>
                        <div className="d-flex">
                            {registrationPaymentAux ? t('global.yes') : t('global.no') }
                            <Form.Check
                                className="ms-3"
                                type="switch"
                                onChange={handleRegistrationPayAux}
                                checked={registrationPaymentAux}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className="fina"/>
                    </Col>
                </Row>
                {registrationPaymentAux ?
                    <Row>
                        <Col xs={12} lg={11} className="mx-auto">
                            <Row className="my-3">
                                <Col xs={6} className="my-3">
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            className='has-content text-right'
                                            type="text"
                                            name='title'
                                            value={state?.registrationPayment?.title || ''}
                                            onChange={inputRegistrationChange}
                                            required
                                        />
                                        <Form.Label htmlFor='title'>{t('groups.registrationpaymenttitle')}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col xs={3} className="my-3">
                                    <div>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className='has-content text-right'
                                                type="number"
                                                step="0.01"
                                                name='price'
                                                value={state?.registrationPayment?.price || 0}
                                                onChange={inputRegistrationChange}
                                                required
                                            />
                                            <Form.Label htmlFor='price'>{t('groups.registrationpaymentprice')}</Form.Label>
                                            <img src="/images/money.svg" alt="Money" className="icono-money" />
                                        </Form.Group>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <small>{t('inscriptions.priceclient')}</small>
                                        {calculatePayment(state?.registrationPayment?.price || 0).priceClient}€
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <small>{t('inscriptions.finalprice')}</small>
                                        <div>{(Number(state?.registrationPayment?.price) + Number(calculatePayment(state?.registrationPayment?.price || 0).priceClient)).toFixed(2)} €</div>
                                    </div>
                                </Col>
                                <Col xs={3} className="my-3">
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            className='has-content'
                                            type='date'
                                            name='maxdate'
                                            value={state?.registrationPayment?.maxdate || ''}
                                            onChange={inputRegistrationChange}
                                            required
                                        />
                                        <Form.Label htmlFor='maxdate'>{t('groups.registrationpaymentdate')}</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    :
                    <></>
                }
                {/* ---------------------      PREGUNTAS      ---------------------- */}
                <Row>
                    <Col xs={12} className="my-3">
                        <div className="d-flex justify-content-between align-items-end">
                            <h3 className="font-single">{t('groups.customquestions')}</h3>
                            <Button variant="default" onClick={() => {openModal()}}>
                                <i className="fas fa-plus" /> {t('groups.addquestion')}
                            </Button>
                        </div>
                        <hr className="fina"/>
                    </Col>
                    <Col xs={12} lg={11} className="mx-auto">
                        {questions?.length > 0 ?
                            <>
                                <Row className='border-bottom border-1 border-dark pb-2'>
                                    <Col xs={4}>{t('groups.questions')}</Col>
                                    <Col xs={5}>
                                        <Row>
                                            <Col xs={6} className="text-center">
                                                {t('groups.answers')}
                                            </Col>
                                            <Col xs={3} className="text-center">
                                                {t('groups.registrationImpact')}
                                            </Col>
                                            <Col xs={3} className="text-center">
                                                {t('groups.seasonPaymentImpact')}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2} className="text-center">{t('groups.required')}</Col>
                                </Row>
                                <div className="preguntas mb-4">
                                {questions.map((question, questionIndex) => (
                                    <Fragment key={questionIndex}>
                                        <Row className="py-3 border-1 border-bottom">
                                            <Col xs={4} className="d-flex align-items-center">
                                               {questionIndex.id} {question.question}
                                            </Col>
                                            <Col xs={5}>
                                                {question.answers.map((answer, answerIndex) => (
                                                    <Fragment key={answerIndex}>
                                                        {answerIndex !== 0 ? <hr className="fina"/> : ''}
                                                        <Row className="align-items-center">
                                                            <Col xs={6} className="text-center">
                                                                {answer.option}
                                                            </Col>
                                                            <Col xs={3} className="text-center">
                                                                {answer.impact.registrationPercentage}/{answer.impact.registrationQuantity}
                                                            </Col>
                                                            <Col xs={3} className="text-center">
                                                                {answer.impact.seasonPaymentPercentage}/{answer.impact.seasonPaymentQuantity}
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                ))}
                                            </Col>
                                            <Col xs={2} className="d-flex align-items-center justify-content-center">
                                                {question.required ? t('global.yes') : t('global.no')}
                                            </Col>
                                            <Col xs={1} className="d-flex align-items-center justify-content-center">
                                                <Link onClick={() => handleRemoveQuestion(questionIndex)}>
                                                    <img src="/images/trash.svg" className="cursor" alt="Trash" />
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ))
                                }
                                </div>
                            </>
                         :
                            <></>
                        }
                    </Col>
                </Row>
                {/* ---------------------      PAGOS RECURRENTES      ---------------------- */}
                <Row className="mt-4">
                    <Col className="d-flex justify-content-between">
                        <h3 className="font-single">
                            {t('groups.seasonpayments')}
                            <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('groups.seasonpaymentsinfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                            </OverlayTrigger>
                        </h3>
                        <div className="d-flex align-items-end">
                            {seasonPaymentsAux
                            ?
                                <>
                                    <Form.Group className="d-flex align-items-end me-4 form-payments">
                                        <Form.Label htmlFor='seasonpaydays'>{t('groups.seasonpaydays')}</Form.Label>
                                        <Form.Control
                                            className='text-center '
                                            type="number"
                                            name='seasonpaydays'
                                            min={0}
                                            value={seasonPayDaysQuantity}
                                            onChange={handleSeasonPayDaysQuantity}
                                            required
                                        />
                                    </Form.Group>
                                    {t('global.yes')}
                                </>
                                :
                                t('global.no')
                            }
                            <Form.Check
                                className="ms-3"
                                type="switch"
                                name="visibility"
                                onChange={handleSeasonPaymentsAux}
                                checked={seasonPaymentsAux}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className="fina"/>
                    </Col>
                </Row>
                {seasonPaymentsAux
                ?
                <Row>
                    <Col xs={12} lg={11} className="mx-auto">
                        <Row className="my-3">
                            <Col>
                                <Row className='border-bottom border-1 border-dark pb-2 mb-3'>
                                    <Col xs={1}>{t('groups.payment')}</Col>
                                    <Col xs={4}>{t('groups.seasonDay')}</Col>
                                    <Col xs={3}>{t('groups.priceclub')}</Col>
                                    <Col xs={2} className="text-center">{t('inscriptions.priceclient')}</Col>
                                    <Col xs={2} className="text-center">{t('inscriptions.finalprice')}</Col>
                                </Row>
                                {seasonPayDaysQuantity > 0 ?
                                    (() => {
                                        const rows = []
                                        for (let index = 0; index < seasonPayDaysQuantity; index++) {
                                            rows.push(
                                                <Row key={index} className="align-items-center">
                                                    <Col xs={1} className="d-flex align-items-center justify-content-center">
                                                        {index+1}
                                                    </Col>
                                                    <Col xs={4}>
                                                        <DateRangePicker onChange={(value) => {handleSeasonDayChange(value, index, 'dates')}} value={[seasonPayDays[index]?.startDate, seasonPayDays[index]?.endDate]}/>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Group className="form-group">
                                                            <Form.Control
                                                                className="has-content"
                                                                type="text"
                                                                step="0.01"
                                                                name='seasonPrice'
                                                                value={seasonPayDays[index]?.price !== undefined ? seasonPayDays[index]?.price : ''}
                                                                onChange={(e) => handlePriceChange(e, index)}
                                                                required
                                                            />
                                                            <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={2} className="text-center">
                                                        {calculatePayment(seasonPayDays[index]?.price).priceClient} €
                                                    </Col>
                                                    <Col xs={2} className="text-center">
                                                        {(Number(seasonPayDays[index]?.price !== undefined ? seasonPayDays[index]?.price : 0) + Number(calculatePayment(seasonPayDays[index]?.price).priceClient)).toFixed(2)} €
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        return rows
                                    })()
                                :
                                    ''
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                :
                <></>
                }
                {/* ---------------------      HORARIOS      ---------------------- */}
                <Row className="my-3">
                    <Col xs={12}>
                        <div className="d-flex justify-content-between align-items-end">
                            <h3 className="font-single">{t('groups.horaries')}</h3>
                            <div className='buttons d-flex justify-content-end'>
                                <Button variant='secondary' onClick={() => openModalHoraries()}>
                                    <i className='fas fa-plus'></i> {t('horaries.createnewhorary')}
                                </Button>
                            </div>
                        </div>
                        <hr className="fina"/>
                    </Col>
                    {state?.horaries?.length > 0 ?
                        <Col xs={12} lg={11} className="mx-auto">
                            <Row className='border-bottom border-1 border-dark pb-2'>
                                <Col xs={3}>{t('horaries.name')}</Col>
                                <Col xs={2} className="text-center">{t('horaries.gaps')}</Col>
                                <Col xs={3} className="text-center">{t('horaries.trainer')}</Col>
                                <Col xs={3} className="text-center">{t('groups.horaries')}</Col>
                                <Col xs={1}></Col>
                            </Row>
                            <div className="striped mb-4">
                                {state?.horaries?.map((horary, index) => (
                                    <Fragment key={index}>
                                        <Row className="py-3 border-1 border-bottom align-items-center">
                                            <Col xs={3}>
                                                {horary.name}
                                            </Col>
                                            <Col xs={2} className="text-center">
                                                {horary.gaps}
                                            </Col>
                                            <Col xs={3}>
                                                {horary.trainer}
                                            </Col>
                                            <Col xs={3} className="font-small text-center">
                                                {sortedSchedules
                                                    .filter(schedule => schedule.scheduleId === horary._id)
                                                    .map((schedule, index) => (
                                                        <div key={index}>
                                                            {t("schedule."+schedule.day)}<br/>
                                                            {`${schedule.startHour} - ${schedule.endHour}`}
                                                        </div>
                                                    ))
                                                }
                                            </Col>
                                            <Col xs={1} className="d-flex justify-content-around">
                                                <Link onClick={() => handleHorarieselect(index)}>
                                                    <img src="/images/edit.svg" alt="edit" width={15}/>
                                                </Link>
                                                {state?.horaries?.length > 1 ?
                                                    <Link onClick={() => handleRemoveHorary(index)}>
                                                        <img src="/images/trash.svg" alt="edit" width={15}/>
                                                    </Link>
                                                    :
                                                    <></>
                                                }
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ))}
                            </div>
                        </Col>
                        :
                            <div className='contenedor-vacio text-center'><h4>{t('horaries.thereisnothoraries')}</h4></div>
                    }
                </Row>

                {/* ---------------------      RESUMEN      ---------------------- */}
                <Row className="my-3">
                    <Col xs={12}>
                        <h3 className="font-single">{t('groups.summary')}</h3>
                        <hr className="fina"/>
                    </Col>
                    <Col xs={6} className="text-center">
                        {t('groups.minimum')}: <h3>{minToPay}€</h3>
                    </Col>
                    <Col xs={6} className="text-center">
                        {t('groups.maximum')}: <h3>{maxToPay}€</h3>
                    </Col>
                </Row>
                {/* ------------------------------------------------------------- */}
                {isAddingOrEditing ?
                    <Loader/>
                :
                    <Row>
                        <Col xs={12} lg={6}>
                            <Link  to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.groups")}` : `/${t("url.club.club")}/${t("url.club.groups")}`} className="btn btn-default mt-3 w-100">
                                {t('global.cancel')}
                            </Link>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Button type="submit" variant="primary" className='mt-3 w-100'>
                            {statetype === 'add'
                            ?    
                                t('global.save')
                            :
                                t('global.edit')
                            }
                            </Button>
                        </Col>
                    </Row>
                }
            </Form>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('groups.customquestions')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo p-5'>
                    <Row className="my-3">
                        <Col xs={8}>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className='has-content'
                                    type='text'
                                    name='question'
                                    value={newQuestion.question}
                                    onChange={handleInputNewQuestion}
                                />
                                <Form.Label htmlFor='question'>{t('groups.question')}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Label htmlFor='required'>{t('groups.required')}</Form.Label>
                                <Form.Check
                                    className="has-content"
                                    type="switch"
                                    name="required"
                                    onChange={handleCheckboxNewQuestion}
                                    checked={newQuestion.required}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="my-3">
                            {newQuestion?.answers?.map((answer, index) => (
                                <Row key={index} className="my-3 align-items-center">
                                    <Col xs={3}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className='has-content'
                                                type='text'
                                                name='option'
                                                value={answer.option}
                                                onChange={(e) => handleInputAnswer(index, e)}
                                            />
                                            <Form.Label htmlFor='option'>{t('groups.answer')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className='has-content'
                                                type="number"
                                                step="0.01"
                                                name='registrationPercentage'
                                                value={answer.impact.registrationPercentage}
                                                onChange={(e) => handleInputImpact(index, e)}
                                            />
                                            <Form.Label htmlFor='registrationPercentage'>{t('groups.registrationPercentage')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className='has-content'
                                                type="number"
                                                step="0.01"
                                                name='registrationQuantity'
                                                value={answer.impact.registrationQuantity}
                                                onChange={(e) => handleInputImpact(index, e)}
                                            />
                                            <Form.Label htmlFor='registrationQuantity'>{t('groups.registrationQuantity')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className='has-content'
                                                type="number"
                                                step="0.01"
                                                name='seasonPaymentPercentage'
                                                value={answer.impact.seasonPaymentPercentage}
                                                onChange={(e) => handleInputImpact(index, e)}
                                            />
                                            <Form.Label htmlFor='seasonPaymentPercentage'>{t('groups.seasonPaymentPercentage')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className='has-content'
                                                type="number"
                                                step="0.01"
                                                name='seasonPaymentQuantity'
                                                value={answer.impact.seasonPaymentQuantity}
                                                onChange={(e) => handleInputImpact(index, e)}
                                            />
                                            <Form.Label htmlFor='seasonPaymentQuantity'>{t('groups.seasonPaymentQuantity')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {newQuestion?.answers?.length >1
                                    ?
                                        <Col xs={1} className="text-center">
                                            <Link onClick={() => removeAnswer(index)} className="link-oscuro text-decoration-none">
                                                <img src="/images/trash.svg" alt="Trash" />
                                            </Link>
                                        </Col>
                                    : <></>
                                    }
                                </Row>
                            ))}
                        </Col>
                        <Col>
                            <Link onClick={addAnswer} className="link-oscuro text-decoration-none">
                                <i className="fas fa-plus" /> {t('groups.addanswer')}
                            </Link>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6} className="ms-auto">
                            <Button variant="primary" className="w-100 mt-2" onClick={handleAddQuestion}>{t('groups.savequestion')}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {/* ---------------------      HORARIOS      ---------------------- */}
            <Modal show={showHoraries} onHide={handleCloseHoraries} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {horaryFormType === 'add' ?
                                <h2>{t('horaries.createnewhorary')}</h2>
                            :
                                <h2>{t('horaries.edithorary')}</h2>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo p-5'>
                    <Row className="my-3">
                        <Col xs={12} lg={6}>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className={horaryForm?.name ? 'has-content' : ''}
                                    type='text'
                                    name='name'
                                    value={horaryForm?.name ||  ''}
                                    onChange={inputChangeHorary}
                                    required
                                />
                                <Form.Label htmlFor='name'>{t('horaries.name')}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className={horaryForm?.trainer ? 'has-content' : ''}
                                    type='text'
                                    name='trainer'
                                    value={horaryForm?.trainer ||  ''}
                                    onChange={inputChangeHorary}
                                />
                                <Form.Label htmlFor='trainer'>{t('horaries.trainer')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label htmlFor='active'>{t('horaries.status')}</Form.Label>
                                <div className='d-flex ms-4'>
                                    <Form.Check
                                        type="switch"
                                        name="active"
                                        onChange={checkboxChangeHorary}
                                        checked={horaryForm?.active || false}
                                    />
                                    <span className="ms-3">
                                        {horaryForm?.active
                                        ?
                                            <>{t('horaries.active')}</>
                                        :
                                            <>{t('horaries.inactive')}</>
                                        }
                                    </span>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='form-group'>
                                <Form.Control 
                                    className={horaryForm?.gaps ? 'has-content' : ''}
                                    type='number'
                                    name='gaps'
                                    value={horaryForm?.gaps ||  ''}
                                    onChange={inputChangeHorary}
                                    required
                                />
                                <Form.Label htmlFor='gaps'>{t('horaries.gaps')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        {horaryForm?.schedule
                        ?
                            <SimpleSchedule title={t('horaries.schedule')} value={horaryForm?.schedule} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange}/>
                        :
                            <SimpleSchedule title={t('horaries.schedule')} value={[]} removeSchedule={removeSchedule} addSchedule={addSchedule} handleDayCheck={handleDayCheck} onScheduleInputChange={handleScheduleInputChange}/>
                        }
                        </Col>
                    </Row>
                    {isAddingOrEditing ?
                        <Loader/>
                    :
                        horaryFormType === 'add' ?
                            <Row>
                                <Col xs={12}>
                                    <Button type="submit" variant="primary" className='mt-3 w-100' onClick={addHorary}>
                                        {t('global.add')}
                                    </Button>
                                </Col>
                            </Row>
                        :
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Button type="submit" variant="primary" className='mt-3 w-100' onClick={() => editHorary(editHoraryIndex)}>
                                        {t('global.edit')}
                                    </Button>
                                </Col>
                            </Row>
                    }
                </Modal.Body>
            </Modal>
            {/* ------------------------------------------------------------- */}
        </>
    )
}