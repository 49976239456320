import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NothingHere () {
    const { t } = useTranslation()

	return (
		<div>
			{t('global.nothinghere')}
		</div>
	)
}