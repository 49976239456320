import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function CheckOutActivity({item}) {
    const { t } = useTranslation()

    const calculateActivityPrice = (item) => {
        let registrationPayment = 0
        if (item.group.registrationPayment?.maxdate && new Date(item.group.registrationPayment?.maxdate) >= new Date()) {
            registrationPayment = parseFloat(item.group.registrationPayment.price)
        }
        const registrationImpact = item.questions
        .reduce((sum, question) => {
            const registrationPercentageTotal = (parseFloat(registrationPayment) * ((parseFloat(question?.impact?.registrationPercentage || 0)) / 100))

            return parseFloat(sum) + (parseFloat(registrationPercentageTotal)) + (question?.impact?.registrationQuantity || 0);
        }, 0)

        const seasonPaymentsTotal = item.group.seasonPayments.reduce((seasonPaymentTotal, seasonPayment) => {
            if (new Date(seasonPayment.endDate) > new Date()) {
                const seasonPaymentImpact = item.questions
                .reduce((questionTotal, question) => {
                    const seasonPaymentPercentageTotal = (parseFloat(seasonPayment.price) * ((parseFloat(question?.impact?.seasonPaymentPercentage || 0)) / 100))

                    return parseFloat(questionTotal) + (parseFloat(seasonPaymentPercentageTotal)) + (question?.impact?.seasonPaymentQuantity || 0);
                }, 0)
                let total = parseFloat(seasonPaymentImpact) + parseFloat(seasonPayment.price)

                if (item.promotions) {
                    for (const promotion of item.promotions) {
                        if (total > 0) {
                            const promotionPercentageTotal = (parseFloat(total) * ((parseFloat(promotion.impact.seasonPaymentPercentage || 0)) / 100))
                            total -= (parseFloat(promotionPercentageTotal)) + (promotion.impact.seasonPaymentQuantity || 0)
                        } else {
                            total = 0
                        }
                    }
                }

                return parseFloat(seasonPaymentTotal) + (total <= 0 ? 0 : total)
            } else {
                return seasonPaymentTotal
            }
        }, 0)

        let registrationTotal = parseFloat(registrationPayment) + parseFloat(registrationImpact)

        if (item.promotions) {
            for (const promotion of item.promotions) {
                if (registrationTotal > 0) {
                    const promotionPercentageTotal = (parseFloat(registrationTotal) * ((parseFloat(promotion.impact.registrationPercentage || 0)) / 100))
                    registrationTotal -= (parseFloat(promotionPercentageTotal)) + (promotion.impact.registrationQuantity || 0)
                } else {
                    registrationTotal = 0
                }
            }
        }

        const priceToPay = (registrationTotal <= 0 ? 0 : registrationTotal) + parseFloat(seasonPaymentsTotal)

        const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);

        if (priceToPay <= 0) {
            return 0.00
        } else {
            return (Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100).toFixed(2)
        }
    }

    return (
        <Row className='border py-4 px-3 rounded my-5'>
            <Col xs={12} md={4}>
                <img src={item.activity.image || `/images/sports/${item.activity?.sport}.jpg`} alt={item.activity.title} className="w-100"/>
            </Col>
            <Col xs={12} md={8}>
                <Row className='mb-5'>
                    <Col xs={8}>
                        <h3>{item.group.title}</h3>
                    </Col>
                    <Col xs={4}>
                        <div className='border rounded group-price text-center py-3 fw-bold'>{calculateActivityPrice(item)}€</div>
                    </Col>
                </Row>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.activity.member")}</span>
                        <span>{item.client?.lastname} {item.client?.firstname}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.activity.category")}</span>
                        <span>{item.group.category.title}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.activity.federated")}</span>
                        <span>{item.group.federated ? t('global.yes') : t('global.no')}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.activity.age")}</span>
                        <span>{item.group.category.ageFrom} - {item.group.category.ageTo} {t('marketplace.activity.yearsOld')}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                {item?.group?.assignableHorary ?
                    <div className='mt-3'>
                        <div className='d-flex justify-content-between align-items-end'>
                            <span className='fw-bold'>{t("marketplace.activity.horary")}</span>
                            <span>{item.group.horaries.find(g => g._id === item.horary)?.name || ''}</span>
                        </div>
                        <hr className='border my-1'/>
                    </div>
                :
                    ''
                }
                {
                    item.questions?.map((question, questionIndex) => (
                        <div className='mt-3' key={questionIndex}>
                            <div className='d-flex justify-content-between align-items-end'>
                                <span className='fw-bold'>{question.question}</span>
                                <span>{question.answer}</span>
                            </div>
                            <hr className='border my-1'/>
                        </div>
                    ))
                }
            </Col>
        </Row>
    )
}