import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { activeUser, sendVerificationCode } from '../../actions/profiles/usersActions'
import Swal from 'sweetalert2'
import { Link, useNavigate, useParams } from 'react-router-dom'

export default function Activation() {     
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { idUser } = useParams()
    const { resultuser } = useSelector(state=>state.users)

    const [activeCode, setActivationcode] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [countdown, setCountdown] = useState(0)
    
    const ActiveCodeChange = ({target})=>{
        setActivationcode(target.value)
    }

    const activate = async(e) => {
        e.preventDefault()

        dispatch(activeUser(idUser, activeCode))
    }

    const sendCode = async(e) => {
        e.preventDefault()
        dispatch(sendVerificationCode(idUser))
        Swal.fire({
            showConfirmButton: true,
            icon: 'success',
            text: t('users.codesended')
        })

        setIsButtonDisabled(true)
        setCountdown(10)

        const interval = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(interval)
                    setIsButtonDisabled(false)
                    return 0
                }
                return prevCountdown - 1
            })
        }, 1000)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('users.gotologin'),
                        icon: 'success',
                        text: t('users.verified')
                    }).then((newresult) => {
                        if (newresult.isConfirmed) {
                            navigate('/')
                        }
                    })
                    break;
                case 'wrongcode':
                    Swal.fire({
                        showConfirmButton: true,
                        confirmButtonText: t('users.tryagain'),
                        icon: 'error',
                        text: t('users.wrongcode')
                    })
                    break;
                case 'emailsended':
                    Swal.fire({
                        showConfirmButton: true,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.emailsended')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>   
                    <Col xs={12} className='d-flex align-items-center justify-content-center p-5 mx-auto'>
                        <Container>
                            <Row>
                                <Col className='text-center'>
                                    <h1>{t('users.verifytitle')}</h1>
                                    {t('users.verifyphrase')}
                                    <Form className="formulario">
                                        <Row className="my-3 justify-content-center">
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='activate_code'>{t('users.activecode')}</Form.Label>
                                                <Form.Control
                                                    className="login mx-auto"
                                                    type='text'
                                                    name='activeCode' 
                                                    value={activeCode ||  ''}                                            
                                                    onChange={ActiveCodeChange}
                                                    required
                                                    style={{textAlign:'center',maxwidth:'300px'}}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="my-2 px-5"
                                                    onClick={activate}
                                                >
                                                    {t('users.verify')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col className='text-center'>
                                    {t('users.sendverifyphrase')}<br/>
                                    <Button type="submit" variant="default" className="my-2 px-5" onClick={sendCode} disabled={isButtonDisabled}>
                                        {isButtonDisabled ? `${t('users.sendverify')} (${countdown})` : t('users.sendverify')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
