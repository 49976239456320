import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../context/auth/useAuth'
import Loader from '../../../components/design/Loader/Loader'
import { getTicketsByClient } from '../../../actions/ticketing/ticketsActions'
import { Link } from 'react-router-dom'
import TicketSummary from '../../../components/ticketing/TicketSummary'

export default function Tickets({clientSelected}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {tickets, loading} = useSelector(state=>state.ticket)

    const [ticketSelected, setTicketSelected] = useState(null)

	const columnsTickets = [
        {
            name: t('tickets.identifier'),
            cell: ticket =>
                ticket.identifier,
            selector: (ticket) => ticket.identifier,
            sortable: true,
        },
        {
            name: t('tickets.match'),
            width: "200px",
            cell: ticket =>
                <div>
                    <div className='fw-bold'>{`${ticket.match.host} - ${ticket.match.visitor}`}</div>
                    <div><small>{`${new Date(ticket.match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${ticket.match.hour}`}</small></div>
                </div>,
            selector: (ticket) => `${ticket.match.host} - ${ticket.match.visitor} ${new Date(ticket.match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${ticket.match.hour}`,
            sortable: true,
        },{
            name: t('tickets.sector'),
            cell: ticket =>
                ticket.sector.name,
            selector: (ticket) => ticket.sector.name,
            sortable: true,
        },{
            name: t('tickets.status'),
            center: 'true',
            cell: ticket =>
                ticket.status === 0 ? t('tickets.pending') :
                ticket.status === 1 ? t('tickets.active') :
                ticket.status === 2 ? t('tickets.canceled') :
                t('tickets.unknown'),
            selector: (ticket) =>
                ticket.status === 0 ? t('tickets.pending') :
                ticket.status === 1 ? t('tickets.active') :
                ticket.status === 2 ? t('tickets.canceled') :
                t('tickets.unknown'),
            sortable: true,
        },{
            name: t('tickets.price'),
            center: 'true',
            width: "100px",
            cell: ticket => (
                    <div>
                        {ticket.price} €
                    </div>
            ),
            selector: (ticket) => ticket.price,
            sortable: true,
        },{
            name: t('tickets.client'),
            cell: ticket => (
                <div className='w-100'>
                    <span>{`${ticket.personData?.firstname} ${ticket.personData?.lastname}`}</span>
                    {
                        ticket.pass
                        ?
                            <>
                                <br />
                                <small>{ticket.pass.passType?.name}</small>
                            </>
                        :
                            <></>
                    }
                </div>
            ),
            selector: (ticket) => `${ticket.personData?.firstname} ${ticket.personData?.lastname} ${ticket.pass?.passType?.name || ''}`,
            sortable: true,
        },
        {
            name: t('global.actions'),
            center: 'true',
            cell: ticket =>
                <Link
                    onClick={() => {
                        setTicketSelected(ticket)
                    }}
                    className='table-link'>
                    <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16}/>
                </Link>,
            sortable: false,
        }
	]

    useEffect(() => {
        if(clientauth){
            dispatch(getTicketsByClient(clientSelected))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth])

    return (
        <Container className='cuerpo'>
            <Row className='mt-3 mb-5'>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('client.tickets')}</h1>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('tickets.ticketsList')}</h2>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            loading
                                                ?
                                                    <Loader/>
                                                :
                                                    <>
                                                        {
                                                            tickets?.length > 0 ?
                                                                <CustomDataTable
                                                                    columns={columnsTickets}
                                                                    data={tickets}
                                                                    exportable={false}
                                                                    printable={false}
                                                                />
                                                            :
                                                                <div className='contenedor-vacio text-center'>
                                                                    <h4>{t('tickets.noTickets')}</h4>
                                                                    {t('tickets.thereIsNoTicketsPhrase')}
                                                                </div>
                                                        }
                                                    </>
                                        }
                                        
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <TicketSummary userType="client" ticketSelected={ticketSelected} closeModal={() => setTicketSelected(null)}/>
        </Container>
    )
}