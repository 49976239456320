export const courtsReducer = (state = {loadingcourt: false, resultcourt: null, courts: [], court: null, scheduleWrong: [], courtCommission: null}, action) => {
    switch (action.type) {
        case 'COURT_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'COURT_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'COURT_GETALL_SUCCESS':
            if(action.payload.courts || action.payload.courtsMarket){
                if(action.payload.courts){
                    return {
                        ...state,
                        loadingcourt: false,
                        courts: action.payload.courts
                    }
                }else{
                    return {
                        ...state,
                        loadingcourt: false,
                        courtsMarket: action?.payload?.courtsMarket,
                        resultcourt: null
                    }
                }
            }
            break;
        case 'COURT_GET_SUCCESS':
            if(action.payload.court || action.payload.courtMarket){
                if(action.payload.court){
                    return {
                        ...state,
                        loadingcourt: false,
                        court: action.payload.court
                    }
                }else{
                    return {
                        ...state,
                        loadingcourt: false,
                        courtMarket: action?.payload?.courtMarket,
                        resultcourt: null
                    }
                }
            }else{
                return {
                    ...state,
                    loadingcourt: false,
                    resultcourt: 'thereisnotcourt'
                }
            }
        case 'COURT_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'COURT_EDITSCHEDULE_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload.message,
                court: action.payload.court,
                scheduleWrong: action.payload.indexWithConflict
            }
        case 'COURT_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'COURT_REMOVE_RESULT':
            return {
                ...state,
                loading: false,
                result: action.payload,
                courts: action.payload.message === "deleteCourtSuccess" ? state.courts.filter(s => s._id !== action.payload.court) : state.courts
            }
        case 'COURT_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        case 'COURT_RESET_SCHEDULE':
            return {
                ...state,
                scheduleWrong: []
            }
        default:
            return state
    }
}