export const feesReducer = (state = {loading: false, result: null, fees: [], fee: null}, action) => {
    switch (action.type) {
        case 'FEE_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'FEE_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'FEE_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                fees: action.payload.fees
            }
        case 'FEE_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                fee: action.payload
            }
        case 'FEE_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'FEE_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'FEE_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}