import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { useDispatch, useSelector } from 'react-redux'
import { getClubsPublicByFilter, getSitesPublicForFilter, getSportsPublicForFilter } from '../../../actions/filtersActions'

export default function SearchClubs({ancho}){
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {sitesfilter, sportsfilter} = useSelector(state=>state.filters)

    const [filterOptions, setFilterOptions] = useState({club: '', province: null, sport: null})
    const [provincesOptions, setProvincesOptions] = useState([])
    const [sportsOptions, setSportsOptions] = useState([])

    useEffect(() =>{
        dispatch(getSitesPublicForFilter())
        dispatch(getSportsPublicForFilter())
    }, [dispatch])

    useEffect(() => {
        if (sitesfilter) {
            const uniqueProvinces = [...new Set(sitesfilter.map(site => site?.province))]
            let arrayOptionsProvinces = uniqueProvinces.map(province => ({label: province, value: province}))
            arrayOptionsProvinces.unshift({label: t('marketplace.allprovinces'), value: null});
            setProvincesOptions(arrayOptionsProvinces)
        }
    }, [sitesfilter, t])

    useEffect(() => {
        if (sportsfilter) {
            let arrayOptionsSports = sportsfilter.map(sport => ({label: t('sports.'+sport), value: sport}))
            arrayOptionsSports.unshift({label: t('marketplace.allsports'), value: null});
            setSportsOptions(arrayOptionsSports)
        }
    }, [sportsfilter, t])

    const handleInputChange = ({target})=>{
        setFilterOptions({
            ...filterOptions,
            'club': target.value
        })
    }

    const handleSelectChange = (target, selecttype) => {
        setFilterOptions({
            ...filterOptions,
            [selecttype]: target.value
        })
    }

    const searchClubs = () => {
        dispatch(getClubsPublicByFilter(filterOptions))
    }

    const clearSearch = () => {
        setFilterOptions({club: '', province: null, sport: null})
        dispatch(getClubsPublicByFilter({club: '', province: null, sport: null}))
    }

    return (
        <Card className='p-0 form-special w-100'>
            <Card.Body className='m-auto' style={{ width: ancho }}>
                <Row>
                    <Col xs={12} sm={3}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                type="text"
                                name="club"
                                onChange={handleInputChange}
                                value={filterOptions?.club}
                                placeholder={t('marketplace.clubsearch')}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3}>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name="province"
                                options={provincesOptions}
                                value={provincesOptions?.find(option => option?.value === filterOptions?.province)}
                                placeholder={t('marketplace.province')}
                                onChange={(e) => handleSelectChange(e, 'province')}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={3}>
                        <Form.Group className='form-group'>
                            <Select
                                className="form-control select has-content"
                                name= "sport"
                                options={sportsOptions}
                                value={sportsOptions?.find(option => option?.value === filterOptions?.sport)}
                                placeholder={t('marketplace.sport')}
                                onChange={(e) => handleSelectChange(e, 'sport')}
                            />
                        </Form.Group>
                    </Col>
                    <Col className='text-center d-flex align-items-center justify-content-around'>
                        <Button variant='secondary' type="submit" className="my-2 mx-1" onClick={() => searchClubs()}>
                            {t('marketplace.search')}
                        </Button>
                        {filterOptions.club || filterOptions.province || filterOptions.sport ?
                            <div className="my-2 mx-1 cursor" onClick={() => clearSearch()}>
                                <img src="/images/trash.svg" alt="Limpiar" /> {t('marketplace.clearsearch')}
                            </div>
                        :
                            <></>
                        }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}