import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable.js'
import useAuth from '../../../../context/auth/useAuth.js'
import { getSeasonsByClub } from '../../../../actions/ticketing/seasonsActions.js'
import Swal from 'sweetalert2'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs.js'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu.js'

export default function Seasons({usertype, handleSeasonSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {seasons, result} = useSelector(state=>state.seasons)
    const {club} = useSelector(state=>state.clubs)

    useEffect(() => {
        handleSeasonSelect(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club?._id){
            dispatch(getSeasonsByClub(club?._id))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'editedSuccess':
                    dispatch(getSeasonsByClub(club?._id))
                    dispatch({
                        type: 'SEASON_RESET_RESULT'
                    })
                    break;
                    
                case "deleteSeasonWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('seasons.deleteSeasonWrongRerservations')
                    })
                    dispatch({
                        type: 'SEASON_RESET_RESULT'
                    })
                    break;
                case "deleteZoneWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('seasons.deleteZoneWrongRerservations')
                    })
                    dispatch({
                        type: 'SEASON_RESET_RESULT'
                    })
                    break;
                case "deleteCourtWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('seasons.deleteCourtWrongRerservations')
                    })
                    dispatch({
                        type: 'SEASON_RESET_RESULT'
                    })
                    break;
                case "deleteAreaWrongRerservations":
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'warning',
                        text: t('seasons.deleteAreaWrongRerservations')
                    })
                    dispatch({
                        type: 'SEASON_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result])

	const columnsSeasons = [
        {
            name: t('seasons.name'),
            cell: season =>
                <Link onClick={() => handleSeasonSelect(season._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.seasons")}/${t("url.club.edit")}`} className='table-link'>
                    {season.name}
                </Link>,
            selector: (season) => season.name,
            sortable: true,
        },{
			name: t('seasons.startDate'),
			cell: season =>
				<Link onClick={() => handleSeasonSelect(season._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.seasons")}/${t("url.club.edit")}`} className='table-link'>
					{new Date(season.startDate).toLocaleDateString("es-ES", {year: "numeric", month: "long", day: "numeric"})}
				</Link>,
			selector: (season) => new Date(season.startDate).toLocaleDateString("es-ES", {year: "numeric", month: "long", day: "numeric"}),
			sortable: true,
		},{
			name: t('seasons.endDate'),
			cell: season =>
				<Link onClick={() => handleSeasonSelect(season._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.seasons")}/${t("url.club.edit")}`} className='table-link'>
					{new Date(season.endDate).toLocaleDateString("es-ES", {year: "numeric", month: "long", day: "numeric"})}
				</Link>,
			selector: (season) => new Date(season.endDate).toLocaleDateString("es-ES", {year: "numeric", month: "long", day: "numeric"}),
			sortable: true,
		},{
			name: t('global.actions'),
            center: 'true',  
            width:'120px',
			cell: season =>
                <Link onClick={() => handleSeasonSelect(season._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.seasons")}/${t("url.club.edit")}`}>
                    <img src="/images/edit.svg" alt={t('global.edit')} width={15}></img>
                </Link> ,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <TicketingSubMenu usertype={usertype} active="seasons"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('seasons.seasonsList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.seasons")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('seasons.createSeason')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {seasons?.length > 0 ?
                                            <CustomDataTable columns={columnsSeasons} data={seasons} exportable={false} printable={false}/>
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('seasons.noSeasons')}</h4>
                                                {t('seasons.thereIsNotSeasonsPhrase')}
                                                <div className='mt-3'>
                                                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.seasons")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                                        {t('seasons.createSeason')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}