import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getActivitiesByClub, removeActivity } from '../../../../actions/activities/activitiesActions'
import ActivitiesSubMenu from '../../../../components/menus/ActivitiesSubMenu'
import useAuth from '../../../../context/auth/useAuth'
import Swal from 'sweetalert2'
import ActivityBack from '../../../../components/Activities/ActivityBack'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'


export default function Activities({usertype, handleActivitySelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {activities, resultactivity} = useSelector(state=>state.activities)
    const {club} = useSelector(state=>state.clubs)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club){
            dispatch(getActivitiesByClub(club._id))
        }
    },[dispatch, t, clientauth, club])

    const handleRemoveActivity = async(e, activityId) => {
        e.preventDefault()

        dispatch(removeActivity(activityId))
    }

    useEffect(() => {
        if(resultactivity){
            let newresult = resultactivity
            dispatch({
                type: 'ACTIVITY_RESET_RESULT'
            })
            switch (newresult) {
                case 'deleteactivitysuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('activities.deleteactivitysuccess')
                    })
                    break;
                case 'deleteactivitywrong':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('activities.deleteactivitywrong')
                    })
                    break;
                case 'deleteactivitynotexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('activities.deleteactivitynotexist')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultactivity])

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row>
                <Col className='d-flex align-items-start justify-content-between'>
                    <h1 className='ms-2 my-0'>{t('menus.activities.activities')}</h1>
                    <div className='buttons d-flex justify-content-end'>
                        <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.newactivity")}` : `/${t("url.club.club")}/${t("url.club.newactivity")}`} className='btn btn-secondary'>
                            <i className='fas fa-plus'></i> {t('activities.createnewactivity')}
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <ActivitiesSubMenu usertype={usertype} active="activities"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    {activities?.length > 0 ?
                        <Row>
                            {activities?.map((activity, index) => (
                                <Col key={index} xs={12} sm={6} md={4} className="my-3">
                                    <ActivityBack usertype={usertype} activity={activity} handleActivitySelect={handleActivitySelect}/>
                                </Col>
                            ))}
                        </Row>
                    :
                        <Card>
                            <Card.Body>
                                <div className='contenedor-vacio text-center'>
                                    <h4>{t('activities.thereisnotactivities')}</h4>
                                    {t('activities.thereisnotactivitiesphrase')}
                                    <div className='mt-3'>
                                        <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.newactivity")}` : `/${t("url.club.club")}/${t("url.club.newactivity")}`} className='btn btn-secondary'>
                                            {t('activities.createnewactivity')}
                                        </Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    }

                </Col>
            </Row>
        </Container>
    )
}