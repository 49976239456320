import React,{useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'

export default function Returns () {
    const { t } = useTranslation()
	useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
    }, [])

	return (
		<>
			<div className='marketplace'>
				<MenuMarketplace/>
				<Container className='market'>
					<Row>
						<Col className='mx-auto'>
							<h1>{t('legalinformation.returns.title1')}</h1>
							<h2 className='display-7'>{t('legalinformation.returns.subtitle1')}</h2>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<div className='my-3'>{t('legalinformation.returns.description1a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description1b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description1c')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle2')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description2a')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle3')}</h3>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<div className='my-3'>{t('legalinformation.returns.description3a')}</div>
							<ol>
								<li className='my-2'>{t('legalinformation.returns.description3b')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3c')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3d')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3e')}</li>
							</ol>
							<div className='my-3'>{t('legalinformation.returns.description3f')}</div>
							<ol>
								<li className='my-2'>{t('legalinformation.returns.description3g')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3h')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3i')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3j')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3k')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3l')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3m')}</li>
								<li className='my-2'>{t('legalinformation.returns.description3n')}</li>
							</ol>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle4')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description4a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description4b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description4c')}</div>
							<div className='my-3'>{t('legalinformation.returns.description4d')}</div>
							<div className='my-3'>{t('legalinformation.returns.description4e')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle5')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description5a')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle6')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description6a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description6b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description6c')}</div>
							<div className='my-3'>{t('legalinformation.returns.description6d')}</div>
							<div className='my-3'>{t('legalinformation.returns.description6e')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle7')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description7a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description7b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description7c')}</div>
							<div className='my-3'>{t('legalinformation.returns.description7d')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle8')}</h3>
							<div className='my-3 fw-bold'>{t('legalinformation.returns.description8a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8c')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8d')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8e')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8f')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8g')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8h')}</div>
							<div className='my-3 fw-bold'>{t('legalinformation.returns.description8i')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8j')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8k')}</div>
							<div className='my-3 fw-bold'>{t('legalinformation.returns.description8l')}</div>
							<div className='my-3'>{t('legalinformation.returns.description8m')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle9')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description9a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description9b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description9c')}</div>
							<div className='my-3'>{t('legalinformation.returns.description9d')}</div>
							<div className='my-3'>{t('legalinformation.returns.description9e')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle10')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description10a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description10b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description10c')}</div>
							<div className='my-3'>{t('legalinformation.returns.description10d')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle11')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description11a')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle12')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description12a')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle13')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description13a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description13b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description13c')}</div>
							<div className='my-3'>{t('legalinformation.returns.description13d')}</div>
							<div className='my-3'>{t('legalinformation.returns.description13e')}</div>
							<div className='my-3'>{t('legalinformation.returns.description13f')}</div>
							<ol type="a">
								<li className='my-2'>{t('legalinformation.returns.description13g')}</li>
								<li className='my-2'>{t('legalinformation.returns.description13h')}</li>
								<li className='my-2'>{t('legalinformation.returns.description13i')}</li>
								<li className='my-2'>{t('legalinformation.returns.description13j')}</li>
							</ol>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle14')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description14a')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle15')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description15a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description15b')}</div>
							<div className='my-3 ms-2 fw-bold'>{t('legalinformation.returns.description15c')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle16')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description16a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description16b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description16c')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h2>{t('legalinformation.returns.subtitle17')}</h2>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle18')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description18a')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle19')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description19a')}</div>
							<div className='my-3'>{t('legalinformation.returns.description19b')}</div>
							<div className='my-3'>{t('legalinformation.returns.description19c')}</div>
							<ol>
								<li className='my-2'>{t('legalinformation.returns.description19d')}</li>
								<li className='my-2'>{t('legalinformation.returns.description19e')}</li>
							</ol>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle20')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description20a')}</div>
							<ol>
								<li className='my-2'>{t('legalinformation.returns.description20b')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20c')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20d')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20e')}
									<ul>
										<li className='my-2'>{t('legalinformation.returns.description20f')}</li>
										<li className='my-2'>{t('legalinformation.returns.description20g')}</li>
										<li className='my-2'>{t('legalinformation.returns.description20h')}</li>
										<li className='my-2'>{t('legalinformation.returns.description20i')}
											<ul>
												<li className='my-2'>{t('legalinformation.returns.description20j')}</li>
												<li className='my-2'>{t('legalinformation.returns.description20k')}</li>
												<li className='my-2'>{t('legalinformation.returns.description20l')}</li>
												<li className='my-2'>{t('legalinformation.returns.description20m')}</li>
											</ul>
										</li>
									</ul>
								</li>
								<li className='my-2'>{t('legalinformation.returns.description20n')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20o')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20p')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20q')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20r')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20s')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20t')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20u')}
									<ul>
										<li className='my-2'>{t('legalinformation.returns.description20v')}</li>
										<li className='my-2'>{t('legalinformation.returns.description20w')}</li>
										<li className='my-2'>{t('legalinformation.returns.description20x')}</li>
										<li className='my-2'>{t('legalinformation.returns.description20y')}</li>
									</ul>
								</li>
								<li className='my-2'>{t('legalinformation.returns.description20z')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20aa')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20ab')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20ac')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20ad')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20ae')}</li>
								<li className='my-2'>{t('legalinformation.returns.description20af')}</li>
							</ol>
							<div className='my-3'>{t('legalinformation.returns.description20ag')}</div>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<h3>{t('legalinformation.returns.subtitle21')}</h3>
							<div className='my-3'>{t('legalinformation.returns.description21a')}</div>
							<ol>
								<li className='my-2'>{t('legalinformation.returns.description21b')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21c')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21d')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21e')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21f')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21g')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21h')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21i')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21j')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21k')}</li>
								<li className='my-2'>{t('legalinformation.returns.description21l')}</li>
							</ol>
						</Col>
					</Row>
				</Container>
			</div>
			<FooterMarketplace className='m-0'/>
		</>
	)
}