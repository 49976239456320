export const sepaLogsReducer = (state = { loadingsepalogs: false, error: null, sepalogs: null}, action)=>{
    switch (action.type) {
        case 'SEPALOGS_LOADING':
            return {
                ...state,
                loadingsepalogs: true
            }
        case 'SEPALOGS_GETALL_SUCCESS':
            return {
                ...state,
                loadingsepalogs: false,
                sepalogs: action.payload.sepalogs
            }
        case 'SEPALOGS_NOT_LOADING':
        return {
            ...state,
            loadingsepalogs:false
        }
        default:
            return state
    }
}