import { Route, Routes } from 'react-router-dom'
import { aliasMapAuth, getComponentAuth } from '../routers/AuthRouter'
import { aliasMapMarketplace, getComponentMarketplace } from '../routers/MarketplaceRouter'
import NotFoundPage from '../pages/error/404'
import NothingHere from '../pages/error/NothingHere'
import { aliasPublicAccessMap, getPublicAccessComponent } from './PublicAccessRouter'

export default function PublicRouter () {
    return (
        <div className="contenedor-full vh-100">
            <div className='contenido'>
                <Routes>
                    {Object.entries(aliasMapAuth).map(([alias, path]) => (
                        <Route key={alias} path={alias} element={getComponentAuth(path)} />
                    ))}
                    {Object.entries(aliasMapMarketplace).map(([alias, path]) => (
                        <Route key={alias} path={alias} element={getComponentMarketplace(path)} />
                    ))}
                    {Object.entries(aliasPublicAccessMap).map(([alias, path]) => (
                        <Route key={alias} path={alias} element={getPublicAccessComponent(path)} />
                    ))}
                    <Route path="/nothinghere" element={<NothingHere />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </div>
        </div>
    )
}