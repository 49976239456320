import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs.js'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu.js'
import Swal from 'sweetalert2'
import { editStadium, getClub } from '../../../../actions/profiles/clubsActions.js'

export default function Stadium({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {club, resultclub} = useSelector(state=>state.clubs)
    const fileRef = createRef("<HTMLInputElement>")

    const [formState, setFormState] = useState({
        name: null,
        image: null
    })

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const changeImage = (e) => {
        setFormState({
            ...formState,
            image: e.target.files[0]
        })
	}

    const handleEdit = (e) => {
        e.preventDefault()
        if (!formState.name || formState.name?.trim() === "") {
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('stadium.nameIsRequired')
            })
        } else {
            dispatch(editStadium(club?._id, formState))
        }
    }

    useEffect(() => {
        if (club) {
            setFormState({
                name: club.stadium?.name,
                image: club.stadium?.image
            })
        }
    }, [club])

    useEffect(() => {
        if(resultclub){
            switch (resultclub) {
                case 'editStadiumSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text:  t('stadium.editStadiumSuccess')
                    })
                    dispatch(getClub(club?._id))
                    dispatch({
                        type: 'CLUB_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [resultclub])

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <TicketingSubMenu usertype={usertype} active="stadium"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('stadium.title')}</h2>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        <Form className="formulario" onSubmit={handleEdit}>
                                            <Row className='my-3'>
                                                <Col xs={12}>
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className={formState.name && formState.name !== '' ? 'has-content' : ''}
                                                            type='text'
                                                            name='name' 
                                                            value={formState?.name ||  ''}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        <Form.Label htmlFor='name'>{t('stadium.name')}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className='my-3'>
                                                <Col xs={12} className='d-flex align-items-center'>
                                                    <div className='stadium-image me-2'>
                                                        {formState.image
                                                            ?
                                                                <img className="img-profile" alt="Preview" src={typeof formState.image === 'string' ? formState.image : URL.createObjectURL(formState.image)} />
                                                            :
                                                                <div className="img-profile"></div>
                                                        }
                                                        <input 
                                                            className='custom-file-input' 
                                                            style={{ display: 'none' }}
                                                            ref={fileRef}
                                                            type="file" 
                                                            name="image" 
                                                            onChange={changeImage}
                                                        />
                                                    </div>
                                                    <div className="editaravatar" onClick={() => fileRef.current?.click()}>{t('stadium.image')}</div>
                                                </Col>
                                            </Row>
                                            <Row className='my-3'>
                                                <Col xs={6} className='d-grid offset-6'>
                                                        <Button type="submit" variant="primary">{t('global.save')}</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}