import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable.js'
import useAuth from '../../../../context/auth/useAuth.js'
import { deleteMatch, getMatchesByClub } from '../../../../actions/ticketing/matchesActions.js'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs.js'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu.js'
import Swal from 'sweetalert2'

export default function Matches({usertype, handleMatchSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {matches, result} = useSelector(state=>state.matches)
    const {club} = useSelector(state=>state.clubs)

    const handleDeleteMatch = (matchId) => {
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.yes'),
            denyButtonText: t('global.no'),
            icon: 'warning',
            text: t('matches.deleteMatchText')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteMatch(matchId))
            }
        })
    }

    useEffect(() => {
        handleMatchSelect(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club?._id){
            dispatch(getMatchesByClub(club?._id))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    dispatch(getMatchesByClub(club?._id))
                    dispatch({
                        type: 'MATCH_RESET_RESULT'
                    })
                    break;
                case 'matchDeleteSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text:  t('matches.deleteSuccessText')
                    })
                    dispatch(getMatchesByClub(club?._id))
                    dispatch({
                        type: 'MATCH_RESET_RESULT'
                    })
                    break;
                case 'matchDeleteWrongTime':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text:  t('matches.matchDeleteWrongTime')
                    })
                    dispatch({
                        type: 'MATCH_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result])

	const columnsMatches = [
        {
            name: t('matches.host'),
            cell: match =>
                <Link onClick={() => handleMatchSelect(match._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.edit")}`} className='table-link'>
                    {match.host}
                </Link>,
            selector: (match) => match.host,
            sortable: true,
        },{
			name: t('matches.visitor'),
			cell: match =>
				<Link onClick={() => handleMatchSelect(match._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.edit")}`} className='table-link'>
					{match.visitor}
				</Link>,
			selector: (match) => match.visitor,
			sortable: true,
		},{
			name: t('matches.day'),
			cell: match =>
				<Link onClick={() => handleMatchSelect(match._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.edit")}`} className='table-link'>
					{match.day}
				</Link>,
			selector: (match) => match.day,
			sortable: true,
		},{
			name: t('matches.date'),
			cell: match =>
				<Link onClick={() => handleMatchSelect(match._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.edit")}`} className='table-link'>
					{`${new Date(match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${match.hour}`}
				</Link>,
			selector: (match) => `${new Date(match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${match.hour}`,
			sortable: true,
		},{
            name: t('matches.active'),
            center: 'true',
            cell: match => (
                match?.active
                    ?
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked disabled></input>
                    </div>
                    :
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" disabled></input>
                    </div>
            ),
            selector: (match) => match?.active,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width:'120px',
			cell: match =>
                <div className='d-flex justify-content-around w-100'>
                    <Link onClick={() => handleMatchSelect(match._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.edit")}`}>
                        <img src="/images/edit.svg" alt={t('global.edit')} width={15}></img>
                    </Link>
                    <a  href={`/${t("url.marketplace.match")}/${match._id}/${match.identifier}`}
                        onClick={() => handleMatchSelect(match._id)}
                        target="_blank"
                        rel="noopener noreferrer">
                        <img src="/images/scan.svg" alt={t('global.check')} width={15}></img>
                    </a>
                    <Link onClick={() => handleMatchSelect(match._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.checks")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.checks")}`}>
                        <i class="fa-regular fa-clipboard" style={{color: 'black'}}></i>
                    </Link>
                    <Link onClick={() => handleDeleteMatch(match._id)}>
                        <img src="/images/trash.svg" alt="trash" width={15}/>
                    </Link>
                </div>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <TicketingSubMenu usertype={usertype} active="matches"/>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('matches.matchesList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('matches.createMatch')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {matches?.length > 0 ?
                                            <CustomDataTable columns={columnsMatches} data={matches} exportable={false} printable={false}/>
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('matches.noMatches')}</h4>
                                                {t('matches.thereIsNotMatchesPhrase')}
                                                <div className='mt-3'>
                                                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.matches")}/${t("url.club.new")}`} className='btn btn-secondary'>
                                                        {t('matches.createMatch')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}