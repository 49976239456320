import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, Alert, Modal} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { changeFavouriteCard, deleteCard, editCard, getClient } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import HeadConfig from '../../../components/menus/SubMenuConfig'
import Loading from '../../../components/design/Modal/Loading'
import { getPaymentStatus, startPurchase } from '../../../actions/payments/paymentActions'
import Loader from '../../../components/design/Loader/Loader'

export default function Carddata({clientselected, usertype}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {client, loadingclient, cardResult} = useSelector(state=>state.clients)
    const { loading, error, paymentData, paymentStatus } = useSelector(state => state.payment)

    const [formState, setFormState] = useState({
        id: null,
        name: null
    })

    const [waitingForPayment, setWaitingForPayment] = useState(false)

    const [isPaying, setIsPaying] = useState(false)

    const handleDeleteCard = (id) => {
        const selectedCard = client.cards.find(c => c._id === id)
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            icon: 'warning',
            text: `${t('users.deleteCardQuestion1')} ${selectedCard.name} ${t('users.deleteCardQuestion2')} ${selectedCard.last4}`
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteCard(client._id, id))
            }
        })
    }


    const handleEditCard = () => {
        dispatch(editCard(clientauth?.client, {cardId: formState.id, name: formState.name}))
    }

    const handleFavouriteCard = (id) => {
        dispatch(changeFavouriteCard(clientauth?.client, id))
    }

    const addCard = () => {
        dispatch(startPurchase({
            quantity: 0,
            paymentClient: client?._id,
            product: t('client.newCardDescription'),
        }))
        setIsPaying(true)
    }

    useEffect(() => {
        if(clientauth){
            dispatch(getClient(clientselected))
        }
    },[dispatch, t, clientauth, clientselected])

    const handlePaymentLinkClick = () => {
        setWaitingForPayment(true)

        const intervalFn = () => {
            clearInterval(window.addCardPaymentStatusInterval)
            setWaitingForPayment(false)
            setIsPaying(false)
            dispatch({ type: 'PAYMENT_PAYMENT_STATUS_RESET' })
        }

        window.addCardPaymentStatusIntervalIndex = 0

        window.addCardPaymentStatusInterval = setInterval(() => {
            window.addCardPaymentStatusIntervalIndex++
            dispatch(getPaymentStatus(paymentData?.payment?._id))
            if (window.addCardPaymentStatusIntervalIndex > 600) {
                intervalFn()
            }
        }, 1000)
    }

    // useEffect(() => {
    //     if (paymentData?.challengeUrl) {
    //         window.open(paymentData.challengeUrl)

    //         setWaitingForPayment(true)

    //         const intervalFn = () => {
    //             clearInterval(window.addCardPaymentStatusInterval)
    //             setWaitingForPayment(false)
    //             dispatch({ type: 'PAYMENT_PAYMENT_STATUS_RESET' })
    //         }

    //         window.addCardPaymentStatusIntervalIndex = 0

    //         window.addCardPaymentStatusInterval = setInterval(() => {
    //             window.addCardPaymentStatusIntervalIndex++
    //             dispatch(getPaymentStatus(paymentData?.payment?._id))
    //             if (window.addCardPaymentStatusIntervalIndex > 600) {
    //                 intervalFn()
    //             }
    //         }, 1000)
    //     }
    // }, [paymentData, dispatch])

    useEffect(() => {
        if (paymentStatus && (paymentStatus === 3 || paymentStatus === 1)) {
            clearInterval(window.addCardPaymentStatusInterval)
            setWaitingForPayment(false)
            dispatch({ type: 'PAYMENT_PAYMENT_STATUS_RESET' })
            dispatch(getClient(clientselected))
        }
    }, [paymentStatus, dispatch, clientselected])

    useEffect(() => {
        if(cardResult){
            switch (cardResult.mensaje) {
                case 'editCardSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.editCardSuccess')
                    })
                    dispatch({ type: 'CLIENT_CARD_RESET' })
                    dispatch(getClient(clientselected))
                    setFormState({
                        id: null,
                        name: null
                    })
                    break;
                case 'deleteCardSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.deleteCardSuccess')
                    })
                    dispatch({ type: 'CLIENT_CARD_RESET' })
                    dispatch(getClient(clientselected))
                    break
                case 'changeFavouriteCardSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.changeFavouriteCardSuccess')
                    })
                    dispatch({ type: 'CLIENT_CARD_RESET' })
                    dispatch(getClient(clientselected))
                    break
                case 'deleteCardErrorAndDeleted':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('users.deleteCardErrorAndDeleted')
                    })
                    dispatch({ type: 'CLIENT_CARD_RESET' })
                    dispatch(getClient(clientselected))
                    break
                case 'cardNotFound':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('users.cardNotFound')
                    })
                    dispatch({ type: 'CLIENT_CARD_RESET' })
                    dispatch(getClient(clientselected))
                    break
                case 'clientNotFound':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('users.clientNotFound')
                    })
                    dispatch({ type: 'CLIENT_CARD_RESET' })
                    dispatch(getClient(clientselected))
                    break
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [cardResult])

    return (
        <>
            <Container className='cuerpo'>
                <Row className="my-3">
                    <Col>
                        <HeadConfig nuevaSeccion="card" type={clientauth?.client === clientselected || usertype === 'superadmin' ? 'client' : 'member'} usertype={usertype}/>
                    </Col>
                </Row>
                {clientauth?.client === clientselected || usertype === 'superadmin' ?
                    <Row className="my-3">
                        {clientauth?.client === clientselected ?
                            <Col xs={12} className='mb-4'>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={9}>
                                                <h3 className='fw-bold'>{t('users.newcard')}</h3>
                                                <hr className='fina mb-4'/>
                                                {
                                                    error &&
                                                    <Alert variant='danger'>{error}</Alert>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className='d-flex justify-content-end'>
                                                <Button variant='secondary' type='button' onClick={addCard}>
                                                    <i className='fas fa-plus me-2'/>
                                                    {t('users.addCard')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        :
                            ''
                        }
                        <Col className='mt-4'>
                            <Row>
                                {
                                    client?.cards?.map(card => (
                                        <Col xs={6} md={4} key={card._id}>
                                            <Card>
                                                <Card.Body>
                                                    <Row className='mb-3 align-items-center'>
                                                        <Col xs={9}>
                                                            {formState.id === card._id ?
                                                                <Form.Control className='py-1' autoFocus type='text' value={formState.name || ''} placeholder={t('users.cardName')} onChange={({target}) => {setFormState({...formState, name: target.value})}}/>
                                                            :
                                                                <Card.Title>
                                                                    {card.name}
                                                                </Card.Title>
                                                            }
                                                        </Col>
                                                        {formState.id === card._id ?
                                                            <>
                                                                <Col xs={1}>
                                                                    <i className='fas fa-check cursor' onClick={handleEditCard}/>
                                                                </Col>
                                                                <Col xs={1}>
                                                                    <i className='fas fa-xmark cursor' onClick={() => setFormState({id: null, name: null})}/>
                                                                </Col>
                                                            </>
                                                        :
                                                            <>
                                                                {clientauth?.client === clientselected ?
                                                                    <>
                                                                        <Col xs={1}>
                                                                            <i className={`fas fa-star ${card.favourite ? 'text-warning' : 'cursor'}`} onClick={() => handleFavouriteCard(card._id)}/>
                                                                        </Col>
                                                                        <Col xs={1}>
                                                                            <i className='fas fa-pen cursor' onClick={() => setFormState({id: card._id, name: card.name})}/>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                    ''
                                                                }
                                                                <Col xs={1}>
                                                                    <i className='fas fa-trash cursor' onClick={() => handleDeleteCard(card._id)}/>
                                                                </Col>
                                                            </>
                                                        }
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col xs={12}>
                                                            **** **** **** {card.last4}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                :
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <h4 className='text-center'>{t('users.cards.noauthorized')}</h4>
                            </Card>
                        </Col>
                    </Row>
                }
            </Container>
            <Loading show={loadingclient || loading || waitingForPayment}/>
            <Modal show={isPaying && !loading && !waitingForPayment} onHide={() => setIsPaying(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{t('cart.paymentInformation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading || waitingForPayment
                            ?
                                <Loader/>
                            :
                                <Row>
                                        {
                                            paymentData?.challengeUrl &&
                                                <Col xs={12}>
                                                    <div className='mb-5'>{t('cart.needToRegisterCard')}</div>
                                                    <div className='d-grid'>
                                                        <a href={paymentData.challengeUrl} target='_blank' className='btn btn-primary' rel="noreferrer" onClick={handlePaymentLinkClick}>{t('cart.saveCard')}</a>
                                                    </div>
                                                </Col>
                                        }
                                </Row>
                    }
                    {
                        error &&
                            <Alert variant='danger'>{ t('cart.' + error) }</Alert>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
