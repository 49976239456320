import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {Container} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import MenuSuperior from '../components/menus/MenuSuperior'
import SidebarClub from '../components/menus/SidebarClub'
import Lobby from '../pages/club/Lobby'
import Dashboard from '../pages/club/Dashboard'
import Basicdata from '../pages/club/config/Basicdata'
import Socialdata from '../pages/club/config/Socialdata'
import Documents from '../pages/club/config/Documents'
import Billingdata from '../pages/club/config/Billingdata'
import Paycometdata from '../pages/club/config/Paycometdata'
import Activities from '../pages/club/activities/activities/Activities'
import ActivityAdd from '../pages/club/activities/activities/ActivityAdd'
import ActivityEdit from '../pages/club/activities/activities/ActivityEdit'
import Categories from '../pages/club/activities/categories/Categories'
import CategoryEdit from '../pages/club/activities/categories/CategoryEdit'
import Groups from '../pages/club/activities/groups/Groups'
import GroupAdd from '../pages/club/activities/groups/GroupAdd'
import GroupEdit from '../pages/club/activities/groups/GroupEdit'
import Inscriptions from '../pages/club/activities/inscriptions/Inscriptions'
import Sites from '../pages/club/sites/sites/Sites'
import AddSite from '../pages/club/sites/sites/AddSite'
// import AddSiteStep2 from '../pages/club/sites/sites/AddSiteStep2'
// import AddSiteStep3 from '../pages/club/sites/sites/AddSiteStep3'
import useAuth from '../context/auth/useAuth'
import EditSite from '../pages/club/sites/sites/EditSite'
import EditSiteStep2 from '../pages/club/sites/sites/EditSiteStep2'
import EditSiteStep3 from '../pages/club/sites/sites/EditSiteStep3'
// import Zones from '../pages/club/sites/zones/Zones'
// import AddZone from '../pages/club/sites/zones/AddZone'
// import EditZone from '../pages/club/sites/zones/EditZone'
import Courts from '../pages/club/sites/courts/Courts'
import AddCourt from '../pages/club/sites/courts/AddCourt'
// import AddCourtStep2 from '../pages/club/sites/courts/AddCourtStep2'
import EditCourt from '../pages/club/sites/courts/EditCourt'
import EditCourtStep2 from '../pages/club/sites/courts/EditCourtStep2'
import CourtsRents from '../pages/club/sites/courts/CourtsRents'
// import Areas from '../pages/club/sites/areas/Areas'
// import AddArea from '../pages/club/sites/areas/AddArea'
// import AddAreaStep2 from '../pages/club/sites/areas/AddAreaStep2'
// import EditArea from '../pages/club/sites/areas/EditArea'
// import EditAreaStep2 from '../pages/club/sites/areas/EditAreaStep2'
import NotFoundPage from '../pages/error/404'
import { useDispatch, useSelector } from 'react-redux'
import { getClubsByUser,getClub } from '../actions/profiles/clubsActions'
import SidebarClubEmpty from '../components/menus/SidebarClubEmpty'
import Config from '../pages/club/config/Config'
import Payments from '../pages/club/payments/payments/payments'
import Promotions from '../pages/club/payments/promotions/Promotions'
import AddPromotion from '../pages/club/payments/promotions/AddPromotion'
import EditPromotion from '../pages/club/payments/promotions/EditPromotion'
import Seasons from '../pages/club/ticketing/seasons/Seasons'
import EditSeason from '../pages/club/ticketing/seasons/EditSeason'
import AddSeason from '../pages/club/ticketing/seasons/AddSeason'
import Sectors from '../pages/club/ticketing/sectors/Sectors'
import AddSector from '../pages/club/ticketing/sectors/AddSector'
import EditSector from '../pages/club/ticketing/sectors/EditSector'
import Stadium from '../pages/club/ticketing/stadium/Stadium'
import Matches from '../pages/club/ticketing/matches/Matches'
import AddMatch from '../pages/club/ticketing/matches/AddMatch'
import EditMatch from '../pages/club/ticketing/matches/EditMatch'
import PassTypes from '../pages/club/ticketing/passTypes/PassTypes'
import AddPassType from '../pages/club/ticketing/passTypes/AddPassType'
import EditPassType from '../pages/club/ticketing/passTypes/EditPassType'
import InscriptionsCanceled from '../pages/club/activities/inscriptions/InscriptionsCanceled'
import Fees from '../pages/club/ticketing/fees/Fees'
import AddFee from '../pages/club/ticketing/fees/AddFee'
import EditFee from '../pages/club/ticketing/fees/EditFee'
import Passes from '../pages/club/ticketing/passes/passes'
import Check from '../pages/club/ticketing/matches/Check'
import Checks from '../pages/club/ticketing/matches/Checks'
import Tickets from '../pages/club/ticketing/tickets/tickets'
import SepaLogs from '../pages/admin/logs/SepaLogs'
import Invitations from '../pages/club/ticketing/tickets/invitations'
import CourtsCanceled from '../pages/club/sites/courts/CourtsCanceled'

export default function ClubRouter () {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {clubs} = useSelector(state=>state.clubs)

	const [usertype, setUsertype] = useState(null)
	const [selectedClubId, setSelectedClubId] = useState(null)
	const [selectedActivityId, setSelectedActivityId] = useState(null)
	const [selectedCategoryId, setSelectedCategoryId] = useState(null)
	const [selectedGroupId, setSelectedGroupId] = useState(null)
	const [selectedInscriptionId, setSelectedInscriptionId] = useState(null)
	const [selectedSiteId, setSelectedSite] = useState(null)
	// const [selectedZoneId, setSelectedZone] = useState(null)
	const [selectedCourtId, setSelectedCourt] = useState(null)
	// const [selectedAreaId, setSelectedArea] = useState(null)
	const [selectedPromotionId, setSelectedPromotion] = useState(null)
	const [selectedSeasonId, setSelectedSeason] = useState(null)
	const [selectedSectorId, setSelectedSector] = useState(null)
	const [selectedFeeId, setSelectedFee] = useState(null)
	const [selectedMatchId, setSelectedMatch] = useState(null)
	const [selectedPassTypeId, setSelectedPassType] = useState(null)

	const [isCollapsed, setIsCollapsed] = useState(false)

	const toggleSidebar = () => {
		setIsCollapsed(!isCollapsed)
	}

	const updateSessionStorage = (newValues) => {
		const currentData = JSON.parse(sessionStorage.getItem('dataIncrono')) || {}
		const updatedData = { ...currentData, ...newValues }
		sessionStorage.setItem('dataIncrono', JSON.stringify(updatedData))
	}

	const handleClientSelect = (clientId) => {
		updateSessionStorage({clientId})
	}

    const handleClubSelect = (clubIdToChange) => {
		const storedData = JSON.parse(sessionStorage.getItem('dataIncrono'))
		let lastClubId = null
		if (storedData?.clubId) {
			lastClubId = storedData?.clubId
		}

		if(clubIdToChange){
			setSelectedClubId(clubIdToChange)
			dispatch(getClub(clubIdToChange))
		}else{
			setSelectedClubId(null)
			dispatch({type: 'CLUB_RESET'})
		}
		if(lastClubId !== clubIdToChange){
			handleActivitySelect(null)
			handleCategorySelect(null)
			handleGroupSelect(null)
			handleInscriptionSelect(null)
			setSelectedSite(null)
			// setSelectedZone(null)
			setSelectedCourt(null)
			// setSelectedArea(null)
			updateSessionStorage({clubId: clubIdToChange})
		}
    }

    const handleActivitySelect = (activityId) => {
        setSelectedActivityId(activityId)
        updateSessionStorage({activityId})
    }

    const handleCategorySelect = (categoryId) => {
        setSelectedCategoryId(categoryId)
        updateSessionStorage({categoryId})
    }

    const handleGroupSelect = (groupId) => {
        setSelectedGroupId(groupId)
        updateSessionStorage({groupId})
    }

	const handleInscriptionSelect = (inscriptionId) => {
        setSelectedInscriptionId(inscriptionId)
		updateSessionStorage({inscriptionId})
	}

	const handleSiteSelect = (siteId) => {
		setSelectedSite(siteId)
		updateSessionStorage({siteId})
	}

	// const handleZoneSelect = (zoneId) => {
	// 	setSelectedZone(zoneId)
	// 	updateSessionStorage({zoneId})
	// }

	const handleCourtSelect = (courtId) => {
		setSelectedCourt(courtId)
		updateSessionStorage({courtId})
	}

	// const handleAreaSelect = (areaId) => {
	// 	setSelectedArea(areaId)
	// 	updateSessionStorage({areaId})
	// }

	const handlePromotionSelect = (promotionId) => {
		setSelectedPromotion(promotionId)
		updateSessionStorage({promotionId})
	}

	const handleSeasonSelect = (seasonId) => {
		setSelectedSeason(seasonId)
		updateSessionStorage({seasonId})
	}

	const handleSectorSelect = (sectorId) => {
		setSelectedSector(sectorId)
		updateSessionStorage({sectorId})
	}

	const handleFeeSelect = (feeId) => {
		setSelectedFee(feeId)
		updateSessionStorage({feeId})
	}

	const handleMatchSelect = (matchId) => {
		setSelectedMatch(matchId)
		updateSessionStorage({matchId})
	}

	const handlePassTypeSelect = (passTypeId) => {
		setSelectedPassType(passTypeId)
		updateSessionStorage({passTypeId})
	}

	useEffect(() => {
		const storedData = sessionStorage.getItem('dataIncrono')
		if (storedData) {
			// const { clubId, activityId, categoryId, groupId, inscriptionId, siteId, zoneId, courtId, areaId } = JSON.parse(storedData)
			const { clubId, activityId, categoryId, groupId, inscriptionId, siteId, courtId, matchId } = JSON.parse(storedData)
			if (clubId) handleClubSelect(clubId)
			if (activityId) setSelectedActivityId(activityId)
			if (categoryId) setSelectedCategoryId(categoryId)
			if (groupId) setSelectedGroupId(groupId)
			if (inscriptionId) setSelectedInscriptionId(inscriptionId)
			if (siteId) setSelectedSite(siteId)
			// if (zoneId) setSelectedZone(zoneId)
			if (courtId) setSelectedCourt(courtId)
			// if (areaId) setSelectedArea(areaId)
			if (matchId) setSelectedMatch(matchId)
		}
	// eslint-disable-next-line
	}, [])

	useEffect(() => {
		let token = sessionStorage.getItem('jwt')

		if (!token) {
			navigate(`/${t("url.auth.login")}`)
		}
	}, [navigate, t])

	useEffect(() => {
		if(clientauth){
			setUsertype(clientauth?.usertype)
			dispatch(getClubsByUser(clientauth?.id))
		}
	}, [dispatch, clientauth])

	const clubRoutesAlias = {
		"": "dashboard",
		[t("url.club.dashboard")]: "dashboard",
		[t("url.club.lobby")]: "lobby",
		[t("url.club.basicdata")]: "basicdata",
		[t("url.club.socialdata")]: "socialdata",
		[t("url.club.documents")]: "documents",
		[t("url.club.billingdata")]: "billingdata",
		[t("url.club.paycometdata")]: "paycometdata",
		[t("url.club.accountdata")]: "config",
		[t("url.club.activities")]: "activities",
		[t("url.club.newactivity")]: "newactivity",
		[t("url.club.editactivity")]: "editactivity",
		[t("url.club.categories")]: "categories",
		[t("url.club.editcategory")]: "editcategory",
		[t("url.club.groups")]: "groups",
		[t("url.club.newgroup")]: "newgroup",
		[t("url.club.editgroup")]: "editgroup",
		[t("url.club.inscriptions")]: "inscriptions",
		[t("url.club.inscriptionscanceled")]: "inscriptionscanceled",
		[t("url.club.inscription")]: "inscription",
		[t("url.club.horaries")]: "horaries",
		[t("url.club.edithorary")]: "edithorary",
		[t("url.club.sites")]: "sites",
		[`${t("url.club.sites")}/${t("url.club.new")}`]: "sites/new",
		// [`${t("url.club.sites")}/${t("url.club.new")}/${t("url.club.step2")}`]: "sites/new/step2",
		// [`${t("url.club.sites")}/${t("url.club.new")}/${t("url.club.step3")}`]: "sites/new/step3",
		[`${t("url.club.sites")}/${t("url.club.edit")}`]: "sites/edit",
		[`${t("url.club.sites")}/${t("url.club.edit")}/${t("url.club.step2")}`]: "sites/edit/step2",
		[`${t("url.club.sites")}/${t("url.club.edit")}/${t("url.club.step3")}`]: "sites/edit/step3",
		// [t("url.club.zones")]: "zones",
		// [`${t("url.club.zones")}/${t("url.club.new")}`]: "zones/new",
		// [`${t("url.club.zones")}/${t("url.club.edit")}`]: "zones/edit",
		[t("url.club.courts")]: "courts",
		[`${t("url.club.courts")}/${t("url.club.new")}`]: "courts/new",
		[`${t("url.club.courts")}/${t("url.club.new")}/${t("url.club.step2")}`]: "courts/new/step2",
		[`${t("url.club.courts")}/${t("url.club.edit")}`]: "courts/edit",
		[`${t("url.club.courts")}/${t("url.club.edit")}/${t("url.club.step2")}`]: "courts/edit/step2",
		[t("url.club.courtsrents")]: "courtsrents",
		[t("url.club.courtscanceled")]: "courtscanceled",
		// [t("url.club.areas")]: "areas",
		// [`${t("url.club.areas")}/${t("url.club.new")}`]: "areas/new",
		// [`${t("url.club.areas")}/${t("url.club.new")}/${t("url.club.step2")}`]: "areas/new/step2",
		// [`${t("url.club.areas")}/${t("url.club.edit")}`]: "areas/edit",
		// [`${t("url.club.areas")}/${t("url.club.edit")}/${t("url.club.step2")}`]: "areas/edit/step2",
		[t("url.club.payments")]: "payments",
		[t("url.club.promotions")]: "promotions",
		[`${t("url.club.promotions")}/${t("url.club.new")}`]: "promotions/new",
		[`${t("url.club.promotions")}/${t("url.club.edit")}`]: "promotions/edit",
		[t("url.club.seasons")]: "seasons",
		[`${t("url.club.seasons")}/${t("url.club.new")}`]: "seasons/new",
		[`${t("url.club.seasons")}/${t("url.club.edit")}`]: "seasons/edit",
		[t("url.club.sectors")]: "sectors",
		[`${t("url.club.sectors")}/${t("url.club.new")}`]: "sectors/new",
		[`${t("url.club.sectors")}/${t("url.club.edit")}`]: "sectors/edit",
		[t("url.club.fees")]: "fees",
		[`${t("url.club.fees")}/${t("url.club.new")}`]: "fees/new",
		[`${t("url.club.fees")}/${t("url.club.edit")}`]: "fees/edit",
		[t("url.club.stadium")]: "stadium",
		[t("url.club.matches")]: "matches",
		[`${t("url.club.matches")}/${t("url.club.new")}`]: "matches/new",
		[`${t("url.club.matches")}/${t("url.club.edit")}`]: "matches/edit",
		[t("url.club.passTypes")]: "passTypes",
		[`${t("url.club.passTypes")}/${t("url.club.new")}`]: "passTypes/new",
		[`${t("url.club.passTypes")}/${t("url.club.edit")}`]: "passTypes/edit",
		[`${t("url.club.matches")}/${t("url.club.checks")}`]: "checks",
		[t("url.club.passes")]: "passes",
		[t("url.club.tickets")]: "tickets",
		[t("url.club.invitations")]: "invitations",
		[`${t("url.club.logs")}/${t("url.club.sepalogs")}`]: "logs/sepalogs",
	}

	const getComponentClub = (path) => {
		switch (path) {
			case "lobby": return <Lobby handleClubSelect={handleClubSelect}/>;
			case "dashboard": return <Dashboard />;

			case "basicdata": return <Basicdata usertype={usertype} />;
			case "socialdata": return <Socialdata usertype={usertype} />;
			case "documents": return <Documents usertype={usertype} />;
			case "billingdata": return <Billingdata usertype={usertype} />;
			case "paycometdata": return <Paycometdata usertype={usertype} />;
			case "config": return <Config usertype={usertype} />;

			case "activities": return <Activities usertype={usertype} handleActivitySelect={handleActivitySelect}/>;
			case "newactivity": return <ActivityAdd usertype={usertype} handleActivitySelect={handleActivitySelect}/>;
			case "editactivity": return <ActivityEdit usertype={usertype} activityselected={selectedActivityId}/>;

			case "categories": return <Categories usertype={usertype} handleCategorySelect={handleCategorySelect}/>;
			case "editcategory": return <CategoryEdit usertype={usertype} categoryselected={selectedCategoryId} />;

			case "groups": return <Groups usertype={usertype} groupselected={selectedGroupId} handleGroupSelect={handleGroupSelect}/>;
			case "newgroup": return <GroupAdd usertype={usertype} handleGroupSelect={handleGroupSelect}/>;
			case "editgroup": return <GroupEdit usertype={usertype} groupselected={selectedGroupId}/>;

			case "inscriptions": return <Inscriptions usertype={usertype} isclub={true} inscriptionselected={selectedInscriptionId} handleInscriptionSelect={handleInscriptionSelect}/>;
			case "inscriptionscanceled": return <InscriptionsCanceled usertype={usertype} isclub={true} inscriptionselected={selectedInscriptionId} handleInscriptionSelect={handleInscriptionSelect}/>;

			case "sites": return <Sites usertype={usertype} handleSiteSelect={handleSiteSelect}/>
			case "sites/new": return <AddSite usertype={usertype} handleSiteSelect={handleSiteSelect}/>
			// case "sites/new/step2": return <AddSiteStep2 usertype={usertype} selectedSite={selectedSiteId}/>
			// case "sites/new/step3": return <AddSiteStep3 usertype={usertype} selectedSite={selectedSiteId}/>
			case "sites/edit": return <EditSite usertype={usertype} handleSiteSelect={handleSiteSelect}/>
			case "sites/edit/step2": return <EditSiteStep2 usertype={usertype} selectedSite={selectedSiteId}/>
			case "sites/edit/step3": return <EditSiteStep3 usertype={usertype} selectedSite={selectedSiteId}/>

			// case "zones": return <Zones usertype={usertype} handleZoneSelect={handleZoneSelect} />;
			// case "zones/new": return <AddZone usertype={usertype} />;
			// case "zones/edit": return <EditZone usertype={usertype} selectedZoneId={selectedZoneId} />;

			case "courts": return <Courts usertype={usertype} handleCourtSelect={handleCourtSelect}/>
			case "courts/new": return <AddCourt usertype={usertype} handleCourtSelect={handleCourtSelect} selectedCourt={selectedCourtId}/>
			// case "courts/new/step2": return <AddCourtStep2 usertype={usertype} handleCourtSelect={handleCourtSelect} selectedCourt={selectedCourtId}/>
			case "courts/edit": return <EditCourt usertype={usertype} selectedCourtId={selectedCourtId}/>
			case "courts/edit/step2": return <EditCourtStep2 usertype={usertype} selectedCourt={selectedCourtId}/>

			case "courtsrents": return <CourtsRents usertype={usertype}/>
			case "courtscanceled": return <CourtsCanceled usertype={usertype}/>

			// case "areas": return <Areas usertype={usertype} handleAreaSelect={handleAreaSelect}/>
			// case "areas/new": return <AddArea usertype={usertype} handleAreaSelect={handleAreaSelect} selectedArea={selectedAreaId}/>
			// case "areas/new/step2": return <AddAreaStep2 usertype={usertype} handleAreaSelect={handleAreaSelect} selectedArea={selectedAreaId}/>
			// case "areas/edit": return <EditArea usertype={usertype} selectedAreaId={selectedAreaId}/>
			// case "areas/edit/step2": return <EditAreaStep2 usertype={usertype} selectedArea={selectedAreaId}/>

			case "payments": return <Payments usertype={usertype} />;

			
			case "promotions": return <Promotions usertype={usertype} handlePromotionSelect={handlePromotionSelect}/>
			case "promotions/new": return <AddPromotion usertype={usertype}/>
			case "promotions/edit": return <EditPromotion usertype={usertype} handlePromotionSelect={handlePromotionSelect} selectedPromotion={selectedPromotionId}/>

			case "seasons": return <Seasons usertype={usertype} handleSeasonSelect={handleSeasonSelect}/>
			case "seasons/new": return <AddSeason usertype={usertype}/>
			case "seasons/edit": return <EditSeason usertype={usertype} handleSeasonSelect={handleSeasonSelect} selectedSeason={selectedSeasonId}/>

			case "sectors": return <Sectors usertype={usertype} handleSectorSelect={handleSectorSelect}/>
			case "sectors/new": return <AddSector usertype={usertype}/>
			case "sectors/edit": return <EditSector usertype={usertype} handleSectorSelect={handleSectorSelect} selectedSector={selectedSectorId}/>

			case "fees": return <Fees usertype={usertype} handleFeeSelect={handleFeeSelect}/>
			case "fees/new": return <AddFee usertype={usertype}/>
			case "fees/edit": return <EditFee usertype={usertype} handleFeeSelect={handleFeeSelect} selectedFee={selectedFeeId}/>

			case "stadium": return <Stadium usertype={usertype}/>

			case "matches": return <Matches usertype={usertype} handleMatchSelect={handleMatchSelect}/>
			case "matches/new": return <AddMatch usertype={usertype}/>
			case "matches/edit": return <EditMatch usertype={usertype} handleMatchSelect={handleMatchSelect} selectedMatch={selectedMatchId}/>

			case "passTypes": return <PassTypes usertype={usertype} handlePassTypeSelect={handlePassTypeSelect}/>
			case "passTypes/new": return <AddPassType usertype={usertype}/>
			case "passTypes/edit": return <EditPassType usertype={usertype} handlePassTypeSelect={handlePassTypeSelect} selectedPassType={selectedPassTypeId}/>

			case "checks": return <Checks usertype={usertype} selectedMatch={selectedMatchId}/>

			case "passes": return <Passes usertype={usertype}/>;

			case "tickets": return <Tickets usertype={usertype}/>;
			case "invitations": return <Invitations usertype={usertype}/>;

			case "logs/sepalogs": return <SepaLogs usertype={usertype}/>

			default: return <NotFoundPage />;
		}
	}

	return (
		<Container fluid className='d-flex p-0'>
			{selectedClubId ?
				<SidebarClub isCollapsed={isCollapsed} />
			:
				<SidebarClubEmpty client={clientauth?.client} handleClientSelect={handleClientSelect} handleClubSelect={handleClubSelect} isCollapsed={isCollapsed} />
			}
			<Container fluid className={`contenedor ${isCollapsed ? 'expanded' : ''}`}>
				{selectedClubId ? <MenuSuperior toggleSidebar={toggleSidebar} usertype={usertype} isCollapsed={isCollapsed} client={clientauth?.client} clubs={clubs} avatar={clientauth?.avatar ? clientauth?.avatar : "/images/avatar-club.png"} handleClientSelect={handleClientSelect} handleClubSelect={handleClubSelect}/> : <></>}
				<Routes>
					{Object.entries(clubRoutesAlias).map(([alias, path]) => (
						<Route key={alias} path={alias} element={getComponentClub(path)} />
					))}
					<Route path="*" element={<NotFoundPage />}/>
				</Routes>
			</Container>
		</Container>
	)
	
}