import React,{useState, useEffect} from 'react'
import { Row, Col, Modal , Form, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editPaymentQuantity } from '../../actions/payments/paymentActions'
import { getCommissionByClub } from '../../actions/settings/commissionsActions'
import { useTranslation } from "react-i18next"
import Swal from 'sweetalert2'

export default function PaymentSummary({userType = "client", payment, onHide}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()
    const {commission} = useSelector(state=>state.commissions)
    const {editStatus} = useSelector(state=>state.payment)
    const [formState, setFormState] = useState({
        rawQuantity: payment?.rawQuantity || ""
    })
    useEffect(() => {
        if(payment){
            dispatch(getCommissionByClub(payment?.ref?.clubId))
            if (payment?.rawQuantity !== undefined) {
                setFormState({ rawQuantity: payment.rawQuantity })
            }
        }

    }, [payment, dispatch])


    const handleInputChange = ({ target }) => {
        setFormState({ rawQuantity: target.value })
    }
    const handleSubmit = () => {
        dispatch(editPaymentQuantity(payment?._id, 'editPaymentQuantity', formState?.rawQuantity));
    };
    const calculatePayment = (price, type) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }
        if (!isNaN(price)) {
            let commisionToApply = null
            if(type === 'Inscription'){
                commisionToApply = commission?.activities?.find(activity => price >= activity.lowPrice && price <= activity.highPrice);
            }
            if(type === 'Pass' || type === 'Ticket'){
                commisionToApply = commission?.ticketing?.find(ticketing => price >= ticketing.lowPrice && price <= ticketing.highPrice);
            }
            if(type === 'Reservation'){
                commisionToApply = commission?.sites?.find(site => price >= site.lowPrice && price <= site.highPrice);
            }
            if (commisionToApply) {
                return {
                    price: price,
                    priceClient: price > 0 ? (Math.ceil(((price * (commisionToApply.percentage || 0) / 100) + (commisionToApply.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
                }
            } else {
                return {
                    price: price,
                    priceClient: '0.00'
                }
            }
        } else {
            return {
                price: '0.00',
                priceClient: '0.00'
            }
        }
    }

    useEffect(() => {
        if (editStatus) {
            switch (editStatus) {
                case "editSuccess":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('payments.editSuccess')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            onHide();
                        }
                    })
                    break;
                default:
                    break;
            }
            dispatch({ type: "PAYMENT_EDIT_RESET" })
        }
    }, [editStatus,t])

    return (
        <Modal show={payment ? true : false} onHide={() => onHide()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('payments.paymentData')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cuerpo px-5 py-4'>
                <div>
                    {
                        payment
                            ?
                                <Row className="my-2 p-2 rounded">
                                    <Col xs={12} lg={6}>
                                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                            <small>{t('inscriptions.paymentreference')}</small> {payment?.paymentReference}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center border-1 border-bottom p-2'>
                                            <small>{t('inscriptions.quantity')}</small>
                                            <Form.Group className='form-group'>
                                                <Form.Control
                                                    className='has-content text-right mb-0'
                                                    value={formState?.rawQuantity}
                                                    name="quantity"
                                                    onChange={handleInputChange}
                                                    style={{ width: '100px' }}
                                                />                                                
                                                <img src="/images/money.svg" alt="Money" className="icono-money" />
                                            </Form.Group>
                                        </div>
                                        <div className='d-flex justify-content-between border-bottom p-2'>
                                            <small>{t('inscriptions.priceclient')}</small>
                                            {calculatePayment(formState?.rawQuantity || 0, payment?.refType).priceClient}€
                                        </div>
                                        <div className='d-flex justify-content-between border-bottom p-2'>
                                            <strong>{t('inscriptions.finalprice')}</strong>
                                            <div>{(Number(formState?.rawQuantity) + Number(calculatePayment(formState?.rawQuantity || 0, payment?.refType).priceClient)).toFixed(2)} €</div>
                                        </div>
                                        <div className='d-flex justify-content-between border-bottom p-2'>
                                            <small>{t('inscriptions.paymentdate')}:</small> {payment.paymentDate ? `${new Date(payment.paymentDate).getDate() > 9 ? new Date(payment.paymentDate).getDate() : "0" + new Date(payment.paymentDate).getDate()}/${new Date(payment.paymentDate).getMonth() > 8 ? parseInt(new Date(payment.paymentDate).getMonth()) + 1 : "0" + (parseInt(new Date(payment.paymentDate).getMonth()) + 1)}/${new Date(payment.paymentDate).getFullYear() > 9 ? new Date(payment.paymentDate).getFullYear() : "0" + new Date(payment.paymentDate).getFullYear()}`: '-'}
                                        </div>
                                        <div className='d-flex justify-content-between border-bottom p-2'>
                                            <small>{t('payments.refType')}:</small> {payment.refType && payment.ref ? t('payments.' + payment.refType) : t('payments.cardRegistration')}
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                            <small>{t('inscriptions.status')}:</small> {t('inscriptions.statusseasonpay'+payment?.status)} {payment.externalPaid ? ` ${t('payments.externalPaid')}` : ''}
                                        </div>
                                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                            <small>{t('inscriptions.creationdate')}:</small> {payment.creationDate ? `${new Date(payment.creationDate).getDate() > 9 ? new Date(payment.creationDate).getDate() : "0" + new Date(payment.creationDate).getDate()}/${new Date(payment.creationDate).getMonth() > 8 ? parseInt(new Date(payment.creationDate).getMonth()) + 1 : "0" + (parseInt(new Date(payment.creationDate).getMonth()) + 1)}/${new Date(payment.creationDate).getFullYear() > 9 ? new Date(payment.creationDate).getFullYear() : "0" + new Date(payment.creationDate).getFullYear()}`: '-'}
                                        </div>
                                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                            <small>{t('payments.member')}:</small> {payment.client ? `${payment.client?.lastname}, ${payment.client?.firstname}` : '-'}
                                        </div>
                                        {   userType === 'superadmin' || userType === 'club'
                                                ?
                                                <>
                                                    <div className='d-flex justify-content-between p-2'>
                                                        <small>{t('inscriptions.sepapaid')}</small> {payment?.sepaPaid ? t('global.yes') : t('global.no')}
                                                    </div>
                                                    <div className='d-flex justify-content-end p-2'>
                                                        <Button variant="primary" onClick={() => handleSubmit()}>
                                                            {t('global.save')}
                                                        </Button>
                                                    </div>
                                                </>
                                                :
                                                    <></>
                                        }
                                    </Col>
                                </Row>
                            :
                                <></>
                    }
                </div>
            </Modal.Body>
        </Modal>
	)
}