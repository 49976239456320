import React from "react";
import { Document, Page, StyleSheet, Font } from "@react-pdf/renderer"
import OnlyTicket from "./OnlyTicket";

Font.register({
	family: 'Northlane Two',
    src: '/fonts/Northlane/Northlane-Two.ttf',
    fontWeight: 'normal',
});

export default function MultipleTickets({tickets}){
	let styles = StyleSheet.create({
		pagina: {
			flexDirection: 'row',
			padding: 10
		}
	})

	const widthTicket = tickets ? tickets[0]?.match?.ticketOrientation ? 670 : 420 : 420 
	const heightTicket = tickets ? tickets[0]?.match?.ticketOrientation ? 420 : 670 : 670

	return (
		<Document>
			{
				tickets?.map((ticket, index) => (
					<Page size={{width: widthTicket, height: heightTicket}} style={styles.pagina} key={index}>
						<OnlyTicket ticket={ticket}/>
					</Page>
				))
			}
		</Document>
	)
}