import { Button, Col, Form, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Loader from "../../design/Loader/Loader"
import { Fragment } from "react"

export default function FeeForm({state, statetype, usertype, isAddingOrEditing, submit, setState, sectors = [], commission}) {
    const { t } = useTranslation()

    const handleInputChange = ({target}) => {
        setState({
            ...state,
            [target.name]: target.value
        })
    }

    const handlePriceInput = (value, index) => {
        setState({
            ...state,
            prices: state.prices?.map((price, priceIndex) => {
                if (priceIndex === index) {
                    if (/^\d*\.?\d*$/.test(value)) {
                        return { ...price, price: value }
                    } else {
                        return price
                    }
                } else {
                    return price
                }
            })
        })
    }

    const calculatePayment = (price) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }

        if (!isNaN(price)) {
            const ticketing = commission?.ticketing?.find(ticketing => price >= ticketing.lowPrice && price <= ticketing.highPrice);
            if (ticketing) {
                return {
                    price: price,
                    priceClient: price > 0 ? (Math.ceil(((price * (ticketing.percentage || 0) / 100) + (ticketing.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
                }
            } else {
                return {
                    price: price,
                    priceClient: '0.00'
                }
            }
        } else {
            return {
                price: '0.00',
                priceClient: '0.00'
            }
        }
    }

    return (
        <>
            <Form onSubmit={submit}>
                <Row>
                    <Col>
                        <h3 className="font-single">{t('fees.data')}</h3>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.name ? 'has-content' : ''}
                                type='text'
                                name='name'
                                value={state?.name ||  ''}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Label htmlFor='name'>{t('fees.name')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3 fw-bold border-bottom">
                    <Col xs={6} md={8}>
                        {t('fees.sector')}
                    </Col>
                    <Col xs={6} md={4}>
                        {t('fees.price')}
                    </Col>
                </Row>
                {
                    sectors?.length > 0 && state.prices?.length > 0
                    ?
                        state.prices?.map((price, index) => (
                            price.deleted
                            ?
                                <Fragment key={index}/>
                            :
                                <Row className="my-2 border-bottom align-items-center" key={index}>
                                    <Col xs={6} md={8}>
                                        { sectors?.find(sector => sector._id === price.sector)?.name }
                                    </Col>
                                    <Col xs={6} md={4}>
                                        <div>
                                            <Form.Group className='form-group'>
                                                <Form.Control
                                                    className={price.price ? 'has-content' : ''}
                                                    type='text'
                                                    name='price'
                                                    value={price.price ||  ''}
                                                    onChange={({target}) => handlePriceInput(target.value, index)}
                                                    placeholder={t('fees.price')}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <small>{t('inscriptions.priceclient')}</small>
                                            <small>
                                                {calculatePayment(price.price || 0).priceClient}€
                                            </small>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <small>{t('inscriptions.finalprice')}</small>
                                            <small>
                                                {(
                                                    Number(price.price) +
                                                    Number(calculatePayment(price.price || 0).priceClient)
                                                ).toFixed(2)}€
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                        ))
                    :
                        <Row>
                            <Col className="text-center">
                                { t('fees.noSectors') }
                            </Col>
                        </Row>
                }
                {isAddingOrEditing
                    ?
                        <Loader/>
                    :
                        <Row className="mt-5">
                            <Col xs={12} lg={6}>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}` : `/${t("url.club.club")}/${t("url.club.fees")}`} className="btn btn-default my-2 w-100">
                                    {t('global.cancel')}
                                </Link>
                            </Col>
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button type="submit" variant="primary" className='my-2 w-100'>
                                    {
                                        statetype === 'add' ? t('global.save') :
                                        statetype === 'edit' ? t('global.edit') : ''
                                    }
                                </Button>
                            </Col>
                        </Row>
                }
            </Form>
        </>
    )    
}
