import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { getPassTypesPublicByFilter } from '../../../actions/filtersActions'

export default function SearchPassTypes({ancho, filters = null, club = null}){
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [filterOptions, setFilterOptions] = useState({passType: '', club: null})

    useEffect(() => {
        if (filters) {
            setFilterOptions(filters)
            dispatch(getPassTypesPublicByFilter(filters))
        }
    }, [filters, dispatch])

    useEffect(() => {
        if (club) {
            setFilterOptions({...filterOptions, club: club})
        }
    // eslint-disable-next-line
    }, [club])

    const handleInputChange = ({target})=>{
        setFilterOptions({
            ...filterOptions,
            'passType': target.value
        })
    }

    const searchPassTypes = () => {
        dispatch(getPassTypesPublicByFilter(filterOptions))
    }

    const clearSearch = () => {
        setFilterOptions({passType: ''})
        dispatch(getPassTypesPublicByFilter({passType: ''}))
    }

    return (
        <Card.Body className='m-auto' style={{ width: ancho }}>
            <Row>
                <Col>
                    <Form.Group className='form-group'>
                        <Form.Control
                            type="text"
                            name="passType"
                            onChange={handleInputChange}
                            value={filterOptions?.passType}
                            placeholder={t('marketplace.passTypesearch')}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col className='text-center d-flex align-items-center justify-content-around'>
                    <Button variant='secondary' type="submit" className="my-2 mx-1" onClick={() => searchPassTypes()}>
                        {t('marketplace.search')}
                    </Button>
                    {filterOptions.passType || filterOptions.province || filterOptions.sport ?
                        <div className="my-2 mx-1 cursor" onClick={() => clearSearch()}>
                            <img src="/images/trash.svg" alt="Limpiar" /> {t('marketplace.clearsearch')}
                        </div>
                    :
                        <></>
                    }
                </Col>
            </Row>
        </Card.Body>
    )
}