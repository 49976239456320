import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import { getActivitiesPublicByClub } from '../../../actions/activities/activitiesActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import MenuClubMarketplace from '../../../components/menus/MenuClubMarketplace'
import DataClub from '../../../components/Club/DataClub'
import DataSite from '../../../components/Club/DataSite'
import FeatureCard from '../../../components/design/Card/FeatureCard'
import Loader from '../../../components/design/Loader/Loader'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import SidebarClubMarketplace from '../../../components/Club/SidebarClubMarketplace'
import NotFoundPage from '../../error/404'

export default function Club() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { clubUrl } = useParams()
    const {clubMarket: club , loadingclub, resultclub} = useSelector(state=>state.clubs)
    const {activitiesMarket: activities} = useSelector(state=>state.activities)

    const backgroundGeneric = '/images/fondo-auth.jpg'

    const [selectedActivities, setSelectedActivities] = useState()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch, clubUrl])

    useEffect(() =>{
        if(club){
            dispatch(getActivitiesPublicByClub(club?._id))
        }
    }, [dispatch, club])

    useEffect(() => {
        if(activities){
            const activitesRandom = [...activities].sort(() => 0.5 - Math.random())
            setSelectedActivities(activitesRandom.slice(0, 3))
        }
    },[activities])
    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image={club?.bannerMarketplace || backgroundGeneric}
                title={club?.name}
                section={club?.name}
            />
            <Container className='market'>
                {loadingclub ?
                    <Loader/>
                :
                    resultclub === 'thereisnotclub'
                    ?
                        <NotFoundPage />
                    :
                        club &&
                        <Row>
                            <Col xs={12} lg={9}>
                                <Row>
                                    <Col>
                                        <MenuClubMarketplace nuevaSeccion="info" clubUrl={clubUrl} activitybooking={club?.activitybooking} ticketing={club?.ticketing} renting={club?.renting}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="my-3">
                                        <DataClub club={club} />
                                    </Col>
                                </Row>
                                <Row>
                                    {club?.services?.length > 0
                                        ?
                                            <Col xs={12} lg={6} className='my-3'>
                                                <FeatureCard
                                                    title={t('global.services') }
                                                    elements={club?.services}
                                                    translate="services"
                                                    pathIcons="services"
                                                />
                                            </Col>
                                        :
                                            <></>
                                    }
                                    {club?.sports?.length > 0
                                        ?
                                            <Col xs={12} lg={6} className='my-3'>
                                                <FeatureCard
                                                    title={t('global.sports') }
                                                    elements={club?.sports}
                                                    translate="sports"
                                                    pathIcons="sports/iconos"
                                                />
                                            </Col>
                                        :
                                            <></>
                                    }
                                </Row>
                                {club?.sites?.length > 0 ?
                                    club.sites.map((site, index) => (
                                        <Row key={index}>
                                            <Col className='my-3'>
                                                <DataSite site={site} clubWeb={club?.web}/>
                                            </Col>
                                        </Row>
                                    ))
                                :
                                    <></>
                                }
                                {club?.carouselImages?.length > 0 ?
                                    <Row className="my-3 carouselClub">
                                        <Col xs={12}>
                                            <Carousel interval={3000}>
                                                {club?.carouselImages?.map((img, idx) => (
                                                    <Carousel.Item key={idx}>
                                                        <img
                                                            className="d-block w-100"
                                                            src={typeof img === 'string' ? img : URL.createObjectURL(img)}
                                                            alt={`Slide ${idx}`}
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        </Col>
                                    </Row>
                                :
                                    <></>
                                }
                            </Col>
                            <Col xs={12} lg={3} className="my-3">
                                <SidebarClubMarketplace
                                    activitiesfeatured={selectedActivities}
                                    sites={club?.sites}
                                    sponsors={club?.sponsors}
                                    sponsorsLinks={club?.sponsorsLinks}
                                />
                            </Col>
                        </Row>
                }
            </Container>
            <FooterMarketplace />
        </>

    )
}