import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Button, Offcanvas, Dropdown } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import useAuth from "../../context/auth/useAuth"
import { useDispatch, useSelector } from 'react-redux'
import CartActivity from '../Activities/CartActivity'
import CartPassType from '../ticketing/CartPassType'
import CartTicket from '../ticketing/CartTicket'
import CartReservation from '../ticketing/CartReservation'

export default function MenuMarketplace({section}) {
    const { t } = useTranslation()
    const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {items, status} = useSelector(state => state.cart)

    const [showCart, setShowCart] = useState(false)

    
    const [totalToPay, setTotalToPay] = useState(null)
    const [totalSaved, setTotalSaved] = useState(null)
    const [totalCommission, setTotalCommision] = useState(null)

    window.addEventListener('addedToCart', () => {
        setShowCart(true)
    })

    useEffect(() => {
        if (status) {
            dispatch({ type: 'CART_STATUS_RESET' })
        }
    }, [status, dispatch])

    useEffect(() => {
        // let acumulated = 0
        let acumulatedToPay = 0
        let totalCommission = 0
        let totalSaved = 0

        for (const item of items) {
            // Si el artículo es una actividad
            if (item.activity) {
                let priceToPay = 0
                let saved = 0
                let isInRangeToEnroll = false
                const currentDate = new Date()
    
                let registrationPayment = 0
                if (item.group.registrationPayment?.maxdate && new Date(item.group.registrationPayment?.maxdate) >= currentDate) {
                    registrationPayment = parseFloat(item.group.registrationPayment.price)
                }
                const registrationImpact = item.questions
                .reduce((sum, question) => {
                    const registrationPercentageTotal = (parseFloat(registrationPayment) * ((parseFloat(question?.impact?.registrationPercentage || 0)) / 100))
    
                    return parseFloat(sum) + (parseFloat(registrationPercentageTotal)) + (question?.impact?.registrationQuantity || 0);
                }, 0)
    
                if (item.promotions) {
                    let registrationTotal = parseFloat(registrationPayment) + parseFloat(registrationImpact)
                    for (const promotion of item.promotions) {
                        if (registrationTotal > 0) {
                            const promotionPercentageTotal = (parseFloat(registrationTotal) * ((parseFloat(promotion.impact.registrationPercentage || 0)) / 100))
                            registrationTotal -= (parseFloat(promotionPercentageTotal)) + (promotion.impact.registrationQuantity || 0)
                        } else {
                            registrationTotal = 0
                        }
                    }
    
                    if (registrationTotal < 0) {
                        registrationTotal = 0
                    }

                    saved += (parseFloat(registrationPayment) + parseFloat(registrationImpact)) - registrationTotal
                }
    
                const seasonPaymentsTotal = item.group.seasonPayments.reduce((seasonPaymentTotal, seasonPayment) => {
                    if (new Date(seasonPayment.startDate) <= currentDate && new Date(seasonPayment.endDate) >= currentDate) {
                        isInRangeToEnroll = true
                        const seasonPaymentImpact = item.questions
                        .reduce((questionTotal, question) => {
                            const seasonPaymentPercentageTotal = (parseFloat(seasonPayment.price) * ((parseFloat(question?.impact?.seasonPaymentPercentage || 0)) / 100))
            
                            return parseFloat(questionTotal) + (parseFloat(seasonPaymentPercentageTotal)) + (question?.impact?.seasonPaymentQuantity || 0);
                        }, 0)
    
                        let total = parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact)
    
                        if (item.promotions) {
                            for (const promotion of item.promotions) {
                                if (total > 0) {
                                    const promotionPercentageTotal = (parseFloat(total) * ((parseFloat(promotion.impact.seasonPaymentPercentage || 0)) / 100))
                                    total -= (parseFloat(promotionPercentageTotal)) + parseFloat(promotion.impact.seasonPaymentQuantity || 0)
                                } else {
                                    total = 0
                                }
                            }
                            if (total < 0) {
                                total = 0
                            }
                        }
    
                        saved += (parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact)) - total
                        return parseFloat(seasonPaymentTotal) + (parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact) <= 0 ? 0 : parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact))
                    } else {
                        if (currentDate < new Date(seasonPayment.endDate)) {
                            isInRangeToEnroll = true
                        }
                        return seasonPaymentTotal
                    }
                }, 0)
    
                priceToPay = (parseFloat(registrationPayment) + parseFloat(registrationImpact) + parseFloat(seasonPaymentsTotal)) - saved
    
                const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
    
                // acumulated += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * parseFloat(priceComission?.percentage) / 100) + parseFloat(priceComission?.double)) * 100) / 100
    
                if (!item.group.verify && isInRangeToEnroll) {
                    if(!item.waitinglist){
                        if (priceToPay > 0) {
                            totalCommission += Math.ceil((parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                            acumulatedToPay += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                        }
                    }
                }
                totalSaved += parseFloat(saved)
            }

            // Si el artículo es un abono o conjunto de abonos
            if (item.passType) {
                let priceToPay = item.passType.seasons?.find(season => season.season?._id === item.season)?.price || 0

                if (priceToPay > 0) {
                    const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
                    totalCommission += Math.ceil((parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                    acumulatedToPay += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                }
            }

            // Si el artículo es una entrada
            if (item.match) {
                let priceToPay = 0

                if (item.ticket?.type === "pass") {
                    priceToPay = item.match.passTypes?.find(p => p.passType === item.ticket.client?.passType)?.price || 0
                } else {
                    priceToPay = item.match.sectors?.find(s => s.sector === item.ticket.sector._id)?.fees?.find(f => f.fee === item.ticket.fee._id)?.price || 0
                }

                if (priceToPay > 0) {
                    const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
                    totalCommission += Math.ceil((parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                    acumulatedToPay += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                }
            }

            // Si el artículo es una reserva
            if (item.court) {
                if (item.slots && item.slots.length > 0) {
                    let sum = 0
                    item.slots.map((value) => {
                        let price = value?.price
                        if (price > 0) {
                            const sitesCommission = item.commission?.find(sites => price >= sites.lowPrice && price <= sites.highPrice);
                            totalCommission += Math.ceil((parseFloat(price * (sitesCommission?.percentage || 0) / 100) + parseFloat(sitesCommission?.double || 0)) * 100) / 100
                            acumulatedToPay += parseFloat((Math.ceil((parseFloat(price) + parseFloat(price * (sitesCommission?.percentage || 0) / 100) + parseFloat(sitesCommission?.double || 0)) * 100) / 100).toFixed(2))
                            return parseFloat(sum) + parseFloat((Math.ceil((parseFloat(price) + parseFloat(price * (sitesCommission?.percentage || 0) / 100) + parseFloat(sitesCommission?.double || 0)) * 100) / 100).toFixed(2))
                        } else {
                            return sum
                        }
                    })
                }
            }
        }

        // setTotal(acumulated)
        setTotalToPay(acumulatedToPay)
        setTotalCommision(totalCommission)
        setTotalSaved(totalSaved)
    }, [items])
    
    return (
        <>
            <div  className="sticky-top p-3 bg-white m-0 border-bottom border-2 border-dark" id="menuMarket">
                <Container>
                    <Row className='align-items-center justify-content-between'>
                        <Col>
                            <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
                        </Col>
                        {
                            section !== 'operation'
                            ?
                            <>
                                <Col className='d-none d-lg-block'>
                                    <Link to={`/${t("url.marketplace.clubs")}`}>
                                        <Button
                                            className='mx-2'
                                            variant={section === 'clubs' ? 'secondary' : 'default'}>
                                                {t('marketplace.clubs')}
                                        </Button>
                                    </Link>
                                    <Link to={`/${t("url.marketplace.activities")}`}>
                                        <Button
                                            className='mx-2'
                                            variant={section === 'activities' ? 'secondary' : 'default'}>
                                                {t('marketplace.activities')}
                                        </Button>
                                    </Link>
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                    <div className='d-block d-lg-none'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="menu-movil" variant="outline-secondary">
                                                <i className="fa-solid fa-bars"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item to={`/${t("url.marketplace.clubs")}`} as={Link}>{t('marketplace.clubs')}</Dropdown.Item>
                                                <Dropdown.Item to={`/${t("url.marketplace.activities")}`} as={Link}>{t('marketplace.activities')}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div>
                                        <Link to={clientauth?.usertype === 'club' ? `/${t("url.club.club")}/${t("url.club.lobby")}` : `/${t("url.auth.login")}`}>
                                            <Button variant='primary' id="login" className='ms-1'>
                                                <div className='d-none d-lg-inline-block'>
                                                    {clientauth
                                                ?
                                                    t('marketplace.privatearea')
                                                :
                                                    t('marketplace.login')
                                                }
                                                </div>
                                                <img src='/images/login.svg' alt='Login' />
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className='ms-1 position-relative'>
                                        <Button variant='outline-primary'  id="cart" type='button' onClick={() => setShowCart(true)}>
                                            <img src='/images/cart.svg' alt='Login' className='cursor px-1'/>
                                        </Button>
                                        {
                                        items && items.length > 0 &&
                                            <div className='badge'>{items.length}</div>
                                        }
                                    </div>
                                </Col>
                            </>
                            :
                            <></>
                        }
                    </Row>
                </Container>
            </div>
            <Offcanvas show={showCart} onHide={() => setShowCart(false)} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('marketplace.cart')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='cart'>
                    {
                        items?.length > 0
                        ?
                            items?.map((item, index) => (
                                item.group
                                ?
                                    <CartActivity key={index} item={item} index={index}/>
                                :
                                    item.passType
                                    ?
                                        <CartPassType key={index} item={item} index={index}/>
                                    :
                                        item.match
                                        ?
                                            <CartTicket key={index} item={item} index={index}/>
                                        :
                                            item.court
                                            ?
                                                <CartReservation key={index} item={item} index={index}/>
                                            :
                                                <Fragment key={index}/>
                            ))
                        :
                            <h5 className='text-center'>{t('cart.cartEmpty')}</h5>
                    }
                </Offcanvas.Body>
                {items?.length > 0
                ?
                    <div>
                        <div>
                            {
                                totalCommission && totalCommission > 0
                                    ?
                                    <>
                                        <div className='d-flex justify-content-between px-3'>
                                            <h5 className='text-uppercase'>{t('cart.total')}:</h5>
                                            <h5>{(totalToPay-totalCommission)?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</h5>
                                        </div>
                                        <div className='d-flex justify-content-between px-3'>
                                            <h5 className='text-uppercase'>{t('cart.administrationFees')}:</h5>
                                            <h5>{totalCommission?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</h5>
                                        </div>
                                    </>
                                    :
                                        <></>
                            }
                            {
                                totalSaved && totalSaved > 0
                                ?
                                    <div className='d-flex justify-content-between px-3'>
                                        <h5 className='text-uppercase'>{t('cart.totalSaved')}:</h5>
                                        <h5>{totalSaved?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</h5>
                                    </div>
                                :
                                    <></>
                            }
                            <div className='d-flex justify-content-between px-3'>
                                <h3 className='me-3 mb-0 font-single'>{t('cart.totalToPay')}:</h3>
                                <h3 className='font-single'>{totalToPay?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</h3>
                            </div>
                        </div>
                        <div className='d-grid'>
                            <Link to={"/" + t("url.marketplace.checkOut")} className='btn btn-primary m-2'>{t('cart.finishCart')}</Link>
                        </div>
                    </div>
                :
                    <></>
                        }
            </Offcanvas>
        </>
    )
}