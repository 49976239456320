import { Button, Col, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Loader from "../../design/Loader/Loader"
import SectorMap from "../../ticketing/SectorMap"

export default function SectorForm({state, statetype, usertype, isAddingOrEditing, submit, setState}) {
    const { t } = useTranslation()

    const handleInputChange = ({target})=>{
        setState({
            ...state,
            [target.name]: target.value
        })
    }

    const handleNumeratedCheck = ({target}) => {
        const stateCopy = {...state}
        stateCopy[target.name] = target.checked

        if (target.checked) {
            if (!stateCopy.distribution.rows) {
                stateCopy.distribution.rows = "0"
            }

            if (!stateCopy.distribution.columns) {
                stateCopy.distribution.columns = "0"
            }

            stateCopy.maxCapacity = parseInt(stateCopy.distribution.rows) * parseInt(stateCopy.distribution.columns) - (stateCopy.distribution?.deleted?.length || 0)
        }

        setState(stateCopy)
    }

    const handleDistributionInputChange = ({target})=>{
        const distributionCopy = {...state.distribution}
        distributionCopy[target.name] = target.value
        setState({
            ...state,
            distribution: distributionCopy,
            maxCapacity: parseInt(distributionCopy?.rows || 0) * parseInt(distributionCopy?.columns || 0) - (distributionCopy?.deleted?.length || 0)
        })
    }

    const handleSeatClick = (row, column) => {
        const distributionCopy = {...state.distribution}
        const isDeleted = distributionCopy?.deleted?.some(d => d[0] === parseInt(row) + 1 && d[1] === parseInt(column) + 1)

        if (isDeleted) {
            distributionCopy.deleted = distributionCopy.deleted?.filter(d => !(d[0] === parseInt(row) + 1 && d[1] === parseInt(column) + 1))
        } else {
            distributionCopy.deleted = [...distributionCopy.deleted, [parseInt(row) + 1, parseInt(column) + 1]]
        }

        setState({
            ...state,
            distribution: distributionCopy,
            maxCapacity: parseInt(distributionCopy?.rows || 0) * parseInt(distributionCopy?.columns || 0) - (distributionCopy?.deleted?.length || 0)
        })
    }

    return (
        <>
            <Form onSubmit={submit}>
                <Row>
                    <Col>
                        <h3 className="font-single">{t('sectors.data')}</h3>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.name ? 'has-content' : ''}
                                type='text'
                                name='name'
                                value={state?.name ||  ''}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Label htmlFor='name'>{t('sectors.name')}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.maxCapacity ? 'has-content' : ''}
                                type='number'
                                name='maxCapacity'
                                value={state?.maxCapacity ||  ''}
                                onChange={state?.numerated ? () => {} : handleInputChange}
                                min={0}
                                required
                                disabled={state?.numerated}
                            />
                            <Form.Label htmlFor='maxCapacity'>{t('sectors.maxCapacity')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label htmlFor='verify'>
                                {t('sectors.active')}
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('sectors.activeInfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                                    </OverlayTrigger>
                            </Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    className="ms-3"
                                    type="switch"
                                    name="active"
                                    onChange={(e) => {handleInputChange({target: {name: 'active', value: e.target.checked}})}}
                                    checked={state?.active || false}
                                />
                                <span className="ms-3">
                                    {state?.active
                                    ?
                                        <>{t('global.yes')}</>
                                    :
                                        <>{t('global.no')}</>
                                    }
                                </span>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label htmlFor='verify'>
                                {t('sectors.numerated')}
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="tooltip">
                                                    {t('sectors.numeratedInfo')}
                                                </Tooltip>}
                                    >
                                        <img src="/images/info.svg" alt="Info" className="ms-2" />
                                    </OverlayTrigger>
                            </Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    className="ms-3"
                                    type="switch"
                                    name="numerated"
                                    onChange={handleNumeratedCheck}
                                    checked={state?.numerated || false}
                                />
                                <span className="ms-3">
                                    {state?.numerated
                                    ?
                                        <>{t('global.yes')}</>
                                    :
                                        <>{t('global.no')}</>
                                    }
                                </span>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    state.numerated &&
                    <Row className="my-3">
                        <Row className="my-3">
                            <Col xs={12} md={4}>
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={state?.distribution?.rows ? 'has-content' : ''}
                                        type='number'
                                        name='rows'
                                        value={state?.distribution?.rows ||  ''}
                                        onChange={handleDistributionInputChange}
                                        min={0}
                                        required
                                    />
                                    <Form.Label htmlFor='rows'>{t('sectors.rows')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={state?.distribution?.columns ? 'has-content' : ''}
                                        type='number'
                                        name='columns'
                                        value={state?.distribution?.columns ||  ''}
                                        onChange={handleDistributionInputChange}
                                        min={0}
                                        required
                                    />
                                    <Form.Label htmlFor='columns'>{t('sectors.columns')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group className='form-group'>
                                    <Form.Label htmlFor='rows'>{t('sectors.numeration')}</Form.Label>
                                    <Form.Select
                                        className="form-control has-content"
                                        name="numeration" 
                                        value={state?.distribution?.numeration || '0'} 
                                        onChange={handleDistributionInputChange}
                                    >
                                        <option value="0">{t('sectors.evenAndOdd')}</option>
                                        <option value="1">{t('sectors.odd')}</option>
                                        <option value="2">{t('sectors.even')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <SectorMap rows={state?.distribution?.rows} columns={state?.distribution?.columns} numeration={state?.distribution?.numeration} deleted={state?.distribution?.deleted} type="management" action={handleSeatClick}/>
                    </Row>
                }
                {isAddingOrEditing
                    ?
                        <Loader/>
                    :
                        <Row className="mt-5">
                            <Col xs={12} lg={6}>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sectors")}` : `/${t("url.club.club")}/${t("url.club.sectors")}`} className="btn btn-default my-2 w-100">
                                    {t('global.cancel')}
                                </Link>
                            </Col>
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button type="submit" variant="primary" className='my-2 w-100'>
                                    {
                                        statetype === 'add' ? t('global.save') :
                                        statetype === 'edit' ? t('global.edit') : ''
                                    }
                                </Button>
                            </Col>
                        </Row>
                }
            </Form>
        </>
    )    
}
