export const redirectReducer = (state = {swal: false}, action)=>{
    switch (action.type) {
        case 'REDIRECT_INVALIDJWT':
            return {
                ...state,
                swal: true
            }
        case 'REDIRECT_RESET':
            return{
                ...state,
                swal: false
            }
        default:
            return state
    }
}