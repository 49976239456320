import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { addPassType } from '../../../../actions/ticketing/passTypesActions.js'
import { getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import PassTypeForm from '../../../../components/forms/passTypes/PassTypeForm.js'
import { getSeasonsByClub } from '../../../../actions/ticketing/seasonsActions.js'
import { EditorState } from 'draft-js'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions.js'

export default function AddPassType({usertype}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading: passTypesLoading} = useSelector(state=>state.passTypes)
    const {sectors, loading: sectorsLoading} = useSelector(state=>state.sectors)
    const {seasons, loading: seasonsLoading} = useSelector(state=>state.seasons)
	const {club} = useSelector(state=>state.clubs)
	const {commission} = useSelector(state=>state.commissions)

    
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [newPassType, setNewPassType] = useState({
        name: '',
        description: '',
        image: null,
        obverseTemplate: null,
        reverseTemplate: null,
        people: '',
        // sport: null,
        sector: null,
        seasons: []
    })

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newPassType.name !== ''){
            if (newPassType.sector) {
                if (newPassType.people && newPassType.people !== "") {
                    if (newPassType.seasons.length > 0) {
                        dispatch(addPassType({...newPassType, club: club._id}))
                    } else {
                        Swal.fire({
                            showConfirmButton: true,
                            showDenyButton: false,
                            confirmButtonText: t('global.ok'),
                            icon: 'warning',
                            text: t('passTypes.noSeasons')
                        })
                    }
                } else {
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('passTypes.noPeople')
                    })
                }
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.ok'),
                    icon: 'warning',
                    text: t('passTypes.noSector')
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('passTypes.noName')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'PASSTYPE_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('passTypes.addSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.passTypes")}`)
                        }
                    })
                    break;
                case 'duplicatePassType':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('passTypes.duplicatePassType')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (club?._id) {
            dispatch(getSectorsByClub(club?._id))
            dispatch(getSeasonsByClub(club?._id))
            dispatch(getCommissionByClub(club?._id))
        }
    }, [club, dispatch])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('passTypes.createNewPassType')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <PassTypeForm
                                state={newPassType}
                                setState={setNewPassType}
                                statetype={'add'}
                                usertype={usertype}
                                isAdding={passTypesLoading || sectorsLoading || seasonsLoading}
                                submit={handleAdd}
                                sectors={sectors}
                                seasons={seasons}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                commission={commission}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}