import React, { useEffect } from 'react'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/design/Loader/Loader'
import { getCart } from '../../actions/payments/cartActions'
import { useParams } from 'react-router'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Pass from '../../components/downloads/Pass'
export default function CartDownloads() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {cartId, cartReference} = useParams()

    const {loading, error, cart} = useSelector(state => state.cart)

    useEffect(() => {
        if (cartId) {
            dispatch(getCart(cartId, cartReference))
        }
    }, [cartId, cartReference, dispatch])

    return (
        <>
            <MenuMarketplace/>
            <Container className='market'>
                <div>
                    <Card className='border-0'>
                        <Card.Body>
                            {
                                loading &&
                                    <Loader />
                            }
                            {
                                error &&
                                    <Alert variant='danger'>{error}</Alert>
                            }
                            {
                                cart &&
                                    <>
                                        {
                                            cart.passes && cart.passes.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <h2>{t('cart.passes')}</h2>
                                                    <hr className="mb-4"/>
                                                </Col>
                                            </Row>
                                        }
                                        {cart.passes?.map((item, index) => (
                                            <Row key={index} className='border py-4 px-3 rounded my-5'>
                                                <Col xs={12} md={4}>
                                                    <img src={item.passType?.image || `/images/sports/${item.passType?.sport}.jpg`} alt={item.passType?.name} className="w-100"/>
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <Row className='mb-5'>
                                                        <Col xs={8}>
                                                            <h3>{item.passType?.name}</h3>
                                                        </Col>
                                                    </Row>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("passTypes.sector")}</span>
                                                            <span>{item.passType?.sector.name}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-between align-items-end'>
                                                            <span className='fw-bold'>{t("passTypes.season")}</span>
                                                            <span>{item.passType?.season?.season?.name}</span>
                                                        </div>
                                                        <hr className='border my-1'/>
                                                    </div>
                                                    {
                                                        item.passes.map((pass, index) => (
                                                            <div className='mt-3' key={index}>
                                                                <div className='d-flex justify-content-between align-items-end'>
                                                                    <span>
                                                                        <strong>{t("passTypes.marketplacePassPerson", { index: index + 1 })}</strong>
                                                                        {
                                                                            pass.client
                                                                            ?
                                                                                ` ${pass.client?.lastname}, ${pass.client?.firstname}`
                                                                            :
                                                                                ` ${pass.personData?.lastname}, ${pass.personData?.firstname}`
                                                                        }
                                                                    </span>
                                                                    <PDFDownloadLink document={<Pass pass={pass}/>} fileName={`${pass.identifier}.pdf`}>
                                                                        <img src='/images/download.svg' alt='download'/>
                                                                    </PDFDownloadLink>
                                                                </div>
                                                                <hr className='border my-1'/>
                                                            </div>
                                                        ))
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    )
}