import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getActivityPublicByUrl } from '../../../actions/activities/activitiesActions'
import Loader from '../../../components/design/Loader/Loader'
import { Link } from 'react-router-dom'
import Description from './Description'
import Group from './Group'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import UsersRegistered from '../../../components/Banners/UsersRegistered'
import BannerPhrase2 from '../../../components/Banners/BannerPhrase2'
import Suscribete from '../../../components/Banners/Subscribe'
import SidebarSite from '../../../components/Activities/SidebarSite'
import NotFoundPage from '../../error/404'

export default function Activity() {
    const { t } = useTranslation()
    const backgroundGeneric = '/images/fondo-auth.jpg'

	const dispatch = useDispatch()
    const {clubUrl, activityUrl} = useParams()

    const {clubMarket: club, loadingclub, resultclub} = useSelector(state=>state.clubs)
    const {activityMarket: activity, loadingactivity, resultactivity} = useSelector(state=>state.activities)

    const [activeTab, setActiveTab] = useState("group")

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch, clubUrl])

    useEffect(() =>{
        if(club){
            dispatch(getActivityPublicByUrl({url: activityUrl, club: club?._id}))
        }
    // eslint-disable-next-line
    }, [club, activityUrl])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image={club?.bannerMarketplace || backgroundGeneric}
                title={activity?.title}
                section={activity?.title}
            />
            <Container className='market'>
                {
                loadingactivity || loadingclub
                ?
                    <Loader/>
                :
                    resultclub === 'thereisnotclub' || resultactivity === 'thereisnotactivity'
                    ?
                        <NotFoundPage />
                    :
                    club &&
                        <Row>
                            <Col xs={12} lg={9}>
                                <div className='submenu my-3'>
                                    <Nav as="ul">
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("group")} active={activeTab === "group"}>{t('activities.group')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("description")} active={activeTab === "description"}>{t('activities.description')}</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                {
                                    activeTab === "group" &&
                                        <Group activity={activity} club={club}/>
                                }
                                 {
                                    activeTab === "description" &&
                                        <Description activity={activity}/>
                                }
                            </Col>
                            <Col xs={12} lg={3} className="my-3">
                                <SidebarSite club={club} />
                            </Col>
                            <Col xs={12} lg={9}>
                                <div>
                                    <Row className='my-3'>
                                        <Col xs={12} sm={6} className='my-3 my-sm-0'>
                                            <UsersRegistered />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <BannerPhrase2
                                                phrase={t('marketplace.banners.yoursport')}
                                                image="/images/banner-incrono01.jpg"
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row className='h-100'>
                                        <Col xs={12} className='columna1 d-flex flex-column'>
                                            <Suscribete />
                                        </Col>
                                    </Row> */}
                                </div>
                            </Col>
                        </Row>
                }

            </Container>
            <FooterMarketplace className='m-0'/>
        </>


    )
}