import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { getAvailablePassTypesByClub } from '../../actions/ticketing/passTypesActions'
import { getSectorsByClub, getSectorUnavailableSeats } from '../../actions/ticketing/sectorsActions'
import Select from "react-select"
import Loader from '../design/Loader/Loader'
import Swal from 'sweetalert2'
import { getSeasonsByClub } from '../../actions/ticketing/seasonsActions'
import SectorMap from './SectorMap'
import { createPass, getPassesByClub } from '../../actions/ticketing/passesActions'

export default function AddPass({show, closeModal, club}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()

    const {loading: passTypesLoading, passTypes} = useSelector(state => state.passTypes)
    const {loading: sectorsLoading, sectors, unavailableSeats} = useSelector(state => state.sectors)
    const {loading: seasonsLoading, seasons} = useSelector(state => state.seasons)
    const {loading, result} = useSelector(state => state.pass)

    const [passForm, setPassForm] = useState({
        passType: null,
        season: null,
        passes: []
    })

    const handleInputChange = ({target}) => {
        const newPassForm = {...passForm}
        if (target.name === "passType") {
            dispatch(getSectorUnavailableSeats(passTypes?.find(p => p._id === target.value)?.sector))
            const peopleQty = passTypes.find(p => p._id === target.value)?.people
            if (newPassForm.passes.length < peopleQty) {
                do {
                    newPassForm.passes = [...newPassForm.passes, {
                        firstname: null,
                        lastname: null,
                        email: null,
                        phone: null,
                        seat: {
                            row: null,
                            column: null
                        }
                    }]
                } while (newPassForm.passes.length < peopleQty);
            }

            if (newPassForm.passes.length > peopleQty) {
                newPassForm.passes.length = peopleQty
            }
        }
        newPassForm[target.name] = target.value

        setPassForm(newPassForm)
    }

    const handlePersonDataInputChange = (index, target) => {
        setPassForm({
            ...passForm,
            passes: passForm.passes.map((pass, passIndex) => {
                if (index === passIndex) {
                    return {
                        ...pass,
                        [target.name]: target.value
                    }
                } else {
                    return pass
                }
            })
        })
    }

    const handleSeatClick = (index, row, column) => {
        setPassForm({
            ...passForm,
            passes: passForm.passes.map((pass, passIndex) => {
                if (index === passIndex) {
                    return {
                        ...pass,
                        seat: {
                            row,
                            column
                        }
                    }
                } else {
                    return pass
                }
            })
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!passForm.passType || !passForm.season) {
            Swal.fire({
                icon: 'error',
                text: t('passes.createPassFormError'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        } else {
            let passData = true
            let passesToSend = []
            const sectorIsNumerated = sectors?.find(s => s._id === passTypes?.find(p => p._id === passForm.passType)?.sector)?.numerated ? true : false
            let passIndex = 0
            for (const pass of passForm.passes) {
                if ((passIndex === 0 && (!pass.firstname || !pass.email)) || (pass.firstname && !pass.email) || (!pass.firstname && pass.email)) {
                    passData = false
                }

                if (pass.firstname && pass.email && sectorIsNumerated && pass.seat.row === null) {
                    passData = false
                }

                if (pass.firstname && pass.email && (!sectorIsNumerated || (sectorIsNumerated && pass.seat.row !== null))) {
                    passesToSend.push(pass)
                }

                passIndex++
            }

            if (passData) {
                dispatch(createPass({...passForm, club: club._id, passes: passesToSend}))
            } else {
                Swal.fire({
                    icon: 'error',
                    text: t('passes.createPassFormError'),
                    showConfirmButton: true,
                    confirmButtonText: t('global.accept'),
                    timer: 2000
                })
            }
        }
    }

    const handleCloseModal = () => {
        setPassForm({
            passType: null,
            season: null,
            passes: []
        })
        closeModal()
    }

    useEffect(() => {
        if (club) {
            dispatch(getAvailablePassTypesByClub(club._id))
            dispatch(getSectorsByClub(club._id))
            dispatch(getSeasonsByClub(club._id))
        }
    }, [club, dispatch])

    useEffect(() => {
        if (result) {
            let newResult = result
            dispatch({
                type: 'PASS_RESULT_RESET'
            })
            switch (newResult) {
                case 'createPassSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('passes.createPassSuccess')
                    }).then(() => {
                        dispatch(getPassesByClub(club._id))
                        setPassForm({
                            passType: null,
                            season: null,
                            passes: []
                        })
                        closeModal()
                    })
                    break;
                case 'createPassFormError':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'error',
                        text: t('passes.createPassFormError')
                    })
                    break;
                case 'createPassEmailError':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'error',
                        text: t('passes.createPassEmailError')
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result, dispatch, t, club])

    return (
        <Modal show={show} onHide={handleCloseModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('passes.addPass')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body className='cuerpo px-5 py-4'>
                    {
                        passTypesLoading || sectorsLoading || seasonsLoading
                        ?
                            <Loader/>
                        :
                            <>
                                <Row>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className='form-control has-content'
                                                name='passType'
                                                options={passTypes.filter(p => p.deleted !== true && p.valid === true).map(passType => {
                                                    return { label: passType.name, value: passType._id}
                                                })}
                                                value={passForm.passType ? {value: passForm.passType, label: passTypes.find(m => m._id === passForm.passType)?.name} : null}
                                                onChange={(value) => {
                                                    handleInputChange({target: { name: 'passType', value: value.value }})
                                                }}
                                            />
                                            <Form.Label htmlFor='passType' className='px-3'>{t('passes.passType')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className='form-control has-content'
                                                name='season'
                                                options={passTypes.find(m => m._id === passForm.passType)?.seasons?.map(season => {
                                                    const currentSeason = seasons.find(s => s._id === season.season)
                                                    if (currentSeason.deleted === true || currentSeason.numerated === true || season.active === false || new Date(season.endDate) < new Date()) {
                                                        return null
                                                    } else {
                                                        return { value: season.season, label: currentSeason.name }
                                                    }
                                                })?.filter(v => v !== null) || []}
                                                value={passForm.season ? {value: passForm.season, label: seasons.find(s => s._id === passForm.season)?.name} : null}
                                                onChange={(value) => {
                                                    handleInputChange({target: { name: 'season', value: value.value }})
                                                }}
                                            />
                                            <Form.Label htmlFor='season' className='px-3'>{t('passes.season')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    passForm.passes?.map((pass, passIndex) => (
                                        <Card className='my-3' key={passIndex}>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} md={6} className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                type='text'
                                                                name='firstname'
                                                                value={pass.firstname || ''}
                                                                onChange={({target}) => handlePersonDataInputChange(passIndex, target)}
                                                                required={passIndex === 0}
                                                                className={pass.firstname ? 'has-content' : ''}
                                                            />
                                                            <Form.Label htmlFor='firstname' className='px-3'>{t('passes.firstname')}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={6} className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                type='text'
                                                                name='lastname'
                                                                value={pass.lastname || ''}
                                                                onChange={({target}) => handlePersonDataInputChange(passIndex, target)}
                                                                className={pass.lastname ? 'has-content' : ''}
                                                            />
                                                            <Form.Label htmlFor='lastname' className='px-3'>{t('passes.lastname')}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6} className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                type='text'
                                                                name='email'
                                                                required={passIndex === 0}
                                                                value={pass.email || ''}
                                                                onChange={({target}) => handlePersonDataInputChange(passIndex, target)}
                                                                className={pass.email ? 'has-content' : ''}
                                                            />
                                                            <Form.Label htmlFor='email' className='px-3'>{t('passes.email')}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={6} className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                type='text'
                                                                name='phone'
                                                                value={pass.phone || ''}
                                                                onChange={({target}) => handlePersonDataInputChange(passIndex, target)}
                                                                className={pass.phone ? 'has-content' : ''}
                                                            />
                                                            <Form.Label htmlFor='phone' className='px-3'>{t('passes.phone')}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {
                                                    passForm.passType && sectors?.find(s => s._id === passTypes?.find(p => p._id === passForm.passType)?.sector)?.numerated &&
                                                    <SectorMap
                                                        rows={sectors?.find(s => s._id === passTypes?.find(p => p._id === passForm.passType)?.sector)?.distribution?.rows}
                                                        columns={sectors?.find(s => s._id === passTypes?.find(p => p._id === passForm.passType)?.sector)?.distribution?.columns}
                                                        unavailable={unavailableSeats?.unavailable?.map(s => {
                                                            return [s[0] + 1, s[1] + 1]
                                                        }).concat(passForm.passes.map((ticket, index) => {
                                                            if (index === passIndex) {
                                                                return null
                                                            } else {
                                                                return [ticket.seat.row + 1, ticket.seat.column + 1]
                                                            }
                                                        })).filter(s => s !== null)}
                                                        deleted={unavailableSeats?.deleted?.map(s => {
                                                            return [s[0] + 1, s[1] + 1]
                                                        })}
                                                        selected={passForm?.passes[passIndex]?.seat?.row !== null ? [passForm?.passes[passIndex]?.seat.row + 1, passForm?.passes[passIndex]?.seat.column + 1] : null}
                                                        action={(row, column) => {handleSeatClick(passIndex, row, column)}}
                                                    />
                                                }
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                                {
                                    passForm.passType && passForm.season &&
                                    <Row className='mt-5'>
                                        <Col xs={6} md={4} className='offset-xs-6 offset-md-8 py-3 fw-bold border rounded group-price text-center'>
                                            {passTypes?.find(p => p._id === passForm.passType)?.seasons?.find(s => s.season === passForm.season)?.price} €
                                        </Col>
                                    </Row>
                                }
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button type='button' variant='default' onClick={handleCloseModal}>{t('global.cancel')}</Button>
                    <Button type='submit' variant='primary'>
                        {
                            loading
                            ?
                                <Loader/>
                            :
                                t('global.save')
                        }
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
	)
}