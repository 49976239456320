import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'

export default function CookiesPolicy () {
    const { t } = useTranslation()
	useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
    }, [])

	return (
		<>
			<MenuMarketplace/>
			<Container className='market px-3'>
				<Row>
					<Col className='mx-auto'>
						<h1>{t('legalinformation.cookiespolicy.title1')}</h1>
						<div>{t('legalinformation.cookiespolicy.description1')}</div>
					</Col>
				</Row>
				<Row className='mt-5'>
					<Col className='mx-auto'>
						<h2>{t('legalinformation.cookiespolicy.subtitle1')}</h2>
						<div>{t('legalinformation.cookiespolicy.subdescription1')}</div>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col className='mx-auto'>
						<h2>{t('legalinformation.cookiespolicy.subtitle2')}</h2>
						<div>{t('legalinformation.cookiespolicy.subdescription2')}</div>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col className='mx-auto'>
						<h2>{t('legalinformation.cookiespolicy.subtitle3')}</h2>
						<div>{t('legalinformation.cookiespolicy.subdescription3')}</div>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col className='mx-auto'>
						<h2>{t('legalinformation.cookiespolicy.subtitle4')}</h2>
						<div>{t('legalinformation.cookiespolicy.subdescription4')}</div>
					</Col>
				</Row>
				<Row className='my-4'>
					<Col className='mx-auto'>
						<h2 className='display-7'>{t('legalinformation.cookiespolicy.title2')}</h2>
						<div>{t('legalinformation.cookiespolicy.description2')}</div>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col className='mx-auto'>
						<h2>{t('legalinformation.cookiespolicy.subtitle5')}</h2>
						<ol>
							<li>{t('legalinformation.cookiespolicy.subsubtitle5')}
								<ol type="a">
									<li>{t('legalinformation.cookiespolicy.description5a')}</li>
									<li>{t('legalinformation.cookiespolicy.description5b')}</li>
								</ol>
							</li>
							<li>{t('legalinformation.cookiespolicy.subsubtitle6')}
								<ol type="a">
									<li>{t('legalinformation.cookiespolicy.description6a')}</li>
									<li>{t('legalinformation.cookiespolicy.description6b')}</li>
									<li>{t('legalinformation.cookiespolicy.description6c')}</li>
									<li>{t('legalinformation.cookiespolicy.description6d')}</li>
								</ol>
							</li>
							<li>{t('legalinformation.cookiespolicy.subsubtitle7')}
								<ol type="a">
									<li>{t('legalinformation.cookiespolicy.description7a')}</li>
									<li>{t('legalinformation.cookiespolicy.description7b')}</li>
								</ol>
							</li>
						</ol>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col className='mx-auto'>
						<h2>{t('legalinformation.cookiespolicy.title3')}</h2>
						<ol>
							<li>{t('legalinformation.cookiespolicy.subsubtitle8')}
								<div>
									{t('legalinformation.cookiespolicy.description8')}
								</div>
							</li>
							<li>{t('legalinformation.cookiespolicy.subsubtitle9')}
								<div>
									{t('legalinformation.cookiespolicy.description9')}
								</div>
							</li>
							<li>{t('legalinformation.cookiespolicy.subsubtitle10')}
								<div>
									{t('legalinformation.cookiespolicy.description10a')}
								</div>
								<div className='my-3'>
									{t('legalinformation.cookiespolicy.description10b')}
								</div>
								<div>
									<div dangerouslySetInnerHTML={{ __html: t('legalinformation.cookiespolicy.description10chrome')}}></div>
									<div dangerouslySetInnerHTML={{ __html: t('legalinformation.cookiespolicy.description10explorer')}}></div>
									<div dangerouslySetInnerHTML={{ __html: t('legalinformation.cookiespolicy.description10firefox')}}></div>
									<div dangerouslySetInnerHTML={{ __html: t('legalinformation.cookiespolicy.description10safari')}}></div>
									<div dangerouslySetInnerHTML={{ __html: t('legalinformation.cookiespolicy.description10opera')}}></div>
								</div>
							</li>
						</ol>
					</Col>
				</Row>
				<Row className='my-4'>
					<Col className='mx-auto'>
						<h2 className='display-7'>{t('legalinformation.cookiespolicy.title4')}</h2>
						<div>{t('legalinformation.cookiespolicy.description4')} <Link to={`/${t("url.marketplace.privacypolicy")}`}>{t('global.link')}</Link></div>
					</Col>
				</Row>
				<Row className='my-4'>
					<Col className='mx-auto'>
						<h2 className='display-7'>{t('legalinformation.cookiespolicy.title5')}</h2>
						<div>{t('legalinformation.cookiespolicy.description5')}</div>
					</Col>
				</Row>
			</Container>
			<FooterMarketplace className='m-0'/>
		</>
	)
}