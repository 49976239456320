import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getReservationsByClient } from '../../../actions/sites/reservationsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../context/auth/useAuth'
import { Link } from 'react-router-dom'
import Loader from '../../../components/design/Loader/Loader'

export default function Reservations({ usertype, clientselected }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { clientauth } = useAuth()
    const { reservations, loadingreservations } = useSelector(state => state.reservations)

    useEffect(() => {
        if (clientauth) {
            dispatch(getReservationsByClient(clientselected))
        }
    }, [dispatch, t, clientauth, clientselected])

    // Separar la lógica para formatear fecha y horas
    const formatDate = (reservation) => {
        if (!reservation) return ""
        const date = new Date(reservation.date)
        return new Intl.DateTimeFormat('es-ES').format(date) // Formato DD/MM/YYYY
    }

    const formatTime = (reservation) => {
        if (!reservation) return ""
        const formattedStartHour = new Date(`1970-01-01T${reservation.startHour}`).toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
        })
        const formattedEndHour = new Date(`1970-01-01T${reservation.endHour}`).toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
        })
        return `${formattedStartHour} - ${formattedEndHour}`
    }

    const columnsReservations = [
        {
            name: t('reservations.date'),
            cell: reservation =>
                <Link className='table-link'>
                    {formatDate(reservation)}
                </Link>,
            selector: (reservation) => reservation?.date,
            sortable: false,
        }, {
            name: t('reservations.hour'),
            cell: reservation =>
                <Link className='table-link'>
                    {formatTime(reservation)}
                </Link>,
            selector: (reservation) => reservation?.startHour,
            sortable: false,
        }, {
            name: t('reservations.court'),
            center: true,
            cell: reservation =>
                reservation?.court?.name,
            selector: (reservation) => reservation?.court?.name,
            sortable: false,
            hide: 'sm',
        }, {
            name: t('reservations.status'),
            center: true,
            cell: reservation =>
                t('reservations.status' + reservation?.status),
            selector: (reservation) => reservation?.status,
            sortable: false,
            hide: 'sm',
        }, {
            name: t('reservations.price'),
            center: true,
            width: '80px',
            cell: reservation =>
                reservation?.price+' €',
            selector: (reservation) => reservation?.price,
            sortable: false,
        }
    ]

    return (
        <>
            <Container className='cuerpo'>
                <Row>
                    <Col className='d-flex align-items-center'>
                        <h1 className='ms-2 my-0'>{t('menus.sites.reservations')}</h1>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Card>
                            {loadingreservations
                                ? <Loader />
                                : <Card.Body>
                                    <div className="my-3">
                                        <Row>
                                            <Col>
                                                {reservations?.length > 0 ?
                                                    <CustomDataTable columns={columnsReservations} data={reservations} exportable={false} printable={false} />
                                                    :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('reservations.thereisnotreservations')}</h4>
                                                        {t('reservations.thereisnotreservationsphrase')}
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}