import React, { useEffect } from 'react'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/design/Loader/Loader'
import { getCart } from '../../actions/payments/cartActions'
import { useParams } from 'react-router'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Ticket from '../../components/downloads/Ticket'
import MultipleTickets from '../../components/downloads/MultipleTickets'
import Pass from '../../components/downloads/Pass'
import { getEntryDownload, getEntryInvitationDownload } from '../../actions/downloads/downloadsActions'

export default function Download() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {loading, error, cart} = useSelector(state => state.cart)
    const {downloadentry, downloadtype, downloadEntries} = useSelector(state => state.downloads)
    const {cartId, cartReference, passId, passIdentifier, ticketId, ticketIdentifier, invitationId, invitationIdentifier} = useParams()

    useEffect(() => {
        if (cartId) {
            dispatch(getCart(cartId, cartReference))
        }
        if (passId || ticketId) {
            let entryId = passId || ticketId
            let entryIdentifier = passIdentifier || ticketIdentifier

            dispatch(getEntryDownload(entryId, entryIdentifier))
        }
        if (invitationId) {
            dispatch(getEntryInvitationDownload(invitationId, invitationIdentifier))
        }
    }, [cartId, cartReference, passId, passIdentifier, ticketId, ticketIdentifier, invitationId, invitationIdentifier, dispatch])

    return (
        <>
            <MenuMarketplace/>
            <Container className='market'>
                <div>
                    <Card className='border-0'>
                        <Card.Body>
                            <h2>{t("cart.downloads")}</h2>
                            <hr className="mb-4"/>
                            {
                                loading &&
                                    <Loader />
                            }
                            {
                                error &&
                                    <Alert variant='danger'>{error}</Alert>
                            }
                            {
                                cart &&
                                    <>
                                        {cart?.passes?.map((item) => (
                                            item?.passes.map((item, index) => (
                                                <Card key={index} className='my-3'>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <Row className='mb-5'>
                                                                <Col xs={12}>
                                                                    <h2>{t("club.pass")}</h2>
                                                                    <h3>{item.passType?.name}</h3>
                                                                </Col>
                                                            </Row>
                                                            <div className='mt-3'>
                                                                <div className='d-flex justify-content-between align-items-end'>
                                                                    <span className='fw-bold'>{t("passTypes.sector")}</span>
                                                                    <span>{item.sector?.name}</span>
                                                                </div>
                                                                <hr className='border my-1'/>
                                                            </div>
                                                            <div className='mt-3' key={index}>
                                                                <div className='d-flex justify-content-between align-items-end'>
                                                                    <span className='fw-bold'>{t("passTypes.marketplacePassPerson", { index: index + 1 })}</span>
                                                                    {
                                                                        item.client
                                                                        ?
                                                                            <span>{item.client?.lastname}, {item.client?.firstname}</span>
                                                                        :
                                                                            <span>{item.personData?.lastname}, {item.personData?.firstname}</span>
                                                                    }
                                                                </div>
                                                                <hr className='border my-1'/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='justify-content-end'>
                                                        <Col xs={12} md={4}>
                                                            <PDFDownloadLink document={<Pass pass={item}/>} fileName={`${item.identifier}.pdf`}>
                                                                <Button type='button' className='w-100 my-2' variant='default'>
                                                                    <img src='/images/download.svg' alt='download'/>
                                                                </Button>
                                                            </PDFDownloadLink>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            ))
                                        ))}
                                        {cart.tickets?.map((item, index) => (
                                            <Card key={index} className='my-3'>
                                                <Row key={index} >
                                                    <Col xs={12}>
                                                        <Row className='mb-5'>
                                                            <Col xs={12}>
                                                                <h2>{t("club.ticket")}</h2>
                                                                <h3>{item.ticket?.match?.host} - {item.ticket?.match?.visitor}</h3>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            !item.ticket?.pass
                                                                ?
                                                                    <>
                                                                        <div className='mt-3'>
                                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                                <span className='fw-bold'>{t("matches.sector")}</span>
                                                                                <span>{item.ticket?.sector?.name}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                                <span className='fw-bold'>{t("matches.client")}</span>
                                                                                <span>{`${item.ticket?.personData?.firstname} ${item.ticket?.personData?.lastname || ''}`}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </div>
                                                                    </>
                                                                :
                                                                    <div className='mt-3'>
                                                                        <div className='d-flex justify-content-between align-items-end'>
                                                                            <span className='fw-bold'>{t("matches.passPerson")}</span>
                                                                            <span>{`${item.ticket?.personData?.firstname} ${item.ticket?.personData?.lastname || ''}`}</span>
                                                                        </div>
                                                                        <hr className='border my-1'/>
                                                                    </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row className='justify-content-end'>
                                                    <Col xs={12} md={4}>
                                                        <PDFDownloadLink document={<Ticket ticket={item.ticket}/>} fileName={`${item?.ticket.identifier}.pdf`}>
                                                            <Button type='button' className='w-100 my-2' variant='default'>
                                                                <img src='/images/download.svg' alt='download'/>
                                                            </Button>
                                                        </PDFDownloadLink>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))}
                                    </>
                            }
                            {
                                downloadentry &&
                                    <>
                                        {downloadtype === "Pass" &&
                                            <Card className='my-3'>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Row className='mb-5'>
                                                            <Col xs={12}>
                                                                <h2>{t("club.pass")}</h2>
                                                                <h3>{downloadentry.passType?.name}</h3>
                                                            </Col>
                                                        </Row>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("passTypes.sector")}</span>
                                                                <span>{downloadentry.sector?.name}</span>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("passTypes.name")}</span>
                                                                {
                                                                    downloadentry.client
                                                                    ?
                                                                        <span>{downloadentry.client?.lastname}, {downloadentry.client?.firstname}</span>
                                                                    :
                                                                        <span>{downloadentry.personData?.lastname}, {downloadentry.personData?.firstname}</span>
                                                                }
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='justify-content-end'>
                                                    <Col xs={12} md={4}>
                                                        <PDFDownloadLink document={<Pass pass={downloadentry}/>} fileName={`${downloadentry.identifier}.pdf`}>
                                                            <Button type='button' className='w-100 my-2' variant='default'>
                                                                <img src='/images/download.svg' alt='download'/>
                                                            </Button>
                                                        </PDFDownloadLink>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        }
                                        {downloadtype === "Ticket" &&
                                            <Card className='my-3'>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Row className='mb-5'>
                                                            <Col xs={12}>
                                                                <h2>{t("club.ticket")}</h2>
                                                                <h3>{downloadentry?.match?.host} - {downloadentry?.match?.visitor}</h3>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            !downloadentry?.pass
                                                                ?
                                                                    <>
                                                                        <div className='mt-3'>
                                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                                <span className='fw-bold'>{t("matches.sector")}</span>
                                                                                <span>{downloadentry?.sector?.name}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                                <span className='fw-bold'>{t("matches.client")}</span>
                                                                                <span>{`${downloadentry?.personData?.firstname} ${downloadentry?.personData?.lastname || ''}`}</span>
                                                                            </div>
                                                                            <hr className='border my-1'/>
                                                                        </div>
                                                                    </>
                                                                :
                                                                    <div className='mt-3'>
                                                                        <div className='d-flex justify-content-between align-items-end'>
                                                                            <span className='fw-bold'>{t("matches.passPerson")}</span>
                                                                            <span>{`${downloadentry?.personData?.firstname} ${downloadentry?.personData?.lastname || ''}`}</span>
                                                                        </div>
                                                                        <hr className='border my-1'/>
                                                                    </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row className='justify-content-end'>
                                                    <Col xs={12} md={4}>
                                                        <PDFDownloadLink document={<Ticket ticket={downloadentry}/>} fileName={`${downloadentry?.identifier}.pdf`}>
                                                            <Button type='button' className='w-100 my-2' variant='default'>
                                                                <img src='/images/download.svg' alt='download'/>
                                                            </Button>
                                                        </PDFDownloadLink>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        }
                                    </>
                            }
                            {downloadEntries && downloadtype === "Invitation" &&
                                <Card className='my-3'>
                                    <Row>
                                        <Col xs={12}>
                                            <Row className='mb-5'>
                                                <Col xs={12}>
                                                    <h2>{t("club.invitations")}</h2>
                                                    <h3>{downloadEntries[0]?.match?.host} - {downloadEntries[0]?.match?.visitor}</h3>
                                                </Col>
                                            </Row>
                                            {
                                                !downloadentry?.pass
                                                    ?
                                                        <>
                                                            <div className='mt-3'>
                                                                <div className='d-flex justify-content-between align-items-end'>
                                                                    <span className='fw-bold'>{t("matches.sector")}</span>
                                                                    <span>{downloadEntries[0]?.sector?.name}</span>
                                                                </div>
                                                                <hr className='border my-1'/>
                                                            </div>
                                                            <div className='mt-3'>
                                                                <div className='d-flex justify-content-between align-items-end'>
                                                                    <span className='fw-bold'>{t("matches.client")}</span>
                                                                    <span>{`${downloadEntries[0]?.personData?.firstname} ${downloadEntries[0]?.personData?.lastname || ''}`}</span>
                                                                </div>
                                                                <hr className='border my-1'/>
                                                            </div>
                                                        </>
                                                    :
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-between align-items-end'>
                                                                <span className='fw-bold'>{t("matches.passPerson")}</span>
                                                                <span>{`${downloadEntries[0]?.personData?.firstname} ${downloadEntries[0]?.personData?.lastname || ''}`}</span>
                                                            </div>
                                                            <hr className='border my-1'/>
                                                        </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-end'>
                                        <Col xs={12} md={4}>
                                            <PDFDownloadLink document={<MultipleTickets tickets={downloadEntries}/>} fileName={`${downloadEntries.reduce((sum, ticket) => { return sum + '-' + ticket.identifier }, '')}.pdf`}>
                                                <Button type='button' className='w-100 my-2' variant='default'>
                                                    <img src='/images/download.svg' alt='download'/>
                                                </Button>
                                            </PDFDownloadLink>
                                        </Col>
                                    </Row>
                                </Card>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    )
}