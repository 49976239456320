import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getSitesByClub } from '../../../../actions/sites/sitesActions'
import CourtForm from '../../../../components/forms/courts/CourtForm'
// import { getZonesBySite } from '../../../../actions/sites/zonesActions'
import { addCourt, editCourt, getCourt } from '../../../../actions/sites/courtsActions'
import SportsComponent from '../../../../components/sports'

export default function AddCourt({usertype, selectedCourt, handleCourtSelect}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const {loading: loadingSite, sites} = useSelector(state => state.sites)
    // const {loading: loadingZone, zones} = useSelector(state => state.zones)
    const {loadingcourt, result, court} = useSelector(state => state.courts)
    const {club} = useSelector(state=>state.clubs)

	const {sports} = SportsComponent()
    const [sportsAux, setSportsAux] = useState([])

    const [courtData, setCourtData] = useState({
        name: '',
        image: null,
        // zone: null,
        site: null,
        sports: [],
        rentable: true,
        active: true,
        onwardsDays: 7,
        freeCancelDays: 1
    })

    const [siteOptions, setSiteOptions] = useState([])
    // const [zoneOptions, setZoneOptions] = useState([])

    const {courtId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    const handleInputChange = ({target})=>{
        setCourtData({
            ...courtData,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setCourtData({
            ...courtData,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        // if (selectType === 'site') {
        //     dispatch(getZonesBySite(target.value))
        // }
        setCourtData({
            ...courtData,
            [selectType]: target
        })
    }
    const handleImage = (e)=>{
        setCourtData({
            ...courtData,
            image: e.target.files[0]
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(courtData.name !== '' || courtData.address === '' || !courtData.province || courtData.town !== ""){
            let sportsToSend = courtData.sports?.map(sport => {
                return sport.value
            }) || []

            if (!courtId) {
                dispatch(addCourt({...courtData, site: courtData.site.value, sports: sportsToSend}))
            } else {
                dispatch(editCourt({...courtData, site: courtData.site.value, sports: sportsToSend}, courtId))
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('courts.formNotFinished')
            })
        }
    }

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    handleCourtSelect(result.court?._id)
                    dispatch({
                        type: 'COURT_RESET_RESULT'
                    })
                    if(usertype === 'superadmin'){
                        navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}/${t("url.admin.edit")}/${t("url.admin.step2")}`)
                    }else{
                        navigate(`/${t("url.club.club")}/${t("url.club.courts")}/${t("url.club.edit")}/${t("url.club.step2")}`)
                    }
                    break;
                case 'duplicatename':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('courts.duplicatename')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(()=>{
        if (courtId && court) {
            let sportsSaved = []

            if(court?.sports?.length > 0){
                for (const sport of court?.sports) {
                    sportsSaved.push(sports.find(s => s.value === sport))
                }
            }

            setCourtData({
                name: court.name,
                image: court.image || null,
                // zone: {value: court.zone._id, label: court.zone.name},
                site: {value: court.site._id, label: court.site.name},
                sports: sportsSaved,
                rentable: court.rentable || false,
                active: court.active || false,
                onwardsDays: court.onwardsDays,
                freeCancelDays: court.freeCancelDays
            })
            setSportsAux(sports)
        }
    //eslint-disable-next-line
    }, [court])

    useEffect(() => {
        if (courtId) {
            dispatch(getCourt(courtId))
        }
    }, [courtId, dispatch])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (club) {
            dispatch(getSitesByClub(club?._id))
            setSportsAux(sports)
        }
        // eslint-disable-next-line
    }, [club])

    useEffect(() => {
        setSiteOptions(sites.map(site => {
            return {label: site.name, value: site._id}
        }))
    }, [sites])

    // useEffect(() => {
    //     setZoneOptions(zones.map(zone => {
    //         return {label: zone.name, value: zone._id}
    //     }))
    // }, [zones])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('club.courts')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('courts.createCourt')}</h2>
                            <hr className='mb-4'/>
                            <CourtForm
                                state={courtData}
                                statetype={'add'}
                                usertype={usertype}
                                isAdding={loadingSite || loadingcourt}
                                submit={handleAdd}
                                sports={sportsAux}
                                inputChange={handleInputChange}
                                checkboxChange={handleCheckboxChange}
                                selectChange={handleSelectChange}
                                handleImage={handleImage}
                                sites={siteOptions}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}