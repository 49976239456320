import React from 'react'
import { Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function ClubBreadCrumbs({usertype, club}) {
    const { t } = useTranslation()

    return (
            club?._id
            ?
                <Row className='mb-2 ms-1'>
                    <Col>
                        {
                            usertype === 'superadmin'
                            ?
                                <>
                                    <Link to={`/${t("url.admin.admin")}/${t("url.admin.clubs")}`} className='link'>{t("menus.general.clubs")}</Link> / {club?.name}
                                </>
                            :
                                <>
                                    <Link to={`/${t("url.club.club")}`} className='link'>{t("menus.general.dashboard")}</Link> / {club?.name}
                                </>
                        }

                    </Col>
                </Row>
            :
                <></>
    )
}