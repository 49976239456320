export const downloadsReducer = (state = {loadingdownload: true, downloadentry: null, downloadtype: null, resultdownload: null, downloadEntries: null} , action) => {
    switch (action.type) {
        case 'DOWNLOAD_GETENTRY_SUCCESS':
            return {
                ...state,
                loadingdownload: false,
                downloadentry: action.payload.entry,
                downloadtype: action.payload.entryType
            }
        case 'DOWNLOAD_GET_INVITATION_ENTRY_SUCCESS':
            return {
                ...state,
                loadingdownload: false,
                downloadtype: action.payload.entryType,
                downloadEntries: action.payload.entries
            }
        case 'DOWNLOAD_SENDEMAIL_SUCCESS':
            return {
                ...state,
                loadingdownload: false,
                resultdownload: action.payload.message
            }
        case 'DOWNLOAD_LOADING':
            return {
                ...state,
                loadingdownload: true
            }
        case 'DOWNLOAD_NOT_LOADING':
            return {
                ...state,
                loadingdownload: false
            }
        case 'DOWNLOAD_RESET_RESULT':
            return {
                ...state,
                resultdownload: null
            }
        default:
            return state
    }
}