import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, CardBody, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import { getPromotionsByClub, deletePromotion, changeActiveStatus } from '../../../../actions/payments/promotionsActions'
import Swal from 'sweetalert2'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'

export default function Promotions({usertype, handlePromotionSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {promotions, result} = useSelector(state => state.promotions)
    const {club} = useSelector(state=>state.clubs)

    useEffect(() => {
        handlePromotionSelect(null)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club?._id){
            dispatch(getPromotionsByClub(club?._id))
        }
        //eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(result?.message){
            dispatch({
                type: 'PROMOTION_RESET_RESULT'
            })
            switch (result.message) {
                case 'editedSuccess':
                    dispatch(getPromotionsByClub(club?._id))
                    break;
                case 'deletePromotionWrong':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'error',
                        text: t('promotions.deletePromotionWrong')
                    })
                    break;
                case 'deletePromotionSuccess':
                    dispatch(getPromotionsByClub(club?._id))
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'success',
                        text: t('promotions.deletePromotionSuccess')
                    })
                    break;
                case 'changeActiveStatusSuccess':
                    dispatch(getPromotionsByClub(club?._id))
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'success',
                        text: t('promotions.changeActiveStatusSuccess')
                    })
                    break;
                default:
                    break;
            }
        }
        //eslint-disable-next-line
    }, [result, dispatch])

    const handleStatusChange = (data) => {
        dispatch(changeActiveStatus(data))
    }

    const handleRemovePromotion = (e, promotionId) => {
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: false,
            confirmButtonText: t('global.accept'),
            icon: 'warning',
            text: t('promotions.editConformText')
        }).then((newresult) => {
            if (newresult.isConfirmed) {
                dispatch(deletePromotion(promotionId))
            }
        })
    }

	const columnsPromotions = [{
            name: t('promotions.name'),
            cell: promotion =>
                <Link onClick={() => handlePromotionSelect(promotion._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.promotions")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.promotions")}/${t("url.club.edit")}`} className='table-link'>
                    {promotion.name}
                </Link>,
            selector: (promotion) => promotion.name,
            sortable: true,
        },{
            name: t('promotions.exclusive'),
            cell: promotion =>
                <div>
                    {promotion.exclusive ? t('global.yes') : t('global.no')}
                </div>,
            selector: (promotion) => promotion.exclusive ? t('global.yes') : t('global.no'),
            sortable: true,
        },{
            name: t('promotions.discount'),
            cell: promotion =>
                <div>
                    <p>{t('promotions.registrationQuantity')}: {promotion.impact.registrationQuantity}</p>
                    <p>{t('promotions.registrationPercentage')}: {promotion.impact.registrationPercentage}</p>
                    <p>{t('promotions.seasonPaymentQuantity')}: {promotion.impact.seasonPaymentQuantity}</p>
                    <p>{t('promotions.seasonPaymentPercentage')}: {promotion.impact.seasonPaymentPercentage}</p>
                </div>,
            selector: (promotion) => JSON.stringify(promotion.impact),
            sortable: true,
        },{
            name: t('promotions.active'),
            center: 'true',
            width: '100px',
            cell: promotion =>
                <div>
                    <Form.Check
                        type='switch'
                        checked={promotion.active}
                        onChange={({target}) => (handleStatusChange({id: promotion._id, active: target.checked}))}
                    />
                </div>,
            selector: (promotion) => promotion.active,
            sortable: false,
        },{
			name: t('global.actions'), 
            center: 'true',
            width:'120px',
			cell: promotion =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='click'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handlePromotionSelect(promotion._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.promotions")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.promotions")}/${t("url.club.edit")}`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={(e) => handleRemovePromotion(e, promotion._id)} className='dropdown-item'>						
                                <img src="/images/trash.svg" alt="trash" width={15} /> {t('global.delete')}
					        </Link>                            
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className='my-3'>
                <Col>
                    <Card>
                        <CardBody>
                            <h2>{t('promotions.promotionsList')}</h2>
                            <hr className='mb-4'/>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.promotions")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.promotions")}/${t("url.admin.new")}`} className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('promotions.createPromotion')}
                                </Link>
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            promotions?.length > 0
                                                ?
                                                    <CustomDataTable columns={columnsPromotions} data={promotions} exportable={false} printable={false} />
                                                :
                                                    <div className='contenedor-vacio text-center'>
                                                        <h4>{t('promotions.noPromotions')}</h4>
                                                        {t('promotions.thereIsNoPromotionsPhrase')}
                                                        <div className='mt-3'>
                                                            <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.promotions")}/${t("url.admin.new")}` : `/${t("url.club.club")}/${t("url.club.promotions")}/${t("url.admin.new")}`} className='btn btn-secondary'>
                                                                {t('promotions.createPromotion')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}