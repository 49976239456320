import React from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { retryPayment } from '../../actions/payments/paymentActions'

export default function ManualPayment({userType = "club", payment, onHide, inscriptionBank = false}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleRetryButton = () => {
        dispatch(retryPayment(payment._id))
        onHide()
    }

    return (
        <Modal show={payment ? true : false} onHide={() => onHide()} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('payments.manualPayment')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cuerpo px-5 py-4'>
                <div>
                    {
                        payment
                            ?
                                <>
                                    <Row className="my-2 p-2 rounded">
                                        <Col xs={12}>
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('inscriptions.paymentreference')}</small> {payment?.paymentReference}
                                            </div>
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('inscriptions.quantity')}</small> {payment?.quantity} €
                                            </div>
                                            {
                                                payment?.refunded &&
                                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                        <small>{t('payments.refunded')}</small> {payment?.refunded} €
                                                    </div>
                                            }
                                            <div className='d-flex justify-content-between border-bottom p-2'>
                                                <small>{t('payments.refType')}:</small> {payment.refType && payment.ref ? t('payments.' + payment.refType) : t('payments.cardRegistration')}
                                            </div>
                                            {
                                                (payment.refType === "Inscription" && inscriptionBank === false)
                                                ?
                                                <>
                                                    <div className='border-bottom p-2'>
                                                        <small>{t('payments.' + payment.refType)}:</small><br/>{payment.ref?.activity}
                                                    </div>
                                                    <div className='d-flex justify-content-between border-bottom p-2'>
                                                        <small>{t('payments.category')}:</small> {payment.ref?.category}
                                                    </div>
                                                    <div className='d-flex justify-content-between border-bottom p-2'>
                                                        <small>{t('payments.group')}:</small> {payment.ref?.group}
                                                    </div>
                                                </>
                                                :
                                                    <></>
                                            }
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('payments.member')}:</small> {payment.client ? `${payment.client?.lastname}, ${payment.client?.firstname}` : '-'}
                                            </div>
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('payments.paymentClient')}:</small> {payment.paymentClient ? `${payment.paymentClient?.lastname}, ${payment.paymentClient?.firstname}` : '-'}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            :
                                <></>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" type="button" onClick={() => {onHide()}}>{t('global.cancel')}</Button>
                <Button variant="primary" type="button" onClick={() => {handleRetryButton()}}>{t('payments.retryPayment')}</Button>
            </Modal.Footer>
        </Modal>
	)
}