import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getClientByUser = (user) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/client/getclientbyuser/'+ user, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLIENT_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const getClients = () => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/client/getall/', {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLIENT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const getClient = (user) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/client/get/'+ user, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLIENT_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const addMember = (userdata) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/client/addmember', userdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'CLIENT_ADDMEMBER_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const getActivateDeactivate = (memberId, parentId) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/client/activatedeactivate/' + memberId, {parentId: parentId}, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'CLIENT_ACTIVATEDEACTIVATE_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const editCard = (clientId, cardData) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/client/editCard/' + clientId, cardData, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'CLIENT_CARD_SUCCESS',
                payload: data.message
            })
        } else {
            dispatch({
                type: 'CLIENT_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const deleteCard = (clientId, cardId) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status} = await Axios.delete('/api/client/deleteCard/' + clientId + '/' + cardId, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'CLIENT_CARD_SUCCESS',
                payload: data.message
            })
        } else {
            dispatch({
                type: 'CLIENT_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const changeFavouriteCard = (clientId, cardId) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/client/changeFavouriteCard/' + clientId + '/' + cardId, {}, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'CLIENT_CARD_SUCCESS',
                payload: data.message
            })
        } else {
            dispatch({
                type: 'CLIENT_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}

export const changeClientActive = (id, statusActive) => async (dispatch) => {
    dispatch({
        type: 'CLIENT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/client/changeactive/' + id, {useractive: statusActive}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'CLIENT_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLIENT_NOT_LOADING'
        })
    }
}