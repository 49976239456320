import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getGroup = (group) => async (dispatch) => {
    dispatch({
        type: 'GROUP_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/group/get/'+ group, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'GROUP_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}

export const getGroupsByClub = (club, isImport = false) => async (dispatch) => {
    dispatch({
        type: 'GROUP_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/group/getgroupsbyclub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: isImport ? 'GROUP_GETALL_IMPORTS_SUCCESS' : 'GROUP_GETALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'GROUP_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}

export const importGroupsByClub = (groupstoimport, club) => async (dispatch) => {
    dispatch({
        type: 'GROUP_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/group/importgroupsbyclub/' + club, {groupstoimport: groupstoimport}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'GROUP_IMPORT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}

export const getGroupsPublicByActivity = (activity) => async (dispatch) => {
    dispatch({
        type: 'GROUP_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/group/public/getgroupspublicbyactivity/'+ activity)

        if (status === 200) {
            dispatch({
                type: 'GROUP_GETALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'GROUP_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}

export const getGroupsPublicByActivityAndClient = (activity, client) => async (dispatch) => {
    dispatch({
        type: 'GROUP_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/group/public/getgroupspublicbyactivityandclient/'+ activity + "/" + client, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'GROUP_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}

export const addGroup = (groupdata) => async (dispatch) => {
    dispatch({
        type: 'GROUP_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/group/register', groupdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'GROUP_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}

export const updateGroup = (groupdata, groupId) => async (dispatch) => {
    dispatch({
        type: 'GROUP_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/group/edit/' + groupId, groupdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'GROUP_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}

export const removeGroup = (group) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/group/remove/' + group, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'GROUP_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'GROUP_NOT_LOADING'
        })
    }
}