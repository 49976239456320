import React, { useEffect,useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { getActivitiesPublicByClub } from '../../../actions/activities/activitiesActions'
import { useParams } from 'react-router'
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import Grid from '../../../components/design/Grid/Grid'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import MenuClubMarketplace from '../../../components/menus/MenuClubMarketplace'
import Loader from '../../../components/design/Loader/Loader'
import SidebarClubMarketplace from '../../../components/Club/SidebarClubMarketplace'
import NotFoundPage from '../../error/404'
import SearchPassTypes from '../../../components/design/Search/SearchPassTypes'
import { getPublicPassTypesByClub } from '../../../actions/ticketing/passTypesActions'


export default function ClubPassTypes() {
    const { t } = useTranslation()
	const dispatch = useDispatch()
    const {clubUrl} = useParams()
    const backgroundGeneric = '/images/fondo-auth.jpg'
    const {activitiesMarket: activities} = useSelector(state=>state.activities)
    const {passTypes, passTypesLoading} = useSelector(state=>state.passTypes)
    const {passTypesFilteredMarket: passTypesFiltered} = useSelector(state=>state.filters)
    const {clubMarket: club, loadingclub, resultclub} = useSelector(state => state.clubs)

    const [selectedActivities, setSelectedActivities] = useState()

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch,clubUrl])

    useEffect(() =>{
        if(club){
            dispatch(getActivitiesPublicByClub(club?._id))
            dispatch(getPublicPassTypesByClub(club?._id))
        }
    }, [dispatch, club])

    useEffect(() => {
        if(activities){
            const activitesRandom = [...activities].sort(() => 0.5 - Math.random())
            setSelectedActivities(activitesRandom.slice(0, 3))
        }
    },[activities])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image={club?.bannerMarketplace || backgroundGeneric}
                title={club?.name}
                section={t('menus.general.passTypes')}
            />

            <Container className='market'>
                {loadingclub || passTypesLoading ?
                    <Loader/>
                :
                    resultclub === 'thereisnotclub'
                    ?
                        <NotFoundPage />
                    :
                        club &&
                        <Row>
                            <Col xs={12} lg={9}>
                                <Row>
                                    <Col>
                                        <MenuClubMarketplace nuevaSeccion="passTypes" clubUrl={clubUrl} activitybooking={club?.activitybooking} renting={club?.renting} ticketing={club?.ticketing}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className='p-0 form-special w-100 border-0'>
                                            <SearchPassTypes club={club?._id} ancho="100%"/>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Grid type="passType" data={passTypesFiltered || passTypes} columns="4"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={3} className="my-3">
                                <SidebarClubMarketplace activitiesfeatured={selectedActivities} sites={club?.sites} />
                            </Col>
                        </Row>
                }
            </Container>
            <FooterMarketplace className='m-0'/>
        </>

    )
}