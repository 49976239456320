import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import SitesEditSubMenu from '../../../../components/menus/SitesEditSubMenu'
import SportsComponent from '../../../../components/sports'
import ServicesComponent from '../../../../components/services'
import { getClubs } from '../../../../actions/profiles/clubsActions'
import SiteForm from '../../../../components/forms/sites/SiteForm'
import provinces from "../../../../components/provinces.json"
import { editSite, getSite } from '../../../../actions/sites/sitesActions'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'

export default function EditSite({usertype, handleSiteSelect}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {club, clubs} = useSelector(state=>state.clubs)

    const {result, loading, site} = useSelector(state => state.sites)

	const {sports} = SportsComponent()
    const {services} = ServicesComponent()

    const [sportsAux, setSportsAux] = useState([])
    const [servicesAux, setServicesAux] = useState([])

    const [siteData, setSiteData] = useState()

    const {siteId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(club){
            dispatch(getClubs(false))
        }
    //eslint-disable-next-line
    }, [dispatch, club])

    useEffect(()=>{
        async function setSiteInfo(site) {
            let sportsSaved = []
            let servicesSaved = []

            for await (const sport of site.sports) {
                sportsSaved.push(sports.find(s => s.value === sport))
            }

            for (const service of site.services) {
                servicesSaved.push(services.find(s => s.value === service))
            }
            
            setSiteData({
                name: site.name,
                sports: sportsSaved,
                image: site.image || null,
                club: {value: club._id, label: club.name},
                province: provinces.find(p => p.value === site.province) || null,
                town: site.town,
                address: site.address,
                services: servicesSaved,
                location: site.location,
                cancelDays: site.cancelDays,
                days: site.schedule?.length > 0 ? site?.schedule : [{weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}],
                datesBlocked: site.datesBlocked?.map(dB => {
                    return {...dB, startDate: new Date(dB.startDate), endDate: new Date(dB.endDate)}
                }),
                deletedDays: [],
                deletedDatesBlocked: [],
                active: site.active
            })
        }

        if (site && club) {
            setSiteInfo(site.site)
            setSportsAux(sports)
            setServicesAux(services)
        }
    //eslint-disable-next-line
    }, [club, site])

    useEffect(() => {
        if (siteId) {
            dispatch(getSite(siteId))
        }
    }, [siteId, dispatch])

    const handleInputChange = ({target})=>{
        setSiteData({
            ...siteData,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setSiteData({
            ...siteData,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        setSiteData({
            ...siteData,
            [selectType]: target
        })
    }

    const handleImage = (e)=>{
        setSiteData({
            ...siteData,
            image: e.target.files[0]
        })
    }

    const handleDayCheck = (target, index) => {
        setSiteData({
            ...siteData,
            days: siteData?.days?.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setSiteData({
            ...siteData,
            days: [...siteData.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, index) => {
        let deletedScheduleId = siteData.days.find((d, i) => i === index)._id
        setSiteData({
            ...siteData,
            days: siteData.days.filter((d, i) => i !== index),
            deletedDays: deletedScheduleId ? [...siteData.deletedDays, deletedScheduleId] : siteData.deletedDays
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setSiteData({
            ...siteData,
            days: siteData.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()

        if(siteData.name !== '' || siteData.address === '' || !siteData.province || siteData.town !== ""){
            let sportsToSend = siteData.sports?.map(sport => {
                return sport.value
            }) || []

            let servicesToSend = siteData.services?.map(service => {
                return service.value
            })

            dispatch(editSite({...siteData, club: siteData.club.value, province: siteData.province.value, sports: sportsToSend, services: servicesToSend}, siteId))   
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('sites.formNotFinished')
            })
        }
    }

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('sites.editsuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.sites")}`)
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

	return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <SitesEditSubMenu usertype={usertype} active="sites"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('sites.editSite')}</h2>
                            <hr className='mb-4'/>
                            <SiteForm state={siteData} clubs={clubs} statetype={'edit'} usertype={usertype} provinces={provinces} isAdding={loading} services={servicesAux} sports={sportsAux} submit={handleEdit} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} handleImage={handleImage} addSchedule={addSchedule} removeSchedule={removeSchedule} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}