import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../../context/auth/useAuth.js'
import { QrReader } from 'react-qr-reader'
import { scanEntry, checkInOut, checkMatchExist, getMatch } from '../../../../actions/ticketing/matchesActions.js'
import Swal from 'sweetalert2'
import { useParams } from 'react-router'

export default function Check() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { clientauth } = useAuth()

    const {selectedMatch, identifier} = useParams()

    const { entry, entryExist, entryOtherMatch, entryCancel, entryValid, maxUsesDone, matchExist, check, loadingentry, resultentry, match } = useSelector(state => state.matches)

    const [show, setShow] = useState(false)
    const [scannerData, setScannerData] = useState('')

    useEffect(() => {
        if (selectedMatch && identifier) {
            dispatch(checkMatchExist(selectedMatch, identifier))
            dispatch(getMatch(selectedMatch))
        }
    }, [dispatch, t, selectedMatch, identifier])

    useEffect(() => {
        if (scannerData && show === false) {
            dispatch(scanEntry(scannerData, selectedMatch, identifier))
        }
    }, [scannerData, show])

    useEffect(() => {
        if(entry && entryOtherMatch === false && entryCancel === false){
            setShow(true)
        }
    }, [entry, entryCancel])

    const handleClose = () => {
        setShow(false)
        resetData()
    }

    const handleOnHide = () => {
        handleClose()
    }

    const resetData = () => {
        setScannerData('')
        dispatch({type: 'MATCH_RESET_ENTRY'})
    }

    const handleCheck = (checkType) => {
        dispatch(checkInOut(scannerData, selectedMatch, checkType))
    }

    useEffect(() => {
        if(entryExist === false){
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text:  t('matches.thisentrynotexist'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    resetData()
                }
            })
        }
        // eslint-disable-next-line
    }, [entryExist])

    useEffect(() => {
        if(entryOtherMatch === true){
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text:  t('matches.noentrymatch'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    resetData()
                }
            })
        }
        // eslint-disable-next-line
    }, [entryOtherMatch])

    useEffect(() => {
        if(entryCancel === true){
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text:  t('matches.thisentryiscancel'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    resetData()
                }
            })
        }
        // eslint-disable-next-line
    }, [entryCancel])

    useEffect(() => {
        if(entryValid === false){
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text:  t('matches.thisentrynotvalid'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    resetData()
                }
            })
        }
        // eslint-disable-next-line
    }, [entryValid])

    useEffect(() => {
        if(maxUsesDone === true){
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text:  t('matches.maxusesdone'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    resetData()
                }
            })
        } else if (maxUsesDone === false) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: t('matches.maxusesnotdone'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    resetData()
                }
            })
        }
        // eslint-disable-next-line
    }, [maxUsesDone])

    useEffect(() => {
        if(matchExist === false){
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text:  t('matches.thismatchnotexist'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    resetData()
                }
            })
        }
        // eslint-disable-next-line
    }, [matchExist])

    useEffect(() => {
        if(resultentry){
            setShow(false)
            resetData()
            dispatch({type: 'ENTRY_RESET_RESULT'})
        }
        // eslint-disable-next-line
    }, [resultentry])

    return (
        <>
            <Container className='cuerpo'>
                <Row className='my-3'>
                    <Col className='text-center'>
                        <h1>{match?.host} - {match?.visitor}</h1>
                        <h5>{new Date(match?.date).toLocaleString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })} - {match?.hour}</h5>
                        <QrReader
                            onResult={(qrData) => {if (qrData) {setScannerData(qrData?.text)}}}
                            onError={(err) => {console.error(err)}}
                            constraints={{facingMode: 'environment'}}
                            style={{ width: '100%', height: '100vh' }}
                        />
                        <h5>{t('matches.dontshareurl')}</h5>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleOnHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('matches.entrydata')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo px-5 py-4'>
                    {entry ?
                        <>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <strong className='pe-2'>{t('matches.personname')}:</strong>
                                    {entry?.personData?.firstname || entry?.personData?.lastname ?
                                        entry?.personData?.firstname+' '+(entry?.personData?.lastname || '')
                                    :
                                        t('matches.nodata') }

                                </Col>
                                <Col xs={12} lg={6}>
                                    <strong className='pe-2'>{t('matches.persondni')}:</strong>
                                    {entry?.personData?.dni ?
                                        entry?.personData?.dni
                                    :
                                        t('matches.nodata') }
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                {check ? (
                                    <>
                                        <Col xs={12} lg={6}>
                                            <strong>{t('matches.checkins')}:</strong><br/>
                                            {check?.checkins?.length > 0 ? (
                                                check.checkins.map((checkin, index) => (
                                                    <p key={index} className='mb-1'>
                                                        {new Date(checkin).toLocaleString('es-ES', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })}
                                                    </p>
                                                ))
                                            ) : (
                                                t('matches.nocheckins')
                                            )}
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <strong>{t('matches.checkouts')}:</strong><br/>
                                            {check?.checkouts?.length > 0 ? (
                                                check.checkouts.map((checkout, index) => (
                                                    <p key={index} className='mb-1'>
                                                        {new Date(checkout).toLocaleString('es-ES', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })}
                                                    </p>
                                                ))
                                            ) : (
                                                t('matches.nocheckouts')
                                            )}
                                        </Col>
                                    </>
                                ) : (
                                    <Col xs={12}>
                                        {t('matches.nocheckincheckout')}
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                {(!check || check?.status === 1) ? (
                                    <Col xs={12} className="text-center">
                                        <button className="btn btn-primary" onClick={() => handleCheck('in')} disabled={loadingentry}>
                                            {t('matches.checkin')}
                                        </button>
                                    </Col>
                                ) : (
                                    <Col xs={12} className="text-center">
                                        <button className="btn btn-secondary" onClick={() => handleCheck('out')} disabled={loadingentry}>
                                            {t('matches.checkout')}
                                        </button>
                                    </Col>
                                )}
                            </Row>
                        </>
                    :
                        <></>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}