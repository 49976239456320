import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getCourtPublicByUrl } from '../../../actions/sites/courtsActions'
import Loader from '../../../components/design/Loader/Loader'
import Reserve from './Reserve'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import UsersRegistered from '../../../components/Banners/UsersRegistered'
import BannerPhrase2 from '../../../components/Banners/BannerPhrase2'
import SidebarSite from '../../../components/Activities/SidebarSite'
import NotFoundPage from '../../error/404'
import { getCommissionByClub } from '../../../actions/settings/commissionsActions'

export default function Court() {
    const { t } = useTranslation()
    const backgroundGeneric = '/images/fondo-auth.jpg'

	const dispatch = useDispatch()
    const {clubUrl, courtUrl} = useParams()

    const {clubMarket: club, loadingclub, resultclub} = useSelector(state=>state.clubs)
    const {courtMarket: court, loadingcourt, resultcourt} = useSelector(state=>state.courts)

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })

        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch, clubUrl, courtUrl])

    useEffect(() =>{
        if(club){
            dispatch(getCourtPublicByUrl({url: courtUrl, club: club?._id}))
            dispatch(getCommissionByClub(club?._id))
        }
    // eslint-disable-next-line
    }, [club, courtUrl])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image={club?.bannerMarketplace || backgroundGeneric}
                title={court?.name}
                section={court?.name}
            />
            <Container className='market'>
                {
                loadingcourt || loadingclub
                ?
                    <Loader/>
                :
                    resultclub === 'thereisnotclub' || resultcourt === 'thereisnotcourt'
                    ?
                        <NotFoundPage />
                    :
                        club && court &&
                        <Row>
                            <Col xs={12} lg={9} className="my-3">
                                <Reserve court={court} club={club}/>
                            </Col>
                            <Col xs={12} lg={3} className="my-3">
                                <SidebarSite club={club} />
                            </Col>
                            <Col xs={12} lg={9}>
                                <div>
                                    <Row className='my-3'>
                                        <Col xs={12} sm={6} className='my-3 my-sm-0'>
                                            <UsersRegistered />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <BannerPhrase2
                                                phrase={t('marketplace.banners.yoursport')}
                                                image="/images/banner-incrono01.jpg"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                }

            </Container>
            <FooterMarketplace className='m-0'/>
        </>
    )
}