import React, { Fragment } from "react";
import { Card } from "react-bootstrap";

export default function DataClub({club}) {
    const socialMedia = ['facebook', 'instagram', 'x', 'tiktok', 'twitch', 'linkedin']

    return (
        <Card className='border-0'>
            <Card.Body>
                <div className='d-flex justify-content-between align-items-end mb-4'>
                    <div className='d-flex align-items-end flex-grow-1'>
                        {club?.avatar
                            ?
                            <img src={club?.avatar} style={{width:'80px'}} alt={club?.name}/>
                            :
                            <img src="/images/avatar-club.png" style={{width:'80px'}} alt={club?.name}/>
                        }

                        <div className='ps-3 flex-grow-1'>
                            <h2 className='border-2 border-bottom border-dark'>{club?.name}</h2>
                        </div>
                    </div>
                    <div className='ps-3 pb-2 d-flex gap-1'>
                        {socialMedia.map((platform, i) => (
                                club?.[platform]
                                ?
                                    <a href={club?.[platform]} key={i}>
                                        <img

                                        src={`/images/${platform}.svg`}
                                        alt={platform}
                                        target="_blank"
                                        rel='noreferrer'
                                        />
                                    </a>
                                :
                                    <Fragment key={i}></Fragment>
                        ))}
                        <img src="/images/share.svg" alt="Compartir Club" />
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: club?.description }} />
            </Card.Body>
        </Card>
    )
}