import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function FeatureCard({title,elements,translate,pathIcons}) {
    const { t } = useTranslation()

    return (
        <Card className='border-0 p-2'>
            <Card.Body>
                <h2 className='border-2 border-bottom border-dark mb-4'>{title}</h2>
                <div className='d-flex flex-wrap feature-card'>
                    {elements?.map ((element, i) => {
                        return (
                            <div className='item' key={i}>
                                <img src={`/images/${pathIcons}/${element}.svg`} alt={element} />
                                {t(translate+'.'+element) }
                            </div>
                        )
                    })}
                </div>
            </Card.Body>
        </Card>
    )
}