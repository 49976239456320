export const horariesReducer = (state = {loadinghorary: true, resulthorary: null, horaries: null, horary: null } , action) => {
    switch (action.type) {
        case 'HORARY_GET_SUCCESS':
            return {
                ...state,
                loadinghorary: false,
                horary: action.payload.horary
            }
        case 'HORARY_GETALL_SUCCESS':
            return {
                ...state,
                loadinghorary: false,
                horaries: action.payload.horaries
            }
        case 'HORARY_ADD_SUCCESS':
            return {
                ...state,
                loadinghorary:false,
                resulthorary: action.payload.message,
                horaries: action.payload.horaries
            }
        case 'HORARY_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resulthorary: action.payload.message
            }
        case 'HORARY_IMPORT_SUCCESS':
            return {
                ...state,
                resulthorary: action.payload.message,
                horaries: action.payload.horaries
            }
        case 'HORARY_REMOVE_RESULT':
            return {...state,
                loading:false,
                resulthorary: action.payload.message,
                horaries: state.horaries.filter(horaries => horaries._id !== action.payload.horary)
            }
        case 'HORARY_RESET_RESULT':
            return {
                ...state,
                resulthorary: null
            }
        case 'HORARY_LOADING':
            return {
                ...state,
                loadinghorary:true
            }
        case 'HORARY_NOT_LOADING':
            return {
                ...state,
                loadinghorary:false
            }
        default:
            return state
    }
}