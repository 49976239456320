import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Table, Button, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import Swal from 'sweetalert2'
import { getInscriptionsByGroup } from '../../../../actions/activities/inscriptionsActions'
import { getGroupsByClub, getGroup, importGroupsByClub, removeGroup } from '../../../../actions/activities/groupsActions'
import { getClubsByUser } from '../../../../actions/profiles/clubsActions'
import Select from "react-select"
import ActivitiesSubMenu from '../../../../components/menus/ActivitiesSubMenu'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'

export default function Groups({usertype, groupselected, handleGroupSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {groups, groupToImport, resultgroup} = useSelector(state=>state.groups)
    const {club, clubs} = useSelector(state=>state.clubs)
    const {inscriptions} = useSelector(state=>state.inscriptions)

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const [clubsList, setClubsList] = useState()
    const [clubToImport, setClubToImport] = useState(null)
    const [groupsList, setGroupsList] = useState()
    const [groupsToImport, setGroupsToImport] = useState(null)
    const [clientsInHorary, setClientsInHorary] = useState({})
    const [titleGroup, setTitleGroup] = useState('')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth){
           fetchData()
        }
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(groupselected){
            dispatch(getInscriptionsByGroup(groupselected))
        }
    },[groupselected])

    useEffect(() => {
        if(inscriptions){
            const groupClientsByHorary = () => {
                const groupClients = {};

                inscriptions.forEach((inscription) => {
                    const clientName = `${inscription?.client?.firstname} ${inscription?.client?.lastname}`
                    const horary = inscription?.horary?.name

                    // Si el horario aún no existe en el objeto, inicializarlo
                    if (!groupClients[horary]) {
                        groupClients[horary] = {
                            clientsIn: [],
                            clientsNotIn: [],
                            schedules: inscription?.horary?.schedule || [],
                            gaps: inscription?.horary?.gaps || 0
                        }
                    }

                    // Agregar el cliente a la lista del horario correspondiente
                    if(inscription?.status === "1" || inscription?.status === "2"){
                        groupClients[horary].clientsIn.push({name: clientName, status: t('inscriptions.status'+inscription?.status)})
                    }else{
                        groupClients[horary].clientsNotIn.push({name: clientName, status: t('inscriptions.status'+inscription?.status)})
                    }
                })
                return groupClients
            }
            // Agrupar los clientes y actualizar el estado
            setClientsInHorary(groupClientsByHorary())
        }
    },[inscriptions])

    const fetchData = () => {
        dispatch(getGroupsByClub(club?._id))
        dispatch(getClubsByUser(clientauth?.id))
    }

    useEffect(()=>{
        if (groupToImport) {
            if (groupToImport.length > 0) {
                const options = groupToImport?.map(group => ({
                    value: group._id,
                    label: group.title
                }))
                setGroupsList(options)
            }else{
                setGroupsList(null)
            }
        }else{
            setGroupsList(null)
        }
    //eslint-disable-next-line
    }, [groupToImport])

    useEffect(()=>{
        if (clubs) {
                if (clubs.length > 0) {
                const options = clubs.map(club => ({
                    value: club._id,
                    label: club.name
                }))
                setClubsList(options)
            }
        }
    //eslint-disable-next-line
    }, [clubs])

    const handleRemoveGroup = async(e, groupId) => {
        e.preventDefault()

        dispatch(removeGroup(groupId)).then(() => fetchData())
    }

    const handleDuplicate = async(e, groupToImport) => {
        e.preventDefault()

        dispatch(importGroupsByClub([{value: groupToImport}], club?._id)).then(() => fetchData())
    }

    const selectClubImport = (target) => {
        setGroupsList(null)
        setClubToImport(target.value)
        dispatch(getGroupsByClub(target.value, true))
    }

    const selectGroupsImport = (target) => {
        setGroupsToImport(target)
    }

    const handleImport = async(e) => {
        e.preventDefault()

        dispatch(importGroupsByClub(groupsToImport, club?._id)).then(() => fetchData())
    }

	const columnsGroups = [
        {
            name: t('groups.title'),
            cell: group =>
                <Link onClick={() => handleGroupSelect(group?._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editgroup")}` : `/${t("url.club.club")}/${t("url.club.editgroup")}`} className='table-link'>
                    {group?.title}
                </Link>,
            selector: (group) => group?.title,
            sortable: true,
        },{
            name: <>{t('groups.activity')} / {t('groups.category')}</>,
            cell: group =>
                <Link onClick={() => handleGroupSelect(group?._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editgroup")}` : `/${t("url.club.club")}/${t("url.club.editgroup")}`} className='table-link'>
                    {group?.activity?.title} / {group?.category?.title}
                </Link>,
            selector: (group) => group?.activity,
            sortable: true,
        },{
            name: t('groups.registrationlength'),
            cell: group => {
                let datestart = new Date(group?.registrationStart)
                let dateend = new Date(group?.registrationEnd)

                datestart.setHours(datestart.getHours() + 2)
                dateend.setHours(dateend.getHours() + 2)

                let formattedDateStart = [
                    String(datestart.getUTCDate()).padStart(2, '0'),
                    String(datestart.getUTCMonth() + 1).padStart(2, '0'),
                    datestart.getUTCFullYear()
                ].join('/')
            
                let formattedDateEnd = [
                    String(dateend.getUTCDate()).padStart(2, '0'),
                    String(dateend.getUTCMonth() + 1).padStart(2, '0'),
                    dateend.getUTCFullYear()
                ].join('/')
                
                return <Link onClick={() => handleGroupSelect(group._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editgroup")}` : `/${t("url.club.club")}/${t("url.club.editgroup")}`} className='table-link'>{formattedDateStart} - {formattedDateEnd}</Link>
            },
            selector: (group) => group?.registrationStart,
            sortable: true,
        },{
            name: t('groups.active'),
            center: 'true',
            cell: group => (
                group?.active
                    ?
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked disabled></input>
                    </div>
                    :
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" disabled></input>
                    </div>
            ),
            selector: (group) => group?.active,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width:'120px',
			cell: group =>
                <div className='d-flex justify-content-around w-100'>
                    <Link
                        onClick={() => {
                            handleGroupSelect(group._id)
                            setTitleGroup(group?.title)
                            setShow(true)
                        }}
                        className='table-link me-2'>
                        <OverlayTrigger placement="top"
                            overlay={<Tooltip id="tooltip">{t('groups.checkinscriptions')}</Tooltip>}
                        >
                            <img className="cursor" src="/images/eyeopen.svg" alt="eyes" height={16}/>
                        </OverlayTrigger>
                    </Link>
                    <Link onClick={() => handleGroupSelect(group?._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editgroup")}` : `/${t("url.club.club")}/${t("url.club.editgroup")}`}>
                        <img src="/images/edit.svg" alt={t('sites.dataTitle')} width={15}/>
                    </Link>
                    <div className='cursor' onClick={(e) => handleDuplicate(e, group?._id)}>
                        <img src="/images/copy.svg" alt="copy" width={15}/>
                    </div>
                    <Link onClick={(e) => handleRemoveGroup(e, group?._id)}>
                        <img src="/images/trash.svg" alt="trash" width={15}/>
                    </Link>
                </div>,
			sortable: false,
		}
	]

    useEffect(() => {
        if(resultgroup){
            let newresult = resultgroup
            dispatch({
                type: 'GROUP_RESET_RESULT'
            })
            switch (newresult) {
                case 'deletegroupsuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('groups.deletegroupsuccess')
                    })
                    break;
                case 'deletegroupwrong':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('groups.deletegroupwrong')
                    })
                    break;
                case 'deletegroupnotexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('groups.deletegroupnotexist')
                    })
                    break;
                case 'clientsingroupalready':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('groups.clientsingroupalready')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultgroup])

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('menus.activities.groups')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <ActivitiesSubMenu usertype={usertype} active="groups"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <div className='buttons d-flex justify-content-end'>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.newgroup")}` : `/${t("url.club.club")}/${t("url.club.newgroup")}`} className='btn btn-secondary'>
                                    <i className='fas fa-plus'></i> {t('groups.createnewgroup')}
                                </Link>
                                {/* TODO: Editar el botón para que se use con actividades y categoria del club que importa
                                OCULATAMOS EL BOTÓN DE IMPORTAR PARA MÁS ADELANTE CONTROLAR EN LA API
                                QUE AL IMPORTAR GRUPOS DE OTRO CLUB MOSTRAR LAS ACTIVIDADES Y CATEGORÍAS
                                DEL CLUB AL QUE SE VA A IMPORTAR Y QUE NO COJA LAS DEL OTRO CLUB
                                <Button variant='secondary' className='ms-2 btn-outline' onClick={() => setShow(true)}>
                                    <i className='fas fa-plus'></i> {t('groups.importgroup')}
                                </Button>
                                */}

                            </div>
                            <div className="my-3">
                                <Row>
                                    <Col>
                                        {groups?.length > 0 ?
                                            <CustomDataTable columns={columnsGroups} data={groups} exportable={false} printable={false} />
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('groups.thereisnotgroups')}</h4>
                                                {t('groups.thereisnotgroupsphrase')}
                                                <div className='mt-3'>
                                                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.newgroup")}` : `/${t("url.club.club")}/${t("url.club.newgroup")}`} className='btn btn-secondary'>
                                                        {t('groups.createnewgroup')}
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="cuerpo px-5 py-4">
                    <h3 className="text-center">{t('groups.datagroup')}</h3>
                    <h2 className="text-center">{titleGroup}</h2>
                    {Object.keys(clientsInHorary)?.length > 0 ? (
                        Object.keys(clientsInHorary)?.map((horary) => (
                            <Card key={horary} className="p-0 mt-4">
                                <Card.Header className='d-flex justify-content-between'>
                                    <h3 className='m-0'>{horary}</h3>
                                    <div>{t('groups.occupiedplaces')}: {clientsInHorary[horary]?.clientsIn?.length}/{clientsInHorary[horary].gaps}</div>
                                </Card.Header>
                                <Card.Body>
                                    {/* Mostrar los horarios asociados */}
                                    {clientsInHorary[horary].schedules.length > 0 ?
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <th>{t('schedule.weekdays')}</th>
                                                    <th>{t('schedule.hours')}</th>
                                                </tr>
                                                {clientsInHorary[horary].schedules.map((schedule, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {schedule.weekDays.map((day, i) => (
                                                                <span key={i}>{t('schedule.' + day)}{i < schedule.weekDays.length - 1 ? ', ' : ''}</span>
                                                            ))}
                                                        </td>
                                                        <td>{schedule.startHour} - {schedule.endHour}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    : (
                                        <p>{t('schedule.noschedule')}</p>
                                    )}

                                    {/* Mostrar la tabla de clientes */}
                                    <Table striped bordered>
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th>{t('schedule.client')}</th>
                                                <th>{t('inscriptions.status')}</th>
                                            </tr>
                                            {clientsInHorary[horary].clientsIn.map((client, index) => (
                                                <tr key={`A${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{client.name}</td>
                                                    <td>{client.status}</td>
                                                </tr>
                                            ))}
                                            {clientsInHorary[horary].clientsNotIn.map((client, index) => (
                                                <tr key={`B${index}`}>
                                                    <td></td>
                                                    <td>{client.name}</td>
                                                    <td>{client.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        ))
                    ) : (
                        <div className="text-center">
                            {t('inscriptions.thereisnotinscriptiors')}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            {/* Modal para la importación de grupos */}
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>
                            <>{t('groups.importgroup')}</>
                        </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo p-4'>
                    <Row className="my-2">
                        <Col>
                            <Form.Group className='form-group'>
                                <Select
                                    className="form-control has-content"
                                    type='text'
                                    name='sport'
                                    options={clubsList}
                                    onChange={(e) => selectClubImport(e)}
                                    required
                                />
                                <Form.Label htmlFor='active'>{t('groups.club')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    {clubToImport ?
                        <Row className="my-2">
                            <Col>
                                <Form.Group className='form-group'>
                                    {groupsList?.length > 0 ?
                                        <>
                                            <Select
                                                className="form-control has-content"
                                                type='text'
                                                name='sport'
                                                options={groupsList}
                                                onChange={(e) => selectGroupsImport(e)}
                                                isMulti
                                                required
                                            />
                                            <Form.Label htmlFor='active'>{t('groups.groups')}</Form.Label>
                                        </>
                                    :
                                        ''
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                    :
                        ''
                    }
                    <Row className="my-2">
                        <Col>
                            <Button variant="primary" className="w-100 my-2" onClick={(e) => handleImport(e)} disabled={!groupsToImport?.length > 0}>
                                {t('global.import')}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal> */}
        </Container>
    )
}
