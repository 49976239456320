export const clubsReducer = (state = {loadingclub: false, resultclub: null, clubs: null, club: null, clubsMarket: null, clubMarket: null } , action) => {
    switch (action.type) {
        case 'CLUB_GET_SUCCESS':
            if(action.payload.club || action.payload.clubMarket){
                if(action.payload.club){
                    return {
                        ...state,
                        loadingclub: false,
                        club: action?.payload?.club,
                        resultclub: null
                    }
                }else{
                    return {
                        ...state,
                        loadingclub: false,
                        clubMarket: action?.payload?.clubMarket,
                        resultclub: null
                    }
                }
            }else{
                return {
                    ...state,
                    loadingclub: false,
                    resultclub: 'thereisnotclub'
                }
            }
        case 'CLUB_GETALL_SUCCESS':
            if(action.payload.clubs || action.payload.clubsMarket){
                if(action.payload.clubs){
                    return {
                        ...state,
                        loadingclub: false,
                        clubs: action.payload.clubs
                    }
                }else{
                    return {
                        ...state,
                        loadingclub: false,
                        clubsMarket: action.payload.clubsMarket
                    }
                }
            }else{
                return {...state}
            }
        case 'CLUB_EDIT_SUCCESS':
            return {
                ...state,
                loadingclub:false,
                clubs: state.clubs?.map(club => {
                    if (club._id === action.payload.club) {
                        club.verified = action.payload.status
                        return club
                    } else {
                        return club
                    }
                })
            }
        case 'CLUB_ADD_SUCCESS':
            return {
                ...state,
                loadingclub:false,
                resultclub: action.payload.message,
                club: action.payload.club
            }
        case 'CLUB_RESET':
            return {
                ...state,
                club: null
            }
        case 'CLUB_RESET_RESULT':
            return {
                ...state,
                resultclub: null
            }
        case 'CLUB_LOADING':
            return {
                ...state,
                loadingclub:true
            }
        case 'CLUB_NOT_LOADING':
            return {
                ...state,
                loadingclub:false
            }
        case 'CLUB_VERIFY_DOCUMENT_SUCCESS':
            return {
                ...state,
                loadingclub: false,
                resultclub: action.payload
            }
        case 'CLUB_EDIT_STADIUM_SUCCESS':
            return {
                ...state,
                loadingclub: false,
                resultclub: action.payload?.message,
                club: action.payload?.club
            }
        default:
            return state
    }
}