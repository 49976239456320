import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { addPromotion } from '../../../../actions/payments/promotionsActions'
import PromotionForm from '../../../../components/forms/payments/PromotionForm'

export default function AddPromotion({usertype}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading} = useSelector(state=>state.promotions)
	const {club} = useSelector(state=>state.clubs)

    const [newPromotion, setNewPromotion] = useState({
        name: '',
        active: true,
        exclusive: false,
        conditions: [],
        impact: {
            registrationQuantity: 0,
            registrationPercentage: 0,
            seasonPaymentQuantity: 0,
            seasonPaymentPercentage: 0
        }
    })

    const handleInputChange = ({target})=>{
        setNewPromotion({
            ...newPromotion,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewPromotion({
            ...newPromotion,
            [target.name]: target.checked
        })
    }

    const onDeleteCondition = (index) => {
        setNewPromotion({
            ...newPromotion,
            conditions: newPromotion.conditions.filter((c, i) => i !== index)
        })
    }

    const handleImpactInputChange = ({target}) => {
        setNewPromotion({
            ...newPromotion,
            impact: {
                ...newPromotion.impact,
                [target.name]: target.value
            }
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newPromotion.name !== ''){
            dispatch(addPromotion({...newPromotion, club: club._id}))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('promotions.noName')
            })
        }
    }

    const addCondition = (condition) => {
        setNewPromotion({
            ...newPromotion,
            conditions: [...newPromotion.conditions, {...condition, _id: newPromotion.conditions.length}]
        })
    }

    const editCondition = (condition) => {
        setNewPromotion({
            ...newPromotion,
            conditions: newPromotion.conditions.map(formCondition => {
                if (formCondition._id === condition._id) {
                    return condition
                } else {
                    return formCondition
                }
            })
        })
    }

    useEffect(() => {
        if(result){
            let newresult = result
            dispatch({
                type: 'PROMOTION_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('promotions.addSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.promotions")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.promotions")}`)
                        }
                    })
                    break;
                case 'duplicateName':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('promotions.duplicateName')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('promotions.createNewPromotion')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <PromotionForm state={newPromotion} editCondition={editCondition} addCondition={addCondition} statetype={'add'} usertype={usertype} isAdding={loading} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} onDeleteCondition={onDeleteCondition} onImpactInputChange={handleImpactInputChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}