import { useTranslation } from "react-i18next"

export default function ClubSchedule({ day, schedules }) {
    const { t } = useTranslation()

    return (
        <div className="clubSchedule d-flex justify-content-between align-items-center px-2 py-2">
            <div className="day w-50">{t('schedule.'+day)}</div>

            <div className="w-50 text-center">
            {
            schedules.length > 0
            ?                
                schedules?.map((schedule, index) => (
                    <div key={index} className="hours">
                        {schedule.startHour} {t('schedule.to')} {schedule.endHour}
                    </div>
                ))
            :
                <>{t('sites.scheduleClose')}</>
            }
            </div>
        </div>
    )
}