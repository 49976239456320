import React from "react"
import { Spinner } from "react-bootstrap"


export default function Loader({ className = "mt-5 pt-5" }) {
    return (
        <div className={`d-flex justify-content-center align-items-center${className}`}>
            <Spinner animation="grow"/>
        </div>
    )
}