import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { editMatch, getMatch } from '../../../../actions/ticketing/matchesActions'
import MatchForm from '../../../../components/forms/matches/MatchForm'
import { getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import { getPassTypesByClub } from '../../../../actions/ticketing/passTypesActions.js'
import { getFeesByClub } from '../../../../actions/ticketing/feesActions.js'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions.js'

export default function EditMatch({usertype, selectedMatch}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading: matchesLoading, match} = useSelector(state=>state.matches)
    const {sectors, loading: sectorsLoading} = useSelector(state=>state.sectors)
    const {passTypes} = useSelector(state=>state.passTypes)
	const {club} = useSelector(state=>state.clubs)
    const {fees} = useSelector(state=>state.fees)
    const {commission} = useSelector(state=>state.commissions)

    const [matchForm, setMatchForm] = useState({
        host: '',
        hostBadge: null,
        visitor: '',
        visitorBadge: null,
        thumbnail: null,
        date: '',
        hour: '',
        day: '',
        sport: null,
        template: null,
        sectors: [],
        passTypes: [],
        usePassTypes: false,
        ticketOrientation: false,
        active: false
    })

    const handleEdit = async(e) => {
        e.preventDefault()

        if(matchForm.host !== '' && matchForm.visitor !== ''){
            if (matchForm.date !== "" && matchForm.hour !== "") {
                if (matchForm.sectors.length > 0) {
                    dispatch(editMatch(match?._id, matchForm))
                } else {
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('matches.noSectors')
                    })
                }
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.ok'),
                    icon: 'warning',
                    text: t('matches.noTime')
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('matches.noTeams')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'MATCH_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('matches.editSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.matches")}`)
                        }
                    })
                    break;
                case 'duplicateDate':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('matches.duplicateDate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (selectedMatch) {
            dispatch(getMatch(selectedMatch))
        }
    }, [selectedMatch, dispatch])

    useEffect(() => {
        if (match) {
            setMatchForm({
                host: match.host,
                hostBadge: match.hostBadge,
                visitor: match.visitor,
                visitorBadge: match.visitorBadge,
                thumbnail: match.thumbnail,
                date: new Date(match.date).toISOString().replace(/T.*/,'').split('-').join('-'),
                hour: match.hour,
                day: match.day,
                sport: match.sport,
                template: match.template,
                sectors: match.sectors,
                passTypes: match.passTypes,
                usePassTypes: match.usePassTypes,
                ticketOrientation: match.ticketOrientation || false,
                active: match.active || false
            })
        }
    }, [match])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (club?._id) {
            dispatch(getSectorsByClub(club?._id))
            dispatch(getPassTypesByClub(club?._id))
            dispatch(getFeesByClub(club?._id))
            dispatch(getCommissionByClub(club?._id))
        }
    }, [club, dispatch])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('matches.editMatchForm')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <MatchForm
                                state={matchForm}
                                setState={setMatchForm}
                                statetype={'edit'}
                                usertype={usertype}
                                isAdding={matchesLoading || sectorsLoading}
                                submit={handleEdit}
                                sectors={sectors}
                                passTypes={passTypes}
                                fees={fees}
                                commission={commission}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}