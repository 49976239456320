import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function CourtsEditSubMenu({usertype, active}) {
    const { t } = useTranslation()

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.courts')}</h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/court" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "courts"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}/${t("url.admin.edit")}` : `/${t("url.club.club")}/${t("url.club.courts")}`}>{t('menus.courts.courtdata')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={active === "editcourtstep2"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}/${t("url.admin.edit")}/${t("url.admin.step2")}` : `/${t("url.club.club")}/${t("url.club.courts")}/${t("url.club.edit")}/${t("url.club.step2")}`}>{t('menus.courts.horaries')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}