import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removeCartItem } from "../../actions/payments/cartActions";

export default function CartPassType({item, index}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const calculatePassTypePrice = (item) => {
        const priceToPay = item.passType.seasons?.find(season => season.season?._id === item.season)?.price || 0

        if (priceToPay > 0) {
            const priceComission = item.commission?.find(passType => priceToPay >= passType.lowPrice && priceToPay <= passType.highPrice);
            return Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
        } else {
            return 0.00
        }
    }

    return (
        <Row className="my-3 cart-item">
            <Col xs={12} md={4}>
                <img src={item.passType.image || `/images/bannerclub.jpg`} alt={item.passType.name} className="w-100"/>
            </Col>
            <Col xs={12} md={8} className="d-flex justify-content-between align-items-center">
                <div className="data">
                    <h3 className="font-single mb-1">{item.passType.name}</h3>
                    <p className="club">{item.club.name}</p>
                    <p>{ t('passTypes.sector') }: {item.passType.sector.name}</p>
                    {
                        item.passes?.map((pass, passIndex) => (
                            <p key={passIndex}>{pass.lastname}, {pass.firstname}</p>
                        ))
                    }
                    <p className="fw-bold">{t('marketplace.passType.total')}: {calculatePassTypePrice(item)}€</p>
                </div>
                <div className="icons">
                    <img src="/images/trash.svg" alt="trash" className="cursor" width={15} onClick={() => {dispatch(removeCartItem(index))}}/>
                </div>
            </Col>
        </Row>
    )
}