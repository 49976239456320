export const sectorsReducer = (state = {loading: false, result: null, sectors: [], sector: null, unavailableSeats: []}, action) => {
    switch (action.type) {
        case 'SECTOR_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'SECTOR_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'SECTOR_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                sectors: action.payload.sectors
            }
        case 'SECTOR_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                sector: action.payload
            }
        case 'SECTOR_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'SECTOR_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'SECTOR_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        case 'SECTOR_GET_UNAVAILABLE_SEATS_SUCCESS':
            return {
                ...state,
                loading: false,
                unavailableSeats: action.payload
            }
        default:
            return state
    }
}