import React, { useEffect, useState } from 'react'
import useAuth from '../../../context/auth/useAuth'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../actions/profiles/usersActions'
import Loader from '../../../components/design/Loader/Loader'
import { getClient } from '../../../actions/profiles/clientsActions'
import { addMultipleCartItems } from '../../../actions/payments/cartActions'
import Swal from 'sweetalert2'
import MemberSelect from '../../../components/design/Select/MemberSelect'
import SectorMap from '../../../components/ticketing/SectorMap'
import { getPassesByPaymentClient } from '../../../actions/ticketing/passesActions'
import Select from "react-select"

export default function TicketPurchase({match, club, sectors, fees, unavailableSeats}) {
	const {clientauth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {user, loading} = useSelector(state=>state.users)
    const {client, loadingclient} = useSelector(state => state.clients)
    const {passes, loading: passesLoading} = useSelector(state=>state.pass)
	const {commission} = useSelector(state=>state.commissions)
    const {status, items} = useSelector(state => state.cart)

    const [clientOptions, setClientOptions] = useState([])
    const [passClientOptions, setPassClientClientOptions] = useState([])

    const [cartUsedSeats, setCartUsedSeats] = useState([])

    // Estado para la información de las personas de los abonos que se crearán
    const [tickets, setTickets] = useState([])

    const handleSubmit = async (event) => {
        event.preventDefault()

        let allFieldsFilled = true
        for await (const ticket of tickets) {
            if (allFieldsFilled) {
                if (!ticket) {
                    allFieldsFilled = false
                } else {
                    if (ticket.type === "normal") {
                        if (
                            !ticket?.firstname || ticket?.firstname === "" ||
                            !ticket?.lastname || ticket?.lastname === "" ||
                            !ticket?.phone || ticket?.phone === "" ||
                            !ticket?.birthDate || ticket?.birthDate === "" ||
                            !ticket?.sector || !ticket.fee
                        ) {
                            allFieldsFilled = false
                        }
                    } else {
                        if (!ticket.client) {
                            allFieldsFilled = false
                        }
                    }
                }
            }
        }

        if (allFieldsFilled) {
            let seatsOk = true
            if (tickets.some(ticket => sectors?.find(s => s._id === ticket.sector)?.numerated)) {
                for await (const ticket of tickets) {
                    if (sectors?.find(s => s._id === ticket.sector)?.numerated && (!ticket || !ticket?.seat)) {
                        seatsOk = false
                    }
                }
            }

            if (seatsOk) {
                // Se añade al carrito
                dispatch(addMultipleCartItems(await Promise.all(tickets.map(async ticket => {
                    return {
                        match: match,
                        paymentClient: clientauth?.client,
                        commission: commission.ticketing,
                        club: club,
                        ticket: {
                            ...ticket,
                            sector: ticket.sector ? sectors.find(s => s._id === ticket.sector) : ticket.sector,
                            fee: ticket.fee ? fees.find(s => s._id === ticket.fee) : ticket.fee
                        }
                    }
                })).then(result => result)))
            } else {
                Swal.fire({
                    icon: 'error',
                    text: t('cart.noSeat'),
                    showConfirmButton: true,
                    confirmButtonText: t('global.accept'),
                    timer: 2000
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                text: t('cart.noticketFields'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        }
    }

    const handleTicketInputChange = (target, index) => {
        setTickets(tickets.map((ticket, ticketIndex) => {
            if (ticketIndex === index) {
                if (ticket) {
                    return {
                        ...ticket,
                        [target.name]: target.value
                    }
                } else {
                    return {
                        [target.name]: target.value
                    }
                }
            } else {
                return ticket
            }
        }))
    }

    const handleClientChange = (selectedClient, index, type) => {
        setTickets(tickets.map((ticket, ticketIndex) => {
            if (ticketIndex === index) {
                if (selectedClient) {
                    if (type === "normal") {
                        const client = clientOptions.find(client => client._id === selectedClient.value)
                        return {
                            ...ticket,
                            client: selectedClient,
                            firstname: client.firstname,
                            lastname: client.lastname,
                            email: client.email,
                            phone: client.phone,
                            birthDate: client?.birthdate?.substring(0, 10) || '',
                            dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                        }
                    } else {
                        const client = passClientOptions.find(client => client._id === selectedClient.value)
                        return {
                            ...ticket,
                            client: selectedClient,
                            firstname: client.firstname || client.firstname,
                            lastname: client.lastname || client.lastname,
                            email: client.email,
                            phone: client.phone,
                            birthDate: client?.birthdate?.substring(0, 10) || '',
                            dni: client?.dni ? client.dni : client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                        }
                    }
                } else {
                    return {
                        ...ticket,
                        client: null,
                        firstname: null,
                        lastname: null,
                        email: null,
                        phone: null,
                        birthDate: null,
                        dni: null
                    }
                }
            } else {
                return ticket
            }
        }))
    }

    const handleSeatClick = (index, row, column) => {
        setTickets(tickets.map((ticket, ticketIndex) => {
            if (ticketIndex === index) {
                if (ticket) {
                    return {
                        ...ticket,
                        seat: {
                            row: row,
                            column: column
                        }
                    }
                } else {
                    return {
                        seat: {
                            row: row,
                            column: column
                        }
                    }
                }
            } else {
                return ticket
            }
        }))
    }

    const handleTicketTypeChange = (target, index) => {
        setTickets(tickets.map((ticket, ticketIndex) => {
            if (ticketIndex === index) {
                if (ticket) {
                    return {
                        ...ticket,
                        [target.name]: target.value,
                        client: null,
                        firstname: null,
                        lastname: null,
                        email: null,
                        phone: null,
                        birthDate: null,
                        dni: null,
                        sector: null,
                        fee: null
                    }
                } else {
                    return {
                        [target.name]: target.value
                    }
                }
            } else {
                return ticket
            }
        }))
    }

    const handleSectorChange = (value, index) => {
        setTickets(tickets.map((ticket, ticketIndex) => {
            if (ticketIndex === index) {
                if (ticket) {
                    return {
                        ...ticket,
                        sector: value,
                        fee: null
                    }
                } else {
                    return {
                        sector: value
                    }
                }
            } else {
                return ticket
            }
        }))
    }

    const addTicket = () => {
        setTickets([...tickets, {type: "normal"}])
    }

    const removeTicket = () => {
        let ticketsCopy = [...tickets]
        if (ticketsCopy.length > 1) {
            ticketsCopy.splice(ticketsCopy.length - 1, 1)
            setTickets(ticketsCopy)
        }
    }

    const calculatePrice = (price) => {
        if (isNaN(price) || price <= 0) {
            return 0.00
        } else {
            const ticketingCommission = commission?.ticketing?.find(activity => price >= activity.lowPrice && price <= activity.highPrice);
            return (Math.ceil((parseFloat(price) + parseFloat(price * (ticketingCommission?.percentage || 0) / 100) + parseFloat(ticketingCommission?.double || 0)) * 100) / 100).toFixed(2)
        }
    }

    useEffect(() => {
        if (clientauth?.id) {
            dispatch(getUser(clientauth?.id))
            dispatch(getClient(clientauth?.client))
            dispatch(getPassesByPaymentClient(clientauth?.client))
        }
    }, [clientauth, dispatch])

    useEffect(() => {
        if (client && user) {
            // Opciones de clientes
            let clients = user.members || []
            clients.unshift(client)
            setClientOptions(clients)

            if (passes) {
                let passClients = []
                for (const pass of passes) {
                    if (pass.client) {
                        if (!passClients.some(c => c._id === pass.client?._id)) {
                            passClients.push({...pass.client, email: pass.client._id === client?._id ? client.email : pass.client.email, _id: pass.client._id, passType: pass.passType, pass: pass._id})
                        }
                    } else {
                        if (!passClients.some(c => c.dni === pass.personData.dni && c.firstname === pass.personData.firstname && c.lastname === pass.personData.lastname)) {
                            passClients.push({...pass.personData, _id: pass._id, passType: pass.passType, pass: pass._id})
                        }
                    }
                }
                setPassClientClientOptions(passClients)
            }

            // Datos de entradas
            if (match?.usePassTypes && passes && passes?.some(p => p.client._id === client._id)) {
                const pass = passes?.find(p => p.client._id === client._id)
                setTickets([{
                    type: "pass",
                    client: {...pass.client, email: pass.client._id === client?._id ? client.email : pass.client.email, _id: pass.client._id, passType: pass.passType, pass: pass._id, label: `${client.firstname} ${client.lastname}`, value: client._id, imageUrl: client.avatar},
                    firstname: client.firstname,
                    lastname: client.lastname,
                    email: client.email,
                    phone: client.phone,
                    birthDate: client?.birthdate?.substring(0, 10) || '',
                    dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                }])
            } else {
                setTickets([{
                    type: "normal",
                    client: {...client, birthdate: client.birthdate ? new Date(client.birthdate) : null, label: `${client.firstname} ${client.lastname}`, value: client._id, imageUrl: client.avatar},
                    firstname: client.firstname,
                    lastname: client.lastname,
                    email: client.email,
                    phone: client.phone,
                    birthDate: client?.birthdate?.substring(0, 10) || '',
                    dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                }])
            }
        } else {
            setTickets([{
                type: "normal"
            }])
        }
    }, [client, match, user, passes, dispatch])

    useEffect(() => {
        if (status === 'addSuccess') {
            Swal.fire({
                icon: 'success',
                text: t('cart.' + status),
                showConfirmButton: false,
                timer: 1200
            })
            // Reseteamos formulario
            if (client) {
                if (match?.usePassTypes && passes && passes?.some(p => p.client._id === client._id)) {
                    const pass = passes?.find(p => p.client._id === client._id)
                    setTickets([{
                        type: "pass",
                        client: {...pass.client, email: pass.client._id === client?._id ? client.email : pass.client.email, _id: pass.client._id, passType: pass.passType, label: `${client.firstname} ${client.lastname}`, value: client._id, imageUrl: client.avatar, pass: pass._id},
                        firstname: client.firstname,
                        lastname: client.lastname,
                        email: client.email,
                        phone: client.phone,
                        birthDate: client?.birthdate?.substring(0, 10) || '',
                        dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                    }])
                } else {
                    setTickets([{
                        type: "normal",
                        client: {...client, birthdate: client.birthdate ? new Date(client.birthdate) : null, label: `${client.firstname} ${client.lastname}`, value: client._id, imageUrl: client.avatar},
                        firstname: client.firstname,
                        lastname: client.lastname,
                        email: client.email,
                        phone: client.phone,
                        birthDate: client?.birthdate?.substring(0, 10) || '',
                        dni: client?.identityDocuments?.find(document => document.documentType === "dni" && document.documentNumber && document.documentNumber !== "")?.documentNumber
                    }])
                }
            } else {
                setTickets([{
                    type: "normal"
                }])
            }
        }
    }, [status, dispatch, t, client, passes, match])

    useEffect(() => {
        if (items) {
            let usedSeats = []

            for (const item of items) {
                // Es una entrada
                if (item.match) {
                    if (item.match._id === match._id && item.ticket?.seat) {
                        if (usedSeats.some(s => s.sector === item.ticket.sector._id)) {
                            usedSeats.find(s => s.sector === item.ticket.sector._id).seats.push([item.ticket.seat.row + 1, item.ticket.seat.column + 1])
                        } else {
                            usedSeats.push({
                                sector: item.ticket.sector._id,
                                seats: [[item.ticket.seat.row + 1, item.ticket.seat.column + 1]]
                            })
                        }
                    }
                }

                // Es un abono
                if (item.passType) {
                    const matchDate = new Date(match.date)
                    const passSeasonStartDate = new Date(item.passType.seasons?.find(s => s.season._id === item.season)?.startDate)
                    const passSeasonEndDate = new Date(item.passType.seasons?.find(s => s.season._id === item.season)?.endDate)

                    if (passSeasonStartDate <= matchDate && passSeasonEndDate >= matchDate) {
                        for (const pass of item.passes) {
                            if (usedSeats.some(s => s.sector === item.passType.sector._id)) {
                                usedSeats.find(s => s.sector === item.passType.sector._id).seats.push([pass.seat.row + 1, pass.seat.column + 1])
                            } else {
                                usedSeats.push({
                                    sector: item.passType.sector._id,
                                    seats: [[pass.seat.row + 1, pass.seat.column + 1]]
                                })
                            }
                        }
                    }
                }
            }

            setCartUsedSeats(usedSeats)
        }
    }, [items, match])

    return (
        <>
            {
                loading || loadingclient || passesLoading
                    ?
                        <Loader/>
                    :
                        <div>
                            <Form onSubmit={handleSubmit}>
                                <Card className='border-0 my-4'>
                                    <Card.Body>
                                        {
                                            clientauth?.usertype === 'club'
                                                ?
                                                    <div className='contenedor-vacio text-center py-4'>
                                                        <h4 className='fw-bold'>{t('marketplace.registerOrLogIn')}</h4>
                                                        {t('marketplace.matches.needAccount')}
                                                    </div>
                                                :
                                                    <div className='cuerpo p-0'>
                                                        <div className='mt-4'>
                                                            <h2 className='mb-5'>{t('matches.ticketPurchase')}</h2>
                                                            {
                                                                tickets?.map((ticket, ticketIndex) => (
                                                                    <Card key={ticketIndex} className='my-3'>
                                                                        <h3>{t('matches.ticketPerson', { index: ticketIndex + 1 })}</h3>
                                                                        {
                                                                            client && match.usePassTypes &&
                                                                                <Row className='my-3'>
                                                                                    <Form.Group className='form-group'>
                                                                                        <Form.Select
                                                                                            name='type'
                                                                                            value={ticket?.type}
                                                                                            className='has-content form-control'
                                                                                            onChange={({target}) => {
                                                                                                handleTicketTypeChange(target, ticketIndex)
                                                                                            }}>
                                                                                            <option value="normal">{t("matches.ticket.normal")}</option>
                                                                                            <option value="pass">{t("matches.ticket.pass")}</option>
                                                                                        </Form.Select>
                                                                                        <Form.Label>{t('matches.ticket.type')}</Form.Label>
                                                                                    </Form.Group>
                                                                                </Row>
                                                                        }
                                                                        {
                                                                            client &&
                                                                            <Row className='my-3'>
                                                                                <MemberSelect
                                                                                    members={
                                                                                        ticket?.type === "normal"
                                                                                        ?
                                                                                            clientOptions?.filter(member => !tickets.filter((ticket, index) => index !== ticketIndex)?.find(ticket => ticket?.client?.value === member._id))
                                                                                        :
                                                                                            passClientOptions?.filter(member => !tickets.filter((ticket, index) => index !== ticketIndex)?.find(ticket => ticket?.client?.value === member._id))
                                                                                    }
                                                                                    value={ticket?.client}
                                                                                    onChange={selectedClient => handleClientChange(selectedClient, ticketIndex, ticket?.type)}
                                                                                    isClearable
                                                                                />
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            ticket?.type === "normal" &&
                                                                            <>
                                                                                <Row className='my-3'>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Form.Control
                                                                                                type='text'
                                                                                                name="firstname"
                                                                                                value={ticket?.firstname || ""}
                                                                                                className={ticket?.firstname ? 'has-content' : ''}
                                                                                                disabled={ticket?.client ? true : false}
                                                                                                onChange={({target}) => {
                                                                                                    if(!ticket?.client) {
                                                                                                        handleTicketInputChange(target, ticketIndex)
                                                                                                    }
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                            <Form.Label htmlFor='name'>{t('matches.ticket.firstname')}</Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Form.Control
                                                                                                type='text'
                                                                                                name="lastname"
                                                                                                value={ticket?.lastname || ""}
                                                                                                className={ticket?.lastname ? 'has-content' : ''}
                                                                                                disabled={ticket?.client ? true : false}
                                                                                                onChange={({target}) => {
                                                                                                    if(!ticket?.client) {
                                                                                                        handleTicketInputChange(target, ticketIndex)
                                                                                                    }
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                            <Form.Label htmlFor='lastname'>{t('matches.ticket.lastname')}</Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='my-3'>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Form.Control
                                                                                                type='text'
                                                                                                name="email"
                                                                                                value={ticket?.email || ""}
                                                                                                className={ticket?.email ? 'has-content' : ''}
                                                                                                disabled={ticket?.client ? true : false}
                                                                                                onChange={({target}) => {
                                                                                                    if(!ticket?.client) {
                                                                                                        handleTicketInputChange(target, ticketIndex)
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            <Form.Label htmlFor='email'>{t('matches.ticket.email')}</Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Form.Control
                                                                                                type='text'
                                                                                                name="phone"
                                                                                                value={ticket?.phone || ""}
                                                                                                className={ticket?.phone ? 'has-content' : ''}
                                                                                                disabled={ticket?.client ? true : false}
                                                                                                onChange={({target}) => {
                                                                                                    if(!ticket?.client) {
                                                                                                        handleTicketInputChange(target, ticketIndex)
                                                                                                    }
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                            <Form.Label htmlFor='phone'>{t('matches.ticket.phone')}</Form.Label>
                                                                                        </Form.Group>
                                                                                        {
                                                                                            ticket?.client && (!ticket.phone || ticket.phone === "")
                                                                                            ?
                                                                                                <span className='text-danger'>* {t('matches.ticket.userWithoutPhone')}</span>
                                                                                            :
                                                                                                ticket?.birthDate && (new Date().getFullYear() - new Date(ticket.birthDate).getFullYear() > 13) && (!ticket.phone || ticket.phone === "")
                                                                                                ?
                                                                                                    <span className='text-danger'></span>
                                                                                                :
                                                                                                    <></>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='my-3'>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Form.Control
                                                                                                type='date'
                                                                                                name="birthDate"
                                                                                                value={ticket?.birthDate || ""}
                                                                                                className='has-content'
                                                                                                disabled={ticket?.client ? true : false}
                                                                                                onChange={({target}) => {
                                                                                                    if(!ticket?.client) {
                                                                                                        handleTicketInputChange(target, ticketIndex)
                                                                                                    }
                                                                                                }}
                                                                                                required
                                                                                            />
                                                                                            <Form.Label htmlFor='birthDate'>{t('matches.ticket.birthDate')}</Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Form.Control
                                                                                                type='text'
                                                                                                name="dni"
                                                                                                value={ticket?.dni || ""}
                                                                                                className={ticket?.dni ? 'has-content' : ''}
                                                                                                disabled={ticket?.client ? true : false}
                                                                                                onChange={({target}) => {
                                                                                                    if(!ticket?.client) {
                                                                                                        handleTicketInputChange(target, ticketIndex)
                                                                                                    }
                                                                                                }}
                                                                                                required={(ticket?.client && (!ticket.dni || ticket.dni === "")) || (ticket?.birthDate && (new Date().getFullYear() - new Date(ticket.birthDate).getFullYear() > 13) && (!ticket.dni || ticket.dni === ""))}
                                                                                            />
                                                                                            <Form.Label htmlFor='dni'>{t('matches.ticket.dni')}</Form.Label>
                                                                                        </Form.Group>
                                                                                        {
                                                                                            ticket?.client && (!ticket.dni || ticket.dni === "")
                                                                                            ?
                                                                                                <span className='text-danger'>* {t('matches.ticket.userWithoutDni')}</span>
                                                                                            :
                                                                                                ticket?.birthDate && (new Date().getFullYear() - new Date(ticket.birthDate).getFullYear() > 13) && (!ticket.dni || ticket.dni === "")
                                                                                                ?
                                                                                                    <span className='text-danger'>* {t('matches.ticket.userWithoutDni')}</span>
                                                                                                :
                                                                                                    <></>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className='my-3'>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Select
                                                                                                className='form-control has-content'
                                                                                                name='sector'
                                                                                                options={match?.sectors?.filter(sector => {
                                                                                                    let seatsLeft = unavailableSeats?.find(s => s.sector === sector.sector)?.seatsLeft || 0
                                                                                                    for (const item of items) {
                                                                                                        if (item.match && item.ticket?.sector?._id === sector.sector) {
                                                                                                            seatsLeft -= 1
                                                                                                        }

                                                                                                        if (item.passType && item.passType.sector._id === sector.sector) {
                                                                                                            seatsLeft -= item.passType.people
                                                                                                        }
                                                                                                    }

                                                                                                    for (const ticket of tickets) {
                                                                                                        if (ticket.sector === sector.sector) {
                                                                                                            seatsLeft -= 1
                                                                                                        }
                                                                                                    }
                                                                                                    return seatsLeft > 0 && sector.active && !sector.deleted
                                                                                                })?.map(sector => {
                                                                                                    return { label: sectors?.find(s => s._id === sector.sector)?.name, value: sector.sector }
                                                                                                })}
                                                                                                value={ticket.sector ? { value: ticket.sector, label: sectors?.find(s => s._id === ticket.sector)?.name } : null}
                                                                                                onChange={(value) => handleSectorChange(value?.value, ticketIndex)}
                                                                                            />
                                                                                            <Form.Label htmlFor='sector' className='px-3'>{t('matches.ticket.sector')}</Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group className='form-group'>
                                                                                            <Select
                                                                                                className='form-control has-content'
                                                                                                name='fee'
                                                                                                options={match?.sectors?.find(s => s.sector === ticket.sector)?.fees?.filter(f => f.active === true)?.map(fee => {
                                                                                                    return { label: fees?.find(s => s._id === fee.fee)?.name, value: fee.fee }
                                                                                                })}
                                                                                                value={ticket.fee ? { value: ticket.fee, label: fees?.find(s => s._id === ticket.fee)?.name } : null}
                                                                                                onChange={(value) => {
                                                                                                    handleTicketInputChange({name: "fee", value: value.value}, ticketIndex)
                                                                                                }}
                                                                                            />
                                                                                            <Form.Label htmlFor='sector' className='px-3'>{t('matches.ticket.fee')}</Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                                {
                                                                                    sectors?.find(s => s._id === ticket.sector)?.numerated &&
                                                                                        <SectorMap
                                                                                            rows={sectors?.find(s => s._id === ticket.sector)?.distribution?.rows}
                                                                                            columns={sectors?.find(s => s._id === ticket.sector)?.distribution?.columns}
                                                                                            numeration={sectors?.find(s => s._id === ticket.sector)?.distribution?.numeration}
                                                                                            unavailable={unavailableSeats?.find(s => s.sector === ticket.sector)?.unavailable?.map(s => {
                                                                                                return [s[0] + 1, s[1] + 1]
                                                                                            })?.concat(tickets.filter((t, index) => index !== ticketIndex).map(t => {
                                                                                                if (t?.sector === ticket.sector && t?.seat) {
                                                                                                    return [t.seat.row + 1, t.seat.column + 1]
                                                                                                } else {
                                                                                                    return null
                                                                                                }
                                                                                            })?.filter(p => p !== null)).concat(cartUsedSeats.find(s => s.sector === ticket.sector)?.seats || [])}
                                                                                            deleted={unavailableSeats?.find(s => s.sector === ticket.sector)?.deleted?.map(s => {
                                                                                                return [s[0] + 1, s[1] + 1]
                                                                                            })}
                                                                                            selected={ticket?.seat ? [ticket.seat.row + 1, ticket.seat.column + 1] : null}
                                                                                            action={(row, column) => {
                                                                                                handleSeatClick(ticketIndex, row, column)
                                                                                            }}
                                                                                        />
                                                                                }
                                                                            </>
                                                                        }
                                                                        {
                                                                            ticket?.type === "normal" && ticket.fee &&
                                                                            <Row className='mt-5'>
                                                                                <Col xs={4} className='offset-8'>
                                                                                    <div className='border rounded group-price text-center py-3 fw-bold'>{match.sectors.find(s => s.sector === ticket.sector)?.fees?.find(f => f.fee === ticket.fee)?.price}€</div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                        {
                                                                            ticket?.type === "pass" && ticket.client &&
                                                                            <Row className='mt-5'>
                                                                                <Col xs={4} className='offset-8'>
                                                                                    <div className='border rounded group-price text-center py-3 fw-bold'>{match.passTypes.find(p => p.passType === ticket.client.passType)?.price}€</div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </Card>
                                                                ))
                                                            }
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <div className='d-grid'>
                                                                        <Button type='button' variant='primary' size='sm' className='p-2' onClick={addTicket}>
                                                                            <i className='fas fa-plus' /> {t('matches.addTicket')}
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                                {
                                                                    tickets?.length > 1 &&
                                                                    <Col xs={12} md={6}>
                                                                        <div className='d-grid'>
                                                                            <Button type='button' variant='outline-primary' size='sm' className='p-2' onClick={removeTicket}>
                                                                                <img src="/images/close-inverted.svg" alt="remove" /> {t('matches.removeLastTicket')}
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </div>
                                                        <Row className='mt-5 align-items-end'>
                                                            <Col xs={12} md={8}>
                                                                <h3 className='me-3 mb-0 font-single'>{t('matches.total')}:
                                                                    <span className='ps-3'>{calculatePrice((tickets ||[]).reduce((sum, ticket) => {
                                                                        if(ticket?.type === "normal") {
                                                                            return parseFloat(sum) + parseFloat(match.sectors.find(s => s.sector === ticket.sector)?.fees?.find(f => f.fee === ticket.fee)?.price)
                                                                        } else {
                                                                            return parseFloat(sum) + parseFloat(match.passTypes.find(p => p.passType === ticket.client?.passType)?.price)
                                                                        }
                                                                    }, 0))}€</span>
                                                                </h3>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <div className='mt-4 d-grid'>
                                                                    <Button type='submit' variant='primary' className='d-flex justify-content-center align-items-center'>{t('marketplace.matches.addToCart')} <img alt='' src='/images/rignt-arrow.svg' className='ms-3'/></Button>
                                                                </div>
                                                            </Col>
                                                            <hr className='my-1'/>
                                                        </Row>
                                                    </div>
                                        }
                                    </Card.Body>
                                </Card>
                            </Form>
                        </div>
            }
        </>
    )
}