import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"


export default function HeadConfigClub({ nuevaSeccion, usertype }) {
    const { t } = useTranslation()

    const storedData = sessionStorage.getItem('dataIncrono')
    const { clubId } = JSON.parse(storedData)

    return (
        <Container fluid className='submenu px-0 mb-3'>
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{usertype === 'superadmin' ? t('admin.clubs.club') : t('club.myclub') }</h1>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col>
                    <Nav defaultActiveKey="/home" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "basicdata"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.basicdata")}` : `/${t("url.club.club")}/${t("url.club.basicdata")}`}>{t('menus.config.basicdata')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            {clubId ?
                                <Nav.Link as={Link} active={nuevaSeccion === "social"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.socialdata")}` : `/${t("url.club.club")}/${t("url.club.socialdata")}`}>{t('menus.config.social')}</Nav.Link>
                            :
                                ''
                            }
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "documents"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.documents")}` : `/${t("url.club.club")}/${t("url.club.documents")}`}>{t('menus.config.documents')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "billingdata"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.billingdata")}` : `/${t("url.club.club")}/${t("url.club.billingdata")}`}>{t('menus.config.billingdata')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as={Link} active={nuevaSeccion === "paycometdata"} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.paycometdata")}` : `/${t("url.club.club")}/${t("url.club.paycometdata")}`}>{t('menus.config.paycometdata')}</Nav.Link>
                        </Nav.Item>
                        {usertype === 'superadmin' ?
                            <>
                                <Nav.Item as="li">
                                    <Nav.Link as={Link} active={nuevaSeccion === "commissiondata"} to={`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.commissiondata")}`}>{t('menus.config.commissiondata')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link as={Link} active={nuevaSeccion === "accounting"} to={`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.accounting")}`}>{t('club.accounting.title')}</Nav.Link>
                                </Nav.Item>
                            </>
                        : 
                            ''
                        }
                    </Nav>
                </Col>
            </Row>
        </Container>
    )
}