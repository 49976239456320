import React from "react"
import ActivityMarket from "../../Activities/ActivityMarket"
import Club from "../../Club/Club"

import { Button, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Opinion from "../../Opinions/Opinion"
import PassTypeMarket from "../../ticketing/PassTypeMarket"
import CourtMarket from "../../Renting/CourtMarket"
import MatchMarket from "../../ticketing/MatchMarket"


export default function Grid({type, data = [], showMoreBtnAction, columns }) {
    const { t } = useTranslation()

    return (
        <>
            <Row className="">
                {type?.toLowerCase() === "club" &&
                    data?.map((club, i) => (
                        <Col key={i} xs={12} sm={6} md={columns} className="my-3"><Club club={club}/></Col>
                    ))
                }
                {type?.toLowerCase() === "activity" &&
                    data?.map((activity, i) => (
                        <Col key={i} xs={12} sm={6} md={columns} className="my-3"><ActivityMarket activity={activity}/></Col>
                    ))
                }
                {type?.toLowerCase() === "court" &&
                    data?.map((court, i) => (
                        <Col key={i} xs={12} sm={6} md={columns} className="my-3"><CourtMarket court={court}/></Col>
                    ))
                }
                {type?.toLowerCase() === "passtype" &&
                    data?.map((passType, i) => (
                        <Col key={i} xs={12} sm={6} md={columns} className="my-3"><PassTypeMarket passType={passType}/></Col>
                    ))
                }
                {type?.toLowerCase() === "match" &&
                    data?.map((match, i) => (
                        <Col key={i} xs={12} sm={6} md={columns} className="my-3"><MatchMarket match={match}/></Col>
                    ))
                }
                {type?.toLowerCase() === "opinion" &&
                    // data?.map((d, i) => (
                    //     <Col key={i} xs={12} sm={6} md={4} xxl={3}><Opinion opinion={d}/></Col>
                    // ))
                    ['uno','dos','tres'].map((opinion, i) => (
                        <Col key={i} xs={12} sm={6} md={4} className="my-3"><Opinion opinion={opinion}/></Col>
                    ))
                }
            </Row>
            {
                showMoreBtnAction &&
                <div>
                    <Button type="button" role="button" onClick={showMoreBtnAction}>{t("global.showMore")}</Button>
                </div>
            }
        </>
    )
}