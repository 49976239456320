import React, { useEffect, useState } from 'react'
import { Row, Col, Modal, Button, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { createManualPayment } from '../../actions/payments/paymentActions'
import Swal from 'sweetalert2'
import paycometErrors from '../../components/paycometError.json'

export default function CreateManualPayment({userType = "club", inscription, onHide}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()

	const {createManualStatus} = useSelector(state=>state.payment)
    const {commission} = useSelector(state=>state.commissions)

    const [quantity, setQuantity] = useState(0)
    const [quantityTax, setQuantityTax] = useState(0)
    const [formState, setFormState] = useState({
        description: '',
        date:''
    })    

    const handleCreatePayment = () => {
        if (quantity > 0) {
            Swal.fire({
                showConfirmButton: true,
                showCancelButton: true,
                icon: 'warning',
                text: t('payments.createManualPaymentQuestion')
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(createManualPayment({
                        paymentClient: inscription.paymentClient,
                        type: 'Inscription',
                        ref: inscription._id,
                        seasonPaymentRef: null,
                        rawQuantity: quantity,
                        description: formState.description,
                        paymentDate: formState.date
                    }))
                }
            })
        }
    }

    const handleQuantityChange = (e) => {
        let inputValue = e.target.value;

        if (/^\d*\.?\d*$/.test(inputValue)) {
            if (quantity === 0 && inputValue !== '0') {
                inputValue = inputValue.replace(/^0+/, '')
            }
            setQuantity(inputValue)
            setQuantityTax(calculatePayment(inputValue));
        }
    }
    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const calculatePayment = (price) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }

        if (!isNaN(price)) {
            const activity = commission?.activities?.find(activity => price >= activity.lowPrice && price <= activity.highPrice);
            if (activity) {
                return price > 0 ? (Math.ceil(((price * (activity.percentage || 0) / 100) + (activity.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
            } else {
                return '0.00'
            }
        } else {
            return '0.00'
        }
    }

    useEffect(() => {
        if (createManualStatus) {
            switch (createManualStatus?.message) {
                case "createPaymentSuccess":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('payments.createManualPaymentSuccess')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            onHide()
                        }
                    })
                    break;
                case "createPaymentError":
                    const errorCreate = paycometErrors?.find(err => err.code === createManualStatus?.typeError.toString())
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        html: `${t('payments.createManualPaymentError')}<br>Error: ${createManualStatus.typeError}<br>${errorCreate?.description}`
                    }).then((result) => {
                        if (result.isConfirmed) {
                            onHide()
                        }
                    })
                    break;
                default:
                    break;
            }
            dispatch({ type: "PAYMENT_CREATEMANUALPAYMENT_RESET" })
        }
    }, [createManualStatus, dispatch, t])

    return (
        <Modal show={inscription ? true : false} onHide={() => onHide()} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('payments.createManualPayment')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='cuerpo px-5 py-4'>
                <div>
                    {
                        inscription
                            ?
                                <>
                                    <Row>
                                        <Col xs={12}>
                                            <Row className='my-3'>
                                                <Col>
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className="has-content"
                                                            type="date"
                                                            name="date"
                                                            value={formState.date || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                        <Form.Label htmlFor='birthdate'>{t('payments.date')}</Form.Label>
                                                    </Form.Group>
                                                    <Form.Text className='text-muted'>
                                                        {t('payments.dateDescription')}
                                                    </Form.Text>
                                                </Col>
                                            </Row>
                                            <Row className='my-3'>
                                                <Col>
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className={formState.description ? 'has-content' : ''}
                                                            type="text"
                                                            name="description"
                                                            value={formState.description || ''}
                                                            onChange={handleInputChange}
                                                            maxLength={25}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]|[áéíóúÁÉÍÓÚñÑ]/g, '');
                                                            }}
                                                        />
                                                        <Form.Label htmlFor='birthdate'>{t('payments.description')}</Form.Label>
                                                    </Form.Group>
                                                    <Form.Text className='text-muted'>
                                                        {t('club.shortNameDescription')}
                                                    </Form.Text>
                                                </Col>
                                            </Row>
                                            <Row className='my-3'>
                                                <Col>
                                                    <Form.Group className='form-group'>
                                                        <Form.Control
                                                            className={quantity ? 'has-content' : ''}
                                                            type="text"
                                                            step={0.01}
                                                            name="refundQuantity"
                                                            min={0}
                                                            required
                                                            value={quantity || ''}
                                                            onChange={handleQuantityChange}
                                                        />
                                                        <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                        <Form.Label htmlFor='birthdate'>{t('payments.quantity')}</Form.Label>
                                                    </Form.Group>
                                                    <div className='d-flex justify-content-between'>
                                                        <small>{t('inscriptions.priceclient')}</small>
                                                        <div>{quantityTax} €</div>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <small>{t('inscriptions.finalprice')}</small>
                                                        <div>{(Number(quantity) + Number(quantityTax)).toFixed(2)} €</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='d-flex justify-content-end'>
                                                    <Button variant="primary" type="button" onClick={() => {handleCreatePayment()}}>{t('payments.create')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            :
                                <></>
                    }
                </div>
            </Modal.Body>
        </Modal>
	)
}