import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removeCartItem } from "../../actions/payments/cartActions";

export default function CartActivity({item, index}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const calculateActivityPrice = (item) => {
        let registrationPayment = 0
        if (item.group.registrationPayment?.maxdate && new Date(item.group.registrationPayment?.maxdate) >= new Date()) {
            registrationPayment = parseFloat(item.group.registrationPayment.price)
        }
        const registrationImpact = item.questions
        .reduce((sum, question) => {
            const registrationPercentageTotal = (parseFloat(registrationPayment) * ((parseFloat(question?.impact?.registrationPercentage || 0)) / 100))

            return parseFloat(sum) + (parseFloat(registrationPercentageTotal)) + (question?.impact?.registrationQuantity || 0);
        }, 0)

        const seasonPaymentsTotal = item.group.seasonPayments.reduce((seasonPaymentTotal, seasonPayment) => {
            if (new Date(seasonPayment.endDate) > new Date()) {
                const seasonPaymentImpact = item.questions
                .reduce((questionTotal, question) => {
                    const seasonPaymentPercentageTotal = (parseFloat(seasonPayment.price) * ((parseFloat(question?.impact?.seasonPaymentPercentage || 0)) / 100))

                    return parseFloat(questionTotal) + (parseFloat(seasonPaymentPercentageTotal)) + (question?.impact?.seasonPaymentQuantity || 0);
                }, 0)
                let total = parseFloat(seasonPaymentImpact) + parseFloat(seasonPayment.price)

                if (item.promotions) {
                    for (const promotion of item.promotions) {
                        if (total > 0) {
                            const promotionPercentageTotal = (parseFloat(total) * ((parseFloat(promotion.impact.seasonPaymentPercentage || 0)) / 100))
                            total -= (parseFloat(promotionPercentageTotal)) + (promotion.impact.seasonPaymentQuantity || 0)
                        } else {
                            total = 0
                        }
                    }
                }

                return parseFloat(seasonPaymentTotal) + (total <= 0 ? 0 : total)
            } else {
                return seasonPaymentTotal
            }
        }, 0)

        let registrationTotal = parseFloat(registrationPayment) + parseFloat(registrationImpact)

        if (item.promotions) {
            for (const promotion of item.promotions) {
                if (registrationTotal > 0) {
                    const promotionPercentageTotal = (parseFloat(registrationTotal) * ((parseFloat(promotion.impact.registrationPercentage || 0)) / 100))
                    registrationTotal -= (parseFloat(promotionPercentageTotal)) + (promotion.impact.registrationQuantity || 0)
                } else {
                    registrationTotal = 0
                }
            }
        }

        const priceToPay = (registrationTotal <= 0 ? 0 : registrationTotal) + parseFloat(seasonPaymentsTotal)

        const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);

        if (priceToPay <= 0) {
            return 0.00
        } else {
            return (Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100).toFixed(2)
        }
    }

    return (
        <Row className="my-3 cart-item">
            <Col xs={12} md={4}>
                <img src={item.activity.image || `/images/sports/${item.activity?.sport}.jpg`} alt={item.activity.title} className="w-100"/>
            </Col>
            <Col xs={12} md={8} className="d-flex justify-content-between align-items-center">
                <div className="data">
                    <h3 className="font-single mb-1">{item.activity.title}</h3>
                    <p className="club">{item.club.name}</p>
                    <p>{item.group.title}</p>
                    <p>{item.client.lastname}, {item.client.firstname}</p>

                    {item?.group?.assignableHorary ?
                        <p>{t('cart.horary')}: {item.group.horaries.find(g => g._id === item.horary)?.name}</p>
                    :
                        ''
                    }
                    <p className="fw-bold">{t('marketplace.activity.total')}: {calculateActivityPrice(item)}€</p>
                </div>
                <div className="icons">
                    <img src="/images/trash.svg" alt="trash" className="cursor" width={15} onClick={() => {dispatch(removeCartItem(index))}}/>
                </div>
            </Col>
        </Row>
    )
}