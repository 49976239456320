import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getPassTypesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/passType/getPassTypesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASSTYPE_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}

export const getAvailablePassTypesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/passType/getAvailablePassTypesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASSTYPE_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}

export const getPassType = (passTypeId) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/passType/getPassType/'+ passTypeId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASSTYPE_GET_SUCCESS',
                payload: data?.passType
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}

export const getPublicPassTypesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/passType/getPublicPassTypesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASSTYPE_GETALL_SUCCESS',
                payload: {...data, passTypes: data.passTypesMarket, passTypesMarket: undefined}
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}

export const getPassTypePublicByUrl = ({url, club}) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/passType/public/getPublicPassTypeByUrl/'+ url +'/'+ club)
        if (status === 200) {
            dispatch({
                type: 'PASSTYPE_GET_SUCCESS',
                payload: data?.passTypeMarket
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}

export const addPassType = (passTypeData) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        // Subida de la miniatura
        if(passTypeData.image !== null && passTypeData.image !== undefined && passTypeData.image !== '' && typeof passTypeData.image !== 'string'){
            let formData = new FormData()
            formData.append('image', passTypeData.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            passTypeData.image = data
        } else {
            passTypeData.image = null
        }

        // Subida del anverso de la plantilla para impresión
        if(passTypeData.obverseTemplate !== null && passTypeData.obverseTemplate !== undefined && passTypeData.obverseTemplate !== '' && typeof passTypeData.obverseTemplate !== 'string'){
            let formData = new FormData()
            formData.append('image', passTypeData.obverseTemplate)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            passTypeData.obverseTemplate = data
        } else {
            passTypeData.obverseTemplate = null
        }

        // Subida del reverso de la plantilla para impresión
        if(passTypeData.reverseTemplate !== null && passTypeData.reverseTemplate !== undefined && passTypeData.reverseTemplate !== '' && typeof passTypeData.reverseTemplate !== 'string'){
            let formData = new FormData()
            formData.append('image', passTypeData.reverseTemplate)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            passTypeData.reverseTemplate = data
        } else {
            passTypeData.reverseTemplate = null
        }

        const { data, status} = await Axios.post('/api/passType/register', passTypeData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'PASSTYPE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}

export const editPassType = (passTypeId, passTypeData) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        // Subida de la miniatura
        if(passTypeData.image !== null && passTypeData.image !== undefined && passTypeData.image !== '' && typeof passTypeData.image !== 'string'){
            let formData = new FormData()
            formData.append('image', passTypeData.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            passTypeData.image = data
        } else {
            passTypeData.image = null
        }

        // Subida del anverso de la plantilla para impresión
        if(passTypeData.obverseTemplate !== null && passTypeData.obverseTemplate !== undefined && passTypeData.obverseTemplate !== '' && typeof passTypeData.obverseTemplate !== 'string'){
            let formData = new FormData()
            formData.append('image', passTypeData.obverseTemplate)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            passTypeData.obverseTemplate = data
        } else {
            passTypeData.obverseTemplate = null
        }

        // Subida del reverso de la plantilla para impresión
        if(passTypeData.reverseTemplate !== null && passTypeData.reverseTemplate !== undefined && passTypeData.reverseTemplate !== '' && typeof passTypeData.reverseTemplate !== 'string'){
            let formData = new FormData()
            formData.append('image', passTypeData.reverseTemplate)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            passTypeData.reverseTemplate = data
        } else {
            passTypeData.reverseTemplate = null
        }

        const { data, status} = await Axios.post('/api/passType/edit/' + passTypeId, passTypeData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'PASSTYPE_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}

export const deletePassType = (passTypeId) => async (dispatch) => {
    dispatch({
        type: 'PASSTYPE_LOADING'
    })
    try {
        const { data, status } = await Axios.delete('/api/passType/delete/'+ passTypeId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASSTYPE_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASSTYPE_NOT_LOADING'
        })
    }
}