import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next"
import { getClubsPublic } from '../../actions/profiles/clubsActions'
import { getActivitiesPublic } from '../../actions/activities/activitiesActions'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import Grid from '../../components/design/Grid/Grid'
import { FooterMarketplace } from '../../components/footers/FooterMarketplace'
import BannerFullWidth from '../../components/Banners/BannerFullWidth'
import Suscribete from '../../components/Banners/Subscribe'
import UsersRegistered from '../../components/Banners/UsersRegistered'
import BannerPhrase from '../../components/Banners/BannerPhrase'
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"
import { getSitesPublicForFilter, getSportsPublicForFilter } from '../../actions/filtersActions'

export default function Home() {
    const { t } = useTranslation()	
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {sitesfilter, sportsfilter} = useSelector(state=>state.filters)
    const {activitiesMarket: activities} = useSelector(state=>state.activities)
    const {clubsMarket: clubs} = useSelector(state=>state.clubs)

    const [clubsMoreVisited, setClubsMoreVisited] = useState(null)
    const [activitiesMoreVisited, setActivitiesMoreVisited] = useState(null)

    const [showVideo, setShowVideo] = useState(true)

    const [filterOptions, setFilterOptions] = useState({club: '', province: null, sport: null})
    const [provincesOptions, setProvincesOptions] = useState([])
    const [sportsOptions, setSportsOptions] = useState([])

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubsPublic())
        dispatch(getActivitiesPublic())

        dispatch(getSitesPublicForFilter())
        dispatch(getSportsPublicForFilter())
    }, [dispatch])

    useEffect(() => {
        if (sitesfilter) {
            const uniqueProvinces = [...new Set(sitesfilter.map(site => site?.province))]
            let arrayOptionsProvinces = uniqueProvinces.map(province => ({label: province, value: province}))
            arrayOptionsProvinces.unshift({label: t('marketplace.allprovinces'), value: null});
            setProvincesOptions(arrayOptionsProvinces)
        }
    }, [sitesfilter, t])

    useEffect(() => {
        if (sportsfilter) {
            let arrayOptionsSports = sportsfilter.map(sport => ({label: t('sports.'+sport), value: sport}))
            arrayOptionsSports.unshift({label: t('marketplace.allsports'), value: null});
            setSportsOptions(arrayOptionsSports)
        }
    }, [sportsfilter, t])

    useEffect(() =>{
        if(clubs){
            const reversedClubs = [...clubs].reverse()
            setClubsMoreVisited(reversedClubs)
        }
    }, [clubs])

    useEffect(() =>{
        if(activities){
            const reversedActivities = [...activities].reverse()
            setActivitiesMoreVisited(reversedActivities.slice(0, 8))
        }
    }, [activities])

    const searchActivities = () => {
        const params = new URLSearchParams()
        if (filterOptions.activity) params.append('activity', filterOptions.activity)
        if (filterOptions.province) params.append('province', filterOptions.province)
        if (filterOptions.sport) params.append('sport', filterOptions.sport)

        navigate(`/${t("url.marketplace.activities")}?${params.toString()}`)
    }

    const clearSearch = () => {
        setFilterOptions({activity: '', province: null, sport: null})
    }

    const handleInputChange = ({target})=>{
        setFilterOptions({
            ...filterOptions,
            'activity': target.value
        })
    }

    const handleSelectChange = (target, selecttype) => {
        setFilterOptions({
            ...filterOptions,
            [selecttype]: target.value
        })
    }

    useEffect(() => {
        const handleResize = () => {
          // Verificar el tamaño de la pantalla para mostrar u ocultar el video
          if (window.innerWidth < 980) {
            setShowVideo(false) // Oculta el video en dispositivos móviles
          } else {
            setShowVideo(true) // Muestra el video en pantallas más grandes
          }
        };
    
        // Inicialmente, verificar el tamaño de la pantalla
        handleResize()
    
        // Agregar un event listener para manejar el redimensionamiento
        window.addEventListener('resize', handleResize)
    
        // Limpiar el event listener cuando el componente se desmonte
        return () => {
          window.removeEventListener('resize', handleResize)
        };
      }, [])

    return (
        <div>
            <div className='firstView d-flex align-items-center justify-content-center'>
                {showVideo && (
                    <div className="video-background">
                        <video autoPlay loop muted className='d-none d-sm-block'>
                            <source src="/videos/video-presentacion.mp4" type="video/mp4" />
                            Tu navegador no soporta la reproducción de video.
                        </video>
                    </div>
                )}
                <div className='position-relative d-flex flex-column align-items-center text-center superior-home'>
                    <figure className='mb-5'><img src='/images/logo-marketplace.svg' alt="Logotipo Incrono" style={{width: '250px'}} /></figure>
                    <h2 className='font-generic text-white'>{t('marketplace.home.phrase1')}</h2>
                    <h4 className='font-generic fw-light text-white'>{t('marketplace.home.phrase2')}</h4>
                    <Card className='p-0 form-special w-100'>
                        <Card.Body className='m-auto' style={{ width: '100%' }}>
                            <Row>
                                <Col xs={12} md={3}>
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            type="text"
                                            name="activity"
                                            onChange={handleInputChange}
                                            value={filterOptions?.activity}
                                            placeholder={t('marketplace.activitysearch')}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Form.Group className='form-group'>
                                        <Select
                                            className="form-control select has-content"
                                            name="province"
                                            options={provincesOptions}
                                            value={provincesOptions?.find(option => option?.value === filterOptions?.province)}
                                            placeholder={t('marketplace.province')}
                                            onChange={(e) => handleSelectChange(e, 'province')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Form.Group className='form-group'>
                                        <Select
                                            className="form-control select has-content"
                                            name= "sport"
                                            options={sportsOptions}
                                            value={sportsOptions?.find(option => option?.value === filterOptions?.sport)}
                                            placeholder={t('marketplace.sport')}
                                            onChange={(e) => handleSelectChange(e, 'sport')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className='text-center d-flex align-items-center justify-content-around'>
                                    <Button variant='secondary' type="submit" className="my-2 mx-1" onClick={() => searchActivities()}>
                                        {t('marketplace.search')}
                                    </Button>
                                    {filterOptions.club || filterOptions.province || filterOptions.sport ?
                                        <div className="my-2 mx-1 cursor" onClick={() => clearSearch()}>
                                            <img src="/images/trash.svg" alt="Limpiar" /> {t('marketplace.clearsearch')}
                                        </div>
                                    :
                                        <></>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {/* <h4 className='font-generic text-white mt-5 mb-1'>{t('marketplace.home.phrase3')}</h4>
                    <h4 className='font-generic fw-light text-white'>{t('marketplace.home.phrase4')}</h4>
                    <Link to={`/${t("url.auth.registerclub")}`} className="btn btn-secondary mt-3">
                        {t('marketplace.home.discoverincrono')}
                    </Link> */}
                </div>
            </div>
            <MenuMarketplace className='m-0'/>
            <div className='marketplace border-bottom border-2 border-dark'>
                <Container className='market'>
                    <Row>
                        <Col xs='12' lg='6'>
                            <h2 className='display-6' dangerouslySetInnerHTML={{ __html: t('marketplace.clubsmoresearch') }} />
                            <img src='/images/clubsmoresearch.png' alt="Club más buscados" className='img-fluid'/>
                        </Col>
                        <Col xs='12' lg='6' className='px-lg-5'>
                            {clubsMoreVisited?.slice(0, 5).map((club, i) => (
                                <div key={i} xs={12} className="my-3 clubsMoreVisited">
                                    <Link to={`/${t("url.marketplace.clubs")}/${club?.url}`} className='link-oscuro'>
                                        <div className='d-flex flex-wrap sports'>
                                            {club?.sports?.map ((element, i) => {
                                                return (
                                                    <div className='item' key={i}>
                                                        {t('sports.'+element)}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='dataclub'>
                                            <div className='d-flex align-items-end flex-grow-1'>
                                                <div className='avatar'>
                                                { club?.avatar
                                                    ?
                                                    <img src={club?.avatar} alt={club?.name}/>
                                                    :
                                                    <img src="/images/avatar-club.png" alt={club?.name}/>
                                                }
                                                </div>
                                                <div className='ps-1 flex-grow-1'>
                                                    {club?.name}
                                                </div>
                                            </div>
                                            <div className='linkclub'>
                                                <img src="images/flechalink.svg" alt="Continuar" className="ms-3"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                            <Row xs={12} className="mt-5 mb-3">
                                <Link to={`/${t("url.marketplace.clubs")}`} className='btn btn-primary'>
                                    {t('global.showallclubs')}
                                </Link>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='marketplace mb-0'>
                <Container className='market-border'>
                    <Row>
                        <Col>
                            <h2 className='display-6 text-center'>{t('marketplace.lastactivities') }</h2>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Grid type="activity" data={activitiesMoreVisited} columns={3} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <BannerFullWidth />
            {/* <div className='marketplace mb-0'>
                <Container className='market'>
                    <Row>
                        <Col xs='12 my-5 pt-5'>
                            <h2 className='display-6 text-center'>{t('marketplace.userexperience') }</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Grid type="opinion"  />
                        </Col>
                    </Row>
                </Container>
            </div> */}
            <div className='marketplace mb-0 py-sm-4'>
            </div>
            {/* <div className='marketplace mb-0'>
                <Container className='market h-100'>
                    <Row className='h-100'>
                        <Col xs={12} lg={8} className='columna1 d-flex flex-column'>
                            <Suscribete />
                        </Col>
                        <Col xs={12} lg={4} className='columna2 d-flex flex-column'>
                            <Row className='flex-grow-0 mt-3 mt-sm-0'>
                                <Col>
                                    <UsersRegistered />
                                </Col>
                            </Row>
                            <Row className='mt-3 flex-grow-1 d-flex'>
                                <Col className='d-flex flex-column'>
                                    <BannerPhrase
                                        className='flex-grow-1'
                                        phrase={t('marketplace.suscribetitle')}
                                        image="/images/banner-incrono01.jpg"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            <FooterMarketplace className='m-0'/>
        </div>
    )
}