import { createRef } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import Loader from '../../design/Loader/Loader'
import { Link } from 'react-router-dom'

export default function CourtForm({state, usertype, isAddingOrEditing, submit, inputChange, checkboxChange, selectChange, handleImage, sites, sports}) {
    const { t } = useTranslation()
    const fileRef = createRef("<HTMLInputElement>")
    
    return (
        <Form onSubmit={submit}>
            <Row className="mt-3 mb-4">
                <Col xs={12} lg={6} className='d-flex align-items-center'>
                    <div className='avatar-cuadrado me-2'>
                        {state?.image
                            ?
                                <img className="img-profile" alt="Preview" src={typeof state?.image === 'string' ? state?.image : URL.createObjectURL(state?.image)} />
                            :
                                <div className="img-profile"></div>
                        }
                        <input 
                            className='custom-file-input' 
                            style={{ display: 'none' }}
                            ref={fileRef}
                            type="file" 
                            name="myImage" 
                            onChange={handleImage} 
                        />
                    </div>
                    <div className="editaravatar" onClick={() => fileRef.current?.click()}>{t('sites.avatar')}</div>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control
                            className={state?.name ? 'has-content' : ''}
                            type='text'
                            name='name'
                            value={state?.name ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='name'>{t('sites.name')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group>
                        <Form.Label htmlFor='active'>{t(`sites.status`)}</Form.Label>
                        <div className='d-flex ms-4'>
                            <Form.Check
                                type="switch"
                                name="active"
                                onChange={checkboxChange}
                                checked={state?.active || false}
                            />
                            <span className="ms-3">
                                {state?.active
                                ?
                                    <>{t('sites.active')}</>
                                :
                                    <>{t('sites.inactive')}</>
                                }
                            </span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control has-content"
                            name='sites'
                            options={sites}
                            value={state?.site}
                            onChange={(e) => selectChange(e, 'site')}
                            required
                        />
                        <Form.Label htmlFor='sites'>{t('courts.site')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group>
                        <Form.Label htmlFor='rentable'>{t(`courts.isRentable`)}</Form.Label>
                        <div className='d-flex ms-4'>
                            <Form.Check
                                type="switch"
                                name="rentable"
                                onChange={checkboxChange}
                                checked={state?.rentable || false}
                            />
                            <span className="ms-3">
                                {state?.rentable
                                ?
                                    <>{t('courts.rentable')}</>
                                :
                                    <>{t('courts.notRentable')}</>
                                }
                            </span>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Select
                            className="form-control has-content"
                            name='sports'
                            options={sports}
                            value={state?.sports || []}
                            onChange={(e) => selectChange(e, 'sports')}
                            isMulti
                            required
                        />
                        <Form.Label htmlFor='sports'>{t('sites.sports')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={6} lg={3}>
                    <Form.Group className='form-group'>
                        <Form.Control
                            className={state?.minSlots ? 'has-content' : ''}
                            type='number'
                            name='minSlots'
                            value={state?.minSlots ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='minSlots'>{t('courts.minslots')}</Form.Label>
                    </Form.Group>
                </Col>
                {/* <Col xs={6} lg={3}>
                    <Form.Group className='form-group'>
                        <Form.Control
                            className={state?.maxSlots ? 'has-content' : ''}
                            type='number'
                            name='maxSlots'
                            value={state?.maxSlots ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='maxSlots'>{t('courts.maxslots')}</Form.Label>
                    </Form.Group>
                </Col> */}
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control
                            className={state?.onwardsDays ? 'has-content' : ''}
                            type='number'
                            name='onwardsDays'
                            value={state?.onwardsDays ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='onwardsdays'>{t('courts.onwardsdays')}</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                    <Form.Group className='form-group'>
                        <Form.Control
                            className={state?.freeCancelDays ? 'has-content' : ''}
                            type='number'
                            name='freeCancelDays'
                            value={state?.freeCancelDays ||  ''}
                            onChange={inputChange}
                            required
                        />
                        <Form.Label htmlFor='freecanceldays'>{t('courts.freecanceldays')}</Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={6}>
                    <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.courts")}` : `/${t("url.club.club")}/${t("url.club.courts")}`}><Button variant="default" className="w-100 mt-3">{t('courts.backtolist')}</Button></Link>
                </Col>
                <Col xs={6}>
                    {isAddingOrEditing ?
                        <Loader/>
                    :
                        <Button type="submit" variant="primary" className='mt-3 w-100'>
                            {t('global.save')}
                        </Button>
                    }
                </Col>
            </Row>
        </Form>
    )    
}
