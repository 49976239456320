import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getActivitiesByClub } from '../../../../actions/activities/activitiesActions'
import { getCategoriesByClub } from '../../../../actions/activities/categoriesActions'
import { getGroup, updateGroup } from '../../../../actions/activities/groupsActions'
import GroupForm from '../../../../components/forms/activities/GroupForm'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions'

export default function GroupEdit({usertype, groupselected}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {group, resultgroup, loadinggroup} = useSelector(state=>state.groups)
	const {club} = useSelector(state=>state.clubs)
	const {commission} = useSelector(state=>state.commissions)
	const {activities} = useSelector(state=>state.activities)
	const {categories} = useSelector(state=>state.categories)

    const [editGroup, setEditGroup] = useState()
    useEffect(()=>{
        if(club && groupselected){
            dispatch(getCommissionByClub(club?._id))
            dispatch(getActivitiesByClub(club?._id))
            dispatch(getCategoriesByClub(club?._id))
            dispatch(getGroup(groupselected))
        }
    //eslint-disable-next-line
    }, [dispatch, club, groupselected])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(group && group?._id === groupselected){
            setEditGroup({
                title: group?.title,
                activity: group?.activity,
                category: group?.category,
                horaries: group?.horaries,
                seasonStart: group?.seasonStart,
                seasonEnd: group?.seasonEnd,
                seasonPayments: group.seasonPayments || [],
                registrationStart: group?.registrationStart,
                registrationEnd: group?.registrationEnd,
                registrationPayment: group?.registrationPayment ? {
                    ...group?.registrationPayment,
                    maxdate: group?.registrationPayment?.maxdate?.substring(0, 10) || null
                } : null,
                assignableHorary: group?.assignableHorary,
                verify: group?.verify,
                postpayverified: group?.postpayverified,
                federated: group?.federated,
                questions: group?.questions,
                active: group?.active,
            })
        }
    //eslint-disable-next-line
    }, [group, groupselected])

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        const nameParts = name.split(/[\[\].]+/).filter(Boolean)

        setEditGroup((prevState) => {
            if (nameParts.length > 1) {
                const [arrayName, index, field] = nameParts;

                return {
                    ...prevState,
                    [arrayName]: prevState[arrayName].map((item, i) =>
                        i === parseInt(index) ? { ...item, [field]: field === 'price' ? parseFloat(value) : value } : item
                    )
                }
            } else {
                // Para campos simples no anidados
                return {
                    ...prevState,
                    [name]: value
                }
            }
        })
    }

    const handleInputRegistrationChange = ({target})=>{
        setEditGroup({
            ...editGroup,
            registrationPayment: {
                ...editGroup.registrationPayment,
                [target.name]: target.value
            }
        })
    }

    const handleCheckboxChange = ({target}) => {
        setEditGroup({
            ...editGroup,
            [target.name]: target.checked
        })
    }

    const handleDaterangeChange = (value, index) => {
        if(value){
            setEditGroup({
                ...editGroup,
                [`${index}Start`]: value[0],
                [`${index}End`]: value[1]
            })
        }else{
            setEditGroup({
                ...editGroup,
                [`${index}Start`]: null,
                [`${index}End`]: null
            })
        }
    }

    const handleSelectChange = (target, selectType) => {
        setEditGroup({
            ...editGroup,
            [selectType]: target.value
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(updateGroup(editGroup, groupselected))
    }

    useEffect(() => {
        if(resultgroup){
            let newresult = resultgroup
            dispatch({
                type: 'GROUP_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('groups.editedsuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.groups")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.groups")}`)
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultgroup])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('groups.editgroup')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <GroupForm state={editGroup} club={club} activities={activities} categories={categories} commission={commission} statetype={'edit'} usertype={usertype} isAdding={loadinggroup} submit={handleAdd} inputChange={handleInputChange} selectChange={handleSelectChange} inputRegistrationChange={handleInputRegistrationChange} checkboxChange={handleCheckboxChange} daterangeChange={handleDaterangeChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}