import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getPassesByClient = (client) => async (dispatch) => {
    dispatch({
        type: 'PASS_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/pass/getPassesByClient/'+ client, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASS_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'PASS_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASS_NOT_LOADING'
        })
    }
}

export const getPassesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'PASS_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/pass/getPassesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASS_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'PASS_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASS_NOT_LOADING'
        })
    }
}

export const getPasses = () => async (dispatch) => {
    dispatch({
        type: 'PASS_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/pass/getAll', {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASS_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'PASS_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASS_NOT_LOADING'
        })
    }
}

export const changePassStatus = (passId, userId, passStatus) => async (dispatch) => {
    dispatch({
        type: 'PASS_LOADING'
    })
    try {
        const { data, status } = await Axios.put('/api/pass/changeStatus', {user: userId, pass: passId, status: passStatus}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASS_CHANGE_STATUS_SUCCESS',
                payload: data?.message
            })
        } else {
            dispatch({
                type: 'PASS_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASS_NOT_LOADING'
        })
    }
}

export const getPassesByPaymentClient = (client) => async (dispatch) => {
    dispatch({
        type: 'PASS_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/pass/getPassesByPaymentClient/'+ client, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASS_GET_ALL_SUCCESS',
                payload: data
            })
        } else {
            dispatch({
                type: 'PASS_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASS_NOT_LOADING'
        })
    }
}

export const createPass = (passData) => async (dispatch) => {
    try {
        const { data, status } = await Axios.post('/api/pass/createPass', passData, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PASS_CREATE_PASS_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PASS_NOT_LOADING'
        })
    }
}