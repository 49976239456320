import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export default function SimpleSchedule({ value, removeSchedule, addSchedule, handleDayCheck, onScheduleInputChange, title, isRequired = true}) {
    const { t } = useTranslation()

    const handleClick = (dayName, index) => {
        const fakeEvent = {
            target: {
                name: dayName,
                checked: !value[index].weekDays.includes(dayName)
            }
        }
        handleDayCheck(fakeEvent, index)
    }

    return (
        <Row className="mb-3">
            <Col xs={12} className="mb-3">
                {title}
                <hr className="fina"/>
            </Col>
            {value?.map((day, index) => (
                <Col xs={12} className="my-3" key={index}>
                    <Row>
                        <Col className="d-flex">
                            {value.length > 1 && (
                                <Col className="d-grid justify-content-center" style={{ width: '50px' }}>
                                    <Button variant="vertical" role="button" className="d-flex" onClick={() => { removeSchedule(day.weekDays, index) }}>
                                        <img src="/images/close.svg" alt="Close" />
                                    </Button>
                                </Col>
                            )}
                            <Col xs={value.length > 1 ? 11 : 12}>
                                <Form.Label>{t('schedule.selectDay')}</Form.Label>
                                <div className="d-flex justify-content-evenly">
                                    {['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(d => {
                                        const isChecked = day.weekDays.includes(d) || day.weekDays.includes('all')
                                        return (
                                            <div
                                                className={`dia ${isChecked ? 'checked' : ''}`}
                                                key={d}
                                                onClick={() => handleClick(d, index)}
                                            >
                                                <Form.Label htmlFor={`${d}-${index}`} className="cursor">
                                                    {t('schedule.' + d)}
                                                </Form.Label>
                                                <Form.Check
                                                    name={d}
                                                    id={`${d}-${index}`}
                                                    onChange={({ target }) => handleDayCheck(target, index)}
                                                    checked={isChecked}
                                                />
                                               
                                            </div>
                                        )
                                    })}
                                </div>
                                <Row className="my-3">
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>{t('sites.startHour')}</Form.Label>
                                            <Form.Control required={isRequired} type="time" name="startHour" value={day.startHour || ''} onChange={({ target }) => { onScheduleInputChange(target, day.weekDays) }} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Label>{t('sites.endHour')}</Form.Label>
                                        <Form.Control required={isRequired} type="time" name="endHour" value={day.endHour || ''} onChange={({ target }) => { onScheduleInputChange(target, day.weekDays) }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            ))}
            <Col xs={12} className="border-top border-bottom border-black py-3 text-center">
                <Button onClick={() => { addSchedule() }} className="link-oscuro text-decoration-none">
                    <i className="fas fa-plus"/> {t('schedule.addSchedule')}
                </Button>
            </Col>
        </Row>
    )
}
