import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function PassTypeMarket({passType}) {
    const { t } = useTranslation()
    const backCardImage = {
        backgroundImage: passType?.image ? `url(${passType?.image})` : `url("/images/bannerclub.jpg")`,        
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    return (
        <Card className="miniatura">
            <Link to={`/${t("url.marketplace.clubs")}/${passType?.club?.url}/${t("url.marketplace.passTypes")}/${passType?.url}`}>
                <div className="image" variant="top" style={backCardImage}></div>
            </Link>
            <Card.Body>
                <Card.Title>{passType.name}</Card.Title>
                <Row className="mt-2">
                    <Col className="d-flex align-items-center">
                        <img src="/images/pass-people.svg" alt="people" className="me-2"/>                    
                        {t('passTypes.marketplacePeople' ,{ people: passType?.people })}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="d-flex align-items-center">
                        <img src="/images/sector.svg" alt="sector" className="me-2"/>                    
                        {passType?.sector?.name}
                    </Col>
                </Row>
                {/* <Row className="mt-2">
                    <Col className="d-flex align-items-center">
                        <img src={`/images/sports/iconos/${passType?.sport}.svg`} alt="actividad" className="me-2"/>
                        {t('sports.' + (passType?.sport || ''))}
                    </Col>
                </Row> */}
                <Row className="mt-4">
                    <Col className="d-flex align-items-center justify-content-between flex-wrap">
                        <span className="price">
                            {passType?.price !== 0
                                ? `${t("global.from")} ${passType?.price?.toFixed(2)}€`
                                : "Gratuita"}
                        </span>
                        <Link
                            to={`/${t("url.marketplace.clubs")}/${passType?.club?.url}/${t("url.marketplace.passTypes")}/${passType?.url}`}
                            className="btn btn-primary"
                        >
                            {t("passTypes.buy")}
                            <img src="/images/rignt-arrow.svg" alt="Continuar" className="ms-3"/>
                        </Link>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}