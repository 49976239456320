import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Form, Modal, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import useAuth from '../../../../context/auth/useAuth'
import { getCourtsByClub } from '../../../../actions/sites/courtsActions'
import { getReservationsByClubAndDate, rentCourt, cancelReservation } from '../../../../actions/sites/reservationsActions'
import SitesSubMenu from '../../../../components/menus/SitesSubMenu'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'
import Swal from 'sweetalert2'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Select from "react-select"

export default function CourtsRent({usertype}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {courts} = useSelector(state=>state.courts)
    const {reservations, resultreservation, reservationsReserved} = useSelector(state=>state.reservations)
    const {club} = useSelector(state=>state.clubs)

    const [show, setShow] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)
    const handleClose = () => setShow(false)


    const [formState, setFormState] = useState({
        court: null,
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        date: null,
        startHour: '00:00',
        endHour: '00:00',
        duration: 0,
        price: 0,
        typePayment: 0,
        status: 1
    })

    const handleInputChange = ({target}) => {
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handlePeriodChange = (value) => {
        setDateNewRent([value ? value[0] : null, value ? value[1] : null])
    }

    const handleDaysChange = (selectedOptions) => {
        let combinedDays = selectedOptions

        setFormState({
            ...formState,
            weekDays: combinedDays
        })
    }

    const [dateNewRent, setDateNewRent] = useState(() => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})
        return new Date(madridDate)
    })

    const [selectedDate, setSelectedDate] = useState(() => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})
        return new Date(madridDate)
    })

    const [groupedReservations, setGroupedReservations] = useState([])


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club){
            dispatch(getCourtsByClub(club?._id))
        }
    // eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if(isPrivate === true || isPrivate === false){
            setFormState({
                court: null,
                name: '',
                phone: '',
                email: '',
                weekDays: [],
                startHour: '00:00',
                endHour: '00:00',
                duration: 0,
                price: 0,
                status: 1
            })

            if(isPrivate){
                setDateNewRent(null)
                setFormState({
                    ...formState,
                    weekDays: []
                })
            }else{
                setDateNewRent(() => {
                    const date = new Date()
                    date.setHours(0, 0, 0, 0)
                    const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})
                    return new Date(madridDate)
                })
                setFormState({
                    ...formState,
                    weekDays: []
                })
            }
        }
    // eslint-disable-next-line
    },[isPrivate])

    useEffect(() => {
        if(clientauth && club && selectedDate){
            const madridTimeZone = new Date(selectedDate).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric',month: '2-digit', day: '2-digit'})

            dispatch(getReservationsByClubAndDate(club?._id, madridTimeZone))
        }
    // eslint-disable-next-line
    },[selectedDate, clientauth, club])

    useEffect(() => {
        if (reservations && courts) {
            const grouped = {};

            // Paso 1: Inicializar todas las pistas con días vacíos
            courts.forEach(court => {
                const courtId = court._id; // Asegúrate de que cada pista tiene un identificador único
                grouped[courtId] = {};
    
                const startDate = new Date(selectedDate);
                const endDate = new Date(selectedDate);
                // endDate.setDate(endDate.getDate() + 2); // Activar si necesitas más días
    
                for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                    grouped[courtId][format(d, 'yyyy-MM-dd')] = [];
                }
            });
    
            // Paso 2: Llenar las reservas en los días correspondientes
            reservations.forEach(reservation => {
                if (!reservation.date) return; // Validación para asegurar que 'date' esté presente
                const courtId = reservation.court;
                const reservationDay = format(new Date(reservation.date), 'yyyy-MM-dd'); // Asegúrate de que 'date' es válida
    
                if (!grouped[courtId]) {
                    // Este caso no debería ocurrir si todas las pistas están inicializadas correctamente
                    grouped[courtId] = {};
                }
    
                if (!grouped[courtId][reservationDay]) {
                    grouped[courtId][reservationDay] = [];
                }
    
                grouped[courtId][reservationDay].push(reservation);
            });

            setGroupedReservations(grouped);
        }
    // eslint-disable-next-line
    }, [reservations, courts]);


    const newRentCourt = async(e) => {
        e.preventDefault()

        let weekDaysSaved = []

        if(formState?.weekDays?.length > 0){
            for(const day of formState.weekDays) {
                weekDaysSaved.push(day.value)
            }
        }

        dispatch(rentCourt({...formState, date: dateNewRent, dateToView: selectedDate, weekDays: weekDaysSaved, club: club?._id}))
    }

    const handleCancelReservation = async(e, reservation) => {
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true, // Botón principal (simple)
            showCancelButton: true,  // Botón de cancelar
            showDenyButton: reservation.reserveReference ? true : false,    // Botón adicional (múltiple)
            confirmButtonText: reservation.reserveReference ? t('reservations.acceptsimple') : t('global.accept'), // Texto del botón principal
            denyButtonText: t('reservations.acceptmultiple'),  // Texto del botón adicional
            cancelButtonText: t('global.return'), // Texto del botón de cancelar
            icon: 'warning',
            text: reservation.reserveReference ? t('reservations.cancelmultiple') : t('reservations.cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(cancelReservation(reservation._id,false));
                handleCloseModal();
            } else if (result.isDenied) {
                dispatch(cancelReservation(reservation._id,true));
                handleCloseModal();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                handleCloseModal();
            }
        });

    }

    useEffect(() => {
        if(resultreservation){
            switch (resultreservation) {
                case 'success':
                case 'successmulti':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'success',
                        text: t('reservations.addsuccess')
                    }).then(() => {
                        handleClose()
                        const date = new Date()
                        date.setHours(0, 0, 0, 0)
                        const madridDate = new Date(date).toLocaleDateString('sv-SE', {timeZone: 'Europe/Madrid', year: 'numeric', month: '2-digit', day: '2-digit'})

                        setDateNewRent(new Date(madridDate))

                        setFormState({
                            court: null,
                            firstname: '',
                            lastname: '',
                            phone: '',
                            email: '',
                            date: new Date(madridDate),
                            startHour: '00:00',
                            endHour: '00:00',
                            duration: 0,
                            price: 0,
                            status: 1
                        })
                    })
                    dispatch({
                        type: 'RESERVATION_RESET_RESULT'
                    })
                    break;
                case 'alreadyReserve':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.accept'),
                        icon: 'error',
                        html: t('reservations.alreadyreserved')+'<br/>'+reservationsReserved?.map(reservation => reservation.personData?.firstname+' '+reservation.personData?.lastname+' ('+t('reservations.phone')+': '+reservation.personData?.phone+')').join(', ')
                    })
                    dispatch({
                        type: 'RESERVATION_RESET_RESULT'
                    })
                    break
                case 'alreadyReserveMulti':
                    Swal.fire({
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: t('global.accept'),
                        cancelButtonText: t('global.cancel'),
                        icon: 'warning',
                        html: `
                            ${t('reservations.alreadyreservedMulti')}<br/>
                            <div class='lista-reservados'>
                                ${reservationsReserved?.map(reservation => {
                                    // Formatear la fecha como día/mes/año
                                    const formattedDate = new Date(reservation.date).toLocaleDateString(undefined, {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric'
                                    });
                                    return `
                                        <div class='reserva'>
                                            ${formattedDate}: ${reservation.personData?.firstname} ${reservation.personData?.lastname} (${t('reservations.phoneMin')}: ${reservation.personData?.phone})
                                        </div>`;
                                }).join('')}
                            </div>
                        `,
                        reverseButtons: true
                    }).then(result => {
                        if (result.isConfirmed) {
                            let weekDaysSaved = []

                            if(formState?.weekDays?.length > 0){
                                for(const day of formState.weekDays) {
                                    weekDaysSaved.push(day.value)
                                }
                            }
                    
                            dispatch(rentCourt({...formState, date: dateNewRent, weekDays: weekDaysSaved, club: club?._id}, true))
                        }
                    })
                    dispatch({
                        type: 'RESERVATION_RESET_RESULT'
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [resultreservation])

    const [showModal, setShowModal] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);

    const handleShowModal = (reservation) => {
        setSelectedReservation(reservation)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setSelectedReservation(null)
        setShowModal(false)
    }

    // Generar intervalos de 30 minutos en el día
    const generateTimeSlots = (startHour, endHour) => {
        const slots = []
        let currentHour = Math.floor(startHour)
        let currentMinute = Math.round((startHour - currentHour) * 60)

        while (currentHour < Math.floor(endHour) || (currentHour === Math.floor(endHour) && currentMinute < (endHour - Math.floor(endHour)) * 60)) {
            const start = `${currentHour < 10 ? `0${currentHour}` : currentHour}:${currentMinute < 10 ? `0${currentMinute}` : currentMinute}`
            let endHourDisplay = currentHour
            let endMinute = (currentMinute + 30) % 60
            if (endMinute === 0) {
                endHourDisplay = (endHourDisplay + 1) % 24
            }
            const end = `${endHourDisplay < 10 ? `0${endHourDisplay}` : endHourDisplay}:${endMinute < 10 ? `0${endMinute}` : endMinute}`
            slots.push({ start, end })

            currentMinute += 30
            if (currentMinute >= 60) {
                currentHour++
                currentMinute = 0
            }
        }

        return slots
    }

    // Función para obtener intervalos de media hora en el rango de reserva
    const getSlotsInRange = (start, end) => {
        const [startHour, startMinute] = start.split(':').map(Number)
        const [endHour, endMinute] = end.split(':').map(Number)

        const slots = []
        let currentHour = startHour
        let currentMinute = startMinute

        while (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)) {
            slots.push(`${currentHour}:${currentMinute === 0 ? '00' : '30'}`)
            currentMinute += 30
            if (currentMinute === 60) {
                currentMinute = 0
                currentHour += 1
            }
        }

        return slots
    }

    // Asegura que el formato de tiempo esté en formato 'HH:mm'
    const formatTime = (time) => {
        const [hours, minutes] = time.split(':').map(Number)
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
    }
    // Función para avanzar un día
    const incrementDate = () => {
        setSelectedDate(prevDate => new Date(prevDate.getTime() + 24 * 60 * 60 * 1000)); // Añade 1 día
    }
    // Función para retroceder un día
    const decrementDate = () => {
        setSelectedDate(prevDate => new Date(prevDate.getTime() - 24 * 60 * 60 * 1000)); // Resta 1 día
    }

    return (
        <>
            <Container className='cuerpo'>
                <ClubBreadCrumbs usertype={usertype} club={club} />
                <Row className="my-3">
                    <Col>
                        <SitesSubMenu usertype={usertype} active="courtsrents"/>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col>
                        <Card>
                            <Card.Body>
                                <div className='d-flex justify-content-between'>
                                    <h2>{t('courts.courtsrentslist')}</h2>
                                    <div className='colors d-flex align-items-center gap-1'>
                                        <div className='color-sample' style={{ backgroundColor: '#5570F1' }}></div>
                                        <small>{t('reservations.busy')}</small>
                                        <div className='ms-2 color-sample' style={{ backgroundColor: '#F14C55' }}></div>
                                        <small>{t('reservations.club')}</small>
                                        <div className='ms-2 color-sample' style={{ backgroundColor: '#F1D14C' }}></div>
                                        <small>{t('reservations.manual')}</small>
                                        <div className='ms-2 color-sample' style={{ backgroundColor: '#F1A04C' }}></div>
                                        <small>{t('reservations.transfer')}</small>
                                        <div className='ms-2 color-sample' style={{ backgroundColor: '#05C0C7' }}></div>
                                        <small>{t('reservations.bizum')}</small>

                                    </div>
                                </div>
                                <hr className='mb-4'/>
                                <Row className='my-3'>
                                    <Col xs={12} md={4}>
                                        {/* Selección de fecha */}
                                        <Form.Group controlId="selectDate">
                                            <Form.Label>{t('marketplace.reservation.selectdate')}</Form.Label><br/>
                                            <div className="d-flex align-items-center">
                                                {/* Botón para retroceder un día */}
                                                <button
                                                    type="button"
                                                    onClick={decrementDate}
                                                    style={{
                                                        marginRight: '8px',
                                                        padding: '5px 10px',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                   <i className='fas fa-chevron-left'></i>
                                                </button>

                                                {/* DatePicker */}
                                                <DatePicker
                                                    locale={es}
                                                    className='form-select'
                                                    selected={selectedDate}
                                                    onChange={date => setSelectedDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText={t('marketplace.reservation.selectdate')}
                                                />

                                                {/* Botón para avanzar un día */}
                                                <button 
                                                    type="button" 
                                                    onClick={incrementDate} 
                                                    style={{
                                                        marginLeft: '8px',
                                                        padding: '5px 10px', 
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <i className='fas fa-chevron-right'></i>
                                                </button>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <div className='buttons d-flex justify-content-end mt-3'>
                                            <Button variant="default" onClick={() => {setIsPrivate(false);setFormState({...formState,startHour:'00:00'});setShow(true)}}>
                                                <i className='fas fa-plus'></i> {t('courts.newrent')}
                                            </Button>
                                            <Button variant="default" className='ms-3' onClick={() => {setIsPrivate(true); setShow(true)}}>
                                                <i className='fas fa-plus'></i> {t('courts.newrentprivate')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4>
                                            {new Intl.DateTimeFormat('es-ES', { day: 'numeric', month: 'long', weekday: 'long' }).format(new Date(selectedDate))}
                                        </h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='pistas'>
                                        {courts?.length > 0
                                            ?
                                            <>
                                                {courts.map((court, index) => (
                                                    <Col className='pista'>
                                                        <Card key={court._id} className='mx-1' eventKey={String(index)}>
                                                            <Card.Body className='p-0'>
                                                                <Row>
                                                                    <Col>
                                                                        <h5>{court.name}</h5>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {Object.keys(groupedReservations[court._id] || {}).sort((a, b) => new Date(a) - new Date(b)).map(day => (
                                                                        <Col key={day} className="p-0 dato-reserva">
                                                                            {court.schedules
                                                                                .filter(sch =>
                                                                                    sch.weekDays.includes(format(new Date(day), 'eeee').toLowerCase()) &&
                                                                                    (!sch.startDate || new Date(day) >= new Date(sch.startDate)) &&
                                                                                    (!sch.endDate || new Date(day) <= new Date(sch.endDate))
                                                                                )
                                                                                .sort((a, b) => a.startHour.localeCompare(b.startHour))
                                                                                .map(sch => {
                                                                                    const [startHours, startMinutes] = sch.startHour.split(':').map(Number);
                                                                                    const newStartHour = startHours + (startMinutes / 60);
                                                                                    const [endHours, endMinutes] = sch.endHour.split(':').map(Number);
                                                                                    const newEndHour = endHours + (endMinutes / 60);
                                                                                    return generateTimeSlots(newStartHour, newEndHour).map(slot => {
                                                                                        const normalizedSlotStart = formatTime(slot.start);
                                                                                        const reservationsForSlot = (groupedReservations[court._id][day] || []).filter(res => {
                                                                                            const reservedSlots = getSlotsInRange(res.startHour, res.endHour).map(formatTime);
                                                                                            return reservedSlots.includes(normalizedSlotStart);
                                                                                        });
                                                                                        const isReserved = reservationsForSlot.length > 0;
                                        
                                                                                        const color = () => {
                                                                                            if (reservationsForSlot.some(res => res.status === 1 && res.typePayment === 0)) return 'reservation-success'
                                                                                            if (reservationsForSlot.some(res => res.status === 1 && res.typePayment === 1)) return 'reservation-free'
                                                                                            if (reservationsForSlot.some(res => res.status === 1 && res.typePayment === 2)) return 'reservation-manual'
                                                                                            if (reservationsForSlot.some(res => res.status === 1 && res.typePayment === 3)) return 'reservation-transfer'
                                                                                            if (reservationsForSlot.some(res => res.status === 1 && res.typePayment === 4)) return 'reservation-bizum'
                                                                                            if (reservationsForSlot.some(res => res.status === 0)) return 'reservation-pending'
                                                                                            if (reservationsForSlot.some(res => res.status === 2)) return 'reservation-cancel'
                                                                                            return 'reservation-cancel'
                                                                                        };

                                                                                        const name = () => {
                                                                                            if (isReserved) {
                                                                                                const names = reservationsForSlot.map(res => res?.personData?.firstname || res?.paymentClient?.firstname).join(', ');
                                                                                                return <span title={names}>{names}</span>;
                                                                                            } else {
                                                                                                return false;
                                                                                            }
                                                                                        };

                                                                                        return (
                                                                                            <div
                                                                                                key={slot.start}
                                                                                                className={`p-2 my-1 cursor reservation ${color()}`}
                                                                                                onClick={() => {
                                                                                                    if (isReserved) {
                                                                                                        handleShowModal(reservationsForSlot)
                                                                                                    } else {
                                                                                                        const currentDay = new Date()
                                                                                                        const [hour, minute] = slot.start.split(':')
                                                                                                        currentDay.setHours(hour, minute, 0, 0)
                                                                                                        const totalMinutesToAdd = court.maxSlots * court.timeSlot
                                                                                                        currentDay.setMinutes(currentDay.getMinutes() + totalMinutesToAdd)
                                                                                                        const hours = currentDay.getHours().toString().padStart(2, '0')
                                                                                                        const minutes = currentDay.getMinutes().toString().padStart(2, '0')
                                                                                                        setFormState({
                                                                                                            ...formState,
                                                                                                            court: court._id,
                                                                                                            startHour: slot.start,
                                                                                                            endHour: hours + ':' + minutes
                                                                                                        })
                                                                                                        setIsPrivate(false)
                                                                                                        setShow(true)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {slot.start}: {name() && <small>({name()})</small>}
                                                                                            </div>
                                                                                        );
                                                                                    });
                                                                                })}

                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </>
                                            :
                                            <p>{t('courts.thereisnotcourts')}</p>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
             {/* Modal para mostrar detalles de la reserva */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('courts.reservationdetails')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {selectedReservation?.length > 0 ? (
                    selectedReservation.map((reservation, index) => (
                        <Row key={reservation._id}>
                            <Col>
                            <div className='d-flex justify-content-between my-2'><strong>{t('courts.time')}:</strong> {reservation.startHour} - {reservation.endHour}</div>
                            <div className='d-flex justify-content-between my-2'><strong>{t('courts.reservationof')}:</strong> {reservation.paymentClient
                                ? `${reservation.paymentClient.firstname} ${reservation.paymentClient.lastname} (${reservation.paymentClient.phone || t('courts.nophone')} - ${reservation.paymentClient.email || t('courts.noemail')})`
                                : `${reservation.personData.firstname} ${reservation.personData.lastname} (${reservation.personData.phone || t('courts.nophone')} - ${reservation.personData.email || t('courts.noemail')})`
                            }</div>
                            <div className='d-flex justify-content-between my-2'><strong>{t('courts.status')}:</strong> {reservation.status === 1 ? t('courts.confirmed') : reservation.status === 0 ? t('courts.pending') : t('courts.canceled')}</div>
                            <div className='d-flex justify-content-between my-2'>
                                <strong>{t('reservations.typepayment')}: </strong>
                                {reservation.typePayment === 0 ? t('reservations.marketplace') : reservation.typePayment === 1 ? t('reservations.club') : reservation.typePayment === 2 ? t('reservations.manual') : reservation.typePayment === 3 ? t('reservations.transfer') : reservation.typePayment === 4 ? t('reservations.bizum') : ''}
                            </div>
                            <div className='d-flex justify-content-between my-2'><strong>{t('courts.price')}:</strong> {reservation.price} €</div>
                            {(reservation.status === 0 || reservation.status === 1) && (
                                <div className='d-flex justify-content-end my-3'>
                                    <Button variant="primary" onClick={(e) => {
                                        handleCancelReservation(e, reservation);
                                    }}>
                                        {t('courts.cancelreservation')}
                                    </Button>
                                </div>
                            )}
                            {index < selectedReservation.length - 1 && <hr />}
                            </Col>
                        </Row>
                    ))
                ) : (
                    <p>{t('courts.noreservationdetails')}</p>
                )}
                </Modal.Body>
            </Modal>
            {/* Modal formulario */}
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('reservations.newrent')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo px-5 py-4'>
                    <Form onSubmit={newRentCourt}>
                        {/* Seleccionar pista */}
                        <Row className="my-3">
                            <Col xs={12} lg={6}>
                                <Form.Group className='form-group'>
                                    <Form.Select
                                        name='court'
                                        className='has-content form-control'
                                        value={formState?.court || ''}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">{t('reservations.selectcourt')}</option>
                                        {courts?.length > 0 && courts.map(court => (
                                            <option key={court._id} value={court._id}>
                                                {court.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Label htmlFor='court'>{t('reservations.court')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group className='form-group'>
                                    <Form.Select
                                        name='typePayment'
                                        className='has-content form-control'
                                        value={formState?.typePayment || ''}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">{t('reservations.selecttype')}</option>
                                        <option value='1'>{t('reservations.club')}</option>
                                        <option value='2'>{t('reservations.manual')}</option>
                                        <option value='3'>{t('reservations.transfer')}</option>
                                        <option value='4'>{t('reservations.bizum')}</option>
                                    </Form.Select>
                                    <Form.Label htmlFor='court'>{t('reservations.typepayment')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} md={6}>
                                {/* Seleccionar firstname */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.firstname ? 'has-content' : ''}
                                        type='text'
                                        name='firstname'
                                        value={formState?.firstname || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='firstname'>{t('reservations.firstname')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {/* Seleccionar lastname */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.lastname ? 'has-content' : ''}
                                        type='text'
                                        name='lastname'
                                        value={formState?.lastname || ''}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Label htmlFor='lastname'>{t('reservations.lastname')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} md={6}>
                                {/* Seleccionar phone */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.phone ? 'has-content' : ''}
                                        type='text'
                                        name='phone'
                                        value={formState?.phone || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='phone'>{t('reservations.phone')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                {/* Seleccionar email */}
                                <Form.Group className="form-group">
                                    <Form.Control
                                        className={formState?.email ? 'has-content' : ''}
                                        type="text"
                                        name="email"
                                        value={formState?.email}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Label htmlFor='price'>{t('reservations.email')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                                {/* Seleccionar date */}
                                {isPrivate ?
                                    <>
                                        <Col xs={12} md={6}>
                                            <Form.Group className='form-group'>
                                                <DateRangePicker
                                                    className="has-content form-control"
                                                    onChange={(value) => {handlePeriodChange(value)}}
                                                    value={dateNewRent ? [dateNewRent[0], dateNewRent[1]] : [null, null]}
                                                    required
                                                />
                                                <Form.Label>{t('schedule.period')}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="form-group">
                                                <Select
                                                    className="form-control has-content"
                                                    name="weekDays"
                                                    options={[
                                                        { value: 1, label: t('schedule.monday') },
                                                        { value: 2, label: t('schedule.tuesday') },
                                                        { value: 3, label: t('schedule.wednesday') },
                                                        { value: 4, label: t('schedule.thursday') },
                                                        { value: 5, label: t('schedule.friday') },
                                                        { value: 6, label: t('schedule.saturday') },
                                                        { value: 0, label: t('schedule.sunday') },
                                                    ]}
                                                    value={formState?.weekDays || []}
                                                    onChange={(selectedOptions) => handleDaysChange(selectedOptions)}
                                                    isMulti
                                                    required
                                                />
                                                <Form.Label htmlFor="weekDays">{t('schedule.weekdays')}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>
                                :
                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="selectDate">
                                            <Form.Label>{t('marketplace.reservation.selectdate')}</Form.Label>
                                            <DatePicker
                                                locale={es}
                                                className='form-select'
                                                selected={dateNewRent}
                                                onChange={date => setDateNewRent(date)}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText={t('marketplace.reservation.selectdate')}
                                            />
                                        </Form.Group>
                                    </Col>
                                }
                        </Row>
                        <Row className='my-3'>
                            <Col xs={12} md={4}>
                                {/* Seleccionar startHour */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.startHour ? 'has-content' : ''}
                                        type='time'
                                        name='startHour'
                                        value={formState?.startHour || '00:00'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='startHour'>{t('reservations.starthour')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                {/* Seleccionar endHour */}
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={formState?.endHour ? 'has-content' : ''}
                                        type='time'
                                        name='endHour'
                                        value={formState?.endHour || '00:00'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='endHour'>{t('reservations.endhour')}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                {/* Seleccionar price */}
                                <Form.Group className="form-group">
                                    <Form.Control
                                        className="has-content"
                                        type="number"
                                        name="price"
                                        value={formState?.price}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <img src="/images/money.svg" alt="Money" className="icono-money" />
                                    <Form.Label htmlFor='price'>{t('reservations.price')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button variant='primary' type="submit" className="w-100 my-2">
                                    {t('reservations.rent')}
                                </Button>
                            </Col>
                        </Row>


                        {/* Seleccionar duration */}
                        {/* <Form.Group className="form-group">
                            <Form.Control
                                className="has-content"
                                type="number"
                                name="duration"
                                value={formState?.duration}
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Label htmlFor='duration' className='mt-2'>{t('reservations.duration')}</Form.Label>
                        </Form.Group> */}


                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}