import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useAuth from '../../context/auth/useAuth'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { dashboardClub } from '../../actions/payments/paymentActions'


export default function Dashboard() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {clientauth} = useAuth()

    const {club} = useSelector(state=>state.clubs)

    const {resume} = useSelector(state=>state.payment)

    const [totalQuantity,setTotalQuantity]= useState(0)
    const [totalRawQuantity,setTotalRawQuantity]= useState(0)
    const [totalRefunded,setTotalRefunded]= useState(0)
    const [totalBenefit,setTotalBenefit]= useState(0)

    const formatNumber = (num) => {
        const number = Number(num)
        return new Intl.NumberFormat('es-ES', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(num)
    }

    useEffect(() => {
        if(club){
            dispatch(dashboardClub(club?._id))
        }
    },[dispatch, t, club])

    useEffect(()=>{
        if(resume){
            setTotalQuantity(parseFloat(resume.totalQuantity) || 0)
            setTotalRawQuantity(parseFloat(resume.totalRawQuantity) || 0)
            setTotalRefunded(parseFloat(resume.totalRefunded) || 0)
            setTotalBenefit(parseFloat(resume.totalBenefit) || 0)
        }
    },[resume])

    return (
        <Container className='p-5'> 
            <Row>
                <Col>
                    <div className='sd-label-dashboard'>{t('club.dashboard.welcome')}</div>
                    <div className="sd-label-userdashboard">{club?.name}</div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>{t('club.dashboard.subwelcome')}</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={3} className='my-3'>
                    <Card>
                        <Link to={`/${t("url.club.club")}/${t("url.club.payments")}`} className='btn btn-dashboard'>
                            <h3 className='noBold'>{t('club.dashboard.payments')}</h3>
                        </Link>
                    </Card>
                </Col>
                <Col xs={6} lg={3} className='my-3'>
                    <Card className='text-center'>
                        <h3>{t('club.dashboard.balance')}</h3>
                        <h3>{formatNumber(parseFloat(totalRawQuantity.toString().replace(',', '.')) || 0)} €</h3>
                    </Card>
                </Col>
                <Col xs={6} lg={3} className='my-3'>
                    <Card className='text-center'>
                        <h3>{t('club.dashboard.refunded')}</h3>
                        <h3>{formatNumber(parseFloat(totalRefunded.toString().replace(',', '.')) || 0)} €</h3>
                    </Card>
                </Col>
            </Row>
            <Row>
            {
                club?.activitybooking
                ?
                    <>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.inscriptions")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.inscriptions')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.groups")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.groups')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.activities")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.activities')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.categories")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.categories')}</h3>
                                </Link>
                            </Card>
                        </Col>
                    </>
                :
                    <></>
            }
            </Row>
            <Row>
            {
                club?.renting
                ?
                    <>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.sites")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.sites')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.courts")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.courts')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.courtsrents")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.courtsrents')}</h3>
                                </Link>
                            </Card>
                        </Col>
                    </>
                :
                    <></>
            }
            </Row>
            <Row>
            {
                club?.ticketing
                ?
                    <>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.matches")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.matches')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.passes")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.passes')}</h3>
                                </Link>
                            </Card>
                        </Col>
                        <Col xs={12} lg={3} className='my-3'>
                            <Card>
                                <Link to={`/${t("url.club.club")}/${t("url.club.tickets")}`} className='btn btn-dashboard'>
                                    <h3 className='noBold'>{t('club.dashboard.tickets')}</h3>
                                </Link>
                            </Card>
                        </Col>
                    </>
                :
                    <></>
            }
            </Row>
        </Container>
    )
}