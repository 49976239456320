import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const addCartItem = (item) => async dispatch => {
    dispatch({ type: 'CART_LOADING' })

    try {
        let sessionObject = sessionStorage.getItem('cart')
        if (!sessionObject) {
            sessionObject = []
        } else {
            sessionObject = JSON.parse(sessionObject)
        }
        sessionObject.push(item)

        // Mandamos petición para aplicar las promociones más óptimas
        const response = await Axios.post('api/promotion/applyPromotion', {items: sessionObject}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        let finalSessionObject = [...sessionObject]


        if (response.status === 200 && response.data?.promotions) {
            finalSessionObject = await Promise.all(sessionObject.map((object, index) => {
                return {...object, promotions: response.data.promotions[index] || []}
            })).then(result => {
                return result
            })
        }

        sessionStorage.setItem('cart', JSON.stringify(finalSessionObject))
        dispatch({
            type: 'CART_SET',
            payload: {
                items: finalSessionObject,
                action: 'add'
            }
        })
        window.dispatchEvent(new Event('addedToCart'))
    } catch (error) {
        dispatch({
            type: 'CART_ERROR',
            payload: error.response.data
        })
    }
}

export const addMultipleCartItems = (items) => async dispatch => {
    dispatch({ type: 'CART_LOADING' })

    try {
        let sessionObject = sessionStorage.getItem('cart')
        if (!sessionObject) {
            sessionObject = []
        } else {
            sessionObject = JSON.parse(sessionObject)
        }
        
        sessionObject = sessionObject.concat(items)

        // Mandamos petición para aplicar las promociones más óptimas
        const response = await Axios.post('api/promotion/applyPromotion', {items: sessionObject}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        let finalSessionObject = [...sessionObject]


        if (response.status === 200 && response.data?.promotions) {
            finalSessionObject = await Promise.all(sessionObject.map((object, index) => {
                return {...object, promotions: response.data.promotions[index] || []}
            })).then(result => {
                return result
            })
        }

        sessionStorage.setItem('cart', JSON.stringify(finalSessionObject))
        dispatch({
            type: 'CART_SET',
            payload: {
                items: finalSessionObject,
                action: 'add'
            }
        })
        window.dispatchEvent(new Event('addedToCart'))
    } catch (error) {
        dispatch({
            type: 'CART_ERROR',
            payload: error.response.data
        })
    }
}

export const substituteCartItem = (item) => async dispatch => {
    try {
        let sessionObject = sessionStorage.getItem('cart')
        if (!sessionObject) {
            sessionObject = []
        } else {
            sessionObject = JSON.parse(sessionObject)
        }

        let newSessionObject = [...sessionObject]

        if (newSessionObject.some(object => item.court && object.court && item.court._id && object.court._id)) {
            newSessionObject = await Promise.all(await sessionObject.map(object => {
                if (item.court && object.court && item.court._id && object.court._id) {
                    return item
                } else {
                    return object
                }
            })).then(result => {
                return result
            })
        } else {
            newSessionObject.push(item)
        }
    
        // Mandamos petición para aplicar las promociones más óptimas
        const response = await Axios.post('api/promotion/applyPromotion', {items: newSessionObject}, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
    
        let finalSessionObject = [...newSessionObject]
    
        if (response.status === 200 && response.data?.promotions) {
            finalSessionObject = await Promise.all(newSessionObject.map((object, index) => {
                return {...object, promotions: response.data.promotions[index] || []}
            })).then(result => {
                return result
            })
        }
    
        sessionStorage.setItem('cart', JSON.stringify(finalSessionObject))
        dispatch({
            type: 'CART_SET',
            payload: {
                items: finalSessionObject,
                action: 'add'
            }
        })
        window.dispatchEvent(new Event('addedToCart'))
    } catch (error) {
        dispatch({
            type: 'CART_ERROR',
            payload: error.response?.data || error.message
        })
    }
}

export const removeCartItem = (position) => async dispatch => {
    let sessionObject = sessionStorage.getItem('cart')
    if (!sessionObject) {
        sessionObject = []
    } else {
        sessionObject = JSON.parse(sessionObject)
    }

    sessionObject.splice(position, 1)

    // Mandamos petición para aplicar las promociones más óptimas
    const response = await Axios.post('api/promotion/applyPromotion', {items: sessionObject}, {
        headers: { auth: sessionStorage.getItem('jwt') }
    })

    let finalSessionObject = [...sessionObject]


    if (response.status === 200 && response.data?.promotions) {
        finalSessionObject = await Promise.all(sessionObject.map((object, index) => {
            return {...object, promotions: response.data.promotions[index] || []}
        })).then(result => {
            return result
        })
    }

    sessionStorage.setItem('cart', JSON.stringify(sessionObject))
    dispatch({
        type: 'CART_SET',
        payload: {
            items: finalSessionObject,
            action: 'edit'
        }
    })
}

export const resetCart = () => async dispatch => {
    let sessionObject = sessionStorage.getItem('cart')
    sessionObject = []

    sessionStorage.setItem('cart', JSON.stringify(sessionObject))
    dispatch({
        type: 'CART_SET',
        payload: {
            items: sessionObject,
            action: 'edit'
        }
    })
}

export const getCart = (cartId, cartReference) => async dispatch => {
    dispatch({
        type: 'CART_LOADING'
    })
    try {
        const response = await Axios.get('api/cart/get/' + cartId + '/' + cartReference, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })

        if (!response.status || response.status > 299) {
            dispatch({
                type: 'CART_ERROR',
                payload: response.response.data.message || response.data.message || 'Unknown error'
            })
        } else {
            dispatch({
                type: 'CART_GET_SUCCESS',
                payload: response?.data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CART_ERROR',
            payload: error.response.data
        })
    }
}