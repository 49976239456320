import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { editPassType, getPassType } from '../../../../actions/ticketing/passTypesActions.js'
import PassTypeForm from '../../../../components/forms/passTypes/PassTypeForm.js'
import { getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import { getSeasonsByClub } from '../../../../actions/ticketing/seasonsActions.js'
import { ContentState, EditorState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions.js'

export default function EditPassType({usertype, selectedPassType}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading: passTypesLoading, passType} = useSelector(state=>state.passTypes)
    const {sectors, loading: sectorsLoading} = useSelector(state=>state.sectors)
    const {seasons, loading: seasonsLoading} = useSelector(state=>state.seasons)
	const {club} = useSelector(state=>state.clubs)
	const {commission} = useSelector(state=>state.commissions)

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [passTypeForm, setPassTypeForm] = useState({
        name: '',
        description: '',
        image: null,
        obverseTemplate: null,
        reverseTemplate: null,
        people: '',
        // sport: null,
        sector: null,
        seasons: []
    })

    const handleEdit = async(e) => {
        e.preventDefault()

        if(passTypeForm.name !== ''){
            if (passTypeForm.sector) {
                if (passTypeForm.people && passTypeForm.people !== "") {
                    if (passTypeForm.seasons.length > 0) {
                        dispatch(editPassType(passType?._id, passTypeForm))
                    } else {
                        Swal.fire({
                            showConfirmButton: true,
                            showDenyButton: false,
                            confirmButtonText: t('global.ok'),
                            icon: 'warning',
                            text: t('passTypes.noSeasons')
                        })
                    }
                } else {
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('passTypes.noPeople')
                    })
                }
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.ok'),
                    icon: 'warning',
                    text: t('passTypes.noSector')
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('passTypes.noName')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'PASSTYPE_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('passTypes.editSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.passTypes")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.passTypes")}`)
                        }
                    })
                    break;
                case 'duplicatePassType':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('passTypes.duplicatePassType')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (selectedPassType) {
            dispatch(getPassType(selectedPassType))
        }
    }, [selectedPassType, dispatch])

    useEffect(() => {
        if (passType) {
            if (passType.description) {
                const blocksFromHtml = htmlToDraft(passType.description)
                const { contentBlocks, entityMap } = blocksFromHtml
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
            }
            setPassTypeForm({
                name: passType.name || '',
                description: passType.description || '',
                image: passType.image || null,
                obverseTemplate: passType.obverseTemplate || null,
                reverseTemplate: passType.reverseTemplate || null,
                people: passType.people || '',
                sport: passType.sport || null,
                sector: passType.sector || null,
                seasons: passType.seasons?.map(season => {
                    return {
                        ...season,
                        startDate: new Date(season.startDate).toISOString().replace(/T.*/,'').split('-').join('-'),
                        endDate: new Date(season.endDate).toISOString().replace(/T.*/,'').split('-').join('-')
                    }
                }) || []
            })
        }
    }, [passType])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (club?._id) {
            dispatch(getSectorsByClub(club?._id))
            dispatch(getSeasonsByClub(club?._id))
            dispatch(getCommissionByClub(club?._id))
        }
    }, [club, dispatch])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('passTypes.editPassTypeForm')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <PassTypeForm state={passTypeForm} setState={setPassTypeForm} statetype={'edit'} usertype={usertype} isAdding={passTypesLoading || sectorsLoading || seasonsLoading} submit={handleEdit} sectors={sectors} seasons={seasons} editorState={editorState} setEditorState={setEditorState} commission={commission}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}