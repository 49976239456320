import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getMatchesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/getMatchesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MATCH_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const getAvailableMatchesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/getAvailableMatchesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MATCH_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const getPublicMatchesByClub = (club) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/public/getPublicMatchesByClub/'+ club, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MATCH_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const getMatch = (matchId) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/getMatch/'+ matchId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MATCH_GET_SUCCESS',
                payload: data?.match
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const getMatchPublicByUrl = ({url, club}) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/public/getPublicMatchByUrl/'+ url +'/'+ club)
        if (status === 200) {
            dispatch({
                type: 'MATCH_GET_SUCCESS',
                payload: data?.matchMarket
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const addMatch = (matchData) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        // Subida de escudo del anfitrión
        if(matchData.hostBadge !== null && matchData.hostBadge !== undefined && matchData.hostBadge !== '' && typeof matchData.hostBadge !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.hostBadge)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.hostBadge = data
        }

        // Subida de escudo del visitante
        if(matchData.visitorBadge !== null && matchData.visitorBadge !== undefined && matchData.visitorBadge !== '' && typeof matchData.visitorBadge !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.visitorBadge)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.visitorBadge = data
        } else {
            matchData.visitorBadge = null
        }

        // Subida de la miniatura
        if(matchData.thumbnail !== null && matchData.thumbnail !== undefined && matchData.thumbnail !== '' && typeof matchData.thumbnail !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.thumbnail)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.thumbnail = data
        } else {
            matchData.thumbnail = null
        }

        // Subida de la plantilla
        if(matchData.template !== null && matchData.template !== undefined && matchData.template !== '' && typeof matchData.template !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.template)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.template = data
        } else {
            matchData.template = null
        }

        const { data, status} = await Axios.post('/api/match/register', matchData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'MATCH_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const editMatch = (matchId, matchData) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        // Subida de escudo del anfitrión
        if(matchData.hostBadge !== null && matchData.hostBadge !== undefined && matchData.hostBadge !== '' && typeof matchData.hostBadge !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.hostBadge)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.hostBadge = data
        }

        // Subida de escudo del visitante
        if(matchData.visitorBadge !== null && matchData.visitorBadge !== undefined && matchData.visitorBadge !== '' && typeof matchData.visitorBadge !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.visitorBadge)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.visitorBadge = data
        } else {
            matchData.visitorBadge = null
        }

        // Subida de la miniatura
        if(matchData.thumbnail !== null && matchData.thumbnail !== undefined && matchData.thumbnail !== '' && typeof matchData.thumbnail !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.thumbnail)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.thumbnail = data
        } else {
            matchData.thumbnail = null
        }

        // Subida de la plantilla
        if(matchData.template !== null && matchData.template !== undefined && matchData.template !== '' && typeof matchData.template !== 'string'){
            let formData = new FormData()
            formData.append('image', matchData.template)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            matchData.template = data
        } else {
            matchData.template = null
        }

        const { data, status} = await Axios.post('/api/match/edit/' + matchId, matchData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'MATCH_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const deleteMatch = (matchId) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.delete('/api/match/delete/'+ matchId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MATCH_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const checkMatchExist = (selectedMatch, identifierMatch) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/checkmatchexist/'+ selectedMatch + '/' + identifierMatch)
        if (status === 200) {
            dispatch({
                type: 'MATCH_EXIST_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const scanEntry = (entryCode, selectedMatch, identifierMatch) => async (dispatch) => {
    dispatch({
        type: 'MATCH_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/scanentry/'+ entryCode + '/' + selectedMatch + '/' + identifierMatch)
        if (status === 200) {
            dispatch({
                type: 'MATCH_GETENTRY_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_NOT_LOADING'
        })
    }
}

export const checkInOut = (entryCode, selectedMatch, checkType) => async (dispatch) => {
    dispatch({
        type: 'MATCH_ENTRY_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/match/checkinout/', {id: entryCode, match: selectedMatch, checkType}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'MATCH_CHECKINOUT_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'MATCH_ENTRY_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_ENTRY_NOT_LOADING'
        })
    }
}

export const getChecksByMatch = (matchId) => async (dispatch) => {
    dispatch({
        type: 'MATCH_ENTRY_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/match/getchecksbymatch/'+ matchId, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MATCH_CHECKS_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MATCH_ENTRY_NOT_LOADING'
        })
    }
}