import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export default function SidebarClient({ isCollapsed, client, handleClientSelect }) {
	const { t } = useTranslation()

  	return (		
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} id="sidebar">
			<Link to="/"><img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='mb-5 ms-3' /></Link>
			<Nav className="flex-column p-0">
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.dashboard")}`}><img src="/images/menus/dashboard.svg" alt={t('menus.general.mydashboard')}/> {!isCollapsed && t('menus.general.mydashboard')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.personaldata")}`} onClick={() => handleClientSelect(client)}><img src="/images/menus/user.svg" alt={t('menus.general.profile')}/> {!isCollapsed && t('menus.general.profile')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.members")}`}><img src="/images/menus/members.svg" alt={t('menus.general.members')}/> {!isCollapsed && t('menus.general.members')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.inscriptions")}`}><img src="/images/menus/calendar.svg" alt={t('menus.general.inscriptions')}/> {!isCollapsed && t('menus.general.inscriptions')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.reservations")}`}><img src="/images/menus/calendar.svg" alt={t('menus.general.reserve')}/> {!isCollapsed && t('menus.general.reserve')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.payments")}`}><img src="/images/menus/payments.svg" alt={t('menus.general.payments')}/> {!isCollapsed && t('menus.general.payments')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.passes")}`}><img src="/images/menus/pass.svg" alt={t('menus.general.passes')}/> {!isCollapsed && t('menus.general.passes')}</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to={`/${t("url.client.client")}/${t("url.client.tickets")}`}><img src="/images/menus/ticketing.svg" alt={t('menus.general.tickets')}/> {!isCollapsed && t('menus.general.tickets')}</Link>
				</Nav.Item>
				<Nav.Item className='disabled'>
					<Link to="/#" onClick={(e) => e.preventDefault()}><img src="/images/menus/promociones.svg" alt={t('menus.general.promotions')}/> {!isCollapsed && t('menus.general.promotions')}</Link>
				</Nav.Item>
			</Nav>
		</div>
	)
}