import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getUsers = (user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/user/getall/', {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'USER_GETALL_SUCCESS',
                payload: data.users
            })
        } else {
            dispatch({
                type: 'USER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const getUser = (user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/user/get/'+ user, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'USER_GET_SUCCESS',
                payload: data.user
            })
        } else {
            dispatch({
                type: 'USER_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const getUserByEmailClient = (email, clientid) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/user/getbyemailclient/'+clientid+'/'+email, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'USER_GET_SUCCESS',
                payload: data.user
            })
        }else{
            dispatch({
                type: 'USER_NOTHINGHERE'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const addUser = (userdata) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/login/register', userdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const activateMember = (userdata) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/user/activatemember', userdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const editUser = (userdata, user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/user/edit/' + user, userdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const editUserSuperadmin = (userdata, user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/user/editsuperadmin/' + user, userdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_EDIT_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const editUserClient = (userdata, image = null, dataimages = null) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        // Subida de imágenes
        if(image !== null && image !== undefined && image !== '' && typeof image !== 'string'){
            let formData = new FormData()
            formData.append('image', image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            userdata.avatar = data
        }

        // Subida de documentos
        if(dataimages?.images !== null && dataimages?.images !== undefined && dataimages?.images !== '' && typeof dataimages?.images !== 'string'){
            let formData = new FormData()
            dataimages?.images?.forEach((image) => {
                formData.append('images', image)
            })

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimages', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            let arrayDocuments = userdata?.identityDocuments || []
            arrayDocuments.push({...dataimages.document, images: data})
            userdata.identityDocuments = arrayDocuments
        }

        const { data, status} = await Axios.put('/api/user/editclient/', userdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_EDIT_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const editUserClub = (clubdata, club, image = null, banner = null, newCarouselImages = null, newSponsors = null, datadocument = null, documentspaycomet = null) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        // Subida de imágenes
        if(image !== null && image !== undefined && image !== '' && typeof image !== 'string'){
            let formData = new FormData()
            formData.append('image', image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            clubdata.avatar = data
        }

        // Subida de banner
        if(banner !== null && banner !== undefined && banner !== '' && typeof banner !== 'string'){
            let formData = new FormData()
            formData.append('image', banner)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            clubdata.bannerMarketplace = data
        }

        // Subida de imágenes del carrusel
        if (newCarouselImages !== null && newCarouselImages.length > 0) {
            let arrayCarouselImages = []
            let formData = new FormData()
            newCarouselImages.forEach(image => {
                if (image instanceof File) {
                    formData.append('images', image)
                }else{
                    arrayCarouselImages.push(image)
                }
            })

            if (formData.has('images')) {
                const {data: carouselUrls} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimages', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`,
                        auth: sessionStorage.getItem('jwt')
                    }
                })
                arrayCarouselImages = arrayCarouselImages.concat(carouselUrls)
            }
            clubdata.carouselImages = arrayCarouselImages
        }

        // Subida de imágenes de los patrocinios
        if (newSponsors !== null && newSponsors.length > 0) {
            let arraySponsorsImages = []
            let formData = new FormData()
            newSponsors.forEach(image => {
                if (image instanceof File) {
                    formData.append('images', image)
                }else{
                    arraySponsorsImages.push(image)
                }
            })

            if (formData.has('images')) {
                const {data: sponsorsUrls} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimages', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`,
                        auth: sessionStorage.getItem('jwt')
                    }
                })
                arraySponsorsImages = arraySponsorsImages.concat(sponsorsUrls)
            }

            clubdata.sponsors = arraySponsorsImages
        }

        // Subida de documentos
        if(datadocument !== null && datadocument !== undefined && datadocument !== '' && typeof datadocument !== 'string'){
            let fileData = new FormData()
            fileData.append('file', datadocument.document)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploaddocument', fileData, {
                headers: { auth: sessionStorage.getItem('jwt') }
            })
            let arrayDocuments = clubdata?.documents || []
            arrayDocuments.push({title: datadocument.title, document: data})
            clubdata.documents = arrayDocuments
        }

        // Subida de documentos paycomet
        if(documentspaycomet !== null && documentspaycomet !== undefined && documentspaycomet !== '' && typeof documentspaycomet !== 'string'){
            let documentsPaycomet = clubdata?.paycomet || {}
            const uploadPromises = []

            Object.keys(documentspaycomet).forEach(key => {
                if (documentspaycomet[key][0]) {
                    let fileData = new FormData()
                    fileData.append('file', documentspaycomet[key][0])
        
                    // Almacenar la promesa en el array de promesas
                    uploadPromises.push(
                        Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploaddocument', fileData, {
                            headers: { auth: sessionStorage.getItem('jwt') }
                        }).then(response => {
                            // Actualizar los datos después de que la solicitud POST sea exitosa
                            documentsPaycomet[key] = response.data
                        })
                    )
                }
            })

            await Promise.all(uploadPromises)

            clubdata.paycomet = documentsPaycomet
        }

        const { data, status} = await Axios.put('/api/user/editclub/' + club, clubdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )

        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_EDIT_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const activeUser = (user, codVerification) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/login/activate/' + user, {activate_code: codVerification}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_EDIT_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const sendVerificationCode = (user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/login/sendverificationcode/' + user, {}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'USER_EMAILSEND',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const createMemberUser = (memberdata) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/user/registermember', memberdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_ADDMEMBER_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}