import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function CheckOutReservation({item}) {
    const { t } = useTranslation()

    const calculateReservationPrice = () => {
        if (item.slots && item.slots.length > 0) {
            let price = item.slots.reduce((sum, value) => {
                let price = value?.price
                if (price > 0) {
                    const sitesCommission = item.commission?.find(sites => price >= sites.lowPrice && price <= sites.highPrice);
                    return parseFloat(sum) + parseFloat((Math.ceil((parseFloat(price) + parseFloat(price * (sitesCommission?.percentage || 0) / 100) + parseFloat(sitesCommission?.double || 0)) * 100) / 100).toFixed(2))
                } else {
                    return sum
                }
            }, 0)

            return parseFloat(price).toFixed(2)
        } else {
            return 0.00
        }
    }

    return (
        <Row className='border py-4 px-3 rounded my-5'>
            <Col xs={12} md={4}>
                {
                    item.court?.image
                    ?
                        <img src={item.court?.image || `/images/bannerclub.jpg`} alt={item.court?.name} className="w-100"/>
                    :
                        <img src="/images/bannerclub.jpg" alt={item.court?.name} className="w-100"/>
                }
            </Col>
            <Col xs={12} md={8}>
                <Row className='mb-5'>
                    <Col xs={8}>
                        <h3>{item.court?.name}</h3>
                    </Col>
                    <Col xs={4}>
                        <div className='border rounded group-price text-center py-3 fw-bold'>{calculateReservationPrice()}€</div>
                    </Col>
                </Row>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.reservation.club")}</span>
                        <span>{item.club?.name}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.reservation.client")}</span>
                        <span>{`${item.personData?.firstname} ${item.personData?.lastname}`}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.reservation.date")}</span>
                        <span>{`${String(new Date(item.date).getDate()).padStart(2, '0')}/${String(new Date(item.date).getMonth() + 1).padStart(2, '0')}/${new Date(item.date).getFullYear()}`}</span>
                    </div>
                    <hr className='border my-1'/>
                </div>
                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className='fw-bold'>{t("marketplace.reservation.slots")}</span>
                        <span>
                            {
                                item.slots?.map(item => `${item.startHour}-${item.endHour}`)?.join(", ")
                            }
                        </span>
                    </div>
                    <hr className='border my-1'/>
                </div>
            </Col>
        </Row>
    )
}