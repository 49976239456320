export const activitiesReducer = (state = {loadingactivity: false, resultactivity: null, activities: null, activity: null } , action) => {
    switch (action.type) {
        case 'ACTIVITY_GET_SUCCESS':
            if(action.payload.activity || action.payload.activityMarket){
                if(action.payload.activity){
                    return {
                        ...state,
                        loadingactivity: false,
                        activity: action?.payload?.activity,
                        resultactivity: null
                    }
                }else{
                    return {
                        ...state,
                        loadingactivity: false,
                        activityMarket: action?.payload?.activityMarket,
                        resultactivity: null
                    }
                }
            }else{
                return {
                    ...state,
                    loadingactivity: false,
                    resultactivity: 'thereisnotactivity'
                }
            }
        case 'ACTIVITY_GETALL_SUCCESS':
            if(action.payload.activities || action.payload.activitiesMarket){
                if(action.payload.activities){
                    return {
                        ...state,
                        loadingactivity: false,
                        activities: action.payload.activities
                    }
                }else{
                    return {
                        ...state,
                        loadingactivity: false,
                        activitiesMarket: action?.payload?.activitiesMarket,
                        resultactivity: null
                    }
                }
            }
        case 'ACTIVITY_ADD_SUCCESS':
            return {
                ...state,
                loadingactivity:false,
                resultactivity: action.payload.message,
                activity: action.payload.activity
            }
        case 'ACTIVITY_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resultactivity: action.payload
            }
        case 'ACTIVITY_REMOVE_RESULT':
            return {...state,
                loading:false,
                resultactivity: action.payload.message,
                activities: state.activities.filter(activities => activities._id !== action.payload.activity)
            }
        case 'ACTIVITY_RESET_RESULT':
            return {
                ...state,
                resultactivity: null
            }
        case 'ACTIVITY_LOADING':
            return {
                ...state,
                loadingactivity:true
            }
        case 'ACTIVITY_NOT_LOADING':
            return {
                ...state,
                loadingactivity:false
            }
        default:
            return state
    }
}