import React from "react";
import { useTranslation } from "react-i18next";

export default function InfoSite({site}) {
    const { t } = useTranslation()

    return (
        <div className="mb-4">
            <h4 className='border-2 border-bottom border-dark mb-1'>{site?.name}</h4>
            {site?.manager?.phoneNumber
            ?
                <a href={`tel:${site?.manager?.phoneNumber}`}>
                    <img src="/images/phone.svg" alt="Phone" className='me-2 my-2' />
                    {site?.manager?.phoneNumber}<br/>
                </a>
            : <></>
            }
            {site?.manager?.email
            ?
                <a href={`mailto:${site?.manager?.email}`}>
                    <img src="/images/envelope.svg" alt="Email" className='me-2 my-2' />
                    {site?.manager?.email}<br/>
                </a>
            : <></>
            }
            {
                site?.location?.length >0
                ?
                <div className='mapa mt-2'>
                    <div dangerouslySetInnerHTML={{ __html: site?.location }} />
                </div>
                :
                <></>
            }

        </div>
    )
}