import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function Description({passType, stadium}) {
    const { t } = useTranslation()

    
	const {commission} = useSelector(state=>state.commissions)

    const getMinimumPrice = () => {
        if (passType?.seasons && passType.seasons.length > 0) {
            const seasons = [...passType.seasons]
                    
            let minimumPrice = Number.MAX_SAFE_INTEGER
            for (const season of seasons) {
                if (season.price < minimumPrice) {
                    minimumPrice = season.price
                }
            }
            if (minimumPrice <= 0) {
                return 0.00
            } else {
                const ticketingCommission = commission?.ticketing?.find(activity => minimumPrice >= activity.lowPrice && minimumPrice <= activity.highPrice);
                return (Math.ceil((parseFloat(minimumPrice) + parseFloat(minimumPrice * (ticketingCommission?.percentage || 0) / 100) + parseFloat(ticketingCommission?.double || 0)) * 100) / 100).toFixed(2)
            }
        } else {
            return 0.00
        }
    }

    return (
        <>
            <Card className='border-0 my-4'>
                <Card.Body>
                    <div className='d-flex align-items-end flex-grow-1'>
                        <div className='flex-grow-1'>
                            <h2 className='border-2 border-bottom border-dark'>{t('global.description') }</h2>
                        </div>
                        <div className='ps-3 pb-2'>
                            <img src="/images/share.svg" alt="Compartir Club" />
                        </div>
                    </div>
                    <Row>
                        <Col className='my-3'>
                            <div dangerouslySetInnerHTML={{ __html: passType?.description }} />
                        </Col>
                    </Row>
                    <div className='d-flex align-items-end flex-grow-1'>
                        <div className='flex-grow-1'>
                            <h2 className='border-2 border-bottom border-dark'>{stadium?.name}</h2>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <Row>
                            <Col xs={12} md={stadium?.image ? 6 : 12}>
                            <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                <strong>{t('marketplace.passType.sector')}</strong>
                                {passType?.sector?.name}
                            </div>
                            <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                <strong>{t('passTypes.priceFrom')}</strong>
                                {getMinimumPrice()}€
                            </div>
                            </Col>
                            {stadium?.image &&
                                <Col xs={12} md={6}>
                                    <img src={stadium?.image} alt='stadium' className='w-100'/>
                                </Col>
                            }
                        </Row>

                    </div>
                </Card.Body>
            </Card>
        </>
    )
}