import React from 'react'

export const FileNameExtractor = ({ documentimage }) => {
  // Función para extraer el nombre del fichero de una URL
  const getFileNameFromUrl = (url) => {
    try {
      const parsedUrl = new URL(url)
      const pathname = parsedUrl.pathname
      const segments = pathname.split('/')
      return segments.pop() || segments.pop()
    } catch (error) {
      console.error('Invalid URL:', url)
      return ''
    }
  }

  // Obtener el nombre del fichero
  const fileName = getFileNameFromUrl(documentimage)

  return (
    <>{fileName}</>
  )
}

