import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import Loader from '../../../../components/design/Loader/Loader'
import { getPaymentsByClub } from '../../../../actions/payments/paymentActions'
import ManualPayment from '../../../../components/Payments/ManualPayment'
import Swal from 'sweetalert2'
import ReturnPayment from '../../../../components/Payments/ReturnPayment'
import paycometErrors from '../../../../components/paycometError.json'

export default function Payments() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {payments, loading, retryStatus, returnStatus} = useSelector(state=>state.payment)
    const {club} = useSelector(state=>state.clubs)

    const [manualPaymentSelected, setManualPaymentSelected] = useState(null)
    const [paymentToReturnSelected, setPaymentToReturnSelected] = useState(null)

    const translatedPayments = payments?.map(payment => {
        const statusText =
            payment.status === 0 ? t('payments.pending') :
            payment.status === 1 ? `${t('payments.failed')} (${payment.totalRetries || 1})` :
            payment.status === 2 ? t('payments.canceled') :
            payment.status === 3 ? t('payments.paid') :
            t('payments.unknown')

        const externalPaidText = payment.externalPaid ? ` ${t('payments.externalPaid')}` : ''

        return {
            ...payment,
            translatedStatus: `${statusText}${externalPaidText}`
        }
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth){
            dispatch(getPaymentsByClub(club?._id))
        }
        // eslint-disable-next-line
    },[dispatch, t, clientauth, club])

    useEffect(() => {
        if (retryStatus) {
            dispatch(getPaymentsByClub(club?._id))
            switch (retryStatus.message) {
                case "retryPaymentSuccess":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('payments.retryPaymentSuccess')
                    })
                    break;
                case "retryPaymentClientError":
                    const error = paycometErrors?.find(err => err.code === retryStatus?.typeError.toString())
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        html: `${t('payments.retryPaymentClientError')}<br>Error: ${retryStatus.typeError}<br>${error?.description}`
                    })
                    break;
                default:
                    break;
            }
            dispatch({ type: "PAYMENT_RETRY_RESET" })
        }
    }, [retryStatus, dispatch, club, t])

    useEffect(() => {
        if (returnStatus) {
            dispatch(getPaymentsByClub(club?._id))
            switch (returnStatus?.message) {
                case "paymentRefundSuccess":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('payments.paymentRefundSuccess')
                    })
                    break;
                case "paymentRefundError":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('payments.paymentRefundError')
                    })
                    break;
                default:
                    break;
            }
            dispatch({ type: "PAYMENT_RETRY_RESET" })
        }
    }, [returnStatus, dispatch, club, t])

	const columnsPayments = [
        {
            name: t('payments.reference'),
            width: '130px',
            cell: payment =>
                payment.paymentReference,
            selector: (payment) => payment.paymentReference,
            sortable: false,
        },{
            name: t('payments.paymentDate'),
            cell: payment =>
                payment.paymentDate ? `${new Date(payment.paymentDate).getDate() > 9 ? new Date(payment.paymentDate).getDate() : "0" + new Date(payment.paymentDate).getDate()}/${new Date(payment.paymentDate).getMonth() > 8 ? parseInt(new Date(payment.paymentDate).getMonth()) + 1 : "0" + (parseInt(new Date(payment.paymentDate).getMonth()) + 1)}/${new Date(payment.paymentDate).getFullYear() > 9 ? new Date(payment.paymentDate).getFullYear() : "0" + new Date(payment.paymentDate).getFullYear()}`: '-',
            selector: (payment) => `${new Date(payment.paymentDate).getDate() > 9 ? new Date(payment.paymentDate).getDate() : "0" + new Date(payment.paymentDate).getDate()}/${new Date(payment.paymentDate).getMonth() > 8 ? parseInt(new Date(payment.paymentDate).getMonth()) + 1 : "0" + (parseInt(new Date(payment.paymentDate).getMonth()) + 1)}/${new Date(payment.paymentDate).getFullYear() > 9 ? new Date(payment.paymentDate).getFullYear() : "0" + new Date(payment.paymentDate).getFullYear()}`,
            sortable: true,
        },{
            name: t('payments.quantity'),
            right: 'true',
            cell: payment =>
                payment.quantity.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + "€",
            selector: (payment) => payment.quantity,
            sortable: true,
        },{
            name: t('payments.refunded'),
            right: 'true',
            cell: payment =>
                payment.refunded ? payment.refunded.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + "€" : '',
            selector: (payment) => payment.refunded ? payment.refunded.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '',
            sortable: true,
        },{
            name: t('payments.status'),
            center: 'true',
            cell: payment => payment.translatedStatus,
            selector: (payment) => payment.translatedStatus,
            sortable: true,
        },{
            name: t('payments.sepaPaid'),
            center: 'true',
            width: '110px',
            cell: payment =>
                payment.sepaPaid ? t('global.yes') : t('global.no'),
            selector: (payment) => payment.sepaPaid ? t('global.yes') : t('global.no'),
            sortable: true,
        },{
            name: t('payments.client'),
            width: '24%',
            cell: payment => {
                const client = payment.client
                    ? `${payment.client.firstname || ''} ${payment.client.lastname || ''}`
                    : null;
                const paymentClient = payment.paymentClient
                    ? `${payment.paymentClient.firstname || ''} ${payment.paymentClient.lastname || ''}`
                    : null;
                const personData = payment.ref?.personData
                ? `${payment.ref?.personData?.firstname || ''} ${payment.ref?.personData?.lastname || ''}`
                : null;
                return (
                    <div className="d-flex flex-column">
                        {client && <>{client}<br/></>}
                        {personData && (
                            <>{personData}</>
                        )}
                        {paymentClient && <small>{t('payments.paymentClient')}: {paymentClient}</small>}
                    </div>
                )
            },
            selector: payment =>
                `${payment.client?.firstname || ''} ${payment.client?.lastname || ''} ${payment.paymentClient?.firstname || ''} ${payment.paymentClient?.lastname || ''} ${payment.ref?.personData?.firstname || ''} ${payment.ref?.personData?.lastname || ''}`,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width:'80px',
			cell: payment =>
                <div className='d-flex justify-content-around w-100'>
                    {
                        (payment.status === 1 || payment.status === 0) &&
                            <div onClick={() => setManualPaymentSelected(payment)}>
                                <OverlayTrigger placement="top"
                                    overlay={<Tooltip id="tooltip">
                                                {t('payments.manualPayment')}
                                            </Tooltip>}
                                >
                                    <img className="cursor" src="/images/menus/payments.svg" alt="eyes" height={16}/>
                                </OverlayTrigger>
                            </div>
                    }
                    {
                        (payment.status === 3 && !payment.sepaPaid) &&
                        <div onClick={() => setPaymentToReturnSelected(payment)}>
                            <OverlayTrigger placement="top"
                                overlay={<Tooltip id="tooltip">
                                            {t('payments.returnPayment')}
                                        </Tooltip>}
                            >
                                <img className="cursor" src="/images/money-return.svg" alt="eyes" height={16}/>
                            </OverlayTrigger>
                        </div>
                    }
                </div>,
			sortable: false,
		}
	]
    const ExpandedRow = ({ data }) => {
        return (
            <Container className='p-3'>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.paymentreference')}</small> {data?.paymentReference}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.quantity')}</small> {data?.quantity} €
                        </div>
                        <div className='d-flex justify-content-between border-bottom p-2'>
                            <small>{t('inscriptions.paymentdate')}:</small> {data.paymentDate ? `${new Date(data.paymentDate).getDate() > 9 ? new Date(data.paymentDate).getDate() : "0" + new Date(data.paymentDate).getDate()}/${new Date(data.paymentDate).getMonth() > 8 ? parseInt(new Date(data.paymentDate).getMonth()) + 1 : "0" + (parseInt(new Date(data.paymentDate).getMonth()) + 1)}/${new Date(data.paymentDate).getFullYear() > 9 ? new Date(data.paymentDate).getFullYear() : "0" + new Date(data.paymentDate).getFullYear()}`: '-'}
                        </div>
                        <div className='d-flex justify-content-between border-bottom p-2'>
                            <small>{t('payments.refType')}:</small> {data.refType && data.ref ? t('payments.' + data.refType) : t('payments.cardRegistration')}
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.status')}:</small> {t('inscriptions.statusseasonpay'+data?.status)} {data.externalPaid ? ` ${t('payments.externalPaid')}` : ''}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('inscriptions.creationdate')}:</small> {data.creationDate ? `${new Date(data.creationDate).getDate() > 9 ? new Date(data.creationDate).getDate() : "0" + new Date(data.creationDate).getDate()}/${new Date(data.creationDate).getMonth() > 8 ? parseInt(new Date(data.creationDate).getMonth()) + 1 : "0" + (parseInt(new Date(data.creationDate).getMonth()) + 1)}/${new Date(data.creationDate).getFullYear() > 9 ? new Date(data.creationDate).getFullYear() : "0" + new Date(data.creationDate).getFullYear()}`: '-'}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('payments.member')}:</small> {data.client ? `${data.client?.lastname}, ${data.client?.firstname} - ${data.client?.phone}` : '-'}
                        </div>
                        <div className='d-flex justify-content-between p-2'>
                            <small>{t('inscriptions.sepapaid')}</small> {data?.sepaPaid ? t('global.yes') : t('global.no')}
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container className='cuerpo'>
            <Row className='mt-3 mb-5'>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('club.payments')}</h1>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('payments.paymentsList')}</h2>
                            <hr className='mb-4'/>
                            <div className='my-3'>
                                <Row>
                                    <Col>
                                        {
                                            loading
                                                ?
                                                    <Loader/>
                                                :
                                                    <>
                                                        {
                                                            payments?.length > 0 ?
                                                                <CustomDataTable
                                                                    columns={columnsPayments}
                                                                    data={translatedPayments}
                                                                    exportable={true}
                                                                    printable={true}
                                                                    expandedData={ExpandedRow}
                                                                />
                                                            :
                                                                <div className='contenedor-vacio text-center'>
                                                                    <h4>{t('payments.noPayments')}</h4>
                                                                    {t('payments.thereIsNoPaymentsPhrase')}
                                                                </div>
                                                        }
                                                    </>
                                        }

                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* <PaymentSummary userType="club" payment={paymentSelected} onHide={() => setPaymentSelected(null)}/> */}
            <ManualPayment userType="club" payment={manualPaymentSelected} onHide={() => setManualPaymentSelected(null)}/>
            <ReturnPayment userType="club" payment={paymentToReturnSelected} onHide={() => setPaymentToReturnSelected(null)}/>
        </Container>
    )
}