import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ActivityBack({activity,handleActivitySelect,usertype}) {
    const { t } = useTranslation()
    const backCardImage = {
        backgroundImage: activity?.image ? `url(${activity?.image})` : `url("/images/sports/${activity?.sport}.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    return (
        <Card className="miniatura p-2">
            <div>
                <Link onClick={() => handleActivitySelect(activity._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editactivity")}` : `/${t("url.club.club")}/${t("url.club.editactivity")}`}>
                    <div className="image" variant="top" style={backCardImage}></div>
                </Link>
                <Link
                    to={`/${t("url.marketplace.clubs")}/${activity?.club?.url}/${t("url.marketplace.activities")}/${activity?.url}`}
                    className="position-absolute top-0 end-0 mt-3 me-3"
                    target="_blank" rel="noreferrer">
                    <img src="/images/link-market.svg" alt="Ver en MarketPlace" className="me-1"/>
                </Link>
            </div>
            <Card.Body>
                <Card.Title>{activity.title}</Card.Title>
                <Row className="mt-0">
                    <Col className="d-flex align-items-center">
                        <img src={`/images/sports/iconos/${activity?.sport}.svg`} alt="actividad" className="me-1"/>
                        {t('sports.' + (activity?.sport || ''))}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={12} className=" d-flex justify-content-between">
                        {t("activities.active")}
                        {activity?.active
                            ?
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" checked disabled></input>
                            </div>
                            :
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" disabled></input>
                            </div>
                        }
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} className=" d-flex justify-content-between">
                        {t("activities.visibility")}
                        {activity?.visibility
                            ?
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" checked disabled></input>
                            </div>
                            :
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" readOnly disabled></input>
                            </div>
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="d-flex align-items-center justify-content-between flex-wrap">
                        <Link onClick={() => handleActivitySelect(activity._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editactivity")}` : `/${t("url.club.club")}/${t("url.club.editactivity")}`} className="btn btn-default w-100">
                            {t("activities.editactivity")}
                            <img src="/images/rignt-arrow.svg" alt="Continuar" className="ms-3"/>
                        </Link>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}