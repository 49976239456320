import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getClubPublicByUrl } from '../../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getMatchPublicByUrl } from '../../../actions/ticketing/matchesActions'
import Loader from '../../../components/design/Loader/Loader'
import { Link } from 'react-router-dom'
import MenuMarketplace from '../../../components/menus/MenuMarketplace'
import BannerHead from '../../../components/Banners/BannerHead'
import { FooterMarketplace } from '../../../components/footers/FooterMarketplace'
import UsersRegistered from '../../../components/Banners/UsersRegistered'
import BannerPhrase2 from '../../../components/Banners/BannerPhrase2'
import Suscribete from '../../../components/Banners/Subscribe'
import NotFoundPage from '../../error/404'
import SidebarSite from '../../../components/Activities/SidebarSite'
import { getCommissionByClub } from '../../../actions/settings/commissionsActions'
import { getMultipleUnavailableSeats, getSectorsByClub } from '../../../actions/ticketing/sectorsActions'
import { getFeesByClub } from '../../../actions/ticketing/feesActions'
import Details from './Details'
import TicketPurchase from './TicketPurchase'
import { getPublicPassTypesByClub } from '../../../actions/ticketing/passTypesActions'

export default function Match() {
    const { t } = useTranslation()
    const backgroundGeneric = '/images/fondo-auth.jpg'

	const dispatch = useDispatch()
    const {clubUrl, matchUrl} = useParams()

    const {clubMarket: club, loadingclub, resultclub} = useSelector(state=>state.clubs)
    const {match, loading, result} = useSelector(state=>state.matches)
    const {sectors, loading: sectorLoading, unavailableSeats} = useSelector(state=>state.sectors)
    const {fees, loading: feesLoading} = useSelector(state=>state.fees)
    const {passTypes, loading: passTypeLoading} = useSelector(state=>state.passTypes)

    const [activeTab, setActiveTab] = useState("details")

    useEffect(() =>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getClubPublicByUrl(clubUrl))
    }, [dispatch, clubUrl])

    useEffect(() =>{
        if(club){
            dispatch(getMatchPublicByUrl({url: matchUrl, club: club?._id}))
            dispatch(getCommissionByClub(club?._id))
            dispatch(getSectorsByClub(club?._id))
            dispatch(getFeesByClub(club?._id))
            dispatch(getPublicPassTypesByClub(club?._id))
        }
    // eslint-disable-next-line
    }, [club, matchUrl, dispatch])

    useEffect(() => {
        if (match?.sectors) {
            dispatch(getMultipleUnavailableSeats(match?.sectors?.map(sector => sector.sector)))
        }
    }, [match, dispatch])

    return (
        <>
            <MenuMarketplace section='clubs'/>
            <BannerHead
                image={club?.bannerMarketplace || backgroundGeneric}
                title={`${match?.host} - ${match?.visitor}`}
                section={`${match?.host} - ${match?.visitor}`}
            />
            <Container className='market'>
                {
                loading || loadingclub || sectorLoading || feesLoading || passTypeLoading
                ?
                    <Loader/>
                :
                    resultclub === 'thereisnotclub' || result === 'thereisnotactivity'
                    ?
                        <NotFoundPage />
                    :
                    club &&
                        <Row>
                            <Col xs={12} lg={9}>
                                <div className='submenu my-3'>
                                    <Nav as="ul">
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("details")} active={activeTab === "details"}>{t('matches.details')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link as={Link} onClick={() => setActiveTab("ticketPurchase")} active={activeTab === "ticketPurchase"}>{t('matches.ticketPurchase')}</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                {
                                    activeTab === "details" &&
                                        <Details match={match} sectors={sectors} fees={fees} stadium={club?.stadium} passTypes={passTypes}/>
                                }
                                {
                                    activeTab === "ticketPurchase" &&
                                        <TicketPurchase match={match} club={club} sectors={sectors} fees={fees} unavailableSeats={unavailableSeats}/>
                                }
                            </Col>
                            <Col xs={12} lg={3} className="my-3">
                                <SidebarSite club={club} />
                            </Col>
                            <Col xs={12} lg={9}>
                                <div>
                                    <Row className='my-3'>
                                        <Col xs={12} sm={6} className='my-3 my-sm-0'>
                                            <UsersRegistered />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <BannerPhrase2
                                                phrase={t('marketplace.banners.yoursport')}
                                                image="/images/banner-incrono01.jpg"
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row className='h-100'>
                                        <Col xs={12} className='columna1 d-flex flex-column'>
                                            <Suscribete />
                                        </Col>
                                    </Row> */}
                                </div>
                            </Col>
                        </Row>
                }

            </Container>
            <FooterMarketplace className='m-0'/>
        </>


    )
}