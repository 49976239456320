import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Col, Card, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCategoriesByClub, importCategoriesByClub, removeCategory } from '../../../../actions/activities/categoriesActions'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import useAuth from '../../../../context/auth/useAuth'
import CategoryAdd from './CategoryAdd'
import Swal from 'sweetalert2'
import ActivitiesSubMenu from '../../../../components/menus/ActivitiesSubMenu'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'

export default function Categories({usertype, clubselected, handleCategorySelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {categories, resultcategory} = useSelector(state=>state.categories)
    const {club} = useSelector(state=>state.clubs)

    const [isNew,setIsNew] = useState(true)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const openModal = (typeModal) => {
        if(typeModal==='newCategory'){
            setIsNew(true)
        }else{
            setIsNew(false)
        }
        setShow(true)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(clientauth && club){
            dispatch(getCategoriesByClub(club?._id))
        }
    },[dispatch, t, clientauth, club])

    const handleDuplicate = async(e, categoriesToImport) => {
        e.preventDefault()

        dispatch(importCategoriesByClub([{value: categoriesToImport}], club?._id))
    }

    const handleRemoveCategory = async(e, categoryId) => {
        e.preventDefault()

        dispatch(removeCategory(categoryId))
    }

    useEffect(() => {
        if(resultcategory){
            let newresult = resultcategory
            dispatch({
                type: 'CATEGORY_RESET_RESULT'
            })
            switch (newresult) {
                case 'successaddcategory':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('categories.addsuccess')
                    }).then(() => {
                        handleClose()
                    })
                    break;
                    case 'successimport':
                        Swal.fire({
                            showConfirmButton: true,
                            showDenyButton: false,
                            confirmButtonText: t('global.great'),
                            icon: 'success',
                            text: t('categories.importsuccess')
                        }).then(() => {
                            handleClose()
                        })
                        break;
                case 'deletecategorysuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('categories.deletecategorysuccess')
                    })
                    break;
                case 'deletecategorywrong':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('categories.deletecategorywrong')
                    })
                    break;
                case 'deletecategorynotexist':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'success',
                        text: t('categories.deletecategorynotexist')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultcategory])

	const columnsCategories = [
        {
            name: t('categories.title'),
            cell: category =>
                <Link onClick={() => handleCategorySelect(category._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editcategory")}` : `/${t("url.club.club")}/${t("url.club.editcategory")}`} className='table-link'>
                    {category.title}
                </Link>,
            selector: (category) => category.title,
            sortable: true,
        },{
            name: t('categories.agefrom'),
            cell: category =>
                <Link onClick={() => handleCategorySelect(category._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editcategory")}` : `/${t("url.club.club")}/${t("url.club.editcategory")}`} className='table-link'>
                    {category.ageFrom} {t('global.years')}
                </Link>,
            selector: (category) => category.ageFrom,
            sortable: true,
        },{
            name: t('categories.ageto'),
            cell: category =>
                <Link onClick={() => handleCategorySelect(category._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editcategory")}` : `/${t("url.club.club")}/${t("url.club.editcategory")}`} className='table-link'>
                    {category.ageTo} {t('global.years')}
                </Link>,
            selector: (category) => category.ageTo,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width: '120px',
			cell: category =>
                <div className='d-flex justify-content-around w-100'>
                    <Link onClick={() => handleCategorySelect(category._id)} to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.editcategory")}` : `/${t("url.club.club")}/${t("url.club.editcategory")}`}>
                        <img src="/images/edit.svg" alt="pencil" width={15}/>
                    </Link>
                    <Link onClick={(e) => handleDuplicate(e, category._id)}>
                        <img src="/images/copy.svg" alt="copy" width={15}/>
                    </Link>
                    <Link onClick={(e) => handleRemoveCategory(e, category._id)}>
                        <img src="/images/trash.svg" alt="trash" width={15}/>
                    </Link>
                </div>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row>
                <Col className='d-flex align-items-center'>
                    <h1 className='ms-2 my-0'>{t('menus.activities.categories')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <ActivitiesSubMenu usertype={usertype} active="categories"/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <div className='buttons d-flex justify-content-end'>
                                <Button variant='secondary' onClick={() => openModal('newCategory')}>
                                    <i className='fas fa-plus'></i> {t('categories.createnewcategory')}
                                </Button>
                                <Button variant='secondary' className='ms-2 btn-outline' onClick={() => openModal('importCategory')}>
                                    <i className='fas fa-plus'></i> {t('categories.importcategory')}
                                </Button>
                            </div>
                            <div className="my-3">
                                <Row>
                                    <Col>
                                        {categories?.length > 0 ?
                                            <CustomDataTable columns={columnsCategories} data={categories} exportable={false} printable={false}/>
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('categories.thereisnotcategories')}</h4>
                                                {t('categories.thereisnotcategoriesphrase')}
                                                <div className='mt-3'>
                                                    <Button variant='secondary' onClick={() => openModal('newCategory')}>
                                                        <i className='fas fa-plus'></i> {t('categories.createnewcategory')}
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>
                            {isNew
                            ?
                                t('categories.createnewcategory')
                            :
                                t('categories.importcategory')
                            }

                        </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='cuerpo px-5'>
                    <CategoryAdd usertype={usertype} isNew={isNew}/>
                </Modal.Body>
            </Modal>
        </Container>
    )
}
