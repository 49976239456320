import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editUserClient } from '../../../actions/profiles/usersActions'
import { getClient } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import HeadConfig from '../../../components/menus/SubMenuConfig'

export default function Medicaldata({clientselected, usertype}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {user, resultuser} = useSelector(state=>state.users)
    const {client} = useSelector(state=>state.clients)

    const [isUpdating, setIslogging] = useState(false)
    const [formState, setFormState] = useState({})

    useEffect(() => {
        if(clientauth){
            dispatch(getClient(clientselected))
        }
    },[dispatch, t, clientauth, clientselected])

    useEffect(() => {
        if(client){
            setFormState({
                medicalObservations: client?.medicalObservations,
                injuryHistory: client?.injuryHistory,
                allergies: client?.allergies,
                nutrition: client?.nutrition,
                psychology: client?.psychology,
                private_policy: client?.private_policy,
            })
        }
    // eslint-disable-next-line
    }, [user, client])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setFormState({
            ...formState,
            [target.name]: target.checked
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        dispatch(editUserClient({...formState, clienttoupdate: clientselected}, null, null))
        setIslogging(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.useredited')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfig nuevaSeccion="medical" type={clientauth?.client === clientselected || usertype === 'superadmin' ? 'client' : 'member'} usertype={usertype}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2>{t('users.editmedical')}</h2>
                            <hr className='mb-4'/>
                            <Form className="formulario" onSubmit={handleEdit}>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.medicalObservations ? 'has-content' : ''}
                                                type='text'
                                                name='medicalObservations' 
                                                value={formState.medicalObservations ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='medicalObservations'>{t('users.medicalobservations')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.injuryHistory ? 'has-content' : ''}
                                                type='text'
                                                name='injuryHistory' 
                                                value={formState.injuryHistory ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='injuryHistory'>{t('users.injuryhistory')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.allergies ? 'has-content' : ''}
                                                type='text'
                                                name='allergies' 
                                                value={formState.allergies ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='allergies'>{t('users.allergies')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.nutrition ? 'has-content' : ''}
                                                type='text'
                                                name='nutrition' 
                                                value={formState.nutrition ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='nutrition'>{t('users.nutrition')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={formState.psychology ? 'has-content' : ''}
                                                type='text'
                                                name='psychology' 
                                                value={formState.psychology ||  ''}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor='psychology'>{t('users.psychology')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <div className="form-check mt-4">
                                            <input className="form-check-input" id="private_policy" name="private_policy" type='checkbox' onChange={handleCheckboxChange} checked={formState?.private_policy || false} />
                                            <Form.Label htmlFor='private_policy'>{t('users.privatepolicy')}</Form.Label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                        {isUpdating
                                            ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                            :
                                                <>{t('global.update')}</>
                                        }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
