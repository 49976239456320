import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Card, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../../context/auth/useAuth.js'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs.js'
import { checkInOut, getChecksByMatch, getMatch } from '../../../../actions/ticketing/matchesActions.js'
import Loader from '../../../../components/design/Loader/Loader.js'
import { CustomDataTable } from '../../../../components/design/Datatables/CustomDataTable'
import TicketingSubMenu from '../../../../components/menus/TicketingSubMenu.js'

export default function Checks({ usertype, selectedMatch }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { clientauth } = useAuth()

    const { club } = useSelector(state => state.clubs)
    const { check, checks, loadingentry, match } = useSelector(state => state.matches)

    useEffect(() => {
        if(selectedMatch){
            dispatch(getChecksByMatch(selectedMatch))
            dispatch(getMatch(selectedMatch))
        }
    }, [dispatch, clientauth, selectedMatch])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
    }, [checks])

    useEffect(() => {
        if (check) {
            dispatch({type: 'MATCH_RESET_ENTRY'})
        }
    }, [check])

    const handleCheckInOut = (entryCode, currentStatus) => {
        const checkType = currentStatus === 1 ? 'outadmin' : 'inadmin'
        dispatch(checkInOut(entryCode, selectedMatch, checkType))
    }

	const columnsChecks = [
        {
            name: t('matches.identifier'),
            cell: check =>
                <div>
                    {check?.entry?.identifier}
                </div>,
            selector: (check) => check?.entry?.identifier,
            sortable: true,
        },{
            name: t('matches.name'),
            cell: check =>
                <div>
                    {check?.entry?.personData?.firstname} {check?.entry?.personData?.lastname}
                </div>,
            selector: (check) => check?.entry?.personData?.firstname+' '+check?.entry?.personData?.lastname,
            sortable: true,
        },{
            name: t('matches.dni'),
            cell: check =>
                <div>
                    {check?.entry?.personData?.dni}
                </div>,
            selector: (check) => check?.entry?.personData?.dni,
            sortable: true,
        },{
            name: t('matches.checkins'),
            cell: check => (
                <div>
                    {check?.checkins?.map(time => new Date(time).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })).join(', ')}
                </div>
            ),
            selector: (check) => check.checkins,
            sortable: true,
        },
        {
            name: t('matches.checkouts'),
            cell: check => (
                <div>
                    {check?.checkouts?.map(time => new Date(time).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })).join(', ')}
                    </div>
            ),
            selector: (check) => check.checkouts,
            sortable: true,
        },{
			name: t('matches.lastmove'),
            center: 'true',
            width: '120px',
			cell: check =>
				<div>
                    <select
                        value={check?.status}
                        onChange={(e) => handleCheckInOut(check?.entry?.identifier, Number(e.target.value))}
                        className="form-select form-select-sm"
                    >
                        <option value={0}>{t('matches.in')}</option>
                        <option value={1}>{t('matches.out')}</option>
                    </select>
                </div>,
			sortable: false,
		}
	]

    return (
        <>
            <Container className='cuerpo'>
                <ClubBreadCrumbs usertype={usertype} club={club} />
                <Row className="my-3">
                    <Col>
                        <TicketingSubMenu usertype={usertype} active="checks"/>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col>
                        <Card>
                            <Card.Body>
                                {match ?
                                    <h3>{match?.host} - {match.visitor}</h3>
                                :
                                <></>
                                }
                                {loadingentry ?
                                    <Loader/>
                                :
                                    checks ?
                                        checks?.length > 0 ?
                                            <CustomDataTable columns={columnsChecks} data={checks} exportable={false} printable={false} />
                                        :
                                            <div className='contenedor-vacio text-center'>
                                                <h4>{t('matches.thereisnotchecks')}</h4>
                                            </div>
                                    :
                                        <></>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}