import React, { useEffect, useState } from 'react'
import {Container, Button, Navbar, NavDropdown, Dropdown, Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { MenuDropdown, MenuDropdownClub } from '../design/Dropdown/MenuDropdown'
import { addClub, getClubsByUser } from '../../actions/profiles/clubsActions'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

export default function MenuSuperior({toggleSidebar, usertype, isCollapsed, client, avatar, clubs, handleClientSelect, handleClubSelect}) {
	const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth, logout} = useAuth()
    const navigate = useNavigate()

    const {club, resultclub} = useSelector(state=>state.clubs)

    const [showNewClub, setShowNewClub] = useState(false)
    const [nameNewClub, setNameNewClub] = useState('')
    const [isCreating, setIsCreating] = useState(false)

    const showInputNewClub = async(e) => {
        e.preventDefault()

		setShowNewClub(!showNewClub)
		setNameNewClub('')
    }

	const handleAdd = async(e) => {
        e.preventDefault()
		setIsCreating(true)

		if(nameNewClub !== ''){
			dispatch(addClub({name: nameNewClub, userid: clientauth?.id}))
		}

		setIsCreating(false)
    }

    useEffect(() => {
        if(resultclub){
            let newresult = resultclub
            dispatch({
                type: 'CLUB_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
					dispatch(getClubsByUser(clientauth?.id))
					handleClubSelect(club._id)
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('club.gotoclub'),
                        icon: 'success',
                        text: t('club.clubadd')
                    }).then((newresult) => {
                        if (newresult.isConfirmed) {
                            navigate(`/${t("url.club.club")}/${t("url.club.basicdata")}`)
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultclub])

  	return (
		<Navbar expand="md" id="menu-superior" className='py-2 bg-white border-bottom border-2 border-dark'>
			<Container fluid>
				<Navbar.Brand className='d-block d-sm-none'>
					<Link to="/">
						{usertype === 'club'
						?
							<img src='/images/logotipo-reducido-movil.svg' id="logo-menu" alt='Logotipo Incrono' className='ms-3' />
						:
							<img src='/images/logotipo-incrono-cabecera.svg' id="logo-menu" alt='Logotipo Incrono' className='ms-3' />
}
					</Link>
				</Navbar.Brand>
				<Navbar.Brand href="#" className='d-none d-sm-block'>
					<Button variant='default' className='btn-icono' onClick={toggleSidebar}>
						{isCollapsed ?
							<>
								<img src='/images/flechas.svg'  className='rotate-horizontal' alt='Flecha'/>
							</>
						:
							<>
								<img src='/images/flechas.svg' alt='Flecha'/>
							</>
						}
					</Button>
				</Navbar.Brand>
				<div className='d-flex'>
					{usertype === 'club' ?
						<MenuDropdownClub>
							{clubs?.map((clubOption, index) => (
								<Dropdown.Item key={index} eventKey={clubOption.name} to={`/${t("url.club.club")}/${t("url.club.dashboard")}`} as={Link} onClick={() => handleClubSelect(clubOption._id)} className='d-flex align-items-center'>
									<figure className='club' style={{
										backgroundImage: `url(${clubOption.avatar})`,
										backgroundSize: 'cover',
										backgroundPosition: 'center',
									}}>
									</figure>
									<span>{clubOption.name}</span>
								</Dropdown.Item>
							))}
							<div className='d-flex align-items-center py-2 ps-4' onClick={(e) => showInputNewClub(e)}>
								<figure className='new'></figure>{nameNewClub ? nameNewClub : t('menus.general.newclub')}
							</div>
							<div className='px-2'>
								{showNewClub ?
									<Form className="formulario" onSubmit={handleAdd}>
										<Form.Group className='form-group'>
											<Form.Control
												type='text'
												name='namenewclub' 
												value={nameNewClub ||  ''}
												onChange={(e) => setNameNewClub(e.target.value)}
												required
											/>
										</Form.Group>
										<Button variant='primary' type="submit" className="w-100 my-2">
										{isCreating
											?
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
											:
												<>{t('global.create')}</>
										}
										</Button>
									</Form>
								:
									''
								}
							</div>
						</MenuDropdownClub>
					:
						<></>
					}
					<MenuDropdown backgroundImage={avatar}>
						{usertype === 'club' ?
							<>
								<Link to={`/${t("url.club.club")}/${t("url.club.payments")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.payments')}</Link>
								{club?.activitybooking
									?
										<>
											<Link to={`/${t("url.club.club")}/${t("url.club.activities")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.activities')}</Link>
											<Link to={`/${t("url.club.club")}/${t("url.club.promotions")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.promotions')}</Link>
										</>
									:
										<></>
								}
								<Link to={`/${t("url.club.club")}/${t("url.club.sites")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.sites')}</Link>
								{club?.renting
									?
									<>
										<Link to={`/${t("url.club.club")}/${t("url.club.courts")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.courts')}</Link>
										<Link to={`/${t("url.club.club")}/${t("url.club.courtsrents")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.courtsrents')}</Link>

									</>
									:
									<></>
								}
								{club?.ticketing
									?
										<Link to={`/${t("url.club.club")}/${t("url.club.seasons")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.ticketing')}</Link>
									:
										<></>
								}
								<Link to={`/${t("url.club.club")}/${t("url.club.logs")}/${t("url.club.sepalogs")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.sepalogs')}</Link>
								<Link to={`/${t("url.club.club")}/${t("url.club.basicdata")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.config')}</Link>
								<NavDropdown.Divider className='d-block d-sm-none' />
								<Link to={`/${t("url.club.club")}/${t("url.club.lobby")}`} className='dropdown-item' onClick={() => handleClubSelect(null)}>{t('menus.general.mydashboard')}</Link>
								<Link to={`/${t("url.club.club")}/${t("url.club.accountdata")}`} className='dropdown-item'>{t('menus.general.accountdata')}</Link>


							</>
						:
							<>
								<Link to={`/${t("url.client.client")}/${t("url.client.dashboard")}`} className='dropdown-item' onClick={() => handleClubSelect(null)}>{t('menus.general.mydashboard')}</Link>
								<Link to={`/${t("url.client.client")}/${t("url.client.personaldata")}`} onClick={() => {handleClientSelect(client); handleClubSelect(null)}} className='dropdown-item'>{t('menus.general.profile')}</Link>
								<Link to={`/${t("url.client.client")}/${t("url.client.members")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.members')}</Link>
								<Link to={`/${t("url.client.client")}/${t("url.client.inscriptions")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.inscriptions')}</Link>
								<Link to={`/${t("url.client.client")}/${t("url.client.reservations")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.reserve')}</Link>
								<Link to={`/${t("url.client.client")}/${t("url.client.passes")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.passes')}</Link>
								<Link to={`/${t("url.client.client")}/${t("url.client.tickets")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.tickets')}</Link>
								<Link to={`/${t("url.client.client")}/${t("url.client.payments")}`} className='dropdown-item d-block d-sm-none'>{t('menus.general.payments')}</Link>
							</>
						}
						<Link to={'/'} className='dropdown-item' onClick={() => handleClubSelect(null)}>{t('menus.general.marketplace')}</Link>
						<NavDropdown.Divider />
						<Link to="/" className='dropdown-item' onClick={logout}>{t('menus.logout')}</Link>
					</MenuDropdown>
				</div>
			</Container>
		</Navbar>
	)
}