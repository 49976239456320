export const promotionsReducer = (state = {loading: false, result: null, promotions: [], promotion: null}, action) => {
    switch (action.type) {
        case 'PROMOTION_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'PROMOTION_NOT_LOADING':
            return {
                ...state,
                loading: false
            }
        case 'PROMOTION_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                promotions: action.payload
            }
        case 'PROMOTION_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                promotion: action.payload
            }
        case 'PROMOTION_ADD_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'PROMOTION_EDIT_SUCCESS':
            return {
                ...state,
                loading: false,
                result: action.payload
            }
        case 'PROMOTION_REMOVE_RESULT':
            return {
                ...state,
                loading: false,
                result: action.payload,
                promotions: action.payload.message === "deletePromotionSuccess" ? state.promotions.filter(s => s._id !== action.payload.promotion) : state.promotions
            }
        case 'PROMOTION_RESET_RESULT':
            return {
                ...state,
                result: null
            }
        default:
            return state
    }
}