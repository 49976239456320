import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function Details({match, stadium, sectors, fees, passTypes}) {
    const { t } = useTranslation()
    
	const {commission} = useSelector(state=>state.commissions)

    const getPrice = (quantity) => {
        if (quantity && quantity > 0) {
            const ticketingCommission = commission?.ticketing?.find(plan => quantity >= plan.lowPrice && quantity <= plan.highPrice);
            return (Math.ceil((parseFloat(quantity) + parseFloat(quantity * (ticketingCommission?.percentage || 0) / 100) + parseFloat(ticketingCommission?.double || 0)) * 100) / 100).toFixed(2)
        } else {
            return 0.00
        }
    }

    return (
        <>
            <Card className='border-0 my-4'>
                <Card.Body>
                    <div className='d-flex align-items-end flex-grow-1'>
                        <div className='flex-grow-1'>
                            <h2 className='border-2 border-bottom border-dark'>{t('matches.details') }</h2>
                        </div>
                        <div className='ps-3 pb-2'>
                            <img src="/images/share.svg" alt="Compartir Club" />
                        </div>
                    </div>
                    <Row className='my-3'>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={12} className='d-flex align-items-end'>
                                    {
                                        match?.host &&
                                        <Col xs={6}>
                                            {
                                                match?.hostBadge &&
                                                    <div className='mb-2 text-center'>
                                                        <img src={match.hostBadge} alt='host' className='w-75'/>
                                                    </div>
                                            }
                                            <h3 className='match text-center'>{match.host}</h3>
                                        </Col>
                                    }
                                    {
                                        match?.visitor &&
                                        <Col xs={6}>
                                            {
                                                match?.visitorBadge &&
                                                    <div className='mb-2 text-center'>
                                                        <img src={match.visitorBadge} alt='visitor' className='w-75'/>
                                                    </div>
                                            }
                                            <h3 className='match text-center'>{match.visitor}</h3>
                                        </Col>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                        <strong>{t('matches.date')}</strong>
                                        {`${String(new Date(match?.date)?.getDate()).padStart(2, '0')}/${String(new Date(match?.date)?.getMonth() + 1).padStart(2, '0')}/${new Date(match?.date)?.getFullYear()}`}
                                    </div>
                                    <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                        <strong>{t('matches.hour')}</strong>
                                        {match?.hour}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                            {
                                match?.sectors?.filter(s => s.active === true && s.deleted === false)?.map((sector, index) => (
                                    <div className='my-5' key={index}>
                                        <div>
                                            <h2 className='border-2 border-bottom border-dark'>{sectors?.find(s => s._id === sector.sector)?.name}</h2>
                                        </div>
                                        {
                                            sector.fees?.filter(f => f.active === true)?.map((fee, feeIndex) => (
                                                <div className='d-flex justify-content-between border-1 border-bottom py-2' key={feeIndex}>
                                                    <strong>{fees?.find(f => f._id === fee.fee)?.name}</strong>
                                                    {fee.price}€
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            {
                                match?.usePassTypes &&
                                    <>
                                        <div className='mt-5'>
                                            <h2 className='border-2 border-bottom border-dark'>{t('matches.passPrices')}</h2>
                                        </div>
                                        {
                                            match?.passTypes?.filter(p => p.deleted === false)?.map((passType, passTypeIndex) => (
                                                <div className='d-flex justify-content-between border-1 border-bottom py-2' key={passTypeIndex}>
                                                    <strong>{passTypes?.find(f => f._id === passType.passType)?.name}</strong>
                                                    {passType.price}€
                                                </div>
                                            ))
                                        }
                                    </>
                            }
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className='text-center'>
                            <h3>{stadium?.name}</h3>
                            <img src={stadium?.image} alt='stadium' className='w-100'/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}