import AxiosControl from '../../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getClub = (club, isAdmin = false) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/get/'+ club + '/' + isAdmin, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLUB_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubPublic = (club) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/public/get/'+ club)
        if (status === 200) {
            dispatch({
                type: 'CLUB_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubPublicByUrl = (url) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/public/getbyurl/'+ url)
        if (status === 200) {
            dispatch({
                type: 'CLUB_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubs = (isAdmin) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/getall/' + isAdmin, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLUB_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubsPublic = () => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/public/getall/')
        if (status === 200) {
            dispatch({
                type: 'CLUB_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const getClubsByUser = (user) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/club/getclubsbyuser/'+ user, {
            headers: { auth: sessionStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'CLUB_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const addClub = (newclubdata) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/club/register', newclubdata, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            if(data.jwtNew){
                sessionStorage.setItem('jwt', data.jwtNew)
            }
            dispatch({
                type: 'CLUB_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const sendToVerify = (club, documentType) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/club/sendToVerify/' + club, {documentType}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'CLUB_VERIFY_DOCUMENT_SUCCESS',
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const changeClubVerify = (id, statusVerify) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/club/changeverify/' + id, {clubverify: statusVerify}, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'CLUB_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}

export const editStadium = (club, stadium) => async (dispatch) => {
    dispatch({
        type: 'CLUB_LOADING'
    })
    try {
        // Subida de imágenes
        if(stadium.image !== null && stadium.image !== undefined && stadium.image !== '' && typeof stadium.image !== 'string'){
            let formData = new FormData()
            formData.append('image', stadium.image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            stadium.image = data
        }

        const { data, status} = await Axios.put('/api/club/editStadium/' + club, stadium, {
                headers: { auth: sessionStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'CLUB_EDIT_STADIUM_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'CLUB_NOT_LOADING'
        })
    }
}