import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { getAvailableMatchesByClub } from '../../actions/ticketing/matchesActions'
import { getSectorsByClub, getSectorUnavailableSeats } from '../../actions/ticketing/sectorsActions'
import Select from "react-select"
import Loader from '../design/Loader/Loader'
import { createInvitation, getTicketsByClub } from '../../actions/ticketing/ticketsActions'
import Swal from 'sweetalert2'

export default function AddInvitation({show, closeModal, club}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()

    const {loading: matchesLoading, matches} = useSelector(state => state.matches)
    const {loading: sectorsLoading, sectors, unavailableSeats} = useSelector(state => state.sectors)
    const {loading, result} = useSelector(state => state.ticket)

    const [invitationForm, setInvitationForm] = useState({
        match: null,
        sector: null,
        personData: {
            firstname: null,
            lastname: null,
            email: null,
            phone: null
        },
        maxUses: 1,
        type: null
    })

    const handleInputChange = ({target}) => {
        if (target.name === "sector") {
            dispatch(getSectorUnavailableSeats(target.value))
        }
        setInvitationForm({
            ...invitationForm,
            [target.name]: target.value
        })
    }

    const handlePersonDataInputChange = ({target}) => {
        setInvitationForm({
            ...invitationForm,
            personData: {
                ...invitationForm.personData,
                [target.name]: target.value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!invitationForm.match || !invitationForm.sector || !invitationForm.maxUses || invitationForm.maxUses === "" || !invitationForm.personData.firstname || invitationForm.personData.firstname === "" || !invitationForm.personData.email || invitationForm.personData.email === "") {
            Swal.fire({
                icon: 'error',
                text: t('matches.ticket.createInvitationFormError'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        } else {
            dispatch(createInvitation({...invitationForm, club: club._id}))
        }
    }

    const handleClose = () => {
        setInvitationForm({
            match: null,
            sector: null,
            personData: {
                firstname: null,
                lastname: null,
                email: null,
                phone: null
            },
            maxUses: 1,
            type: null
        })
        closeModal()
    }

    useEffect(() => {
        if (club) {
            dispatch(getAvailableMatchesByClub(club._id))
            dispatch(getSectorsByClub(club._id))
        }
    }, [club, dispatch])

    useEffect(() => {
        if (result) {
            let newResult = result
            dispatch({
                type: 'TICKET_CHANGE_STATUS_RESET'
            })
            switch (newResult) {
                case 'createInvitationSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('matches.ticket.createInvitationSuccess')
                    }).then(() => {
                        dispatch(getTicketsByClub(club._id))
                        setInvitationForm({
                            match: null,
                            sector: null,
                            personData: {
                                firstname: null,
                                lastname: null,
                                email: null,
                                phone: null
                            },
                            maxUses: 1,
                            type: null
                        })
                        closeModal()
                    })
                    break;
                case 'createInvitationFormError':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'error',
                        text: t('matches.ticket.createInvitationFormError')
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result, dispatch, t, club])

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('tickets.addInvitation')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body className='cuerpo px-5 py-4'>
                    {
                        matchesLoading || sectorsLoading
                        ?
                            <Loader/>
                        :
                            <>
                                <Row>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className='form-control has-content'
                                                name='match'
                                                options={matches.map(match => {
                                                    return { label: `${match.host} - ${match.visitor} ${new Date(match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${match.hour}`, value: match._id}
                                                })}
                                                value={invitationForm.match ? {value: invitationForm.match, label: `${matches.find(m => m._id === invitationForm.match)?.host} - ${matches.find(m => m._id === invitationForm.match)?.visitor} ${new Date(matches.find(m => m._id === invitationForm.match)?.date || new Date()).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${matches.find(m => m._id === invitationForm.match)?.hour}`} : null}
                                                onChange={(value) => {
                                                    handleInputChange({target: { name: 'match', value: value.value }})
                                                }}
                                            />
                                            <Form.Label htmlFor='match' className='px-3'>{t('matches.ticket.match')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='my-3'>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className='form-control has-content'
                                                name='sector'
                                                options={matches.find(m => m._id === invitationForm.match)?.sectors?.map(sector => {
                                                    const currentSector = sectors.find(s => s._id === sector.sector)
                                                    if (currentSector.deleted === true || currentSector.numerated === true || sector.active === false) {
                                                        return null
                                                    } else {
                                                        return { value: sector.sector, label: currentSector.name }
                                                    }
                                                })?.filter(v => v !== null) || []}
                                                value={invitationForm.sector ? {value: invitationForm.sector, label: sectors.find(s => s._id === invitationForm.sector)?.name} : null}
                                                onChange={(value) => {
                                                    handleInputChange({target: { name: 'sector', value: value.value }})
                                                }}
                                            />
                                            <Form.Label htmlFor='sector' className='px-3'>{t('matches.ticket.sector')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className='form-control has-content'
                                                name='type'
                                                options={["multiple", "single"].map(type => {
                                                    return {label: t(`matches.ticket.${type}`), value: type}
                                                })}
                                                value={invitationForm.type ? {value: invitationForm.type, label: t(`matches.ticket.${invitationForm.type}`)} : null}
                                                onChange={(value) => {
                                                    handleInputChange({target: { name: 'type', value: value.value }})
                                                }}
                                            />
                                            <Form.Label htmlFor='sector' className='px-3'>{t('matches.ticket.useType')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='number'
                                                name='maxUses'
                                                required
                                                value={invitationForm.maxUses || ''}
                                                min={1}
                                                max={unavailableSeats?.seatsLeft || 0}
                                                onChange={handleInputChange}
                                                className={invitationForm.maxUses ? 'has-content' : ''}
                                            />
                                            {
                                                unavailableSeats?.seatsLeft !== null && unavailableSeats?.seatsLeft !== undefined
                                                ?
                                                    <Form.Label htmlFor='maxUses' className='px-3'>{t(invitationForm.type === "single" ? 'matches.ticket.quantityWithRemain' : 'matches.ticket.maxUsesWithRemain', {quantity: unavailableSeats.seatsLeft})}</Form.Label>
                                                :
                                                    <Form.Label htmlFor='maxUses' className='px-3'>{t(invitationForm.type === "single" ? 'matches.ticket.quantity' : 'matches.ticket.maxUses')}</Form.Label>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='firstname'
                                                value={invitationForm.personData.firstname || ''}
                                                onChange={handlePersonDataInputChange}
                                                required
                                                className={invitationForm.personData.firstname ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='firstname' className='px-3'>{t('matches.ticket.firstname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='lastname'
                                                value={invitationForm.personData.lastname || ''}
                                                onChange={handlePersonDataInputChange}
                                                className={invitationForm.personData.lastname ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='lastname' className='px-3'>{t('matches.ticket.lastname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='email'
                                                required
                                                value={invitationForm.personData.email || ''}
                                                onChange={handlePersonDataInputChange}
                                                className={invitationForm.personData.email ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='email' className='px-3'>{t('matches.ticket.email')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='phone'
                                                value={invitationForm.personData.phone || ''}
                                                onChange={handlePersonDataInputChange}
                                                className={invitationForm.personData.phone ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='phone' className='px-3'>{t('matches.ticket.phone')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button type='button' variant='default' onClick={handleClose}>{t('global.cancel')}</Button>
                    <Button type='submit' variant='primary'>
                        {
                            loading
                            ?
                                <Loader/>
                            :
                                t('global.save')
                        }
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
	)
}