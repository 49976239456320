import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col, Card, Modal} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select, { components } from "react-select"
import { editUserClient } from '../../../actions/profiles/usersActions'
import { getClient } from '../../../actions/profiles/clientsActions'
import Swal from 'sweetalert2'
import useAuth from '../../../context/auth/useAuth'
import CountriesComponent from '../../../components/countries'
import provinces from '../../../components/provinces.json'
import towns from '../../../components/towns.json'
import HeadConfig from '../../../components/menus/SubMenuConfig'

export default function Addressdata({clientselected, usertype}) {
    const { t } = useTranslation()
	const {clientauth} = useAuth()
    const dispatch = useDispatch()

    const {user, resultuser} = useSelector(state=>state.users)
    const {client} = useSelector(state=>state.clients)
	const {countries} = CountriesComponent()

    const [countriesAux, setCountriesAux] = useState([])
    const Input = ({ ...rest }) => <components.Input {...rest} autoComplete={'nope'} />
    const [provinceAux, setProvinceAux] = useState({label: '', value: ''})
    const [townAux, setTownAux] = useState({label: '', value: ''})
    const [townsAux, setTownsAux] = useState([])

    const [provinceEditAux, setProvinceEditAux] = useState({label: '', value: ''})
    const [townEditAux, setTownEditAux] = useState({label: '', value: ''})
    const [townsEditAux, setTownsEditAux] = useState([])

    const [isUpdating, setIsUpdating] = useState(false)

    const [formState, setFormState] = useState({})
    const [show, setShow] = useState(false)

    const [newAddress, setNewAddress] = useState({})
    const [editAddress, setEditAddress] = useState({})

    useEffect(() => {
        if(clientauth){
            dispatch(getClient(clientselected))
            setCountriesAux(countries)
        }
    // eslint-disable-next-line
    },[dispatch, t, clientauth, clientselected])

    useEffect(() => {
        if(client){
            setFormState({
                addresses: client?.addresses || []
            })
        }
    // eslint-disable-next-line
    }, [user, client])

    const handleInputChange = (handletype, {target})=>{
        if(handletype === 'add'){
            setNewAddress({
                ...newAddress,
                [target.name]: target.value
            })
        }else{
            setEditAddress({
                ...editAddress,
                [target.name]: target.value
            })
        }
    }

    const handleCountryChange = (handletype, target)=>{
        if(handletype === 'add'){
            setNewAddress({
                ...newAddress,
                'country': target.value,
                'province': '',
                'town': ''
            })
            setProvinceAux({label: '', value: ''})
            setTownAux({label: '', value: ''})
        }else{
            setEditAddress({
                ...editAddress,
                'country': target.value,
                'province': '',
                'town': ''
            })
            setProvinceEditAux({label: '', value: ''})
            setTownEditAux({label: '', value: ''})
        }
    }

    const handleSelectProvinceChange = (handletype, target)=>{
        if(handletype === 'add'){
            setNewAddress({
                ...newAddress,
                'province': target.value,
                'town': ''
            })
            setProvinceAux(provinces.filter(province => province.value === target.value))
            setTownAux({label: '', value: ''})
            setTownsAux(towns.filter(town => town.parent_value === target.value))
        }else{
            setEditAddress({
                ...editAddress,
                'province': target.value,
                'town': ''
            })
            setProvinceEditAux(provinces.filter(province => province.value === target.value))
            setTownEditAux({label: '', value: ''})
            setTownsEditAux(towns.filter(town => town.parent_value === target.value))
        }
    }

    const handleSelectTownChange = (handletype, target)=>{
        if(handletype === 'add'){
            setNewAddress({
                ...newAddress,
                'town': target.label
            })
            setTownAux(towns.filter(town => town.value === target.value && newAddress.province === town.parent_value))
        }else{
            setEditAddress({
                ...editAddress,
                'town': target.label
            })
            setTownEditAux(towns.filter(town => town.value === target.value && editAddress.province === town.parent_value))
        }
    }

    const openEditAddress = (index) => {
        setShow(true)
    
        setProvinceEditAux({label: '', value: ''})
        setTownEditAux({label: '', value: ''})
        setTownsEditAux([])

        setEditAddress({...formState?.addresses[index], position: index})

        if(formState?.addresses[index].country === 'spain'){
            setProvinceEditAux(provinces.filter(province => province.value === formState?.addresses[index].province))
            setTownsEditAux(towns.filter(town => town.parent_value === formState?.addresses[index].province))
            setTownEditAux(towns.filter(town => town.value === formState?.addresses[index].town))
        }
    }

    const handleClose = () => setShow(false)

    const deleteAddress = (addressIndex)=>{
        Swal.fire({            
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: t('global.cancel'),
            icon: 'question',
            text: t('users.addresses.addressdeletequestion')
        }).then((result) => {
            if (result.isConfirmed) {
                let arrayAddresses = [...formState.addresses]
                arrayAddresses.splice(addressIndex, 1)
                setFormState({
                    addresses: arrayAddresses
                })
                dispatch(editUserClient({...formState, 'addresses': arrayAddresses, clienttoupdate: clientselected}, null, null))
            }
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIsUpdating(true)

        if(editAddress && editAddress.name !== '' && editAddress.address !== '' && ((editAddress.country !== '' && editAddress.town !== '') || (editAddress.country !== '' && editAddress.province !== '' && editAddress.town !== ''))){
            let arrayAddresses = formState?.addresses
    
            const { position, ...newEditAddress } = editAddress
            arrayAddresses[editAddress.position] = newEditAddress
    
            dispatch(editUserClient({...formState, 'addresses': arrayAddresses, clienttoupdate: clientselected}, null, null))
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.missingdata')
            })
        }

        setIsUpdating(false)
    }

    const handleAdd = async(e) => {
        e.preventDefault()
        setIsUpdating(true)

        if(newAddress && (newAddress.name && newAddress.name !== '') && (newAddress.address && newAddress.address !== '') && (((newAddress.country && newAddress.country !== '') && (newAddress.town && newAddress.town !== '')) || ((newAddress.country && newAddress.country !== '') && (newAddress.province && newAddress.province !== '') && (newAddress.town && newAddress.town !== '')))){
            let arrayAddresses = formState?.addresses || []

            arrayAddresses.push({...newAddress})

            dispatch(editUserClient({...formState, 'addresses': arrayAddresses, clienttoupdate: clientselected}, null, null))

            setProvinceAux({label: '', value: ''})
            setTownAux({label: '', value: ''})
            setTownsAux([])
            setNewAddress({})
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.missingdata')
            })
        }

        setIsUpdating(false)
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('users.addressupdated')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <HeadConfig nuevaSeccion="address" type={clientauth?.client === clientselected || usertype === 'superadmin' ? 'client' : 'member'} usertype={usertype}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} lg={7} className='mb-2'>
                    <Card>
                        <Card.Body>
                            <h2>{t('users.editaddress')}</h2>
                            <hr className='mb-4'/>
                            <h4>{t('users.newaddress')}</h4>
                            <Form className="formulario" onSubmit={handleAdd}>
                                <Row className="my-3">
                                    <Col xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={newAddress.name ? 'has-content' : ''}
                                                type='text'
                                                name='name' 
                                                value={newAddress?.name ||  ''}
                                                onChange={(e) => handleInputChange('add', e)}
                                                required
                                            />
                                            <Form.Label htmlFor='name'>{t('users.addresses.addressname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={newAddress.address ? 'has-content' : ''}
                                                type='text'
                                                name='address' 
                                                value={newAddress?.address ||  ''}
                                                onChange={(e) => handleInputChange('add', e)}
                                                required
                                            />
                                            <Form.Label htmlFor='address'>{t('users.addresses.address')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Select
                                                components={{ Input }} 
                                                className="form-control select has-content"
                                                type='text'
                                                name='country'
                                                inputProps={{ autoComplete: 'nope' }}
                                                options={countriesAux}
                                                value={{ value: newAddress?.country || '', label: t('countries.' + (newAddress?.country || '')) }}
                                                onChange={(e) => handleCountryChange('add', e)}
                                                required
                                            />
                                            <Form.Label htmlFor='country'>{t('users.addresses.country')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                className={newAddress.postalCode ? 'has-content' : ''}
                                                type='text'
                                                name='postalCode' 
                                                value={newAddress?.postalCode ||  ''}
                                                onChange={(e) => handleInputChange('add', e)}
                                                required
                                            />
                                            <Form.Label htmlFor='postalCode'>{t('users.addresses.postalcode')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {newAddress?.country === 'spain' ?
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Select
                                                        className="form-control select has-content" 
                                                        name={'province'}
                                                        options={provinces}
                                                        value={provinceAux || null}
                                                        onChange={(e) => handleSelectProvinceChange('add', e)}
                                                        required
                                                    />
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{ opacity: 0, height: 0 }}
                                                        value={newAddress?.province || ''}
                                                        required
                                                        readOnly
                                                    />
                                                    <Form.Label htmlFor='province'>{t('users.addresses.province')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Select
                                                        className="form-control select has-content"
                                                        name={'town'}
                                                        options={townsAux}
                                                        value={townAux || null}
                                                        onChange={(e) => handleSelectTownChange('add', e)}
                                                        required
                                                    />
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{ opacity: 0, height: 0 }}
                                                        value={newAddress?.town || ''}
                                                        required
                                                        readOnly
                                                    />
                                                    <Form.Label htmlFor='town'>{t('users.addresses.town')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    :
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-group'>
                                                    <Form.Control
                                                        className={newAddress.town ? 'has-content' : ''}
                                                        type='text'
                                                        name='town'
                                                        value={newAddress?.town ||  ''}
                                                        onChange={(e) => handleInputChange('add', e)}
                                                        required
                                                    />
                                                    <Form.Label htmlFor='town'>{t('users.addresses.town')}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                }
                                <Row className="mt-5">
                                    <Col xs={12} lg={6} className='ms-auto'>
                                        <Button variant='primary' type="submit" className="w-100 my-2">
                                        {isUpdating
                                            ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </>
                                            :
                                                <>{t('global.add')}</>
                                        }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={5}>
                    {formState?.addresses?.length > 0 ?
                        formState?.addresses?.map((address, index) => (
                            <Col xs={12} key={index} className='mb-2'>
                                <Card>
                                    <Row>
                                        <Col xs={10}>
                                            <h3>{address.name}</h3>
                                            {address.address}<br/>
                                            {address.town}, {address.province ? address.province+', ' : '' } {address.region ? address.region+', ' : '' } {t('countries.'+address.country)}<br/>
                                            {address.postal_code}
                                        </Col>
                                        <Col xs={2} className='d-flex align-items-center justify-content-around'>
                                            <i className="fas fa-edit cursor" aria-hidden="true" onClick={() => openEditAddress(index)}></i>
                                            <i className="fas fa-trash cursor" aria-hidden="true" onClick={() => deleteAddress(index)}></i>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))
                    :
                        ''
                    }
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{t('categories.editaddress')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="my-3">
                        <Col xs={12}>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className={editAddress.name ? 'has-content' : ''}
                                    type='text'
                                    name='name' 
                                    value={editAddress?.name ||  ''}
                                    onChange={(e) => handleInputChange('edit', e)}
                                    required
                                />
                                <Form.Label htmlFor='name'>{t('users.addresses.addressname')}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} className='my-3'>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className={editAddress.address ? 'has-content' : ''}
                                    type='text'
                                    name='address' 
                                    value={editAddress?.address ||  ''}
                                    onChange={(e) => handleInputChange('edit', e)}
                                    required
                                />
                                <Form.Label htmlFor='address'>{t('users.addresses.address')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col xs={12} lg={6}>
                            <Form.Group className='form-group'>
                                <Select
                                    components={{ Input }} 
                                    className="form-control select has-content"
                                    type='text'
                                    name='country'
                                    inputProps={{ autoComplete: 'nope' }}
                                    options={countriesAux}
                                    value={{ value: editAddress?.country || '', label: t('countries.' + (editAddress?.country || '')) }}
                                    onChange={(e) => handleCountryChange('edit', e)}
                                    required
                                />
                                <Form.Label htmlFor='country'>{t('users.addresses.country')}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='form-group'>
                                <Form.Control
                                    className={editAddress.postalCode ? 'has-content' : ''}
                                    type='text'
                                    name='postalCode' 
                                    value={editAddress?.postalCode ||  ''}
                                    onChange={(e) => handleInputChange('edit', e)}
                                    required
                                />
                                <Form.Label htmlFor='postalCode'>{t('users.addresses.postalcode')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    {editAddress?.country === 'spain' ?
                        <Row className="my-3">
                            <Col xs={12} lg={6}>
                                <Form.Group className='form-group'>
                                    <Select
                                        className="form-control select has-content" 
                                        name={'province'}
                                        options={provinces}
                                        value={provinceEditAux || null}
                                        onChange={(e) => handleSelectProvinceChange('edit', e)}
                                        required
                                    />
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        value={editAddress?.province || ''}
                                        required
                                        readOnly
                                    />
                                    <Form.Label htmlFor='province'>{t('users.addresses.province')} : {editAddress?.province}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group className='form-group'>
                                    <Select
                                        className="form-control select has-content"
                                        name={'town'}
                                        options={townsEditAux}
                                        value={townEditAux || null}
                                        onChange={(e) => handleSelectTownChange('edit', e)}
                                        required
                                    />
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        value={editAddress?.town || ''}
                                        required
                                        readOnly
                                    />
                                    <Form.Label htmlFor='town'>{t('users.addresses.town')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    :
                        <Row className="my-3">
                            <Col xs={12} lg={6}>
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={editAddress.town ? 'has-content' : ''}
                                        type='text'
                                        name='town'
                                        value={editAddress?.town ||  ''}
                                        onChange={(e) => handleInputChange('edit', e)}
                                        required
                                    />
                                    <Form.Label htmlFor='town'>{t('users.addresses.town')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    <Row className="mt-5">
                        <Col xs={12} lg={6} className='ms-auto'>
                            <Button variant='primary' type="submit" className="w-100 my-2" onClick={handleEdit}>
                            {isUpdating
                                ?
                                    <>
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </>
                                :
                                    <>{t('global.update')}</>
                            }
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    )
}
