import React, { useEffect } from 'react'
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { changeClubVerify, getClubs } from '../../../actions/profiles/clubsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Loader from '../../../components/design/Loader/Loader'
import { paySepa } from '../../../actions/payments/paymentActions'
import Swal from 'sweetalert2'
import Loading from '../../../components/design/Modal/Loading'

export default function Clubs({handleClubSelect}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {clientauth} = useAuth()

    const {clubs,loadingclub} = useSelector(state=>state.clubs)
    const {loading, sepaResult} = useSelector(state => state.payment)

    const handleVerifyChange = (clubId, verify) => {
        dispatch(changeClubVerify(clubId, verify))
    }

    useEffect(() =>{
        if(clientauth){
            dispatch(getClubs(true))
        }
    }, [dispatch, t, clientauth])

    useEffect(() => {
        if (sepaResult) {
            switch (sepaResult) {
                case 'paySepaSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('admin.clubs.paySepaSuccess')
                    })
                    dispatch(getClubs(true))
                    break;
                default:
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('admin.clubs.' + sepaResult)
                    })
                    break;
            }
            dispatch({type: 'PAYMENT_SEPA_RESULT_RESET'})
        }
    }, [sepaResult, dispatch])

    const handleSepaBtn = (club) => {
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.yes'),
            denyButtonText: t('global.no'),
            icon: 'warning',
            text: t('admin.clubs.paySepaText')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(paySepa(clientauth.id, club))
            }
        })
    }

    const columnsClubs= [
        {
            name: t('admin.clubs.club'),
            cell: club =>
                <Link onClick={() => handleClubSelect(club?._id)} to={`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.basicdata")}`} className='table-link'>
                     <img
                        src={club?.avatar ? club?.avatar : "/images/shield.png"}
                        alt="Icon shield"
                        width={60}
                        className='me-3'
                    />
                    {club?.name}
                </Link>,
            selector: (club) => club?.name,
            sortable: true,
        },{
            name: t('club.verified'),
            center: 'true',
            width: '200px',
            cell: club =>
                <div>
                    {club.verified === true || club.verified === false ?
                        <Form.Check
                            type='switch'
                            checked={club?.verified}
                            onChange={({target}) => (handleVerifyChange(club._id, target.checked))}
                        />
                    :
                        ''
                    }
                </div>,
            selector: (club) => club.verified,
            sortable: true,
        },{
            name: t('club.accounting.title'),
            center: 'true',
            width: '200px',
            cell: club =>
                club.accounting
                ?
                    <div className='my-2'>
                        <p className='my-2'>
                            <strong>{t('club.accounting.charged')}: </strong>
                            <span>{(club?.accounting?.charged !== null && club?.accounting?.charged !== undefined) ? club.accounting.charged.toFixed(2) : ''}€</span>
                        </p>
                        <p className='my-2'>
                            <strong>{t('club.accounting.paid')}: </strong>
                            <span>{(club?.accounting?.paid !== null && club?.accounting?.paid !== undefined) ? club.accounting.paid.toFixed(2) : ''}€</span>
                        </p>
                        <p className='my-2'>
                            <strong>{t('club.accounting.sepaPending')}: </strong>
                            <span>{(club?.accounting?.sepaPending !== null && club?.accounting?.sepaPending !== undefined) ? club.accounting.sepaPending.toFixed(2) : ''}€</span>
                        </p>
                        <p className='my-2'>
                            <strong>{t('club.accounting.freeSepaPending')}: </strong>
                            <span>{(club?.accounting?.freeSepaPending !== null && club?.accounting?.freeSepaPending !== undefined) ? club.accounting.freeSepaPending.toFixed(2) : ''}€</span>
                        </p>
                        {
                            club?.accounting?.freeSepaPending && club?.accounting?.freeSepaPending > 0 && club?.paycomet?.allVerified === true && club?.paycomet?.iban
                            ?
                                <p className='my-2'>
                                    <Button type='button' size='sm' onClick={() => {handleSepaBtn(club._id)}} className='py-1' disabled={loading}>{t('club.accounting.paySepa')}</Button>
                                </p>
                            :
                                <></>
                        }
                    </div>
                :
                    <></>,
            selector: (club) => club.verified,
            sortable: true,
        },{
			name: t('global.actions'),
            center: 'true',
            width: '120px',
			cell: club =>
                <OverlayTrigger
                    placement="bottom"
                    trigger='click'
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className='menu-puntos text-left'>
                            <Link onClick={() => handleClubSelect(club?._id)} to={`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.basicdata")}`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt="pencil" width={15}></img> {t('global.edit')}
                            </Link>
                            <Link onClick={() => handleClubSelect(club?._id)} to={`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.activities")}`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt="pencil" width={15}></img> {t('admin.clubs.activities')}
                            </Link>
                            <Link onClick={() => handleClubSelect(club?._id)} to={`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}`} className='dropdown-item'>
                                <img src="/images/edit.svg" alt="pencil" width={15}></img> {t('admin.clubs.sites')}
                            </Link>
                            {
                                club.ticketing === true
                                ?
                                    <Link onClick={() => handleClubSelect(club?._id)} to={`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}`} className='dropdown-item'>
                                        <img src="/images/edit.svg" alt="pencil" width={15}></img> {t('admin.clubs.ticketing')}
                                    </Link>
                                :
                                    <></>
                            }
                        </Tooltip>
                    }
                    >
                    <Button variant="puntos"><img src='/images/menupuntos.svg' alt='Menu auxiliar'/></Button>
                </OverlayTrigger>,
			sortable: false,
		}
	]

    return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('admin.clubs.list')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        { loadingclub
                        ?
                            <Loader/>
                        :
                            clubs ?
                                <CustomDataTable columns={columnsClubs} data={clubs} exportable={false} printable={false} />
                            :
                                ''
                        }
                    </Card>
                </Col>
            </Row>
            {
                loading && !loadingclub
                ?
                    <Loading show={loading}/>
                :
                    <></>
            }
        </Container>
    )
}