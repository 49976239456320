import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Club({club}) {
    const { t } = useTranslation()    
    const backCardImage = {
        backgroundImage: club?.bannerMarketplace ? `url(${club?.bannerMarketplace})` : `url("/images/bannerclub.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    return (
        <Card className="miniatura">
            <Link to={`/${t("url.marketplace.clubs")}/${club?.url}`}>
                <div className="image" variant="top" style={backCardImage}></div>
            </Link>
            <Card.Body>
                <Card.Title className="d-flex align-items-center mb-5">
                    {club.avatar
                    ?
                        <img src={club?.avatar} alt="Escudo club" style={{width: '50px'}} className="me-2"/>
                    :
                        ''
                    }
                    {club?.name}
                </Card.Title>
                <Row className="mt-auto">
                    <Col>
                        <Link to={`/${t("url.marketplace.clubs")}/${club?.url}`} className="btn btn-primary w-100">
                            {t("global.moreInfo")}
                            <img src="images/rignt-arrow.svg" alt="Continuar" className="ms-3"/>
                        </Link>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}