import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { addUser } from '../../actions/profiles/usersActions'
import Swal from 'sweetalert2'

export default function RegisterClub() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {user, resultuser, useractivate} = useSelector(state=>state.users)

    const [formState, setFormState] = useState({})
    const [policy, setPolicy] = useState(false)

    //eslint-disable-next-line
    const [passwordverify, setPasswordverify] = useState("")
    const [isCorrect, setIsCorrect] = useState(false)
    const [showPasswordOne, setShowPasswordOne] = useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        setFormState({
            profile: 'Club',
            username: '',
            firstname: '',
            lastname: '',
            password: '',
            email: ''
        })
    }, [user, dispatch])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handlePolicy  = (e) => {
        setPolicy(e.target.checked)
    }

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handlePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo)
    }

    const checkValidation = (isPassVer, e) => {
        const confirmPass = e.target.value

        if(isPassVer){
            setPasswordverify(confirmPass)
        }else{
            setFormState({
                ...formState,
                'password': confirmPass
            })
        }

        if (formState.password !== confirmPass) {
            setIsCorrect(false)
        }else {
            setIsCorrect(true)
        }
    }

    const handleAdd = async(e) => {
        e.preventDefault()
        if(isCorrect){
            if(formState.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,20}$/g)){
                dispatch(addUser(formState))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('users.passwordsnotvalid')
                })
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.passwordsnotmatch')
            })
        }
    }

    useEffect(() => {
        if(resultuser){
            let newresult = resultuser
            dispatch({
                type: 'USER_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('users.gotoverify'),
                        icon: 'success',
                        text: t('users.useradd')
                    }).then((newresult) => {
                        if (newresult.isConfirmed) {
                            navigate(`/${t("url.auth.activation")}/`+useractivate)
                        }
                    })
                    break;
                case 'email':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.emailduplicate')
                    })
                    break;
                case 'username':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.usernameduplicate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <Fragment>
            <header className="cabecera sticky-top d-flex align-items-center px-4">
                <Link to='/'><img src="/images/logotipo-incrono-cabecera.svg" alt="Logotipo Incrono" /></Link>
            </header>
            <Container fluid className='p-0 m-0'>
                <Row className='p-0 m-0 centro-auth'>
                    <Col md={7} className='d-none d-md-block aside-auth'>
                    </Col>
                    <Col xs={12} md={5} className='d-flex align-items-center p-5'>
                        <Container>
                            <Row>
                                <Col>
                                    <h1>{t('users.createaccount')}</h1>            
                                    <Form className="formulario" onSubmit={handleAdd}>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='name'>{t('users.clubname')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='name' 
                                                    value={formState.name ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='firstname'>{t('users.firstname')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='firstname' 
                                                    value={formState.firstname ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Label htmlFor='lastname'>{t('users.lastname')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='lastname' 
                                                    value={formState.lastname ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='phone'>{t('users.phone')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='phone'
                                                    value={formState.phone ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='username'>{t('users.username')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='text'
                                                    name='username' 
                                                    value={formState.username ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col>
                                                <Form.Label htmlFor='email'>{t('users.email')} *</Form.Label>
                                                <Form.Control
                                                    className="login"
                                                    type='email'
                                                    name='email' 
                                                    value={formState.email ||  ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="my-3">
                                            <Col className='sd-passwordview'>
                                                <Form.Label htmlFor='password'>{t('users.password')} *</Form.Label>
                                                <Form.Control
                                                    className="sd-input"
                                                    type={showPasswordOne ? 'text' : 'password'}
                                                    name='password' 
                                                    value={formState.password ||  ''}
                                                    placeholder={t('users.setpassword')}
                                                    onChange={(e) => checkValidation(false, e)}
                                                    required
                                                />                                            
                                                <img className="sd-imageneye" src={showPasswordOne ? "/images/eyeclose.svg" : "/images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <p>
                                                <span className='fs-12 fw-400 ps-1'>{t('users.passwordneed1')}</span><br/>
                                                <span className='password-list'>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed2')}</span>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed3')}</span>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed4')}</span>
                                                </span>
                                            </p>
                                        </Row>
                                        <Row className="my-3">
                                            <Col className='sd-passwordview'>
                                                <Form.Label htmlFor='passwordcheck'>{t('users.passwordconfirm')} *</Form.Label>
                                                <Form.Control
                                                    className="sd-input"
                                                    type={showPasswordTwo ? 'text' : 'password'}
                                                    name='passwordcheck'
                                                    placeholder={t('users.setpasswordconfirm')}
                                                    onChange={(e) => checkValidation(true, e)}
                                                    required
                                                />
                                                <img className="sd-imageneye" src={showPasswordTwo ? "/images/eyeclose.svg" : "/images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityTwo}></img>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='d-flex align-items-start'>
                                                <Form.Check className='float-left pe-3' type='checkbox' name='policy' onChange={handlePolicy} checked={policy || false} />                                
                                                <p>
                                                    {t('users.policy1')}
                                                    <a href="https://incrono.com/es/politica-cookies" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy2')}</u></a>
                                                    {t('users.policy3')}
                                                    <a href="https://incrono.com/es/politica-privacidad" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy4')}</u></a>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="text-center my-3">
                                            <Button variant='primary' type="submit" className="w-100 my-2" disabled={!policy}>{t('users.createaccountbutton')}</Button>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col className='text-center'>
                                    {t('registerlanding.alreadyaccount')}
                                    <Link to={`/${t("url.auth.login")}`} className='link ms-1'>
                                        {t('signin.login')}
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
