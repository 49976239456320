import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ActivityMarket({activity}) {
    const { t } = useTranslation()
    const backCardImage = {
        backgroundImage: activity?.image ? `url(${activity?.image})` : `url("/images/sports/${activity?.sport}.jpg")`,        
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    return (
        <Card className="miniatura">
            <Link to={`/${t("url.marketplace.clubs")}/${activity?.club?.url}/${t("url.marketplace.activities")}/${activity?.url}`}>
                <div className="image" variant="top" style={backCardImage}></div>
            </Link>
            <Card.Body>
                <Card.Title>{activity.title}</Card.Title>
                <Row className="mt-3">
                    <Col className="d-flex align-items-center">
                        <img src="/images/club.svg" alt="club" className="me-2"/>                    
                        {activity?.club?.name}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="d-flex align-items-center">
                        <img src={`/images/sports/iconos/${activity?.sport}.svg`} alt={t("marketplace.activity.activity")} className="me-2"/>
                        {t('sports.' + (activity?.sport || ''))}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="d-flex align-items-center justify-content-between flex-wrap">
                        <span className="price">
                            {activity?.minimumPrice !== 0
                                ? `${t("global.from")} ${activity?.minimumPrice.toFixed(2)}€`
                                : t("activities.free")}
                        </span>
                        <Link
                            to={`/${t("url.marketplace.clubs")}/${activity?.club?.url}/${t("url.marketplace.activities")}/${activity?.url}`}
                            className="btn btn-primary"
                        >
                            {t("activities.enrole")}
                            <img src="/images/rignt-arrow.svg" alt={t("global.continue")} className="ms-3"/>
                        </Link>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}