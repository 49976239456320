import { Button, Card, Col, Form, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Loader from "../../design/Loader/Loader"
import { createRef } from "react"

export default function MatchForm({state, statetype, usertype, isAddingOrEditing, submit, setState, sectors, passTypes, fees, commission}) {
    const { t } = useTranslation()
    const hostBadgeRef = createRef("<HTMLInputElement>")
    const visitorBadgeRef = createRef("<HTMLInputElement>")
    const thumbnailRef = createRef("<HTMLInputElement>")
    const templateRef = createRef("<HTMLInputElement>")

    const handleInputChange = ({target})=>{
        setState({
            ...state,
            [target.name]: target.value
        })
    }

    const handleImage = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.files[0]
        })
    }

    const handleSectorCheck = (target, index) => {
        setState({
            ...state,
            sectors: state.sectors?.map((sector, sectorIndex) => {
                if (index === sectorIndex) {
                    return {
                        ...sector,
                        [target.name]: target.checked
                    }
                } else {
                    return sector
                }
            })
        })
    }

    const handleSelectChange = ({target}, selectType) => {
        setState({
            ...state,
            [selectType]: target.checked
        })
    }

    const handleSectorFeeInputChange = (target, sectorIndex, feeIndex) => {
        setState({
            ...state,
            sectors: state.sectors?.map((sector, stateSectorIndex) => {
                if (sectorIndex === stateSectorIndex) {
                    return {
                        ...sector,
                        fees: sector.fees.map((fee, sectorFeeIndex) => {
                            if (feeIndex === sectorFeeIndex) {
                                return {
                                    ...fee,
                                    [target.name]: target.value
                                }
                            } else {
                                return fee
                            }
                        })
                    }
                } else {
                    return sector
                }
            })
        })
    }

    const handlePassTypesInputChange = (target, passTypesIndex) => {
        setState({
            ...state,
            passTypes: state.passTypes?.map((passType, statePassTypesIndex) => {
                if (passTypesIndex === statePassTypesIndex) {
                    return {
                        ...passType,
                        [target.name]: target.value
                    }
                } else {
                    return passType
                }
            })
        })
    }
    const calculatePayment = (price) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }

        if (!isNaN(price)) {
            const ticketing = commission?.ticketing?.find(ticketing => price >= ticketing.lowPrice && price <= ticketing.highPrice);
            if (ticketing) {
                return {
                    price: price,
                    priceClient: price > 0 ? (Math.ceil(((price * (ticketing.percentage || 0) / 100) + (ticketing.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
                }
            } else {
                return {
                    price: price,
                    priceClient: '0.00'
                }
            }
        } else {
            return {
                price: '0.00',
                priceClient: '0.00'
            }
        }
    }

    return (
        <>
            <Form onSubmit={submit}>
                <Row className="justify-content-between">
                    <Col className="d-flex justify-content-between">
                        <h3 className="font-single">{t('matches.data')}</h3>
                        <div className="d-flex">
                            {state?.active ? t('horaries.active') : t('horaries.inactive') }
                            <Form.Check
                                className="ms-3"
                                type="switch"
                                name="active"
                                onChange={(e) => handleSelectChange(e, 'active')}
                                checked={state?.active || false}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center">
                                <div className='avatar me-2'>
                                    {state?.hostBadge
                                        ?
                                            <img className="img-profile" alt="Preview" src={typeof state?.hostBadge === 'string' ? state?.hostBadge : URL.createObjectURL(state?.hostBadge)} />
                                        :
                                            <div className="img-profile"></div>
                                    }
                                    <input 
                                        className='custom-file-input' 
                                        style={{ display: 'none' }}
                                        ref={hostBadgeRef}
                                        type="file"
                                        name="hostBadge" 
                                        onChange={handleImage} 
                                    />
                                </div>
                                <div className="editaravatar" onClick={() => hostBadgeRef.current?.click()}>{t('matches.hostBadge')}</div>
                            </Col>
                            <Col xs={12} className="mt-3">
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={state?.host ? 'has-content' : ''}
                                        type='text'
                                        name='host'
                                        value={state?.host ||  ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='host'>{t('matches.host')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center">
                                <div className='avatar me-2'>
                                    {state?.visitorBadge
                                        ?
                                            <img className="img-profile" alt="Preview" src={typeof state?.visitorBadge === 'string' ? state?.visitorBadge : URL.createObjectURL(state?.visitorBadge)} />
                                        :
                                            <div className="img-profile"></div>
                                    }
                                    <input 
                                        className='custom-file-input' 
                                        style={{ display: 'none' }}
                                        ref={visitorBadgeRef}
                                        type="file" 
                                        name="visitorBadge" 
                                        onChange={handleImage} 
                                    />
                                </div>
                                <div className="editaravatar" onClick={() => visitorBadgeRef.current?.click()}>{t('matches.visitorBadge')}</div>
                            </Col>
                            <Col xs={12} className="mt-3">
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={state?.visitor ? 'has-content' : ''}
                                        type='text'
                                        name='visitor'
                                        value={state?.visitor ||  ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <Form.Label htmlFor='visitor'>{t('matches.visitor')}</Form.Label>
                                </Form.Group>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center">
                                <div className='avatar-cuadrado me-2'>
                                    {state?.thumbnail
                                        ?
                                            <img className="img-profile" alt="Preview" src={typeof state?.thumbnail === 'string' ? state?.thumbnail : URL.createObjectURL(state?.thumbnail)} />
                                        :
                                            <div className="img-profile"></div>
                                    }
                                    <input
                                        className='custom-file-input'
                                        style={{ display: 'none' }}
                                        ref={thumbnailRef}
                                        type="file"
                                        name="thumbnail"
                                        onChange={handleImage}
                                    />
                                </div>
                                <div className="editaravatar" onClick={() => thumbnailRef.current?.click()}>{t('matches.thumbnail')}</div>
                            </Col>
                            <Col xs={12} className="mt-3">
                                <Form.Group className='form-group'>
                                    <Form.Control
                                        className={state?.day ? 'has-content' : ''}
                                        type='text'
                                        name='day'
                                        value={state?.day ||  ''}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Label htmlFor='day'>{t('matches.day')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center">
                                <div className='avatar-cuadrado me-2'>
                                    {state?.template
                                        ?
                                            <img className="img-profile" alt="Preview" src={typeof state?.template === 'string' ? state?.template : URL.createObjectURL(state?.template)} />
                                        :
                                            <div className="img-profile"></div>
                                    }
                                    <input
                                        className='custom-file-input' 
                                        style={{ display: 'none' }}
                                        ref={templateRef}
                                        type="file"
                                        name="template"
                                        onChange={handleImage} 
                                    />
                                </div>
                                <div className="editaravatar" onClick={() => templateRef.current?.click()}>{t('matches.template')}</div>
                            </Col>
                            <Col xs={12} className="mt-3">
                                <Form.Group className='form-group'>
                                    <Form.Select className="has-content form-control" onChange={handleInputChange} name="ticketOrientation" value={state?.ticketOrientation}>
                                        <option value={false}>{t('matches.vertical')}</option>
                                        {/* <option value={true}>{t('matches.horizontal')}</option> */}
                                    </Form.Select>
                                    <Form.Label htmlFor='ticketOrientation'>{t('matches.ticketOrientation')}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <Form.Label htmlFor='date'>{t('matches.date')}</Form.Label>
                        <Form.Control
                            className={state?.date ? 'has-content' : ''}
                            type='date'
                            name='date'
                            value={state?.date ||  ''}
                            onChange={handleInputChange}
                            required
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Label htmlFor='date'>{t('matches.hour')}</Form.Label>
                        <Form.Control
                            className={state?.hour ? 'has-content' : ''}
                            type='time'
                            name='hour'
                            value={state?.hour ||  ''}
                            onChange={handleInputChange}
                            required
                        />
                    </Col>
                </Row>
                <Row className="mt-5 mb-3">
                    <Col xs={12} className="my-3">
                        <h3 className="font-single">{t('matches.pricesectors')}</h3>
                        <hr className="fina"/>
                    </Col>
                    {
                        state?.sectors?.map((sector, index) => (
                            <Col xs={12} md={6} key={index} className="p-1">
                                <Card>
                                    <Card.Body className="p-0">
                                        <div className="mb-4">
                                            <h3 className="font-single">{sectors?.find(s => s._id === sector.sector)?.name}</h3>
                                            <hr className="fina"/>
                                        </div>
                                        <div className='d-flex justify-content-between border-1 border-bottom py-2'>
                                            <span>{t('matches.marketplaceVisible')}</span>
                                            <Form.Check
                                                className="ms-3"
                                                type="switch"
                                                name="marketplaceVisible"
                                                onChange={({target}) => {handleSectorCheck(target, index)}}
                                                checked={sector?.marketplaceVisible || false}
                                            />
                                        </div>
                                        <div>
                                            <div className='d-flex justify-content-between py-2'>
                                                <span>{t('matches.active')}</span>
                                                <Form.Check
                                                    type="switch"
                                                    name="active"
                                                    onChange={({target}) => {handleSectorCheck(target, index)}}
                                                    checked={sector?.active || false}
                                                />
                                            </div>
                                            <hr/>
                                        </div>
                                        <div>
                                            {t('matches.fees')}
                                            <hr className="fina"/>
                                        </div>
                                        {
                                            sector.fees?.map((fee, feeIndex) => (
                                                <Row className='border-1 border-bottom py-2 align-items-center' key={feeIndex}>
                                                    <Col xs={6}>
                                                        {fees?.find(f => f._id === fee.fee)?.name}
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Control
                                                            className={fee?.price ? 'has-content' : ''}
                                                            type='number'
                                                            name='price'
                                                            value={fee?.price ||  ''}
                                                            onChange={({target}) => {handleSectorFeeInputChange(target, index, feeIndex)}}
                                                        />
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Label htmlFor="active">{t('matches.active')}</Form.Label>
                                                        <Form.Check
                                                            type="switch"
                                                            name="active"
                                                            onChange={({target}) => {handleSectorFeeInputChange({name: "active", value: target.checked}, index, feeIndex)}}
                                                            checked={fee?.active || false}
                                                        />
                                                    </Col>
                                                    <Col xs={12}>
                                                        <div className='d-flex justify-content-end'>
                                                            <small>{t('inscriptions.priceclient')}</small>
                                                            <small className="ms-2">{calculatePayment(fee?.price || 0).priceClient}€</small>
                                                        </div>
                                                        <div className='d-flex justify-content-end'>
                                                            <small>{t('inscriptions.finalprice')}</small>
                                                            <small className="ms-2">
                                                                {(
                                                                    Number(fee?.price) +
                                                                    Number(calculatePayment(fee?.price || 0).priceClient)
                                                                ).toFixed(2)}€
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
                <Row className="mt-5 mb-3">
                    <Col xs={12} className="d-flex justify-content-between">
                        <h3 className="font-single">{t('matches.passTypesPay')}</h3>
                        <div className="d-flex">
                            {
                                state?.usePassTypes
                                ?
                                    <span className="me-3">{t('global.yes')}</span>
                                :
                                    <span className="me-3">{t('global.no')}</span>
                            }
                            <Form.Check
                                type="switch"
                                name="usePassTypes"
                                onChange={({target}) => {handleInputChange({target: {name: "usePassTypes", value: target.checked}})}}
                                checked={state?.usePassTypes || false}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className="fina"/>
                    </Col>
                </Row>
                {
                    state.usePassTypes
                    ?
                        state.passTypes?.map((passType, passTypeIndex) => (
                            <Row className='border-1 border-bottom py-2 align-items-center' key={passTypeIndex}>
                                <Col xs={8}>
                                    {passTypes?.find(f => f._id === passType.passType)?.name}
                                </Col>
                                <Col xs={4}>
                                    <Form.Control
                                        className={passType?.price ? 'has-content' : ''}
                                        type='number'
                                        name='price'
                                        value={passType?.price ||  ''}
                                        onChange={({target}) => {handlePassTypesInputChange(target, passTypeIndex)}}
                                    />
                                </Col>
                            </Row>
                        ))
                    :
                    <></>
                }
                {isAddingOrEditing
                    ?
                        <Loader/>
                    :
                        <Row className="mt-5">
                            <Col xs={12} lg={6}>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.matches")}` : `/${t("url.club.club")}/${t("url.club.matches")}`} className="btn btn-default my-2 w-100">
                                    {t('global.cancel')}
                                </Link>
                            </Col>
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button type="submit" variant="primary" className='my-2 w-100'>
                                    {
                                        statetype === 'add' ? t('global.save') :
                                        statetype === 'edit' ? t('global.edit') : ''
                                    }
                                </Button>
                            </Col>
                        </Row>
                }
            </Form>
        </>
    )    
}
