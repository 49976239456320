import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ActivityForm from '../../../../components/forms/activities/ActivityForm'
import SportsComponent from '../../../../components/sports'
import { updateActivity, getActivity } from '../../../../actions/activities/activitiesActions'

import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export default function ActivityEdit({usertype, activityselected}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {activity, resultactivity, loadingactivity} = useSelector(state=>state.activities)
	const {club, clubs} = useSelector(state=>state.clubs)

	const {sports} = SportsComponent()
    const [sportsAux, setSportsAux] = useState([])

    const [editActivity, setEditActivity] = useState()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(()=>{
        if(club && activityselected){
            dispatch(getActivity(activityselected))
        }
    //eslint-disable-next-line
    }, [dispatch, club, activityselected])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(club && activity){
            if (activity?.description) {
                const blocksFromHtml = htmlToDraft(activity.description)
                const { contentBlocks, entityMap } = blocksFromHtml
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
            }

            setEditActivity({
                title: activity?.title,
                description: activity?.description,
                image: activity?.image,
                club: {value: club._id, label: club.name},
                sport: activity?.sport,
                visibility_type: activity?.visibility_type,
                visibility: activity?.visibility,
                active: activity?.active
            })
    
            setSportsAux(sports)
        }
    //eslint-disable-next-line
    }, [club, activity])

    const handleInputChange = ({target})=>{
        setEditActivity({
            ...editActivity,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setEditActivity({
            ...editActivity,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        setEditActivity({
            ...editActivity,
            [selectType]: target.value
        })
    }

    const handleSelectChangeClub = (target, selectType) => {
        setEditActivity({
            ...editActivity,
            [selectType]: target
        })
    }

    const handleImage = (e)=>{
        setEditActivity({
            ...editActivity,
            image: e.target.files[0]
        })
    }

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState)

        setEditActivity({
            ...editActivity,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(editActivity.sport !== ''){
            dispatch(updateActivity({...editActivity, club: editActivity.club.value}, activityselected))
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('activities.nosportselected')
            })
        }
    }

    useEffect(() => {
        if(resultactivity){
            let newresult = resultactivity
            dispatch({
                type: 'ACTIVITY_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('activities.editedsuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.activities")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.activities")}`)
                        }
                    })
                    break;
                case 'duplicatetitle':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('activities.duplicatetitle')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultactivity])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('activities.editactivity')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <ActivityForm state={editActivity} clubs={clubs} statetype={'edit'} usertype={usertype} isAdding={loadingactivity} sports={sportsAux} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} selectChangeClub={handleSelectChangeClub} handleImage={handleImage} editorState={editorState} handleEditorStateChange={handleEditorStateChange}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}