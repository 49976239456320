import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select"
import CategoryForm from '../../../../components/forms/activities/CategoryForm'
import { addCategory, getCategoriesByClub, importCategoriesByClub } from '../../../../actions/activities/categoriesActions'

export default function CategoryAdd({usertype, isNew}) {
    const { t } = useTranslation()
	const dispatch = useDispatch()

	const {categoryToImport, loadingcategory} = useSelector(state=>state.categories)
	const {club, clubs} = useSelector(state=>state.clubs)

    const [newCategory, setNewCategory] = useState()

    const [clubsList, setClubsList] = useState()
    const [clubToImport, setClubToImport] = useState(null)
    const [categoriesList, setCategoriesList] = useState()
    const [categoriesToImport, setCategoriesToImport] = useState(null)

    useEffect(()=>{
        setNewCategory({
            club: club?._id,
            title: '',
            ageFrom: 0,
            ageTo: 50,
            active: true
        })
    //eslint-disable-next-line
    }, [dispatch, club])

    useEffect(()=>{
        if (categoryToImport) {
            if (categoryToImport.length > 0) {
                const options = categoryToImport?.map(category => ({
                    value: category._id,
                    label: category.title
                }))
                setCategoriesList(options)
            }else{
                setCategoriesList(null)
            }
        }else{
            setCategoriesList(null)
        }
    //eslint-disable-next-line
    }, [categoryToImport])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (clubs) {
                if (clubs.length > 0) {
                const options = clubs.map(club => ({
                    value: club._id,
                    label: club.name
                }))
                setClubsList(options)
            }
        }
    //eslint-disable-next-line
    }, [clubs])

    const handleInputChange = ({target})=>{
        setNewCategory({
            ...newCategory,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewCategory({
            ...newCategory,
            [target.name]: target.checked
        })
    }

    const handleSliderChange = (low, high) => {
        setNewCategory({
            ...newCategory,
            ageFrom: low,
            ageTo: high
        })
    }

    const selectClubImport = (target) => {
        setCategoriesList(null)
        setClubToImport(target.value)
        dispatch(getCategoriesByClub(target.value, true))
    }

    const selectCategoriesImport = (target) => {
        setCategoriesToImport(target)
    }

    const handleImport = async(e) => {
        e.preventDefault()

        dispatch(importCategoriesByClub(categoriesToImport, club?._id))
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        dispatch(addCategory(newCategory))
    }

	return (
        <>
            {isNew
            ?
                <Col>
                    <CategoryForm
                        state={newCategory}
                        statetype={'add'}
                        usertype={usertype}
                        isAdding={loadingcategory}
                        submit={handleAdd}
                        inputChange={handleInputChange}
                        checkboxChange={handleCheckboxChange}
                        sliderChange={handleSliderChange}
                    />
                </Col>
            :
                <div>
                    <Row className="my-2">
                        <Col>
                            <Form.Group className='form-group'>
                                <Select
                                    className="form-control has-content"
                                    type='text'
                                    name='club'
                                    options={clubsList}
                                    onChange={(e) => selectClubImport(e)}
                                    required
                                />
                                <Form.Label htmlFor='club'>{t('groups.club')}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                {clubToImport ?
                    <Row className="my-2">
                        <Col>
                            <Form.Group className='form-group'>
                                {categoriesList?.length > 0 ?
                                    <>
                                        <Select
                                            className="form-control has-content"
                                            type='text'
                                            name='category'
                                            options={categoriesList}
                                            onChange={(e) => selectCategoriesImport(e)}
                                            isMulti
                                            required
                                        />
                                        <Form.Label htmlFor='category'>{t('categories.categories')}</Form.Label>
                                    </>
                                :
                                    ''
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                :
                    ''
                }
                    <Row className="my-2">
                        <Col>
                            <Button variant="primary" className="w-100 my-2" onClick={(e) => handleImport(e)} disabled={!categoriesToImport?.length > 0}>
                                {t('global.import')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
        </>
	)
}