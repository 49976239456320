import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function MatchMarket({match}) {
    const { t } = useTranslation()

    return (
        <Card className="miniatura">
            <Link to={`/${t("url.marketplace.clubs")}/${match?.club?.url}/${t("url.marketplace.matches")}/${match?.url}`}>
                {
                    match.hostBadge && match.hostBadge !== "" && match.visitorBadge && match.visitorBadge !== ""
                    ?
                        <Row className="justify-content-around my-2">
                            <Col xs={5}>
                                <div className="image" variant="top" style={{
                                    backgroundImage: `url(${match.hostBadge})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}/>
                            </Col>
                            <Col xs={5}>
                                <div className="image" variant="top" style={{
                                    backgroundImage: `url(${match.visitorBadge})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}/>
                            </Col>
                        </Row>
                    :
                        match.hostBadge && match.hostBadge !== ""
                        ?
                            <Row className="justify-content-around my-2">
                                <Col xs={7}>
                                    <div className="image" variant="top" style={{
                                        backgroundImage: `url(${match.hostBadge})`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}/>
                                </Col>
                            </Row>
                        :
                            <div className="image" variant="top" style={{
                                backgroundImage: `url("/images/bannerclub.jpg")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}/>

                }
            </Link>
            <Card.Body>
                <Card.Title>{match.host} - {match.visitor}</Card.Title>
                <Row className="mt-2">
                    <Col className="d-flex align-items-center">
                        <img src="/images/calendar.svg" alt="people" className="me-2"/>                    
                        {`${String(new Date(match.date).getDate()).padStart(2, '0')}/${String(new Date(match.date).getMonth() + 1).padStart(2, '0')}/${new Date(match.date).getFullYear()} ${match.hour}`}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="d-flex align-items-center justify-content-between flex-wrap">
                        <span className="price">
                            {match?.price !== 0
                                ? `${t("global.from")} ${match?.price?.toFixed(2)}€`
                                : "Gratuita"}
                        </span>
                        <Link
                            to={`/${t("url.marketplace.clubs")}/${match?.club?.url}/${t("url.marketplace.matches")}/${match?.url}`}
                            className="btn btn-primary"
                        >
                            {t("matches.buy")}
                            <img src="/images/rignt-arrow.svg" alt="Continuar" className="ms-3"/>
                        </Link>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}