import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { addFee } from '../../../../actions/ticketing/feesActions.js'
import { getSectorsByClub } from '../../../../actions/ticketing/sectorsActions.js'
import FeeForm from '../../../../components/forms/fees/FeeForm.js'
import { getCommissionByClub } from '../../../../actions/settings/commissionsActions.js'

export default function AddFee({usertype}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()

	const {result, loading: feesLoading} = useSelector(state=>state.fees)
    const {sectors, loading: sectorsLoading} = useSelector(state=>state.sectors)
	const {club} = useSelector(state=>state.clubs)
	const {commission} = useSelector(state=>state.commissions)

    const [newFee, setNewFee] = useState({
        name: '',
        prices: []
    })

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newFee.name !== ''){
            if (!newFee.prices?.some(p => !p.price || p.price === "")) {
                dispatch(addFee({...newFee, club: club._id}))
            } else {
                Swal.fire({
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('global.ok'),
                    icon: 'warning',
                    text: t('fees.noPrices')
                })
            }
        } else {
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('fees.noName')
            })
        }
    }

    useEffect(() => {
        if(result){
            let newresult = result.message
            dispatch({
                type: 'FEE_RESET_RESULT'
            })
            switch (newresult) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('fees.addSuccess')
                    }).then(() => {
                        if(usertype === 'superadmin'){
                            navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.fees")}`)
                        }else{
                            navigate(`/${t("url.club.club")}/${t("url.club.fees")}`)
                        }
                    })
                    break;
                case 'duplicateFee':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'warning',
                        text: t('fees.duplicateFee')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

    useEffect(() => {
        if (club?._id) {
            dispatch(getSectorsByClub(club?._id))
            dispatch(getCommissionByClub(club?._id))
        }
    }, [club, dispatch])

    useEffect(() => {
        if (sectors) {
            setNewFee({
                ...newFee,
                prices: sectors.map(sector => {
                    return {
                        price: '',
                        sector: sector._id
                    }
                })
            })
        }
    // eslint-disable-next-line
    }, [sectors])

	return (
        <Container className='cuerpo'>
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('fees.createNewFee')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <FeeForm state={newFee} setState={setNewFee} statetype={'add'} usertype={usertype} isAdding={feesLoading || sectorsLoading} submit={handleAdd} sectors={sectors} commission={commission}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
	)
}