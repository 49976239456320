import { Button, Col, Form, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Loader from "../../design/Loader/Loader"

export default function SeasonForm({state, statetype, usertype, isAddingOrEditing, submit, inputChange}) {
    const { t } = useTranslation()

    return (
        <>
            <Form onSubmit={submit}>
                <Row>
                    <Col>
                        <h3 className="font-single">{t('seasons.data')}</h3>
                        <hr className="fina"/>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={state?.name ? 'has-content' : ''}
                                type='text'
                                name='name'
                                value={state?.name ||  ''}
                                onChange={inputChange}
                                required
                            />
                            <Form.Label htmlFor='name'>{t('seasons.name')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={'has-content'}
                                type='date'
                                name='startDate'
                                value={state?.startDate ||  ''}
                                onChange={inputChange}
                                required
                            />
                            <Form.Label htmlFor='startDate'>{t('seasons.startDate')}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='form-group'>
                            <Form.Control
                                className={'has-content'}
                                type='date'
                                name='endDate'
                                value={state?.endDate ||  ''}
                                onChange={inputChange}
                                required
                            />
                            <Form.Label htmlFor='endDate'>{t('seasons.endDate')}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                {isAddingOrEditing
                    ?
                        <Loader/>
                    :
                        <Row className="mt-5">
                            <Col xs={12} lg={6}>
                                <Link to={usertype === 'superadmin' ? `/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.seasons")}` : `/${t("url.club.club")}/${t("url.club.seasons")}`} className="btn btn-default my-2 w-100">
                                    {t('global.cancel')}
                                </Link>
                            </Col>
                            <Col xs={12} lg={6} className='ms-auto'>
                                <Button type="submit" variant="primary" className='my-2 w-100'>
                                    {
                                        statetype === 'add' ? t('global.save') :
                                        statetype === 'edit' ? t('global.edit') : ''
                                    }
                                </Button>
                            </Col>
                        </Row>
                }
            </Form>
        </>
    )    
}
